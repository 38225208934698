// This file is generated automatically by scripts/generate-icon.js. Don't modify it.

import withIconProps from '../withIconProps';

import LogoInternal from '../_raw/ui/logo_internal.svg';

export default withIconProps(LogoInternal, {
  defaultHeight: 32,
  defaultWidth: 192,
});
