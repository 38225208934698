import React from 'react';
import { Translate } from 'core/translate';

export const pageInitialValues = {
  pageName: '',
  pageId: undefined,
  status: true,
  updateZonedDateTime: undefined,
  product: '',
};

export const labelInitialValues = {
  conditions: [
    {
      condition: 'none',
      labelValues: [
        {
          lang: 'en-US',
          value: '',
        },
      ],
      translationStatus: '',
    },
  ],
  labelId: null,
  labelName: '',
  note: '',
  status: true,
  updateZonedDateTime: null,
};

export const initialSortValue = {
  options: [
    {
      value: 'sort.updateDateTime=desc',
      text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.New_Old" />,
    },
    {
      value: 'sort.updateDateTime=asc',
      text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.Old_New" />,
    },
    {
      value: 'sort.pageName=asc',
      text: <Translate id="Supplier_Enumeration.Sort.Name_Alphabetical.Ascending" />,
    },
    {
      value: 'sort.pageName=desc',
      text: <Translate id="Supplier_Enumeration.Sort.Name_Alphabetical.Descending" />,
    },
  ],
  selectedValue: 'sort.pageName=asc',
};

export const initialLabelSortValue = {
  options: [
    {
      value: 'sort.updateDateTime=desc',
      text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.New_Old" />,
    },
    {
      value: 'sort.updateDateTime=asc',
      text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.Old_New" />,
    },
    {
      value: 'sort.labelName=asc',
      text: <Translate id="Supplier_Enumeration.Sort.Name_Alphabetical.Ascending" />,
    },
    {
      value: 'sort.labelName=desc',
      text: <Translate id="Supplier_Enumeration.Sort.Name_Alphabetical.Descending" />,
    },
  ],
  selectedValue: 'sort.updateDateTime=desc',
};

export const STATUS_FILTER_OPTIONS = [
  {
    label: <Translate id="Supplier_Enumeration.Status.Activation.Active" />,
    name: 'filter.pageStatus',
    value: 'active',
  },
  {
    label: <Translate id="Supplier_Enumeration.Status.Activation.Inactive" />,
    name: 'filter.pageStatus',
    value: 'inactive',
  },
  {
    label: <Translate id="Common.Common.Enumeration.All" />,
    name: 'filter.pageStatus',
    value: 'all',
  },
];

export const PRODUCT_FILTER_OPTIONS = [
  {
    label: 'Extranet',
    name: 'filter.product',
    value: 'Extranet',
  },
  {
    label: 'Traveler',
    name: 'filter.product',
    value: 'Traveler',
  },
  {
    label: 'All',
    name: 'filter.product',
    value: '',
  },
];

export const initialLabelFilterValue = [
  {
    label: <Translate id="Label_List_Management.List.Filter.Page_Status" />,
    id: 'labelStatus',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Status.Activation.Active" />,
        value: 'active',
        isCheck: true,
      },
      {
        label: <Translate id="Supplier_Enumeration.Status.Activation.Inactive" />,
        value: 'inactive',
        isCheck: false,
      },
    ],
  },
  {
    label: <Translate id="Label_List_Management.List.Filter.Language_Code" />,
    id: 'langCode',
    options: [
      {
        label: <Translate id="Supplier_Input_Langauge.en-us" />,
        value: 'en-US',
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Input_Langauge.ja-jp" />,
        value: 'ja-JP',
        isCheck: false,
      },
    ],
  },
];
