import React from 'react';
import styles from './filterPageSkeleton.module.scss';
import SkeletonListItems, { SimpleSkeletonItem } from '@travel/ui/components/SkeletonListItems';

function FilterPageSkeleton() {
  return (
    <div data-testid="filterPageSkeleton-wrapper">
      <div className={styles.header}>
        <SimpleSkeletonItem hasMedia={false} titleClassName={styles.title} />
      </div>

      <div className={styles.contentWrapper}>
        <section className={styles.filterSection}>
          <SkeletonListItems
            listClassName={styles.skeletonList}
            itemClassName={styles.skeletonItem}
            numberOfItem={3}
            skeletonType={'detailPage'}
            hasMedia={false}
          />
        </section>
        <section className={styles.mainContent}></section>
      </div>
    </div>
  );
}

export default FilterPageSkeleton;
