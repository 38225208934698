import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  RatePlan,
  RatePlanErrors,
  RatePlanDetail_API,
  ApplicableRatePlanBrowseParameters,
  FeatureFilterValues,
} from 'RatePlan-Types';
import {
  fetchMasterRatePlansAsync,
  fetchRatePlansAsync,
  fetchRatePlanDetailAsync,
  setInitialPlanDetail,
  updateRatePlanBrowseParameter,
  updateFeatureFilterBrowseParameters,
  clearCurrentRatePlanList,
  clearFeatureFilterBrowseParamaters,
  createRatePlanAsync,
  updateRatePlanAsync,
  deleteRatePlanAsync,
  deletableRatePlanAsync,
} from './actions';
import {
  initialValues,
  applicbleRatePlanBrowseParameter,
  initialFeatureFilterParamaters,
} from './initialValues';
import { ToolBarOptions } from 'constants/rate-plan';
import { BrowseParameters } from 'Utils-Types';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchRatePlansAsync.request], () => true)
  .handleAction([fetchRatePlansAsync.success, fetchRatePlansAsync.failure], () => false);

export const isMasterFetching = createReducer(false as boolean)
  .handleAction([fetchMasterRatePlansAsync.request], () => true)
  .handleAction(
    [fetchMasterRatePlansAsync.success, fetchMasterRatePlansAsync.failure],
    () => false,
  );

export const isCreating = createReducer(false)
  .handleAction([createRatePlanAsync.request, updateRatePlanAsync.request], () => true)
  .handleAction(
    [
      createRatePlanAsync.success,
      createRatePlanAsync.failure,
      updateRatePlanAsync.success,
      updateRatePlanAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction([deleteRatePlanAsync.request, deletableRatePlanAsync.request], () => true)
  .handleAction(
    [
      deleteRatePlanAsync.success,
      deleteRatePlanAsync.failure,
      deletableRatePlanAsync.success,
      deletableRatePlanAsync.failure,
    ],
    () => false,
  );

export const ratePlanList = createReducer({} as RatePlan)
  .handleAction(fetchRatePlansAsync.success, (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  })
  .handleAction(clearCurrentRatePlanList, () => ({} as RatePlan));

export const masterRatePlanList = createReducer({} as RatePlan)
  .handleAction(fetchMasterRatePlansAsync.success, (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;
    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  })
  .handleAction(clearCurrentRatePlanList, () => ({} as RatePlan));

export const browseParameters = createReducer(ToolBarOptions as BrowseParameters);

export const ratePlanListErrors = createReducer([] as RatePlanErrors[]).handleAction(
  fetchRatePlansAsync.failure,
  (_state, action) => action.payload,
);

export const masterRatePlanListErrors = createReducer([] as RatePlanErrors[]).handleAction(
  fetchMasterRatePlansAsync.failure,
  (_state, action) => action.payload,
);

export const ratePlanDetail = createReducer(initialValues as RatePlanDetail_API)
  .handleAction(fetchRatePlanDetailAsync.success, (_state, action) => {
    return {
      ...initialValues,
      ...action.payload,
    };
  })
  .handleAction(setInitialPlanDetail, () => initialValues);

export const ratePlanDetailErrors = createReducer([] as RatePlanErrors[]).handleAction(
  fetchRatePlanDetailAsync.failure,
  (_state, action) => action.payload,
);

export const applicableRatePlanbrowseParameters = createReducer(
  applicbleRatePlanBrowseParameter as ApplicableRatePlanBrowseParameters,
).handleAction(updateRatePlanBrowseParameter, (_state, action) => action.payload);

export const featureFilterParameters = createReducer(
  initialFeatureFilterParamaters as FeatureFilterValues,
)
  .handleAction(updateFeatureFilterBrowseParameters, (_state, action) => action.payload)
  .handleAction(
    clearFeatureFilterBrowseParamaters,
    () => initialFeatureFilterParamaters as FeatureFilterValues,
  );

const ratePlanReducer = combineReducers({
  isMasterFetching,
  isFetching,
  ratePlanList,
  masterRatePlanList,
  masterRatePlanListErrors,
  ratePlanListErrors,
  ratePlanDetail,
  ratePlanDetailErrors,
  browseParameters,
  applicableRatePlanbrowseParameters,
  featureFilterParameters,
  isCreating,
  isDeleting,
});

export default ratePlanReducer;
export type RatePlanState = ReturnType<typeof ratePlanReducer>;
