import { AccountingReports, YearAndMonths } from 'AccountingReport-Types';
import { ApiClient } from '@travel/api-client';

export const ApiFetchYearAndMonths = (http: ApiClient): Promise<YearAndMonths> =>
  http.get(`/fia/available/years/months`).then((data: YearAndMonths) => data);

export const ApiFetchAccountingReports = (
  http: ApiClient,
  params: Object,
): Promise<AccountingReports> =>
  http.get(`/fia/find/reports`, { values: params }).then((data: AccountingReports) => data);

export const ApiDownloadReport = (http: ApiClient, recodId: string, file: string) =>
  http.get(`/fia-file-loader/${recodId}/${file}`).then(data => data);
