import React from 'react';
import { Correct, Info, Trash } from '@travel/icons/ui';
import { Translate } from '@travel/translation';

export const POPUP_TYPE = {
  SUCCESS: 'SUCCESS',
  CONFIRM: 'CONFIRM',
  DELETE: 'DELETE',
};

export const TITLE = {
  [POPUP_TYPE.SUCCESS]: <Translate id={'Common.Detail.Save_Success.Main_Message'} />,
  [POPUP_TYPE.DELETE]: <Translate id={'Delete_Common.Delete_Success.Title'} />,
};

export const ICON = {
  [POPUP_TYPE.SUCCESS]: <Correct size={24} />,
  [POPUP_TYPE.CONFIRM]: <Info size={24} />,
  [POPUP_TYPE.DELETE]: <Trash size={24} />,
};
