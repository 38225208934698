import React, { ReactNode } from 'react';

import { cx } from '@travel/utils';
import { Photo, Video } from '@travel/icons/ui';
import { Translate } from '@travel/translation';

import FlatButton from './../FlatButton';
import Tag, { BADGE_COLOR } from './../Tag';

import styles from './listItem.module.scss';

export type NavigationAlignmentType = 'inline' | 'block';

type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** Status of List Item */
  status?: ReactNode;

  /** Media type of List Item / File Type*/
  mediaType?: string;

  /** Custom text to be displayed for type of media */
  mediaTypeText?: ReactNode;

  /** Timestamp of ListItem Created or Updated */
  time?: string | number | React.ReactElement;

  /** Flag to show 'Updated' or 'Created' after time label */
  isUpdatedTime?: boolean;

  /** Childrens of List Item */
  children: ReactNode;

  /** Item Id */
  listItemId?: string;

  /** Navigation alignment */
  navigationAlignment: NavigationAlignmentType;

  /** callback to be called after detail button clicked */
  onDetailClick?: (listItemId?: string | number) => void;

  /** callback to be called after sub button clicked */
  onSubButtonClick?: (listItemId?: string | number) => void;

  /** isGridView is used to control List/Grid view  */
  isGridView?: boolean;

  /** for customizing Details button label */
  buttonLabel?: ReactNode;
  subButtonLabel?: ReactNode;

  /** Label for Time & Action */
  timeActionLabel?: ReactNode;

  detailLabel: ReactNode;

  /** The color of status badge */
  statusColor: BADGE_COLOR;

  /** Custom style for content section */
  contentClassName?: string;
};

function ListItem(props: Props) {
  const {
    className,
    mediaType,
    mediaTypeText,
    status,
    isUpdatedTime,
    onDetailClick,
    onSubButtonClick,
    listItemId,
    navigationAlignment,
    isGridView,
    buttonLabel,
    timeActionLabel,
    detailLabel,
    subButtonLabel,
    statusColor,
    time,
    contentClassName,
  } = props;

  const handleDetailClick = () => onDetailClick?.(listItemId);
  const handleSubButtonClick = () => onSubButtonClick?.(listItemId);

  return (
    <div
      data-testid={`listItem-wrapper-${listItemId}`}
      className={cx(className, styles.listItem, isGridView && styles.gridColumn)}
    >
      {!isGridView && (
        <section
          className={cx(contentClassName, styles.itemContent)}
          data-testid="listItem-children-content"
        >
          {props.children}
        </section>
      )}
      <section
        data-testid="listItem-navigation-wrapper"
        className={cx(styles.itemNavigation, navigationAlignment === 'inline' && styles.inline)}
      >
        <div className={styles.status}>
          {(timeActionLabel || time) && (
            <label className={styles.timeStamp}>
              {timeActionLabel ? (
                <span data-testid="listItem-time-action-label">{timeActionLabel}</span>
              ) : isUpdatedTime ? (
                // TODO: Make it as a prop passing from supplier
                <Translate
                  data-testid="listItem-time-updated-label"
                  id="Common.List.Updated"
                  data={{ updated_time: time }}
                />
              ) : (
                // TODO: Make it as a prop passing from supplier
                <Translate
                  data-testid="listItem-time-created-label"
                  id="Common.List.Created"
                  data={{ created_time: time }}
                />
              )}
            </label>
          )}
          {mediaType === 'IMAGE' && (
            <>
              <Photo size={16} />
              <span className={styles.mediaTypeText}>
                {mediaTypeText || <Translate id="Supplier_Enumeration.Media.Type.Image" />}
              </span>
            </>
          )}
          {mediaType === 'VIDEO' && (
            <>
              <Video size={16} />
              <span className={styles.mediaTypeText}>
                {mediaTypeText || <Translate id="Supplier_Enumeration.Media.Type.Video" />}
              </span>
            </>
          )}
          {status && (
            <Tag
              className={cx(styles.tag, statusColor ? styles.long : styles.short)}
              color={statusColor as BADGE_COLOR}
              type="filled"
              data-testid="listItem-status-label"
            >
              {status}
            </Tag>
          )}
        </div>

        {isGridView && (
          <section className={styles.gridWidth} data-testid="listItem-children-content--grid">
            {props.children}
          </section>
        )}

        {// experimental implementation for availability check suggested by YinXin. TODO: ask designer to design
        onSubButtonClick && subButtonLabel && (
          <FlatButton
            data-testid="listItem-sub-button"
            className={styles.subButton}
            classType={'primary'}
            onClick={handleSubButtonClick}
          >
            {subButtonLabel}
          </FlatButton>
        )}
        {onDetailClick && (
          <FlatButton
            data-testid="listItem-detail-button"
            className={isGridView ? styles.detailButtonToggle : styles.detailButton}
            classType={buttonLabel ? 'primary' : 'secondary'}
            onClick={handleDetailClick}
          >
            {buttonLabel ? buttonLabel : detailLabel}
          </FlatButton>
        )}
      </section>
    </div>
  );
}

ListItem.defaultProps = {
  navigationAlignment: 'block',
  detailLabel: 'TLFX: Detail',
  statusColor: 'gray',
};

export default ListItem;
