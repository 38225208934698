import { UserSegmentQueryParams } from 'UserSegment-Types';
import { SEARCH_CONDITIONS } from 'constants/searchConditions';

export const initFilterState: UserSegmentQueryParams = {
  limit: 30,
  offset: 0,
  keyword: '',
  searchCondition: SEARCH_CONDITIONS[0].value,
  sort: {
    editedDate: 'desc',
  },
};
