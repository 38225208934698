import { ApiClient } from '@travel/api-client';
import {
  HistoryParams,
  PastHistory,
  DownloadSummaryData,
  UploadResult,
  HistoryData,
} from 'ProviderDataTranslation-Types';
import { supplierBatchPrefix } from 'constants/api-prefix';

export const ApiFetchHistory = (http: ApiClient, params: HistoryParams): Promise<PastHistory> =>
  http
    .get(`${supplierBatchPrefix}/providers/translation/imports`, { values: params.filter })
    .then((data: PastHistory) => data);

export const ApiFetchSummary = (
  http: ApiClient,
  formData: FormData,
): Promise<DownloadSummaryData> =>
  http
    .post(`${supplierBatchPrefix}/providers/translation/exportSummary`, {
      values: formData,
    })
    .then((data: DownloadSummaryData) => data);

export const ApiExportTranslationCSV = (http: ApiClient, formData: FormData): Promise<Blob> => {
  return http
    .post(`${supplierBatchPrefix}/providers/translation/export`, {
      values: formData,
      ignoreCommonErrorHandler: true,
    })
    .then(data => data.blob())
    .catch(error => error);
};

export const ApiExportFailedData = (http: ApiClient, importId: string): Promise<Blob> => {
  return http
    .get(`${supplierBatchPrefix}/providers/translation/imports/${importId}/downloadFailedData`)
    .then(data => data.blob())
    .catch(error => error);
};

export const ApiUploadProviderContent = (
  http: ApiClient,
  formData: FormData,
): Promise<UploadResult> => {
  return http
    .post(`${supplierBatchPrefix}/providers/translation/imports`, { values: formData })
    .then((data: UploadResult) => data)
    .catch(error => error);
};

export const ApiFetchProcessData = (http: ApiClient, importId: string): Promise<HistoryData> => {
  return http
    .get(`${supplierBatchPrefix}/providers/translation/imports/${importId}`)
    .then((data: HistoryData) => data)
    .catch(error => error);
};
