import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { MarketItems, MarketsErrors, GlobalMarketItem } from 'SupplierMarkets-Types';
import { fetchMarketsAsync, setMarket, setGlobalMarkets } from './actions';

export const isFetching = createReducer(false)
  .handleAction(fetchMarketsAsync.request, () => true)
  .handleAction([fetchMarketsAsync.success, fetchMarketsAsync.failure], () => false);

export const items = createReducer([] as MarketItems)
  .handleAction(fetchMarketsAsync.success, (_state, action) => action.payload)
  .handleAction(setMarket, (_state, action) => action.payload);

export const globalMarkets = createReducer([] as GlobalMarketItem).handleAction(
  setGlobalMarkets,
  (_state, action) => action.payload,
);

export const errors = createReducer([] as MarketsErrors[]).handleAction(
  fetchMarketsAsync.failure,
  (_state, action) => action.payload,
);

const marketsReducer = combineReducers({
  items,
  globalMarkets,
  errors,
  isFetching,
});

export default marketsReducer;
export type MarketsState = ReturnType<typeof marketsReducer>;
