import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  Feature,
  Features,
  FeatureStatus,
  IconList,
  FeatureFilter,
  ConsumerFilterFeatureBrowseParameters,
} from 'Feature-Types';
import {
  fetchFeaturesAsync,
  searchFeatureAsync,
  addFeatureAsync,
  updateFeatureAsync,
  fetchFeatureAsync,
  fetchIconListAsync,
  setInitialFeature,
  updateIconBrowseParameter,
  fetchParentFeaturesAsync,
  updateSearchFeatureBrowseParameter,
  updateUpperFeatureBrowseParameter,
  fetchFeatureFiltersAsync,
  fetchConsumerFilterFeaturesAsync,
  updateConsumerFilterFeatureBrowseParameter,
} from './actions';

import {
  initialFeatures,
  initialFeature,
  initialIconBrowseParameter,
  initialSearchBrowseParameter,
  initialConsumerFilterFeatureBrowseParameter,
} from './initialValues';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchFeaturesAsync.request,
      searchFeatureAsync.request,
      fetchFeatureAsync.request,
      fetchIconListAsync.request,
      fetchParentFeaturesAsync.request,
      fetchFeatureFiltersAsync.request,
      fetchConsumerFilterFeaturesAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchFeaturesAsync.success,
      fetchFeaturesAsync.failure,
      searchFeatureAsync.success,
      searchFeatureAsync.failure,
      fetchParentFeaturesAsync.success,
      fetchParentFeaturesAsync.failure,
      fetchFeatureAsync.success,
      fetchFeatureAsync.failure,
      fetchIconListAsync.success,
      fetchIconListAsync.failure,
      fetchFeatureFiltersAsync.success,
      fetchFeatureFiltersAsync.failure,
      fetchConsumerFilterFeaturesAsync.success,
      fetchConsumerFilterFeaturesAsync.failure,
    ],
    () => false,
  );

export const isCreating = createReducer(false as boolean)
  .handleAction([addFeatureAsync.request, updateFeatureAsync.request], () => true)
  .handleAction(
    [
      addFeatureAsync.success,
      addFeatureAsync.failure,
      updateFeatureAsync.success,
      updateFeatureAsync.failure,
    ],
    () => false,
  );

export const fetchFeatures = createReducer(initialFeatures as Features).handleAction(
  fetchFeatureAsync.success,
  (state, action) => action.payload,
);

export const fetchFeaturesError = createReducer([] as FeatureStatus[]).handleAction(
  fetchFeatureAsync.failure,
  (state, action) => action.payload,
);

export const searchResults = createReducer({} as Features).handleAction(
  searchFeatureAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const searchFeaturesError = createReducer([] as FeatureStatus[]).handleAction(
  searchFeatureAsync.failure,
  (state, action) => action.payload,
);

export const consumerFilterFeatures = createReducer(initialFeatures as Features).handleAction(
  fetchConsumerFilterFeaturesAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const consumerFilterFeaturesError = createReducer(initialFeatures as Features).handleAction(
  fetchConsumerFilterFeaturesAsync.failure,
  (state, action) => action.payload,
);

export const parentFeatures = createReducer(initialFeatures as Features).handleAction(
  fetchParentFeaturesAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;
    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const parentFeaturesError = createReducer([] as FeatureStatus[]).handleAction(
  fetchParentFeaturesAsync.failure,
  (state, action) => action.payload,
);

export const newFeature = createReducer({} as Feature).handleAction(
  addFeatureAsync.success,
  (state, action) => action.payload,
);

export const newFeatureError = createReducer([] as FeatureStatus[]).handleAction(
  addFeatureAsync.failure,
  (state, action) => action.payload,
);

export const updatedFeature = createReducer({} as Feature).handleAction(
  updateFeatureAsync.success,
  (state, action) => action.payload,
);

export const updateFeatureError = createReducer([] as FeatureStatus[]).handleAction(
  updateFeatureAsync.failure,
  (state, action) => action.payload,
);

export const fetchFeature = createReducer(initialFeature as Feature)
  .handleAction(fetchFeatureAsync.success, (state, action) => action.payload)
  .handleAction(setInitialFeature, () => initialFeature);

export const fetchFeatureError = createReducer([] as FeatureStatus[]).handleAction(
  fetchFeatureAsync.failure,
  (state, action) => action.payload,
);

export const fetchIconList = createReducer({} as IconList).handleAction(
  fetchIconListAsync.success,
  (state, action) => action.payload,
);

export const fetchIconListError = createReducer([] as FeatureStatus[]).handleAction(
  fetchIconListAsync.failure,
  (state, action) => action.payload,
);

export const iconBrowseParameters = createReducer(initialIconBrowseParameter).handleAction(
  updateIconBrowseParameter,
  (state, action) => action.payload,
);

export const searchBrowseParameters = createReducer(initialSearchBrowseParameter).handleAction(
  updateSearchFeatureBrowseParameter,
  (state, action) => action.payload,
);

export const upperFeatureBrowseParameters = createReducer(
  initialSearchBrowseParameter,
).handleAction(updateUpperFeatureBrowseParameter, (state, action) => action.payload);

export const featureFilter = createReducer({} as FeatureFilter).handleAction(
  fetchFeatureFiltersAsync.success,
  (state, action) => action.payload,
);

export const featureFilterError = createReducer([] as FeatureStatus[]).handleAction(
  fetchFeatureFiltersAsync.failure,
  (state, action) => action.payload,
);

export const consumerFilterFeaturesBrowseParameters = createReducer(
  initialConsumerFilterFeatureBrowseParameter as ConsumerFilterFeatureBrowseParameters,
).handleAction(updateConsumerFilterFeatureBrowseParameter, (_state, action) => action.payload);

const featureReducer = combineReducers({
  isFetching,
  fetchFeatures,
  fetchFeaturesError,
  searchResults,
  newFeature,
  newFeatureError,
  updatedFeature,
  updateFeatureError,
  searchFeaturesError,
  parentFeatures,
  parentFeaturesError,
  fetchFeature,
  fetchFeatureError,
  iconBrowseParameters,
  fetchIconList,
  fetchIconListError,
  searchBrowseParameters,
  upperFeatureBrowseParameters,
  featureFilter,
  consumerFilterFeatures,
  consumerFilterFeaturesBrowseParameters,
  isCreating,
});

export default featureReducer;
export type FeatureState = ReturnType<typeof featureReducer>;
