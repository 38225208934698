import { ApiClient } from '@travel/api-client';
import { internalPrefix } from 'constants/api-prefix';
import { ApplicableSizeTypes } from 'SizeTypes-Types';

export const ApiFetchSizeTypes = (
  http: ApiClient,
  params: Object,
  language: string,
): Promise<ApplicableSizeTypes> => {
  return http.get(`${internalPrefix}/sizeTypes`, {
    values: params,
    headers: { 'trv-language-ui': language },
  });
};
