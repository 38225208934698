import { Providers, ProviderDetail, ApiStatus } from 'Providers-Types';
import { ApiClient } from '@travel/api-client';
import { LANGUAGE_CODE } from 'core/httpClient/requestInterceptor';

//TODO https://jira.rakuten-it.com/jira/browse/EVEREST-10808
export const ApiFetchProviders = (http: ApiClient, queries: any): Promise<Providers> =>
  http.get('/provider-api/extranet/providers', {
    values: { ...queries },
  });

export const ApiPostProvider = (http: ApiClient, data: ProviderDetail): Promise<ApiStatus> =>
  http
    .post('/provider-api/extranet/providers', {
      values: data,
      headers: { [LANGUAGE_CODE]: data.providerLanguage },
    })
    .then((data: ApiStatus) => data);

export const ApiPutProvider = (http: ApiClient, data: ProviderDetail): Promise<ApiStatus> =>
  http
    .put(`/provider-api/extranet/providers/${data.id}`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiFetchProvider = (http: ApiClient, providerId: string): Promise<ProviderDetail> =>
  http.get(`/provider-api/extranet/providers/${providerId}`);
