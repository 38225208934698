import { useSelector } from 'react-redux';
import { getUserPlatform, PLATFORM } from 'utils';
import { getLoggedInUser } from 'store/accessControl/selectors';

export function useBasePath() {
  const { scope, providerGroupId, providerUuid } = useSelector(getLoggedInUser);
  const userPlatform = getUserPlatform(scope);

  if (userPlatform === PLATFORM.INTERNAL) {
    return `/internal`;
  } else if (userPlatform === PLATFORM.GROUP_EXTRANET) {
    return `/providerGroup/${providerGroupId}`;
  } else {
    return `/provider/${providerUuid}`;
  }
}
