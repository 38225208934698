import { universalRouterProps } from 'core/universalRouter/types';
import { fetchFeatureSelection } from 'store/featureSelection/actions';
import { OBJECT_TYPE, MANAGEMENT } from 'constants/feature';
import { QueryParams } from 'FeatureSelection-Types';

type Location = {
  params: {
    providerId: string;
    extraRateId: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  let promises: Array<Promise<void>> = [];
  const { providerId } = location.params;

  const filter: QueryParams = {
    resourceId: '',
    objectType: OBJECT_TYPE.EXTRA_AGE_RATE,
    management: [MANAGEMENT.EXTRA.BENEFIT.name],
  };
  promises.push(dispatch(fetchFeatureSelection(false, providerId, filter)));

  return Promise.all(promises);
};
