export const filterIdList = [
  'ratePlanStatus',
  'itemStatus',
  'planBenefits',
  'planCategory',
  'cancellationPolicies',
  'designation',
];

export const SELECTED_PLAN = {
  RATE_PLAN: 'ratePlan',
  ITEM_RATE_PLAN: 'itemRatePlan',
};

export const MAX_LIMIT = 999;
