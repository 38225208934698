import { GroupList } from '../FilterBalloon';

export const getSelectedFilterObject = (groups: GroupList[]) => {
  return groups.reduce((filter, group) => {
    const groupId = group.id || 'id';
    const values = filter[groupId] || [];
    group.options.forEach(option => option.isCheck && values.push(option.value));
    filter[groupId] = values;
    return filter;
  }, {} as { [key: string]: string[] });
};
