export type ENUM = { [fieldID: string]: string };

export const LAYER: ENUM = {
  ROOT: '1',
  PARENT: '2',
};

export const ID_TYPE: ENUM = {
  GROUP: 'GROUP',
  FEATURE: 'FEATURE',
};
