import React from 'react';
import { SkeletonListItems } from '@travel/ui';
import SkeletonTable from 'components/SkeletonTable';
import styles from './folderPageSkeleton.module.scss';

type Props = {};

function FolderPageSkeleton(props: Props) {
  return (
    <div className={styles.wrapper} data-testid="folderPageSkeleton-wrapper">
      <section className={styles.sidebar}>
        <SkeletonListItems itemClassName={styles.listItemSkeleton} numberOfItem={3} />
      </section>
      <section className={styles.content}>
        <div className={styles.contentHeader}></div>
        <SkeletonTable className={styles.table} />
      </section>
    </div>
  );
}

export default FolderPageSkeleton;
