import { ApiClient } from '@travel/api-client';
import { Alias, AliasList, ApiStatus, AliasQueryParams, Errors } from 'Aliases-Types';

export const ApiFetchAliases = (http: ApiClient, params: AliasQueryParams): Promise<AliasList> =>
  http
    .get('/extranet-account/member-token/aliases', { values: params })
    .catch((data: Errors) => data);

export const ApiFetchAlias = (http: ApiClient, aliasId: string): Promise<Alias> =>
  http.get(`/extranet-account/member-token/alias/${aliasId}`).catch((data: Errors) => data);

export const ApiCreateAlias = (http: ApiClient, data: Alias): Promise<ApiStatus> =>
  http
    .post('/extranet-account/member-token/alias', { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiUpdateAlias = (http: ApiClient, data: Alias, aliasId: string): Promise<ApiStatus> =>
  http
    .patch(`/extranet-account/member-token/alias/${aliasId}`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiDeleteAlias = (http: ApiClient, aliasName: string): Promise<ApiStatus> =>
  http
    .delete(`/extranet-account/member-token/alias/${aliasName}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
