import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { MediaList, MediaItem, MediaStatus, NewMedia } from 'Media-Types';
import {
  fetchMediaListAsync,
  fetchMediaDetailAsync,
  fetchDeletableMediaAsync,
  putDeletableDialogAsync,
  submitNewMediaAsync,
  uploadNewMediaAsync,
  updateMediaAsync,
  deleteMediaAsync,
  updateMediaBrowseParameter,
  setInitialMedia,
  clearSubmittedMedia,
  storeMediaOnLocal,
  clearMediaOnLocal,
} from './actions';

import {
  initialBrowseParameter,
  initialUploadedMedia,
  initialMediaDetail,
  initialDeletableDialog,
  initialMediaDeletable,
} from './initialValues';

export const isCreating = createReducer(false)
  .handleAction(
    [submitNewMediaAsync.request, uploadNewMediaAsync.request, updateMediaAsync.request],
    () => true,
  )
  .handleAction(
    [
      submitNewMediaAsync.success,
      submitNewMediaAsync.failure,
      uploadNewMediaAsync.success,
      uploadNewMediaAsync.failure,
      updateMediaAsync.success,
      updateMediaAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction(deleteMediaAsync.request, () => true)
  .handleAction([deleteMediaAsync.success, deleteMediaAsync.failure], () => false);

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchMediaListAsync.request, fetchMediaDetailAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchMediaListAsync.success,
      fetchMediaListAsync.failure,
      fetchMediaDetailAsync.success,
      fetchMediaDetailAsync.failure,
    ],
    (state, action) => false,
  );

export const mediaList = createReducer({} as MediaList).handleAction(
  fetchMediaListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const mediaListError = createReducer([] as MediaStatus[]).handleAction(
  fetchMediaListAsync.failure,
  (state, action) => action.payload,
);

export const mediaDetail = createReducer(initialMediaDetail as MediaItem)
  .handleAction(fetchMediaDetailAsync.success, (state, action) => action.payload)
  .handleAction(setInitialMedia, () => initialMediaDetail);

export const mediaDetailError = createReducer([] as MediaStatus[]).handleAction(
  fetchMediaDetailAsync.failure,
  (state, action) => action.payload,
);

export const submitNewMedia = createReducer([] as MediaItem[])
  .handleAction(
    submitNewMediaAsync.success,
    (state, action) => (state = state.concat(action.payload)),
  )
  .handleAction(clearSubmittedMedia, () => []);

export const submitNewMediaError = createReducer([] as MediaStatus[]).handleAction(
  submitNewMediaAsync.failure,
  (state, action) => action.payload,
);

export const uploadNewMedia = createReducer(initialUploadedMedia as NewMedia).handleAction(
  uploadNewMediaAsync.success,
  (state, action) => action.payload,
);

export const uploadNewMediaError = createReducer([] as MediaStatus[]).handleAction(
  uploadNewMediaAsync.failure,
  (state, action) => action.payload,
);

export const updateMedia = createReducer({} as MediaStatus).handleAction(
  updateMediaAsync.success,
  (state, action) => action.payload,
);

export const updateMediaError = createReducer([] as MediaStatus[]).handleAction(
  updateMediaAsync.failure,
  (state, action) => action.payload,
);

export const deletableMedia = createReducer(initialMediaDeletable).handleAction(
  fetchDeletableMediaAsync.success,
  (state, action) => {
    const { payload } = action;
    const updatedPayload = {
      ...payload,
      statusCode: payload.status,
    };
    return updatedPayload;
  },
);

export const localMedia = createReducer([] as MediaItem[])
  .handleAction(storeMediaOnLocal, (state, action) => {
    const { payload } = action;
    return [...state, payload];
  })
  .handleAction(clearMediaOnLocal, (state, action) => {
    return [];
  });

export const deletableDialog = createReducer(initialDeletableDialog).handleAction(
  putDeletableDialogAsync.success,
  (state, action) => action.payload,
);

export const deleteMedia = createReducer({} as MediaStatus).handleAction(
  deleteMediaAsync.success,
  (state, action) => action.payload,
);

export const deleteMediaError = createReducer([] as MediaStatus[]).handleAction(
  deleteMediaAsync.failure,
  (state, action) => action.payload,
);

export const browseParameters = createReducer(initialBrowseParameter).handleAction(
  updateMediaBrowseParameter,
  (state, action) => action.payload,
);

const mediaReducer = combineReducers({
  isFetching,
  mediaList,
  mediaListError,
  mediaDetail,
  mediaDetailError,
  submitNewMedia,
  submitNewMediaError,
  uploadNewMedia,
  uploadNewMediaError,
  updateMedia,
  updateMediaError,
  deletableMedia,
  deletableDialog,
  deleteMedia,
  deleteMediaError,
  browseParameters,
  localMedia,
  isCreating,
  isDeleting,
});

export default mediaReducer;
export type MediaState = ReturnType<typeof mediaReducer>;
