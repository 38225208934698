import { FilterComponent } from 'FilterComponent-Types';

export const initialFilterComponent: FilterComponent = {
  id: '',
  name: '',
  filterGroups: [],
};

export const initialBrowseParameter = {
  keyword: '',
  searchCondition: 'PHRASE',
};
