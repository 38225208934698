import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';

import { as, queryToObject, toQuery } from '@travel/utils';

import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { pushLocation } from 'store/__router/actions';

import {
  AllPagesBody,
  ApiStatus,
  DownLoadParameter,
  Label,
  LabelBody,
  LabelManagementErrors,
  Page,
  PageLabelInformation,
  QueryParameter,
} from 'LabelManagement-Types';
import {
  getAllPages,
  getLabel,
  getLabels,
  getPage,
  getPages,
  postLabel,
  postPage,
  putLabel,
  putPage,
  ApiDownloadLabel,
  ApiUploadLabel,
} from './api';

export const clearLabel = createStandardAction('CLEAR_LABEL')();

export const clearPage = createStandardAction('CLEAR_PAGE')();

export const createPageAsync = createAsyncAction(
  'CREATE_PAGE_REQUEST',
  'CREATE_PAGE_SUCCESS',
  'CREATE_PAGE_FAILURE',
)<undefined, Page, LabelManagementErrors[]>();

export const updatePageAsync = createAsyncAction(
  'UPDATE_PAGE_REQUEST',
  'UPDATE_PAGE_SUCCESS',
  'UPDATE_PAGE_FAILURE',
)<undefined, Page, LabelManagementErrors[]>();

export const fetchPageAsync = createAsyncAction(
  'FETCH_PAGE_REQUEST',
  'FETCH_PAGE_SUCCESS',
  'FETCH_PAGE_FAILURE',
)<undefined, Page, LabelManagementErrors[]>();

export const fetchPagesAsync = createAsyncAction(
  'FETCH_PAGES_REQUEST',
  'FETCH_PAGES_SUCCESS',
  'FETCH_PAGES_FAILURE',
)<undefined, AllPagesBody, LabelManagementErrors[]>();

export const fetchAllPagesAsync = createAsyncAction(
  'FETCH_ALL_PAGES_REQUEST',
  'FETCH_ALL_PAGES_SUCCESS',
  'FETCH_ALL_PAGES_FAILURE',
)<undefined, AllPagesBody, LabelManagementErrors[]>();

export const fetchAllLabelsAsync = createAsyncAction(
  'FETCH_ALL_LABEL_REQUEST',
  'FETCH_ALL_LABEL_SUCCESS',
  'FETCH_ALL_LABEL_FAILURE',
)<undefined, PageLabelInformation, LabelManagementErrors[]>();

export const fetchLabelDetailAsync = createAsyncAction(
  'FETCH_LABEL_DETAIL_REQUEST',
  'FETCH_LABEL_DETAIL_SUCCESS',
  'FETCH_LABEL_DETAIL_FAILURE',
)<undefined, Label, LabelManagementErrors[]>();

export const updateLabelDetailAsync = createAsyncAction(
  'UPDATE_LABEL_REQUEST',
  'UPDATE_LABEL_SUCCESS',
  'UPDATE_LABEL_FAILURE',
)<undefined, Label[], LabelManagementErrors[]>();

export const createLabelAsync = createAsyncAction(
  'CREATE_LABEL_REQUEST',
  'CREATE_LABEL_SUCCESS',
  'CREATE_LABEL_FAILURE',
)<undefined, Label[], LabelManagementErrors[]>();

export const downloadLabelAsync = createAsyncAction(
  'DOWNLOAD_LABEL_REQUEST',
  'DOWNLOAD_LABEL_SUCCESS',
  'DOWNLOAD_LABEL_FAILURE',
)<undefined, Object, LabelManagementErrors[]>();

export const uploadLabelAsync = createAsyncAction(
  'UPLOAD_LABEL_REQUEST',
  'UPLOAD_LABEL_SUCCESS',
  'UPLOAD_LABEL_FAILURE',
)<undefined, ApiStatus, LabelManagementErrors[]>();

const getPath = (product: string): string => {
  switch (product) {
    case 'SEO':
      return 'seoLabelManagement';
    case 'Traveler':
      return 'travelerLabelManagement';
    case 'Traveler_Common':
      return 'tvlrCommonLabelManagement';
    case 'DP':
      return 'dpLabelManagement';
    case 'MyPage':
      return 'myPageLabelManagement';
    case 'TravelApp':
      return 'travelAppLabelManagement';
    default:
      return 'labelManagement';
  }
};

export const createPage = (data: Page): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(createPageAsync.request());
    const response = await postPage(apiClient, data);
    dispatch(createPageAsync.success(response));
    dispatch(pushLocation(`/internal/${getPath(data.product)}/${response.pageId}`));
    dispatch(fetchGlobalErrorDialogAsync.success(response));
  } catch (err) {
    dispatch(createPageAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const fetchPage = (pageId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchPageAsync.request());
  const response = await getPage(apiClient, pageId);
  dispatch(fetchPageAsync.success(response));
};

export const updatePage = (data: Page, stateQuery?: { prevSearch: string }): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    const state = getState();
    const pageId = state.labelManagement.page.pageId;
    dispatch(updatePageAsync.request());
    const response = await putPage(apiClient, data, pageId || '');
    dispatch(updatePageAsync.success(response));
    dispatch(fetchGlobalErrorDialogAsync.success(response));
    dispatch(
      pushLocation({ pathname: `/internal/${getPath(data.product)}/${pageId}`, state: stateQuery }),
    );
  } catch (err) {
    dispatch(updatePageAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const fetchPages = (query: QueryParameter): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchPagesAsync.request());
  const queryObject = queryToObject(query);
  const updatedQuery = {
    ...queryObject,
    'filter.pageStatus': queryObject.filter.pageStatus || 'active',
    sort: queryObject.sort || { pageName: 'asc' },
  };
  const response = await getPages(apiClient, toQuery(updatedQuery));
  dispatch(fetchPagesAsync.success(response));
};

export const fetchAllLabels = (pageId: string, query: QueryParameter): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchAllLabelsAsync.request());
  const queryObject = queryToObject(query);
  const updatedQuery = {
    ...queryObject,
    filter: queryObject.filter || { labelStatus: ['active'], langCode: '' },
  };
  const response = await getLabels(apiClient, pageId, toQuery(updatedQuery));
  dispatch(fetchAllLabelsAsync.success(response));
};

export const fetchLabelDetail = (pageId: string, labelId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchLabelDetailAsync.request());
  const response = await getLabel(apiClient, pageId, labelId);
  dispatch(fetchLabelDetailAsync.success(response));
};

const formatLabelData = ({
  conditions,
  labelId,
  labelName,
  note,
  status,
  updateZonedDateTime,
}: Label): LabelBody => {
  const conditionValues = conditions.map(item => {
    let labelCondition = '';
    if (item.condition) {
      if (
        !item.condition.includes('count') &&
        !item.condition.includes('exist') &&
        !item.condition.includes('none') &&
        !item.condition.includes('status ==')
      ) {
        labelCondition = `status == ${item.condition}`;
      } else {
        labelCondition = item.condition;
      }
    } else {
      labelCondition = 'none';
    }
    item.condition = labelCondition;
    return item;
  });
  return {
    conditions: conditionValues,
    labelId,
    labelName,
    note,
    status,
    updateZonedDateTime,
  };
};

export const createLabel = (labelData: Label, pageId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(createLabelAsync.request());
    const formattedData = formatLabelData(labelData);
    const response = await postLabel(apiClient, formattedData, pageId);
    const state = getState();
    const path = getPath(state.labelManagement.page.product);
    dispatch(createLabelAsync.success(response));
    dispatch(pushLocation(`/internal/${path}/${pageId}/labels/${response.labelId}`));
    dispatch(fetchGlobalErrorDialogAsync.success(response[0]));
  } catch (err) {
    dispatch(createLabelAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const updateLabel = (labelData: Label, pageId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    const state = getState();
    const labelId = state.labelManagement.label.labelId;
    const path = getPath(state.labelManagement.page.product);
    dispatch(updateLabelDetailAsync.request());
    const formattedData = formatLabelData(labelData);
    const response = await putLabel(apiClient, formattedData, pageId, labelId);
    dispatch(updateLabelDetailAsync.success(response));
    dispatch(pushLocation(`/internal/${path}/${pageId}/labels/${labelId}`));
    dispatch(fetchGlobalErrorDialogAsync.success(response[0]));
  } catch (err) {
    dispatch(updateLabelDetailAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const fetchAllPages = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(fetchAllPagesAsync.request());
    const response = await getAllPages(apiClient);
    dispatch(fetchAllPagesAsync.success(response));
  } catch (err) {
    dispatch(fetchAllPagesAsync.failure(err));
  }
};

export const downloadLabel = (filter: DownLoadParameter): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(downloadLabelAsync.request());
  const res = await ApiDownloadLabel(apiClient, filter);
  try {
    const blob = await res.blob(apiClient);
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `manual-translation-labels-${new Date().getTime()}.zip`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  } catch (error) {
    console.error(error);
    dispatch(downloadLabelAsync.failure(error));
  }
  dispatch(downloadLabelAsync.success(res));
};

export const uploadLabel = (file: File): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(uploadLabelAsync.request());

  const formData = new FormData();
  formData.append('translation', file, file.name);

  try {
    const res = await ApiUploadLabel(apiClient, formData);

    dispatch(uploadLabelAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(res)));
  } catch (error) {
    if (error.commonErrorHandler) {
      error.commonErrorHandler();
    }

    dispatch(uploadLabelAsync.failure([error]));
    dispatch(
      fetchGlobalErrorDialogAsync.success(
        as<ApiStatus>({ status: error.status, message: error.error }),
      ),
    );
  }
};
