import { ApiClient } from '@travel/api-client';

import {
  ApiStatus,
  ChangePasswordWithOneTimeKey,
  Credentials,
  CurrentStageProviderDetail,
  ForgotPasswordData,
  JumpToV1DetailParams,
  JumpToV1TopParams,
  LoginStatus,
  ResetPasswordPreData,
  SetV1TokenParams,
  SwitchUserValues,
  TokenInfo,
} from 'Login-Types';

export const validateCredentials = (
  http: ApiClient,
  credentials: Credentials,
): Promise<LoginStatus> =>
  http.post(`/extranet/authorize`, { values: credentials, ignoreCommonErrorHandler: true });

export const sendKeyToEmail = (
  http: ApiClient,
  data: ForgotPasswordData,
  ignoreCommonErrorHandler: boolean = true,
): Promise<LoginStatus> =>
  http.post(`/extranet-account/member-password/password/forgot`, {
    values: data,
    ignoreCommonErrorHandler,
  });

export const validateKey = (http: ApiClient, oneTimeKey: string): Promise<LoginStatus> =>
  http.patch(`/extranet-account/member-password/password/oneTimeKey`, {
    values: { oneTimeKey },
    ignoreCommonErrorHandler: true,
  });

export const changePasswordWithOneTimeKey = (
  http: ApiClient,
  data: ChangePasswordWithOneTimeKey,
): Promise<LoginStatus> =>
  http.patch(`/extranet-account/member-password/password/changeWithOneTimeKey`, {
    values: data,
    ignoreCommonErrorHandler: true,
  });

export const changeExpiredPassword = (
  http: ApiClient,
  data: ResetPasswordPreData,
): Promise<LoginStatus> =>
  http.patch(`/extranet-account/member-password/password/changeExpired`, {
    values: data,
    ignoreCommonErrorHandler: true,
  });

export const refreshToken = (http: ApiClient): Promise<LoginStatus> =>
  http.post('/extranet-refresh/refresh', { ignoreCommonErrorHandler: true });

export const switchUserToken = (http: ApiClient, values: SwitchUserValues): Promise<LoginStatus> =>
  http.post(`/extranet-refresh/switch`, {
    values,
    ignoreCommonErrorHandler: true,
  });

export const ApiLogout = (http: ApiClient): Promise<LoginStatus> =>
  http.get('/extranet-account/member-token/logout', { ignoreCommonErrorHandler: true });

export const ApiFetchCurrentStageProvider = (
  http: ApiClient,
  currentStageProviderId?: string,
): Promise<CurrentStageProviderDetail> =>
  http.get(`/extranet-account/member-password/verify/currentstage/${currentStageProviderId}`);

export const ApiJumpToV1Detail = (
  http: ApiClient,
  params: JumpToV1DetailParams,
): Promise<ApiStatus> =>
  http
    .post(`/current-extranet-details/currentStageDetails`, { values: params })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiJumpToV1Top = (http: ApiClient): Promise<ApiStatus> =>
  http
    .post(`/return-extranet/returnToCurrentTop`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiJumpToV1TopFromDialog = (
  http: ApiClient,
  params: JumpToV1TopParams,
): Promise<ApiStatus> =>
  http
    .post(`/return-extranet-top/backToExtranet`, { values: params })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiVerifyToken = (http: ApiClient, token?: string): Promise<TokenInfo> =>
  http.get('/extranet/verifyToken', {
    headers: {
      Authorization: token,
    },
    ignoreCommonErrorHandler: true,
  });

export const ApiSetV1Token = (http: ApiClient, params: SetV1TokenParams): Promise<ApiStatus> =>
  http.get('https://manage.travel.rakuten.co.jp/auth-extranet-users/currentStage/setToken', {
    values: params,
  });
