import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  AnnouncementList,
  AnnouncementDetail,
  AnnouncementErrors,
  UploadResult,
} from 'Announcement-Types';
import {
  fetchAnnouncementListAsync,
  fetchAnnouncementDetailAsync,
  deleteAnnouncementAsync,
  createAnnouncementAsync,
  clearAnnouncement,
  updateAnnouncementAsync,
  uploadFileAsync,
} from './actions';
import initialValues from './initialValues';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchAnnouncementListAsync.request, fetchAnnouncementDetailAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchAnnouncementListAsync.success,
      fetchAnnouncementListAsync.failure,
      fetchAnnouncementDetailAsync.success,
      fetchAnnouncementDetailAsync.failure,
    ],
    () => false,
  );
export const isCreating = createReducer(false)
  .handleAction([createAnnouncementAsync.request, updateAnnouncementAsync.request], () => true)
  .handleAction(
    [
      createAnnouncementAsync.success,
      createAnnouncementAsync.failure,
      updateAnnouncementAsync.success,
      updateAnnouncementAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction(deleteAnnouncementAsync.request, () => true)
  .handleAction([deleteAnnouncementAsync.success, deleteAnnouncementAsync.failure], () => false);

export const announcementList = createReducer({} as AnnouncementList).handleAction(
  fetchAnnouncementListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      announcements:
        state.announcements && shouldConcat
          ? state.announcements.concat(action.payload.announcements)
          : action.payload.announcements,
    };
  },
);

export const uploadResult = createReducer({} as UploadResult).handleAction(
  uploadFileAsync.success,
  (state, action) => action.payload,
);

export const isUploading = createReducer(false)
  .handleAction(uploadFileAsync.request, () => true)
  .handleAction([uploadFileAsync.success, uploadFileAsync.failure], () => false);

export const announcementDetail = createReducer(initialValues as AnnouncementDetail)
  .handleAction(fetchAnnouncementDetailAsync.success, (state, action) => action.payload)
  .handleAction(clearAnnouncement, (state, action) => initialValues);

export const errors = createReducer([] as AnnouncementErrors[]).handleAction(
  [fetchAnnouncementDetailAsync.failure, fetchAnnouncementListAsync.failure],
  (state, action) => action.payload,
);

const announcementReducer = combineReducers({
  isFetching,
  isDeleting,
  isCreating,
  announcementList,
  announcementDetail,
  uploadResult,
  isUploading,
  errors,
});

export default announcementReducer;
export type AnnouncementState = ReturnType<typeof announcementReducer>;
