import React from 'react';
import Grid, { GridBody, GridCell, GridHead, GridRow } from 'components/Grid';
import { cx } from '@travel/utils';
import SimpleSkeletonItem from '@travel/ui/components/SkeletonListItems/components/SimpleSkeletonItem';
import styles from './skeletonTable.module.scss';

type Props = {
  className: string;
  numOfCol?: number;
  numOfRow?: number;
};

function SkeletonTable(props: Props) {
  const { className, numOfCol = 7, numOfRow = 4 } = props;

  const renderGridHeadCells = () => {
    return [...Array(numOfCol)].map((_, i) => <GridCell key={i}>{''}</GridCell>);
  };

  const renderGridBodyRows = () => {
    return [...Array(numOfRow)].map((_, i) => (
      <GridRow key={i}>
        <td colSpan={numOfCol}>
          <SimpleSkeletonItem contentClassName={styles.skeletonContent} />
        </td>
      </GridRow>
    ));
  };

  return (
    <div className={cx(className, styles.wrapper)} data-testid="skeletonTable-wrapper">
      <Grid>
        <GridHead>
          <GridRow>{renderGridHeadCells()}</GridRow>
        </GridHead>
        <GridBody>{renderGridBodyRows()}</GridBody>
      </Grid>
    </div>
  );
}

SkeletonTable.defaultProps = {
  className: '',
};

export default SkeletonTable;
