import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Menu as IconMenu, NavbarHeaderHamburger as Hamburger } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { FlatButton, IconTextLink } from '@travel/ui';
import { cx } from '@travel/utils';

import { UserScopeType } from 'utils/userScopeHelper';

import { returnToInternal, switchUser } from 'store/login/actions';

import { getIsInternalSwitchUser, getIsSwitchUser } from 'utils';

import { getVerifyTokenDetail } from 'store/login/selectors';

import styles from './navigationMenu.module.scss';

type Props = {
  name?: string;
  id: string;
  basePath: string;
  userScope: UserScopeType;
  onCloseMenu: () => void;
  isNotLoginPage: boolean;
  isMenuOpen?: boolean;
};

type BackToExtranetProps = {
  name: ReactNode;
  userScope: UserScopeType;
  isMenuOpen?: boolean;
};

function BackToExtranet(props: BackToExtranetProps) {
  const dispatch = useDispatch();
  const { userScope, isMenuOpen } = props;
  const isInternalSwitchUser = getIsInternalSwitchUser(userScope);
  const tokenInfo = useSelector(getVerifyTokenDetail);
  const returnInternal = <Translate id="Extranet_Header.Back_To_Internal_Tool" />;
  const returnToGroupExtranet = <Translate id="Extranet_Header.Back_To_Group_Extranet" />;

  const backToExtranet = () => {
    if (isInternalSwitchUser) {
      dispatch(returnToInternal());
    } else {
      dispatch(switchUser(undefined, tokenInfo.providerGroupId)); // return to Group
    }
  };

  return (
    <>
      {isMenuOpen && (
        <FlatButton
          classType={'secondary'}
          onClick={backToExtranet}
          className={styles.returnBtn}
          data-testid="backToExtranet"
        >
          {isInternalSwitchUser ? returnInternal : returnToGroupExtranet}
        </FlatButton>
      )}
    </>
  );
}

function MenuHeader(props: Props) {
  const { name, id, userScope, isNotLoginPage, onCloseMenu, isMenuOpen } = props;
  const isSwitchUser = getIsSwitchUser(userScope);
  return (
    <div data-locator-id="menuHeader-Wrapper">
      <div className={cx(styles.rootNav)}>
        <div className={cx(styles.headSection)}>
          <div className={styles.subHeader}>
            {isNotLoginPage && (
              <>
                {!isMenuOpen ? (
                  <IconTextLink
                    className={styles.menuButton}
                    onClick={onCloseMenu}
                    icon={<IconMenu className={styles.menuIcon} size={24} />}
                    text=""
                    hoveredColor="currentColor"
                    unhoveredColor="currentColor"
                  />
                ) : (
                  <IconTextLink
                    onClick={onCloseMenu}
                    className={styles.closeMenuIcon}
                    icon={<Hamburger className={styles.menuIcon} size={24} />}
                    text=""
                    hoveredColor="currentColor"
                    unhoveredColor="currentColor"
                  />
                )}
              </>
            )}
            <div className={cx(styles.suspendedContent, styles.providerHeader)}>
              <div className={styles.heading}>
                <p>{name}</p>
              </div>
              {id && (
                <p className={styles.provider}>
                  <Translate id="Extranet_Header.ID" />: {id}
                </p>
              )}
            </div>
          </div>
        </div>
        {isSwitchUser && (
          <BackToExtranet name={name} userScope={userScope} isMenuOpen={isMenuOpen} />
        )}
        <div className={styles.divider} />
      </div>
    </div>
  );
}

export default MenuHeader;
