import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { QrCodeGeneratorErrors } from 'QrCodeGenerator-Types';
import { fetchQrCodeGeneratorAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchQrCodeGeneratorAsync.request], (state, action) => true)
  .handleAction(
    [fetchQrCodeGeneratorAsync.success, fetchQrCodeGeneratorAsync.failure],
    (state, action) => false,
  );

export const errors = createReducer([] as QrCodeGeneratorErrors[]).handleAction(
  fetchQrCodeGeneratorAsync.failure,
  (state, action) => action.payload,
);

const qrcodegeneratorReducer = combineReducers({
  isFetching,
  errors,
});

export default qrcodegeneratorReducer;
export type QrCodeGeneratorState = ReturnType<typeof qrcodegeneratorReducer>;
