import React from 'react';
import { BrowseParameters } from 'SalesPromotionList-Types';
import { Translate } from 'core/translate';

export const initialBrowseParameter: BrowseParameters = {
  keyword: '',
  travelPeriodFrom: '',
  travelPeriodTo: '',
  promotionPeriodFrom: '',
  promotionPeriodTo: '',
  filter: [
    {
      label: <Translate id="Provider_Cost_Sales_Promotion.List.Filter.Status" />,
      id: 'status',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Status.Promotion.Ready" />,
          value: 'READY',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Promotion.Not_Ready" />,
          value: 'UNREADY',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Promotion.Running" />,
          value: 'RUNNING',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Promotion.Paused" />,
          value: 'PAUSED',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Promotion.Expired" />,
          value: 'EXPIRED',
          isCheck: false,
        },
      ],
    },
  ],
  sort: {
    options: [
      {
        text: <Translate id="Supplier_Enumeration.Sort.Creation_Date.New_Old" />,
        value: 'createDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Creation_Date.Old_New" />,
        value: 'createDateTime=asc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.New_Old" />,
        value: 'updateDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.Old_New" />,
        value: 'updateDateTime=asc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Sales_Promotion_Start_Date.New_Old" />,
        value: 'startDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Sales_Promotion_Start_Date.Old_New" />,
        value: 'startDateTime=asc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Sales_Promotion_End_Date.New_Old" />,
        value: 'endDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Sales_Promotion_End_Date.Old_New" />,
        value: 'endDateTime=asc',
      },
    ],
    selectedValue: 'createDateTime=desc',
  },
  offset: 0,
  limit: 30,
};

export const initialPromotion = {
  active: true,
  analytics: null,
  applicablePlans: [],
  code: '',
  createDateTime: '',
  description: '',
  designations: ['STANDARD', 'LUXURY'],
  discount: null,
  effectivePeriod: null,
  featureGroups: [],
  id: null,
  incentiveType: '',
  name: '',
  privateTitle: '',
  paymentMethods: [],
  point: null,
  promotionBudget: {},
  promotionCap: {},
  searchCondition: {},
  status: 'READY',
  updateDateTime: '',
  userCondition: null,
  platformCondition: null,
  applyForNewPlan: false,
  incentive: '',
  representativeFeature: null,
};

export const initialPromotionDeletable = {
  message: '',
  statusCode: null,
};

export const initialDeletableDialog = {
  isShow: false,
};
