import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { toDecodedQuery, toQuery } from '@travel/utils';
import {
  VisualContentGroupList,
  VisualContentGroup,
  VisualContentGroupStatus,
} from 'VisualContentGroup-Types';
import {
  ApiFetchVisualContentGroupList,
  ApiFetchVisualContentGroup,
  ApiPostVisualContentGroup,
  ApiPutVisualContentGroup,
  ApiDeleteVisualContentGroup,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';

export const fetchVisualContentGroupListAsync = createAsyncAction(
  'FETCH_VISUAL_CONTENT_GROUP_LIST_REQUEST',
  'FETCH_VISUAL_CONTENT_GROUP_LIST_SUCCESS',
  'FETCH_VISUAL_CONTENT_GROUP_LIST_FAILURE',
)<undefined, VisualContentGroupList, VisualContentGroupStatus[]>();

export const fetchVisualContentGroupAsync = createAsyncAction(
  'FETCH_VISUAL_CONTENT_GROUP_REQUEST',
  'FETCH_VISUAL_CONTENT_GROUP_SUCCESS',
  'FETCH_VISUAL_CONTENT_GROUP_FAILURE',
)<undefined, VisualContentGroup, VisualContentGroupStatus[]>();

export const postVisualContentGroupAsync = createAsyncAction(
  'POST_VISUAL_CONTENT_GROUP_REQUEST',
  'POST_VISUAL_CONTENT_GROUP_SUCCESS',
  'POST_VISUAL_CONTENT_GROUP_FAILURE',
)<undefined, VisualContentGroupStatus, VisualContentGroupStatus[]>();

export const putVisualContentGroupAsync = createAsyncAction(
  'PUT_VISUAL_CONTENT_GROUP_REQUEST',
  'PUT_VISUAL_CONTENT_GROUP_SUCCESS',
  'PUT_VISUAL_CONTENT_GROUP_FAILURE',
)<undefined, VisualContentGroupStatus, VisualContentGroupStatus[]>();

export const deleteVisualContentGroupAsync = createAsyncAction(
  'DELETE_VISUAL_CONTENT_GROUP_REQUEST',
  'DELETE_VISUAL_CONTENT_GROUP_SUCCESS',
  'DELETE_VISUAL_CONTENT_GROUP_FAILURE',
)<undefined, VisualContentGroupStatus, VisualContentGroupStatus[]>();

export const setInitialVisualContentGroup = createStandardAction(
  'SET_INITIAL_VISUAL_CONTENT_GROUP',
)();

export const fetchVisualContentGroupList = (
  providerId: string,
  query: { [key: string]: string | number } = {},
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchVisualContentGroupListAsync.request());
  try {
    const res = await ApiFetchVisualContentGroupList(apiClient, transformQuery(query), providerId);
    dispatch(fetchVisualContentGroupListAsync.success(res));
  } catch (errors) {
    dispatch(fetchVisualContentGroupListAsync.failure([errors as VisualContentGroupStatus]));
  }
};

export const fetchVisualContentGroup = (
  visualContentGroupId: string,
  providerId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(fetchVisualContentGroupAsync.request());
    const res = await ApiFetchVisualContentGroup(apiClient, visualContentGroupId, providerId);
    dispatch(fetchVisualContentGroupAsync.success(res));
  } catch (errors) {
    dispatch(fetchVisualContentGroupAsync.failure([errors as VisualContentGroupStatus]));
  }
};

export const postVisualContentGroup = (
  providerId: string,
  data: VisualContentGroup,
  baseUrl: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(postVisualContentGroupAsync.request());
    const res = await ApiPostVisualContentGroup(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(postVisualContentGroupAsync.success(res));
    if (res.id) {
      dispatch(pushLocation(`${baseUrl}/visualContentGroup/${res.id}`));
    }
  } catch (errors) {
    dispatch(postVisualContentGroupAsync.failure([errors as VisualContentGroupStatus]));
  }
};

export const putVisualContentGroup = (
  providerId: string,
  data: VisualContentGroup,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(putVisualContentGroupAsync.request());
    const res = await ApiPutVisualContentGroup(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putVisualContentGroupAsync.success(res));
  } catch (errors) {
    dispatch(putVisualContentGroupAsync.failure([errors as VisualContentGroupStatus]));
  }
};

export const deleteVisualContentGroup = (
  providerId: string,
  name: string,
  id?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteVisualContentGroupAsync.request());
  try {
    const res = await ApiDeleteVisualContentGroup(apiClient, providerId, id);
    res.message = name;
    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteVisualContentGroupAsync.success(res));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
    dispatch(deleteVisualContentGroupAsync.success(err));
  }
};

function transformQuery(query: { [key: string]: string | number }) {
  if (!query) return toQuery({});
  const { searchText, keyword, limit, offset, searchCondition, ...rest } = query;

  const builtQuery = {
    filter: {
      key: searchText,
      ...rest,
    },
    keyword,
    limit: limit || 30,
    offset: offset || 0,
    searchCondition,
  };

  return toDecodedQuery(builtQuery);
}
