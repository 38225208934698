import {
  Inventory,
  ItemDetail,
  ApiStatus,
  MasterGroupList,
  BulkDetails,
  CreateFeatureGroup,
} from 'Inventory-Types';
import providerPrefix from 'constants/api-prefix';
import { ApiClient } from '@travel/api-client';

export const ApiFetchMasterGroup = (
  http: ApiClient,
  providerId: string,
): Promise<MasterGroupList> => http.get(`${providerPrefix}/${providerId}/features/presetGroups`);

export const ApiBulkUpdateFeatures = (
  http: ApiClient,
  providerId: string,
  data: BulkDetails,
): Promise<ApiStatus> => {
  return http
    .post(`${providerPrefix}/${providerId}/features/references/bulk`, {
      values: data,
    })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiCreateFeatureGroup = (
  http: ApiClient,
  providerId: string,
  data: CreateFeatureGroup,
): Promise<ApiStatus> => {
  return http
    .post(`${providerPrefix}/${providerId}/features/presetGroup`, {
      values: data,
    })
    .then((data: ApiStatus) => data);
};

export const ApiFetchInventoryList = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<Inventory> =>
  http
    .get(`${providerPrefix}/${providerId}/items`, { values: params })
    .then((data: Inventory) => data);

export const ApiFetchItemDetail = (
  http: ApiClient,
  providerId: string,
  itemId: string,
): Promise<ItemDetail> =>
  http.get(`${providerPrefix}/${providerId}/items/${itemId}`).then((data: ItemDetail) => data);

export const ApiSubmitNewItem = (
  http: ApiClient,
  providerId: string,
  data: ItemDetail,
): Promise<ApiStatus> => {
  return http
    .post(`${providerPrefix}/${providerId}/items`, { values: { providerId: providerId, ...data } })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiUpdateItem = (
  http: ApiClient,
  providerId: string,
  data: ItemDetail,
): Promise<ApiStatus> => {
  return http
    .put(`${providerPrefix}/${providerId}/items/${data.id}`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiDeleteItem = (
  http: ApiClient,
  providerId: string,
  itemId: string,
): Promise<ApiStatus> => {
  return http
    .delete(`${providerPrefix}/${providerId}/items/${itemId}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};
