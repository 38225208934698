import { universalRouterProps } from 'core/universalRouter/types';
import { fetchCancelPolicyList } from 'store/cancelPolicy/actions';

export type URLParams = {
  params: { providerId: string };
  query: { [key: string]: string };
};

export default (
  { store: { dispatch } }: universalRouterProps,
  { params: { providerId }, query }: URLParams,
) => {
  const encodedQuery = {
    ...query,
    keyword: encodeURIComponent(query.keyword || ''),
  };
  return Promise.all([dispatch(fetchCancelPolicyList(providerId, encodedQuery))]);
};
