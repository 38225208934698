import { GotoPromotion_UI } from 'GoToPromotion-Types';
import {
  RepFeature,
  SalesPromotionStatus,
  Discount,
  PromotionCap,
  PromotionBudget,
} from 'SalesPromotion-Types';

export const defaultPromotionDetail: GotoPromotion_UI = {
  active: true,
  analytics: null,
  applicablePlans: [],
  code: '',
  createDateTime: '',
  currency: 'JPY', // fixed
  description: '',
  designations: ['LUXURY'], // fixed
  discount: {} as Discount,
  effectivePeriod: null,
  id: null,
  incentive: null,
  incentiveType: 'DISCOUNT',
  offer: {
    offerType: 'COUPON_WITHOUT_CODE', // fixed
    offer: [],
  },
  participationExclusive: false,
  paymentMethods: ['ON_SITE_ECO', 'ON_SITE_ONLY', 'AGENT_ECO', 'AGENT_ONLY'],
  platformCondition: {},
  privateTitle: null,
  promotionBudget: {} as PromotionBudget,
  promotionCap: {} as PromotionCap,
  promotionClass: 'GOTO', // fixed
  representativeFeature: {} as RepFeature,
  searchCondition: null,
  status: '' as SalesPromotionStatus,
  timeZone: 'Asia/Tokyo', // fixed
  updateDateTime: '',
  userCondition: {
    rakutenMembershipRequired: true,
    prohibitedPrefectureCodes: [],
  },
};

export const defaultPromotionDeletable = {
  message: '',
  statusCode: null,
};

export const defaultDeletableDialog = {
  isShow: false,
};
