import { universalRouterProps } from 'core/universalRouter/types';
import { fetchExtraRateList } from 'store/extraRate/actions';
import { RATE_UNIT } from 'constants/rate-plan';

type Query = { [key: string]: string };
type Location = {
  params: {
    providerId: string;
  };
  query: Query;
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  const { query, params } = location;
  const { providerId } = params;
  const rateUnitType = query['tabFilter'];
  if (rateUnitType !== RATE_UNIT.PER_PERSON && rateUnitType !== RATE_UNIT.PER_ITEM) {
    query['tabFilter'] = RATE_UNIT.PER_PERSON;
  }

  const promises = [dispatch(fetchExtraRateList(providerId, query))];
  return Promise.all(promises);
};
