import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { DiagnosisErrors, DiagnosisAvailability } from 'Diagnosis-Types';
import { createDiagnosisAvailabilityAsync, clearDiagnosisAvailability } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([createDiagnosisAvailabilityAsync.request], (state, action) => true)
  .handleAction(
    [createDiagnosisAvailabilityAsync.success, createDiagnosisAvailabilityAsync.failure],
    (state, action) => false,
  );

export const availability = createReducer({} as DiagnosisAvailability)
  .handleAction(createDiagnosisAvailabilityAsync.success, (state, action) => action.payload)
  .handleAction(clearDiagnosisAvailability, () => ({} as DiagnosisAvailability));

export const errors = createReducer([] as DiagnosisErrors[]).handleAction(
  createDiagnosisAvailabilityAsync.failure,
  (state, action) => action.payload,
);

const diagnosisAvailabilityReducer = combineReducers({
  isFetching,
  availability,
  errors,
});

export default diagnosisAvailabilityReducer;
export type DiagnosisAvailabilityState = ReturnType<typeof diagnosisAvailabilityReducer>;
