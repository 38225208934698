import { universalRouterProps } from 'core/universalRouter/types';
import { AnnouncementListParams } from 'Announcement-Types';
import { fetchAnnouncementList } from 'store/announcement/actions';
import { initialListQuery } from 'store/announcement/initialValues';
import { toDecodedQuery } from '@travel/utils';

export type URLParams = { query: AnnouncementListParams };

export default ({ store: { dispatch } }: universalRouterProps, { query }: URLParams) => {
  const queryParams =
    query && Object.keys(query).length
      ? {
          ...query,
          offset: 0,
          limit: 30,
        }
      : toDecodedQuery(initialListQuery);

  return Promise.all([dispatch(fetchAnnouncementList(queryParams))]);
};
