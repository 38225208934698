import {
  HighlightContent,
  HighlightContentArticle,
  HighlightContentStatus,
} from 'HighlightContent-Types';
import { ApiClient } from '@travel/api-client';
import { providerPrefix } from 'constants/api-prefix';

export const ApiFetchHighlightContent = (
  http: ApiClient,
  params: Object,
  providerId: string,
): Promise<HighlightContent> => {
  const url = `${providerPrefix}/${providerId}/media/articles`;
  return http.get(url, { values: params }).then((data: HighlightContent) => data);
};

export const ApiFetchHighlightContentArticle = (
  http: ApiClient,
  providerId: string,
  articleId: string,
): Promise<HighlightContentArticle> => {
  const url = `${providerPrefix}/${providerId}/media/articles/${articleId}`;
  return http.get(url).then((data: HighlightContentArticle) => data);
};

export const ApiPostHighlightContent = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<HighlightContentStatus> => {
  const url = `${providerPrefix}/${providerId}/media/articles`;
  return http
    .post(url, {
      values: {
        ...params,
        type: 'HIGHLIGHT',
      },
    })
    .then((data: HighlightContentStatus) => data)
    .catch((data: HighlightContentStatus) => data);
};

export const ApiPutHighlightContent = (
  http: ApiClient,
  providerId: string,
  params: HighlightContentArticle,
): Promise<HighlightContentStatus> => {
  const url = `${providerPrefix}/${providerId}/media/articles/${params.id}`;
  return http
    .put(url, {
      values: {
        ...params,
        type: 'HIGHLIGHT',
      },
    })
    .then((data: HighlightContentStatus) => data)
    .catch((data: HighlightContentStatus) => data);
};

export const ApiDeleteHighlightContent = (
  http: ApiClient,
  providerId: string,
  articleId: string,
): Promise<HighlightContentStatus> => {
  const url = `${providerPrefix}/${providerId}/media/articles/${articleId}`;
  return http.delete(url, {}).then((data: HighlightContentStatus) => data);
};

export const ApiPutHighlightContentArticles = (
  http: ApiClient,
  providerId: string,
  params: HighlightContentArticle[],
): Promise<HighlightContentStatus> => {
  const url = `${providerPrefix}/${providerId}/media/articles`;
  return http
    .put(url, {
      values: [...params],
    })
    .then((data: HighlightContentStatus) => data)
    .catch((data: HighlightContentStatus) => data);
};
