import { clearCurrentCampaign, fetchCampaign } from 'store/campaign/actions';
import { fetchApplications } from 'store/applicationTracker/actions';
import { fetchAllChannelDistributors } from 'store/channelDistributor/actions';
import { fetchCurrencies } from 'store/currencies/actions';
import { universalRouterProps } from 'core/universalRouter/types';
import { clearLastUsedValue, fetchLastUsedValue } from 'store/lastUsedValue/actions';
import { fetchUserSegments, fetchSelectedUserSegment } from 'store/userSegment/actions';
import { initFilterState } from 'store/userSegment/initialValues';
import { fetchAllFeatureGroups } from 'store/featureSelection/actions';
import { MANAGEMENT, OBJECT_TYPE } from 'constants/feature';
import { PAGE_ID } from 'constants/lastUsedValue';

type Location = {
  params: {
    campaignId?: string;
  };
};

export default ({ store: { dispatch, getState } }: universalRouterProps, location: Location) => {
  const { campaignId } = location.params;
  const userUuid = getState().accessControl.loggedInUser?.userUuid;

  const featureQueryParams = {
    objectType: OBJECT_TYPE.SALES_PROMOTION,
    management: [MANAGEMENT.SALES_PROMOTION.CAMPAIGN.name],
  };
  const applicationFilter = { filter: { status: [] }, keyword: '' };

  const promises = [
    dispatch(fetchAllChannelDistributors()),
    dispatch(fetchCurrencies()),
    dispatch(fetchUserSegments(initFilterState)),
    dispatch(fetchAllFeatureGroups('', featureQueryParams)),
  ];

  if (campaignId) {
    promises.push(
      dispatch(fetchCampaign(campaignId)).then(data => {
        dispatch(fetchSelectedUserSegment({ segmentIds: data?.userCondition?.userSegments || [] }));
      }),
      dispatch(fetchApplications(campaignId, applicationFilter)),
    );
    dispatch(clearLastUsedValue());
  } else {
    promises.push(dispatch(fetchLastUsedValue({ type: PAGE_ID.SPONSOR_SP, userUuid })));
    dispatch(clearCurrentCampaign());
  }

  return Promise.all(promises);
};
