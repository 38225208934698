import React from 'react';
import cx from '@travel/ui/utils/classnames';
import styles from './gridHead.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

function GridHead(props: Props) {
  const { className } = props;

  return (
    <thead
      data-locator-id="GridHead-29f47b33-c191-4112-834f-a411f24d8bc5"
      className={cx(className, styles.gridHead)}
    >
      {props.children}
    </thead>
  );
}

export default GridHead;
