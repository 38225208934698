import { isEmptyObject } from '@travel/utils';
import { queryToObject } from '@travel/utils/src/queryString';

import { CONDITION_VALUES, PERIOD_TYPE_CONDITIONS } from 'constants/searchConditions';
import { SEARCH_MAXIMUM_DAYS } from 'pages/BookingList/components/BookingSearchConditionFields/constant';

import { fetchBookingList } from 'store/booking/actions';

import { universalRouterProps } from 'core/universalRouter/types';
import { getUserPlatform, PLATFORM } from 'utils';
import { addDays, getDeviceTZ, getToday } from 'utils/calendar';

type Query = { search?: { tags: string; conditions: string }; sort?: string[] };

export type URLParams = { params: { providerId: string }; query: Query };

export const getQuery = (query: Query = {}, platform: string) => {
  const timezone = getDeviceTZ();
  const fromDate = getToday(timezone);
  const toDate = addDays(fromDate, SEARCH_MAXIMUM_DAYS - 1);
  const objectQuery = queryToObject(query);
  const isInternal = platform === PLATFORM.INTERNAL;
  const isFirstLoad = objectQuery?.search === undefined;
  let periodFrom = '';
  let periodTo = '';
  if (!isInternal) {
    if (isFirstLoad) {
      // if it is first load or neither of periodFrom and periodTo is passed
      periodFrom = fromDate;
      periodTo = toDate;
    } else {
      periodFrom = objectQuery?.search?.periodFrom;
      periodTo = objectQuery?.search?.periodTo;
    }
  } else {
    // if the platform is internal then we allow any type of input
    // if it not first load then don't reset the value in the queryObject
    if (!isFirstLoad) {
      periodFrom = 'periodFrom' in objectQuery?.search ? objectQuery?.search?.periodFrom : '';
      periodTo = 'periodTo' in objectQuery?.search ? objectQuery?.search?.periodTo : '';
    }
  }
  const conditions = {
    periodType: PERIOD_TYPE_CONDITIONS.defaultSelectedValue,
    ...objectQuery?.search,
    periodFrom: periodFrom,
    periodTo: periodTo,
  };

  const tags = objectQuery?.search?.tags;
  const filter = objectQuery.filter;
  const sortValue = objectQuery.sort && Object.keys(objectQuery.sort)[0];
  const matchType = objectQuery?.matchType || CONDITION_VALUES.EXACT;

  return {
    ...filter,
    ...conditions,
    ...tags,
    matchType,
    sort: sortValue,
    limit: 30,
  };
};

export default ({ store: { dispatch, getState } }: universalRouterProps, urlParams: URLParams) => {
  const { params, query } = urlParams;
  const providerId = params && params.providerId;
  const platform = getUserPlatform(getState().accessControl?.loggedInUser?.scope);
  const isQueryEmpty = isEmptyObject(query);

  return (
    !isQueryEmpty &&
    Promise.all([dispatch(fetchBookingList(getQuery(query, platform), providerId, 0, false))])
  );
};
