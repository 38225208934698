import React from 'react';

import { cx } from '@travel/utils';

import styles from './listLoader.module.scss';

type Props = {
  className?: string;
  type?: 'pulse';
};

function ListLoader(props: Props) {
  const { className, type = 'pulse' } = props;

  return <div className={cx(className, styles[type])} />;
}

export default ListLoader;
