import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { toQuery } from '@travel/utils';
import { Feature, FeatureStatus } from 'Feature-Types';
import { FeatureGroupList } from 'FeatureGroup-Types';
import { BrowseParameters, QueryParams } from 'FeatureSelection-Types';
import {
  ApiFetchProviderFeatures,
  ApiFetchFeatures,
  ApiFetchProviderFeatureDetail,
  ApiUpdateProviderFeatureDetail,
} from './apis';

export const fetchFeaturesForSelectionAsync = createAsyncAction(
  'FETCH_FEATURES_FOR_SELECTION_REQUEST',
  'FETCH_FEATURES_FOR_SELECTION_SUCCESS',
  'FETCH_FEATURES_FOR_SELECTION_FAILURE',
)<undefined, FeatureGroupList, FeatureStatus[]>();

export const fetchAllFeatureGroupsAsync = createAsyncAction(
  'FETCH_ALL_FEATURE_GROUPS_REQUEST',
  'FETCH_ALL_FEATURE_GROUPS_SUCCESS',
  'FETCH_ALL_FEATURE_GROUPS_FAILURE',
)<undefined, FeatureGroupList, FeatureStatus[]>();

export const fetchProviderFeaturesAsync = createAsyncAction(
  'FETCH_PROVIDER_FEATURE_SELECTION_REQUEST',
  'FETCH_PROVIDER_FEATURE_SELECTION_SUCCESS',
  'FETCH_PROVIDER_FEATURE_SELECTION_FAILURE',
)<undefined, FeatureGroupList, FeatureStatus[]>();

export const fetchFeaturesAsync = createAsyncAction(
  'FETCH_FEATURE_SELECTION_REQUEST',
  'FETCH_FEATURE_SELECTION_SUCCESS',
  'FETCH_FEATURE_SELECTION_FAILURE',
)<undefined, FeatureGroupList, FeatureStatus[]>();

export const fetchProviderFeatureDetailAsync = createAsyncAction(
  'FETCH_PROVIDER_FEATURE_DETAIL_REQUEST',
  'FETCH_PROVIDER_FEATURE_DETAIL_SUCCESS',
  'FETCH_PROVIDER_FEATURE_DETAIL_FAILURE',
)<undefined, Feature, FeatureStatus[]>();

export const updateProviderFeatureDetailAsync = createAsyncAction(
  'UPDATE_FEATURE_DETAIL_REQUEST',
  'UPDATE_FEATURE_DETAIL_SUCCESS',
  'UPDATE_FEATURE_DETAIL_FAILURE',
)<undefined, FeatureStatus, FeatureStatus[]>();

export const updateFeatureBrowseParameter = createStandardAction(
  'UPDATE_FEATURE_SELECTION_BROWSE_PARAMETER',
)<BrowseParameters>();

export const resetFeatureDescriptons = createStandardAction('CLEAR_FEATURE_DESCRIPTIONS')<string>();

/**
 * Fetch all featureGroups with objectType and management in resolver.
 * NOTE: Please try to use this instead of fetchProviderFeatures/fetchInternalFeatures
 */
export const fetchFeaturesForSelection = (
  providerId: string,
  queryParams: QueryParams,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const {
    resourceId,
    objectType,
    management,
    sortValue = '',
    keyword,
    searchCondition,
  } = queryParams;
  const [sortType, value] = sortValue?.split('=');

  const queryObject = {
    filter: { id: resourceId, objectType, management },
    sort: { [sortType]: value },
    keyword,
    searchCondition,
    limit: 100,
    offset: 0, // TODO: infinite scroll
  };

  dispatch(fetchFeaturesForSelectionAsync.request());
  try {
    if (providerId) {
      const res = await ApiFetchProviderFeatures(apiClient, providerId || '', toQuery(queryObject));
      dispatch(fetchFeaturesForSelectionAsync.success(res));
    } else {
      const res = await ApiFetchFeatures(apiClient, toQuery(queryObject));
      dispatch(fetchFeaturesForSelectionAsync.success(res));
    }
  } catch (error) {
    dispatch(fetchFeaturesForSelectionAsync.failure([error as FeatureStatus]));
  }
};

/** to show feature group name. should be used in resolver
 * TODO: heavy. should fetch only featureGroups or use featureGroup action */
export const fetchAllFeatureGroups = (
  providerId: string,
  queryParams: QueryParams,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { resourceId, objectType, management, sortValue = '', keyword } = queryParams;
  const [sortType, value] = sortValue?.split('=');

  const queryObject = {
    filter: { id: resourceId, objectType, management },
    sort: { [sortType]: value },
    keyword,
    limit: 100,
    offset: 0, // TODO: infinite scroll
  };

  dispatch(fetchAllFeatureGroupsAsync.request());
  try {
    if (providerId) {
      const res = await ApiFetchProviderFeatures(apiClient, providerId || '', toQuery(queryObject));
      dispatch(fetchAllFeatureGroupsAsync.success(res));
    } else {
      const res = await ApiFetchFeatures(apiClient, toQuery(queryObject));
      dispatch(fetchAllFeatureGroupsAsync.success(res));
    }
  } catch (error) {
    dispatch(fetchAllFeatureGroupsAsync.failure([error as FeatureStatus]));
  }
};

/** Old version action. please use fetchFeaturesForSelection */
export const fetchFeatureSelection = (
  isInternal: boolean,
  providerId: string,
  filter: QueryParams,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  if (isInternal) {
    return dispatch(fetchInternalFeatures(filter));
  } else {
    return dispatch(fetchProviderFeatures(providerId, filter));
  }
};

// for provider
export const fetchProviderFeatures = (providerId: string, filter: QueryParams): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(updateFilter(filter));
  const { featureSelection } = getState();

  dispatch(fetchProviderFeaturesAsync.request());
  try {
    const res = await ApiFetchProviderFeatures(
      apiClient,
      providerId || '',
      transformToQueryObject(featureSelection.browseParameters),
    );
    dispatch(fetchProviderFeaturesAsync.success(res));
  } catch (error) {
    dispatch(fetchProviderFeaturesAsync.failure([error as FeatureStatus]));
  }
};
// for internal
export const fetchInternalFeatures = (filter: QueryParams): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(updateFilter(filter));
  const { featureSelection } = getState();

  dispatch(fetchFeaturesAsync.request());
  try {
    const res = await ApiFetchFeatures(
      apiClient,
      transformToQueryObject(featureSelection.browseParameters),
    );
    dispatch(fetchFeaturesAsync.success(res));
  } catch (error) {
    dispatch(fetchFeaturesAsync.failure([error as FeatureStatus]));
  }
};

/** Get feature description per provider */
export const fetchProviderFeatureDetail = (
  providerId: string,
  featureId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  // for featureDescriptions
  const currentProviderId = getState().featureSelection.featureDescriptions?.providerId;
  if (currentProviderId !== providerId) dispatch(resetFeatureDescriptons(providerId));

  dispatch(fetchProviderFeatureDetailAsync.request());

  try {
    const res = await ApiFetchProviderFeatureDetail(apiClient, providerId, featureId);
    dispatch(fetchProviderFeatureDetailAsync.success(res));
  } catch (error) {
    dispatch(fetchProviderFeatureDetailAsync.failure([error as FeatureStatus]));
  }
};

/** Update feature description per provider */
export const updateProviderFeatureDetail = (providerId: string, data: Feature): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(updateProviderFeatureDetailAsync.request());

  try {
    const res = await ApiUpdateProviderFeatureDetail(apiClient, providerId, data);
    dispatch(updateProviderFeatureDetailAsync.success(res));
    dispatch(fetchProviderFeatureDetail(providerId, data.id));
  } catch (error) {
    dispatch(updateProviderFeatureDetailAsync.failure([error as FeatureStatus]));
  }
};

/** update obkectType in browser parameter */
export const updateFilter = (filter: QueryParams): AppThunk<void> => (dispatch, getState) => {
  const { featureSelection } = getState();
  const { resourceId, objectType, management } = filter;
  const newParams = { ...featureSelection.browseParameters };
  newParams.filter = { ...newParams.filter, id: resourceId, objectType, management };

  dispatch(updateFeatureBrowseParameter(newParams));
};

/** Query constructor for options like: Filter, Sort, and Search */
function transformToQueryObject(params: BrowseParameters) {
  const parseSort = () => {
    const selectedSort = params.sort.options[params.sort.selectedValue].value;
    const [sortType, value] = selectedSort.split('=');
    return {
      [sortType]: value,
    };
  };

  const builtQuery = Object.assign({}, params, { sort: parseSort() });
  return toQuery(builtQuery);
}
