import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { PastHistory, DownloadSummaryData, HistoryData } from 'ProviderDataTranslation-Types';
import {
  fetchDownloadSummaryAsync,
  setProviderListFile,
  fetchHistoryAsync,
  setProviderList,
  setImportIdList,
  fetchProcessDataAsync,
  clearProviderListCSV,
  clearProviderList,
  clearImportIdList,
  clearProcessDataList,
  downloadProviderCSVAsync,
  downloadItemCSVAsync,
  downloadRatePlanCSVAsync,
} from './actions';
import { SelectedProvider } from 'ProvidersInformation-Types';
import { defaultSummary, initialProcessList, initialHistoryData } from './initialValues';

export const isFetchingSummary = createReducer(false as boolean)
  .handleAction([fetchDownloadSummaryAsync.request], (state, action) => true)
  .handleAction(
    [fetchDownloadSummaryAsync.success, fetchDownloadSummaryAsync.failure],
    (state, action) => false,
  );

export const isFetchingProviderCSV = createReducer(false as boolean)
  .handleAction([downloadProviderCSVAsync.request], () => true)
  .handleAction([downloadProviderCSVAsync.success, downloadProviderCSVAsync.failure], () => false);
export const isFetchingItemCSV = createReducer(false as boolean)
  .handleAction([downloadItemCSVAsync.request], () => true)
  .handleAction([downloadItemCSVAsync.success, downloadItemCSVAsync.failure], () => false);
export const isFetchingRatePlanCSV = createReducer(false as boolean)
  .handleAction([downloadRatePlanCSVAsync.request], () => true)
  .handleAction([downloadRatePlanCSVAsync.success, downloadRatePlanCSVAsync.failure], () => false);

export const isFetchingHistory = createReducer(false as boolean)
  .handleAction([fetchHistoryAsync.request], (state, action) => true)
  .handleAction([fetchHistoryAsync.success, fetchHistoryAsync.failure], (state, action) => false);

export const historyData = createReducer(initialHistoryData as PastHistory)
  .handleAction([fetchHistoryAsync.success], (state, action) => action.payload)
  .handleAction([fetchProcessDataAsync.success], (state, action) => {
    const index = state.imports.findIndex(data => data.importId === action.payload.importId);
    if (index > -1) {
      state.imports[index] = action.payload;
    }
    return state;
  });

export const summaryData = createReducer(defaultSummary as DownloadSummaryData).handleAction(
  fetchDownloadSummaryAsync.success,
  (state, action) => action.payload,
);

export const providerListFile = createReducer((null as unknown) as File)
  .handleAction(setProviderListFile, (state, action) => action.payload)
  .handleAction(clearProviderListCSV, () => (null as unknown) as File);

export const providerList = createReducer([] as Array<SelectedProvider>)
  .handleAction(setProviderList, (state, action) => action.payload)
  .handleAction(clearProviderList, () => []);

export const importIdList = createReducer([] as Array<string>)
  .handleAction(setImportIdList, (state, action) => action.payload)
  .handleAction(clearImportIdList, () => []);

export const processDataList = createReducer(initialProcessList as HistoryData[])
  .handleAction(fetchProcessDataAsync.success, (state, action) => {
    const index = state.findIndex(data => data.importId === action.payload.importId);
    if (index > -1) {
      state[index] = action.payload;
    } else {
      state.unshift(action.payload);
    }
    return [...state];
  })
  .handleAction(clearProcessDataList, () => []);

const providerDataTranslationReducer = combineReducers({
  isFetchingSummary,
  isFetchingProviderCSV,
  isFetchingItemCSV,
  isFetchingRatePlanCSV,
  isFetchingHistory,
  providerListFile,
  providerList,
  summaryData,
  historyData,
  importIdList,
  processDataList,
});

export default providerDataTranslationReducer;
export type ProviderDataTranslationState = ReturnType<typeof providerDataTranslationReducer>;
