import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, FlatButton } from '@travel/ui';
import { Translate } from '@travel/translation';
import { getGlobalPopup } from 'store/utility/selectors';
import styles from './globalPopup.module.scss';
import { closeGlobalPopup } from 'store/utility/actions';
import { TITLE, ICON } from './constants';

function GlobalPopup() {
  const dispatch = useDispatch();
  const globalPopup = useSelector(getGlobalPopup);
  const {
    isOpen,
    customTitleLabelId = '',
    messageLabelId = '',
    primaryButtonLabelId = 'Common.Detail.Save_Success.OK',
    type = '',
  } = globalPopup;

  const handleOnClose = () => {
    dispatch(closeGlobalPopup());
  };

  return (
    <Dialog
      className={styles.dialog}
      contentClassName={styles.contentDialog}
      headerClassName={styles.headerDialog}
      title={''}
      isOpen={isOpen}
      onClose={handleOnClose}
    >
      <div className={styles.wrapper} data-testid={'globalPopup-content'}>
        <div className={styles.titleContainer}>
          {ICON[type]}
          <span className={styles.title}>
            {customTitleLabelId ? <Translate id={customTitleLabelId} /> : TITLE[type]}
          </span>
        </div>
        <div className={styles.message}>
          <Translate id={messageLabelId} />
        </div>
        <FlatButton
          className={styles.button}
          classType={'primary'}
          onClick={handleOnClose}
          data-testid={'globalPopup-button'}
        >
          <Translate id={primaryButtonLabelId} />
        </FlatButton>
      </div>
    </Dialog>
  );
}

export default GlobalPopup;
