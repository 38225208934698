import { ApiClient } from '@travel/api-client';
import {
  ChannelManagerProviderSearchBody,
  ChannelManagerConnectProviderBody,
} from 'ChannelManagerProviderList-Types';

export const fetchAllBmc = (
  http: ApiClient,
  managerId: string,
  query: { [key: string]: string | number },
): Promise<ChannelManagerProviderSearchBody> =>
  http.get(`/partner-api/partner/channelManagers/${managerId}/bmc/searchConnectedProviders`, {
    values: { ...query },
  });

export const fetchAllAri = (
  http: ApiClient,

  managerId: string,
  query: { [key: string]: string | number },
): Promise<ChannelManagerProviderSearchBody> =>
  http.get(`/partner-api/partner/channelManagers/${managerId}/ari/searchConnectedProviders`, {
    values: { ...query },
  });

export const disconnectBmcProvider = (http: ApiClient, managerId: string, providerIds: string[]) =>
  http.put(`/partner-api/partner/channelManagers/${managerId}/bmc/disconnect`, {
    values: { providerIds },
  });

export const disconnectAriProvider = (http: ApiClient, managerId: string, providerIds: string[]) =>
  http.put(`/partner-api/partner/channelManagers/${managerId}/ari/disconnect`, {
    values: { providerIds },
  });

export const connectBmcProvider = (
  http: ApiClient,
  managerId: string,
  data: ChannelManagerConnectProviderBody,
) => http.put(`/partner-api/partner/channelManagers/${managerId}/bmc/connect`, { values: data });

export const connectAriProvider = (
  http: ApiClient,
  managerId: string,
  data: ChannelManagerConnectProviderBody,
) => http.put(`/partner-api/partner/channelManagers/${managerId}/ari/connect`, { values: data });
