import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ExtraRateList, ExtraRateErrors } from 'ExtraRate-Types';
import {
  fetchExtraRateListAsync,
  fetchExtraRateListPerPersonAsync,
  fetchExtraRateListPerItemAsync,
  fetchExtraRateDetailAsync,
  clearExtraRateDetail,
  createExtraRateDetailAsync,
  updateExtraRateDetailAsync,
} from './actions';
import { initialExtraRateDetail } from './initialValues';

export const isCreating = createReducer(false as boolean)
  .handleAction(
    [createExtraRateDetailAsync.request, updateExtraRateDetailAsync.request],
    () => true,
  )
  .handleAction(
    [
      createExtraRateDetailAsync.success,
      createExtraRateDetailAsync.failure,
      updateExtraRateDetailAsync.success,
      updateExtraRateDetailAsync.failure,
      fetchExtraRateListPerItemAsync.success,
      fetchExtraRateListPerItemAsync.failure,
    ],
    () => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchExtraRateListAsync.request], (state, action) => true)
  .handleAction(
    [fetchExtraRateListAsync.success, fetchExtraRateListAsync.failure],
    (state, action) => false,
  )
  .handleAction([fetchExtraRateListPerPersonAsync.request], (state, action) => true)
  .handleAction(
    [fetchExtraRateListPerPersonAsync.success, fetchExtraRateListPerPersonAsync.failure],
    (state, action) => false,
  )
  .handleAction([fetchExtraRateListPerItemAsync.request], (state, action) => true)
  .handleAction(
    [fetchExtraRateListPerItemAsync.success, fetchExtraRateListPerItemAsync.failure],
    (state, action) => false,
  );

export const extraRateListPerItem = createReducer({} as ExtraRateList).handleAction(
  fetchExtraRateListPerItemAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const extraRateListPerPerson = createReducer({} as ExtraRateList).handleAction(
  fetchExtraRateListPerPersonAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const extraRateList = createReducer({} as ExtraRateList).handleAction(
  fetchExtraRateListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const extraRateDetail = createReducer(initialExtraRateDetail)
  .handleAction(clearExtraRateDetail, (state, action) => initialExtraRateDetail)
  .handleAction(fetchExtraRateDetailAsync.success, (state, action) => action.payload);

export const errors = createReducer([] as ExtraRateErrors[])
  .handleAction(fetchExtraRateListAsync.failure, (state, action) => action.payload)
  .handleAction(fetchExtraRateDetailAsync.failure, (state, action) => action.payload);

const extraRateReducer = combineReducers({
  isFetching,
  extraRateList,
  extraRateListPerItem,
  extraRateListPerPerson,
  extraRateDetail,
  errors,
  isCreating,
});

export default extraRateReducer;
export type ExtraRateState = ReturnType<typeof extraRateReducer>;
