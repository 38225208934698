import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  NotificationInboxList,
  ComposeNotification,
  ApiStatus,
  NotificationDetailList,
} from 'Notification-Types';
import {
  fetchNotificationAsync,
  fetchNotificationDetailAsync,
  clearNotification,
  composeNotificationAsync,
  fetchNotificationDetailListAsync,
} from './actions';
import initialValues from './initialValues';

export const isComposing = createReducer(false)
  .handleAction(composeNotificationAsync.request, () => true)
  .handleAction([composeNotificationAsync.success, composeNotificationAsync.failure], () => false);

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchNotificationAsync.request], (state, action) => true)
  .handleAction(
    [fetchNotificationAsync.success, fetchNotificationAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as NotificationInboxList).handleAction(
  fetchNotificationAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      notifications:
        state.notifications && shouldConcat
          ? state.notifications.concat(action.payload.notifications)
          : action.payload.notifications,
    };
  },
);

export const notificationDetail = createReducer(initialValues as ComposeNotification)
  .handleAction(fetchNotificationDetailAsync.success, (state, action) => action.payload)
  .handleAction(clearNotification, (state, action) => initialValues);

export const notificationDetailList = createReducer({} as NotificationDetailList).handleAction(
  fetchNotificationDetailListAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as ApiStatus[]).handleAction(
  fetchNotificationAsync.failure,
  (state, action) => action.payload,
);

const notificationReducer = combineReducers({
  isFetching,
  items,
  notificationDetail,
  notificationDetailList,
  errors,
  isComposing,
});

export default notificationReducer;
export type NotificationState = ReturnType<typeof notificationReducer>;
