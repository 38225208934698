import { createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  ApplicationTrackerItems,
  ApplicationTrackerErrors,
  FilterType,
  MemoType,
  ApiStatus,
  UploadResult,
} from 'ApplicationTracker-Types';
import {
  fetch,
  updateMemo,
  updateEncourage,
  exportProviderList,
  exportRatePlanList,
  exportOverlayList,
  uploadCSV,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

// XXX: Kindly update payloads to your own async implementation
export const fetchApplicationAsync = createAsyncAction(
  'FETCH_APPLICATIONS_REQUEST',
  'FETCH_APPLICATION_SUCCESS',
  'FETCH_APPLICATION_FAILURE',
)<undefined, ApplicationTrackerItems[], ApplicationTrackerErrors[]>();

export const updateMemoAsync = createAsyncAction(
  'UPDATE_MEMO_REQUEST',
  'UPDATE_MEMO_SUCCESS',
  'UPDATE_MEMO_FAILURE',
)<undefined, ApplicationTrackerItems, ApplicationTrackerErrors[]>();

export const updateEncourageAsync = createAsyncAction(
  'UPDATE_ENCOURAGE_REQUEST',
  'UPDATE_ENCOURAGE_SUCCESS',
  'UPDATE_ENCOURAGE_FAILURE',
)<undefined, ApplicationTrackerItems, ApplicationTrackerErrors[]>();

export const exportInExcelFormatAsync = createAsyncAction(
  'EXPORT_EXCEL_REQUEST',
  'EXPORT_EXCEL_SUCCESS',
  'EXPORT_EXCEL_FAILURE',
)<undefined, Blob, ApplicationTrackerErrors[]>();

export const uploadFileAsync = createAsyncAction(
  'UPLOAD_FILE_REQUEST',
  'UPLOAD_FILE_SUCCESS',
  'UPLOAD_FILE_FAILURE',
)<undefined, UploadResult, ApplicationTrackerErrors[]>();

export const fetchApplications = (campaignId: string, filter: FilterType): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchApplicationAsync.request());
  try {
    const response = await fetch(apiClient, campaignId, filter);
    dispatch(fetchApplicationAsync.success(response));
  } catch (error) {
    dispatch(fetchApplicationAsync.failure(error));
  }
};

export const updateApplicationMemo = (
  campaignId: string,
  applicationID: string,
  data: MemoType,
  filter: FilterType,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateMemoAsync.request());
  try {
    await updateMemo(apiClient, campaignId, applicationID, data);
    dispatch(fetchApplications(campaignId, filter));
  } catch (error) {
    dispatch(updateMemoAsync.failure(error));
  }
};

export const updateApplicationEncourage = (
  campaignId: string,
  applicationID: string,
  data: MemoType,
  filter: FilterType,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateEncourageAsync.request());
  try {
    await updateEncourage(apiClient, campaignId, applicationID, data);
    dispatch(fetchApplications(campaignId, filter));
  } catch (error) {
    dispatch(updateEncourageAsync.failure(error));
  }
};

export const exportProviderListInCSVFormat = (
  campaignId: string,
  filter: FilterType,
): AppThunk<Promise<Blob | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(exportInExcelFormatAsync.request());
  try {
    const response = await exportProviderList(apiClient, campaignId, filter);
    dispatch(exportInExcelFormatAsync.success(response));
    return response;
  } catch (error) {
    dispatch(exportInExcelFormatAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const exportRatePlanListInCSVFormat = (
  campaignId: string,
  filter: FilterType,
): AppThunk<Promise<Blob | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(exportInExcelFormatAsync.request());
  try {
    const response = await exportRatePlanList(apiClient, campaignId, filter);
    dispatch(exportInExcelFormatAsync.success(response));
    return response;
  } catch (error) {
    dispatch(exportInExcelFormatAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const exportOverlayListInCSVFormat = (
  campaignId: string,
  filter: FilterType,
): AppThunk<Promise<Blob | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(exportInExcelFormatAsync.request());
  try {
    const response = await exportOverlayList(apiClient, campaignId, filter);
    dispatch(exportInExcelFormatAsync.success(response));
    return response;
  } catch (error) {
    dispatch(exportInExcelFormatAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const uploadFile = (file: File, campaignId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(uploadFileAsync.request());
  const formData = new FormData();
  formData.append('file', file, file.name);

  try {
    const res = await uploadCSV(apiClient, campaignId, formData);
    dispatch(uploadFileAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
  } catch (error) {
    dispatch(uploadFileAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};
