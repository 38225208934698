import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { setCalendarHolidays } from './actions';
import { CalendarHolidays } from 'CalendarHoliday-Types';

export const calendarHoliday = createReducer({} as CalendarHolidays).handleAction(
  setCalendarHolidays,
  (state, action) => action.payload,
);

const calendarHolidayReducer = combineReducers({
  calendarHoliday,
});

export default calendarHolidayReducer;
export type CalendarHolidayState = ReturnType<typeof calendarHolidayReducer>;
