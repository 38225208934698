import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { ApiStatus } from 'GlobalErrorDialog-Types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import {
  APIResponse,
  ReservationSendMessageRequest,
  ReservationMessageForm,
  ReservationMessageList,
  ReservationMessagePreview,
} from 'BookingMessage-Types';
import {
  ApiSendMessage,
  ApiFetchMessagePreview,
  ApiFetchSentMessagePreview,
  ApiFetchMessageList,
} from './apis';

export const sendMessageAsync = createAsyncAction(
  'SEND_MESSAGE_REQUEST',
  'SEND_MESSAGE_SUCCESS',
  'SEND_MESSAGE_FAILURE',
)<undefined, undefined, undefined>();

export const fetchMessageListAsync = createAsyncAction(
  'FETCH_MESSAGE_LIST_REQUEST',
  'FETCH_MESSAGE_LIST_SUCCESS',
  'FETCH_MESSAGE_LIST_FAILURE',
)<undefined, ReservationMessageList, undefined>();

export const fetchSentMessagePreviewAsync = createAsyncAction(
  'FETCH_SENT_MESSAGE_PREVIEW_REQUEST',
  'FETCH_SENT_MESSAGE_PREVIEW_SUCCESS',
  'FETCH_SENT_MESSAGE_PREVIEW_FAILURE',
)<undefined, ReservationMessagePreview, undefined>();

export const fetchMessagePreviewAsync = createAsyncAction(
  'FETCH_MESSAGE_PREVIEW_REQUEST',
  'FETCH_MESSAGE_PREVIEW_SUCCESS',
  'FETCH_MESSAGE_PREVIEW_FAILURE',
)<undefined, ReservationMessagePreview, undefined>();

export const sendMessage = (
  reservationId: string,
  values: ReservationSendMessageRequest,
): AppThunk<Promise<APIResponse | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(sendMessageAsync.request());
  try {
    const res = await ApiSendMessage(apiClient, reservationId, values);
    dispatch(sendMessageAsync.success());
    return res;
  } catch (error) {
    dispatch(sendMessageAsync.failure());
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
    throw error;
  }
};

export const fetchMessageList = (reservationId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMessageListAsync.request());
  try {
    const res = await ApiFetchMessageList(apiClient, reservationId);
    dispatch(fetchMessageListAsync.success(res));
  } catch (error) {
    dispatch(fetchMessageListAsync.failure());
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const fetchSentMessagePreview = (
  reservationId: string,
  messageId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchSentMessagePreviewAsync.request());
  try {
    const res = await ApiFetchSentMessagePreview(apiClient, reservationId, messageId);
    dispatch(fetchSentMessagePreviewAsync.success(res));
  } catch (error) {
    dispatch(fetchSentMessagePreviewAsync.failure());
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const fetchMessagePreview = (
  reservationId: string,
  values: ReservationMessageForm,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchMessagePreviewAsync.request());
  try {
    const res = await ApiFetchMessagePreview(apiClient, reservationId, values);
    dispatch(fetchMessagePreviewAsync.success(res));
  } catch (error) {
    dispatch(fetchMessagePreviewAsync.failure());
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};
