import { combineReducers } from 'redux';
import { UserSegments } from 'Segments-Types';
import { createReducer } from 'typesafe-actions';
import { fetchSegmentsAsync } from './actions';

export const items = createReducer({} as UserSegments).handleAction(
  fetchSegmentsAsync.success,
  (state, action) => action.payload,
);

export const isFetching = createReducer(false)
  .handleAction(fetchSegmentsAsync.request, () => true)
  .handleAction([fetchSegmentsAsync.success, fetchSegmentsAsync.failure], () => false);

const segmentsReducer = combineReducers({
  items,
  isFetching,
});

export default segmentsReducer;
export type SegmentsState = ReturnType<typeof segmentsReducer>;
