import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import TextField from '../TextField';
import { cx, isNotEmptyArray } from '@travel/utils';
import { Search, SingleArrow } from '@travel/icons/ui'; //TODO: This a temporary icon. Actual icon is not available yet
import { PaginationDot } from '@travel/icons/service';
import RadioBalloon from '../RadioBalloon';
import { Props as SearchCondition } from '../RadioButton';
import styles from './searchBox.module.scss';
import tfstyles from '../TextField/textField.module.scss';
import { Translate, useTranslate } from '@travel/translation';

type Props = {
  /** Custom style for wrapper */
  className: string;
  popupClassName?: string;
  /** Value of Search box */
  value: string;
  /** Custom label for search condition title */
  searchConditionTitle?: React.ReactNode;
  /** Search conditions of Search box */
  searchConditions?: Array<SearchCondition>;
  /** Default search condtion selected */
  defaultCondition: string;

  /**
   * Allow to search as user type with some debounce
   */
  isAutoSearch?: boolean;

  /** Shows/Hides the search conditions */
  isHideOption?: boolean;
  /** Shows/Hides the search button */
  isHideButton?: boolean;
  /** Callback to be called after keyword or condition changes */
  onChange: (keyword: string, condition: string) => void;
  /**
   * we don't need to pass onClear just to clear value as already doing it with onChange
   * this can be useful to handle some different things or integrating with Third party lib
   * callback to be called after clear button click
   * */
  onClear?: () => void;
  onClick?: () => void;
  onCustomConditionClick?: () => void;

  /** Custom Placeholder */
  textBoxPlaceHolder?: string;
  id?: string;
  isDisabled?: boolean;
  isSearchConditionDisabled?: boolean;
};

function SearchBox(props: Props) {
  const {
    className,
    popupClassName,
    value,
    searchConditionTitle,
    searchConditions,
    isHideOption,
    isHideButton,
    defaultCondition,
    textBoxPlaceHolder,
    id,
    isDisabled,
    isAutoSearch,
    onChange,
    onClear,
    onClick,
    onCustomConditionClick,
    isSearchConditionDisabled,
    ...rest
  } = props;
  const [keyword, setKeyword] = useState(value);
  const [condition, setCondition] = useState(defaultCondition);
  const [isOpen, setIsOpen] = useState(false);
  const [isHideSearchBtn, setIsHideSearchBtn] = useState(true);
  const [isSearchReady, setIsSearchReady] = useState(false);

  const node: any = useRef();

  const defaultPlaceholder = useTranslate({ id: 'Common.List.Search.Guidance' });
  const textFieldProps = {
    placeholder: textBoxPlaceHolder || defaultPlaceholder,
    id: id,
    style: { border: 'none' },
  };

  const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== keyword) {
      setKeyword(event.target.value);
    }
    if (event.target.value) {
      setIsSearchReady(true);
    } else {
      setIsSearchReady(false);
    }
  };

  const handleConditionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchCondition = event.target?.value;

    setCondition(searchCondition);
    setIsOpen(false);
    onChange && onChange(keyword, searchCondition);
  };

  const handleConditionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsOpen(!isOpen);
    onCustomConditionClick && onCustomConditionClick();
  };

  const handleOnClear = () => {
    onClear && onClear();
    setKeyword('');
    setIsSearchReady(false);
    setIsHideSearchBtn(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onChange(keyword, condition);
    }
  };

  const handleFocus = () => {
    setIsHideSearchBtn(false);
  };

  const handleSearchClick = () => {
    onChange(keyword, condition);
  };

  useEffect(() => {
    const handleFocusOut = (event: any) => {
      if (node.current && !node.current.contains(event.target)) {
        setIsOpen(!isOpen);
      }
    };

    document.addEventListener('click', handleFocusOut);

    return () => {
      document.removeEventListener('click', handleFocusOut);
    };
  }, [isOpen]);

  return (
    <div className={cx(className, styles.container)} {...rest}>
      <div className={styles.searchBar} data-testid="searchBox-search-bar">
        <TextField
          isDisabled={isDisabled}
          value={keyword}
          inputProps={textFieldProps}
          customIcon={<Search className={tfstyles.clearButton} />}
          onChange={handleKeywordChange}
          onClear={handleOnClear}
          onClick={onClick}
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          {...rest}
        />
        {!isHideOption && (
          <button
            type="button"
            className={styles.conditionButton}
            onClick={handleConditionClick}
            disabled={isSearchConditionDisabled}
            data-testid="searchBox-condition-button"
          >
            <PaginationDot className={styles.conditionIcon} />
          </button>
        )}
        {!isHideButton && !isHideSearchBtn && (
          <button
            type="button"
            className={cx(styles.singleArrowButton, isSearchReady ? styles.searchReady : '')}
            onClick={handleSearchClick}
            disabled={isSearchConditionDisabled}
            data-testid="searchBox-search-button"
          >
            <SingleArrow className={styles.singleArrowIcon} />
          </button>
        )}
      </div>
      {isOpen && isNotEmptyArray(searchConditions) && (
        <div className={styles.balloonWrapper} ref={node}>
          <RadioBalloon
            className={cx(styles.balloon, popupClassName)}
            label={searchConditionTitle || <Translate id="Common.List.Search.Condition_Title" />}
            options={searchConditions}
            onChange={handleConditionChange}
            defaultSelectedOption={condition}
          />
        </div>
      )}
    </div>
  );
}

SearchBox.defaultProps = {
  className: '',
  isHideOption: false,
  onChange: () => {},
  onClear: () => {},
  defaultCondition: '',
  isDisabled: false,
  isAutoSearch: true,
  isSearchConditionDisabled: false,
  isHideButton: false,
};

export default SearchBox;
