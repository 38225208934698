import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { ApiStatus, PlanListParams, PlanList, PlanErrors, FeatureFilterValues } from 'Plan-Types';

import { filterIdList } from 'constants/plan';
import { transformToQueryObject } from 'utils/transformToQueryObject';

import { ApiFetchPlanList } from './apis';

export const fetchPlanListAsync = createAsyncAction(
  'FETCH_PLAN_LIST_REQUEST',
  'FETCH_PLAN_LIST_SUCCESS',
  'FETCH_PLAN_LIST_FAILURE',
)<undefined, PlanList, PlanErrors[]>();

export const updatePlanBrowseParameter = createStandardAction('UPDATE_PLAN_BROWSE_PARAMETER')<
  PlanListParams
>();

export const clearFeatureFilterBrowseParamaters = createStandardAction(
  'CLEAR_FEATURE_FILTER_BROWSE_PARAMETER',
)();

export const updateFeatureFilterBrowseParameters = createStandardAction(
  'UPDATE_PLAN_LIST_FEATURE_FILTER_BROWSE_PARAMETER',
)<FeatureFilterValues>();

export const clearPlanList = createStandardAction('CLEAR_PLAN_LIST')();

export const fetchPlanList = (
  providerId: string | null,
  query?: { [key: string]: string | number | string[] },
  offset?: number,
): AppThunk => async (dispatch, _getState, { apiClient }) => {
  dispatch(fetchPlanListAsync.request());
  try {
    const fetchQuery = {
      ...transformToQueryObject(query ? query : {}, filterIdList),
      offset: offset || 0,
      limit: 30,
    };
    const res = await ApiFetchPlanList(apiClient, providerId, fetchQuery);

    dispatch(fetchPlanListAsync.success(res));
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};
