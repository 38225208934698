import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { ManualTranslationErrors, DownLoadParameter, ApiStatus } from 'ManualTranslation-Types';
import { getDataTypeApi, downloadThermsApi, uploadThermsApi } from './api';
import { as } from '@travel/utils';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

export const getDataTypeAsync = createAsyncAction(
  'FETCH_DATA_TYPE_REQUEST',
  'FETCH_DATA_TYPE_SUCCESS',
  'FETCH_DATA_TYPE_FAILURE',
)<undefined, string[], ManualTranslationErrors[]>();

export const downloadThermsAsync = createAsyncAction(
  'DOWNLOAD_THERMS_REQUEST',
  'DOWNLOAD_THERMS_SUCCESS',
  'DOWNLOAD_THERMS_FAILURE',
)<undefined, Object, ManualTranslationErrors[]>();

export const uploadThermsAsync = createAsyncAction(
  'UPLOAD_THERMS_REQUEST',
  'UPLOAD_THERMS_SUCCESS',
  'UPLOAD_THERMS_FAILURE',
)<undefined, ApiStatus, ManualTranslationErrors[]>();

export const getDataType = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(getDataTypeAsync.request());
  try {
    const response = await getDataTypeApi(apiClient);
    dispatch(getDataTypeAsync.success(response));
  } catch (err) {
    dispatch(getDataTypeAsync.failure(err));
  }
};

export const downloadTherms = (filters: DownLoadParameter): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(downloadThermsAsync.request());
  try {
    const response = await downloadThermsApi(apiClient, filters);
    const blob = await response.blob();

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `manual-translation-therms-${new Date().getTime()}.zip`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    dispatch(downloadThermsAsync.success(response));
  } catch (err) {
    console.error(err);
    dispatch(downloadThermsAsync.failure(err));
  }
};

export const uploadTherms = (file: File): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(uploadThermsAsync.request());

  const formData = new FormData();
  formData.append('translation', file, file.name);

  try {
    const res = await uploadThermsApi(apiClient, formData);

    dispatch(uploadThermsAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(res)));
  } catch (error) {
    if (error.commonErrorHandler) {
      error.commonErrorHandler();
    }

    dispatch(uploadThermsAsync.failure([error]));
    dispatch(
      fetchGlobalErrorDialogAsync.success(
        as<ApiStatus>({ status: error.status, message: error.error }),
      ),
    );
  }
};
