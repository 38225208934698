import React, { useState, useEffect } from 'react';
import { Attention } from '@travel/icons/ui';
import styles from './networkError.module.scss';
import { Translate } from '@travel/translation';

function NetworkError() {
  const [isOnline, setIsOnline] = useState(true);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  }, []);

  return !isOnline ? (
    <div
      className={styles.networkError}
      data-locator-id="NetworkError-0818731f-55c9-466f-afeb-1a98cf2aff77"
    >
      <Attention size={24} />
      <span>
        <Translate id="Extranet_Error.System_Error.Unstable_Internet_Connection" />
      </span>
    </div>
  ) : null;
}

export default NetworkError;
