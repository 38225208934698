import { universalRouterProps } from 'core/universalRouter/types';
import { fetchItemDetail, setInitialItemDetail } from 'store/inventory/actions';
import { fetchItemSizeTypes } from 'store/sizeTypes/actions';
import { fetchVisualContentGroupList } from 'store/visualContentGroup/actions';
import { fetchAllFeatureGroups } from 'store/featureSelection/actions';
import { OBJECT_TYPE } from 'constants/feature';
import { getIsCreateAccess } from 'utils/userScopeHelper';

type Location = {
  params: {
    providerId: string;
    itemId: string;
  };
};

export default ({ store }: universalRouterProps, location: Location) => {
  const { providerId, itemId } = location.params;

  const featureQueryParams = { resourceId: providerId, objectType: OBJECT_TYPE.ITEM };
  const { dispatch } = store;
  const { permissions } = store.getState()?.accessControl?.loggedInUser || { permissions: [] };
  const hasMediaAccess = getIsCreateAccess(permissions, 'MediaMgmt');

  const promises = [
    dispatch(fetchItemSizeTypes('ITEM')),
    dispatch(fetchAllFeatureGroups(providerId, featureQueryParams)),
  ];

  if (hasMediaAccess) {
    promises.push(dispatch(fetchVisualContentGroupList(providerId, {})));
  }

  dispatch(setInitialItemDetail());
  if (itemId) {
    promises.push(dispatch(fetchItemDetail(providerId, itemId)));
  }

  return Promise.all(promises);
};
