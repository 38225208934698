import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  LabelManagementErrors,
  Page,
  AllPagesBody,
  AllLabelsBody,
  Label,
} from 'LabelManagement-Types';
import {
  createPageAsync,
  fetchPageAsync,
  updatePageAsync,
  fetchPagesAsync,
  fetchAllLabelsAsync,
  fetchLabelDetailAsync,
  createLabelAsync,
  updateLabelDetailAsync,
  clearLabel,
  clearPage,
  fetchAllPagesAsync,
  uploadLabelAsync,
  downloadLabelAsync,
} from './actions';
import { pageInitialValues, labelInitialValues } from './initialValues';

export const isDownloading = createReducer(false)
  .handleAction(downloadLabelAsync.request, () => true)
  .handleAction([downloadLabelAsync.success, downloadLabelAsync.failure], () => false);

export const isCreating = createReducer(false)
  .handleAction(
    [
      createPageAsync.request,
      updatePageAsync.request,
      createLabelAsync.request,
      updateLabelDetailAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      createPageAsync.success,
      createPageAsync.failure,
      updatePageAsync.success,
      updatePageAsync.failure,
      createLabelAsync.failure,
      createLabelAsync.success,
      updateLabelDetailAsync.success,
      updateLabelDetailAsync.failure,
    ],
    () => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchPageAsync.request,
      fetchPagesAsync.request,
      fetchAllLabelsAsync.request,
      fetchLabelDetailAsync.request,
      fetchAllPagesAsync.request,
      uploadLabelAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchPageAsync.success,
      fetchPageAsync.failure,
      fetchPagesAsync.success,
      fetchPagesAsync.failure,
      fetchAllLabelsAsync.success,
      fetchAllLabelsAsync.failure,
      fetchLabelDetailAsync.success,
      fetchLabelDetailAsync.failure,
      fetchAllPagesAsync.success,
      fetchAllPagesAsync.failure,
      uploadLabelAsync.success,
      uploadLabelAsync.failure,
    ],
    () => false,
  );

export const errors = createReducer([] as LabelManagementErrors[]).handleAction(
  [
    createPageAsync.failure,
    fetchPageAsync.failure,
    updatePageAsync.failure,
    fetchPagesAsync.failure,
    downloadLabelAsync.failure,
    uploadLabelAsync.failure,
  ],
  (_, action) => action.payload,
);

export const page = createReducer(pageInitialValues as Page)
  .handleAction(fetchPageAsync.success, (_, action) => action.payload)
  .handleAction(clearPage, () => pageInitialValues);

export const pages = createReducer({} as AllPagesBody).handleAction(
  [fetchPagesAsync.success, fetchAllPagesAsync.success],
  (state, action) => {
    const shouldConcat = !state.pageable || action.payload.pageable.offset > state.pageable.offset;

    return {
      ...action.payload,
      content:
        state.content && shouldConcat
          ? state.content.concat(action.payload.content)
          : action.payload.content,
    };
  },
);

export const labels = createReducer({} as AllLabelsBody).handleAction(
  fetchAllLabelsAsync.success,
  (state, action) => {
    const shouldConcat = !state.pageable || action.payload.pageable.offset > state.pageable.offset;

    return {
      ...action.payload,
      content:
        state.content && shouldConcat
          ? state.content.concat(action.payload.content)
          : action.payload.content,
    };
  },
);

export const label = createReducer(labelInitialValues as Label)
  .handleAction(fetchLabelDetailAsync.success, (_, action) => action.payload)
  .handleAction(clearLabel, () => labelInitialValues);

const labelManagementReducer = combineReducers({
  isFetching,
  errors,
  page,
  pages,
  labels,
  label,
  isCreating,
  isDownloading,
});

export default labelManagementReducer;
export type LabelManagementState = ReturnType<typeof labelManagementReducer>;
