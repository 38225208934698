import React, { ReactNode, useRef } from 'react';
import { NoResult, NoItem, SkeletonListItems, Skeleton } from '@travel/ui';
import ListLoader from '../List/ListLoader';
import { cx, isNotEmptyArray, isNotNullish } from '@travel/utils';
import { Translate } from '@travel/translation';
import useInfiniteScroll from './useInfiniteScroll';
import styles from './listWrapper.module.scss';
import LoadingGreenLine from '../LoadingGreenLine';

type Props = {
  /** custom style for wrapper */
  className?: string;
  /** custom style for skeleton list item */
  itemClassName?: string;
  /** Childrens of List */
  children: ReactNode;
  /** Flag to determine whether to apply infinite scrolling */
  hasInfiniteScroll?: boolean; // TODO: think later if false
  /** Flag to define whether to call next function */
  hasMore?: boolean;
  /** Function to be called after reaching the bottom */
  next?: () => void;
  /** Flag to determine whether to scroll back to top */
  shouldScrollUpToTop?: boolean;
  /** Flag to show line loader */
  isFetching: boolean;
  /** Flag to show NoItem in case false. */
  hasFilter: boolean;
  /** List data to distinguish initial load or no result. */
  listData: Array<any> | undefined;
  /** Flag for skeleton list item style */
  hasMedia: boolean;
  /** Flag for skeleton list item style */
  hasCheckbox: boolean;
  /** Label for the search result */
  resultLabel?: ReactNode;
  /** total for result label */
  total?: number | string;
  /** Flag to show description on NoItem */
  hasDescriptionOnNoItem?: boolean;
  /** Icon for <NoItem /> */
  noItemIcon?: ReactNode;
  /** Flag to Show pulse loader instead of Skeleton List Item. */
  isPulseLoaderType?: boolean;
  /** Flag to add styles for non fixed button */
  isFixedButton: boolean;
  /** Flag to display correct No Result message for Page / Dialog that doesn't have create page */
  isMiniMessage?: boolean;
  /** Flag to show top green loader */
  hasCustomLoading?: boolean;
  /** Flag to show table skeleton */
  isSkeletonTable?: boolean;
};

function ListWrapper(props: Props) {
  const {
    className,
    itemClassName,
    children,
    hasInfiniteScroll,
    next,
    hasMore,
    shouldScrollUpToTop,
    isFetching,
    hasFilter,
    listData,
    hasMedia,
    hasCheckbox,
    resultLabel,
    total,
    hasDescriptionOnNoItem,
    noItemIcon,
    isPulseLoaderType,
    isFixedButton,
    isMiniMessage,
    hasCustomLoading = false,
    isSkeletonTable,
  } = props;
  const scrollEl = useRef<HTMLDivElement>(null);
  const skeletonEl = useRef<HTMLDivElement>(null);
  useInfiniteScroll(scrollEl, skeletonEl, hasInfiniteScroll, hasMore, next, shouldScrollUpToTop);

  const isInitialLoad = listData === undefined;
  const isNotEmpty = isNotEmptyArray(listData);

  const renderSkeleton = (isBottom: boolean) => {
    return isPulseLoaderType ? (
      <ListLoader type={'pulse'} />
    ) : isSkeletonTable ? (
      <Skeleton type={'image'} height="100px" />
    ) : (
      <SkeletonListItems
        hasMedia={hasMedia}
        hasCheckbox={hasCheckbox}
        listClassName={isBottom ? styles.bottomSkeleton : ''}
        data-testid={isBottom ? 'listWrapper-bottom-skeleton' : undefined}
        itemClassName={itemClassName}
        numberOfItem={isBottom ? 2 : 3}
      />
    );
  };

  return (
    <>
      {!hasCustomLoading && (
        <LoadingGreenLine isLoading={isFetching && (!isInitialLoad || hasMore)} />
      )}
      <div
        className={cx(className, styles.listWrapper, isFixedButton && styles.fixedButton)}
        ref={scrollEl}
        data-testid="listWrapper-wrapper"
      >
        <div className={styles.list}>
          {(isInitialLoad || isNotEmpty) &&
          (resultLabel || isNotNullish(total)) && ( // TODO: remove result label from <NoResult>
              <section
                className={styles.searchResult}
                data-testid="listWrapper-searchResults-wrapper"
              >
                {resultLabel || (
                  <>
                    <Translate id="Common.List.List_Count" /> {total}
                  </>
                )}
              </section>
            )}
          <section>
            {isInitialLoad ? (
              renderSkeleton(false)
            ) : isNotEmpty ? (
              children
            ) : !hasFilter && !isFetching ? (
              <NoItem hasDescription={hasDescriptionOnNoItem} icon={noItemIcon} />
            ) : (
              <NoResult isMiniMessage={isMiniMessage} />
            )}
          </section>
        </div>
        <div ref={skeletonEl}>{!hasCustomLoading && hasMore && renderSkeleton(true)}</div>
      </div>
    </>
  );
}

ListWrapper.defaultProps = {
  className: '',
  itemClassName: '',
  shouldScrollBackToTop: false,
  hasInfiniteScroll: true,
  hasFilter: true,
  hasMedia: false,
  hasCheckbox: true,
  isPulseLoaderType: false,
  isFixedButton: false,
  isMiniMessage: false,
  isSkeletonTable: false,
};

export default ListWrapper;
