import { universalRouterProps } from 'core/universalRouter/types';
import { fetchVisualContentGroupList } from 'store/visualContentGroup/actions';

export default (
  { store: { dispatch } }: universalRouterProps,
  query: { keyword: string },
  params: { providerId: string },
) => {
  const { providerId } = params;
  const encodeQuery = {
    ...query,
    keyword: encodeURIComponent(query.keyword || ''),
  };

  return Promise.all([dispatch(fetchVisualContentGroupList(providerId, encodeQuery))]);
};
