import { Certification } from 'Certification-Types';
import { getToday } from 'utils/calendar';

export const defaultProviderDetail = {
  active: false,
  ancestors: [],
  rakutenStatus: 'SANDBOX',
  id: null,
  name: '',
  designations: [],
  currency: '',
  allowPointUsage: false,
  isTestProvider: false,
  certifications: [] as Certification[],
  canCreatePromotions: true,
  ariChannelManager: {},
  bmcChannelManager: {},
  groups: [],
  roundPositionForPriceCalculation: '0',
  cancellationPolicyRule: {
    applicationRule: 'STRICTEST',
    penaltyApplicationUnit: 'PER_EACH_STAY_NIGHT',
  },
  blackList: {
    market: [],
    distributor: [],
  },
  address: {
    address1: '',
    postcode: '',
    latitude: '0',
    longitude: '0',
  },
  paymentMethods: [],
  updateDateTime: '',
  createDateTime: '',
  media: undefined,
  parentPlaceId: null,
  nearbyTerminals: [],
  recommendedSpots: [],
  reviewLimitType: 'SHOW_ALL',
  providerLanguage: '',
  holidayCutoffWriteAccess: true,
  featureGroups: [],
  catchphrase: '',
  description: '',
  contacts: [
    {
      content: '',
      from: '',
      to: '',
      type: '',
    },
  ],
  childDefinition: {},
  bookingConstraint: {
    bookingCutOff: {
      cutOffDays: 0,
      cutOffTimeLimit: '00:00',
    },
    losRule: 'STRICTEST',
    creditCardForGuaranteeRequired: true,
    shortenReservationRule: 'MODIFY',
  },
  totalNumberOfRooms: 0,
  cancellationDeadLine: {
    cutOffDays: 0,
    cutOffTimeLimit: '00:00',
    holidayCutoffEnabled: false,
  },
  taxAndFees: [
    {
      id: null,
      code: '',
      charge: '',
      inclusiveness: true,
      currency: '',
      announceDate: getToday(),
      effectiveDate: getToday(),
      expiredDate: '',
      conditions: [
        {
          value: null,
          type: 'PERCENT',
        },
      ],
    },
  ],
  serviceAvailablePeriod: {
    checkInFrom: '15:00',
    checkInTo: '24:00',
    checkOut: '10:00',
  },
  notificationAddresses: [],
  introductions: [],
  bookingNotice: {
    notifications: [],
    questions: [],
  },
};
