import { FilterComponents, FilterComponent, FilterComponentStatus } from 'FilterComponent-Types';
import { ApiClient } from '@travel/api-client';
import { internalPrefix } from 'constants/api-prefix';

export const ApiFetchFilterComponents = (
  http: ApiClient,
  params: Object,
): Promise<FilterComponents> =>
  http
    .get(`${internalPrefix}/filterComponents/search`, { values: params })
    .then((data: FilterComponents) => data);

export const ApiFetchFilterComponent = (
  http: ApiClient,
  filterComponentId: string,
): Promise<FilterComponent> =>
  http
    .get(`${internalPrefix}/filterComponents/${filterComponentId}`)
    .then((data: FilterComponent) => data);

export const ApiPutFilterComponent = (
  http: ApiClient,
  data: FilterComponent,
): Promise<FilterComponentStatus> =>
  http
    .put(`${internalPrefix}/filterComponents/${data.id}`, { values: data })
    .then((data: FilterComponentStatus) => data)
    .catch((data: FilterComponentStatus) => data);

export const ApiDeleteFilterComponent = (
  http: ApiClient,
  filterComponentId: string,
): Promise<FilterComponentStatus> =>
  http
    .delete(`${internalPrefix}/filterComponents/${filterComponentId}`, {})
    .then((data: FilterComponentStatus) => data)
    .catch((data: FilterComponentStatus) => data);
