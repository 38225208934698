import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLanguage, setGlobalDayjsLocal } from '@travel/i18n';
import { Question } from '@travel/icons/ui';
import { NotificationDialog, PopupMessage } from '@travel/ui';

import ConfirmationPopup from 'components/ConfirmationPopup';
import HelmetWrapper from 'components/HelmetWrapper';
import { LinkItem } from 'constants/menuItems';
import isBetween from 'dayjs/plugin/isBetween';
import useTokenRefresh from 'hooks/useTokenRefresh';
import { getLoggedInExpiryDate, getLoggedInUserScope } from 'store/accessControl/selectors';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { initialResponse } from 'store/globalErrorDialog/initialValues';
import { getResponse } from 'store/globalErrorDialog/selectors';
import { getProviderGroupBaseInfo } from 'store/providerGroup/selectors';
import { getProviderBasicInfo } from 'store/providersInformation/selectors';
import { getCookie, setCookie, COOKIE_NAMES } from 'utils/cookieStorage';
import { getIsRFBUser } from 'utils/verifyTokenHelper';
import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';
import GlobalPopup from './components/GlobalPopup';
import Header from './components/Header';
import MainContent from './components/MainContent';
import NavigationMenu from './components/NavigationMenu';
import MenuFooter from './components/NavigationMenu/MenuFooter';
import MenuHeader from './components/NavigationMenu/MenuHeader';
import MenuItems from './components/NavigationMenu/MenuItems';
import NetworkError from './components/NetworkError';
import RFBMenu from './components/RFBMenu';

import { pushLocation } from 'store/__router/actions';
import { setInputLanguage } from 'store/languages/actions';
import { logout } from 'store/login/actions';
import { setMenuIsOpen } from 'store/menu/actions';

import { Translate } from 'core/translate';
import {
  createErrorLabel,
  getBasePath,
  getCurrentLink,
  getProviderRelatedId,
  getUserPlatform,
  isNotLogin,
  PLATFORM,
} from 'utils';
import { getDateDiffDays, getFormatedDate, getToday } from 'utils/calendar';
import { dispatchRatEvent } from 'utils/rat';
import {
  EVEREST_PROVIDER_ID_LENGTH,
  FOOTER_DIALOG_PARAMS,
  HOTEL_ID,
  PROVIDER_ID,
} from './constants';

import { getSelectedLanguage } from 'store/languages/selectors';
import { getIsInitialLoad, getVerifyTokenDetail } from 'store/login/selectors';
import { getMenuIsOpen } from 'store/menu/selectors';

import styles from './app.module.scss';

const PASSWORD_RESET_LINK = 'https://manage.travel.rakuten.com/partner/login/forgotPassword';
dayjs.extend(isBetween);

type DialogContent = {
  dialogTitle: ReactNode;
  dialogBody: ReactNode;
  primaryLabel: ReactNode;
  secondaryLabel: ReactNode;
};

export type Props = {
  children: ReactNode;
  params: {
    providerId?: string;
  };
  pathname: string;
  search: string;
  language: string;
  state: {
    [key: string]: any;
  };
};

function ConfirmDialogContent(props: { icon: ReactNode; title: ReactNode; message: ReactNode }) {
  const { icon, title, message } = props;
  return (
    <>
      <div className={styles.dialogTitle}>
        <span className={styles.dialogTitleIcon}>{icon}</span>
        <span className={styles.dialogTitleText}>{title}</span>
      </div>
      <div className={styles.dialogMessage}>{message}</div>
    </>
  );
}

function App(props: Props) {
  const { params, pathname, search, state } = props;
  const { providerId } = params;

  const dispatch = useDispatch();

  const userScope = useSelector(getLoggedInUserScope);
  const providerBasicInfo = useSelector(getProviderBasicInfo);
  const selectedLanguage = useSelector(getSelectedLanguage);
  const providerGroupBasicInfo = useSelector(getProviderGroupBaseInfo);
  const isMenuOpen = useSelector(getMenuIsOpen);
  const loggedInUserExpiryDate = useSelector(getLoggedInExpiryDate);
  const uiLanguage = useSelector(getLanguage);
  const tokenInfo = useSelector(getVerifyTokenDetail);
  const isRFBUser = getIsRFBUser(tokenInfo.loginScheme);

  const todaysDate = getToday();
  const momentExpiredDate = getFormatedDate(loggedInUserExpiryDate);
  const expiryDaysCount = getDateDiffDays(todaysDate, momentExpiredDate);

  // For Global Popup Message, Success/Error
  const {
    status: apiStatus,
    shouldHideHeading,
    isSuccess,
    isDelete,
    isResetPassword,
    isProviderCampaign,
    errorDialogTitle = '',
    errorTitle = '',
    successDialogTitle = '',
    successTitle = '',
    successSubTitle = '',
    message,
    labelId,
    data,
    count,
  } = useSelector(getResponse);

  const platform = getUserPlatform(userScope);
  const providerRelatedId = getProviderRelatedId(userScope, params);
  const basePath = getBasePath(userScope, providerRelatedId);
  const titleName =
    providerGroupBasicInfo?.name ||
    providerBasicInfo?.name ||
    (platform === PLATFORM.INTERNAL && 'Internal') ||
    '';
  const isNotLoginPage = isNotLogin(pathname);
  const newMessage = labelId ? createErrorLabel(labelId, data, count) : message;

  const [isShowDialog, setShowDialog] = useState<boolean>(!!apiStatus);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false);
  const [confirmationDialogLink, setConfirmationDialogLink] = useState<string>('/');

  const [isShowPasswordExpired, setIsShowPasswordExpired] = useState<boolean>(
    !!loggedInUserExpiryDate && expiryDaysCount <= 5 && isNotLoginPage && !isRFBUser,
  );

  const [confirmationDialogContent, setConfirmationDialogContent] = useState<DialogContent>({
    dialogTitle: '',
    dialogBody: '',
    primaryLabel: '',
    secondaryLabel: '',
  });

  setGlobalDayjsLocal(selectedLanguage);

  const onCloseMenu = () => {
    dispatch(setMenuIsOpen(!isMenuOpen));
  };

  const handleLanguageChange = useCallback(
    (newLanguage: string) => {
      const currentLink = getCurrentLink(pathname, platform);
      dispatch(setInputLanguage(newLanguage));
      setCookie(COOKIE_NAMES.INPUT_LANGUAGE, `${newLanguage}#${currentLink}#${uiLanguage}`);
      dispatch(pushLocation({ pathname, search, state }));
    },
    [dispatch, pathname, platform, search, state, uiLanguage],
  );

  const onCloseDialog = () => {
    dispatch(fetchGlobalErrorDialogAsync.success(initialResponse));
    setShowDialog(false);
  };

  const handleClickFooterLinks = (linkItem: LinkItem) => {
    const token = getCookie(COOKIE_NAMES.APP_TOKEN_NAME) || '';
    const { key, link } = linkItem;
    const isFAQ = key === 'FAQ';
    const idType = providerId?.length === EVEREST_PROVIDER_ID_LENGTH ? PROVIDER_ID : HOTEL_ID; // Everest Provider ID has 14 digits
    const redirectTo = key === 'LUX_TC' ? link : `${link}?${idType}=${providerId}&token=${token}`;

    setConfirmationDialogContent({
      dialogTitle: '',
      dialogBody: <ConfirmDialogContent {...FOOTER_DIALOG_PARAMS[key]} />,
      primaryLabel: isFAQ ? (
        <Translate id="FAQ.popup_faq.button.confirm" />
      ) : (
        <Translate id="FAQ.popup_elearning.button.confirm" />
      ),
      secondaryLabel: isFAQ ? (
        <Translate id="FAQ.popup_faq.button.cancel" />
      ) : (
        <Translate id="FAQ.popup_elearning.button.cancel" />
      ),
    });

    setConfirmationDialogLink(redirectTo);

    setIsOpenConfirmation(true);
  };

  const handleOnClose = () => {
    setIsOpenConfirmation(false);
  };

  const handleOnConfirm = () => {
    if (confirmationDialogLink.includes('forgotPassword')) {
      dispatch(logout());
      dispatch(pushLocation('/login/forgotPassword'));
    } else {
      window.open(confirmationDialogLink, '_blank');
    }
    setIsOpenConfirmation(false);
  };

  const handleOnPasswordExpiredConfirm = () => {
    setConfirmationDialogContent({
      dialogTitle: '',
      dialogBody: (
        <ConfirmDialogContent
          icon={<Question />}
          title={<Translate id={'System_Account_Management.Popup.Exit_Extranet.Main'} />}
          message={<Translate id={'System_Account_Management.Popup.Exit_Extranet.Sub'} />}
        />
      ),
      primaryLabel: <Translate id={'Common.Confirm'} />,
      secondaryLabel: <Translate id={'Common.Cancel'} />,
    });
    setConfirmationDialogLink(PASSWORD_RESET_LINK);
    setIsShowPasswordExpired(false);
    setIsOpenConfirmation(true);
  };

  useEffect(() => {
    if (apiStatus && !isShowDialog) {
      setShowDialog(!isShowDialog);
    }
  }, [apiStatus, isShowDialog]);

  useEffect(() => {
    dispatchRatEvent();
  }, []);

  // refresh token
  useTokenRefresh(isNotLoginPage);

  return (
    <div className={styles.root}>
      <HelmetWrapper title={'Rakuten Travel - Supplier site'} />
      {isNotLoginPage && platform !== PLATFORM.OUTSIDE && (
        <NavigationMenu isExpand={isMenuOpen}>
          <MenuHeader
            userScope={userScope}
            id={providerRelatedId}
            name={titleName}
            basePath={basePath}
            onCloseMenu={onCloseMenu}
            isNotLoginPage={isNotLoginPage}
            isMenuOpen={isMenuOpen}
          />
          <RFBMenu isRFBUser={isRFBUser} isMenuOpen={isMenuOpen} />
          <MenuItems
            platform={platform}
            pathname={props.pathname}
            basePath={basePath}
            isMenuOpen={isMenuOpen}
            onCloseMenu={onCloseMenu}
          />
          {platform === PLATFORM.EXTRANET && (
            <MenuFooter onClickFooterLink={handleClickFooterLinks} isMenuOpen={isMenuOpen} />
          )}
        </NavigationMenu>
      )}
      <div className={styles.appContent}>
        <Header
          onCloseMenu={onCloseMenu}
          providerId={providerId || ''}
          onLanguageChange={handleLanguageChange}
        />
        <NetworkError />
        <ErrorBoundary>
          <MainContent isMenuOpen={isMenuOpen}>{props.children}</MainContent>
        </ErrorBoundary>
        <Footer />
      </div>
      <PopupMessage
        isOpen={isShowDialog}
        isSuccess={!!isSuccess}
        isDelete={isDelete}
        onCloseDialog={onCloseDialog}
        errorMessage={newMessage}
        shouldHideHeading={shouldHideHeading}
        isDisplayedOnDialog={isShowDialog}
        customDialogTitle={successDialogTitle ?? null}
        deleteDialogLabel={{
          title: <Translate id={'Delete_Common.Delete_Success.Title'} />,
          subTitle: <Translate id={'Delete_Common.Delete_Success.Delete'} />,
          message: (
            <Translate
              id={'Delete_Common.Delete_Success.Message'}
              data={{
                item_name: <span className={styles.lblGreen}>{newMessage}</span>,
              }}
            />
          ),
        }}
        popUpLabel={{
          errorDialogTitle: errorDialogTitle || <Translate id={'Common.Popup.Error.Title'} />,
          errorTitle: errorTitle || <Translate id={'Common.Detail.Popup.Error.Main_Message'} />,
          successTitle:
            successTitle ||
            (isResetPassword ? (
              <Translate id="loginlogout.forget_password_information.pop_up.title" />
            ) : isProviderCampaign ? (
              <Translate id="Sponsor_Sales_Promotion_Provider.Application_Page.Submit_Message_Title" />
            ) : (
              <Translate id={'Common.Detail.Save_Success.Main_Message'} />
            )),
          successSubTitle:
            successSubTitle ||
            (isResetPassword ? (
              <Translate id="loginlogout.forget_password_information.pop_up.message" />
            ) : isProviderCampaign ? (
              <Translate id="Sponsor_Sales_Promotion_Provider.Application_Page.Submit_Message" />
            ) : (
              <Translate id={'Common.Detail.Save_Success.Message'} data={{ object_type: 'Form' }} />
            )),
        }}
        buttonText={
          isDelete ? (
            <Translate id={'Delete_Common.Delete_Success.OK'} />
          ) : (
            <Translate id={'Common.Detail.Save_Success.OK'} />
          )
        }
      />
      <ConfirmationPopup
        isOpenConfirmation={isOpenConfirmation}
        onClose={handleOnClose}
        onConfirm={handleOnConfirm}
        dialogContent={confirmationDialogContent}
        primaryLabel={confirmationDialogContent.primaryLabel}
        secondaryLabel={confirmationDialogContent.secondaryLabel}
      />
      {isShowPasswordExpired && (
        <NotificationDialog>
          <Translate
            id="System_Account_Management.Password_Expire.Main"
            data={{
              expire_days_count: <span className={styles.daysExpire}>{expiryDaysCount}</span>,
            }}
          />
          <Translate id="System_Account_Management.Password_Expire.Sub" />
          <Translate
            id="System_Account_Management.Edit.system_message.password_reset"
            data={{
              reset_password_link: (
                <p className={styles.resetPasswordLink} onClick={handleOnPasswordExpiredConfirm}>
                  {PASSWORD_RESET_LINK}
                </p>
              ),
            }}
          />
        </NotificationDialog>
      )}
      <GlobalPopup />
    </div>
  );
}

export default App;
