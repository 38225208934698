import React from 'react';
import {
  Feature,
  Features,
  Media,
  Provider,
  Filter,
  BrowseParameters,
  IconBrowseParameters,
  ConsumerFilterFeatureBrowseParameters,
} from 'Feature-Types';
import { OBJECT_TYPE } from 'constants/feature';
import { Translate, getTranslation } from '@travel/translation';

export const sort = {
  options: [
    {
      text: <Translate id="Supplier_Enumeration.Sort.Feature_Score.High_Low" />,
      value: 'score=desc',
    },
    {
      text: <Translate id="Supplier_Enumeration.Sort.Feature_Score.Low_High" />,
      value: 'score=asc',
    },
  ],
  selectedValue: 0,
};

export const filter: Array<Filter> = [
  {
    label: <Translate id="Feature_List_Management.Create.Upper_Layer.Status_of_Selection" />,
    id: 'status',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Status.Selection.Selected" />,
        value: 'selected',
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Status.Selection.Unselected" />,
        value: 'deselected',
        isCheck: false,
      },
    ],
  },
];

export const initialBrowseParameter: BrowseParameters = {
  keyword: '',
  searchCondition: '',
  filter: [
    {
      label: <Translate id="Feature_List_Management.Detail.Status.Title" />,
      id: 'status',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Status.Activation.Active" />,
          value: 'true',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Activation.Inactive" />,
          value: 'false',
          isCheck: false,
        },
      ],
    },
  ],
  sort,
  offset: 0,
  limit: 28,
};

export const initialSearchBrowseParameter: BrowseParameters = {
  keyword: '',
  searchCondition: '',
  filter,
  sort,
  offset: 0,
  limit: 28,
};

export const initialFeatures: Features = {
  offset: 0,
  limit: 28,
  total: 0,
  contents: [],
};

export const initialMedia: Media = {
  id: '',
  thumbnailUrl: '',
  order: 0,
  type: 'ICON',
  url: '',
};

export const initialProvider: Provider = {
  bookingConstraint: {
    bookingCutoffDays: 0,
    bookingTimeLimit: 0,
    cancelCutoffDays: 0,
    cancelTimeLimit: 0,
    creditCardForGuaranteeRequired: false,
    shortenReservationRule: 0,
  },
  cancellationPolicyRule: {
    applicationRule: '',
    penaltyApplicationUnit: '',
  },
  childDefinition: {
    childAgeTo: 0,
  },
  distributorBlackList: [],
  id: '',
  marketBlackList: [],
  media: [],
  providerActivate: false,
  providerAllowPointUsage: false,
  providerCancellationPolicyRule: false,
  providerCertification: '',
  providerChain: '',
  providerChannelManager: '',
  providerCountry: '',
  providerCurrency: '',
  providerDistributionBlacklist: '',
  providerItcInCharge: '',
  providerName: '',
  providerPaymentMethod: '',
  providerRoundPositionForPriceCalculation: '',
  providerStarRating: 0,
  providerStatus: {
    activeByProvider: false,
    activeByRakuten: false,
  },
  providerType: '',
};

export const initialFeature: Feature = {
  status: false,
  assignedFeatureGroups: [],
  childFeatures: [],
  description: '',
  id: '',
  media: initialMedia,
  management: '',
  name: '',
  parentFeature: null,
  provider: initialProvider,
  providerId: '',
  score: 0,
  selected: false,
  title: '',
  size: {
    count: 0,
    sizeTypes: [],
  },
  active: true,
  featureGroupNames: [],
};

export const initialIconBrowseParameter: IconBrowseParameters = {
  keyword: '',
  searchCondition: '',
  filter: [],
  offset: 0,
  limit: 28,
};

export const consumerFilter: Array<Filter> = [
  {
    label: (
      <Translate id="Filter_Group_List.Add_Feature_Filter.Search.Filter.Expected_Object_Type" />
    ),
    id: 'objectType',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Expected_Object_Type.Any" />,
        value: OBJECT_TYPE.ANY,
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Expected_Object_Type.Provider" />,
        value: OBJECT_TYPE.PROVIDER,
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Expected_Object_Type.Item" />,
        value: OBJECT_TYPE.ITEM,
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Expected_Object_Type.Rate_Plan" />,
        value: OBJECT_TYPE.PLAN,
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Expected_Object_Type.Visual_Content" />,
        value: OBJECT_TYPE.MEDIA,
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Expected_Object_Type.Extra_Rate" />,
        value: OBJECT_TYPE.EXTRA_AGE_RATE,
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Expected_Object_Type.Guest_Review" />,
        value: OBJECT_TYPE.GUEST,
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Expected_Object_Type.Sales_Promotion" />,
        value: OBJECT_TYPE.SALES_PROMOTION,
        isCheck: false,
      },
    ],
  },
  {
    label: <Translate id="Filter_Group_List.Add_Feature_Filter.Search.Filter.Status_Selection" />,
    id: 'selected',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Status.Selection.Selected" />,
        value: 'true',
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Status.Selection.Unselected" />,
        value: 'false',
        isCheck: false,
      },
    ],
  },
];

export const initialConsumerFilterFeatureBrowseParameter: ConsumerFilterFeatureBrowseParameters = {
  searchText: '',
  filter: consumerFilter,
  offset: 0,
  sort: {
    options: [
      {
        text: getTranslation({ id: 'Supplier_Enumeration.Sort.Feature_Score.High_Low' }),
        value: 'score=desc',
      },
      {
        text: getTranslation({ id: 'Supplier_Enumeration.Sort.Feature_Score.Low_High' }),
        value: 'score=asc',
      },
    ],
    selectedValue: 'score=desc',
  },
};
