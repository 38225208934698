import React, { ReactNode } from 'react';

import { Translate } from 'core/translate';

type SiteMap = {
  [key: string]: {
    authKey: string;
    label: ReactNode;
  };
};

/** NOTE: Let's use this object instead of defining in SITE_MENU from point of consistency. */
export const SITE_MENU_MAP: SiteMap = {
  // Cancellation Policy Management
  CANCELLATION_POLICY: {
    authKey: 'CxlPolMgmt',
    label: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Cancellation_Policy_Management" />,
  },
  ALIAS_MANAGEMENT: {
    authKey: 'AliasMgmt',
    label: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Alias_Management" />,
  },
  MEDIA_MANAGEMENT: {
    authKey: 'MediaMgmt',
    label: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Media_Management" />,
  },
  CONSUMER_SITE_FILTER_GROUP_MANAGEMENT: {
    authKey: 'ConsSFGMgmt',
    label: (
      <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Consumer_Site_Filter_Group_Management" />
    ),
  },
  CONSUMER_SITE_FILTER_COMPONENT_MANAGEMENT: {
    authKey: 'ConsSFCMgmt',
    label: (
      <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Consumer_Site_Filter_Component_Management" />
    ),
  },
  // Sales Promotions
  GOTO_PROMOTION: {
    authKey: 'GoToCpnMgmt',
    label: (
      <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Go_To_Travel_Coupoun_Management" />
    ),
  },
  INTRODUCTION_CONTENT: {
    authKey: 'IntroductionContentsMgmt',
    label: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Intro_Contents" />,
  },
  HIGHLIGHT_CONTENT: {
    authKey: 'HighlightContentsMgmt',
    label: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Highlight_Contents" />,
  },
  OBJECT_SCORE: {
    authKey: 'ScoreRankList',
    label: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Object_Score_Management" />,
  },
  // Provider Data Translation
  PROVIDER_DATA_TRANSLATION: {
    authKey: 'ManualTranslationProviderContents',
    label: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Provider_Data_Translation" />,
  },
  SEO_MANAGEMENT: {
    authKey: 'SeoMgmt',
    label: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.SEO_Management" />,
  },
  PROVIDER_RESTRICTION_MANAGEMENT: {
    authKey: 'ProvRestrictionMgmt',
    label: <Translate id="InternalTools_Provider_Restriction.Menu.Provider_Restrictions" />,
  },
};

/** CAUTION: This is also used in access control.
 *  NOTE: Let's define authKey and label in SITE_MENU_MAP to keep consistency. */
const SITE_MENU: {
  [key: string]: ReactNode;
} = {
  //Home
  Home: <Translate id="Supplier_Menu_Sitemap.Common.Home" />,
  // FAQ
  FAQ: <Translate id="Supplier_Menu_Sitemap.Common.FAQ" />,
  //   E-Learning
  eLearing: <Translate id="Supplier_Menu_Sitemap.Common.E_Learning" />,
  // Luxury Terms and Conditions
  LUX_TC: <Translate id="Supplier_Menu_Sitemap.Common.Lux_TC" />,
  //=== Extranet ===
  //== Provider Information Section
  ProvInfoSec: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Provider_Information" />,
  // Provider Info Management
  ProvInfoMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Provider_Information_Management" />
  ),
  // Cancellation Policy Management
  CxlPolMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Cancellation_Policy_Management" />
  ),
  //== Photo/Video Management Section
  PhotoVideoSec: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Photo_Video_Management" />,
  // Visual Content Group Management
  VisConGrpMgmtEx: (
    <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Visual_Content_Group_Management" />
  ),
  // Media Management
  MediaMgmt: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Media_Management" />,

  //== Item/Plan Management Section
  ItemPlanSec: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Item_Plan_Management" />,
  // Item Management
  ItemMgmt: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Items" />,
  // Inventory Control
  InvtryMgmt: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Inventory" />,
  // Rate Plan Management
  RatePlanMgmt: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Rate_Plan_Management" />,
  // Rate and Availability Management
  PlanMgmt: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Plan_Management" />,
  // Extra Rate Management
  XtraRateMgmt: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Extra_Rate_Management" />,

  //== Sales Promotions Section
  SalesPromSec: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Sales_Promotions" />,
  // Provider Cost Sales Promotion Management
  ProvCostSlsPromo: (
    <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Provider_Cost_Sales_Promotion_Management" />
  ),
  // Sponsored Sales Promotion Management
  SpoSlsPromoMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Sponsored_Sales_Promotion_Management" />
  ),

  //== Reservations Section
  ReservSec: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Reservations" />,
  // Booking Management
  BookMgmtEx: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Booking_Management" />,
  // Review Management
  RevMgmt: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Review_Management" />,

  //== Finance Management Section
  FinancSec: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Finance_Management" />,
  // Accounting Report
  AccReport: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Accounting_Report" />,

  //== Access Control Section
  AccessCtrlSec: (
    <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Provider_Access_Control_Management" />
  ),
  // System Account Management
  SysAcctAccCtrlMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.System_Account_Control_Management" />
  ),

  //Notification
  NotificationEx: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Notification" />,

  // Asset Control
  // AssetControl: <Translate id="Supplier_Menu_Sitemap.Extranet.Section_Titles.Assets Control" />,

  // Bookings and Promotions
  // BookPromo: <Translate id="Supplier_Menu_Sitemap.Extranet.Section_Titles.Booking_Promotions" />,

  // Content Management
  // ContMgt: <Translate id="Supplier_Menu_Sitemap.Extranet.Section_Titles.Content_Management" />,

  // Settings
  // Settings: <Translate id="Supplier_Menu_Sitemap.Extranet.Section_Titles.Settings" />,

  //=== Internal ===
  //== Provider Management Section
  ProvMgmtSec: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Provider_Management_Section" />
  ),
  // Providers
  ProvMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Provider_Management" />,
  // Provider Groups
  ProvGropMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Provider_Group_Management" />
  ),
  // Provider Restriction Management
  [SITE_MENU_MAP.PROVIDER_RESTRICTION_MANAGEMENT.authKey]:
    SITE_MENU_MAP.PROVIDER_RESTRICTION_MANAGEMENT.label,

  //== Reservation Management Section
  ReservationControl: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Section_Titles.Reservation_Control" />
  ),
  // Booking Management
  BookMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Booking_Management" />,
  // DP Reservation
  DPBookMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.dp_reservations" />,
  //Fax Management
  FaxMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Fax_Reservation_Notification" />
  ),

  //== Conectivity Management Section
  ConMgmtSec: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Connectivity_Management" />,
  // Channel Manager List
  ChanlMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Channel_Manager_Management" />
  ),
  // Certificate Management
  CertificationManagement: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Certification_Management" />
  ),
  // Distributor Management
  DistrMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Distributor_Management" />,
  // Rate Plan Distribution
  RatePlanDistr: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Rate_Plan_Distribution" />
  ),

  //==Site Content Control Section
  SiteContentControl: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Section_Titles.Site_Content_Control" />
  ),
  LabelMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Section_Titles.Labels_And_Translation" />
  ),
  // Place & Spot Management
  PlcSpotMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Place_Spot_Management" />,
  // Visual Content Group Management
  VisConGrpMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Visual_Content_Group_Management" />
  ),
  // Media
  Media: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Media_Management" />,
  // Consumer Site Filter Component Management
  ConsSFCMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Consumer_Site_Filter_Component_Management" />
  ),
  // Consumer Site Filter Group Management
  ConsSFGMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Consumer_Site_Filter_Group_Management" />
  ),
  // Feature Group Management
  FeatGrpMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Feature_Group_Management" />
  ),
  // Feature Management
  FeatMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Feature_Management" />,
  // Review Examination
  RevEx: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Review_Management" />,
  // Traveler Label Management
  TvlrLblMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Traveler_Label_Management" />
  ),
  // Extranet Label Management
  ExtrntLblMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Extranet_Label_Management" />
  ),
  // SEO Label Management
  SeoLblMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.SEO_Label_Management" />,
  // Traveler Common Label Management
  TvlrCommonLblMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Traveler_Common_Label_Management" />
  ),
  // Balloon Label Management
  DPLblMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.JRDP_Label_Management" />,
  // My Page Label Management
  MyPageLblMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.MyPage_Label_Management" />
  ),
  // Rental Car Label Management
  RentalCarLblMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.RentalCar_Label_Management" />
  ),
  // Traveler App Label Management
  TravelAppLblMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.TravelApp_Label_Management" />
  ),
  // Manual Translation
  TranslationMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Manual_Translation" />,
  // Forbidden Word Management
  ForbiddenWordMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Forbidden_Word_Management" />
  ),
  // luxury Invitation
  LuxuryInvitation: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Luxury_invitation" />,
  // SEO Management
  [SITE_MENU_MAP.SEO_MANAGEMENT.authKey]: SITE_MENU_MAP.SEO_MANAGEMENT.label,

  //== Promotions Section
  Promotions: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Section_Titles.Sales_Promotions" />
  ),
  // Basic Rakuten Sales Promotion
  RakutenSP: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Basic_Rakuten_Sales_Promotion" />
  ),
  // Sponsored Sales Promotion
  SponsoredSP: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Sponsored_Sales_Promotion_Management" />
  ),
  // User Segment
  UserSegment: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.User_Segment_Upload" />,
  // Go To Travel Coupon
  [SITE_MENU_MAP.GOTO_PROMOTION.authKey]: SITE_MENU_MAP.GOTO_PROMOTION.label,

  //== internal User Management Section
  InternalUserMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Section_Titles.Internal_User_Management" />
  ),
  // Internal User Access Control Management
  IntUsrAccCtrlMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Internal_User_Access_Control_Management" />
  ),
  // Alias Management
  AliasMgmt: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Alias_Management" />,

  //Notification
  Notification: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Notification" />,
  //Announcements
  Announcement: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Announcement" />,

  // Domestic Extranet: Full Access
  v1ExtrntFull: (
    <Translate id="System_Account_Management.Permission_Settings.V1_Extranet.Full_Access" />
  ),
  // Domestic Extranet: Customize Page
  v1ExtrntCstmPage: (
    <Translate id="System_Account_Management.Permission_Settings.V1_Extranet.Customize_Page" />
  ),

  // Master Control
  // MasterControl: (
  //   <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Section_Titles.Master_Control" />
  // ),

  // Provider Group Extranet Control
  // ProvGroupControl: (
  //   <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Section_Titles.Provider_Group_Extranet_Control" />
  // ),

  /** Provider Group Extranet */
  // Provider Management
  PgProviderMgmt: (
    <Translate id="Supplier_Menu_Sitemap.Provider_Extranet.Tools.Provider_Management" />
  ),
  // Basic Information
  PgBasicInfo: <Translate id="Supplier_Menu_Sitemap.Provider_Extranet.Tools.Basic_Information" />,
  // Reservation Management
  PgReservation: (
    <Translate id="Supplier_Menu_Sitemap.Provider_Extranet.Tools.Reservation_Management" />
  ),
  BookMgmtCSQMO: (
    <Translate id="Internal_Access_User_Control.Access.Management.Res_Mgmt_Member_Easy_ID" />
  ),
  UserSegMgmt: <Translate id="Internal_Access_User_Control.Alias.Management.User_Segment" />,
  IntroductionContentsMgmt: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Intro_Contents" />,
  HighlightContentsMgmt: <Translate id="Supplier_Menu_Sitemap.Extranet.Tools.Highlight_Contents" />,
  [SITE_MENU_MAP.OBJECT_SCORE.authKey]: SITE_MENU_MAP.OBJECT_SCORE.label,
  [SITE_MENU_MAP.PROVIDER_DATA_TRANSLATION.authKey]: SITE_MENU_MAP.PROVIDER_DATA_TRANSLATION.label,
} as const;

export default SITE_MENU;
