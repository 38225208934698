import { universalRouterProps } from 'core/universalRouter/types';
import { fetchMediaList } from 'store/media/actions';

export type Context = {
  params: { providerId: string };
  query: { [key: string]: string };
};

export default ({ store: { dispatch } }: universalRouterProps, context: Context) => {
  const { params, query } = context;
  return Promise.all([dispatch(fetchMediaList(params.providerId, query))]);
};
