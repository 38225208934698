import {
  QueryParams,
  ReviewList,
  ReviewDetail,
  ApiStatus,
  ReviewStatus,
  ReplyStatus,
  ReviewHistory,
  ReplyHistory,
} from 'ReviewExamination-Types';
import { ApiClient } from '@travel/api-client';

export const ApiFetchReviewList = (http: ApiClient, params: QueryParams) =>
  http.get(`/reviews/internal`, { values: params }).then((data: ReviewList) => data);

export const ApiFetchReview = (http: ApiClient, reviewId: string) =>
  http.get(`/reviews/internal/${reviewId}`).then((data: ReviewDetail) => data);

export const ApiFetchReviewHistory = (http: ApiClient, reviewId: string) =>
  http.get(`/reviews/internal/${reviewId}/history`).then((data: ReviewHistory) => data);

export const ApiFetchReplyHistory = (http: ApiClient, reviewId: string) =>
  http.get(`/reviews/internal/${reviewId}/replyHistory`).then((data: ReplyHistory) => data);

export const ApiUpdateReviewStatus = (http: ApiClient, reviewId: string, values: ReviewStatus) =>
  http
    .put(`/reviews/internal/${reviewId}/status`, { values: values })
    .then((data: ApiStatus) => data);

export const ApiUpdateReplyStatus = (http: ApiClient, reviewId: string, values: ReplyStatus) =>
  http
    .put(`/reviews/internal/${reviewId}/replyStatus`, { values: values })
    .then((data: ApiStatus) => data);
