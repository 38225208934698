import { universalRouterProps } from 'core/universalRouter/types';
import { fetchApplicationDetail, fetchRatePlanSuggestion } from 'store/applicationDetail/actions';
import { fetchCampaign } from 'store/campaign/actions';

type UrlParams = {
  params: {
    campaignId: string;
    applicationId: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, urlParams: UrlParams) => {
  const { campaignId, applicationId } = urlParams.params;

  return Promise.all([
    dispatch(fetchApplicationDetail(campaignId, applicationId)),
    dispatch(fetchCampaign(campaignId)),
    dispatch(fetchRatePlanSuggestion(campaignId, applicationId)),
  ]);
};
