import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  AnnouncementList,
  AnnouncementErrors,
  AnnouncementListParams,
  AnnouncementDetail,
  ApiStatus,
  UploadResult,
  UserData,
  UserSearchParams,
} from 'Announcement-Types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import {
  ApiFetchAnnouncementList,
  ApiFetchAnnouncementDetail,
  ApiUploadFile,
  ApiCreateAnnouncement,
  ApiUpdateAnnouncement,
  ApiDeleteAnnouncement,
  ApiExportUploadFile,
  ApiDownloadUserListFile,
  ApiSearchUser,
} from './apis';
import { pushLocation } from 'store/__router/actions';

export const fetchAnnouncementListAsync = createAsyncAction(
  'FETCH_ANNOUNCEMENT_LIST_REQUEST',
  'FETCH_ANNOUNCEMENT_LIST_SUCCESS',
  'FETCH_ANNOUNCEMENT_LIST_FAILURE',
)<undefined, AnnouncementList, AnnouncementErrors[]>();

export const fetchAnnouncementDetailAsync = createAsyncAction(
  'FETCH_ANNOUNCEMENT_DETAIL_REQUEST',
  'FETCH_ANNOUNCEMENT_DETAIL_SUCCESS',
  'FETCH_ANNOUNCEMENT_DETAIL_FAILURE',
)<undefined, AnnouncementDetail, ApiStatus[]>();

export const uploadFileAsync = createAsyncAction(
  'UPLOAD_FILE_REQUEST',
  'UPLOAD_FILE_SUCCESS',
  'UPLOAD_FILE_FAILURE',
)<undefined, UploadResult, ApiStatus[]>();

export const createAnnouncementAsync = createAsyncAction(
  'CREATE_ANNOUNCEMENT_REQUEST',
  'CREATE_ANNOUNCEMENT_SUCCESS',
  'CREATE_ANNOUNCEMENT_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const updateAnnouncementAsync = createAsyncAction(
  'UPDATE_ANNOUNCEMENT_REQUEST',
  'UPDATE_ANNOUNCEMENT_SUCCESS',
  'UPDATE_ANNOUNCEMENT_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const deleteAnnouncementAsync = createAsyncAction(
  'DELETE_ANNOUNCEMENT_REQUEST',
  'DELETE_ANNOUNCEMENT_SUCCESS',
  'DELETE_ANNOUNCEMENT_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const downloadFileAsync = createAsyncAction(
  'DOWNLOAD_FILE_REQUEST',
  'DOWNLOAD_FILE_SUCCESS',
  'DOWNLOAD_FILE_FAILURE',
)<undefined, Blob, ApiStatus[]>();

export const searchUserAsync = createAsyncAction(
  'SEARCH_USER_REQUEST',
  'SEARCH_USER_SUCCESS',
  'SEARCH_USER_FAILURE',
)<undefined, AnnouncementDetail, ApiStatus[]>();

export const clearAnnouncement = createStandardAction('CLEAR_ANNOUNCEMENT')();

export const fetchAnnouncementList = (
  params: AnnouncementListParams,
  rejectIfFetching?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isFetching = getState().announcement.isFetching;
  if (isFetching && rejectIfFetching) return;

  dispatch(fetchAnnouncementListAsync.request());
  try {
    const res = await ApiFetchAnnouncementList(apiClient, params);
    dispatch(fetchAnnouncementListAsync.success(res));
  } catch (error) {
    dispatch(fetchAnnouncementListAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const fetchAnnouncementDetail = (announcementId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchAnnouncementDetailAsync.request());
  try {
    const res = await ApiFetchAnnouncementDetail(apiClient, announcementId);
    dispatch(fetchAnnouncementDetailAsync.success(res));
  } catch (error) {
    dispatch(fetchAnnouncementDetailAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const createAnnouncement = (announcement: AnnouncementDetail): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(createAnnouncementAsync.request());

  try {
    const res = await ApiCreateAnnouncement(apiClient, announcement);
    dispatch(createAnnouncementAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    if (res.announcementId) {
      dispatch(pushLocation(`/internal/announcement/${res.announcementId}`));
    }
  } catch (error) {
    dispatch(createAnnouncementAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const updateAnnouncement = (
  announcement: AnnouncementDetail,
  state: {
    prevSearch: string;
  },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateAnnouncementAsync.request());

  try {
    const res = await ApiUpdateAnnouncement(apiClient, announcement);
    dispatch(updateAnnouncementAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(
      pushLocation({ pathname: `/internal/announcement/${announcement.announcementId}`, state }),
    );
  } catch (error) {
    dispatch(updateAnnouncementAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const deleteAnnouncement = (
  notificationId: string,
  notificationTitle: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteAnnouncementAsync.request());
  try {
    const res = await ApiDeleteAnnouncement(apiClient, notificationId);
    dispatch(deleteAnnouncementAsync.success(res));
    dispatch(
      fetchGlobalErrorDialogAsync.success({ ...res, message: notificationTitle, isDelete: true }),
    );
    dispatch(pushLocation(`/internal/announcement`));
  } catch (err) {
    dispatch(deleteAnnouncementAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
  }
};

export const uploadFile = (
  idType: string,
  file: File,
): AppThunk<Promise<UploadResult | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(uploadFileAsync.request());
  const formData = new FormData();
  formData.append('csvFile', file, file.name);
  formData.append('targetUserIdType', idType);

  try {
    const res = await ApiUploadFile(apiClient, formData);
    dispatch(uploadFileAsync.success(res));
    return res;
  } catch (error) {
    dispatch(uploadFileAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const exportUploadCSV = (importId: string): AppThunk<Promise<Blob | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(downloadFileAsync.request());
  try {
    const res = await ApiExportUploadFile(apiClient, importId);
    dispatch(downloadFileAsync.success(res));
    return res;
  } catch (error) {
    dispatch(downloadFileAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const exportUserListCSV = (
  announceId: string,
): AppThunk<Promise<Blob | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(downloadFileAsync.request());
  try {
    const res = await ApiDownloadUserListFile(apiClient, announceId);
    dispatch(downloadFileAsync.success(res));
    return res;
  } catch (error) {
    dispatch(downloadFileAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const searchUser = (
  params: UserSearchParams,
): AppThunk<Promise<UserData | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(searchUserAsync.request());
  try {
    const res = await ApiSearchUser(apiClient, params);
    dispatch(searchUserAsync.success(res));
    return res;
  } catch (error) {
    dispatch(searchUserAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};
