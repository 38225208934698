import React, { ReactNode } from 'react';
import { cx } from '@travel/utils';
import styles from './components.module.scss';

type Props = {
  children: ReactNode;
  isMenuOpen: boolean;
};

function MainContent(props: Props) {
  return (
    <div className={cx(styles.mainContent, !props.isMenuOpen && styles.menuClosed)}>
      {props.children}
    </div>
  );
}

export default MainContent;
