import React from 'react';
import { FeatureGroupSearch, BrowseParameters, checkboxOptionGroup } from 'FeatureGroup-Types';

import { Translate } from 'core/translate';

export const initialFeatureGroup = {
  id: '',
  management: '',
  name: '',
  active: false,
  expectedObjectType: '',
  features: [],
  tangible: '',
};

export const initialFeatureGroupSearch: FeatureGroupSearch = {
  xtrFeatureGroups: [],
  selectedFeatureIds: [],
};

export const initialBrowseParameter: BrowseParameters = {
  keyword: '',
  searchCondition: '',
  filter: [
    {
      label: <Translate id="Feature_Group_Management.List.Filter.Status" />,
      id: 'status',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Status.Activation.Active" />,
          value: 'true',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Activation.Inactive" />,
          value: 'false',
          isCheck: false,
        },
      ],
    },
    {
      label: <Translate id="Feature_Group_Management.List.Filter.Tangibility" />,
      id: 'tangible',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Feature_Tangibility.Tangible" />,
          value: 'true',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Feature_Tangibility.Intangible" />,
          value: 'false',
          isCheck: false,
        },
      ],
    },
  ],
  offset: 0,
  limit: 30,
};

export const filter: Array<checkboxOptionGroup> = [
  {
    label: <Translate id="Feature_Group_Management.Create.Select_Features.Status_Of_Selection" />,
    id: 'status',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Status.Selection.Selected" />,
        value: 'selected',
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Status.Selection.Unselected" />,
        value: 'deselected',
        isCheck: false,
      },
    ],
  },
];
