import React, { ReactNode } from 'react';
import cx from '../../utils/classnames';
import styles from './noItem.module.scss';
import { NotFoundFolder } from '@travel/icons/ui';
import { Translate } from '@travel/translation';

type Props = {
  /** Custom style for wrapper */
  className: string;
  hasDescription?: boolean;
  icon: ReactNode;
};

function NoItem(props: Props) {
  const { className, hasDescription, icon, ...rest } = props;

  return (
    <div className={cx(className, styles.wrapper)} data-testid="noItem-wrapper" {...rest}>
      <div className={styles.iconWrapper}>{icon}</div>
      <Translate className={styles.title} id="Common.List.List_Empty" />
      {hasDescription && <Translate className={styles.desc} id="Common.List_Empty.Sub_Message" />}
    </div>
  );
}

NoItem.defaultProps = {
  className: '',
  hasDescription: true,
  icon: <NotFoundFolder />,
};

export default NoItem;
