import { createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  Reviews,
  ProviderReviewDetail,
  ReviewsErrors,
  ReviewParams,
  ReviewDetail,
  ReplyReview,
  ApiStatus,
  Analytics,
} from 'Reviews-Types';
import {
  ApiFetchReviews,
  ApiFetchReview,
  ApiReplyReview,
  ApiFetchProviderReviewScore,
  ApiFetchProviderScoreAnalytics,
} from './apis';
import { pushLocation } from 'store/__router/actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

export const fetchReviewsAsync = createAsyncAction(
  'FETCH_REVIEWS_REQUEST',
  'FETCH_REVIEWS_SUCCESS',
  'FETCH_REVIEWS_FAILURE',
)<undefined, Reviews, ReviewsErrors[]>();

export const fetchReviewAsync = createAsyncAction(
  'FETCH_REVIEW_REQUEST',
  'FETCH_REVIEW_SUCCESS',
  'FETCH_REVIEW_FAILURE',
)<undefined, ReviewDetail, ReviewsErrors[]>();

export const replyReviewAsync = createAsyncAction(
  'REPLY_REVIEW_REQUEST',
  'REPLY_REVIEW_SUCCESS',
  'REPLY_REVIEW_FAILURE',
)<undefined, ApiStatus, ReviewsErrors[]>();

export const fetchProviderReviewScoreAsync = createAsyncAction(
  'FETCH_PROVIDER_REVIEW_SCORE_REQUEST',
  'FETCH_PROVIDER_REVIEW_SCORE_SUCCESS',
  'FETCH_PROVIDER_REVIEW_SCORE_FAILURE',
)<undefined, ProviderReviewDetail, ReviewsErrors[]>();

export const fetchProviderScoreAnalyticsAsync = createAsyncAction(
  'FETCH_PROVIDER_SCORE_ANALYTICS_REQUEST',
  'FETCH_PROVIDER_SCORE_ANALYTICS_SUCCESS',
  'FETCH_PROVIDER_SCORE_ANALYTICS_FAILURE',
)<undefined, Analytics, ReviewsErrors[]>();

export const fetchReviews = (params: ReviewParams, offset?: number): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const fetchQuery = {
    ...params,
    offset: offset || 0,
  };

  dispatch(fetchReviewsAsync.request());
  const res = await ApiFetchReviews(apiClient, fetchQuery);
  dispatch(fetchReviewsAsync.success(res));
};

export const fetchReview = (reviewId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReviewAsync.request());
  const res = await ApiFetchReview(apiClient, reviewId);
  dispatch(fetchReviewAsync.success(res));
};

export const replyReview = (
  providerId: string,
  reviewId: string,
  values: ReplyReview,
  state?: { prevSearch: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(replyReviewAsync.request());
  try {
    const res = await ApiReplyReview(apiClient, reviewId, values);
    dispatch(replyReviewAsync.success(res));

    if (res.status === 200) {
      dispatch(
        pushLocation({
          pathname: `/provider/${providerId}/reviews/${reviewId}`,
          state,
        }),
      );
      dispatch(fetchGlobalErrorDialogAsync.success(res));
    }
  } catch (err) {
    dispatch(replyReviewAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const fetchProviderReviewScore = (): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProviderReviewScoreAsync.request());
  const res = await ApiFetchProviderReviewScore(apiClient);
  dispatch(fetchProviderReviewScoreAsync.success(res));
};

// export const fetchProviderScoreAnalytics = (): AppThunk => async (
//   dispatch,
//   getState,
//   { apiClient },
// ) => {
//   dispatch(fetchProviderScoreAnalyticsAsync.request());
//   const res = await ApiFetchProviderScoreAnalytics(apiClient);
//   dispatch(fetchProviderScoreAnalyticsAsync.success(res));
// };

export const fetchProviderScoreAnalytics = (params: ReviewParams): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const fetchQuery = {
    ...params,
  };

  dispatch(fetchProviderScoreAnalyticsAsync.request());
  const res = await ApiFetchProviderScoreAnalytics(apiClient, fetchQuery);
  dispatch(fetchProviderScoreAnalyticsAsync.success(res));
};
