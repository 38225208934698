import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

const SATURDAY = 6;
const SUNDAY = 7;

const checkIsWeekend = (date: string) => {
  const dateWrapper = dayjs(date);
  const isoWeekday = dateWrapper.isoWeekday();
  return dateWrapper.isValid() && (isoWeekday === SATURDAY || isoWeekday === SUNDAY);
};

export default checkIsWeekend;
