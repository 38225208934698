import { useState, useEffect } from 'react';
import { History } from 'history';

export const useLocation = (history: History) => {
  const [location, setLocation] = useState(history.location);
  useEffect(() => {
    const unlisten = history.listen(location => setLocation(location));
    return () => unlisten();
  }, [history]);

  return location;
};
