import { universalRouterProps } from 'core/universalRouter/types';
import { fetchSalesPromotions } from 'store/salesPromotionList/actions';

export default (
  { store: { dispatch } }: universalRouterProps,
  { providerId }: { providerId: string },
  query: { [key: string]: string },
) => {
  return Promise.all([dispatch(fetchSalesPromotions(providerId, query))]);
};
