import { universalRouterProps } from 'core/universalRouter/types';
import { fetchBookingDetail } from 'store/booking/actions';
import { fetchMessageList } from 'store/bookingMessage/actions';

export type URLParams = {
  params: { providerId: string; bookingId: string };
  query: { [key: string]: string };
};

export default ({ store: { dispatch } }: universalRouterProps, urlParams: URLParams) => {
  const { bookingId, providerId } = urlParams.params;

  return Promise.all([
    dispatch(fetchBookingDetail(bookingId, providerId)),
    dispatch(fetchMessageList(bookingId)),
  ]);
};
