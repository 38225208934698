import { createAsyncAction, AppThunk } from 'typesafe-actions';

import { getTranslation } from '@travel/translation';
import { isEmptyObject } from '@travel/utils';
import { isEmptyArray } from '@travel/utils/src/arrays';
import { downloadFileFromBlob } from '@travel/utils/src/download';

import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { ProviderRestriction, ProviderRestrictionsError } from 'ProviderRestrictions-Types';
import {
  ApiFetchProviderRestrictions,
  ApiFetchProviderRestrictionCsv,
  ApiUploadProviderRestrictionCsv,
} from './apis';

export const fetchProviderRestrictionsAsync = createAsyncAction(
  'FETCH_PROVIDER_RESTRICTIONS_REQUEST',
  'FETCH_PROVIDER_RESTRICTIONS_SUCCESS',
  'FETCH_PROVIDER_RESTRICTIONS_FAILURE',
)<undefined, ProviderRestriction[], ProviderRestrictionsError>();

export const fetchProviderRestrictionDetailAsync = createAsyncAction(
  'FETCH_PROVIDER_RESTRICTION_DETAIL_REQUEST',
  'FETCH_PROVIDER_RESTRICTION_DETAIL_SUCCESS',
  'FETCH_PROVIDER_RESTRICTION_DETAIL_FAILURE',
)<undefined, ProviderRestriction, ProviderRestrictionsError>();

export const fetchProviderRestrictionCsvDownloadAsync = createAsyncAction(
  'FETCH_PROVIDER_RESTRICTION_CSV_DOWNLOAD_REQUEST',
  'FETCH_PROVIDER_RESTRICTION_CSV_DOWNLOAD_SUCCESS',
  'FETCH_PROVIDER_RESTRICTION_CSV_DOWNLOAD_FAILURE',
)<undefined, Response, ProviderRestrictionsError>();

export const uploadProviderRestrictionCsvAsync = createAsyncAction(
  'UPLOAD_PROVIDER_RESTRICTION_CSV_REQUEST',
  'UPLOAD_PROVIDER_RESTRICTION_CSV_SUCCESS',
  'UPLOAD_PROVIDER_RESTRICTION_CSV_FAILURE',
)<undefined, Response, ProviderRestrictionsError>();

export const fetchProviderRestrictions = (): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProviderRestrictionsAsync.request());
  try {
    const providerRestrictions = getState().providerRestrictions.providerRestrictions;
    if (!isEmptyArray(providerRestrictions)) {
      dispatch(fetchProviderRestrictionsAsync.success(providerRestrictions));
      return;
    }

    const res = await ApiFetchProviderRestrictions(apiClient);
    dispatch(fetchProviderRestrictionsAsync.success(res));
  } catch (err) {
    dispatch(fetchProviderRestrictionsAsync.failure(err as ProviderRestrictionsError));
  }
};

export const fetchProviderRestrictionDetail = (code: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProviderRestrictionDetailAsync.request());
  try {
    const currentProviderRestrictionDetail = getState().providerRestrictions
      .providerRestrictionDetail;
    if (
      !isEmptyObject(currentProviderRestrictionDetail) &&
      currentProviderRestrictionDetail.code === code
    ) {
      dispatch(fetchProviderRestrictionDetailAsync.success(currentProviderRestrictionDetail));
    } else {
      await dispatch(fetchProviderRestrictions());
      const queriedProviderRestrictionDetail = getState().providerRestrictions.providerRestrictions.find(
        item => item.code === code,
      );

      if (isEmptyObject(queriedProviderRestrictionDetail)) {
        const providerRestrictionError = {
          status: 404,
          message: getTranslation({ id: 'Extranet_Error.No_Result.Provider_Munual_Addition' }).join(
            '',
          ),
        } as ProviderRestrictionsError;
        dispatch(fetchGlobalErrorDialogAsync.success(providerRestrictionError));
        dispatch(fetchProviderRestrictionDetailAsync.failure(providerRestrictionError));
      } else {
        dispatch(
          fetchProviderRestrictionDetailAsync.success(
            queriedProviderRestrictionDetail as ProviderRestriction,
          ),
        );
      }
    }
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err as ProviderRestrictionsError));
    dispatch(fetchProviderRestrictionDetailAsync.failure(err as ProviderRestrictionsError));
  }
};

export const fetchProviderRestrictionCsvDownload = (id: string): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchProviderRestrictionCsvDownloadAsync.request());
  try {
    const response = await ApiFetchProviderRestrictionCsv(apiClient, id);
    const contentDisposition = response?.headers?.get('content-disposition');
    const filename = !!contentDisposition
      ? /attachment; filename="(.*)"/.exec(contentDisposition)?.[1]
      : null;
    const blob = await response?.blob();
    if (blob && filename) {
      downloadFileFromBlob(new Blob([blob], { type: 'text/csv' }), filename);
    }
    dispatch(fetchProviderRestrictionCsvDownloadAsync.success(response));
  } catch (err) {
    dispatch(fetchProviderRestrictionCsvDownloadAsync.failure(err as ProviderRestrictionsError));
    dispatch(
      fetchGlobalErrorDialogAsync.success({
        ...(err as ProviderRestrictionsError),
        errorMessage: getTranslation({ id: 'Common.Detail.Popup.Error.Failed_Download' }).join(''),
      }),
    );
  }
};

export const uploadProviderRestrictionCsv = (id: string, csvFile: File): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(uploadProviderRestrictionCsvAsync.request());
  try {
    const formData = new FormData();
    formData.append('file', csvFile, csvFile.name);
    const res = await ApiUploadProviderRestrictionCsv(apiClient, id, formData);
    dispatch(uploadProviderRestrictionCsvAsync.success(res));
    dispatch(
      fetchGlobalErrorDialogAsync.success({
        ...res,
        successTitle: getTranslation({
          id: 'InternalTools_Provider_Restriction.Confirmation_Popup.Successfully_Uploaded',
        }).join(''),
        successSubTitle: getTranslation({
          id: 'InternalTools_Provider_Restriction.Confirmation_Popup.Your_File_Uploaded',
        }).join(''),
        successDialogTitle: getTranslation({
          id: 'InternalTools_Provider_Restriction.Confirmation_Popup.Operation_Result',
        }).join(''),
      }),
    );
  } catch (err) {
    dispatch(uploadProviderRestrictionCsvAsync.failure(err as ProviderRestrictionsError));
    dispatch(
      fetchGlobalErrorDialogAsync.success({
        ...(err as ProviderRestrictionsError),
        errorMessage: getTranslation({ id: 'Extranet_Error.System_Logic.CSV_Unreadable' }).join(''),
      }),
    );
  }
};
