import { universalRouterProps } from 'core/universalRouter/types';
import { queryToObject } from '@travel/utils/src/queryString';
import { fetchReviews, fetchProviderReviewScore } from 'store/reviews/actions';
import { getToday, reduceDays } from 'utils/calendar';
import { MAX_DATE_RANGE_MANAGEMENT } from 'constants/reviews';

type Query = { search?: { tags: string; conditions: string }; sort?: string[] };

export type URLParams = { query: Query };

export const getQuery = (query: Query = {}, timezone: string) => {
  const today = getToday(timezone);
  const fromDate = reduceDays(today, -MAX_DATE_RANGE_MANAGEMENT + 1);
  const objectQuery = queryToObject(query);
  const conditions = {
    postDateFrom: fromDate,
    postDateTo: today,
    ...objectQuery?.search,
  };

  const searchCondition = objectQuery?.search?.condition;
  const tags = objectQuery?.search?.tags;
  const filter = objectQuery?.filter;
  const sortValue = objectQuery.sort && Object.keys(objectQuery.sort)[0];

  return {
    ...filter,
    ...conditions,
    ...tags,
    searchCondition,
    sort: sortValue,
    limit: 30,
  };
};

export default ({ store }: universalRouterProps, urlParams: URLParams) => {
  const { query } = urlParams;
  const { dispatch } = store;
  const providerTimeZone =
    store.getState()?.providersInformation?.providerBasicInfo?.timezone || '';

  return Promise.all([
    dispatch(fetchReviews(getQuery(query, providerTimeZone))),
    dispatch(fetchProviderReviewScore()),
  ]);
};
