import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';

import { isEmptyObject, isNotEmptyValue } from '@travel/utils';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData'; // dependent on utc plugin
import localizedFormat from 'dayjs/plugin/localizedFormat'; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

type CalendarEventAction = () => void;
export type Period =
  | {
      fromDate: string;
      toDate: string;
    }
  | undefined;

export type StartEndDateTime = {
  startDateTime: string;
  endDateTime: string;
};

const defaultTimeZone = 'Asia/Tokyo';

export const defaultExtranetSearchPeriodStart = dayjs()
  .tz(defaultTimeZone)
  .subtract(30, 'day')
  .format('YYYY-MM-DD');

export const defaultExtranetSearchPeriodEnd = dayjs()
  .tz(defaultTimeZone)
  .add(1, 'year')
  .endOf('year')
  .format('YYYY-MM-DD');

export const getTodayWithFormat = (format: string, timezone?: string, currentDate?: string) => {
  return timezone
    ? dayjs(currentDate)
        .tz(timezone)
        .format(format)
    : dayjs(currentDate).format(format);
};
export const getToday = (timezone?: string) => {
  return timezone
    ? dayjs()
        .tz(timezone)
        .format('YYYY-MM-DD')
    : dayjs().format('YYYY-MM-DD');
};

export const isLessThan = (firstDate: string, secondDate: string) => {
  return dayjs(firstDate).format('YYYY-MM-DD') < dayjs(secondDate).format('YYYY-MM-DD');
};

export const getBookingListFormatDate = (date: string) => {
  return dayjs(date).format('MM/DD/YYYY LT');
};

export const getFormatedDate = (date: string) => {
  return dayjs(date).format('MM/DD/YYYY');
};

export const getUTCDateTime = (date: string) => {
  return dayjs.utc(date).format('MM/DD/YYYY (ddd) LT');
};

export const getDateDiffDays = (fromDate: string, toDate: string) => {
  const isoToDate = new Date(toDate);
  return dayjs(isoToDate).diff(fromDate, 'days');
};

export const isValidDate = (date: string) => {
  return dayjs(date, 'YYYY-MM-DD', true).isValid();
};

export const getDateDiffMonths = (fromDate: string, toDate: string) => {
  return dayjs(toDate).diff(fromDate, 'month');
};

export const getDaysInBetween = (fromDate: string, toDate: string) => {
  let diff: number = dayjs(toDate).diff(fromDate, 'day') - 1;
  // Days order in array don't matter
  const daysInBetween = [fromDate, toDate];

  let addedDay = fromDate;
  while (diff > 0) {
    const dayToAdd = dayjs(addedDay)
      .add(1, 'day')
      .format('YYYY-MM-DD');
    addedDay = dayToAdd;
    daysInBetween.push(dayToAdd);
    diff--;
  }

  return daysInBetween;
};

export const getDateTimeWithTimezone = (
  date: string,
  timezone?: string,
  format: string = '',
  shouldTimeZoneBeConsidered: boolean = true,
) => {
  if (date) {
    const day = date?.split('T')[0];
    const hours = date?.split('T')[1]?.split(':')[0];
    const min = date?.split('T')[1]?.split(':')[1];
    const isOverADay = parseInt(hours) > 23;
    const convertedDate = isOverADay
      ? dayjs(day)
          .add(1, 'day')
          .hour(parseInt(hours) - 24)
          .minute(parseInt(min))
      : dayjs(date);

    if (convertedDate.isValid()) {
      return shouldTimeZoneBeConsidered
        ? dayjs(convertedDate)
            .tz(timezone || defaultTimeZone)
            .format(format)
        : dayjs(convertedDate).format(format);
    }
  }
  return '';
};

export const getStartOfMonth = (currentDate: string) => {
  return dayjs(currentDate)
    .startOf('month')
    .format('YYYY-MM-DD');
};
export const getEndOfMonth = (currentDate: string) => {
  return dayjs(currentDate)
    .endOf('month')
    .format('YYYY-MM-DD');
};
export const addDays = (currentDate: string, numOfDays: number) => {
  return dayjs(currentDate)
    .add(numOfDays, 'days')
    .format('YYYY-MM-DD');
};
export const reduceDays = (currentDate: string, numOfDays: number) => {
  return dayjs(currentDate)
    .add(numOfDays, 'days')
    .format('YYYY-MM-DD');
};

export const addMonths = (currentDate: string, numOfMonth: number) => {
  return dayjs(currentDate)
    .add(numOfMonth, 'month')
    .format('YYYY-MM-DD');
};

export const reduceMonths = (currentDate: string, numOfMonth: number) => {
  return dayjs(currentDate)
    .add(numOfMonth, 'month')
    .format('YYYY-MM-DD');
};

export const getDeviceTZ = () => {
  return dayjs.tz.guess();
};

export const reOrderWeekdays = (firstDayOfWeek: boolean, weekdaysShort: string[]) => {
  const newWeekdays = weekdaysShort;
  if (firstDayOfWeek) {
    const last: string = newWeekdays.shift() || '';
    newWeekdays.push(last);
  }
  return newWeekdays;
};

/**
 * Returns the Day Name From the Date
 * @param dateStr
 */
export const getDayNameFromDate = (dateStr: string) => {
  if (dateStr) {
    return dayjs(dateStr)
      .format('dddd')
      .toLowerCase();
  }
  return '';
};

/**
 * Returns the total minutes from given time duration
 * @param hours
 * @param minutes
 * e.g 3 (hours), 30 (minutes) = 210 (total minutes)
 */
export const getTotalMinutes = (hours: number, minutes: number) => {
  const hoursInMinutes = dayjs.duration(hours, 'h').asMinutes();
  const totalMinutes = hoursInMinutes + minutes;

  return totalMinutes;
};

/**
 * Returns hours and remaining minutes from given total minutes
 * @param totalMinutes
 * e.g 210 (minutes) = [3 (hours), 30 (minutes)]
 */
export const getHoursAndMinutes = (totalMinutes: number) => {
  const durationObj = dayjs.duration(totalMinutes, 'm');

  const hours = durationObj.hours();
  const minutes = durationObj.minutes();

  return [hours, minutes];
};

/**
 * compare date is before today or not.
 * @param date
 */
export const isOutdated = (date: string): boolean => {
  return dayjs(date).isBefore(dayjs());
};

export const splitDateTime = (dateTime: string): [string, string] => {
  const splitted = dateTime.split('T');
  return [splitted?.[0] || '', splitted?.[1] || ''];
};

/**
 * @param shouldValidateDate validate start and end dates are outdated.
 * If either one of them is outdated, return null.
 */
export const createPeriod = (
  startEnd: StartEndDateTime | null,
  shouldValidateDate: boolean = false,
): { startDate: string; startTime: string; endDate: string; endTime: string } | null => {
  if (!startEnd) return null;
  const { startDateTime, endDateTime } = startEnd;
  const [startDate, startTime] = startDateTime ? splitDateTime(startDateTime) : ['', ''];
  const [endDate, endTime] = endDateTime ? splitDateTime(endDateTime) : ['', ''];

  if (shouldValidateDate) {
    if (isOutdated(startDate) || isOutdated(endDate)) return null;
  }
  return { startDate, startTime, endDate, endTime };
};

export const calendarKeyDownListener = (
  e: KeyboardEvent,
  onClickPrev: CalendarEventAction,
  onClickNext: CalendarEventAction,
  selectorClass: string,
) => {
  // Listen when Calendar is open only
  const calendarPicker = document.querySelector(`.${selectorClass}`);
  if (document.body.contains(calendarPicker)) {
    if (e.keyCode === 9 && e.shiftKey) {
      // Previous
      e.preventDefault();
      onClickPrev();
      e.stopPropagation();
    } else if (e.keyCode === 9 && !e.shiftKey) {
      // Next
      e.preventDefault();
      onClickNext();
      e.stopPropagation();
    }
  }
};

export const convertTimezone = (utcTime: string, timezone: string): string => {
  const date = dayjs
    .utc(utcTime)
    .tz(timezone)
    .format();
  return date;
};

export const getIsOverlapped = (periods: Array<Period>, targetIndex: number): boolean => {
  const target = periods[targetIndex];

  if (!target?.fromDate || !target?.toDate) return false;

  return periods.some((period, index) => {
    if (index === targetIndex) return false;
    if (!period?.fromDate || !period?.toDate) return false;
    return !(
      dayjs(period.toDate).isBefore(target.fromDate) ||
      dayjs(period.fromDate).isAfter(target.toDate)
    );
  });
};

export const getIsInPeriod = (period: StartEndDateTime, currentDate?: string): boolean => {
  const { startDateTime, endDateTime } = period;
  return dayjs(startDateTime).isBefore(currentDate) && dayjs(endDateTime).isAfter(currentDate);
};

export const isNotEmptyPeriod = (period: Period) => {
  if (isEmptyObject(period)) return false;
  return isNotEmptyValue(period?.fromDate) || isNotEmptyValue(period?.toDate);
};

/**
 * Returns all dates between start and end
 */
export const getDatesArray = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const returnArr: string[] = [];
  if (startDate <= endDate) {
    while (startDate <= endDate) {
      returnArr.push(startDate.toISOString().substring(0, 10));
      startDate.setDate(startDate.getDate() + 1);
    }
  } else {
    while (endDate <= startDate) {
      returnArr.push(startDate.toISOString().substring(0, 10));
      startDate.setDate(startDate.getDate() - 1);
    }
  }
  return returnArr;
};
