import { ApiClient } from '@travel/api-client';

import {
  APIResponse,
  BookingCancellation,
  BookingDetail,
  BookingList,
  BookingModificationDetail,
  CancelReservationResponse,
  CancelStepTwoData,
  FormData,
  RatePlanList,
  SaveNoteData,
  Statistics,
  TranslateTextResponse,
} from 'Booking-Types';
import { bookingPrefix } from 'constants/api-prefix';

const trvDesignation = 'trv-designation';
const LIST_API_TIMEOUT = 60000; // 60s
const STATISTICS_API_TIMEOUT = 60000; // 60s

export const ApiFetchBookingList = (
  http: ApiClient,
  params: Object,
  providerId?: string,
): Promise<BookingList> => {
  const url = `${bookingPrefix}/reservations`;

  return http
    .post(url, { values: params, ignoreCommonErrorHandler: true, timeout: LIST_API_TIMEOUT })
    .then((data: BookingList) => data);
};

export const ApiBookingDetail = (
  http: ApiClient,
  reservationId: string,
): Promise<BookingDetail> => {
  const url = `${bookingPrefix}/reservations/${reservationId}`;

  return http.get(url).then((data: BookingDetail) => data);
};

export const ApiBookingCancelStepOne = (
  http: ApiClient,
  reservationId: string,
  isNoShow: boolean,
  designation: string,
): Promise<BookingCancellation> =>
  http
    .get(`${bookingPrefix}/reservations/${reservationId}/cancellationDetails`, {
      values: { noShow: isNoShow },
      headers: {
        [trvDesignation]: designation,
      },
    })
    .then((data: BookingCancellation) => data);

export const ApiBookingCancelStepTwo = (
  http: ApiClient,
  reservationId: string,
  values: CancelStepTwoData,
  designation: string,
): Promise<CancelReservationResponse> =>
  http
    .post(`${bookingPrefix}/reservations/${reservationId}/cancel`, {
      values,
      headers: {
        [trvDesignation]: designation,
      },
    })
    .then((data: CancelReservationResponse) => data);

export const ApiSaveNote = (
  http: ApiClient,
  reservationId: string,
  values: SaveNoteData,
): Promise<APIResponse> =>
  http
    .post(`${bookingPrefix}/reservations/${reservationId}/saveNote`, {
      values,
    })
    .then((data: APIResponse) => data);

export const ApiFetchBookingModificationDetails = (
  http: ApiClient,
  reservationId: string,
): Promise<BookingModificationDetail> =>
  http
    .get(`${bookingPrefix}/reservations/${reservationId}/modificationDetails`)
    .then((data: BookingModificationDetail) => data);

export const ApiUpdatePriceChange = (
  http: ApiClient,
  reservationId: string,
  values: FormData,
  designation: string,
): Promise<APIResponse> =>
  http
    .put(`${bookingPrefix}/reservations/${reservationId}/price/change`, {
      values,
      ignoreCommonErrorHandler: true,
      headers: {
        [trvDesignation]: designation,
      },
    })
    .then((data: APIResponse) => data);

export const ApiConfirmPriceChange = (
  http: ApiClient,
  reservationId: string,
  values: FormData,
  designation: string,
): Promise<BookingModificationDetail> =>
  http
    .put(`${bookingPrefix}/reservations/${reservationId}/price/confirm`, {
      values,
      ignoreCommonErrorHandler: true,
      headers: {
        [trvDesignation]: designation,
      },
    })
    .then((data: BookingModificationDetail) => data);

export const ApiUpdateStayDateChange = (
  http: ApiClient,
  reservationId: string,
  values: FormData,
  designation: string,
): Promise<APIResponse> =>
  http
    .put(`${bookingPrefix}/reservations/${reservationId}/date/change`, {
      values,
      ignoreCommonErrorHandler: true,
      headers: {
        [trvDesignation]: designation,
      },
    })
    .then((data: APIResponse) => data);

export const ApiConfirmStayDateChange = (
  http: ApiClient,
  reservationId: string,
  values: FormData,
  designation: string,
): Promise<BookingModificationDetail> =>
  http
    .put(`${bookingPrefix}/reservations/${reservationId}/date/confirm`, {
      values,
      ignoreCommonErrorHandler: true,
      headers: {
        [trvDesignation]: designation,
      },
    })
    .then((data: BookingModificationDetail) => data);

export const ApiUpdateCancellationCharge = (
  http: ApiClient,
  reservationId: string,
  values: FormData,
): Promise<APIResponse> =>
  http
    .put(`${bookingPrefix}/reservations/${reservationId}/cancellationCharge`, { values })
    .then((data: APIResponse) => data);

export const ApiTranslateText = (
  http: ApiClient,
  reservationId: string,
  values: FormData,
): Promise<TranslateTextResponse> =>
  http
    .post(`${bookingPrefix}/reservations/${reservationId}/translate-text`, {
      values,
      ignoreCommonErrorHandler: true,
    })
    .then((data: TranslateTextResponse) => data);

export const ApiFetchStatistics = (
  http: ApiClient,
  params: Object,
  providerId: string,
): Promise<Statistics> => {
  const url = `${bookingPrefix}/statistics`;
  return http
    .post(url, { values: { ...params, providerId }, timeout: STATISTICS_API_TIMEOUT })
    .then((data): Statistics => data);
};

export const ApiFetchRatePlans = (https: ApiClient, params: Object): Promise<RatePlanList> => {
  const url = `${bookingPrefix}/rate-plans`;
  return https.post(url, { values: params }).then((data: RatePlanList) => data);
};

export const ApiDeleteQueue = (
  http: ApiClient,
  reservationId: string,
  noticeQueueId: string,
): Promise<APIResponse> =>
  http
    .post(`${bookingPrefix}/reservations/${reservationId}/rconnect/deleteNotification`, {
      values: { noticeQueueId },
    })
    .then((data: APIResponse) => data);

export const ApiRConnectConfirm = (http: ApiClient, reservationId: string): Promise<APIResponse> =>
  http
    .post(`${bookingPrefix}/reservations/${reservationId}/rconnect/confirm`, {})
    .then((data: APIResponse) => data);
