import { universalRouterProps } from 'core/universalRouter/types';
import { fetchFeatureGroupList, fetchFeatureGroupObjectTypes } from 'store/featureGroup/actions';

export default (
  { store: { dispatch } }: universalRouterProps,
  query: { [field: string]: string },
) => {
  return Promise.all([
    dispatch(fetchFeatureGroupList(query)),
    dispatch(fetchFeatureGroupObjectTypes()),
  ]);
};
