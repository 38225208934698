import React, { ReactNode } from 'react';

import styles from './notificationDialog.module.scss';
import { Alert } from '@travel/ui';

type Props = {
  children: ReactNode;
  title?: string;
  type?: 'info' | 'success' | 'error';
  isClosable?: boolean;
};

function NotificationDialog(props: Props) {
  const { children, type = 'info', title = '', isClosable = true } = props;

  return (
    <div className={styles.container}>
      <Alert
        isClosable={isClosable}
        type={type}
        title={title}
        description={<div className={styles.content}>{children} </div>}
      />
    </div>
  );
}

export default NotificationDialog;
