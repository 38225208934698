import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  RatePlanDistributionErrors,
  ApiStatus,
  RatePlanDistributionDetail,
  RatePlanDistributionDetail_UI,
} from 'RatePlanDistribution-Types';
import { ApiPostRatePlanDistribution } from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';

export const setSucceededRatePlanDistribution = createStandardAction(
  'SET_SUCCEEDED_RATE_PLAN_DISTRIBUTION',
)<RatePlanDistributionDetail_UI>();

export const createRatePlanDistributionAsync = createAsyncAction(
  'CREATE_RATE_PLAN_REQUEST',
  'CREATE_RATE_PLAN_SUCCESS',
  'CREATE_RATE_PLAN_FAILURE',
)<undefined, ApiStatus, RatePlanDistributionErrors[]>();

export const createRatePlanDistribution = (data: RatePlanDistributionDetail): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(createRatePlanDistributionAsync.request());
  try {
    const res = await ApiPostRatePlanDistribution(apiClient, data);
    dispatch(createRatePlanDistributionAsync.success(res));
    if (res.status === 200) {
      dispatch(pushLocation(`/internal/rateplanDistribution/success`));
    }
    dispatch(fetchGlobalErrorDialogAsync.success(res));
  } catch (err) {
    dispatch(createRatePlanDistributionAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};
