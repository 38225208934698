import { universalRouterProps } from 'core/universalRouter/types';

import {
  fetchHighlightContentArticle,
  setInitialHighlightContentArticle,
} from 'store/highlightContent/actions';

type Location = {
  params: {
    providerId: string;
    articleId?: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  let promises: Array<Promise<void>> = [];
  const { providerId, articleId } = location.params;

  if (articleId) {
    promises = [dispatch(fetchHighlightContentArticle(providerId, articleId))];
  } else {
    dispatch(setInitialHighlightContentArticle());
  }

  return Promise.all(promises);
};
