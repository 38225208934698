import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';

import { isNotEmptyArray, toQuery } from '@travel/utils';

import {
  ProviderCsv2SearchFilters,
  ProviderCsvRspSearchFilters,
  ProviderManualSearchFilters,
} from 'components/SelectProviderOption/types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { OBJECT_TYPE } from 'constants/feature';
import { GlobalErrorDialogResponse } from 'GlobalErrorDialog-Types';
import { ProvidersErrors } from 'Providers-Types';
import {
  ApiStatus,
  Provider,
  ProviderBasicInfo,
  SelectProvider,
  SelectProvider2,
  SelectProviderRsp,
} from 'ProvidersInformation-Types';
import { as } from 'utils';
import {
  fetchProvider,
  updateProvider,
  ApiFetchSelectionProviderSearchByConditions,
  ApiPostSelectionProvider,
  ApiPostSelectionProviderSearchCsv,
  ApiPostSelectionProviderSearchCsv2,
  ApiPostSelectionProviderSearchCsvRsp,
} from './apis';

export const setMidTaskMediaName = createStandardAction('SET_MID_TASK_MEDIA_NAME')<string>();
export const setProviderBasicInfoStandard = createStandardAction('SET_PROVIDER_BASIC_INFO')<
  ProviderBasicInfo
>();

export const fetchProviderInformationAsync = createAsyncAction(
  'FETCH_PROVIDER_DETAILS_REQUEST',
  'FETCH_PROVIDER_DETAILS_SUCCESS',
  'FETCH_PROVIDER_DETAILS_FAILURE',
)<undefined, Provider, ProvidersErrors[]>();

export const updateProviderInformationAsync = createAsyncAction(
  'UPDATE_PROVIDER_DETAILS_REQUEST',
  'UPDATE_PROVIDER_DETAILS_SUCCESS',
  'UPDATE_PROVIDER_DETAILS_FAILURE',
)<undefined, ApiStatus, ProvidersErrors>();

export const PostSelectionProviderSearchManuallyAsync = createAsyncAction(
  'POST_SELECTION_PROVIDER_REQUEST',
  'POST_SELECTION_PROVIDER_SUCCESS',
  'POST_SELECTION_PROVIDER_FAILURE',
)<undefined, SelectProvider, ProvidersErrors[]>();

export const PostSelectionProviderSearchCsvAsync = createAsyncAction(
  'POST_SELECTION_PROVIDER_SEARCH_CSV_REQUEST',
  'POST_SELECTION_PROVIDER_SEARCH_CSV_SUCCESS',
  'POST_SELECTION_PROVIDER_SEARCH_CSV_FAILURE',
)<undefined, SelectProvider | SelectProvider2 | SelectProviderRsp, ProvidersErrors[]>();

export const PostSelectionProviderSearchConditionAsync = createAsyncAction(
  'POST_SELECTION_PROVIDER_SEARCH_CONDITION_REQUEST',
  'POST_SELECTION_PROVIDER_SEARCH_CONDITION_SUCCESS',
  'POST_SELECTION_PROVIDER_SEARCH_CONDITION_FAILURE',
)<undefined, SelectProvider, ProvidersErrors[]>();

export const fetchProviderInformation = (id: string): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(fetchProviderInformationAsync.request());
  try {
    const res = await fetchProvider(apiClient, id);
    dispatch(fetchProviderInformationAsync.success(res));
  } catch (error) {
    dispatch(fetchProviderInformationAsync.failure([error]));
    return Promise.reject(error);
  }
};

export const updateProviderInformation = (
  id: string,
  updatedProviderData: Provider,
): AppThunk => async (dispatch, _getState, { apiClient }) => {
  dispatch(updateProviderInformationAsync.request());
  try {
    const res = await updateProvider(apiClient, id, updatedProviderData);

    dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(res)));
    dispatch(updateProviderInformationAsync.success(as<ApiStatus>(res)));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(err)));
    dispatch(updateProviderInformationAsync.failure(err));
  }
};

export const PostSelectionProviderSearchManually = (
  providerId: Array<string>,
  errorMsg: string = '',
  queryParameter: ProviderManualSearchFilters,
): AppThunk<Promise<SelectProvider | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(PostSelectionProviderSearchManuallyAsync.request());
  try {
    const res = await ApiPostSelectionProvider(apiClient, providerId, queryParameter);
    dispatch(PostSelectionProviderSearchManuallyAsync.success(res));

    if ('status' in res) {
      if (res['status'] >= 400) {
        const apiRes: ApiStatus = { status: 400, message: '' };
        dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(apiRes)));
      }
    } else {
      if (isNotEmptyArray(res.contents)) {
        return res;
      } else {
        const notFound: ApiStatus = {
          status: 404,
          message: errorMsg,
        };
        dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(notFound)));
      }
    }
  } catch (error) {
    dispatch(PostSelectionProviderSearchManuallyAsync.failure([error]));
  }
};

export const PostSelectionProviderSearchByCsv = (
  file: File,
  queryParameter: ProviderManualSearchFilters,
): AppThunk<Promise<SelectProvider | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(PostSelectionProviderSearchCsvAsync.request());

  const formData = new FormData();
  formData.append('file', file, file.name);

  try {
    const res = await ApiPostSelectionProviderSearchCsv(apiClient, formData, queryParameter);
    dispatch(PostSelectionProviderSearchCsvAsync.success(res));

    if ('status' in res) {
      if (res['status'] >= 400) {
        const apiRes: ApiStatus = { status: 400, message: '' };
        dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(apiRes)));
      }
    } else {
      return res;
    }
  } catch (error) {
    dispatch(PostSelectionProviderSearchCsvAsync.failure([error]));
  }
};

export const PostSelectionProviderSearchByCsv2 = (
  file: File,
  queryParameter?: ProviderCsv2SearchFilters,
): AppThunk<Promise<SelectProvider2 | undefined>> => async (dispatch, _getState, { apiClient }) => {
  dispatch(PostSelectionProviderSearchCsvAsync.request());

  const formData = new FormData();
  formData.append('file', file, file.name);

  try {
    const res = await ApiPostSelectionProviderSearchCsv2(apiClient, formData, queryParameter);
    dispatch(PostSelectionProviderSearchCsvAsync.success(res));

    if ('status' in res) {
      if (res['status'] >= 400) {
        const apiRes: ApiStatus = { status: 400, message: '' };
        dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(apiRes)));
      }
    } else {
      return res;
    }
  } catch (error) {
    dispatch(PostSelectionProviderSearchCsvAsync.failure([error]));
  }
};

export const PostSelectionProviderSearchByCsvRsp = (
  file: File,
  queryParameter: ProviderCsvRspSearchFilters,
): AppThunk<Promise<SelectProviderRsp | undefined>> => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  dispatch(PostSelectionProviderSearchCsvAsync.request());

  const formData = new FormData();
  formData.append('file', file, file.name);

  try {
    const res = await ApiPostSelectionProviderSearchCsvRsp(apiClient, formData, queryParameter);
    dispatch(PostSelectionProviderSearchCsvAsync.success(res));

    if ('status' in res) {
      if ((res as any)['status'] >= 400) {
        const apiRes: GlobalErrorDialogResponse = {
          status: 400,
          message: '',
          labelId: 'Extranet_Error.System_Logic.CSV_Unreadable',
        };
        dispatch(fetchGlobalErrorDialogAsync.success(apiRes));
      }
    } else {
      return res;
    }
  } catch (error) {
    dispatch(PostSelectionProviderSearchCsvAsync.failure([error]));
  }
};

export const fetchSelectionProviderSearchCondition = (
  queryParameter: Object,
  errorMsg: string = '',
): AppThunk<Promise<SelectProvider | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(PostSelectionProviderSearchConditionAsync.request());
  try {
    const res = await ApiFetchSelectionProviderSearchByConditions(
      apiClient,
      toQuery(queryParameter),
    );
    dispatch(PostSelectionProviderSearchConditionAsync.success(res));

    if ('status' in res) {
      if (res['status'] >= 400) {
        const apiRes: ApiStatus = { status: 400, message: '' };
        dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(apiRes)));
      }
    } else {
      if (isNotEmptyArray(res.contents)) {
        let newRes: SelectProvider = {
          limit: 0,
          offset: 0,
          total: 0,
          contents: [],
        };
        res.contents.forEach(provider => {
          // If selectionType is OBJECT_TYPE.PLAN (RSP/SSP), we do not need to show provider where ratePlan is empty
          // If other, we should add the rate plan, empty or not
          if ('filter' in queryParameter && 'selectionType' in queryParameter['filter']) {
            const selectionType = queryParameter['filter']['selectionType'];
            const isPlanSelection = selectionType === OBJECT_TYPE.PLAN;
            if (!isPlanSelection || (isPlanSelection && isNotEmptyArray(provider.ratePlans))) {
              newRes.contents.push(provider);
            }
          }
        });

        if (isNotEmptyArray(newRes.contents)) {
          return newRes;
        } else {
          const notFound: ApiStatus = {
            status: 404,
            message: errorMsg,
            shouldHideHeading: true,
          };
          dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(notFound)));
        }
      } else {
        const notFound: ApiStatus = {
          status: 404,
          message: errorMsg,
          shouldHideHeading: true,
        };
        dispatch(fetchGlobalErrorDialogAsync.success(as<ApiStatus>(notFound)));
      }
    }
  } catch (error) {
    dispatch(PostSelectionProviderSearchConditionAsync.failure([error]));
  }
};

export const setProviderBasicInfo = (data: ProviderBasicInfo): AppThunk => async dispatch => {
  dispatch(setProviderBasicInfoStandard(data));
};
