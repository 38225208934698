import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import {
  FetchAllBody,
  ChannelManagerErrors,
  ChannelManager,
  QueryParameter,
  ApiStatus,
} from 'ChannelManager-Types';
import { fetchAll, fetchManager, createManager, updateManager } from './api';
import { pushLocation } from 'store/__router/actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { transformToQueryObject } from 'utils/transformToQueryObject';

export const fetchChannelManagersAsync = createAsyncAction(
  'FETCH_CHANNEL_MANAGERS_REQUEST',
  'FETCH_CHANNEL_MANAGERS_SUCCESS',
  'FETCH_CHANNEL_MANAGERS_FAILURE',
)<undefined, FetchAllBody, ChannelManagerErrors>();

export const clearChannelManager = createStandardAction('CLEAR_CHANNEL_MANAGER')();

export const fetchChannelManagerAsync = createAsyncAction(
  'FETCH_CHANNEL_MANAGER_REQUEST',
  'FETCH_CHANNEL_MANAGER_SUCCESS',
  'FETCH_CHANNEL_MANAGER_FAILURE',
)<undefined, ChannelManager, ChannelManagerErrors>();

export const createChannelManagerAsync = createAsyncAction(
  'CREATE_CHANNEL_MANAGER_REQUEST',
  'CREATE_CHANNEL_MANAGER_SUCCESS',
  'CREATE_CHANNEL_MANAGER_FAILURE',
)<undefined, ApiStatus, ChannelManagerErrors>();

export const updateChannelManagerAsync = createAsyncAction(
  'UPDATE_CHANNEL_MANAGER_REQUEST',
  'UPDATE_CHANNEL_MANAGER_SUCCESS',
  'UPDATE_CHANNEL_MANAGER_FAILURE',
)<undefined, ApiStatus, ChannelManagerErrors>();

export const fetchChannelManagers = (query: QueryParameter): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchChannelManagersAsync.request());
  try {
    const newQuery = {
      ...transformToQueryObject(query || {}, ['status']),
      limit: 30,
      offset: query?.offset || 0,
    };
    const response = await fetchAll(apiClient, newQuery);
    dispatch(fetchChannelManagersAsync.success(response));
  } catch (errors) {
    dispatch(fetchChannelManagersAsync.failure(errors));
  }
};

export const fetchChannelManager = (managerId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchChannelManagerAsync.request());
    const response = await fetchManager(apiClient, managerId);
    dispatch(fetchChannelManagerAsync.success(response));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
  }
};

export const createChannelManager = (data: ChannelManager): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(createChannelManagerAsync.request());
  try {
    const response = await createManager(apiClient, data);
    dispatch(createChannelManagerAsync.success(response));
    dispatch(fetchGlobalErrorDialogAsync.success(response));
    dispatch(pushLocation(`/internal/channelManagement/edit/${response.id}`));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
    dispatch(createChannelManagerAsync.failure(err));
  }
};

export const updateChannelManager = (
  data: ChannelManager,
  managerId: string,
  prevSearch?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateChannelManagerAsync.request());
  try {
    const response = await updateManager(apiClient, { ...data, apiKey: null }, managerId);
    dispatch(updateChannelManagerAsync.success(response));
    dispatch(fetchGlobalErrorDialogAsync.success(response));

    dispatch(
      pushLocation({
        pathname: `/internal/channelManagement/edit/${data.id}`,
        state: { prevSearch },
      }),
    );
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
    dispatch(updateChannelManagerAsync.failure(err));
  }
};
