import {
  BrowseParameters,
  CampaignItems,
  CouponCodes,
  PromotionBudget,
  PromotionCap,
} from 'Campaign-Types';

export const defaultCampaignItem: CampaignItems = {
  id: '',
  analytics: null,
  representativeFeature: null,
  code: '',
  status: 'READY',
  active: false,
  currency: '',
  timeZone: '',
  effectivePeriod: {},
  privateTitle: null,
  placeId: '',
  description: '',
  designations: [],
  type: 'RAKUTEN_SALES_PROMOTION',
  incentiveType: null,
  participationExclusive: false,
  discount: null,
  featureGroups: [],
  point: null,
  offerType: 'DIRECT',
  couponCodes: [],
  searchCondition: {
    travellingPeriod: null,
    excludedDates: [],
    guestsNumber: null,
    lengthOfStay: {
      minLos: null,
      maxLos: null,
    },
    price: {
      minPrice: null,
      maxPrice: null,
    },
  },
  paymentMethods: [],
  platformCondition: {},
  userCondition: {},
  promotionCap: {} as PromotionCap,
  promotionClass: 'MAIN',
  promotionBudget: {} as PromotionBudget,
  applyForNewPlan: false,
  createDateTime: '',
  updateDateTime: '',
  manualProviderInvGen: true,
  freePlanSelectionAllowed: false,
  phase: 'PRE_APPLICATION',
  internalReleased: false,
  submissionPeriod: {},
  reviewPeriod: {},
  privateDocLink: '',
  publicDocLink: '',
  termConditionLink: '',
  campaignPageUrl: '',
  internalNote: '',
  campaignPrice: {
    applicationFee: 0,
    extraCommission: 0,
    promotionCostRequired: false,
  },
  promotionCostRequired: true,
  invitations: {},
  ancestors: [],
  parentId: '',
  characteristic: {},
  campaign: {},
};

export const defaultCampaignDeletable = {
  message: '',
  statusCode: null,
};

export const defaultDeletableDialog = {
  isShow: false,
};

export const defaultCoupon: CouponCodes = {
  code: '',
  active: false,
  note: '',
  url: '',
};

export const initialBrowseParameter: BrowseParameters = {
  keyword: '',
  startDate: '',
  endDate: '',
  searchCondition: 'BROAD',
  filter: [
    {
      label: 'Status',
      id: 'status',
      options: [
        { label: 'Active', value: 'ACTIVE', isCheck: true },
        { label: 'Inactive', value: 'INACTIVE', isCheck: false },
      ],
    },
    {
      label: 'Provider List Generation',
      id: 'listGeneration',
      options: [{ label: 'Generate manually', value: 'MANUAL', isCheck: true }],
    },
    {
      label: 'Phase',
      id: 'phase',
      options: [
        { label: 'Pre-Application', value: 'PREAPPLICATION', isCheck: true },
        { label: 'Application', value: 'APPLICATION', isCheck: false },
        { label: 'Review', value: 'REVIEW', isCheck: false },
        { label: 'Publish', value: 'PUBLISH', isCheck: false },
        { label: 'Expired', value: 'EXPIRED', isCheck: false },
      ],
    },
  ],
  sort: {
    options: [
      { text: 'Creation date: Newest-to-oldest', value: 'createDateTime=desc' },
      { text: 'Creation date: Oldest-to-newest', value: 'createDateTime=asc' },
      { text: 'Edit date: Newest-to-oldest', value: 'updateDateTime=desc' },
      { text: 'Edit date: Oldest-to-newest', value: 'updateDateTime=asc' },
      { text: 'Published Date: Newest-to-oldest', value: 'publishDateTime=desc' },
      { text: 'Published Date: Oldest-to-newest', value: 'publishDateTime=asc' },
      { text: 'End Date: Newest-to-oldest', value: 'endDateTime=desc' },
      { text: 'End Date: Oldest-to-newest', value: 'endDateTime=asc' },
    ],
    selectedValue: 'createDateTime=desc',
  },
  offset: 0,
  limit: 100,
};
