import { universalRouterProps } from 'core/universalRouter/types';

import { fetchRateControl } from 'store/rateControl/actions';
import { pushLocation } from 'store/__router/actions';

type Location = {
  baseUrl: string;
  params: {
    providerId: string;
  };
  query: {
    ratePlanId: string;
    itemList: string;
  };
};

export default (
  { store: { dispatch, getState } }: universalRouterProps,
  { params, query, baseUrl }: Location,
) => {
  const storedRatePlanId = getState().rateControl.rates?.ratePlanId;
  const { ratePlanId = storedRatePlanId, itemList } = query;
  const providerId = params.providerId;

  if (!ratePlanId || !itemList) pushLocation(`${baseUrl}/plans`);

  const rateControlFilter = {
    filter: {
      viewType: 'range',
      roomType: itemList?.split(','),
      // Copied the filter params from RateControlExtraRateForAGe (current filter we pass on fetching Rate Control)
      year: 2020,
    },
  };

  const promises = [dispatch(fetchRateControl(providerId, ratePlanId, rateControlFilter))];

  return Promise.all(promises);
};
