import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { FaxManagementItems, FaxManagementErrors } from 'FaxManagement-Types';
import { fetchFaxManagementAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchFaxManagementAsync.request], (state, action) => true)
  .handleAction(
    [fetchFaxManagementAsync.success, fetchFaxManagementAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as FaxManagementItems).handleAction(
  fetchFaxManagementAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      notifications:
        state.notifications && shouldConcat
          ? state.notifications.concat(action.payload.notifications)
          : action.payload.notifications,
    };
  },
);

export const errors = createReducer([] as FaxManagementErrors[]).handleAction(
  fetchFaxManagementAsync.failure,
  (state, action) => action.payload,
);

const faxManagementReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default faxManagementReducer;
export type FaxManagementState = ReturnType<typeof faxManagementReducer>;
