import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import {
  AvailabilityControl,
  AvailabilityControlErrors,
  FilterParams,
  ApiStatus,
  BulkSettings,
} from 'AvailabilityControl-Types';
import {
  fetchItemRatePlansAvailabilityControl,
  saveItemRatePlanAvailabilityControlCalendar,
  fetchRatePlansAvailabilityControl,
  saveRatePlansAvailabilityControlCalendar,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

export const setCalendarDataStandard = createStandardAction('SET_CALENDAR_DATA')<
  AvailabilityControl
>();

export const setBulkSettings = createStandardAction('SET_BULK_SETTINGS')<BulkSettings | {}>();

export const setBulkSettingCalendarData = createStandardAction('SET_BULK_SETTING_DATA')<
  AvailabilityControl | {}
>();
export const setFilterParams = createStandardAction('SET_FILTER_PARAMS')<FilterParams>();

export const setCalendarData = (calendarData: AvailabilityControl): AppThunk<void> => dispatch => {
  dispatch(setCalendarDataStandard(calendarData));
};

export const saveAvailabilityControlAsync = createAsyncAction(
  'SAVE_INVENTORY_CONTROL_REQUEST',
  'SAVE_INVENTORY_CONTROL_SUCCESS',
  'SAVE_INVENTORY_CONTROL_FAILURE',
)<undefined, ApiStatus, AvailabilityControlErrors[]>();

export const fetchAvailabilityControlAsync = createAsyncAction(
  'FETCH_AVAILABILITY_CONTROL_REQUEST',
  'FETCH_AVAILABILITY_CONTROL_SUCCESS',
  'FETCH_AVAILABILITY_CONTROL_FAILURE',
)<undefined, AvailabilityControl, AvailabilityControlErrors[]>();

export const fetchBulkRangeAvailabilityControlAsync = createAsyncAction(
  'FETCH_BULK_RANGE_AVAILABILITY_CONTROL_REQUEST',
  'FETCH_BULK_RANGE_AVAILABILITY_CONTROL_SUCCESS',
  'FETCH_BULK_RANGE_AVAILABILITY_CONTROL_FAILURE',
)<undefined, AvailabilityControl, AvailabilityControlErrors[]>();

//** Item Rate Plan Availability Control Actions */=====================================================
export const fetchItemRatePlanAvailabilityControl = (
  providerId: string,
  planId: string,
  filterParams: FilterParams,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchAvailabilityControlAsync.request());
  const availabilityControl = await fetchItemRatePlansAvailabilityControl(
    apiClient,
    providerId,
    planId,
    filterParams,
  );
  dispatch(fetchAvailabilityControlAsync.success(availabilityControl));

  dispatch(setFilterParams(filterParams));
};

export const fetchItemRatePlanBulkRangeAvailabilityControl = (
  providerId: string,
  planId: string,
  filterParams: FilterParams,
): AppThunk<Promise<AvailabilityControl>> => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchBulkRangeAvailabilityControlAsync.request());
  const availabilityControl = await fetchItemRatePlansAvailabilityControl(
    apiClient,
    providerId,
    planId,
    filterParams,
  );
  dispatch(fetchBulkRangeAvailabilityControlAsync.success(availabilityControl));
  dispatch(setBulkSettingCalendarData(availabilityControl));

  return availabilityControl;
};

export const saveItemRatePlanAvailabilityControl = (
  providerId: string,
  planId: string,
  calendarData: AvailabilityControl,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { filterParams } = getState().availabilityControl;

  dispatch(saveAvailabilityControlAsync.request());
  try {
    const response = await saveItemRatePlanAvailabilityControlCalendar(
      apiClient,
      providerId,
      planId,
      calendarData,
    );

    dispatch(fetchItemRatePlanAvailabilityControl(providerId, planId, filterParams));
    dispatch(saveAvailabilityControlAsync.success(response));
    dispatch(fetchGlobalErrorDialogAsync.success(response));
    /** Clear Bulk Settings and Data */
    dispatch(setBulkSettings({}));
    dispatch(setBulkSettingCalendarData({}));
  } catch (err) {
    dispatch(saveAvailabilityControlAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

//** Rate Plan Availability Control Actions */========================================================
export const fetchRatePlanAvailabilityControl = (
  providerId: string,
  ratePlanId: string,
  filterParams: FilterParams,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchAvailabilityControlAsync.request());
  const availabilityControl = await fetchRatePlansAvailabilityControl(
    apiClient,
    providerId,
    ratePlanId,
    filterParams,
  );
  dispatch(fetchAvailabilityControlAsync.success(availabilityControl));

  dispatch(setFilterParams(filterParams));
};

export const fetchRatePlanBulkRangeAvailabilityControl = (
  providerId: string,
  ratePlanId: string,
  filterParams: FilterParams,
): AppThunk<Promise<AvailabilityControl>> => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchBulkRangeAvailabilityControlAsync.request());
  const availabilityControl = await fetchRatePlansAvailabilityControl(
    apiClient,
    providerId,
    ratePlanId,
    filterParams,
  );
  dispatch(fetchBulkRangeAvailabilityControlAsync.success(availabilityControl));
  dispatch(setBulkSettingCalendarData(availabilityControl));

  return availabilityControl;
};

export const saveRatePlanAvailabilityControl = (
  providerId: string,
  ratePlanId: string,
  calendarData: AvailabilityControl,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { filterParams } = getState().availabilityControl;

  dispatch(saveAvailabilityControlAsync.request());
  try {
    const response = await saveRatePlansAvailabilityControlCalendar(
      apiClient,
      providerId,
      ratePlanId,
      calendarData,
    );

    dispatch(fetchRatePlanAvailabilityControl(providerId, ratePlanId, filterParams));
    dispatch(saveAvailabilityControlAsync.success(response));
    dispatch(fetchGlobalErrorDialogAsync.success(response));
    /** Clear Bulk Settings and Data */
    dispatch(setBulkSettings({}));
    dispatch(setBulkSettingCalendarData({}));
  } catch (err) {
    dispatch(saveAvailabilityControlAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};
