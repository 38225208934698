import {
  AnnouncementList,
  AnnouncementDetail,
  AnnouncementListParams,
  ApiStatus,
  UploadResult,
  UserData,
  UserSearchParams,
} from 'Announcement-Types';
import { ApiClient } from '@travel/api-client';

export const ApiFetchAnnouncementList = async (http: ApiClient, params: AnnouncementListParams) => {
  return http
    .get(`/announcement/list`, { values: params })
    .then((data: AnnouncementList) => data)
    .catch((data: AnnouncementList) => data);
};

export const ApiFetchAnnouncementDetail = (http: ApiClient, announcementId: string) => {
  return http
    .get(`/announcement/${announcementId}`)
    .then((data: AnnouncementDetail) => data)
    .catch((data: AnnouncementDetail) => data);
};

export const ApiUploadFile = (http: ApiClient, formData: FormData) => {
  return http
    .post(`/announcement/upload`, { values: formData })
    .then((data: UploadResult) => data)
    .catch((data: UploadResult) => data);
};

export const ApiDownloadUserListFile = (http: ApiClient, announceId: string) => {
  return http
    .get(`/announcement/${announceId}/download`, {})
    .then(data => data.blob())
    .catch(error => error);
};

export const ApiExportUploadFile = (http: ApiClient, importId: string) => {
  return http
    .get(`/announcement/upload/${importId}/download`)
    .then(data => data.blob())
    .catch(error => error);
};

export const ApiCreateAnnouncement = async (http: ApiClient, announcement: AnnouncementDetail) => {
  return http
    .post('/announcement', { values: announcement })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiUpdateAnnouncement = async (http: ApiClient, announcement: AnnouncementDetail) => {
  return http
    .put('/announcement', { values: announcement })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiDeleteAnnouncement = async (http: ApiClient, announcementId: string) => {
  return http
    .delete(`/announcement/${announcementId}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiSearchUser = (http: ApiClient, params: UserSearchParams) => {
  return http
    .post(`/announcement/addMember`, { values: params })
    .then((data: UserData) => data)
    .catch(data => data);
};
