import { RootState } from 'typesafe-actions';
import { initialResponse } from './initialValues';
import { GlobalErrorDialogResponse } from 'GlobalErrorDialog-Types';

const ERROR_CODES = [400, 401, 403, 404, 405, 408, 409, 413, 500, 502, 503, 504];

export const getResponse = (state: RootState) => {
  const response = state.globalErrorDialog.response;
  const { status, field, errorMessage } = response;
  let message = response.message;

  if (ERROR_CODES.includes(status)) {
    /**** Failure Case *********/
    if (field) {
      message = `${field} ${message}`;
    }
    if (!message && errorMessage) {
      // GATEWAY error(503, 504) has errorMessage key.
      message = errorMessage;
    }
    return {
      ...response,
      status: response.status, // NOTE: add explicity to avoid vanish
      message,
    };
  } else if (status !== 0) {
    /**** Success Case *********/
    // TODO: [Refactor] avoid to handle dialog with status (above status !== 0 is not good)
    // Many actions.ts are using dispatch(fetchGlobalErrorDialogAsync.success(response));
    // so if response is having status key and status === 0, above condition won't work. see core/App/index.tsx and EVEREST-13341
    return {
      ...response,
      status: 200,
      isSuccess: true,
    };
  }

  return initialResponse as GlobalErrorDialogResponse;
};
