import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import {
  ChannelManagerProviderSearchBody,
  ChannelManagerProviderListErrors,
  ChannelManagerConnectProviderBody,
  DisconnectedProvider,
} from 'ChannelManagerProviderList-Types';
import {
  fetchAllBmc,
  fetchAllAri,
  disconnectBmcProvider,
  disconnectAriProvider,
  connectAriProvider,
  connectBmcProvider,
} from './api';
import { getFilterQuery } from 'utils';
import { parseQueryString } from '@travel/utils';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { Query, transformToQueryObject as paramsToQueryObject } from 'utils/transformToQueryObject';
import { FilterGroup, Sort } from 'RatePlan-Types';

export const setDisconnectedProviderId = createStandardAction('SET_DISCONNECTED_PROVIDER_ID')<
  DisconnectedProvider[]
>();

export const clearDisconnectedProviderId = createStandardAction('CLEAR_DISCONNECTED_PROVIDER_ID')();

export const fetchChannelManagerBmcProviderListAsync = createAsyncAction(
  'FETCH_CHANNEL_MANAGER_BMC_PROVIDER_LIST_REQUEST',
  'FETCH_CHANNEL_MANAGER_BMC_PROVIDER_LIST_SUCCESS',
  'FETCH_CHANNEL_MANAGER_BMC_PROVIDER_LIST_FAILURE',
)<undefined, ChannelManagerProviderSearchBody, ChannelManagerProviderListErrors[]>();

export const fetchChannelManagerAriProviderListAsync = createAsyncAction(
  'FETCH_CHANNEL_MANAGER_ARI_PROVIDER_LIST_REQUEST',
  'FETCH_CHANNEL_MANAGER_ARI_PROVIDER_LIST_SUCCESS',
  'FETCH_CHANNEL_MANAGER_ARI_PROVIDER_LIST_FAILURE',
)<undefined, ChannelManagerProviderSearchBody, ChannelManagerProviderListErrors[]>();

export const disconnectBmcProviderAsync = createAsyncAction(
  'DISCONNECT_BMC_PROVIDER_REQUEST',
  'DISCONNECT_BMC_PROVIDER_SUCCESS',
  'DISCONNECT_BMC_PROVIDER_FAILURE',
)<undefined, ChannelManagerProviderSearchBody, ChannelManagerProviderListErrors[]>();

export const disconnectAriProviderAsync = createAsyncAction(
  'DISCONNECT_ARI_PROVIDER_REQUEST',
  'DISCONNECT_ARI_PROVIDER_SUCCESS',
  'DISCONNECT_ARI_PROVIDER_FAILURE',
)<undefined, ChannelManagerProviderSearchBody, ChannelManagerProviderListErrors[]>();

export const connectBmcProviderAsync = createAsyncAction(
  'CONNECT_BMC_PROVIDER_REQUEST',
  'CONNECT_BMC_PROVIDER_SUCCESS',
  'CONNECT_BMC_PROVIDER_FAILURE',
)<undefined, ChannelManagerProviderSearchBody, ChannelManagerProviderListErrors[]>();

export const connectAriProviderAsync = createAsyncAction(
  'CONNECT_ARI_PROVIDER_REQUEST',
  'CONNECT_ARI_PROVIDER_SUCCESS',
  'CONNECT_ARI_PROVIDER_FAILURE',
)<undefined, ChannelManagerProviderSearchBody, ChannelManagerProviderListErrors[]>();

export const fetchChannelManagerBmcProviderList = (
  managerId: string,
  query: Query = {},
  offset?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const fetchQuery = {
    ...paramsToQueryObject(query, ['status']),
    offset: offset || 0,
    limit: 30,
  };
  dispatch(fetchChannelManagerBmcProviderListAsync.request());
  const response = await fetchAllBmc(apiClient, managerId, fetchQuery);
  dispatch(fetchChannelManagerBmcProviderListAsync.success(response));
};

export const fetchChannelManagerAriProviderList = (
  managerId: string,
  query: Query = {},
  offset?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const fetchQuery = {
    ...paramsToQueryObject(query, ['status']),
    offset: offset || 0,
    limit: 30,
  };
  dispatch(fetchChannelManagerAriProviderListAsync.request());
  const response = await fetchAllAri(apiClient, managerId, fetchQuery);
  dispatch(fetchChannelManagerAriProviderListAsync.success(response));
};

export const disconnectBmcProviders = (
  filter: FilterGroup[] = [],
  sort: Sort = { options: [], selectedValue: '' },
  keyword?: string,
  callbackWhenSuccess?: () => void,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const state = getState();
  const managerId = state.channelManager.managerDetail.id;
  const providerIds = state.channelManagerProviderList.disconnectedProviders.map(
    provider => provider.id,
  );
  dispatch(disconnectBmcProviderAsync.request());
  const response = await disconnectBmcProvider(apiClient, managerId, providerIds);
  dispatch(disconnectBmcProviderAsync.success(response));

  if (response?.status === 200) {
    callbackWhenSuccess?.();
  }

  const searchQueryParams = parseQueryString(getFilterQuery(filter, {}, sort));
  dispatch(fetchChannelManagerBmcProviderList(managerId, { ...searchQueryParams, keyword }));
};

export const disconnectAriProviders = (
  filter: FilterGroup[] = [],
  sort: Sort = { options: [], selectedValue: '' },
  keyword?: string,
  callbackWhenSuccess?: () => void,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const state = getState();
  const managerId = state.channelManager.managerDetail.id;
  const providerIds = state.channelManagerProviderList.disconnectedProviders.map(
    provider => provider.id,
  );
  dispatch(disconnectAriProviderAsync.request());
  const response = await disconnectAriProvider(apiClient, managerId, providerIds);
  dispatch(disconnectAriProviderAsync.success(response));

  if (response?.status === 200) {
    callbackWhenSuccess?.();
  }

  const searchQueryParams = parseQueryString(getFilterQuery(filter, {}, sort));
  dispatch(fetchChannelManagerAriProviderList(managerId, { ...searchQueryParams, keyword }));
};

export const connectAriProviderIds = (
  data: ChannelManagerConnectProviderBody,
  filter: FilterGroup[] = [],
  sort: Sort = { options: [], selectedValue: '' },
  keyword?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const state = getState();
  const managerId = state.channelManager.managerDetail.id;
  dispatch(connectAriProviderAsync.request());
  const response = await connectAriProvider(apiClient, managerId, data);
  dispatch(connectAriProviderAsync.success(response));

  dispatch(fetchGlobalErrorDialogAsync.success(response));

  if (response.status === 200) {
    const searchQueryParams = parseQueryString(getFilterQuery(filter, {}, sort));
    dispatch(fetchChannelManagerAriProviderList(managerId, { ...searchQueryParams, keyword }));
  }
};

export const connectBmcProviderIds = (
  data: ChannelManagerConnectProviderBody,
  filter: FilterGroup[] = [],
  sort: Sort = { options: [], selectedValue: '' },
  keyword?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const state = getState();
  const managerId = state.channelManager.managerDetail.id;
  dispatch(connectBmcProviderAsync.request());

  const response = await connectBmcProvider(apiClient, managerId, data);
  dispatch(connectBmcProviderAsync.success(response));

  dispatch(fetchGlobalErrorDialogAsync.success(response));

  if (response.status === 200) {
    const searchQueryParams = parseQueryString(getFilterQuery(filter, {}, sort));
    dispatch(fetchChannelManagerBmcProviderList(managerId, { ...searchQueryParams, keyword }));
  }
};
