import React, { Component, ReactNode } from 'react';
import { AttentionDisable } from '@travel/icons/ui';

import styles from './errorBoundary.module.scss';
import { Translate } from '@travel/translation';
import { noticeError } from '@travel/tracker';

type Props = {
  children?: ReactNode;
};

type ErrorInfo = {
  componentStack: string;
};

type State = {
  error?: Error;
  info?: ErrorInfo;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log('ErrorBoundary ErrorInfo: ', errorInfo.componentStack);
    noticeError('ErrorBoundary ErrorInfo: ', errorInfo.componentStack);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error !== undefined) {
      console.log('::::Errorboundary logs::::', error);
      noticeError('::::Errorboundary logs::::', error);

      // You can render any custom fallback UI or redirect page from here
      return (
        <>
          <div
            data-locator-id="ErrorBoundary-ad693029-38b7-47c6-a104-3321b62e35d8"
            className={styles.errorPage}
          >
            <AttentionDisable size={150} color="pastelGray" className={styles.icon} />
            <p className={styles.mainText}>
              <Translate id="Extranet_Error.System_Error.Redirection_Failure"></Translate>
            </p>
          </div>
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
