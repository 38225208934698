import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ObjectScoreList } from 'ObjectScore-Types';
import { fetchObjectScoreListAsync, clearObjectScoreList } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchObjectScoreListAsync.request], (state, action) => true)
  .handleAction(
    [fetchObjectScoreListAsync.success, fetchObjectScoreListAsync.failure],
    (state, action) => false,
  );

export const list = createReducer({} as ObjectScoreList)
  .handleAction(clearObjectScoreList, () => ({} as ObjectScoreList))
  .handleAction(fetchObjectScoreListAsync.success, (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      ranks:
        state.ranks && shouldConcat
          ? state.ranks.concat(action.payload.ranks)
          : action.payload.ranks,
    };
  });

const objectScoreReducer = combineReducers({
  isFetching,
  list,
});

export default objectScoreReducer;
export type ObjectScoreState = ReturnType<typeof objectScoreReducer>;
