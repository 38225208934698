import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import isNotEmptyArray from '@travel/ui/utils/arrays';

import unionBy from 'lodash/unionBy';

import { AllRate, FilterType, RateControlErrors, RateControlRates } from 'RateControl-Types';
import {
  clearBulkSettingsTarget,
  fetchBulkSettingsTargetAsync,
  fetchRateControlAsync,
  saveRateControlAsync,
  setCalendarDataStandard,
  setFilterParams,
} from './actions';

export const calendarData = createReducer({} as RateControlRates).handleAction(
  setCalendarDataStandard,
  (state, action) => action.payload,
);

export const isCreating = createReducer(false)
  .handleAction(saveRateControlAsync.request, () => true)
  .handleAction([saveRateControlAsync.success, saveRateControlAsync.failure], () => false);

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchRateControlAsync.request], (state, action) => true)
  .handleAction(
    [fetchRateControlAsync.success, fetchRateControlAsync.failure],
    (state, action) => false,
  )
  .handleAction([fetchBulkSettingsTargetAsync.request], (state, action) => true)
  .handleAction(
    [fetchBulkSettingsTargetAsync.success, fetchBulkSettingsTargetAsync.failure],
    (state, action) => false,
  );

export const rates = createReducer({} as RateControlRates).handleAction(
  fetchRateControlAsync.success,
  (state, action) => action.payload,
);

export const bulkSettingsTarget = createReducer([] as AllRate[])
  .handleAction(fetchBulkSettingsTargetAsync.success, (state, action) => {
    const tempRate: AllRate = action.payload[0];

    // to concatenate new bulk settings targets to previous bulk settings targets
    if (isNotEmptyArray(state)) {
      tempRate.adultRates.forEach(room => {
        const targetRoom = state[0].adultRates.find(appliedRoom => {
          return appliedRoom.itemId === room.itemId;
        });

        room.guestRates?.forEach(rate => {
          const targetRate = targetRoom?.guestRates.find(
            appliedRate => appliedRate.guestCnt === rate.guestCnt,
          );

          if (!!targetRate) {
            rate.rates = unionBy(targetRate.rates, rate.rates, 'date');
          }
        });
      });
    }

    return [tempRate];
  })
  .handleAction(clearBulkSettingsTarget, () => []);

export const errors = createReducer([] as RateControlErrors[]).handleAction(
  fetchRateControlAsync.failure,
  (state, action) => action.payload,
);

export const filterParams = createReducer({} as FilterType).handleAction(
  setFilterParams,
  (state, action) => action.payload,
);

const rateControlReducer = combineReducers({
  isFetching,
  rates,
  errors,
  filterParams,
  calendarData,
  bulkSettingsTarget,
  isCreating,
});

export default rateControlReducer;
export type RateControlState = ReturnType<typeof rateControlReducer>;
