import { useEffect, useRef, RefObject } from 'react';

const useInfiniteScroll = (
  scrollEl: RefObject<HTMLDivElement>,
  skeletonEl: RefObject<HTMLDivElement>,
  hasInfiniteScroll?: boolean,
  hasMore?: boolean,
  next?: () => void,
  shouldScrollUpToTop?: boolean,
): [RefObject<HTMLDivElement>] => {
  const actionTriggered = useRef<boolean>(false);

  useEffect(() => {
    const scrollElem = scrollEl.current;
    const skeletonElem = skeletonEl.current;

    if (!hasInfiniteScroll || !scrollElem) return;

    // initialize actionTriggered when new data was fetched
    if (actionTriggered.current) actionTriggered.current = false;

    // scroll back to top
    const isNotTop = scrollElem.scrollTop > 0;
    if (shouldScrollUpToTop && isNotTop) scrollElem.scrollTo({ top: 0 });

    // call next() function prop when scroll reached bottom
    const handleScroll = () => {
      // return if the next() function has already been done, to prevent multiple trigger
      if (actionTriggered.current) return;

      const skeletonEleClientHeight = skeletonElem ? skeletonElem.clientHeight : 0;
      const isBottom =
        scrollElem.scrollHeight -
          scrollElem.scrollTop -
          scrollElem.clientHeight -
          skeletonEleClientHeight <
        1;

      if (isBottom && hasMore) {
        actionTriggered.current = true;

        // timer to prevent multiple call from api when loader shows
        const handler = setTimeout(() => next && next(), 300);

        return () => clearTimeout(handler);
      } else {
        return;
      }
    };

    scrollElem.addEventListener('scroll', handleScroll, false);
    return () => scrollElem.removeEventListener('scroll', handleScroll, false);
  }, [hasInfiniteScroll, hasMore, next, scrollEl, shouldScrollUpToTop, skeletonEl]);

  return [scrollEl];
};

export default useInfiniteScroll;
