import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ReservationMessageList, ReservationMessagePreview } from 'BookingMessage-Types';
import {
  fetchMessageListAsync,
  fetchSentMessagePreviewAsync,
  fetchMessagePreviewAsync,
} from './actions';

export const messageList = createReducer({} as ReservationMessageList).handleAction(
  fetchMessageListAsync.success,
  (state, action) => action.payload,
);

export const messagePreview = createReducer({} as ReservationMessagePreview)
  .handleAction(fetchSentMessagePreviewAsync.success, (state, action) => action.payload)
  .handleAction(fetchMessagePreviewAsync.success, (state, action) => action.payload);

const bookingMessageReducer = combineReducers({
  messageList,
  messagePreview,
});

export default bookingMessageReducer;
export type BookingState = ReturnType<typeof bookingMessageReducer>;
