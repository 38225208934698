import { ApiClient } from '@travel/api-client';
import providerPrefix from './../../constants/api-prefix';

import { ApiStatus, ProviderCampaignList, ProviderCampaign } from 'ProviderCampaign-Types';

export const ApiFetchPromotionCampaignList = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<ProviderCampaignList> =>
  http
    .get(`${providerPrefix}/${providerId}/campaigns`, { values: params })
    .then((data: ProviderCampaignList) => data);

export const ApiFetchPromotionCampaign = (
  http: ApiClient,
  providerId: string,
  campaignId: string,
): Promise<ProviderCampaign> =>
  http
    .get(`${providerPrefix}/${providerId}/campaigns/${campaignId}`)
    .then((data: ProviderCampaign) => data);

export const ApiPutPromotionCampaignSave = (
  http: ApiClient,
  providerId: string,
  campaignId: string,
  data: ProviderCampaign,
): Promise<ApiStatus> =>
  http
    .put(`${providerPrefix}/${providerId}/campaigns/${campaignId}`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiPutPromotionCampaignSubmit = (
  http: ApiClient,
  providerId: string,
  campaignId: string,
  data: ProviderCampaign,
): Promise<ApiStatus> =>
  http
    .put(`${providerPrefix}/${providerId}/campaigns/${campaignId}/submit`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
