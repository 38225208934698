import { universalRouterProps } from 'core/universalRouter/types';
import { fetchUserSegments } from 'store/userSegment/actions';
import { initFilterState } from 'store/userSegment/initialValues';
import { UserSegmentQueryParams } from 'UserSegment-Types';

type Location = {
  query: UserSegmentQueryParams | null;
};

export default ({ store: { dispatch } }: universalRouterProps, { query }: Location) => {
  const fetchQuery =
    query && Object.keys(query).length
      ? { ...query, keyword: encodeURIComponent(query.keyword || '') }
      : initFilterState;

  return Promise.all([dispatch(fetchUserSegments(fetchQuery))]);
};
