import {
  BookingDetail,
  BookingModificationDetail,
  CancelType,
  GuestInfo,
  PaymentMethod,
  PenaltyType,
  ServiceType,
} from 'Booking-Types';

export const initialBookingModificationDetail: BookingModificationDetail = {
  reservationId: '',
  checkInDate: '',
  checkOutDate: '',
  price: {
    totalPerUnitDiscount: 0,
    totalPerReservationDiscount: 0,
    totalPriceWithoutDiscount: 0,
    totalPriceWithDiscount: 0,
    totalRakutenPromotion: 0,
    totalProviderPromotion: 0,
    usageUnits: [],
    salesPromotions: [],
    totalUsedPoint: 0,
  },
  providerCurrency: '',
  providerTimeZone: '',
  travelerTimeZone: '',
  note: '',
  dpBookingId: '',
  dpBookingStatus: '',
  dpRateTotal: 0,
  tourStartDate: '',
  tourEndDate: '',
  market: '',
  cancellationDetails: {
    cancellationType: 'CANCELLED',
    cancellationPolicyName: '',
    totalCancellationCharge: Infinity,
    cancellationCharges: [],
  },
  confirmStayDateCancellationDetails: {
    totalCancellationCharge: 0,
    cancelledUnits: [],
  },
};

export const initialBookingTranslation = {
  userSpecialRequest: '',
  showTranslatedUserSpecialRequestBtn: false,
  noticesWithTranslation: [],
  questions: [],
};

export const defaultBookingDetail: BookingDetail = {
  bookingDetails: {
    bookingId: '',
    reservationStatus: 'BOOKED',
    designation: '',
    isNoShow: false,
    reservedDateTime: '',
    travelerReservedDateTime: '',
    cancelledDateTime: '',
    travelerCancelledDateTime: '',
    checkInDate: '',
    checkInTime: '',
    checkInTimeFrom: '',
    checkInTimeTo: '',
    checkOutDate: '',
    checkOutTime: '',
    arrivalTime: '',
    cancellationReason: 'OTHER',
    distributorReservationId: '',
    note: '',
    numberOfStayNights: 0,
    pointAssignmentVersion: '0',
    reservationId: '',
    externalReservationId: '',
    userSpecialRequest: '',
    showTranslatedUserSpecialRequestBtn: true,
    travelerTimeZone: '',
    providerTimeZone: '',
    editedDateTime: '',
    travelerEditedDateTime: '',
    optedForInsurance: null,
    translatedUserSpecialRequest: '',
  },
  guestDetails: {
    noOfFemaleGuests: 0,
    noOfMaleGuests: 0,
    noOfAdults: 0,
    noOfChildren: 0,
    noOfGuests: 0,
    noOfElementaryStudent: 0,
    noOfInfantMealBed: 0,
    noOfInfantNoMealBed: 0,
    childrenAges: [],
    guests: [{ isRepresentative: false, firstName: '', lastName: '', sequenceId: 0 }] as GuestInfo,
  },
  market: '',
  provider: {
    id: '',
    providerIdV1: '',
    v1SyncStatus: false,
    name: '',
    requireCreditCardForGuarantee: false,
    checkIn: '',
    checkOut: '',
    remarks: [],
    version: '',
    media: [
      {
        thumbnailUrl: '',
        type: 'IMAGE',
        url: '',
      },
    ],
    sizes: [
      {
        id: '',
        userName: '',
        measurements: {
          measurementName: '',
          measurementSystem: '',
          unit: '',
          unitSyntax: '',
          value: 0,
        },
      },
    ],
    priceRoundPosition: 0,
    market: '',
  },
  item: {
    id: '',
    v1Id: '',
    version: '',
    itemCode: '',
    rateUnit: '',
    rateCapacity: 0,
    name: '',
    managementName: '',
    description: '',
    guestCapacity: {
      minGuests: 0,
      maxGuests: 0,
      minAdults: 0,
      maxAdults: 0,
      maxChildren: 0,
      minChildren: 0,
    },
    featureGroups: [
      {
        id: '',
        name: '',
        title: '',
        features: [{ id: '', name: '', icon: '', iconType: '' }],
      },
    ],
    media: [
      {
        thumbnailUrl: '',
        type: 'IMAGE',
        url: '',
      },
    ],
    sizes: [
      {
        id: '',
        userName: '',
        measurements: {
          measurementName: '',
          measurementSystem: '',
          unit: '',
          unitSyntax: '',
          value: 0,
        },
      },
    ],
  },
  receiptIssueLimit: false,
  ratePlan: {
    id: '',
    v1Id: '',
    v1SyncStatus: false,
    active: false,
    version: '',
    ratePlanCode: '',
    name: '',
    managementName: '',
    description: '',
    media: [],
    rateCalculationRule: 'STAY_DATE_BASE',
    checkIn: '',
    lastCheckIn: '',
    checkOut: '',
    benefits: [],
    payments: [],
    lengthOfStayRestrictions: [
      {
        effectivePeriods: [{ from: '', to: '' }],
        minLengthOfStay: 0,
        maxLengthOfStay: 0,
      },
    ],
    bookingAcceptance: {
      bookableAbsoluteStart: '',
      bookableAbsoluteEnd: '',
      bookableStartDays: 0,
      bookableStartDayLimitTime: '',
      bookableCutoffDays: 0,
      bookableCutoffDayLimitTime: '',
    },
    featureGroups: [
      {
        id: '',
        name: '',
        managementName: '',
        title: '',
        features: [
          {
            id: '',
            name: '',
            managementName: '',
            title: '',
            iconType: '',
            iconUrl: '',
            iconThumbnailUrl: '',
          },
        ],
      },
    ],
  },
  applicant: {
    email: '',
    language: 'en-US',
    market: '',
    membershipType: 'RAKUTEN',
    easyId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    designation: '',
    memberId: '',
  },
  cancellationPolicyDetails: {
    echoId: '',
    estimatedPenalties: [
      {
        cancellationPenaltySeqId: 0,
        endDateTime: '',
        penaltyType: 'CANCELLATION_PENALTY' as PenaltyType,
        appliedAnyTime: false,
        penaltyAmountUser: 0,
        penaltyAmountProvider: 0,
        percentPenalty: 0,
        penaltyNights: 0,
        deadlineDays: 0,
        deadlineTime: '',
        hasPenaltyPerNights: true,
      },
    ],
    id: '',
    version: '',
    name: '',
    planId: '',
    policyId: '',
    policyVersion: '',
    providerCurrency: '',
    providerId: '',
    ratePlanId: '',
    userCurrency: '',
  },
  review: '',
  exchangeRates: [],
  payments: [],
  paymentId: '',
  price: {
    totalPriceWithoutDiscountProvider: 0,
    totalPriceWithoutDiscountUser: 0,
    totalProviderPromotionUser: 0,
    totalRakutenPromotionUser: 0,
    totalPerUnitDiscountUser: 0,
    totalPriceWithDiscountUser: 0,
    totalPerReservationDiscountUser: 0,
    totalPerUnitDiscountProvider: 0,
    totalPerReservationDiscountProvider: 0,
    totalPriceWithDiscountProvider: 0,
    totalRakutenPromotionProvider: 0,
    totalProviderPromotionForProvider: 0,
    totalUsedPoint: 0,
    paymentType: '',
    payments: [],
    providerCurrency: '',
    totalEarnedPoints: {
      amount: 0,
      basePoints: 0,
      promotionPoints: 0,
    },
    salesPromotions: [],
    usageUnits: [
      {
        date: '',
        unitPriceWithoutDiscountProvider: 0,
        unitPriceWithoutDiscountUser: 0,
        unitDiscountProvider: 0,
        unitDiscountUser: 0,
        unitPriceWithDiscountProvider: 0,
        unitPriceWithDiscountUser: 0,
        usagePrices: [
          {
            age: 0,
            childRateAgeFrom: 0,
            childRateAgeTo: 0,
            guestType: null,
            benefits: [],
            count: 1,
            priceProvider: 0,
            priceUser: 0,
            subTotalProvider: 0,
            subTotalUser: 0,
            type: 'ADULT',
            usagePriceSeqIdList: [1],
          },
        ],
        taxInfos: [],
      },
    ],
    usageUnitMealConditions: [
      {
        date: '',
        infantNoMealBedRate: 0,
        mealCondition: {
          breakfastIncluded: false,
          breakfastLocation: 'RESTAURANT',
          dinnerIncluded: false,
          dinnerLocation: 'RESTAURANT',
        },
      },
    ],
    userCurrency: '',
  },
  taxesAndFees: [
    {
      id: '',
      active: false,
      announcementDate: '',
      chargeUnit: '',
      code: 'CITY_TAX',
      currency: '',
      effectiveDate: '',
      expiredDate: '',
      included: false,
      type: 'TAX',
      name: '',
      conditions: [
        {
          id: '',
          priceForUseFrom: '',
          priceForUseTo: '',
          ageFrom: '',
          ageTo: '',
          losFrom: '',
          losTo: '',
          providerRatingFrom: '',
          providerRatingTo: '',
          amount: '',
          percentage: 0,
        },
      ],
    },
  ],
  goToLocalCouponAmount: null,
  goToLocalCouponToken: null,
  reservationChangeHistory: [],
  cancellable: false,
  noticesAndQuestions: {
    notices: [],
    questions: [],
    noticesWithTranslation: [],
  },
  cancellationDetails: {
    totalAmountProvider: 0,
    totalAmountTraveler: 0,
    source: null,
    cancellationCharges: [
      {
        isNoShow: false,
        cancelledStayDate: '',
        appliedPenaltyUntil: '',
        hasManuallyModified: false,
        providerAmount: 0,
        userAmount: 0,
        penaltyPercentage: 0,
        penaltyNights: 0,
      },
    ],
  },
  refundDetails: [
    {
      orderId: '',
      providerAmount: 0,
      userAmount: 0,
      userCurrency: '',
      providerCurrency: '',
      source: 'RAKUTEN',
      last4Digits: '',
      cardBin: '',
    },
  ],
  dpPrice: {
    actor: 'INTERNAL',
    bookingCenterInfo: null,
    cancelPointCash: 0,
    cancelType: 'NORMAL' as CancelType,
    couponProviderAmount: 0,
    couponTravelerAmount: 0,
    dpBookingId: '',
    dpBookingStatus: 'BOOKED',
    dpCancelRateTotal: 0,
    dpDiscountTotal: 0,
    dpRateTotal: 0,
    dpRemainingRateTotal: 0,
    dpTransitionDateTime: '',
    expectedGrantPoint: 0,
    paymentMethod: 'CREDIT_CARD' as PaymentMethod,
    priorityUseCash: true,
    tourEndDate: '',
    tourStartDate: '',
    usagePointCash: 0,
    usageCouponInfo: [
      {
        couponDescription: '',
        couponId: '',
        couponName: '',
        discountAmount: 0,
        serviceType: 'DP_YADO' as ServiceType,
      },
    ],
  },
  dpTransitions: [
    {
      actor: 'INTERNAL',
      bookingCenterInfo: null,
      cancelPointCash: 0,
      cancelType: 'NORMAL' as CancelType,
      couponProviderAmount: 0,
      couponTravelerAmount: 0,
      dpBookingId: '',
      dpBookingStatus: 'BOOKED',
      dpCancelRateTotal: 0,
      dpDiscountTotal: 0,
      dpRateTotal: 0,
      dpRemainingRateTotal: 0,
      dpTransitionDateTime: '',
      expectedGrantPoint: 0,
      paymentMethod: 'CREDIT_CARD' as PaymentMethod,
      priorityUseCash: true,
      tourEndDate: '',
      tourStartDate: '',
      usagePointCash: 0,
      usageCouponInfo: [
        {
          couponDescription: '',
          couponId: '',
          couponName: '',
          discountAmount: 0,
          serviceType: 'DP_YADO' as ServiceType,
        },
      ],
    },
  ],
  totalRefundDetails: [],
  isNoteEditAllowed: false,
  isShortenAllowed: true,
  isModifyPriceAllowed: true,
  isModifyCancellationFeeAllowed: false,
  rconnect: {
    enabled: false,
    status: 'CONFIRMED_BOOKING',
    queues: [],
    history: [],
  },
};
