import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  BrowseParameters,
  CancelPolicyErrors,
  CancelPolicy,
  CancelPolicyContent,
  ApiStatus,
  Deletable,
  DeletableDialog,
} from 'CancelPolicy-Types';
import { transformToQueryObject } from 'utils/transformToQueryObject';
import { filterIdList } from './initialValues';
import {
  ApiFetchCancelPolicyList,
  ApiFetchCancelPolicy,
  create,
  deleteCancellationPolicy,
  update,
  fetchDeletableCancellation,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { updateCampaignAsync } from 'store/campaign/actions';
import { toDecodedQuery, toQuery } from '@travel/utils';

export const updateCancelPolicyBrowseParameter = createStandardAction(
  'UPDATE_CANCEL_POLICY_BROWSE_PARAMETER',
)<BrowseParameters>();
export const updateSearchText = createStandardAction('UPDATE_SEARCH_TEXT')<string>();

export const clearCancelPolicy = createStandardAction('CLEAR_CANCEL_POLICY')();

export const fetchCancelPolicyListAsync = createAsyncAction(
  'FETCH_CANCEL_POLICY_LIST_REQUEST',
  'FETCH_CANCEL_POLICY_LIST_SUCCESS',
  'FETCH_CANCEL_POLICY_LIST_FAILURE',
)<undefined, CancelPolicy, CancelPolicyErrors[]>();

export const fetchMostLaxCancelPolicyAsync = createAsyncAction(
  'FETCH_MOST_LAX_CANCEL_POLICY_REQUEST',
  'FETCH_MOST_LAX_CANCEL_POLICY_SUCCESS',
  'FETCH_MOST_LAX_CANCEL_POLICY_FAILURE',
)<undefined, CancelPolicyContent, CancelPolicyErrors[]>();

export const fetchCancelPolicyAsync = createAsyncAction(
  'FETCH_CANCEL_POLICY_REQUEST',
  'FETCH_CANCEL_POLICY_SUCCESS',
  'FETCH_CANCEL_POLICY_FAILURE',
)<undefined, CancelPolicyContent, CancelPolicyErrors[]>();

export const fetchDeletableCancelPolicyAsync = createAsyncAction(
  'FETCH_DELETABLE_CANCEL_POLICY_REQUEST',
  'FETCH_DELETABLE_CANCEL_POLICY_SUCCESS',
  'FETCH_DELETABLE_CANCEL_POLICY_FAILURE',
)<undefined, Deletable, CancelPolicyErrors[]>();

export const putDeletableDialogAsync = createAsyncAction(
  'DELETABLE_DIALOG_CANCEL_POLICY_REQUEST',
  'DELETABLE_DIALOG_CANCEL_POLICY_SUCCESS',
  'DELETABLE_DIALOG_CANCEL_POLICY_FAILURE',
)<undefined, DeletableDialog, CancelPolicyErrors[]>();

export const createCancelPolicyAsync = createAsyncAction(
  'CREATE_CANCEL_POLICY_REQUEST',
  'CREATE_CANCEL_POLICY_SUCCESS',
  'CREATE_CANCEL_POLICY_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const updateCancelPolicyAsync = createAsyncAction(
  'UPDATE_CANCEL_POLICY_REQUEST',
  'UPDATE_CANCEL_POLICY_SUCCESS',
  'UPDATE_CANCEL_POLICY_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const deleteCancelPolicyAsync = createAsyncAction(
  'DELETE_CANCEL_POLICY_REQUEST',
  'DELETE_CANCEL_POLICY_SUCCESS',
  'DELETE_CANCEL_POLICY_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const fetchCancelPolicyList = (
  providerID: string,
  params: { [key: string]: string },
  offset?: number,
  limit?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchCancelPolicyListAsync.request());
  const fetchQuery = {
    ...transformQuery(params),
    offset: offset || 0,
    limit: limit || 30,
  };
  const policyList = await ApiFetchCancelPolicyList(apiClient, providerID, fetchQuery);
  dispatch(fetchCancelPolicyListAsync.success(policyList));
};

export const fetchMostLaxCancelPolicy = (providerID: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMostLaxCancelPolicyAsync.request());
  const fetchQuery = {
    ...toDecodedQuery({ filter: { status: [true] }, sort: { strictnessLevel: 'asc' } }),
    offset: 0,
    limit: 1,
  };
  const policyList = await ApiFetchCancelPolicyList(apiClient, providerID, fetchQuery);
  dispatch(fetchMostLaxCancelPolicyAsync.success(policyList?.contents?.[0] || {}));
};

export const fetchCancelPolicy = (
  providerID: string,
  policyId: string,
  params: { [key: string]: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchCancelPolicyAsync.request());
  const policy = await ApiFetchCancelPolicy(
    apiClient,
    providerID,
    policyId,
    transformToQueryObject(params, filterIdList),
  );
  dispatch(fetchCancelPolicyAsync.success(policy));
};

export const fetchDelatableCancelPolicy = (
  providerID: string,
  cancelPolicyId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(fetchDeletableCancelPolicyAsync.request());
    const res = await fetchDeletableCancellation(apiClient, providerID, cancelPolicyId);
    dispatch(fetchDeletableCancelPolicyAsync.success(res));
    dispatch(putDeletableDialogAsync.success({ isShow: true }));
  } catch (error) {
    error.status === 403
      ? dispatch(fetchDeletableCancelPolicyAsync.success(error as ApiStatus)) &&
        dispatch(putDeletableDialogAsync.success({ isShow: true }))
      : dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

// TODO ask the author to fix the type
export const createCancelPolicy = (
  providerId: string,
  data: CancelPolicyContent,
): AppThunk<Promise<any>> => async (dispatch, getState, { apiClient }) => {
  dispatch(createCancelPolicyAsync.request());
  const correctData = { ...data };
  delete correctData['cancellationDefinition'];
  try {
    const res = await create(apiClient, providerId, correctData);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(createCancelPolicyAsync.success(res));
    return res;
  } catch (err) {
    dispatch(createCancelPolicyAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const updateCancelPolicy = (
  providerId: string,
  data: CancelPolicyContent,
): AppThunk<Promise<any>> => async (dispatch, getState, { apiClient }) => {
  dispatch(updateCancelPolicyAsync.request());
  const correctData = { ...data };
  delete correctData['cancellationDefinition'];
  try {
    const res = await update(apiClient, providerId, correctData);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(updateCancelPolicyAsync.success(res));

    return res;
  } catch (err) {
    dispatch(updateCampaignAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    return err;
  }
};

export const deleteCancelPolicy = (
  providerId: string,
  cancelPolicyId: string,
  cancelPolicyName: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteCancelPolicyAsync.request());

  try {
    const res = await deleteCancellationPolicy(apiClient, providerId, cancelPolicyId);
    // Pass the deleted object name as message
    res.message = cancelPolicyName;

    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteCancelPolicyAsync.success(res));
  } catch (error) {
    dispatch(deleteCancelPolicyAsync.failure(error));
    dispatch(deleteCancelPolicyAsync.failure([error as ApiStatus]));
  }
};

function transformQuery(query: { [key: string]: string }) {
  if (!query) return toQuery({});
  const {
    searchText,
    keyword,
    cancelPolicyName,
    createDateTime,
    strictnessLevel,
    updateDateTime,
    searchCondition,
    ...rest
  } = query;

  const builtQuery = {
    filter: {
      key: searchText || '',
      ...rest,
    },
    sort: {
      cancelPolicyName,
      createDateTime,
      strictnessLevel,
      updateDateTime,
    },
    keyword: keyword,
    searchCondition,
  };

  return toDecodedQuery(builtQuery);
}
