import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { ObjectScoreList, ApiStatus } from 'ObjectScore-Types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { ApiFetchObjectScoreList, ApiDownloadObjectScoreList } from './apis';
import { downloadFileFromBlob } from '@travel/utils';

export const clearObjectScoreList = createStandardAction('CLEAR_OBJECT_SCORE_LIST')();

export const fetchObjectScoreListAsync = createAsyncAction(
  'FETCH_OBJECT_SCORE_REQUEST',
  'FETCH_OBJECT_SCORE_SUCCESS',
  'FETCH_OBJECT_SCORE_FAILURE',
)<undefined, ObjectScoreList, undefined>();

export const downloadObjectScoreListAsync = createAsyncAction(
  'DOWNLOAD_OBJECT_SCORE_REQUEST',
  'DOWNLOAD_OBJECT_SCORE_SUCCESS',
  'DOWNLOAD_OBJECT_SCORE_FAILURE',
)<undefined, undefined, undefined>();

export const fetchObjectScoreList = (
  query: { [key: string]: string },
  offset?: number,
  rejectIfFetching?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isFetching = getState().objectScore.isFetching;
  if (isFetching && rejectIfFetching) return;

  const apiQuery = {
    ...query,
    limit: 30,
    offset: offset || 0,
  };

  dispatch(fetchObjectScoreListAsync.request());
  try {
    const res = await ApiFetchObjectScoreList(apiClient, apiQuery);
    dispatch(fetchObjectScoreListAsync.success(res as ObjectScoreList));
  } catch (error) {
    dispatch(fetchObjectScoreListAsync.failure());
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const downloadObjectScoreCsv = (
  query: { [key: string]: string },
  fileName: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(downloadObjectScoreListAsync.request());
  const res = await ApiDownloadObjectScoreList(apiClient, query);
  try {
    const blob = await res.blob();
    downloadFileFromBlob(blob, fileName);
    dispatch(downloadObjectScoreListAsync.success());
  } catch (error) {
    dispatch(downloadObjectScoreListAsync.failure());
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};
