export const SIZE_COUNT_TYPE = {
  BOTH: 'BOTH',
  COUNT: 'COUNT',
  SIZE_TYPES: 'SIZE_TYPES',
  NONE: 'NONE',
} as const;

export type SizeCountType = typeof SIZE_COUNT_TYPE[keyof typeof SIZE_COUNT_TYPE];

export const showSizeTypesForm = (type: string) =>
  type === SIZE_COUNT_TYPE.BOTH || type === SIZE_COUNT_TYPE.SIZE_TYPES;

export const showCountForm = (type: string) =>
  type === SIZE_COUNT_TYPE.BOTH || type === SIZE_COUNT_TYPE.COUNT;
