import { isEmptyObject, isNotEmptyArray } from '@travel/utils';

const hasFilteringQuery = <T = { [key: string]: string }>(
  query: T,
  filterIdList: string[],
): boolean => {
  if (isEmptyObject(query) || !isNotEmptyArray(filterIdList)) return false;
  return !!Object.keys(query).find(key => {
    const isExistFilter = key.indexOf('[') !== -1;
    const newKey = isExistFilter ? key.slice(0, key.indexOf('[')) : key;
    return filterIdList.includes(newKey) || newKey === 'keyword';
  });
};

/** Return true anyway if query has at least one filter. */
export const getHasFilter = (query: { [key: string]: unknown }): boolean => {
  if (isEmptyObject(query)) return false;
  return Object.keys(query).some(key => key === 'keyword' || key.includes('filter.'));
};

export default hasFilteringQuery;
