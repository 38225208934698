import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { GlobalPopup } from 'Utility-Types';
import { setGlobalPopupContent, closeGlobalPopup } from './actions';

export const globalPopup = createReducer({} as GlobalPopup)
  .handleAction(setGlobalPopupContent, (state, action) => action.payload)
  .handleAction(closeGlobalPopup, (state, action) => ({ isOpen: false }));

const utilityReducer = combineReducers({
  globalPopup,
});

export default utilityReducer;
export type UtilityState = ReturnType<typeof utilityReducer>;
