import { universalRouterProps } from 'core/universalRouter/types';
import { fetchReview } from 'store/reviews/actions';

type Location = {
  params: {
    reviewId: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  const { reviewId } = location.params;

  return Promise.all([dispatch(fetchReview(reviewId))]);
};
