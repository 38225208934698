export const initialProviderGroup = {
  groupId: '',
  name: '',
  active: true,
  types: [],
  objectives: [],
  contacts: [],
  groupAccounts: [
    {
      active: true,
      name: '',
      userId: '',
      password: '',
      email: '',
      language: 'en-US',
    },
  ],
  providers: [],
};
