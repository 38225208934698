import { universalRouterProps } from 'core/universalRouter/types';
import { MANAGEMENT, OBJECT_TYPE } from 'constants/feature';
import { fetchAllChannelDistributors } from 'store/channelDistributor/actions';
import { fetchCurrencies } from 'store/currencies/actions';
import { fetchUserSegments } from 'store/userSegment/actions';
import { initFilterState } from 'store/userSegment/initialValues';
import { fetchAllFeatureGroups } from 'store/featureSelection/actions';

import { fetchGoToPromotion, clearCurrentGoToPromotion } from 'store/goToPromotion/actions';

type Location = {
  params: {
    promotionId: string;
  };
};

export default ({ store: { dispatch, getState } }: universalRouterProps, location: Location) => {
  const { promotionId } = location.params;
  const featureQueryParams = {
    resourceId: promotionId,
    objectType: OBJECT_TYPE.SALES_PROMOTION,
    management: [MANAGEMENT.SALES_PROMOTION.CAMPAIGN.name],
  };

  const promises = [
    dispatch(fetchAllChannelDistributors()),
    dispatch(fetchCurrencies()),
    dispatch(fetchUserSegments(initFilterState)),
    dispatch(fetchAllFeatureGroups('', featureQueryParams)),
  ];

  if (promotionId) {
    promises.push(dispatch(fetchGoToPromotion(promotionId)));
  } else {
    dispatch(clearCurrentGoToPromotion());
  }

  return Promise.all(promises);
};
