import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ManualTranslationErrors } from 'ManualTranslation-Types';
import { getDataTypeAsync, downloadThermsAsync, uploadThermsAsync } from './actions';

export const isDownloading = createReducer(false)
  .handleAction(downloadThermsAsync.request, () => true)
  .handleAction([downloadThermsAsync.success, downloadThermsAsync.failure], () => false);

export const isFetching = createReducer(false as boolean)
  .handleAction([getDataTypeAsync.request, uploadThermsAsync.request], () => true)
  .handleAction(
    [
      getDataTypeAsync.success,
      getDataTypeAsync.failure,
      uploadThermsAsync.success,
      uploadThermsAsync.failure,
    ],
    () => false,
  );

export const dataTypes = createReducer([] as string[]).handleAction(
  getDataTypeAsync.success,
  (_state, action) => action.payload,
);

export const errors = createReducer([] as ManualTranslationErrors[]).handleAction(
  [uploadThermsAsync.failure, downloadThermsAsync.failure],
  (_state, action) => action.payload,
);

const manualTranslationReducer = combineReducers({
  isFetching,
  isDownloading,
  dataTypes,
  errors,
});

export default manualTranslationReducer;
export type ManualTranslationState = ReturnType<typeof manualTranslationReducer>;
