import { Translate } from '@travel/translation';
import React from 'react';

import styles from './footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer} data-locator-id="Footer-8d20d83b-8e09-4af3-9b64-8c48d238bc51">
      <Translate className={styles.content} id="Extranet_Header.Footer.Rakuten_Name" />
    </footer>
  );
}

export default Footer;
