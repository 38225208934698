import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import dayjs from 'dayjs';

import {
  InventoryControlErrors,
  InventoryControlItems,
  FilterType,
  InventoryBulkUpdateParams,
} from 'InventoryControl-Types';
import {
  fetchInventoryControlAsync,
  setCalendarDataStandard,
  updateSingleInventoryStandard,
  setFilterRoomTypes,
  setFilterParams,
  saveInventoryControlAsync,
  bulkUpdateInventoryStandard,
  setBulkUpdateParams,
} from './actions';
import { isNotNullish } from '@travel/utils';

export const calendarData = createReducer([] as InventoryControlItems[])
  .handleAction(setCalendarDataStandard, (state, action) => action.payload)
  /**
   * Handle Only Single Inventory Change from individual Input box
   */
  .handleAction(updateSingleInventoryStandard, (state, action) => {
    const { itemId, value, index } = action.payload;
    const inventoryIndex = state.findIndex(inventory => inventory.itemId === itemId);
    // to only update when value is defined (= true/false)
    value.available !== undefined &&
      (state[inventoryIndex].inventories[index].available = value.available);
    value.active !== undefined && (state[inventoryIndex].inventories[index].active = value.active);

    return [...state];
  })
  /**
   * Handle Bulk Update
   */
  .handleAction(bulkUpdateInventoryStandard, (state, action) => {
    const {
      fromDate,
      toDate,
      includeSunday,
      includeMonday,
      includeTuesday,
      includeWednesday,
      includeThursday,
      includeFriday,
      includeSaturday,
      items,
      active,
      inventory,
    } = action.payload;

    const daysMap = [
      includeSunday,
      includeMonday,
      includeTuesday,
      includeWednesday,
      includeThursday,
      includeFriday,
      includeSaturday,
    ].reduce((a, v, i) => {
      if (v) a.push(i);
      return a;
    }, []);

    state.forEach(each => {
      if (items.includes(each.itemId)) {
        each.inventories.forEach(data => {
          const date = dayjs(data.date);
          if (
            data.valid &&
            !date.isBefore(fromDate) &&
            !date.isAfter(toDate) &&
            daysMap.includes(date.day())
          ) {
            if (isNotNullish(active)) {
              data.active = active;
            } else if (isNotNullish(inventory)) {
              data.available = inventory;
            }
          }
        });
      }
    });
    return [...state];
  });

type Option = {
  label: string;
  value: string;
  isCheck: boolean;
};

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchInventoryControlAsync.request], (state, action) => true)
  .handleAction(
    [fetchInventoryControlAsync.success, fetchInventoryControlAsync.failure],
    (state, action) => false,
  );

export const inventories = createReducer([] as InventoryControlItems[]).handleAction(
  fetchInventoryControlAsync.success,
  (state, action) => action.payload,
);

export const allRoomTypes = createReducer({} as Array<Option>).handleAction(
  setFilterRoomTypes,
  (state, action) => action.payload,
);

export const bulkUpdateParams = createReducer({} as InventoryBulkUpdateParams).handleAction(
  setBulkUpdateParams,
  (state, action) => action.payload,
);

export const filterParams = createReducer({} as FilterType).handleAction(
  setFilterParams,
  (state, action) => action.payload,
);

export const errors = createReducer([] as InventoryControlErrors[]).handleAction(
  fetchInventoryControlAsync.failure,
  (state, action) => action.payload,
);

export const isSaving = createReducer(false as boolean)
  .handleAction([saveInventoryControlAsync.request], (state, action) => true)
  .handleAction(
    [saveInventoryControlAsync.success, saveInventoryControlAsync.failure],
    (state, action) => false,
  );

export const saveErrors = createReducer([] as InventoryControlErrors[]).handleAction(
  saveInventoryControlAsync.failure,
  (state, action) => action.payload,
);

const inventoryControlReducer = combineReducers({
  isFetching,
  inventories,
  errors,
  calendarData,
  allRoomTypes,
  bulkUpdateParams,
  filterParams,
  isSaving,
  saveErrors,
});

export default inventoryControlReducer;
export type InventoryControlState = ReturnType<typeof inventoryControlReducer>;
