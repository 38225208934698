import { universalRouterProps } from 'core/universalRouter/types';
import { fetchChannelDistributor } from 'store/channelDistributor/actions';
import { fetchFeaturesForSelection } from 'store/featureSelection/actions';
import { OBJECT_TYPE, MANAGEMENT } from 'constants/feature';

type Location = {
  params: {
    distributorId: string;
    type: string;
  };
};

export default (
  { store: { dispatch } }: universalRouterProps,
  { params: { distributorId } }: Location,
) => {
  const promise = [];
  const featureFilter = {
    objectType: OBJECT_TYPE.PROVIDER,
    management: [MANAGEMENT.PROVIDER.CATEGORY.name],
  };

  promise.push(dispatch(fetchFeaturesForSelection('', featureFilter)));

  if (distributorId) {
    dispatch(fetchChannelDistributor(distributorId));
  }

  return Promise.all(promise);
};
