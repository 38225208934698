import { ReactNode } from 'react';

const getSortQuery = (
  activeFilters: ReactNode[],
  query: { [key: string]: string | string[] },
  filterIdList: string[],
) => {
  const searchQueryParams = Object.keys(query)
    .reduce((newFiler: ReactNode[], key) => {
      let newKey: string = key;
      if (key.indexOf('[') !== -1) {
        newKey = key.slice(0, key.indexOf('['));
        if (/^filter\./.test(newKey)) {
          newKey = newKey.slice('filter.'.length);
        }
      }
      if (filterIdList.includes(newKey)) {
        newFiler.push(`${key}=${query[key]}`);
      }
      return newFiler;
    }, activeFilters)
    .filter(queryParam => queryParam !== '')
    .join('&');
  return searchQueryParams;
};

export default getSortQuery;
