import { universalRouterProps } from 'core/universalRouter/types';
import { fetchAccounts } from 'store/accessControl/actions';
import { queryToObject } from '@travel/utils';
import { getStatusFilter } from './helper';

type Location = {
  query: { [key: string]: string };
};
const defaultQuery = {
  offset: 0,
  limit: 30,
};

export default ({ store: { dispatch } }: universalRouterProps, { query }: Location) => {
  const objectQuery = queryToObject(query);

  const encodeQuery = {
    limit: query.limit,
    offset: query.offset,
    keyword: encodeURIComponent(query.keyword || ''),
    sort: objectQuery?.sort,
    searchCondition: query.searchCondition,
    filter: {
      status: getStatusFilter(objectQuery?.filter?.status),
      alias: objectQuery?.filter?.alias,
    },
  };

  return Promise.all([dispatch(fetchAccounts(encodeQuery || defaultQuery))]);
};
