import { FilterGroup, Sort } from 'RatePlan-Types';
import { isNotEmptyArray } from '@travel/utils';

const getFilterQuery = (
  filterList: Array<FilterGroup>,
  query: { [key: string]: string | string[] },
  sort: Sort,
  filterPrefix?: boolean,
) => {
  const activeFilters = [
    query.keyword ? `keyword=${query.keyword}` : '',
    query.searchCondition ? `searchCondition=${query.searchCondition}` : '',
    sort.selectedValue ? sort.selectedValue.toString() : '',
  ];

  const getPrefix = (id?: string) => (filterPrefix ? `filter.${id}` : id);

  const searchQueryParams = (filterList || [])
    .reduce((acc: string[], filter) => {
      const checkedFilter = filter.options.filter(({ isCheck }) => isCheck);
      if (isNotEmptyArray(checkedFilter) && checkedFilter.length <= filter.options.length) {
        checkedFilter.map((item, index) =>
          acc.push(`${getPrefix(filter.id)}[${index}]=${item.value}`),
        );
      } else if (checkedFilter.length === 0) {
        acc.push(`${getPrefix(filter.id)}=`);
      }
      return acc;
    }, activeFilters)
    .filter(queryParam => queryParam !== '')
    .join('&');
  return searchQueryParams;
};

export default getFilterQuery;
