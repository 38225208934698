import { BADGE_COLOR } from '@travel/ui/components/Tag';
import { getTranslation } from '@travel/translation';

export const RAKUTEN_STATUSES = {
  SANDBOX: 'SANDBOX',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CLOSED: 'CLOSED',
};

export const RAKUTEN_STAUS_COLORS: { [key: string]: BADGE_COLOR } = {
  SANDBOX: 'silver',
  ACTIVE: 'yellow',
  INACTIVE: 'gray',
  CLOSED: 'red',
};

export const PROVIDER_DESIGNATION = {
  STANDARD: 'STANDARD',
  LUXURY: 'LUXURY',
  JRDP: 'JRDP',
};

export const CONDITION_VALUE_TYPE = {
  PERCENT: 'PERCENT',
  EXACT_AMOUNT: 'EXACT_AMOUNT',
};

// NOTE: Can add other certificate type here once needed
export const PROVIDER_CERTIFICATE_TYPE = {
  JAPAN_STANDARD: 'JAPAN_STANDARD',
};

export const roundPositionOptions = [
  {
    text: '4',
    value: '4',
  },
  {
    text: '3',
    value: '3',
  },
  {
    text: '2',
    value: '2',
  },
  {
    text: '1',
    value: '1',
  },
  {
    text: '0',
    value: '0',
  },
  {
    text: '-1',
    value: '-1',
  },
  {
    text: '-2',
    value: '-2',
  },
];

export const REVIEW_DISPLAY_RANGE_3_MONTH = 'SHOW_PAST_THREE_MONTHS';

export const getInternalReviewLimitType = () => [
  {
    text: getTranslation({ id: 'Supplier_Enumeration.Provider.Guest_Review_Range.null' }).join(''),
    value: 'SHOW_ALL',
  },
  {
    text: getTranslation({ id: 'Supplier_Enumeration.Provider.Guest_Review_Range.365_days' }).join(
      '',
    ),
    value: 'SHOW_PAST_ONE_YEAR',
  },
  {
    text: getTranslation({ id: 'Supplier_Enumeration.Provider.Guest_Review_Range.90_days' }).join(
      '',
    ),
    value: 'SHOW_PAST_THREE_MONTHS',
  },
];

export const getExtranetReviewLimitType = () => [
  {
    text: getTranslation({ id: 'Supplier_Enumeration.Provider.Guest_Review_Range.null' }).join(''),
    value: 'SHOW_ALL',
    isDisabled: false,
  },
  {
    text: getTranslation({ id: 'Supplier_Enumeration.Provider.Guest_Review_Range.365_days' }).join(
      '',
    ),
    value: 'SHOW_PAST_ONE_YEAR',
    isDisabled: false,
  },
];

export const STAR_RATING_OPTIONS = [
  {
    text: '-',
    value: '',
  },
  {
    text: '5.0',
    value: '5',
  },
  {
    text: '4.5',
    value: '4.5',
  },
  {
    text: '4.0',
    value: '4',
  },
  {
    text: '3.5',
    value: '3.5',
  },
  {
    text: '3.0',
    value: '3',
  },
  {
    text: '2.5',
    value: '2.5',
  },
  {
    text: '2.0',
    value: '2',
  },
  {
    text: '1.5',
    value: '1.5',
  },
  {
    text: '1.0',
    value: '1',
  },
  {
    text: '0.5',
    value: '0.5',
  },
];

export const TERMINAL_CATEGORIES = ['AIRPORT', 'BUS_STOP', 'SEAPORT', 'TRAIN_STATION'];
