import { universalRouterProps } from 'core/universalRouter/types';
import {
  fetchVisualContentGroup,
  fetchVisualContentGroupList,
  setInitialVisualContentGroup,
} from 'store/visualContentGroup/actions';
import { fetchProviderInformation } from 'store/providersInformation/actions';
import { getIsCreateAccess } from 'utils/userScopeHelper';

type Location = {
  params: {
    providerId?: string;
    visualContentGroupId?: string;
  };
};

export default ({ store }: universalRouterProps, location: Location) => {
  let promises: Array<Promise<void>> = [];
  const { providerId = '', visualContentGroupId } = location.params;
  const { dispatch } = store;
  const { permissions } = store.getState().accessControl?.loggedInUser || { permissions: [] };
  const hasMediaAccess = getIsCreateAccess(permissions, 'MediaMgmt');

  if (visualContentGroupId) {
    promises = [dispatch(fetchVisualContentGroup(visualContentGroupId, providerId || ''))];
    if (hasMediaAccess) {
      promises.push(dispatch(fetchVisualContentGroupList(providerId, {})));
    }
  } else {
    if (providerId) {
      dispatch(setInitialVisualContentGroup());
      promises = [dispatch(fetchProviderInformation(providerId))];
    } else {
      dispatch(setInitialVisualContentGroup());
    }
  }

  return Promise.all(promises);
};
