import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { getPlatformFlag as getPlatformFlagUtil } from 'utils';
import { AccessControlState } from './reducer';

export const getAccessControl = (state: RootState) => state.accessControl;

export const getAccountsList = createSelector(
  getAccessControl,
  (state: AccessControlState) => state?.accountList,
);

export const getAccountDetail = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.account,
);

export const getAccounts = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.accountList?.accounts,
);

export const getIsFirstAccount = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.account?.firstAccount,
);

export const getLoggedInExpiryDate = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.loggedInUser?.passwordExpiredDateTime,
);

export const getLoggedInUserName = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.loggedInUser?.name,
);

export const getLoggedInUserPermissions = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.loggedInUser?.permissions,
);

export const getLoggedInUserScope = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.loggedInUser?.scope,
);

export const getLoggedInUser = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.loggedInUser,
);

export const getErrors = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.errors,
);

export const getIsLoggedInUser = (state: RootState) => {
  const { account, loggedInUser } = state.accessControl;
  if (account && loggedInUser) {
    return account.userUuid === loggedInUser.userUuid;
  }
  return false;
};

export const getIsCreating = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.isCreating,
);

export const getIsDeleting = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.isDeleting,
);

export const getIsFetching = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.isFetching,
);

export const getPlatformFlag = createSelector(getAccessControl, (state: AccessControlState) =>
  getPlatformFlagUtil(state.loggedInUser?.scope),
);

export const getGroupAccount = createSelector(
  getAccessControl,
  (state: AccessControlState) => state.groupAccount,
);
