import { createSelector } from 'reselect';
import { LanguagesState } from './reducer';
import { RootState } from 'typesafe-actions';

export const getErrors = (state: RootState) => state.languages.errors;

export const getLanguages = (state: RootState) => state.languages;

export const getSelectedLanguage = (state: RootState) => state._i18n.language;

export const getIsFetching = createSelector(
  getLanguages,
  (state: LanguagesState) => state.isFetching,
);

export const getLanguageList = createSelector(getLanguages, (state: LanguagesState) => state.items);

export const getInputLanguage = createSelector(
  getLanguages,
  (state: LanguagesState) => state?.inputLanguage,
);

export const getIsDataInputLanguageAllow = createSelector(
  getLanguages,
  (state: LanguagesState) => state.isDataInputLanguageAllow,
);
