import React from 'react';
import cx from '../../utils/classnames';
import styles from './toolBar.module.scss';

/** XXX: className can be removed from props if you don't set any className on root element,
 * after you remove it, it might be passed through ...rest
 */
type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** ToolBar Items */
  children: React.ReactNode;

  /** isDisplayBlock: to make sure dispaly:block.
   * sometimes display:block is overwritten by other page's display:flex cache. */
  isDisplayBlock?: boolean;
};

function ToolBar(props: Props) {
  const { className, isDisplayBlock } = props;

  return (
    <div
      className={cx(className, styles.toolBar, isDisplayBlock && styles.block)}
      data-testid="toolBar-div-wrapper"
    >
      {props.children}
    </div>
  );
}

export default ToolBar;
