import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  FeatureGroupList,
  FeatureGroupStatus,
  FeatureGroupSearch,
  FeatureGroupObjectTypes,
} from 'FeatureGroup-Types';
import { FeatureGroup } from 'Feature-Types';
import {
  fetchFeatureGroupListAsync,
  fetchFeatureGroupAsync,
  fetchFeatureGroupSearchAsync,
  fetchFeatureGroupObjectTypesAsync,
  fetchRepFeatureGroupAsync,
  postFeatureGroupAsync,
  putFeatureGroupAsync,
  setInitialFeatureGroup,
} from './actions';

import { initialFeatureGroup, initialFeatureGroupSearch } from './initialValues';

export const isCreating = createReducer(false)
  .handleAction([postFeatureGroupAsync.request, putFeatureGroupAsync.request], () => true)
  .handleAction(
    [
      postFeatureGroupAsync.success,
      postFeatureGroupAsync.failure,
      putFeatureGroupAsync.success,
      putFeatureGroupAsync.failure,
    ],
    () => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchFeatureGroupListAsync.request,
      fetchFeatureGroupAsync.request,
      fetchFeatureGroupObjectTypesAsync.request,
      fetchFeatureGroupSearchAsync.request,
      fetchRepFeatureGroupAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchFeatureGroupListAsync.success,
      fetchFeatureGroupListAsync.failure,
      fetchFeatureGroupAsync.success,
      fetchFeatureGroupAsync.failure,
      fetchFeatureGroupSearchAsync.success,
      fetchFeatureGroupSearchAsync.failure,
      fetchFeatureGroupObjectTypesAsync.success,
      fetchFeatureGroupObjectTypesAsync.failure,
      fetchRepFeatureGroupAsync.success,
      fetchRepFeatureGroupAsync.failure,
    ],
    () => false,
  );

export const featureGroupList = createReducer({} as FeatureGroupList).handleAction(
  fetchFeatureGroupListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const featureGroupListError = createReducer([] as FeatureGroupStatus[]).handleAction(
  fetchFeatureGroupListAsync.failure,
  (state, action) => action.payload,
);

export const featureGroup = createReducer(initialFeatureGroup as FeatureGroup)
  .handleAction(fetchFeatureGroupAsync.success, (state, action) => action.payload)
  .handleAction(setInitialFeatureGroup, () => initialFeatureGroup);

export const featureGroupError = createReducer([] as FeatureGroupStatus[]).handleAction(
  fetchFeatureGroupListAsync.failure,
  (state, action) => action.payload,
);

export const featureGroupSearch = createReducer(
  initialFeatureGroupSearch as FeatureGroupSearch,
).handleAction(fetchFeatureGroupSearchAsync.success, (state, action) => action.payload);

export const featureGroupSearchError = createReducer([] as FeatureGroupStatus[]).handleAction(
  fetchFeatureGroupListAsync.failure,
  (state, action) => action.payload,
);

export const postFeatureGroup = createReducer({} as FeatureGroup).handleAction(
  postFeatureGroupAsync.success,
  (state, action) => action.payload,
);

export const postFeatureGroupError = createReducer([] as FeatureGroupStatus[]).handleAction(
  postFeatureGroupAsync.failure,
  (state, action) => action.payload,
);

export const putFeatureGroup = createReducer({} as FeatureGroup).handleAction(
  putFeatureGroupAsync.success,
  (state, action) => action.payload,
);

export const putFeatureGroupError = createReducer([] as FeatureGroupStatus[]).handleAction(
  putFeatureGroupAsync.failure,
  (state, action) => action.payload,
);

export const featureGroupObjectTypes = createReducer([] as FeatureGroupObjectTypes).handleAction(
  fetchFeatureGroupObjectTypesAsync.success,
  (state, action) => {
    return action.payload;
  },
);

export const featureGroupObjectTypesError = createReducer([] as FeatureGroupStatus[]).handleAction(
  fetchFeatureGroupObjectTypesAsync.failure,
  (state, action) => action.payload,
);

export const repFeatureGroup = createReducer({} as FeatureGroup).handleAction(
  fetchRepFeatureGroupAsync.success,
  (state, action) => action.payload,
);

export const repFeatureGroupError = createReducer([] as FeatureGroupStatus[]).handleAction(
  fetchRepFeatureGroupAsync.failure,
  (state, action) => action.payload,
);

const featureGroupReducer = combineReducers({
  isFetching,
  featureGroupList,
  featureGroupListError,
  featureGroup,
  featureGroupError,
  featureGroupSearch,
  featureGroupSearchError,
  postFeatureGroup,
  postFeatureGroupError,
  putFeatureGroup,
  putFeatureGroupError,
  featureGroupObjectTypes,
  featureGroupObjectTypesError,
  repFeatureGroup,
  repFeatureGroupError,
  isCreating,
});

export default featureGroupReducer;
export type FeatureGroupState = ReturnType<typeof featureGroupReducer>;
