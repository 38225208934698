import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';

import {
  ApiStatus,
  ProviderCampaignErrors,
  ProviderCampaignList,
  ProviderCampaign,
} from 'ProviderCampaign-Types';

import {
  ApiFetchPromotionCampaignList,
  ApiFetchPromotionCampaign,
  ApiPutPromotionCampaignSave,
  ApiPutPromotionCampaignSubmit,
} from './api';

export const clearCurrentProviderCampaignList = createStandardAction(
  'CLEAR_PROVIDER_CAMPAIGN_LIST',
)();

export const fetchProviderCampaignListAsync = createAsyncAction(
  'FETCH_PROVIDER_CAMPAIGN_LIST_REQUEST',
  'FETCH_PROVIDER_CAMPAIGN_LIST_SUCCESS',
  'FETCH_PROVIDER_CAMPAIGN_LIST_FAILURE',
)<undefined, ProviderCampaignList, ProviderCampaignErrors[]>();

export const fetchProviderCampaignAsync = createAsyncAction(
  'FETCH_PROVIDER_CAMPAIGN_REQUEST',
  'FETCH_PROVIDER_CAMPAIGN_SUCCESS',
  'FETCH_PROVIDER_CAMPAIGN_FAILURE',
)<undefined, ProviderCampaign, ProviderCampaignErrors[]>();

export const putProviderCampaignSaveAsync = createAsyncAction(
  'PUT_PROVIDER_CAMPAIGN_SAVE_REQUEST',
  'PUT_PROVIDER_CAMPAIGN_SAVE_SUCCESS',
  'PUT_PROVIDER_CAMPAIGN_SAVE_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const putProviderCampaignSubmitAsync = createAsyncAction(
  'PUT_PROVIDER_CAMPAIGN_SUBMIT_REQUEST',
  'PUT_PROVIDER_CAMPAIGN_SUBMIT_SUCCESS',
  'PUT_PROVIDER_CAMPAIGN_SUBMIT_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const fetchProviderCampaignList = (
  providerId: string,
  params: { [key: string]: string | number },
  offset?: number,
  rejectIfFetching?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isFetching = getState().providerCampaign.isFetching;
  if (isFetching && rejectIfFetching) return;

  dispatch(fetchProviderCampaignListAsync.request());
  try {
    const fetchQuery = {
      ...params,
      offset: offset || 0,
      limit: 30,
    };

    const res = await ApiFetchPromotionCampaignList(apiClient, providerId, fetchQuery);
    dispatch(fetchProviderCampaignListAsync.success(res));
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const fetchProviderCampaign = (providerId: string, campaignId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchProviderCampaignAsync.request());
    const res = await ApiFetchPromotionCampaign(apiClient, providerId, campaignId);
    dispatch(fetchProviderCampaignAsync.success(res));
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

// Save the Campaign Action
export const putProviderCampaignSave = (
  providerId: string,
  campaignId: string,
  data: ProviderCampaign,
  callbackWhenSuccess?: () => void,
): AppThunk<Promise<ApiStatus | undefined>> => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(putProviderCampaignSaveAsync.request());
    const res = await ApiPutPromotionCampaignSave(apiClient, providerId, campaignId, data);
    dispatch(putProviderCampaignSaveAsync.success(res));

    if (res?.status === 200) {
      callbackWhenSuccess?.();
    }

    return res;
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

// Submit the Campaign Action
export const putProviderCampaignSubmit = (
  providerId: string,
  campaignId: string,
  data: ProviderCampaign,
  search?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(putProviderCampaignSubmitAsync.request());
    const res = await ApiPutPromotionCampaignSubmit(apiClient, providerId, campaignId, data);
    dispatch(putProviderCampaignSubmitAsync.success(res));

    if (res.status === 200) {
      dispatch(
        fetchGlobalErrorDialogAsync.success({
          status: 200,
          message: '',
          isProviderCampaign: true,
        }),
      );

      dispatch(
        pushLocation({
          pathname: `/provider/${providerId}/campaigns/${campaignId}`,
          state: {
            prevSearch: search,
          },
        }),
      );
    }
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};
