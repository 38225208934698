import { ApiClient } from '@travel/api-client';
import { providerPrefix } from 'constants/api-prefix';
import { ExtraRateDetail, ExtraRateList, ExtraRateStatus } from 'ExtraRate-Types';

export const ApiFetchExtraRateList = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<ExtraRateList> => {
  return http.get(`${providerPrefix}/${providerId}/extraRateTemplates`, { values: params });
};

export const ApiFetchExtraRateDetail = (
  http: ApiClient,
  providerId: string,
  extraRateId: string,
): Promise<ExtraRateDetail> =>
  http.get(`${providerPrefix}/${providerId}/extraRateTemplates/${extraRateId}`, {});

export const ApiCreateExtraRateDetail = (
  http: ApiClient,
  providerId: string,
  data: ExtraRateDetail,
): Promise<ExtraRateStatus> =>
  http.post(`${providerPrefix}/${providerId}/extraRateTemplates`, {
    values: data,
  });

export const ApiUpdateExtraRateDetail = (
  http: ApiClient,
  providerId: string,
  data: ExtraRateDetail,
): Promise<ExtraRateStatus> =>
  http.put(`${providerPrefix}/${providerId}/extraRateTemplates/${data.id}`, {
    values: data,
  });

export const ApiDeleteExtraRateDetail = (
  http: ApiClient,
  providerId: string,
  extraRateId: string,
): Promise<ExtraRateStatus> =>
  http
    .delete(`${providerPrefix}/${providerId}/extraRateTemplates/${extraRateId}`, {})
    .then((data: ExtraRateStatus) => data)
    .catch((data: ExtraRateStatus) => data);
