import React from 'react';
import { BrowseParameters } from 'Media-Types';
import { Translate } from 'core/translate';

export const initialBrowseParameter: BrowseParameters = {
  keyword: '',
  searchCondition: '',
  filter: [
    {
      label: <Translate id="Media.List.Filter.Media_Type" />,
      id: 'type',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Media.Type.Image" />,
          value: 'image',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Media.Type.Video" />,
          value: 'video',
          isCheck: false,
        },
      ],
    },
  ],
  sort: {
    options: [
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.New_Old" />,
        value: 'updateDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.Old_New" />,
        value: 'updateDateTime=asc',
      },
    ],
    selectedValue: 'updateDateTime=desc',
  },
  offset: 0,
  limit: 30,
};

export const initialUploadedMedia = {
  id: '',
  fileName: '',
  mime: '',
  type: '',
  size: 0,
  width: 0,
  height: 0,
  url: '',
  thumbnailUrl: '',
  active: true,
  status: '',
};

export const initialMediaDetail = {
  id: '',
  type: '',
  url: '',
  thumbnailUrl: '',
  active: true,
  name: '',
  description: '',
  isMain: false,
  featureGroups: [],
  mediumGroups: [],
  associatedObjectTypes: [],
  createDateTime: '',
  updateDateTime: '',
  status: '',
};

export const initialMediaDeletable = {
  message: '',
  statusCode: null,
};

export const initialDeletableDialog = {
  isShow: false,
};
