import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  InventoryControlItems,
  InventoryControlErrors,
  FilterType,
  SaveInventoryControl,
  ApiStatus,
  RoomTypes,
  SingleInventoryUpdate,
  InventoryBulkUpdateParams,
} from 'InventoryControl-Types';
import { fetch, saveInventoryControlCalendar, bulkUpdateInventoryApi } from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import isNotEmptyArray from '@travel/ui/utils/arrays';

const getRoomTypes = (items: Array<InventoryControlItems>) => {
  let rooms;
  if (isNotEmptyArray(items)) {
    rooms = items.map(room => {
      return { value: room.itemId, label: room.itemCode, isCheck: false };
    });
  }
  return rooms;
};

export const getInitialBulkUpdateParams = (items: Array<InventoryControlItems>) => {
  return {
    fromDate: '',
    toDate: '',
    includeSunday: true,
    includeMonday: true,
    includeTuesday: true,
    includeWednesday: true,
    includeThursday: true,
    includeFriday: true,
    includeSaturday: true,
    items: items.map(item => item.itemId),
  };
};

export const setCalendarDataStandard = createStandardAction('SET_SELECTED_INVENTORIES')<
  InventoryControlItems[]
>();

export const updateSingleInventoryStandard = createStandardAction('UPDATE_SINGLE_INVENTORY')<
  SingleInventoryUpdate
>();

export const bulkUpdateInventoryStandard = createStandardAction('BULK_UPDATE_INVENTORY')<
  InventoryBulkUpdateParams
>();

export const setCalendarData = (selectedInventories: InventoryControlItems[]): AppThunk<void> => (
  dispatch,
  getState,
) => {
  dispatch(setCalendarDataStandard(selectedInventories));
};

export const updateSingleInventoryChange = (data: SingleInventoryUpdate): AppThunk<void> => (
  dispatch,
  getState,
) => {
  dispatch(updateSingleInventoryStandard(data));
};

export const updateBulkInventoryChange = (data: InventoryBulkUpdateParams): AppThunk<void> => (
  dispatch,
  getState,
) => {
  dispatch(bulkUpdateInventoryStandard(data));
};

export const setFilterRoomTypes = createStandardAction('SET_FILTER_ROOM_TYPES')<RoomTypes>();

export const setBulkUpdateParams = createStandardAction('SET_BULK_UPDATE_PARAMS')<
  InventoryBulkUpdateParams
>();

export const setFilterParams = createStandardAction('SET_FILTER_PARAMS')<FilterType>();

export const fetchInventoryControlAsync = createAsyncAction(
  'FETCH_INVENTORY_CONTROL_REQUEST',
  'FETCH_INVENTORY_CONTROL_SUCCESS',
  'FETCH_INVENTORY_CONTROL_FAILURE',
)<undefined, InventoryControlItems[], InventoryControlErrors[]>();

export const fetchInventoryControl = (
  providerID: string,
  filterParams: FilterType,
  isInitialLoad?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchInventoryControlAsync.request());
  const inventories = await fetch(apiClient, providerID, filterParams);
  dispatch(fetchInventoryControlAsync.success(inventories));
  dispatch(setFilterParams(filterParams));
  if (isInitialLoad) {
    const roomTypes = getRoomTypes(inventories);
    dispatch(setFilterRoomTypes(roomTypes));
  }
};

export const saveInventoryControlAsync = createAsyncAction(
  'SAVE_INVENTORY_CONTROL_REQUEST',
  'SAVE_INVENTORY_CONTROL_SUCCESS',
  'SAVE_INVENTORY_CONTROL_FAILURE',
)<undefined, ApiStatus, InventoryControlErrors[]>();

export const saveInventoryControl = (
  providerId: string,
  calendarData: SaveInventoryControl,
): AppThunk<Promise<ApiStatus>> => async (dispatch, getState, { apiClient }) => {
  const { filterParams } = getState().inventoryControl;

  dispatch(saveInventoryControlAsync.request());
  const response = await saveInventoryControlCalendar(apiClient, providerId, calendarData);

  dispatch(fetchInventoryControl(providerId, filterParams));
  dispatch(saveInventoryControlAsync.success(response));
  dispatch(fetchGlobalErrorDialogAsync.success(response));
  return response;
};

export const bulkUpdateInventoryControlAsync = createAsyncAction(
  'BULK_UPDATE_INVENTORY_CONTROL_REQUEST',
  'BULK_UPDATE_INVENTORY_CONTROL_SUCCESS',
  'BULK_UPDATE_INVENTORY_CONTROL_FAILURE',
)<undefined, ApiStatus, InventoryControlErrors[]>();

export const bulkUpdateInventory = (
  providerId: string,
  data: InventoryBulkUpdateParams,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { filterParams } = getState().inventoryControl;

  try {
    dispatch(bulkUpdateInventoryControlAsync.request());
    const response = await bulkUpdateInventoryApi(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(response));
    dispatch(bulkUpdateInventoryControlAsync.success(response));
    dispatch(fetchInventoryControl(providerId, filterParams));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
    dispatch(bulkUpdateInventoryControlAsync.failure(err));
  }
};
