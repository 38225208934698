import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  HighlightContent,
  HighlightContentStatus,
  HighlightContentArticle,
} from 'HighlightContent-Types';

import {
  fetchHighlightContentAsync,
  fetchHighlightContentArticleAsync,
  setInitialHighlightContentArticle,
  putHighlightArticleAsync,
  postHighlightArticleAsync,
} from './actions';
import { initialContentArticle } from './initialValues';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchHighlightContentAsync.request], (state, action) => true)
  .handleAction(
    [fetchHighlightContentAsync.success, fetchHighlightContentAsync.failure],
    (state, action) => false,
  );

export const highlightContent = createReducer({} as HighlightContent).handleAction(
  fetchHighlightContentAsync.success,
  (state, action) => action.payload,
);

export const highlightContentError = createReducer([] as HighlightContentStatus[]).handleAction(
  fetchHighlightContentAsync.failure,
  (state, action) => action.payload,
);

export const highlightContentArticle = createReducer(
  initialContentArticle as HighlightContentArticle,
)
  .handleAction(fetchHighlightContentArticleAsync.success, (state, action) => action.payload)
  .handleAction(setInitialHighlightContentArticle, () => initialContentArticle);

export const highlightContentArticleError = createReducer(
  [] as HighlightContentStatus[],
).handleAction(fetchHighlightContentArticleAsync.failure, (state, action) => action.payload);

export const isCreating = createReducer(false)
  .handleAction([postHighlightArticleAsync.request, putHighlightArticleAsync.request], () => true)
  .handleAction(
    [
      postHighlightArticleAsync.success,
      postHighlightArticleAsync.failure,
      putHighlightArticleAsync.success,
      putHighlightArticleAsync.failure,
    ],
    () => false,
  );

const highlightContentReducer = combineReducers({
  isCreating,
  isFetching,
  highlightContent,
  highlightContentError,
  highlightContentArticle,
  highlightContentArticleError,
});

export default highlightContentReducer;
export type HighlightContentState = ReturnType<typeof highlightContentReducer>;
