import { createAsyncAction, AppThunk } from 'typesafe-actions';

import { ApplicationRatePlanDetail, Error, ApiStatus } from 'ApplicationDetail-Types';
import {
  ApiFetchApplicationRatePlanDetail,
  ApiAcceptApplicationRatePlanDetail,
  ApiSuggestApplicationRatePlanDetail,
  ApiRejectApplicationRatePlanDetail,
} from './ratePlanApis';
import { fetchApplicationDetail } from './actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

export const fetchApplicationRatePlanDetailAsync = createAsyncAction(
  'FETCH_APPLICATION_RATE_PLAN_DETAIL_REQUEST',
  'FETCH_APPLICATION_RATE_PLAN_DETAIL_SUCCESS',
  'FETCH_APPLICATION_RATE_PLAN_DETAIL_FAILURE',
)<undefined, ApplicationRatePlanDetail, Error[]>();

export const acceptApplicationRatePlanDetailAsync = createAsyncAction(
  'ACCEPT_APPLICATION_RATE_PLAN_DETAIL_REQUEST',
  'ACCEPT_APPLICATION_RATE_PLAN_DETAIL_SUCCESS',
  'ACCEPT_APPLICATION_RATE_PLAN_DETAIL_FAILURE',
)<undefined, ApiStatus, Error[]>();

export const suggestApplicationRatePlanDetailAsync = createAsyncAction(
  'SUGGEST_APPLICATION_RATE_PLAN_DETAIL_REQUEST',
  'SUGGEST_APPLICATION_RATE_PLAN_DETAIL_SUCCESS',
  'SUGGEST_APPLICATION_RATE_PLAN_DETAIL_FAILURE',
)<undefined, ApiStatus, Error[]>();

export const rejectApplicationRatePlanDetailAsync = createAsyncAction(
  'REJECT_APPLICATION_RATE_PLAN_DETAIL_REQUEST',
  'REJECT_APPLICATION_RATE_PLAN_DETAIL_SUCCESS',
  'REJECT_APPLICATION_RATE_PLAN_DETAIL_FAILURE',
)<undefined, ApiStatus, Error[]>();

export const fetchApplicationRatePlanDetail = (
  campaignId: string,
  applicationId: string,
  ratePlanId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchApplicationRatePlanDetailAsync.request());

  try {
    const response = await ApiFetchApplicationRatePlanDetail(
      apiClient,
      campaignId,
      applicationId,
      ratePlanId,
    );
    dispatch(fetchApplicationRatePlanDetailAsync.success(response));
  } catch (error) {
    dispatch(fetchApplicationRatePlanDetailAsync.failure([error]));
  }
};

export const acceptApplicationRatePlanDetail = (
  campaignId: string,
  applicationId: string,
  ratePlanId: string,
): AppThunk<Promise<ApiStatus | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(acceptApplicationRatePlanDetailAsync.request());
  try {
    const res = await ApiAcceptApplicationRatePlanDetail(
      apiClient,
      campaignId,
      applicationId,
      ratePlanId,
    );
    dispatch(fetchApplicationDetail(campaignId, applicationId));
    dispatch(acceptApplicationRatePlanDetailAsync.success(res));
    return res;
  } catch (error) {
    dispatch(acceptApplicationRatePlanDetailAsync.failure([error]));
  }
};

export const suggestApplicationRatePlanDetail = (
  campaignId: string,
  applicationId: string,
  ratePlanId: string,
  suggestion: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(suggestApplicationRatePlanDetailAsync.request());
  try {
    const res = await ApiSuggestApplicationRatePlanDetail(
      apiClient,
      campaignId,
      applicationId,
      ratePlanId,
      suggestion,
    );
    dispatch(fetchApplicationRatePlanDetail(campaignId, applicationId, ratePlanId));
    dispatch(suggestApplicationRatePlanDetailAsync.success(res));
  } catch (error) {
    dispatch(suggestApplicationRatePlanDetailAsync.failure([error]));
  }
};

export const rejectApplicationRatePlanDetail = (
  campaignId: string,
  applicationId: string,
  ratePlanId: string,
  rejection: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(rejectApplicationRatePlanDetailAsync.request());
  try {
    const res = await ApiRejectApplicationRatePlanDetail(
      apiClient,
      campaignId,
      applicationId,
      ratePlanId,
      rejection,
    );

    dispatch(fetchApplicationDetail(campaignId, applicationId));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(rejectApplicationRatePlanDetailAsync.success(res));
  } catch (error) {
    dispatch(rejectApplicationRatePlanDetailAsync.failure([error]));
  }
};
