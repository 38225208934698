import { universalRouterProps } from 'core/universalRouter/types';
import { fetchPage } from '../../store/labelManagement/actions';
type Location = {
  params: {
    pageId: string;
  };
};

export default (
  { store: { dispatch } }: universalRouterProps,
  { params: { pageId } }: Location,
) => {
  return Promise.all([dispatch(fetchPage(pageId))]);
};
