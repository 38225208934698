import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ApplicableSizeTypes, SizeTypesErrors } from 'SizeTypes-Types';
import { fetchFeatureSizeTypesAsync, fetchResouceSizeTypesAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchFeatureSizeTypesAsync.request, fetchResouceSizeTypesAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchFeatureSizeTypesAsync.success,
      fetchFeatureSizeTypesAsync.failure,
      fetchResouceSizeTypesAsync.success,
      fetchResouceSizeTypesAsync.failure,
    ],
    (state, action) => false,
  );

export const featureSizeTypes = createReducer({} as ApplicableSizeTypes).handleAction(
  fetchFeatureSizeTypesAsync.success,
  (state, action) => action.payload,
);

export const resourceSizeTypes = createReducer({} as ApplicableSizeTypes).handleAction(
  fetchResouceSizeTypesAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as SizeTypesErrors[]).handleAction(
  [fetchFeatureSizeTypesAsync.failure, fetchResouceSizeTypesAsync.failure],
  (state, action) => action.payload,
);

const sizeTypesReducer = combineReducers({
  isFetching,
  featureSizeTypes,
  resourceSizeTypes,
  errors,
});

export default sizeTypesReducer;
export type SizeTypesState = ReturnType<typeof sizeTypesReducer>;
