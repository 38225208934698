import {
  FeatureGroupList,
  FeatureGroupStatus,
  FeatureGroupObjectTypes,
  FeatureGroupSearch,
} from 'FeatureGroup-Types';
import { FeatureGroup } from 'Feature-Types';
import { ApiClient } from '@travel/api-client';
import { internalPrefix } from 'constants/api-prefix';

export const ApiFetchFeatureGroupList = (
  http: ApiClient,
  params: Object,
): Promise<FeatureGroupList> => {
  return http
    .get(`${internalPrefix}/featureGroups`, { values: params })
    .then((data: FeatureGroupList) => data);
};

export const ApiFetchFeatureGroup = (
  http: ApiClient,
  featureGroupId: string,
): Promise<FeatureGroup> =>
  http.get(`${internalPrefix}/featureGroups/${featureGroupId}`).then((data: FeatureGroup) => data);

export const ApiFetchFeatureGroupSearch = (
  http: ApiClient,
  type: string,
  id?: string,
): Promise<FeatureGroupSearch> =>
  http
    .get(`/provider-api/extranet/featureGroups/search`, { values: { type, id } })
    .then((data: FeatureGroupSearch) => data);

export const ApiPostFeatureGroup = (
  http: ApiClient,
  data: FeatureGroup,
): Promise<FeatureGroupStatus> => {
  return http
    .post(`${internalPrefix}/featureGroups`, { values: data })
    .then((data: FeatureGroupStatus) => data)
    .catch((data: FeatureGroupStatus) => data);
};

export const ApiPutFeatureGroup = (
  http: ApiClient,
  data: FeatureGroup,
): Promise<FeatureGroupStatus> =>
  http
    .put(`${internalPrefix}/featureGroups/${data.id}`, { values: data })
    .then((data: FeatureGroupStatus) => data)
    .catch((data: FeatureGroupStatus) => data);

export const ApiFetchFeatureGroupObjectTypes = (
  http: ApiClient,
): Promise<FeatureGroupObjectTypes> => {
  return http
    .get(`${internalPrefix}/featureGroups/objectTypes`, {})
    .then((data: FeatureGroupObjectTypes) => data);
};

export const ApiFetchFindByObjectTypeAndManagement = (
  http: ApiClient,
  param: {
    management: string;
    type: string;
  },
): Promise<FeatureGroup> => {
  return http
    .get(`${internalPrefix}/featureGroups/findByObjectTypeAndManagement`, { values: param })
    .then((data: FeatureGroup) => data);
};
