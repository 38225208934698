import { universalRouterProps } from 'core/universalRouter/types';
import { fetchGoToPromotions } from 'store/goToPromotion/actions';

export default (
  { store: { dispatch } }: universalRouterProps,
  query: { [key: string]: string },
) => {
  const promises = [dispatch(fetchGoToPromotions({ ...query, limit: 30, offset: 0 }))];
  return Promise.all(promises);
};
