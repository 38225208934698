import { Permission } from 'AccessControl-Types';
import { UserScopeType, getHasAccess, USER_SCOPE } from 'utils/userScopeHelper';

export function getIsUserNameClickable(scope: UserScopeType, permissions: Permission[]) {
  if (scope === USER_SCOPE.INTERNAL) {
    return getHasAccess(permissions, 'IntUsrAccCtrlMgmt'); // TODO: change hard code.
  } else if (scope === USER_SCOPE.PROVIDER) {
    return getHasAccess(permissions, 'SysAcctAccCtrlMgmt'); // TODO: change hard code.
  } else {
    return false;
  }
}
