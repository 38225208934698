import { universalRouterProps } from 'core/universalRouter/types';
import { fetchFaxManagement } from 'store/faxManagement/actions';
import { CONDITION_VALUES } from 'constants/searchConditions';
import { queryToObject } from '@travel/utils/src/queryString';
type Query = { [key: string]: string | number };

export type URLParams = { params: { providerId: string }; query: Query };

export const getQuery = (query: Query = {}) => {
  const objectQuery = queryToObject(query);
  const conditions = objectQuery?.search;
  const filter = objectQuery.filter;
  const sort = objectQuery.sort && Object.keys(objectQuery.sort)[0];
  const sortValue = sort && `${sort}.${Object.keys(objectQuery?.sort[sort])[0]}`;
  const matchType = objectQuery?.matchType || CONDITION_VALUES.BROAD;

  return {
    ...filter,
    ...conditions,
    matchType,
    sort: sortValue,
    limit: 30,
    offset: query.offset || 0,
  };
};

export default ({ store: { dispatch } }: universalRouterProps, { query }: URLParams) => {
  return Promise.all([dispatch(fetchFaxManagement(getQuery(query)))]);
};
