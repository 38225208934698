import React, { ReactNode } from 'react';

import FlatButton, { Props as FlatButtonProps } from '../FlatButton';

import cx from '../../utils/classnames';

import styles from './fixedButton.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** children of the component */
  children?: ReactNode;

  /** primary label */
  primaryLabel?: ReactNode;

  /** method to call on click of primary button */
  onClickPrimary?: () => void;

  /** secondary label */
  secondaryLabel?: ReactNode;

  /** method to call on click of secondary button */
  onClickSecondary?: () => void;

  /** tertiary label */
  tertiaryLabel?: ReactNode;

  /** method to call on click of tertiary button */
  onClickTertiary?: () => void;

  /** primary button's props */
  primaryProps?: FlatButtonProps;

  /** secondary button's props */
  secondaryProps?: FlatButtonProps;

  /** secondary button's props */
  tertiaryProps?: FlatButtonProps;

  /** disable save button */
  isSecondaryDisabled?: boolean;

  /** disable third button */
  isTertiaryDisabled?: boolean;

  /** flag to display third button */
  hasTertiaryButton?: boolean;

  /** indicate if primary is loading  */
  isPrimaryLoading?: boolean;

  /** indicate if secondary is loading  */
  isSecondaryLoading?: boolean;

  /** indicate if tertiary is loading  */
  isTertiaryLoading?: boolean;
};

function FixedButton(props: Props) {
  const {
    className,
    primaryLabel,
    onClickPrimary,
    secondaryLabel,
    onClickSecondary,
    tertiaryLabel,
    onClickTertiary,
    isSecondaryDisabled,
    isTertiaryDisabled,
    hasTertiaryButton,
    isPrimaryLoading,
    isSecondaryLoading,
    isTertiaryLoading,
    children,
    primaryProps,
    secondaryProps,
    tertiaryProps,
    ...rest
  } = props;

  return (
    <div className={cx(className, styles.fixedbutton)} data-testid="fixedButton-wrapper" {...rest}>
      {children}
      {primaryLabel && (
        <FlatButton
          classType="secondary"
          onClick={onClickPrimary}
          data-testid="secondary-button"
          isLoading={isPrimaryLoading}
          {...primaryProps}
        >
          {primaryLabel}
        </FlatButton>
      )}
      {secondaryLabel && (
        <FlatButton
          isDisabled={isSecondaryDisabled}
          classType="primary"
          onClick={onClickSecondary}
          data-testid="primary-button"
          isLoading={isSecondaryLoading}
          {...secondaryProps}
        >
          {secondaryLabel}
        </FlatButton>
      )}
      {tertiaryLabel && hasTertiaryButton && (
        <FlatButton
          classType="primary"
          onClick={onClickTertiary}
          isDisabled={isTertiaryDisabled}
          data-testid="tertiary-button"
          isLoading={isTertiaryLoading}
          {...tertiaryProps}
        >
          {tertiaryLabel}
        </FlatButton>
      )}
    </div>
  );
}

export default FixedButton;
