import { Feature } from 'Feature-Types';
import { BrowseParameters } from 'FeatureSelection-Types';
import { useTranslate } from '@travel/translation';

const sort = {
  options: [
    {
      text: useTranslate({ id: 'Supplier_Enumeration.Sort.Name_Alphabetical.Ascending' }),
      value: 'name=asc',
    },
    {
      text: useTranslate({ id: 'Supplier_Enumeration.Sort.Name_Alphabetical.Descending' }),
      value: 'name=desc',
    },

    {
      text: useTranslate({ id: 'Supplier_Enumeration.Sort.Feature_Score.High_Low' }),
      value: 'score=desc',
    },
    {
      text: useTranslate({ id: 'Supplier_Enumeration.Sort.Feature_Score.Low_High' }),
      value: 'score=asc',
    },
  ],
  selectedValue: 0,
};

export const initialBrowseParameter: BrowseParameters = {
  keyword: '',
  sort,
  filter: { objectType: '' },
  offset: 0,
  limit: 100,
};

export const initialDescriptions = { providerId: '', features: [] as Feature[] };
