import React, { ReactNode } from 'react';
import { ELearning, Question, MasterAssets } from '@travel/icons/ui';
import { Translate } from '@travel/translation';

export const FOOTER_DIALOG_PARAMS: {
  [key: string]: { icon: ReactNode; title: ReactNode; message: ReactNode };
} = {
  FAQ: {
    title: <Translate id="FAQ.popup_faq.header.title" />,
    message: <Translate id="FAQ.popup_faq.message" />,
    icon: <Question />,
  },
  E_Learning: {
    title: <Translate id="FAQ.popup_elearning.header.title" />,
    message: <Translate id="FAQ.popup_elearning.message" />,
    icon: <ELearning />,
  },
  LUX_TC: {
    title: (
      <Translate id="FAQ.popup_lux_tc.header.title" condition={() => 'status == provider_luxury'} />
    ),
    message: (
      <Translate id="FAQ.popup_lux_tc.message" condition={() => 'status == provider_luxury'} />
    ),
    icon: <MasterAssets />,
  },
};

export const EVEREST_PROVIDER_ID_LENGTH = 14;
export const PROVIDER_ID = 'providerid';
export const HOTEL_ID = 'hotelId';
