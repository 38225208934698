export function downloadFileFromBlob(blobObject: Blob | string, fileName: string = '') {
  // @ts-ignore : msSaveOrOpenBlob is removed from DOM.d.ts
  if (window.navigator?.msSaveOrOpenBlob) {
    // IE11, Opera
    // @ts-ignore : msSaveOrOpenBlob is removed from DOM.d.ts
    window.navigator?.msSaveOrOpenBlob(blobObject, fileName);
  } else {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blobObject);
    link.href = url;
    fileName && link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
