import React from 'react';
import { PlanList, PlanListParams, FeatureFilterValues } from 'Plan-Types';
import { Translate } from 'core/translate';

export const initialPlanList: PlanList = {
  offset: 0,
  limit: 30,
  total: 0,
  ratePlans: [],
  items: [],
};

export const initialFeatureFilterParamaters: FeatureFilterValues = {
  ratePlanStatus: [],
  itemStatus: [],
  planBenefits: [],
  planCategory: [],
  cancellationPolicies: [],
  designation: [],
};

export const initialSortValue = {
  options: [
    {
      text: 'All',
      value: 'sort=all',
    },
  ],
  selectedValue: 'sort=all',
};

export const initialBrowseParameter: PlanListParams = {
  keyword: '',
  searchCondition: '',
  filter: [
    {
      label: <Translate id="Plan_management.list.filter.rate_plan_status" />,
      id: 'ratePlanStatus',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Status.Sales.Active" />,
          value: 'true',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Sales.Inactive" />,
          value: 'false',
          isCheck: false,
        },
      ],
    },
    {
      label: <Translate id="Plan_management.list.filter.intem_status" />,
      id: 'itemStatus',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Status.Sales.Active" />,
          value: 'true',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Sales.Inactive" />,
          value: 'false',
          isCheck: false,
        },
      ],
    },
    {
      label: <Translate id="rate_control.control.filter.plan_category" />,
      id: 'planCategory',
      options: [
        {
          label: <Translate id="rate_control.control.filter.plan_category_Early_Bird" />,
          value: 'EARLY_BIRD',
          isCheck: false,
        },
        {
          label: <Translate id="rate_control.control.filter.plan_category_Last_Minute" />,
          value: 'LAST_MINUTE',
          isCheck: false,
        },
        {
          label: <Translate id="rate_control.control.filter.plan_category_Consecutive_Stay" />,
          value: 'CONSECUTIVE_STAY',
          isCheck: false,
        },
      ],
    },
    {
      label: <Translate id="Plan_management.list.filter.cancellation_policy" />,
      id: 'cancellationPolicies',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Refundability.Refundable" />,
          value: 'REFUNDABLE',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Refundability.Non_Refundable" />,
          value: 'NO_REFUNDABLE',
          isCheck: false,
        },
      ],
    },
  ],
  sort: {
    options: [
      {
        text: <Translate id="Plan_management.Enumeration.Sort.Rate_Plan.Creation_Date.New_Old" />,
        value: 'ratePlanCreateDateTime=desc',
      },
      {
        text: <Translate id="Plan_management.Enumeration.Sort.Rate_Plan.Creation_Date.Old_New" />,
        value: 'ratePlanCreateDateTime=asc',
      },
      {
        text: <Translate id="Plan_management.Enumeration.Sort.Rate_Plan.Updated_Date.New_Old" />,
        value: 'ratePlanUpdateDateTime=desc',
      },
      {
        text: <Translate id="Plan_management.Enumeration.Sort.Rate_Plan.Updated_Date.Old_New" />,
        value: 'ratePlanUpdateDateTime=asc',
      },
      {
        text: <Translate id="Plan_management.Enumeration.Sort.Item.Creation_Date.New_Old" />,
        value: 'itemCreateDateTime=desc',
      },
      {
        text: <Translate id="Plan_management.Enumeration.Sort.Item.Creation_Date.Old_New" />,
        value: 'itemCreateDateTime=asc',
      },
      {
        text: <Translate id="Plan_management.Enumeration.Sort.Item.Updated_Date.New_Old" />,
        value: 'itemUpdateDateTime=desc',
      },
      {
        text: <Translate id="Plan_management.Enumeration.Sort.Item.Updated_Date.Old_New" />,
        value: 'itemUpdateDateTime=asc',
      },
    ],
    selectedValue: 'ratePlanCreateDateTime=desc',
  },
  offset: 0,
  limit: 30,
};

export enum ApplicableSettingsType {
  ALLPLAN = 'ALL_PLANS',
  SELECTEDPLAN = 'SELECTED_PLANS',
}

export const initialApplicablePlans = {
  applicability: ApplicableSettingsType.ALLPLAN,
  plans: [],
  provider: {
    id: '',
    name: '',
  },
};
