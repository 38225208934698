import {
  createStandardAction,
  createAsyncAction,
  AppThunk,
  AsyncActionCreator,
} from 'typesafe-actions';
import {
  ProviderDataTranslationErrors,
  PastHistory,
  DownloadSummaryData,
  HistoryParams,
  UploadResult,
  HistoryData,
  Attribute,
  DownloadParams,
} from 'ProviderDataTranslation-Types';
import {
  ApiFetchHistory,
  ApiFetchSummary,
  ApiExportTranslationCSV,
  ApiUploadProviderContent,
  ApiFetchProcessData,
  ApiExportFailedData,
} from './apis';
import { SelectedProvider } from 'ProvidersInformation-Types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { ApiStatus } from 'GlobalErrorDialog-Types';
import { downloadFileFromBlob, isNotEmptyArray } from '@travel/utils';
import { getTodayWithFormat } from 'utils/calendar';
import { defaultSummary } from './initialValues';
import { getTranslation } from '@travel/translation';

export const setProviderListFile = createStandardAction('SET_PROVIDER_LIST_CSV').map((file: File): {
  payload: File;
} => ({
  payload: file,
}));
export const clearProviderListCSV = createStandardAction('CLEAR_PROVIDER_LIST_CSV')();

export const setProviderList = createStandardAction('SET_PROVIDER_LIST').map(
  (
    providers: Array<SelectedProvider>,
  ): {
    payload: Array<SelectedProvider>;
  } => ({
    payload: providers,
  }),
);
export const clearProviderList = createStandardAction('CLEAR_PROVIDER_LIST')();

export const setImportIdList = createStandardAction('SET_IMPORT_ID_LIST').map(
  (
    importIdList: Array<string>,
  ): {
    payload: Array<string>;
  } => ({
    payload: importIdList,
  }),
);
export const clearImportIdList = createStandardAction('CLEAR_IMPORT_ID_LIST')();

export const fetchDownloadSummaryAsync = createAsyncAction(
  'FETCH_DOWNLOAD_SUMMARY_REQUEST',
  'FETCH_DOWNLOAD_SUMMARY_SUCCESS',
  'FETCH_DOWNLOAD_SUMMARY_FAILURE',
)<undefined, DownloadSummaryData, ProviderDataTranslationErrors[]>();

export const fetchHistoryAsync = createAsyncAction(
  'FETCH_HISTORY_REQUEST',
  'FETCH_HISTORY_SUCCESS',
  'FETCH_HISTORY_FAILURE',
)<undefined, PastHistory, ProviderDataTranslationErrors[]>();

export const fetchProcessDataAsync = createAsyncAction(
  'FETCH_PROCESS_DATA_REQUEST',
  'FETCH_PROCESS_DATA_SUCCESS',
  'FETCH_PROCESS_DATA_FAILURE',
)<undefined, HistoryData, ProviderDataTranslationErrors[]>();

export const downloadProviderCSVAsync = createAsyncAction(
  'DOWNLOAD_PROVIDER_CSV_REQUEST',
  'DOWNLOAD_PROVIDER_CSV_SUCCESS',
  'DOWNLOAD_PROVIDER_CSV_FAILURE',
)<undefined, undefined, undefined>();

export const downloadItemCSVAsync = createAsyncAction(
  'DOWNLOAD_ITEM_CSV_REQUEST',
  'DOWNLOAD_ITEM_CSV_SUCCESS',
  'DOWNLOAD_ITEM_CSV_FAILURE',
)<undefined, undefined, undefined>();

export const downloadRatePlanCSVAsync = createAsyncAction(
  'DOWNLOAD_RATE_PLAN_CSV_REQUEST',
  'DOWNLOAD_RATE_PLAN_CSV_SUCCESS',
  'DOWNLOAD_RATE_PLAN_CSV_FAILURE',
)<undefined, undefined, undefined>();

export const uploadProviderListAsync = createAsyncAction(
  'UPLOAD_PROVIDER_LIST_REQUEST',
  'UPLOAD_PROVIDER_LIST_SUCCESS',
  'UPLOAD_PROVIDER_LIST_FAILURE',
)<undefined, File, ProviderDataTranslationErrors[]>();

export const uploadTranslationDataAsync = createAsyncAction(
  'UPLOAD_TRANSLATION_DATA_REQUEST',
  'UPLOAD_TRANSLATION_DATA_SUCCESS',
  'UPLOAD_TRANSLATION_DATA_FAILURE',
)<undefined, UploadResult, ProviderDataTranslationErrors[]>();

export const fetchFailedDataCSVAsync = createAsyncAction(
  'FETCH_FAILED_DATA_CSV_REQUEST',
  'FETCH_FAILED_DATA_CSV_SUCCESS',
  'FETCH_FAILED_DATA_CSV_FAILURE',
)<undefined, Blob, ProviderDataTranslationErrors[]>();

export const fetchHistory = (queryParameter: HistoryParams): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchHistoryAsync.request());
  try {
    const res = await ApiFetchHistory(apiClient, queryParameter);
    dispatch(fetchHistoryAsync.success(res));
  } catch (error) {
    dispatch(fetchHistoryAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const fetchProcessData = (importId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProcessDataAsync.request());
  try {
    const res = await ApiFetchProcessData(apiClient, importId);
    dispatch(fetchProcessDataAsync.success(res));
  } catch (error) {
    dispatch(fetchProcessDataAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};
export const clearProcessDataList = createStandardAction('CLEAR_PROCESS_DATA_LIST')();

export const fetchDownloadSummary = (params: DownloadParams, file?: File): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchDownloadSummaryAsync.request());

  const formData = new FormData();

  const filter = JSON.stringify(params);
  formData.append('filter', filter);

  if (file) {
    formData.append('file', file, file.name);
    if (file.size > 1048576) {
      const message = getTranslation({
        id: 'Extranet_Error.CSV_Size_Error',
        data: { size_limit: '1MB' },
      }).join('');
      dispatch(fetchGlobalErrorDialogAsync.success({ status: 413, message }));
      dispatch(fetchDownloadSummaryAsync.success(defaultSummary));
      return;
    }
  }

  try {
    const res = await ApiFetchSummary(apiClient, formData);
    dispatch(fetchDownloadSummaryAsync.success(res));
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error));
    dispatch(fetchDownloadSummaryAsync.success(defaultSummary));
  }
};

export const exportCSV = (
  attributes: Attribute[],
  params: Omit<DownloadParams, 'attributes'>,
  asyncAction: AsyncActionCreator<[string, undefined], [string, undefined], [string, undefined]>,
  fileName: string,
  file?: File,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(asyncAction.request());
  const filter: DownloadParams = { ...params, attributes: attributes };

  const formData = new FormData();
  formData.append('filter', JSON.stringify(filter));
  if (file) {
    formData.append('file', file, file.name);
  }

  try {
    const blob = await ApiExportTranslationCSV(apiClient, formData);
    if (blob) {
      downloadFileFromBlob(blob, fileName);
    }
    dispatch(asyncAction.success());
  } catch (error) {
    const errorResponse: ApiStatus = {
      status: 500,
      labelId: 'Extranet_Error.CSV_Download_Error',
      ...error,
    };
    dispatch(asyncAction.failure());
    dispatch(fetchGlobalErrorDialogAsync.success(errorResponse));
  }
};

export const exportTranslationCSVs = (params: DownloadParams, file?: File): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const { attributes, ...rest } = params;
  const providerAttr = attributes.filter(
    attr => attr === 'PROVIDER_ADDRESS' || attr === 'PROVIDER_NAME' || attr === 'CATCHPHRASE',
  );
  const itemAttr = attributes.filter(attr => attr === 'ITEM_NAME');
  const ratePlanAttr = attributes.filter(attr => attr === 'RATE_PLAN_NAME');
  const today = getTodayWithFormat('DDMMYYYY');

  if (isNotEmptyArray(providerAttr)) {
    const fileName = 'provider_translation_' + today;
    dispatch(exportCSV(providerAttr, rest, downloadProviderCSVAsync, fileName, file));
  }
  if (isNotEmptyArray(itemAttr)) {
    const fileName = 'item_translation_' + today;
    dispatch(exportCSV(itemAttr, rest, downloadItemCSVAsync, fileName, file));
  }
  if (isNotEmptyArray(ratePlanAttr)) {
    const fileName = 'rateplan_translation_' + today;
    dispatch(exportCSV(ratePlanAttr, rest, downloadRatePlanCSVAsync, fileName, file));
  }
};

export const exportFailedDataCSV = (
  importId: string,
): AppThunk<Promise<Blob | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchFailedDataCSVAsync.request());
  try {
    const res = await ApiExportFailedData(apiClient, importId);
    dispatch(fetchFailedDataCSVAsync.success(res));
    return res;
  } catch (error) {
    dispatch(fetchFailedDataCSVAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const uploadProviderContentCSV = (
  file: File,
): AppThunk<Promise<UploadResult | undefined>> => async (dispatch, getState, { apiClient }) => {
  if (file.size > 1048576) {
    const message = getTranslation({
      id: 'Extranet_Error.CSV_Size_Error',
      data: { size_limit: '1MB' },
    }).join('');
    dispatch(fetchGlobalErrorDialogAsync.success({ status: 413, message }));
    return;
  }

  dispatch(uploadTranslationDataAsync.request());
  const formData = new FormData();
  formData.append('file', file, file.name);

  try {
    const res = await ApiUploadProviderContent(apiClient, formData);
    dispatch(uploadTranslationDataAsync.success(res));
    return res;
  } catch (error) {
    dispatch(uploadTranslationDataAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};
