import { universalRouterProps } from 'core/universalRouter/types';
import { fetchAlias } from 'store/aliases/actions';

type Location = {
  params: {
    aliasId: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, { params }: Location) => {
  return Promise.all([params.aliasId ? dispatch(fetchAlias(params.aliasId)) : null]);
};
