import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  ProviderReviewDetail,
  ReviewsErrors,
  ReviewDetail,
  ReviewList,
  Analytics,
} from 'Reviews-Types';
import {
  fetchReviewsAsync,
  fetchReviewAsync,
  fetchProviderReviewScoreAsync,
  replyReviewAsync,
  fetchProviderScoreAnalyticsAsync,
} from './actions';

export const isReplying = createReducer(false)
  .handleAction(replyReviewAsync.request, () => true)
  .handleAction([replyReviewAsync.success, replyReviewAsync.failure], () => false);

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchReviewsAsync.request], () => true)
  .handleAction([fetchReviewsAsync.success, fetchReviewsAsync.failure], () => false);

export const reviewList = createReducer({} as ReviewList).handleAction(
  fetchReviewsAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      reviews:
        state.reviews && shouldConcat
          ? state.reviews.concat(action.payload.reviews)
          : action.payload.reviews,
    };
  },
);

export const reviewDetail = createReducer({} as ReviewDetail).handleAction(
  fetchReviewAsync.success,
  (_state, action) => action.payload,
);

export const providerReviewScore = createReducer({} as ProviderReviewDetail).handleAction(
  fetchProviderReviewScoreAsync.success,
  (_state, action) => action.payload,
);

export const providerScoreAnalytics = createReducer({} as Analytics).handleAction(
  fetchProviderScoreAnalyticsAsync.success,
  (_state, action) => action.payload,
);

export const errors = createReducer([] as ReviewsErrors[]).handleAction(
  fetchReviewsAsync.failure,
  (_state, action) => action.payload,
);

const reviewsReducer = combineReducers({
  isFetching,
  reviewList,
  reviewDetail,
  providerReviewScore,
  providerScoreAnalytics,
  errors,
  isReplying,
});

export default reviewsReducer;
export type ReviewsState = ReturnType<typeof reviewsReducer>;
