import React from 'react';

import Plus from '@travel/icons/ui/Plus';
import { Translate } from '@travel/translation';
import { FlatButton } from '@travel/ui';
import { cx } from '@travel/utils';

import styles from './createButton.module.scss';

type Props = {
  /** Custom button label */
  label?: React.ReactNode;
  /** className for the button */
  className?: string;
  /** disable the create button */
  isDisabled?: boolean;
  /** callback to be called after create button clicked */
  onCreate?: () => void;
};

function CreateButton(props: Props) {
  const { onCreate, className, label, isDisabled } = props;

  return (
    <FlatButton
      type="button"
      data-testid="createPageHeader-create-button"
      className={cx(className, styles.createButton)}
      icon={<Plus size={24} className={styles.plusIcon} />}
      onClick={onCreate}
      isDisabled={isDisabled}
    >
      {label || <Translate id={'Common.List.Create'} />}
    </FlatButton>
  );
}

export default CreateButton;
