import React from 'react';
import { Translate } from 'core/translate';
import { FilterGroup, BrowseParameters, checkboxOptionGroup } from 'FilterGroup-Types';

export const initialFilterGroup: FilterGroup = {
  id: '',
  active: false,
  features: [],
  logic: 'AND',
  name: '',
};

export const sort = {
  options: [
    {
      text: <Translate id="Supplier_Enumeration.Sort.Display_Order.Low_High" />,
      value: 'order=asc',
    },
    {
      text: <Translate id="Supplier_Enumeration.Sort.Display_Order.High_Low" />,
      value: 'order=desc',
    },
  ],
  selectedValue: 'order=asc',
};

export const filter: Array<checkboxOptionGroup> = [
  {
    label: <Translate id="Filter_Group_List.List.Filter.Status" />,
    id: 'status',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Status.Activation.Active" />,
        value: 'true',
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Status.Activation.Inactive" />,
        value: 'false',
        isCheck: false,
      },
    ],
  },
  {
    label: <Translate id="Filter_Group_List.List.Filter.Logic_Gate" />,
    id: 'logic',
    options: [
      {
        label: <Translate id="Filter_Group_List.Edit.Logic_Gate.Enumeration.AND" />,
        value: 'AND',
        isCheck: false,
      },
      {
        label: <Translate id="Filter_Group_List.Edit.Logic_Gate.Enumeration.OR" />,
        value: 'OR',
        isCheck: false,
      },
    ],
  },
];

export const initialBrowseParameter: BrowseParameters = {
  searchText: '',
  searchCondition: '',
  filter,
  offset: 0,
  limit: 30,
};
