import { createSelector } from 'reselect';
import { LabelManagementState } from './reducer';
import { RootState } from 'typesafe-actions';
import { Page, AllPagesBody, AllLabelsBody } from 'LabelManagement-Types';

export const getErrors = (state: RootState) => state.labelManagement.errors;

export const getLabelManagement = (state: RootState) => state.labelManagement;

export const getIsCreating = createSelector(
  getLabelManagement,
  (state: LabelManagementState) => state.isCreating,
);

export const getIsDownloading = createSelector(
  getLabelManagement,
  (state: LabelManagementState) => state.isDownloading,
);

export const getPage = createSelector(
  getLabelManagement,
  (state: LabelManagementState) => state.page,
);

export const getPagesReducer = createSelector(
  getLabelManagement,
  (state: LabelManagementState) => state.pages,
);

export const getLabelsReducer = createSelector(
  getLabelManagement,
  (state: LabelManagementState) => state.labels,
);

export const getPages = createSelector(getPagesReducer, (state: AllPagesBody) => state?.content);

export const getPagesCount = createSelector(
  getPagesReducer,
  (state: AllPagesBody) => state?.totalElements,
);

export const getPagesOffset = createSelector(
  getPagesReducer,
  (state: AllPagesBody) => state?.pageable?.offset,
);

export const getLabels = createSelector(getLabelsReducer, (state: AllLabelsBody) => state.content);

export const getLabelsCount = createSelector(
  getLabelsReducer,
  (state: AllLabelsBody) => state.totalElements,
);

export const getLabelsOffset = createSelector(
  getLabelsReducer,
  (state: AllLabelsBody) => state.pageable?.offset,
);

export const getLabel = createSelector(
  getLabelManagement,
  (state: LabelManagementState) => state.label,
);

export const getPageName = createSelector(getPage, (state: Page) => state.pageName);
export const getProductName = createSelector(getPage, (state: Page) => state.product);

export const getIsFetching = createSelector(
  getLabelManagement,
  (state: LabelManagementState) => state.isFetching,
);

export const getFilterPages = createSelector(getPagesReducer, (state: AllPagesBody) => {
  return state.content.reduce((formattedPages, page) => {
    if (page.product) {
      const pages = formattedPages.find(
        (formattedPage: Feature) => formattedPage.id === page.product,
      );
      const newPage = {
        id: page.pageId || page.pageName,
        name: page.pageName,
        title: page.pageName,
        childFeatures: [],
        description: '',
        selected: false,
      };
      if (pages) {
        pages.childFeatures.push(newPage);
      } else {
        const newProduct = {
          id: page.product,
          name: page.product,
          title: page.product,
          childFeatures: [newPage],
          description: '',
          selected: false,
          displayChild: false,
          labelId: `Supplier_Enumeration.Label.Page_Product.${
            page.product === 'DP' ? 'JRDP' : page.product
          }`,
        };
        formattedPages.push(newProduct);
      }
    }
    return formattedPages;
  }, [] as Array<Feature>);
});

type Feature = {
  id: string;
  name: string;
  title: string;
  childFeatures: Feature[];
  description: string;
  selected: boolean;
};
