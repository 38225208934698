import { ObjectScoreList, ApiStatus } from 'ObjectScore-Types';
import { ApiClient } from '@travel/api-client';

const RANK_BASE_URL = '/search-data-api/rank';

export const ApiFetchObjectScoreList = (
  http: ApiClient,
  params: { [key: string]: string | number },
): Promise<ObjectScoreList | ApiStatus> => {
  const url = `${RANK_BASE_URL}/read`;
  return http
    .get(url, { values: params })
    .then((data: ObjectScoreList) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiDownloadObjectScoreList = (
  http: ApiClient,
  params: { [key: string]: string | number },
) => {
  const url = `${RANK_BASE_URL}/download`;
  return http.get(url, { values: params }).then(data => data);
};
