// This file is generated automatically by scripts/generate-icon.js. Don't modify it.

import NoResult from '../_raw/ui/icon_no_result.svg';
import withIconProps from '../withIconProps';

export default withIconProps(NoResult, {
  shouldSetDefaultSize: true,
  defaultWidth: 400,
  defaultHeight: 200,
});
