import { universalRouterProps } from 'core/universalRouter/types';
import { fetchAccountingReports, fetchYearAndMonths } from 'store/accountingReport/actions';
import { queryToObject } from '@travel/utils/src/queryString';

type Query = { [key: string]: string };
type Location = {
  params: {};
  query: Query;
};

export default ({ store: { dispatch } }: universalRouterProps, { params, query }: Location) => {
  return Promise.all([
    dispatch(
      fetchAccountingReports(
        queryToObject(query || { limit: 30, month: 'JUNE', offset: 0, year: 2020 }),
      ),
    ),
    dispatch(fetchYearAndMonths()),
  ]);
};
