import React, { ReactNode } from 'react';
import { cx } from '@travel/utils';
import styles from './block.module.scss';
import Divider from '../Divider';

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Display contents of the block container */
  children: ReactNode;
  /** Set the style of the divider to solid or dotted */
  isSolid: boolean;
  /** Set the last divider to display horizontal line */
  isNotLast: boolean;
};

function Block(props: Props) {
  const { className, isSolid, isNotLast, children } = props;

  return (
    <div className={cx(styles.blockContainer, className)} data-testid="block-wrapper">
      {children}
      {isNotLast && <Divider type={isSolid ? 'solid' : 'dotted'} className={styles.belowSpace} />}
    </div>
  );
}

Block.defaultProps = {
  isSolid: true,
  isNotLast: true,
};

export default Block;
