import { ApiClient } from '@travel/api-client';
import { ApplicationRatePlanDetail, ApiStatus } from 'ApplicationDetail-Types';

export const ApiFetchApplicationRatePlanDetail = (
  http: ApiClient,
  campaignId: string,
  applicationId: string,
  ratePlanId: string,
): Promise<ApplicationRatePlanDetail> => {
  return http
    .get(
      `/provider-api/extranet/campaigns/${campaignId}/applications/${applicationId}/ratePlans/${ratePlanId}`,
    )
    .then((response: ApplicationRatePlanDetail) => response);
};

export const ApiAcceptApplicationRatePlanDetail = (
  http: ApiClient,
  campaignId: string,
  applicationId: string,
  ratePlanId: string,
): Promise<ApiStatus> => {
  return http
    .put(
      `/provider-api/extranet/campaigns/${campaignId}/applications/${applicationId}/ratePlans/${ratePlanId}/accept`,
      {},
    )
    .then((response: ApiStatus) => response);
};

export const ApiSuggestApplicationRatePlanDetail = (
  http: ApiClient,
  campaignId: string,
  applicationId: string,
  ratePlanId: string,
  suggestion: string,
): Promise<ApiStatus> => {
  return http
    .put(
      `/provider-api/extranet/campaigns/${campaignId}/applications/${applicationId}/ratePlans/${ratePlanId}/suggestModification`,
      {
        values: {
          memo: suggestion,
        },
      },
    )
    .then((response: ApiStatus) => response);
};

export const ApiRejectApplicationRatePlanDetail = (
  http: ApiClient,
  campaignId: string,
  applicationId: string,
  ratePlanId: string,
  rejection: string,
): Promise<ApiStatus> => {
  return http
    .put(
      `/provider-api/extranet/campaigns/${campaignId}/applications/${applicationId}/ratePlans/${ratePlanId}/reject`,
      {
        values: {
          memo: rejection,
        },
      },
    )
    .then((response: ApiStatus) => response);
};
