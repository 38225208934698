import { fetchGroupAccount } from 'store/accessControl/actions';
import { clearProviderGroup, fetchProviderGroup } from 'store/providerGroup/actions';

import { universalRouterProps } from 'core/universalRouter/types';
import { QueryParameter } from 'ProviderGroup-Types';

type Location = {
  params: {
    providerGroupId?: string;
  };
  query: QueryParameter;
};
export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  const { params, query } = location;
  const { providerGroupId } = params;

  const promises: Promise<void>[] = [];

  if (providerGroupId) {
    promises.push(dispatch(fetchProviderGroup(query, providerGroupId)));
    promises.push(dispatch(fetchGroupAccount(providerGroupId)));
  } else {
    dispatch(clearProviderGroup());
  }

  return Promise.all(promises);
};
