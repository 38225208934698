import React from 'react';
import styles from './loadingGreenLine.module.scss';
type Props = {
  isLoading?: boolean;
};

function LoadingGreenLine(props: Props) {
  const { isLoading } = props;
  return (
    <div className={styles.wrapper}>
      {isLoading && <div className={styles.loader} data-testid="list-loader"></div>}
    </div>
  );
}

export default LoadingGreenLine;
