import React from 'react';
import { cx } from '@travel/utils';
import styles from './toolBar.module.scss';
import { ToolBar } from '@travel/ui';

/** XXX: className can be removed from props if you don't set any className on root element,
 * after you remove it, it might be passed through ...rest
 */
type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** ToolBar Items */
  children: React.ReactNode;

  /** isDisplayBlock: to make sure dispaly:block.
   * sometimes display:block is overwritten by other page's display:flex cache. */
  isDisplayBlock?: boolean;
};

function ToolBarSupplier(props: Props) {
  const { className, isDisplayBlock } = props;

  return (
    <ToolBar
      data-locator-id="ToolBar-ed505790-4123-4d08-954d-a49118af32d3"
      className={cx(className, styles.toolBar, isDisplayBlock && styles.block)}
    >
      {props.children}
    </ToolBar>
  );
}

ToolBarSupplier.defaultProps = {
  className: '',
  isDisplayBlock: false,
};

export default ToolBarSupplier;
