import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { CutOffItems, CutOffErrors, ApiStatus } from 'CutOff-Types';
import { ApiUpdateCutOffSettings, ApiFetchCutOffSettings } from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

export const fetchCutOffAsync = createAsyncAction(
  'FETCH_CUT_OFF_REQUEST',
  'FETCH_CUT_OFF_SUCCESS',
  'FETCH_CUT_OFF_FAILURE',
)<undefined, CutOffItems, CutOffErrors[]>();

export const updateCutOffAsync = createAsyncAction(
  'UPDATE_CUT_OFF_REQUEST',
  'UPDATE_CUT_OFF_SUCCESS',
  'UPDATE_CUT_OFF_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const fetchCutOffSettings = (providerId: string, query: Object): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchCutOffAsync.request());
  const res = await ApiFetchCutOffSettings(apiClient, providerId, query);
  dispatch(fetchCutOffAsync.success(res));
};

export const updateCutOffSetting = (providerId: string, values: CutOffItems): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(updateCutOffAsync.request());
  const res = await ApiUpdateCutOffSettings(apiClient, providerId, values);
  dispatch(fetchGlobalErrorDialogAsync.success(res));
  dispatch(updateCutOffAsync.success(res));
};
