import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getLanguage } from '@travel/i18n';
import { getProviderLanguage } from 'store/providersInformation/selectors';

import { getCurrentLink, PLATFORM } from 'utils';
import { getIsJumpedUser, PlatformType } from 'utils/userScopeHelper';
import { parseInputLanguage } from 'utils/dataInputLang';
import { COOKIE_NAMES, setCookie, getCookie } from 'utils/cookieStorage';
import { setInputLanguage } from 'store/languages/actions';
import { getLoggedInUser } from 'store/accessControl/selectors';

type Props = {
  pathname: string;
  platform: PlatformType;
  selectedLanguage: string;
};

function useRefreshInputLanguage(props: Props) {
  const { pathname, platform, selectedLanguage } = props;
  const dispatch = useDispatch();

  const uiLanguage = useSelector(getLanguage);
  const providerLanguage = useSelector(getProviderLanguage);

  const currentLink = getCurrentLink(pathname, platform);
  const initCurrentlink = useRef(currentLink);
  const normalizedLanguage =
    parseInputLanguage(getCookie(COOKIE_NAMES.INPUT_LANGUAGE) || '', true) || 'en-US';
  const { scope } = useSelector(getLoggedInUser);
  const isJumpedUser = getIsJumpedUser(scope);

  useEffect(() => {
    /** Check whether the platform has enabled DIL (Data Input lang) and
     *  if user changed it then navigated to the next management page.
     */
    if (
      (platform === PLATFORM.INTERNAL || platform === PLATFORM.GROUP_EXTRANET) &&
      ((uiLanguage !== selectedLanguage && initCurrentlink.current !== currentLink) ||
        normalizedLanguage !== selectedLanguage)
    ) {
      initCurrentlink.current = currentLink;

      /** Set and fetch data again based on UI lang */
      dispatch(setInputLanguage(uiLanguage));
      setCookie(COOKIE_NAMES.INPUT_LANGUAGE, `${uiLanguage}#${currentLink}#${uiLanguage}`);
    } else if (platform === PLATFORM.EXTRANET && normalizedLanguage !== selectedLanguage) {
      /** Set and fetch data again based on Provider lang */
      dispatch(setInputLanguage(providerLanguage));
      setCookie(COOKIE_NAMES.INPUT_LANGUAGE, providerLanguage);
    } else if (isJumpedUser) {
      dispatch(setInputLanguage(uiLanguage));
      setCookie(COOKIE_NAMES.INPUT_LANGUAGE, uiLanguage);
    } else if (platform !== PLATFORM.OUTSIDE && normalizedLanguage !== selectedLanguage) {
      /** Set cookie to selected language by default once login */
      setCookie(COOKIE_NAMES.INPUT_LANGUAGE, `${selectedLanguage}#${currentLink}#${uiLanguage}`);
    }
  }, [
    dispatch,
    uiLanguage,
    selectedLanguage,
    currentLink,
    platform,
    providerLanguage,
    normalizedLanguage,
    isJumpedUser,
  ]);

  return;
}

export default useRefreshInputLanguage;
