import React, { useState, ReactNode, useRef, useEffect, useCallback } from 'react';
import { cx } from '@travel/utils';
import { Filter, DropdownArrow } from '@travel/icons/ui';
import FilterBalloon, { GroupList } from '../FilterBalloon';
import styles from './filterDropDown.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** Set description per props */
  title?: ReactNode;

  /** Group of Checkbox(s) */
  filterList?: Array<GroupList>;
  /** Any additional  component to render with filterList */
  filterComponent?: ReactNode;

  /** callback to be called after filter option selected */
  onFilter: (filterList: Array<GroupList>) => void;

  /** callback function when clear button is clicked */
  onClear: (filterList: Array<GroupList>) => void;

  /** callback function when ballon opend */
  onOpen: () => void;

  /** Component children */
  children?: ReactNode;

  /** Clear Button Label. Displays button only if label clearButtonText is passed */
  clearButtonText?: ReactNode;

  /** Option to disable click outside listner for integrated child popups */
  shouldDisableFocusOut?: boolean;

  onSubmit: () => void;

  hasApplyButton?: boolean;
  applyButtonText?: ReactNode;
  isFilterOpen?: boolean;
  setIsFilterOpen?: (isOpen: boolean) => void;
};

function FilterDropDown(props: Props) {
  const {
    className,
    title,
    onFilter,
    filterList,
    filterComponent,
    onClear,
    children,
    clearButtonText,
    shouldDisableFocusOut,
    onSubmit,
    onOpen,
    hasApplyButton,
    applyButtonText,
    isFilterOpen,
    setIsFilterOpen,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(isFilterOpen || false);
  const node: any = useRef();

  const handleFilterDropdownStatus = useCallback(
    (status: boolean) => {
      children && setIsFilterOpen && setIsFilterOpen(status);
    },
    [children, setIsFilterOpen],
  );

  const handleFilterBalloon = () => {
    if (!isOpen) onOpen();
    setIsOpen(!isOpen);
    handleFilterDropdownStatus(!isOpen);
  };

  const handleSubmit = () => {
    handleFilterBalloon();
    onSubmit();
  };

  useEffect(() => {
    const handleFocusOut = (event: any) => {
      if (node.current && !node.current.contains(event.target)) {
        setIsOpen(!isOpen);
      }
    };
    if (!shouldDisableFocusOut) {
      document.addEventListener('click', handleFocusOut);
    }
    return () => {
      document.removeEventListener('click', handleFocusOut);
    };
  }, [isOpen, shouldDisableFocusOut]);

  useEffect(() => {
    if (typeof isFilterOpen === 'boolean' && isOpen !== isFilterOpen) {
      setIsOpen(isFilterOpen);
    }
  }, [isOpen, isFilterOpen]);

  return (
    <div
      data-locator-id="FilterDropDown-3f716028-1eaa-4dc2-8efb-26d68f25f90f"
      className={cx(className, styles.filterDropDown)}
      {...rest}
    >
      <div
        data-testid="filterDropdown-filter-button"
        onClick={handleFilterBalloon}
        className={isOpen ? styles.menuWrapperDimmed : styles.menuWrapper}
      >
        <Filter size={24} /> <span className={styles.title}>{title}</span>
        <DropdownArrow size={24} />
      </div>
      {isOpen && (
        <div className={styles.balloonWrapper} ref={node}>
          {!children ? (
            <FilterBalloon
              direction="topLeft"
              filterList={filterList}
              filterComponent={filterComponent}
              isSolid={true}
              clearButtonText={clearButtonText}
              onSelect={onFilter}
              onClear={onClear}
              className={styles.filterBalloon}
              onSubmit={handleSubmit}
              hasApplyButton={hasApplyButton}
              applyButtonText={applyButtonText}
            />
          ) : (
            children
          )}
        </div>
      )}
    </div>
  );
}

FilterDropDown.defaultProps = {
  className: '',
  filterList: [],
  title: '',
  onFilter: () => {},
  onClear: () => {},
  onSubmit: () => {},
  onOpen: () => {},
  children: '',
  clearButtonText: '',
  hasApplyButton: true,
};

export default FilterDropDown;
