import { universalRouterProps } from 'core/universalRouter/types';
import { fetchProviderGroupProviderList } from 'store/providerGroup/actions';
import { QueryParameter } from 'ProviderGroup-Types';

type Location = {
  query: QueryParameter;
  params: {
    providerGroupId: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, { params, query }: Location) => {
  const { providerGroupId } = params;

  const promises = [dispatch(fetchProviderGroupProviderList(query, providerGroupId))];

  return Promise.all(promises);
};
