import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  fetchCurrentStageProviderAsync,
  jumpingAsync,
  logoutAsync,
  postLoginAsync,
  processChangeExpiredPasswordAsync,
  processForgotPasswordAsync,
  processOneTimeKeyAsync,
  processRefreshTokenAsync,
  resetPasswordPreData,
  setIsInitialLoad,
  setIsShowLoginDialog,
  setIsTokenAlmostExpire,
  setSsoTokenData,
  setVerifyTokenStandard,
  switchTokenAsync,
  verifyTokenAsync,
} from './actions';

import {
  CurrentStageProviderDetail,
  LoginStatus,
  ResetPasswordPreData,
  TokenInfo,
} from 'Login-Types';
import { setIsShowBrowserPrompt } from '../accessControl/actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      postLoginAsync.request,
      processForgotPasswordAsync.request,
      processOneTimeKeyAsync.request,
      processChangeExpiredPasswordAsync.request,
      processRefreshTokenAsync.request,
      switchTokenAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      postLoginAsync.success,
      postLoginAsync.failure,
      processForgotPasswordAsync.success,
      processForgotPasswordAsync.failure,
      processOneTimeKeyAsync.success,
      processOneTimeKeyAsync.failure,
      processChangeExpiredPasswordAsync.success,
      processChangeExpiredPasswordAsync.failure,
      processRefreshTokenAsync.failure,
      processRefreshTokenAsync.success,
      switchTokenAsync.failure,
      switchTokenAsync.success,
    ],
    () => false,
  );

export const isTokenExpire = createReducer(false)
  .handleAction(processRefreshTokenAsync.success, () => false)
  .handleAction(processRefreshTokenAsync.failure, () => true);

export const isTokenAlmostExpire = createReducer(false).handleAction(
  setIsTokenAlmostExpire,
  (_state, action) => action.payload,
);

export const isInitialLoad = createReducer(true).handleAction(
  setIsInitialLoad,
  (_state, action) => action.payload,
);

export const isShowLoginDialog = createReducer(false).handleAction(
  setIsShowLoginDialog,
  (_state, action) => action.payload,
);

export const isShowBrowserPrompt = createReducer(true).handleAction(
  setIsShowBrowserPrompt,
  (_state, action) => action.payload,
);

export const loginResponse = createReducer({} as LoginStatus)
  .handleAction(
    [
      postLoginAsync.success,
      processRefreshTokenAsync.success,
      setSsoTokenData,
      switchTokenAsync.success,
    ],
    (_state, action) => action.payload,
  )
  .handleAction(
    [processRefreshTokenAsync.failure, switchTokenAsync.failure, logoutAsync.failure],
    () => ({} as LoginStatus),
  );

export const loginErrors = createReducer([] as LoginStatus[]).handleAction(
  postLoginAsync.failure,
  (_state, action) => action.payload,
);

export const forgotPasswordResponse = createReducer({} as LoginStatus).handleAction(
  processForgotPasswordAsync.success,
  (_state, action) => action.payload,
);

export const forgotPasswordError = createReducer({} as LoginStatus).handleAction(
  processForgotPasswordAsync.failure,
  (_state, action) => action.payload,
);

export const oneTimeKeyResponse = createReducer({} as LoginStatus).handleAction(
  processOneTimeKeyAsync.success,
  (_state, action) => action.payload,
);

export const oneTimeKeyError = createReducer({} as LoginStatus).handleAction(
  processOneTimeKeyAsync.failure,
  (_state, action) => action.payload,
);

export const passwordPreData = createReducer({} as ResetPasswordPreData).handleAction(
  resetPasswordPreData,
  (_state, action) => action.payload,
);

export const currentStageProviderDetail = createReducer(
  {} as CurrentStageProviderDetail,
).handleAction(fetchCurrentStageProviderAsync.success, (_state, action) => action.payload);

export const isJumping = createReducer(false as boolean)
  .handleAction([jumpingAsync.request], (state, action) => true)
  .handleAction([jumpingAsync.success, jumpingAsync.failure], (state, action) => false);

export const verifyTokenDetail = createReducer({} as TokenInfo).handleAction(
  [setVerifyTokenStandard, verifyTokenAsync.success],
  (_state, action) => action.payload,
);

const loginReducer = combineReducers({
  isFetching,
  loginResponse,
  currentStageProviderDetail,
  loginErrors,
  forgotPasswordResponse,
  forgotPasswordError,
  oneTimeKeyResponse,
  oneTimeKeyError,
  passwordPreData,
  isTokenExpire,
  isTokenAlmostExpire,
  isShowLoginDialog,
  isShowBrowserPrompt,
  isJumping,
  verifyTokenDetail,
  isInitialLoad,
});

export default loginReducer;
export type LoginState = ReturnType<typeof loginReducer>;
