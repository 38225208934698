import { ApiClient } from '@travel/api-client';
import { toQuery } from '@travel/utils';
import {
  ApplicationTrackerItems,
  FilterType,
  MemoType,
  UploadResult,
} from 'ApplicationTracker-Types';
// import { internalPrefix } from 'constants/api-prefix';

export const fetch = (
  http: ApiClient,
  campaignId: string,
  params: FilterType,
): Promise<ApplicationTrackerItems[]> => {
  return http
    .get(`/provider-api/extranet/campaigns/${campaignId}/applications`, {
      values: toQuery(params),
    })
    .then((data: ApplicationTrackerItems[]) => data)
    .catch((data: ApplicationTrackerItems[]) => {
      //TODO: Error handling
      console.error(data);
      return data;
    });
};

export const updateMemo = (
  http: ApiClient,
  campaignId: string,
  applicationId: string,
  memo: MemoType,
): Promise<ApplicationTrackerItems> => {
  return http
    .put(`/provider-api/extranet/campaigns/${campaignId}/applications/${applicationId}/memo`, {
      values: memo,
    })
    .then((data: ApplicationTrackerItems) => data)
    .catch((data: ApplicationTrackerItems) => {
      //TODO: Error handling
      return data;
    });
};

export const updateEncourage = (
  http: ApiClient,
  campaignId: string,
  applicationId: string,
  memo: MemoType,
): Promise<ApplicationTrackerItems> => {
  return http
    .put(`/provider-api/extranet/campaigns/${campaignId}/applications/${applicationId}/encourage`, {
      values: memo,
    })
    .then((data: ApplicationTrackerItems) => data)
    .catch((data: ApplicationTrackerItems) => {
      //TODO: Error handling
      return data;
    });
};

export const exportProviderList = (
  http: ApiClient,
  campaignId: string,
  params: FilterType,
): Promise<Blob> => {
  return http
    .get(`/provider-api/extranet/campaigns/${campaignId}/applications/downloadProviders`, {
      values: toQuery(params),
    })
    .then(data => data.blob())
    .catch(error => error);
};

export const exportRatePlanList = (
  http: ApiClient,
  campaignId: string,
  params: FilterType,
): Promise<Blob> => {
  return http
    .get(`/provider-api/extranet/campaigns/${campaignId}/applications/downloadRatePlans`, {
      values: toQuery(params),
    })
    .then(data => data.blob())
    .catch(error => error);
};

export const exportOverlayList = (
  http: ApiClient,
  campaignId: string,
  params: FilterType,
): Promise<Blob> => {
  return http
    .get(`/provider-api/extranet/campaigns/${campaignId}/applications/downloadOverlays`, {
      values: toQuery(params),
    })
    .then(data => data.blob())
    .catch(error => error);
};

export const uploadCSV = (
  http: ApiClient,
  campaignId: string,
  formData: FormData,
): Promise<UploadResult> => {
  return http
    .post(`/provider-api/extranet/campaigns/${campaignId}/applications/importRatePlans`, {
      values: formData,
      ignoreCommonErrorHandler: true,
    })
    .then(data => data)
    .catch(data => data);
};
