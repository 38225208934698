import React, { ReactElement } from 'react';
import { isNotEmptyArray, isNotNullish } from '@travel/utils';
import { Translate } from '@travel/translation';
import { RootErrorContent } from 'GlobalErrorDialog-Types';

type LabelObjects = { [key: string]: string | number | ReactElement };

const getErrorContent = (data?: RootErrorContent[]) => {
  if (!isNotEmptyArray(data)) return {};

  return data.reduce((acc, datum) => {
    const key = datum.key?.toLowerCase() || '';
    if (datum.labelId) {
      return {
        ...acc,
        [key]: <Translate id={datum.labelId} data={{ ...getErrorContent(datum.data) }} />,
      };
    } else if (isNotNullish(datum.value)) {
      return {
        ...acc,
        [key]: datum.value,
      };
    }
    return acc;
  }, {} as LabelObjects);
};

export const createErrorLabel = (labelId: string, data?: RootErrorContent[], count?: number) => {
  return <Translate id={labelId} data={{ ...getErrorContent(data) }} count={count} />;
};
