import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  ApplicationTrackerItems,
  ApplicationTrackerErrors,
  UploadResult,
} from 'ApplicationTracker-Types';
import {
  fetchApplicationAsync,
  updateMemoAsync,
  updateEncourageAsync,
  uploadFileAsync,
  exportInExcelFormatAsync,
} from './actions';
import { initialImportResult } from './initialValues';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchApplicationAsync.request, exportInExcelFormatAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchApplicationAsync.success,
      fetchApplicationAsync.failure,
      exportInExcelFormatAsync.success,
      exportInExcelFormatAsync.failure,
    ],
    (state, action) => false,
  );

export const items = createReducer({} as ApplicationTrackerItems).handleAction(
  fetchApplicationAsync.success,
  (state, action) => action.payload,
);

export const uploadResult = createReducer(initialImportResult as UploadResult).handleAction(
  uploadFileAsync.success || uploadFileAsync.failure,
  (state, action) => {
    return action.payload;
  },
);

export const errors = createReducer([] as ApplicationTrackerErrors[]).handleAction(
  fetchApplicationAsync.failure || updateMemoAsync.failure || updateEncourageAsync.failure,
  (state, action) => action.payload,
);

const applicationTrackerReducer = combineReducers({
  isFetching,
  items,
  uploadResult,
  errors,
});

export default applicationTrackerReducer;
export type ApplicationTrackerState = ReturnType<typeof applicationTrackerReducer>;
