export const initialExtraRateDetail = {
  accepted: null,
  ageFrom: null,
  ageTo: null,
  id: undefined,
  includeAsHeadCount: null,
  managementName: undefined,
  rateSettingType: undefined,
  rateUnitType: undefined,
  rates: [],
};
