import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { toQuery } from '@travel/utils';
import {
  Feature,
  Features,
  FeatureStatus,
  BrowseParameters,
  Option,
  IconList,
  FeatureFilter,
  ConsumerFilterFeatureBrowseParameters,
  IconBrowseParameters,
} from 'Feature-Types';
import {
  ApiFetchFeatures,
  ApiSearchFeatures,
  ApiAddFeature,
  ApiUpdateFeature,
  ApiGetFeature,
  ApiFetchIconList,
  ApiFetchFeatureFilters,
  ApiFetchConsumerFilterFeatures,
} from './api';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { ID_TYPE, LAYER } from '../../pages/FeatureCreate/constants';
import { pushLocation } from 'store/__router/actions';
import { filterIdList } from 'constants/rate-plan';
import { transformToQueryObject as paramsToQueryObject } from 'utils/transformToQueryObject';
import { initialBrowseParameter as init } from 'store/feature/initialValues';

export const updateIconBrowseParameter = createStandardAction('UPDATE_ICON_BROWSE_PARAMETER')<
  IconBrowseParameters
>();

export const updateSearchFeatureBrowseParameter = createStandardAction(
  'UPDATE_SEARCH_FEATURE_BROWSE_PARAMETER',
)<BrowseParameters>();

export const updateUpperFeatureBrowseParameter = createStandardAction(
  'UPDATE_UPPER_FEATURE_BROWSE_PARAMETER',
)<BrowseParameters>();

export const fetchFeaturesAsync = createAsyncAction(
  'FETCH_FEATURES_REQUEST',
  'FETCH_FEATURES_SUCCESS',
  'FETCH_FEATURES_FAILURE',
)<undefined, Features, FeatureStatus[]>();

export const searchFeatureAsync = createAsyncAction(
  'SEARCH_FEATURE_REQUEST',
  'SEARCH_FEATURE_SUCCESS',
  'SEARCH_FEATURE_FAILURE',
)<undefined, Features, FeatureStatus[]>();

export const addFeatureAsync = createAsyncAction(
  'ADD_FEATURE_REQUEST',
  'ADD_FEATURE_SUCCESS',
  'ADD_FEATURE_FAILURE',
)<undefined, FeatureStatus, FeatureStatus[]>();

export const updateFeatureAsync = createAsyncAction(
  'UPDATE_FEATURE_REQUEST',
  'UPDATE_FEATURE_SUCCESS',
  'UPDATE_FEATURE_FAILURE',
)<undefined, FeatureStatus, FeatureStatus[]>();

export const fetchFeatureAsync = createAsyncAction(
  'GET_FEATURE_REQUEST',
  'GET_FEATURE_SUCCESS',
  'GET_FEATURE_FAILURE',
)<undefined, Feature, FeatureStatus[]>();

export const fetchIconListAsync = createAsyncAction(
  'GET_ICON_LIST_REQUEST',
  'GET_ICON_LIST_SUCCESS',
  'GET_ICON_LIST_FAILURE',
)<undefined, IconList, FeatureStatus[]>();

export const fetchParentFeaturesAsync = createAsyncAction(
  'GET_PARENT_FEATURES_REQUEST',
  'GET_PARENT_FEATURES_SUCCESS',
  'GET_PARENT_FEATURES_FAILURE',
)<undefined, Features, FeatureStatus[]>();

export const fetchFeatureFiltersAsync = createAsyncAction(
  'FETCH_FEATURE_FILTERS_REQUEST',
  'FETCH_FEATURE_FILTERS_SUCCESS',
  'FETCH_FEATURE_FILTERS_FAILURE',
)<undefined, FeatureFilter, FeatureStatus[]>();

export const fetchConsumerFilterFeaturesAsync = createAsyncAction(
  'FETCH_CUSTOMER_FILTER_FEATURES_REQUEST',
  'FETCH_CUSTOMER_FILTER_FEATURES_SUCCESS',
  'FETCH_CUSTOMER_FILTER_FEATURES_FAILURE',
)<undefined, Features, FeatureStatus[]>();

export const setInitialFeature = createStandardAction('SET_INITIAL_FEATURE')();

export const fetchFeatures = (groupNames: Array<string>): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchFeaturesAsync.request());

  try {
    const res = await ApiFetchFeatures(apiClient, toQuery({ groupNames }));
    dispatch(fetchFeaturesAsync.success(res));
  } catch (error) {
    dispatch(fetchFeaturesAsync.failure([error as FeatureStatus]));
  }
};

export const searchFeatures = <T = { [key: string]: string }>(
  query?: T,
  offset?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(searchFeatureAsync.request());

  try {
    const fetchQuery = {
      ...paramsToQueryObject(query || {}, filterIdList),
      offset: offset || init.offset,
      limit: 30,
    };

    const res = await ApiSearchFeatures(apiClient, fetchQuery);
    dispatch(searchFeatureAsync.success(res));
  } catch (error) {
    dispatch(searchFeatureAsync.failure([error as FeatureStatus]));
  }
};

export const searchCorrespondingFeatures = (
  featureId?: string,
  idType?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { feature } = getState();
  dispatch(searchFeatureAsync.request());

  try {
    const browseParameters = feature.searchBrowseParameters;
    const res = await ApiSearchFeatures(
      apiClient,
      transformToQueryObjectSearch(browseParameters, featureId, idType),
    );
    dispatch(searchFeatureAsync.success(res));
  } catch (error) {
    dispatch(searchFeatureAsync.failure([error as FeatureStatus]));
  }
};

export const fetchParentFeatures = (featureId?: string, idType?: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const { feature } = getState();
  dispatch(fetchParentFeaturesAsync.request);

  try {
    const res = await ApiSearchFeatures(
      apiClient,
      transformToQueryObjectSearch(
        feature.upperFeatureBrowseParameters,
        featureId,
        idType,
        LAYER.PARENT,
      ),
    );
    dispatch(fetchParentFeaturesAsync.success(res));
  } catch (error) {
    dispatch(fetchParentFeaturesAsync.failure([error as FeatureStatus]));
  }
};

export const fetchConsumerFilterFeatures = (id?: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const { feature } = getState();

  dispatch(fetchConsumerFilterFeaturesAsync.request());

  try {
    const res = await ApiFetchConsumerFilterFeatures(
      apiClient,
      transformToConsumerFilterFeatureQueryObject(
        feature.consumerFilterFeaturesBrowseParameters,
        id,
      ),
    );
    dispatch(fetchConsumerFilterFeaturesAsync.success(res));
  } catch (error) {
    dispatch(fetchConsumerFilterFeaturesAsync.failure([error as FeatureStatus]));
  }
};

export const updateConsumerFilterFeatureBrowseParameter = createStandardAction(
  'UPDATE_CUSTOMER_FILTER_FEATURE_BROWSE_PARAMETER',
)<ConsumerFilterFeatureBrowseParameters>();

export const addFeature = (newFeature: Feature): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(addFeatureAsync.request());

  try {
    const res = await ApiAddFeature(apiClient, newFeature);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(addFeatureAsync.success(res));
    if (res.id) {
      dispatch(pushLocation(`/internal/feature/${res.id}`));
    }
  } catch (error) {
    dispatch(addFeatureAsync.failure([error as FeatureStatus]));
  }
};

export const updateFeature = (newFeature: Feature): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(updateFeatureAsync.request());

  try {
    const res = await ApiUpdateFeature(apiClient, newFeature);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(updateFeatureAsync.success(res));
  } catch (error) {
    dispatch(updateFeatureAsync.failure([error as FeatureStatus]));
  }
};

export const fetchFeature = (id: string): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchFeatureAsync.request());
  try {
    const res = await ApiGetFeature(apiClient, id);
    dispatch(fetchFeatureAsync.success(res));
  } catch (error) {
    dispatch(fetchFeatureAsync.failure([error as FeatureStatus]));
  }
};

export const fetchFeatureFilters = (providerId: string, objectType: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchFeatureFiltersAsync.request());
  try {
    const res = await ApiFetchFeatureFilters(apiClient, providerId, objectType);
    dispatch(fetchFeatureFiltersAsync.success(res));
  } catch (error) {
    dispatch(fetchFeatureFiltersAsync.failure([error as FeatureStatus]));
  }
};

export const fetchIconList = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { feature } = getState();
  dispatch(searchFeatureAsync.request());

  try {
    const res = await ApiFetchIconList(
      apiClient,
      transformToIconQueryObject(feature.iconBrowseParameters, false),
    );
    dispatch(fetchIconListAsync.success(res));
  } catch (error) {
    dispatch(fetchIconListAsync.failure([error as FeatureStatus]));
  }
};

/** Query constructor for Icon Filter and Search values */
function transformToIconQueryObject(params: IconBrowseParameters, hasFilter: boolean) {
  let builtQuery = { ...params };

  const parseStatus = (options: Array<Option>) => {
    const status = [] as boolean[];
    options.forEach(option => {
      if (option.value === 'active' && option.isCheck) {
        status.push(true);
      } else if (option.value === 'inactive' && option.isCheck) {
        status.push(false);
      }
    });
    return status;
  };

  if (hasFilter) {
    const getStatus = params.filter.filter(item => item.id === 'status');
    const filter = {
      status: parseStatus(getStatus[0].options),
    };

    builtQuery = Object.assign({}, params, { filter });
  }
  return toQuery(builtQuery);
}

function transformToQueryObjectSearch(
  params: BrowseParameters,
  id?: string,
  idType?: string,
  layer?: string,
) {
  const parseStatus = (options: Array<Option>) => {
    const status = [] as boolean[];
    options.forEach(option => {
      if (option.value === 'selected' && option.isCheck) {
        status.push(true);
      } else if (option.value === 'deselected' && option.isCheck) {
        status.push(false);
      }
    });
    return status;
  };

  const generateFilter = () => {
    if (idType === ID_TYPE.GROUP) {
      const groupFilter = {
        selected: parseStatus(params.filter[0].options),
        groupId: id,
      };
      return groupFilter;
    } else {
      const featureFilter = {
        selected: parseStatus(params.filter[0].options),
        featureId: id,
        layer,
      };
      return featureFilter;
    }
  };

  const parseSort = () => {
    const selectedSort = params.sort.options[params.sort.selectedValue].value;
    const [sortType, value] = selectedSort.split('=');

    return {
      [sortType]: value,
    };
  };

  const filter = generateFilter();

  const builtQuery = Object.assign({}, params, { filter, sort: parseSort() });
  return toQuery(builtQuery);
}

function transformToConsumerFilterFeatureQueryObject(
  params: ConsumerFilterFeatureBrowseParameters,
  id?: string,
) {
  const parseFilter = () => {
    const filter = params.filter;
    let objectTypes = [] as string[];
    let selecteds = [] as string[];
    filter.forEach(f => {
      f.options.forEach((option: Option) => {
        if (f.id === 'objectType' && option.isCheck) {
          objectTypes.push(option.value);
        }
        if (f.id === 'selected' && option.isCheck) {
          selecteds.push(option.value);
        }
      });
    });
    return {
      objectType: objectTypes,
      selected: selecteds,
      groupId: id,
    };
  };

  const parseSort = () => {
    const [sortType, value] = params.sort.selectedValue.split('=');

    return {
      [sortType]: value,
    };
  };

  const filter = parseFilter();
  const sort = parseSort();
  const keyword = params.searchText;
  const searchCondition = params?.searchCondition;
  const builtQuery = Object.assign(
    {},
    params,
    { filter },
    { sort },
    { keyword },
    { searchCondition },
  );
  return toQuery(builtQuery);
}
