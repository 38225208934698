import { UserSegments } from 'Segments-Types';
import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { fetchSegments } from './api';

export const fetchSegmentsAsync = createAsyncAction(
  'FETCH_SEGMENTS_REQUEST',
  'FETCH_SEGMENTS_SUCCESS',
  'FETCH_SEGMENTS_FAILURE',
)<undefined, UserSegments, undefined>();

export const fetchSegmentItems = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchSegmentsAsync.request());
  const res = await fetchSegments(apiClient);
  dispatch(fetchSegmentsAsync.success(res));
};
