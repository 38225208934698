import { universalRouterProps } from 'core/universalRouter/types';
import { fetchNotificationDetail, clearNotification } from 'store/notification/actions';

export type URLParams = {
  params: { providerId: string; notificationId: string };
  query: { [key: string]: string };
};

export default (
  { store: { dispatch } }: universalRouterProps,
  { params: { providerId, notificationId }, query }: URLParams,
) => {
  let promises = [];
  if (notificationId) {
    promises.push(dispatch(fetchNotificationDetail(notificationId)));
  } else {
    dispatch(clearNotification());
  }

  return Promise.all(promises);
};
