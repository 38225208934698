import env from '@travel/env';

const apiServerName = env('UNIVERSAL_ENV_NAME') || '';
const NON_PROD_ENVS = ['DEV', 'STA', 'STB', 'STC', 'PRF', 'QMT', 'QAT'];

export const isNotProdServer = NON_PROD_ENVS.includes(apiServerName);

export const getEnvNameIfNotProd: () => string = () => {
  if (isNotProdServer) {
    return apiServerName;
  }
  return '';
};
