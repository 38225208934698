import { universalRouterProps } from 'core/universalRouter/types';
import {
  fetchExtraRateList,
  fetchExtraRateListPerPerson,
  fetchExtraRateListPerItem,
} from 'store/extraRate/actions';
import { fetchRateControl } from 'store/rateControl/actions';
import { pushLocation } from 'store/__router/actions';
import { RATE_UNIT } from 'constants/rate-plan';

type Location = {
  baseUrl: string;
  params: {
    providerId: string;
  };
  query: {
    ratePlanId: string;
    itemList: string;
  };
};

export default (
  { store: { dispatch, getState } }: universalRouterProps,
  { params, query, baseUrl }: Location,
) => {
  const storedRatePlanId = getState().rateControl.rates?.ratePlanId;
  const { ratePlanId = storedRatePlanId, itemList } = query;
  const providerId = params.providerId;

  if (!ratePlanId || !itemList) pushLocation(`${baseUrl}/plans`);

  const extraRateTemplateFilter = {
    filter: { rateUnitType: RATE_UNIT.PER_PERSON, ageFrom: '', ageTo: '' },
  };
  const rateControlFilter = {
    filter: {
      viewType: 'range',
      roomType: itemList?.split(','),
      year: 2020, // TODO: fixed variable
    },
  };

  const promises = [
    dispatch(fetchExtraRateList(providerId, extraRateTemplateFilter)),
    dispatch(fetchExtraRateListPerPerson(providerId)),
    dispatch(fetchExtraRateListPerItem(providerId)),
    dispatch(fetchRateControl(providerId, ratePlanId, rateControlFilter)),
  ];

  return Promise.all(promises);
};
