import React from 'react';
import { Translate } from 'core/translate';
import { VisualContentGroup, BrowseParameters } from 'VisualContentGroup-Types';

export const initialVisualContentGroup: VisualContentGroup = {
  id: '',
  groupName: '',
  description: '',
  mediaCount: 0,
  order: 0,
  ownerName: 'RAKUTEN',
  mediaList: [],
};

export const initialBrowseParameter: BrowseParameters = {
  keyword: '',
  searchCondition: '',
  filter: [
    {
      label: <Translate id="Visual_Content_Group.List.Filter.Owner" />,
      id: 'owner',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Expected_Object_Type.Provider" />,
          value: 'Provider',
          isCheck: true,
        },
        {
          label: <Translate id="Supplier_Enumeration.Media.Owner.Rakuten" />,
          value: 'Rakuten',
          isCheck: true,
        },
      ],
    },
  ],
  offset: 0,
  limit: 30,
};
