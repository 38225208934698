import { universalRouterProps } from 'core/universalRouter/types';
import { fetchChannelManager, fetchChannelManagers } from '../../store/channelManager/actions';
import {
  fetchChannelManagerBmcProviderList,
  fetchChannelManagerAriProviderList,
  clearDisconnectedProviderId,
} from '../../store/channelManagerProviderList/actions';
import { Query } from 'utils/transformToQueryObject';

type Location = {
  params: {
    channelManagementId: string;
    type: string;
  };
  query: Query;
};

export default (
  { store: { dispatch } }: universalRouterProps,
  { params: { channelManagementId }, query }: Location,
) => {
  return Promise.all([
    dispatch(fetchChannelManagers({})),
    dispatch(fetchChannelManager(channelManagementId)),
    dispatch(fetchChannelManagerBmcProviderList(channelManagementId, query)),
    dispatch(fetchChannelManagerAriProviderList(channelManagementId, query)),
    dispatch(clearDisconnectedProviderId()),
  ]);
};
