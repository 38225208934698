import { createAsyncAction, AppThunk, createStandardAction } from 'typesafe-actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { ApiFetchServiceTypes } from './api';
import { ServiceTypes, ServiceTypesErrors } from 'SupplierService-Types';
import { GlobalErrorDialogResponse } from 'GlobalErrorDialog-Types';

export const fetchServiceTypesAsync = createAsyncAction(
  'FETCH_SERVICE_TYPES_REQUEST',
  'FETCH_SERVICE_TYPES_SUCCESS',
  'FETCH_SERVICE_TYPES_FAILURE',
)<undefined, ServiceTypes, ServiceTypesErrors[]>();

export const setServiceTypesStandard = createStandardAction('SET_SERVICE_TYPES')<
  ServiceTypes | []
>();

export const fetchServiceTypes = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchServiceTypesAsync.request());
  try {
    const res = await ApiFetchServiceTypes(apiClient);
    dispatch(fetchServiceTypesAsync.success(res));
  } catch (err) {
    dispatch(fetchServiceTypesAsync.failure(err as ServiceTypesErrors[]));
    dispatch(fetchGlobalErrorDialogAsync.success(err as GlobalErrorDialogResponse));
  }
};

export const setServiceTypes = (data: ServiceTypes): AppThunk => async dispatch => {
  dispatch(setServiceTypesStandard(data));
};
