import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import {
  RatePlan,
  RatePlanDetail,
  ApiStatus,
  BulkStatusUpdate,
  RatePlanErrors,
  ApplicableRatePlanBrowseParameters,
  FeatureFilterValues,
  DeletableResult,
} from 'RatePlan-Types';
import {
  ApiFetchRatePlanList,
  ApiFetchMasterRatePlanList,
  ApiFetchRatePlanDetail,
  ApiDeleteRatePlan,
  ApiDeletableRatePlan,
  ApiUpdateRatePlan,
  ApiSubmitNewRatePlan,
  ApibulkStatusUpdate,
} from './apis';
import { filterIdList } from 'constants/rate-plan';
import { transformToQueryObject } from 'utils/transformToQueryObject';
import { pushLocation } from 'store/__router/actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

export const setInitialPlanDetail = createStandardAction('SET_INITIAL_PLAN_DETAIL')();

export const updateRatePlanBrowseParameter = createStandardAction(
  'UPDATE_APPLICABLE_RATE_PLANS_BROWSE_PARAMETER',
)<ApplicableRatePlanBrowseParameters>();

export const updateFeatureFilterBrowseParameters = createStandardAction(
  'UPDATE_FEATURE_FILTER_BROWSE_PARAMETER',
)<FeatureFilterValues>();

export const clearFeatureFilterBrowseParamaters = createStandardAction(
  'CLEAR_FEATURE_FILTER_BROWSE_PARAMETER',
)();

export const clearCurrentRatePlanList = createStandardAction('CLEAR_RATE_PLAN_LIST')();

export const fetchRatePlansAsync = createAsyncAction(
  'FETCH_RATE_PLANS_REQUEST',
  'FETCH_RATE_PLANS_SUCCESS',
  'FETCH_RATE_PLANS_FAILURE',
)<undefined, RatePlan, RatePlanErrors[]>();

export const fetchMasterRatePlansAsync = createAsyncAction(
  'FETCH_MASTER_RATE_PLANS_REQUEST',
  'FETCH_MASTER_RATE_PLANS_SUCCESS',
  'FETCH_MASTER_RATE_PLANS_FAILURE',
)<undefined, RatePlan, RatePlanErrors[]>();

export const fetchDisplayOrderAsync = createAsyncAction(
  'FETCH_DISPLAY_ORDER_REQUEST',
  'FETCH_DISPLAY_ORDER_SUCCESS',
  'FETCH_DISPLAY_ORDER_FAILURE',
)<undefined, RatePlan, RatePlanErrors[]>();

export const fetchRatePlanDetailAsync = createAsyncAction(
  'FETCH_RATE_PLAN_DETAIL_REQUEST',
  'FETCH_RATE_PLAN_DETAIL_SUCCESS',
  'FETCH_RATE_PLAN_DETAIL_FAILURE',
)<undefined, RatePlanDetail, RatePlanErrors[]>();

export const createRatePlanAsync = createAsyncAction(
  'CREATE_RATE_PLAN_REQUEST',
  'CREATE_RATE_PLAN_SUCCESS',
  'CREATE_RATE_PLAN_FAILURE',
)<undefined, ApiStatus, RatePlanErrors[]>();

export const updateRatePlanAsync = createAsyncAction(
  'UPDATE_RATE_PLAN_REQUEST',
  'UPDATE_RATE_PLAN_SUCCESS',
  'UPDATE_RATE_PLAN_FAILURE',
)<undefined, ApiStatus, RatePlanErrors[]>();

export const deleteRatePlanAsync = createAsyncAction(
  'DELETE_RATE_PLAN_REQUEST',
  'DELETE_RATE_PLAN_SUCCESS',
  'DELETE_RATE_PLAN_FAILURE',
)<undefined, ApiStatus, RatePlanErrors[]>();

export const deletableRatePlanAsync = createAsyncAction(
  'DELETABLE_RATE_PLAN_REQUEST',
  'DELETABLE_RATE_PLAN_SUCCESS',
  'DELETABLE_RATE_PLAN_FAILURE',
)<undefined, undefined, undefined>();

export const bulkStatusUpdateAsync = createAsyncAction(
  'BULK_STATUS_UPDATE_REQUEST',
  'BULK_STATUS_UPDATE_SUCCESS',
  'BULK_STATUS_UPDATE_FAILURE',
)<undefined, ApiStatus, RatePlanErrors[]>();

export const fetchRatePlanList = (
  providerId: string,
  queryParameter: { [key: string]: string | string[] },
  offset?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const fetchQuery = {
    ...transformToQueryObject(queryParameter, filterIdList),
    offset: offset || 0,
    limit: 30,
  };

  dispatch(fetchRatePlansAsync.request());
  const res = await ApiFetchRatePlanList(apiClient, providerId, fetchQuery);
  dispatch(fetchRatePlansAsync.success(res));
};

export const fetchMasterRatePlanList = (
  providerId: string,
  queryParameter: { [key: string]: string | string[] },
  offset?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const fetchQuery = {
    ...transformToQueryObject(queryParameter, filterIdList),
    offset: offset || 0,
    limit: 30,
  };

  dispatch(fetchMasterRatePlansAsync.request());
  try {
    const res = await ApiFetchMasterRatePlanList(apiClient, providerId, fetchQuery);
    dispatch(fetchMasterRatePlansAsync.success(res));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
    dispatch(fetchMasterRatePlansAsync.failure(err));
  }
};

export const createRatePlan = (providerId: string, data: RatePlanDetail): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(createRatePlanAsync.request());
  try {
    const res = await ApiSubmitNewRatePlan(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(createRatePlanAsync.success(res));

    if (res.id) {
      dispatch(pushLocation(`/provider/${providerId}/ratePlans/${res.id}`));
    }
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    dispatch(createRatePlanAsync.failure(err));
  }
};

export const fetchRatePlanDetail = (providerId: string, ratePlanId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchRatePlanDetailAsync.request());
  const res = await ApiFetchRatePlanDetail(apiClient, providerId, ratePlanId);
  dispatch(fetchRatePlanDetailAsync.success(res));
};

export const updateRatePlan = (
  providerId: string,
  data: RatePlanDetail,
  search?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateRatePlanAsync.request());
  try {
    const res = await ApiUpdateRatePlan(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(updateRatePlanAsync.success(res));

    if (res.status === 200) {
      dispatch(
        pushLocation({
          pathname: `/provider/${providerId}/ratePlans/${data.id}`,
          state: {
            prevSearch: search,
          },
        }),
      );
    }
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    dispatch(updateRatePlanAsync.failure(err));
  }
};

export const deleteRatePlanDetail = (
  providerId: string,
  ratePlanId: string,
  ratePlanName: string,
): AppThunk<Promise<ApiStatus | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteRatePlanAsync.request());
  const res = await ApiDeleteRatePlan(apiClient, providerId, ratePlanId);
  try {
    // Pass the deleted object name as message
    res.message = ratePlanName;

    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteRatePlanAsync.success(res));

    if (res.status === 200) {
      dispatch(fetchRatePlanList(providerId, {}));
    }
    return res;
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
    dispatch(deleteRatePlanAsync.failure(err));
  }
};

export const fetchDeletable = (
  providerId: string,
  ratePlanId: string,
): AppThunk<Promise<DeletableResult | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(deletableRatePlanAsync.request());
  try {
    const res = await ApiDeletableRatePlan(apiClient, providerId, ratePlanId);
    if (res.status === 200) {
      dispatch(deletableRatePlanAsync.success());
      return { isDeletable: true };
    } else if (res.status === 403) {
      dispatch(deletableRatePlanAsync.success());
      return { isDeletable: false };
    } else {
      throw res;
    }
  } catch (error) {
    dispatch(deletableRatePlanAsync.failure());
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
    throw error;
  }
};

export const bulkStatusUpdate = (
  providerId: string,
  data: BulkStatusUpdate,
  queryParameter: { [key: string]: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(bulkStatusUpdateAsync.request());
  const res = await ApibulkStatusUpdate(apiClient, providerId, data);

  dispatch(fetchGlobalErrorDialogAsync.success(res));

  if (res.status === 200) {
    dispatch(fetchRatePlanList(providerId, queryParameter));
    dispatch(bulkStatusUpdateAsync.success(res));
  }
};
