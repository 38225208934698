import {
  Reviews,
  ProviderReviewDetail,
  ReviewParams,
  ReviewDetail,
  ApiStatus,
  ReplyReview,
  Analytics,
} from 'Reviews-Types';
import { ApiClient } from '@travel/api-client';

export const ApiFetchReviews = (http: ApiClient, params: ReviewParams) =>
  http.get(`/reviews/extranet`, { values: params }).then((data: Reviews) => data);

export const ApiFetchReview = (http: ApiClient, reviewId: string) =>
  http.get(`/reviews/extranet/${reviewId}`).then((data: ReviewDetail) => data);

export const ApiReplyReview = (http: ApiClient, reviewId: string, values: ReplyReview) =>
  http.post(`/reviews/extranet/${reviewId}/reply`, { values }).then((data: ApiStatus) => data);

export const ApiFetchProviderReviewScore = (http: ApiClient) =>
  http.get(`/reviews/extranet/providerInfo`).then((data: ProviderReviewDetail) => data);

export const ApiFetchProviderScoreAnalytics = (http: ApiClient, params: ReviewParams) =>
  http.get(`/providerScores/analytics`, { values: params }).then((data: Analytics) => data);
