import { universalRouterProps } from 'core/universalRouter/types';
import { searchFeatures } from 'store/feature/actions';
import { sort as initSort } from 'store/feature/initialValues';

type Query = { [field: string]: string };

export default ({ store: { dispatch } }: universalRouterProps, query: Query) => {
  // TODO: [Refactor] query should be like query.sort.name=asc. Otherwise we have to handle like this:
  if (!query.name && !query.score) {
    // TODO: [Refactor] change initSort.selectedValue from 0 to name=asc. type change is needed, but complex.
    const [key, value] = initSort.options[0].value.split('=');
    query[key] = value;
  }
  return Promise.all([dispatch(searchFeatures(query))]);
};
