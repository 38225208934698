import { ApiClient } from '@travel/api-client';
import {
  APIResponse,
  ReservationSendMessageRequest,
  ReservationMessageForm,
  ReservationMessagePreview,
  ReservationMessageList,
} from 'BookingMessage-Types';
import { notification } from 'constants/api-prefix';

export const ApiSendMessage = (
  http: ApiClient,
  reservationId: string,
  values: ReservationSendMessageRequest,
): Promise<APIResponse> =>
  http.post(`${notification}/reservations/${reservationId}/messages/send`, { values });

export const ApiFetchMessageList = (
  http: ApiClient,
  reservationId: string,
): Promise<ReservationMessageList> =>
  http.get(`${notification}/reservations/${reservationId}/messages`);

export const ApiFetchMessagePreview = (
  http: ApiClient,
  reservationId: string,
  values: ReservationMessageForm,
): Promise<ReservationMessagePreview> => {
  const url = `${notification}/reservations/${reservationId}/messages/template`;
  return http.post(url, { values });
};

export const ApiFetchSentMessagePreview = (
  http: ApiClient,
  reservationId: string,
  messageId: string,
): Promise<ReservationMessagePreview> => {
  const url = `${notification}/reservations/${reservationId}/messages/${messageId}/template`;
  return http.get(url);
};
