import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { LastUsedValueErrors } from 'LastUsedValue-Types';
import { clearLastUsedValue, fetchLastUsedValueAsync } from './actions';

export const content = createReducer({} as unknown)
  .handleAction(clearLastUsedValue, () => null)
  .handleAction(fetchLastUsedValueAsync.success, (state, action) => action.payload);

export const errors = createReducer([] as LastUsedValueErrors[]).handleAction(
  fetchLastUsedValueAsync.failure,
  (state, action) => action.payload,
);

const lastUsedValueReducer = combineReducers({
  content,
  errors,
});

export default lastUsedValueReducer;
export type LastUsedValueState = ReturnType<typeof lastUsedValueReducer>;
