import { createAsyncAction, AppThunk } from 'typesafe-actions';

import { ApplicationDetail, Error, RatePlanSuggestion } from 'ApplicationDetail-Types';
import {
  ApiFetchApplicationDetail,
  ApiFetchRatePlanSuggestion,
  ApiUpdateRatePlanSuggestion,
} from './apis';

export const fetchApplicationDetailAsync = createAsyncAction(
  'FETCH_APPLICATION_DETAIL_REQUEST',
  'FETCH_APPLICATION_DETAIL_SUCCESS',
  'FETCH_APPLICATION_DETAIL_FAILURE',
)<undefined, ApplicationDetail, Error[]>();

export const fetchRatePlanSuggestionAsync = createAsyncAction(
  'FETCH_RATEPLAN_SUGGESTION_REQUEST',
  'FETCH_RATEPLAN_SUGGESTION_SUCCESS',
  'FETCH_RATEPLAN_SUGGESTION_FAILURE',
)<undefined, RatePlanSuggestion[], Error[]>();

export const updateRatePlanSuggestionAsync = createAsyncAction(
  'UPDATE_RATEPLAN_SUGGESTION_REQUEST',
  'UPDATE_RATEPLAN_SUGGESTION_SUCCESS',
  'UPDATE_RATEPLAN_SUGGESTION_FAILURE',
)<undefined, undefined, Error[]>();

export const fetchApplicationDetail = (
  campaignId: string,
  applicationId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchApplicationDetailAsync.request());

  try {
    const response = await ApiFetchApplicationDetail(apiClient, campaignId, applicationId);
    dispatch(fetchApplicationDetailAsync.success(response));
  } catch (error) {
    dispatch(fetchApplicationDetailAsync.failure([error]));
  }
};

export const fetchRatePlanSuggestion = (
  campaignId: string,
  applicationId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchRatePlanSuggestionAsync.request());
  try {
    const response = await ApiFetchRatePlanSuggestion(apiClient, campaignId, applicationId);
    dispatch(fetchRatePlanSuggestionAsync.success(response));
  } catch (error) {
    dispatch(fetchRatePlanSuggestionAsync.failure([error]));
  }
};

export const updateRatePlanSuggestion = (
  campaignId: string,
  applicationId: string,
  data: RatePlanSuggestion[],
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    await ApiUpdateRatePlanSuggestion(apiClient, campaignId, applicationId, data);
    dispatch(fetchRatePlanSuggestion(campaignId, applicationId));
    dispatch(fetchApplicationDetail(campaignId, applicationId));
  } catch (err) {
    dispatch(updateRatePlanSuggestionAsync.failure(err));
  }
};
