import { isNotEmptyArray } from '@travel/utils';

export const parseInputLanguage = (
  str: string,
  isGetInputLang: boolean,
  currentBasePath?: string,
) => {
  if (str && !str.includes('#')) {
    return str;
  }

  const langStr = str?.split('#') || [];
  const inputLang = langStr?.length > 1 ? langStr[0] : langStr.join('');
  const currentLink = langStr?.length > 1 ? langStr[1] : '';
  const uiLang = langStr?.length > 2 ? langStr[2] : langStr.join('');

  const isInternal = currentBasePath?.includes('/internal');
  const currentBasePathStr = currentBasePath?.split('/') || [];
  const currentMgmtPage =
    currentBasePathStr?.length > 1
      ? isInternal
        ? currentBasePathStr[2]
        : currentBasePathStr[3]
      : currentBasePathStr.join('');

  const isProvider = isNotEmptyArray(currentBasePathStr) && currentBasePathStr[1] === 'provider';

  if (isGetInputLang || isProvider || `/${currentMgmtPage}` === currentLink) {
    return inputLang;
  }

  return uiLang;
};
