import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { LanguagesItems, LanguagesErrors } from 'Languages-Types';
import { fetchLanguagesAsync, setInputLanguageAsync, setIsDataInputLanguageAllow } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchLanguagesAsync.request], (state, action) => true)
  .handleAction(
    [fetchLanguagesAsync.success, fetchLanguagesAsync.failure],
    (state, action) => false,
  );

export const items = createReducer([] as LanguagesItems[]).handleAction(
  fetchLanguagesAsync.success,
  (state, action) => action.payload.items,
);

export const errors = createReducer([] as LanguagesErrors[]).handleAction(
  fetchLanguagesAsync.failure,
  (state, action) => action.payload,
);

export const inputLanguage = createReducer('' as string).handleAction(
  setInputLanguageAsync.success,
  (_state, action) => action.payload,
);

export const isDataInputLanguageAllow = createReducer(false as boolean).handleAction(
  setIsDataInputLanguageAllow,
  (state, action) => action.payload,
);

const languagesReducer = combineReducers({
  isFetching,
  items,
  errors,
  inputLanguage,
  isDataInputLanguageAllow,
});

export default languagesReducer;
export type LanguagesState = ReturnType<typeof languagesReducer>;
