import { FilterGroup } from 'RatePlan-Types';
import { isNotEmptyArray } from '@travel/utils';

const getFilterObjectQuery = (filterList: Array<FilterGroup>) => {
  const searchQueryParams = filterList
    .reduce((acc: string[], filter) => {
      const uncheckedFilter = filter.options.filter(({ isCheck }) => !isCheck);
      if (isNotEmptyArray(uncheckedFilter)) {
        const checkedFilter = filter.options.filter(({ isCheck }) => isCheck);
        if (isNotEmptyArray(checkedFilter) && checkedFilter.length <= filter.options.length) {
          acc.push(
            `filter.${filter.id}=${checkedFilter.map((item, index) => item.value).join(',')}`,
          );
        } else if (checkedFilter.length === 0) {
          acc.push(`filter.${filter.id}=`);
        }
      }
      return acc;
    }, [])
    .filter(queryParam => queryParam !== '')
    .join('&');

  return searchQueryParams;
};

export default getFilterObjectQuery;
