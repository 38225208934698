import { TokenInfo } from 'Login-Types';
import { getProviderRelatedIdInUrl } from './urlHelper';

export const ACCOUNT_TYPE = {
  INTERNAL: 'internal',
  PROVIDER: 'provider',
  GROUP: 'provider-group',
};

export const RFB_USER_ROLE = {
  OWNER: 'Service Owner',
  ADMIN: 'Subscription Admin',
  STAFF: 'Subscription Staff',
} as const;

export type RFBUserRoleType = typeof RFB_USER_ROLE[keyof typeof RFB_USER_ROLE];

export const getIsRFBUser = (loginSchema?: string) => {
  return loginSchema === 'omni';
};

export const getShouldCallSwitch = (verifyToken: TokenInfo, path: string) => {
  const providerIdInUrl = getProviderRelatedIdInUrl(path);
  const accountType = verifyToken.accountType;

  if (!!verifyToken.apicErrorCode) {
    return false;
  }

  if (path.match(/^\/provider\/(.*)$/)) {
    if (accountType === ACCOUNT_TYPE.INTERNAL || accountType === ACCOUNT_TYPE.GROUP) {
      return providerIdInUrl !== verifyToken.providerId;
    }
  } else if (path.match(/^\/providerGroup\/(.*)$/)) {
    if (accountType === ACCOUNT_TYPE.INTERNAL) {
      return !!verifyToken.providerId || providerIdInUrl !== verifyToken.providerGroupId;
    } else if (accountType === ACCOUNT_TYPE.GROUP) {
      return !!verifyToken.providerId && providerIdInUrl === verifyToken.providerGroupId;
    }
  }

  return false;
};

export const getShouldNavigateInternalLogin = (verifyToken: TokenInfo, path: string) => {
  const accountType = verifyToken.accountType;
  if (accountType === ACCOUNT_TYPE.INTERNAL && path.match(/^\/internal\/(.*)$/)) {
    return !!verifyToken.providerId || !!verifyToken.providerGroupId;
  }
  return false;
};
