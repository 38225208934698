export const itemInitialState = {
  id: null,
  code: '',
  name: '',
  features: [],
  featureIds: [],
  description: '',
  rateDetail: {
    guestCapacity: {
      maxGuestsNumber: null,
      minGuestsNumber: null,
      maxAdultsNumber: null,
      minAdultsNumber: null,
      minChildrenNumber: null,
      maxChildrenNumber: null,
    },
  },
  size: {},
  media: [],
  active: true,
  lastUpdate: '',
};
export const bulkInitialState = {
  references: [
    {
      id: '',
      type: '',
    },
  ],
  featureGroups: [
    {
      id: '',
      features: [
        {
          id: '',
        },
      ],
    },
  ],
};

export const createFeatureGroupInitial = {
  presetGroupName: '',
  featureGroup: {
    id: '',
    features: [
      {
        id: '',
      },
    ],
  },
};
