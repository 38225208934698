import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import { GlobalMarketItem, MarketItems, MarketsErrors } from 'SupplierMarkets-Types';
import { fetchAll } from './api';
import { isNotEmptyArray } from '@travel/utils';

export const fetchMarketsAsync = createAsyncAction(
  'FETCH_MARKETS_REQUEST',
  'FETCH_MARKETS_SUCCESS',
  'FETCH_MARKETS_FAILURE',
)<undefined, MarketItems, MarketsErrors[]>();

export const setGlobalMarkets = createStandardAction('SET_GLOBAL_MARKETS_LIST')<GlobalMarketItem>();

export const setMarket = createStandardAction('SET_MARKETS_LIST')<MarketItems>();

export const fetchMarkets = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { markets } = getState();
  // will not re-fetch when receive successful response
  if (isNotEmptyArray(markets.items)) return;

  dispatch(fetchMarketsAsync.request());

  try {
    const response = await fetchAll(apiClient);
    dispatch(fetchMarketsAsync.success(response));
  } catch (error) {
    dispatch(fetchMarketsAsync.failure([error as MarketsErrors]));
  }
};
