import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import {
  IntroductionContent,
  IntroductionContentArticle,
  IntroductionContentStatus,
} from 'IntroductionContent-Types';
import {
  ApiFetchIntroductionContent,
  ApiPostIntroductionContentArticle,
  ApiPutIntroductionContentArticle,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';

export const fetchIntroductionContentAsync = createAsyncAction(
  'FETCH_INTRODUCTION_CONTENT_REQUEST',
  'FETCH_INTRODUCTION_CONTENT_SUCCESS',
  'FETCH_INTRODUCTION_CONTENT_FAILURE',
)<undefined, IntroductionContent, IntroductionContentStatus[]>();

export const setIntroductionData = createStandardAction('SET_INTRODUCTION_DATA')<
  IntroductionContentArticle
>();

export const postIntroductionContentArticleAsync = createAsyncAction(
  'POST_INTRODUCTION_CONTENT_ARTICLE_REQUEST',
  'POST_INTRODUCTION_CONTENT_ARTICLE_SUCCESS',
  'POST_INTRODUCTION_CONTENT_ARTICLE_FAILURE',
)<undefined, IntroductionContentStatus, IntroductionContentStatus[]>();

export const putIntroductionContentArticleAsync = createAsyncAction(
  'PUT_INTRODUCTION_CONTENT_ARTICLE_REQUEST',
  'PUT_INTRODUCTION_CONTENT_ARTICLE_SUCCESS',
  'PUT_INTRODUCTION_CONTENT_ARTICLE_FAILURE',
)<undefined, IntroductionContentStatus, IntroductionContentStatus[]>();

export const fetchIntroductionContent = (providerId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchIntroductionContentAsync.request());
  try {
    const res = await ApiFetchIntroductionContent(apiClient, { type: 'INTRODUCTION' }, providerId);
    dispatch(fetchIntroductionContentAsync.success(res));
  } catch (errors) {
    dispatch(fetchIntroductionContentAsync.failure([errors as IntroductionContentStatus]));
  }
};

export const postIntroductionContentArticle = (
  providerId: string,
  data: IntroductionContentArticle,
  pathname: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(postIntroductionContentArticleAsync.request());
    const res = await ApiPostIntroductionContentArticle(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(postIntroductionContentArticleAsync.success(res));
    dispatch(pushLocation({ pathname }));
  } catch (errors) {
    dispatch(postIntroductionContentArticleAsync.failure([errors as IntroductionContentStatus]));
  }
};

export const putIntroductionContentArticle = (
  providerId: string,
  data: IntroductionContentArticle,
  pathname: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(putIntroductionContentArticleAsync.request());
    const res = await ApiPutIntroductionContentArticle(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putIntroductionContentArticleAsync.success(res));
    dispatch(pushLocation({ pathname }));
  } catch (errors) {
    dispatch(putIntroductionContentArticleAsync.failure([errors as IntroductionContentStatus]));
  }
};
