import { universalRouterProps } from 'core/universalRouter/types';
import { fetchPlaceDetail, fetchPlaceCategories, clearPlaceDetail } from 'store/place/actions';
import { fetchVisualContentGroupList } from 'store/visualContentGroup/actions';
import { getIsCreateAccess } from 'utils/userScopeHelper';

type Location = {
  params: {
    placeId?: string;
    parentId?: string;
  };
};

export default ({ store }: universalRouterProps, location: Location) => {
  const { placeId = '', parentId = '' } = location.params;
  const { dispatch } = store;
  const { permissions } = store.getState()?.accessControl?.loggedInUser || { permissions: [] };
  const hasMediaAccess = getIsCreateAccess(permissions, 'MediaMgmt');

  let promises: Array<Promise<void>> = [];
  if (placeId || parentId) {
    promises = [dispatch(fetchPlaceDetail(placeId || parentId)), dispatch(fetchPlaceCategories())];
  } else {
    dispatch(clearPlaceDetail());
    promises = [dispatch(fetchPlaceCategories())];
  }

  if (hasMediaAccess) {
    promises.push(dispatch(fetchVisualContentGroupList('', {})));
  }

  return Promise.all(promises);
};
