import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  ChannelManagerProviderSearchBody,
  ChannelManagerProviderListErrors,
  DisconnectedProvider,
} from 'ChannelManagerProviderList-Types';
import {
  fetchChannelManagerBmcProviderListAsync,
  fetchChannelManagerAriProviderListAsync,
  setDisconnectedProviderId,
  disconnectBmcProviderAsync,
  clearDisconnectedProviderId,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchChannelManagerBmcProviderListAsync.request,
      fetchChannelManagerAriProviderListAsync.request,
      disconnectBmcProviderAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchChannelManagerBmcProviderListAsync.success,
      fetchChannelManagerBmcProviderListAsync.failure,
      fetchChannelManagerAriProviderListAsync.success,
      fetchChannelManagerAriProviderListAsync.failure,
      disconnectBmcProviderAsync.success,
      disconnectBmcProviderAsync.failure,
    ],
    () => false,
  );

export const bmcProviders = createReducer({} as ChannelManagerProviderSearchBody).handleAction(
  fetchChannelManagerBmcProviderListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const ariProviders = createReducer({} as ChannelManagerProviderSearchBody).handleAction(
  fetchChannelManagerAriProviderListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const errors = createReducer([] as ChannelManagerProviderListErrors[]).handleAction(
  fetchChannelManagerBmcProviderListAsync.failure,
  (_state, action) => action.payload,
);

export const disconnectedProviders = createReducer([] as DisconnectedProvider[])
  .handleAction(setDisconnectedProviderId, (_state, action) => action.payload)
  .handleAction([clearDisconnectedProviderId], () => []);

const channelManagerProviderListReducer = combineReducers({
  isFetching,
  bmcProviders,
  ariProviders,
  disconnectedProviders,
  errors,
});

export default channelManagerProviderListReducer;
export type ChannelManagerProviderListState = ReturnType<typeof channelManagerProviderListReducer>;
