import { createAsyncAction, AppThunk, createStandardAction } from 'typesafe-actions';
import { APIfetchLanguages } from './apis';
import { LanguagesItems, LanguagesErrors } from 'Languages-Types';

export const fetchLanguagesAsync = createAsyncAction(
  'FETCH_LANGUAGES_REQUEST',
  'FETCH_LANGUAGES_SUCCESS',
  'FETCH_LANGUAGES_FAILURE',
)<undefined, LanguagesItems[], LanguagesErrors[]>();

export const setInputLanguageAsync = createAsyncAction(
  'FETCH_INPUT_LANGUAGE_REQUEST',
  'FETCH_INPUT_LANGUAGE_SUCCESS',
  'FETCH_INPUT_LANGUAGE_FAILURE',
)<undefined, string, LanguagesErrors[]>();

export const setIsDataInputLanguageAllow = createStandardAction('SET_IS_DATA_INPUT_LANG_ALLOW')<
  boolean
>();

export const fetchLanguages = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchLanguagesAsync.request());
  const res = await APIfetchLanguages(apiClient);
  dispatch(fetchLanguagesAsync.success(res));
};

export const setInputLanguage = (inputLanguage: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(setInputLanguageAsync.request());
  dispatch(setInputLanguageAsync.success(inputLanguage));
};
