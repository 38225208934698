import { dispatchRatEvent } from 'utils/rat';

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

type ActionOrNothing = { type: string };

export default function reducer(state: Object = {}, action: ActionOrNothing = { type: '' }) {
  switch (action.type) {
    //TODO: Extract dispatch logic to another place
    case LOCATION_CHANGE:
      dispatchRatEvent();
      return state;
    default:
      return state;
  }
}
