import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { UploadResult, ApiStatus } from 'LuxuryInvitation-Types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { ApiUploadFile } from './api';
import { initialUploadResult } from './initialValues';
import { UPLOAD_STATUS } from 'constants/luxuryInvitation';

export const uploadFileAsync = createAsyncAction(
  'UPLOAD_FILE_REQUEST',
  'UPLOAD_FILE_SUCCESS',
  'UPLOAD_FILE_FAILURE',
)<undefined, UploadResult, ApiStatus[]>();

export const resetResult = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  try {
    dispatch(uploadFileAsync.success(initialUploadResult));
  } catch (error) {
    dispatch(uploadFileAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const uploadFile = (file: File): AppThunk<Promise<UploadResult | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(uploadFileAsync.request());
  const formData = new FormData();
  formData.append('file', file, file.name);

  try {
    const res = await ApiUploadFile(apiClient, formData);
    dispatch(uploadFileAsync.success(res));
    if ('status' in res && res['status'] === UPLOAD_STATUS.DONE) {
      dispatch(
        fetchGlobalErrorDialogAsync.success({
          status: 200,
          message: '',
        }),
      );
    } else {
      dispatch(
        fetchGlobalErrorDialogAsync.success({
          status: 400,
          message: 'wrong data is detected in uploaded file',
        }),
      );
    }
    return res;
  } catch (error) {
    dispatch(uploadFileAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};
