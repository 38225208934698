import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';

import {
  ApiStatus,
  BrowseParameters,
  CampaignErrors,
  CampaignItems,
  CampaignList,
  CouponCodes,
  Deletable,
  DeletableDialog,
} from 'Campaign-Types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { pushLocation } from 'store/__router/actions';

import { ApiFetchPlaceDetail } from '../place/apis';
import {
  ApiCreateCampaign,
  ApiDeleteCampaign,
  ApiFetchCampaign,
  ApiFetchCampaignDeletable,
  ApiFetchCampaignList,
  ApiGenerateCoupons,
  ApiResubmitCampaign,
  ApiUpdateCampaign,
} from './api';

export const updateCampaignBrowseParameter = createStandardAction(
  'UPDATE_CAMPAIGN_BROWSE_PARAMETER',
)<BrowseParameters>();

export const clearCurrentCampaign = createStandardAction('CLEAR_CAMPAIGN')();
export const clearCoupon = createStandardAction('CLEAR_COUPON')();

export const fetchCampaignListAsync = createAsyncAction(
  'FETCH_CAMPAIGN_LIST_REQUEST',
  'FETCH_CAMPAIGN_LIST_SUCCESS',
  'FETCH_CAMPAIGN_LIST_FAILURE',
)<undefined, CampaignList, CampaignErrors[]>();

export const fetchCampaignAsync = createAsyncAction(
  'FETCH_CAMPAIGN_REQUEST',
  'FETCH_CAMPAIGN_SUCCESS',
  'FETCH_CAMPAIGN_FAILURE',
)<undefined, CampaignItems, CampaignErrors[]>();

export const createCampaignAsync = createAsyncAction(
  'CREATE_CAMPAIGN_REQUEST',
  'CREATE_CAMPAIGN_SUCCESS',
  'CREATE_CAMPAIGN_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const updateCampaignAsync = createAsyncAction(
  'UPDATE_CAMPAIGN_REQUEST',
  'UPDATE_CAMPAIGN_SUCCESS',
  'UPDATE_CAMPAIGN_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const deleteCampaignAsync = createAsyncAction(
  'DELETE_CAMPAIGN_REQUEST',
  'DELETE_CAMPAIGN_SUCCESS',
  'DELETE_CAMPAIGN_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const fetchDeletableCampaignAsync = createAsyncAction(
  'FETCH_DELETABLE_CAMPAIGN_REQUEST',
  'FETCH_DELETABLE_CAMPAIGN_SUCCESS',
  'FETCH_DELETABLE_CAMPAIGN_FAILURE',
)<undefined, Deletable, CampaignErrors[]>();

export const putDeletableDialogAsync = createAsyncAction(
  'DELETABLE_DIALOG_CAMPAIGN_REQUEST',
  'DELETABLE_DIALOG_CAMPAIGN_SUCCESS',
  'DELETABLE_DIALOG_CAMPAIGN_FAILURE',
)<undefined, DeletableDialog, CampaignErrors[]>();

export const internallyReleaseCampaignAsync = createAsyncAction(
  'UPDATE_INTERNALLY_CAMPAIGN_REQUEST',
  'UPDATE_INTERNALLY_CAMPAIGN_SUCCESS',
  'UPDATE_INTERNALLY_CAMPAIGN_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const resubmitCampaignAsync = createAsyncAction(
  'UPDATE_INTERNALLY_CAMPAIGN_REQUEST',
  'UPDATE_INTERNALLY_CAMPAIGN_SUCCESS',
  'UPDATE_INTERNALLY_CAMPAIGN_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const generateCouponsAsync = createAsyncAction(
  'GENERATE_COUPONS_CAMPAIGN_REQUEST',
  'GENERATE_COUPONS_CAMPAIGN_SUCCESS',
  'GENERATE_COUPONS_CAMPAIGN_FAILURE',
)<undefined, CouponCodes[], ApiStatus[]>();

export const generateCouponAsync = createAsyncAction(
  'GENERATE_COUPON_CAMPAIGN_REQUEST',
  'GENERATE_COUPON_CAMPAIGN_SUCCESS',
  'GENERATE_COUPON_CAMPAIGN_FAILURE',
)<undefined, CouponCodes[], ApiStatus[]>();

export const submitUninvitedProvidersAsync = createAsyncAction(
  'SUBMIT_UNINVITED_PROVIDERS_REQUEST',
  'SUBMIT_UNINVITED_PROVIDERS_SUCCESS',
  'SUBMIT_UNINVITED_PROVIDERS_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const fetchCampaignList = (params: Object, rejectIfFetching?: boolean): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const isFetching = getState().campaign.isFetching;
  if (isFetching && rejectIfFetching) return;

  try {
    dispatch(fetchCampaignListAsync.request());
    const res = await ApiFetchCampaignList(apiClient, params);
    dispatch(fetchCampaignListAsync.success(res));
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const createCampaign = (data: CampaignItems): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(createCampaignAsync.request());
    const res = await ApiCreateCampaign(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(createCampaignAsync.success(res));

    if (res.id) {
      dispatch(pushLocation(`/internal/campaigns/${res.id}`));
    }
  } catch (error) {
    dispatch(createCampaignAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const fetchCampaign = (
  sponsoredId: string,
): AppThunk<Promise<CampaignItems | undefined>> => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(fetchCampaignAsync.request());
    const campaign = await ApiFetchCampaign(apiClient, sponsoredId);

    if (campaign?.placeId) {
      const places = await ApiFetchPlaceDetail(apiClient, campaign.placeId || '');
      dispatch(fetchCampaignAsync.success({ ...campaign, ancestors: places.ancestors }));
    } else {
      dispatch(fetchCampaignAsync.success({ ...campaign, ancestors: [] }));
    }

    return campaign;
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }

  return;
};

export const updateCampaign = (
  sponsoredId: string,
  data: CampaignItems,
  state?: { prevSearch: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(updateCampaignAsync.request());
    const res = await ApiUpdateCampaign(apiClient, sponsoredId, data);
    dispatch(updateCampaignAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(pushLocation({ pathname: `/internal/campaigns/${data.id}`, state }));
  } catch (error) {
    dispatch(updateCampaignAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const deleteCampaign = (sponsoredId: string, sponsoredName: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(deleteCampaignAsync.request());
    const res = await ApiDeleteCampaign(apiClient, sponsoredId);
    dispatch(deleteCampaignAsync.success(res));
    res.message = sponsoredName;
    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteCampaignAsync.success(res));
  } catch (error) {
    dispatch(deleteCampaignAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const fetchDeletableCampaign = (sponsoredId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchDeletableCampaignAsync.request());
    const res = await ApiFetchCampaignDeletable(apiClient, sponsoredId);
    dispatch(fetchDeletableCampaignAsync.success(res));
    dispatch(putDeletableDialogAsync.success({ isShow: true }));
  } catch (error) {
    error.status === 403
      ? dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus)) &&
        dispatch(putDeletableDialogAsync.success({ isShow: true }))
      : dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const resubmitCampaign = (sponsoredId: string, params: Object): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(resubmitCampaignAsync.request());
    const res = await ApiResubmitCampaign(apiClient, sponsoredId, params);
    dispatch(resubmitCampaignAsync.success(res));
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const generateCoupons = (
  amount: number,
): AppThunk<Promise<CouponCodes[] | undefined>> => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(generateCouponsAsync.request());
    const res = await ApiGenerateCoupons(apiClient, amount);
    dispatch(generateCouponsAsync.success(res));
    return res;
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const generateCoupon = (): AppThunk<Promise<CouponCodes[] | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(generateCouponAsync.request());
    const res = await ApiGenerateCoupons(apiClient, 1);
    dispatch(generateCouponAsync.success(res));
    return res;
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};
