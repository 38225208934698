// This file is generated automatically by scripts/generate-icon.js. Don't modify it.

import withIconProps from '../withIconProps';

import NotificationVariant from '../_raw/ui/icon_notification_variant.svg';

export default withIconProps(NotificationVariant, {
  shouldSetDefaultSize: true,
  defaultWidth: 24,
  defaultHeight: 24,
});
