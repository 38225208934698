import { LastUsedSearchCondition } from 'LastUsedValue-Types';
import { isOutdated } from 'utils/calendar';

/** for api call */
export const PAGE_ID = {
  RAKUTEN_SP: 'RAKUTEN_SALES_PROMOTION',
  PROVIDER_SP: 'PROVIDER_SALES_PROMOTION',
  SPONSOR_SP: 'SPONSOR_PROMOTION',
  RATE_PLAN: 'RATE_PLAN',
};

export const getValidSearchCondition = (
  value: LastUsedSearchCondition,
): LastUsedSearchCondition => {
  let searchCondition = { ...value };
  if (searchCondition?.travellingPeriod) {
    const { fromDate, toDate } = searchCondition.travellingPeriod;
    if ((fromDate && isOutdated(fromDate)) || (toDate && isOutdated(toDate))) {
      searchCondition.travellingPeriod = {
        ...searchCondition.travellingPeriod,
        fromDate: '',
        toDate: '',
      };
    }
  }
  return {
    ...searchCondition,
    lengthOfStay: {
      minLos: searchCondition?.lengthOfStay?.minLos || '',
      maxLos: searchCondition?.lengthOfStay?.maxLos || '',
    },
    price: {
      minPrice: searchCondition?.price?.minPrice || '',
      maxPrice: searchCondition?.price?.maxPrice || '',
    },
  };
};
