import { toQuery } from '@travel/utils';
import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { FolderList, Query, ApiStatus } from 'Folder-Types';
import { FOLDER_OBJECT_TYPE } from 'constants/folder';
import { ApiFetchFolderList, ApiPutFolder, ApiPostFolder, ApiDeleteFolder } from './apis';

export const fetchFolderSidebarAsync = createAsyncAction(
  'FETCH_FOLDER_SIDEBAR_REQUEST',
  'FETCH_FOLDER_SIDEBAR_SUCCESS',
  'FETCH_FOLDER_SIDEBAR_FAILURE',
)<undefined, FolderList, ApiStatus>();

export const fetchFolderListAsync = createAsyncAction(
  'FETCH_FOLDER_LIST_REQUEST',
  'FETCH_FOLDER_LIST_SUCCESS',
  'FETCH_FOLDER_LIST_FAILURE',
)<undefined, FolderList, ApiStatus>();

export const putFolderAsync = createAsyncAction(
  'PUT_FOLDER_REQUEST',
  'PUT_FOLDER_SUCCESS',
  'PUT_FOLDER_FAILURE',
)<undefined, undefined, ApiStatus>();

export const postFolderAsync = createAsyncAction(
  'POST_FOLDER_REQUEST',
  'POST_FOLDER_SUCCESS',
  'POST_FOLDER_FAILURE',
)<undefined, undefined, ApiStatus>();

export const deleteFolderAsync = createAsyncAction(
  'DELETE_FOLDER_REQUEST',
  'DELETE_FOLDER_SUCCESS',
  'DELETE_FOLDER_FAILURE',
)<undefined, undefined, ApiStatus>();

export const setSidebarFolderPosition = createStandardAction('SET_SIDEBAR_FOLDER_POSITION')<
  number
>();

export const fetchSidebarFolders = (
  providerId: string,
  params: Query,
  offset?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchFolderSidebarAsync.request());
  const currentOffset = getState().folder.sidebarFolders.offset;

  try {
    const fetchQuery = {
      ...params,
      sort: { name: 'asc' },
      offset: offset || 0,
      limit: 30,
    };
    const res = await ApiFetchFolderList(apiClient, providerId, toQuery(fetchQuery));
    const shouldConcat = res.offset > currentOffset;
    if (!shouldConcat) {
      // reset folder position
      dispatch(setSidebarFolderPosition(0));
    }
    dispatch(fetchFolderSidebarAsync.success(res));
  } catch (error) {
    dispatch(fetchFolderSidebarAsync.failure(error as ApiStatus));
  }
};

export const fetchFolderList = (
  providerId: string,
  params: Query,
  offset?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchFolderListAsync.request());
  try {
    const fetchQuery = {
      ...params,
      sort: { name: 'asc' },
      offset: offset || 0,
      limit: 30,
    };
    const res = await ApiFetchFolderList(apiClient, providerId, toQuery(fetchQuery));
    dispatch(fetchFolderListAsync.success(res));
  } catch (error) {
    dispatch(fetchFolderListAsync.failure(error as ApiStatus));
  }
};

export const putFolder = (providerId: string, id: string, name: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(putFolderAsync.request());
    await ApiPutFolder(apiClient, providerId, id, name);
    dispatch(putFolderAsync.success());
    dispatch(fetchSidebarFolders(providerId, { objectType: FOLDER_OBJECT_TYPE.VISUAL_CONTENT }));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: 200, message: '' }));
  } catch (error) {
    dispatch(putFolderAsync.failure(error as ApiStatus));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const postFolder = (providerId: string, name: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(postFolderAsync.request());
    await ApiPostFolder(apiClient, providerId, name);
    dispatch(postFolderAsync.success());
    dispatch(fetchSidebarFolders(providerId, { objectType: FOLDER_OBJECT_TYPE.VISUAL_CONTENT }));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: 200, message: '' }));
  } catch (error) {
    dispatch(postFolderAsync.failure(error as ApiStatus));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const deleteFolder = (providerId: string, id: string, name: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(deleteFolderAsync.request());
    await ApiDeleteFolder(apiClient, providerId, id);
    dispatch(deleteFolderAsync.success());
    dispatch(fetchGlobalErrorDialogAsync.success({ status: 200, message: name, isDelete: true }));
    return;
  } catch (error) {
    dispatch(deleteFolderAsync.failure(error as ApiStatus));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
    return;
  }
};
