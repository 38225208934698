import React, { ReactNode } from 'react';

import { Translate } from '@travel/translation';

export type Option = {
  /** title of the checkbox being displayed after checkbox*/
  label: ReactNode;
  /** unique name of parameter */
  value: string;
  /** isChecked is used to control check/uncheck status  */
  isCheck?: boolean;
};

export const SP_PAYMENT_METHOD = {
  ON_SITE_ECO: 'ON_SITE_ECO',
  ON_SITE_ONLY: 'ON_SITE_ONLY',
  AGENT_ECO: 'AGENT_ECO',
  AGENT_ONLY: 'AGENT_ONLY',
};

export const SP_OFFER_TYPE = {
  COUPON_WITH_CODE: 'COUPON_WITH_CODE',
  COUPON_WITHOUT_CODE: 'COUPON_WITHOUT_CODE',
  DIRECT: 'DIRECT',
};

export const SP_OFFER_TYPE_LABEL = {
  COUPON_WITH_CODE: (
    <Translate id="Sales_Promotion_Rakuten_Basic.List.Filter.Promotion_Method.Coupon_With_Code" />
  ),
  COUPON_WITHOUT_CODE: (
    <Translate id="Sales_Promotion_Rakuten_Basic.List.Filter.Promotion_Method.Coupon_Without_Code" />
  ),
  DIRECT: <Translate id="Supplier_Enumeration.Offer_Type.Direct" />,
};

export const COUPON_OR_DIRECT = {
  COUPON_WITH_CODE: <Translate id="Supplier_Enumeration.Offer_Type.Coupon" />,
  COUPON_WITHOUT_CODE: <Translate id="Supplier_Enumeration.Offer_Type.Coupon" />,
  DIRECT: <Translate id="Supplier_Enumeration.Offer_Type.Direct" />,
};

export const SP_INCENTIVE_TYPE = {
  DISCOUNT: 'DISCOUNT',
  POINT: 'POINT',
};

export const SP_VALUE_TYPE = {
  PERCENT: 'PERCENT',
  EXACT_AMOUNT: 'EXACT_AMOUNT',
};

export const PROMOTION_DESIGNATION = {
  STANDARD: 'STANDARD',
  LUXURY: 'LUXURY',
  STANDARD_LUXURY: 'STANDARD,LUXURY',
};

export const enum PROMOTION_LABEL_TYPE {
  PSP = 'PSP',
  RSP = 'RSP',
  SSP = 'SSP',
  GSP = 'GSP',
  PSSP = 'PSSP',
}
export type PromotionType = typeof PROMOTION_LABEL_TYPE[keyof typeof PROMOTION_LABEL_TYPE];

export const PROMOTION_STATUS = {
  READY: 'READY',
  UNREADY: 'UNREADY',
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
  EXPIRED: 'EXPIRED',
};

export const SP_TAG_COLOR = {
  READY: 'green',
  UNREADY: 'yellow',
  RUNNING: 'blue',
  PAUSED: 'red',
  EXPIRED: 'gray',
} as const;

export const GOTO_PROMOTION = 'GOTO_PROMOTION'; // keep this separately from PROMOTION_LABEL_TYPE.

export const DATE_TIME_DISPLAY_TYPE = {
  CREATE: 'createDateTime',
  UPDATE: 'updateDateTime',
};

export enum APPLICABLE_SETTINGS_OPTIONS {
  NONE = 'NONE', // initial value for brand new RSP promotions that haven't had their setting set yet
  ALL_PROVIDERS = 'ALL_PROVIDERS',
  SELECTED_PROVIDERS_CURRENT_RATE_PLANS = 'SELECTED_PROVIDERS_CURRENT_RATE_PLANS',
  SELECTED_PROVIDERS_ALL_RATE_PLANS = 'SELECTED_PROVIDERS_ALL_RATE_PLANS',
  SELECTED_RATE_PLANS = 'SELECTED_RATE_PLANS',
}

export const APPLICABLE_SETTINGS_TYPE = {
  options: [
    {
      label: (
        <Translate id="Sales_Promotion_Rakuten_Basic.Create.Applicable_Settings.Provider_Selection.All" />
      ),

      name: APPLICABLE_SETTINGS_OPTIONS.ALL_PROVIDERS,
      value: APPLICABLE_SETTINGS_OPTIONS.ALL_PROVIDERS,
    },
    {
      label: (
        <Translate id="Sales_Promotion_Rakuten_Basic.Create.Applicable_Settings.Provider_Selection.Selected_Current" />
      ),
      name: APPLICABLE_SETTINGS_OPTIONS.SELECTED_PROVIDERS_CURRENT_RATE_PLANS,
      value: APPLICABLE_SETTINGS_OPTIONS.SELECTED_PROVIDERS_CURRENT_RATE_PLANS,
    },
    {
      label: (
        <Translate id="Sales_Promotion_Rakuten_Basic.Create.Applicable_Settings.Provider_Selection.Selected_Future" />
      ),
      name: APPLICABLE_SETTINGS_OPTIONS.SELECTED_PROVIDERS_ALL_RATE_PLANS,
      value: APPLICABLE_SETTINGS_OPTIONS.SELECTED_PROVIDERS_ALL_RATE_PLANS,
    },
    {
      label: (
        <Translate id="Sales_Promotion_Rakuten_Basic.Create.Applicable_Settings.Provider_Selection.Selected_ratePlan" />
      ),
      name: APPLICABLE_SETTINGS_OPTIONS.SELECTED_RATE_PLANS,
      value: APPLICABLE_SETTINGS_OPTIONS.SELECTED_RATE_PLANS,
    },
  ],
  defaultSelectedValue: APPLICABLE_SETTINGS_OPTIONS.ALL_PROVIDERS,
};

export const INIT_INCENTIVE_GOTO = {
  incentiveType: SP_INCENTIVE_TYPE.DISCOUNT,
  discount: {
    discountValue: '',
    discountValueType: SP_VALUE_TYPE.PERCENT,
    discountPeak: 14000,
  },
};

export const INIT_INCENTIVE_PSP = {
  incentiveType: SP_INCENTIVE_TYPE.DISCOUNT,
  discount: {
    discountValueType: '',
  },
};

export const BIN_ARRAY_MAX_LENGTH = 10000;
