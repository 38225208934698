import { PLATFORM, PlatformType } from 'utils/userScopeHelper';
import styles from './backgroundColors.module.scss';
import { isNotProdServer } from 'utils/environmentHelpers';

const nonProdBgColors = {
  [PLATFORM.INTERNAL]: styles.bgGray,
  [PLATFORM.EXTRANET]: styles.bgWhiteGreen,
  [PLATFORM.GROUP_EXTRANET]: styles.bgWhiteLincolnGreen,
  [PLATFORM.OUTSIDE]: styles.bgWhiteGreen,
};
const bgColors = {
  [PLATFORM.INTERNAL]: styles.bgBlack,
  [PLATFORM.EXTRANET]: styles.bgGreen,
  [PLATFORM.GROUP_EXTRANET]: styles.bgLincolnGreen,
  [PLATFORM.OUTSIDE]: styles.bgGreen,
};

export const getBgColor = (platform: PlatformType) =>
  isNotProdServer ? nonProdBgColors[platform] : bgColors[platform];
