import {
  UserSegmentQueryParams,
  ApiStatus,
  UserSegment,
  UploadResult,
  SegmentIds,
  SelectedUserSegment,
} from 'UserSegment-Types';

import { ApiClient } from '@travel/api-client';

export const ApiFetchUserSegments = (
  http: ApiClient,
  params: UserSegmentQueryParams,
): Promise<UserSegment> => http.get('/user-segment-api/segments', { values: params });

export const ApiFetchSelectedUserSegments = (
  http: ApiClient,
  segmentIds: SegmentIds,
): Promise<SelectedUserSegment[]> =>
  http
    .post('/user-segment-api/fetchSegments', {
      values: segmentIds,
      ignoreCommonErrorHandler: true,
    })
    .then((data: SelectedUserSegment[]) => data);

export const ApiCreateUserSegment = (
  http: ApiClient,
  formData: FormData,
  name: string,
): Promise<UploadResult> =>
  http
    .post('/user-segment-api/userSegments', {
      values: formData,
      ignoreCommonErrorHandler: true,
    })
    .then(data => data);

export const ApiUpdateUserSegment = (
  http: ApiClient,
  formData: FormData,
  id: string,
): Promise<UploadResult> =>
  http
    .put('/user-segment-api/userSegments', {
      values: formData,
      ignoreCommonErrorHandler: true,
    })
    .then(data => data);

export const ApiDownloadUserSegment = (http: ApiClient, id: string): Promise<Blob> => {
  return http
    .get(`/user-segment-api/userSegments/${id}/download`)
    .then(data => data.blob())
    .catch(error => error);
};

export const ApiDeleteUserSegment = (http: ApiClient, id: string): Promise<ApiStatus> => {
  return http
    .delete(`/user-segment-api/userSegments/${id}`, {})
    .then(data => data)
    .catch(error => error);
};
