import { PAGE_ID } from 'constants/lastUsedValue';
import { fetchAllChannelDistributors } from 'store/channelDistributor/actions';
import { fetchAllFeatureGroups } from 'store/featureSelection/actions';
import { clearLastUsedValue, fetchLastUsedValue } from 'store/lastUsedValue/actions';
import { fetchProviderInformation } from 'store/providersInformation/actions';
import { clearCurrentRatePlanList } from 'store/ratePlan/actions';
import {
  fetchSalesPromotionDetail,
  setInitialPromotionDetail,
} from 'store/salesPromotionList/actions';

import { OBJECT_TYPE } from 'constants/feature';
import { universalRouterProps } from 'core/universalRouter/types';

type Location = {
  params: {
    providerId: string;
    promotionId: string;
  };
};

export default ({ store: { dispatch, getState } }: universalRouterProps, location: Location) => {
  const { providerId, promotionId } = location.params;
  const userUuid = getState().accessControl.loggedInUser?.userUuid;
  const featureQueryParams = { resourceId: providerId, objectType: OBJECT_TYPE.SALES_PROMOTION };

  const promises = [
    dispatch(fetchAllChannelDistributors()),
    dispatch(fetchAllFeatureGroups(providerId, featureQueryParams)),
  ];

  // to delete existing list in store and reset to empty object
  dispatch(clearCurrentRatePlanList());

  if (promotionId) {
    promises.push(
      dispatch(fetchSalesPromotionDetail(providerId, promotionId)),
      dispatch(fetchProviderInformation(providerId)),
    );
    dispatch(clearLastUsedValue());
  } else {
    promises.push(
      dispatch(fetchLastUsedValue({ type: PAGE_ID.PROVIDER_SP, providerId, userUuid })),
    );
    dispatch(setInitialPromotionDetail());
  }

  return Promise.all(promises);
};
