import { createReducer } from 'typesafe-actions';
import {
  XtrPagedItemsOfXtrGotoPromotion,
  XtrGotoPromotion,
  GotoPromotion_UI,
  SalsPromotionsErrors,
} from 'GoToPromotion-Types';
import {
  clearCurrentGoToDeletable,
  clearCurrentGoToPromotion,
  fetchDeletablePromotionAsync,
  fetchGoToPromotionsAsync,
  fetchGoToPromotionAsync,
  putDeletableDialogAsync,
  postGoToPromotionAsync,
  putGoToPromotionAsync,
} from './actions';
import {
  defaultDeletableDialog,
  defaultPromotionDeletable,
  defaultPromotionDetail,
} from './initialValues';

import { combineReducers } from 'redux';
import { deleteCampaignAsync } from 'store/campaign/actions';
import { createPeriod } from 'utils/calendar';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchGoToPromotionsAsync.request], (state, action) => true)
  .handleAction(
    [fetchGoToPromotionsAsync.success, fetchGoToPromotionsAsync.failure],
    (state, action) => false,
  );

export const isCreating = createReducer(false)
  .handleAction([postGoToPromotionAsync.request, putGoToPromotionAsync.request], () => true)
  .handleAction(
    [
      postGoToPromotionAsync.success,
      postGoToPromotionAsync.failure,
      putGoToPromotionAsync.success,
      putGoToPromotionAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction(deleteCampaignAsync.request, () => true)
  .handleAction([deleteCampaignAsync.success, deleteCampaignAsync.failure], () => false);

export const promotions = createReducer({} as XtrPagedItemsOfXtrGotoPromotion).handleAction(
  fetchGoToPromotionsAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

// TODO: move to selector
export const promotion = createReducer(defaultPromotionDetail)
  .handleAction(fetchGoToPromotionAsync.success, (state, action) => {
    const promotion: XtrGotoPromotion = action.payload;

    return {
      ...promotion,
      effectivePeriod: createPeriod(promotion.effectivePeriod),
      incentive: {
        incentiveType: promotion.incentiveType,
        discount: promotion.discount,
      },
      offer: {
        offerType: promotion.offerType,
        offer: [],
      },
      userCondition: {
        ...promotion.userCondition,
        prohibitedPrefectureCodes: promotion.prohibitedPrefectureCodes,
      },
    } as GotoPromotion_UI;
  })
  .handleAction(clearCurrentGoToPromotion, (state, action) => defaultPromotionDetail);

export const deletable = createReducer(defaultPromotionDeletable)
  .handleAction(fetchDeletablePromotionAsync.success, (state, action) => {
    const { payload } = action;
    const updatedPayload = {
      ...payload,
      statusCode: payload.status,
    };
    return updatedPayload;
  })
  .handleAction(clearCurrentGoToDeletable, (state, action) => defaultPromotionDeletable);

export const deletableDialog = createReducer(defaultDeletableDialog).handleAction(
  putDeletableDialogAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as SalsPromotionsErrors[]).handleAction(
  fetchGoToPromotionsAsync.failure,
  (state, action) => action.payload,
);

const goToPromotionsReducer = combineReducers({
  isFetching,
  promotions,
  promotion,
  deletable,
  deletableDialog,
  errors,
  isCreating,
  isDeleting,
});

export default goToPromotionsReducer;
export type GoToPromotionsState = ReturnType<typeof goToPromotionsReducer>;
