// This file is generated automatically by scripts/generate-icon.js. Don't modify it.

import withIconProps from '../withIconProps';

import LogoExtranet from '../_raw/ui/logo_extranet.svg';

export default withIconProps(LogoExtranet, {
  defaultHeight: 32,
  defaultWidth: 192,
});
