import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import {
  HighlightContent,
  HighlightContentStatus,
  HighlightContentArticle,
} from 'HighlightContent-Types';
import {
  ApiFetchHighlightContent,
  ApiFetchHighlightContentArticle,
  ApiPostHighlightContent,
  ApiPutHighlightContent,
  ApiDeleteHighlightContent,
  ApiPutHighlightContentArticles,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';

export const fetchHighlightContentAsync = createAsyncAction(
  'FETCH_HIGHLIGHT_CONTENT_REQUEST',
  'FETCH_HIGHLIGHT_CONTENT_SUCCESS',
  'FETCH_HIGHLIGHT_CONTENT_FAILURE',
)<undefined, HighlightContent, HighlightContentStatus[]>();

export const fetchHighlightContentArticleAsync = createAsyncAction(
  'FETCH_HIGHLIGHT_CONTENT_ARTICLE_REQUEST',
  'FETCH_HIGHLIGHT_CONTENT_ARTICLE_SUCCESS',
  'FETCH_HIGHLIGHT_CONTENT_ARTICLE_FAILURE',
)<undefined, HighlightContentArticle, HighlightContentStatus[]>();

export const postHighlightArticleAsync = createAsyncAction(
  'POST_HIGHLIGHT_CONTENT_ARTICLE_REQUEST',
  'POST_HIGHLIGHT_CONTENT_ARTICLE_SUCCESS',
  'POST_HIGHLIGHT_CONTENT_ARTICLE_FAILURE',
)<undefined, HighlightContentStatus, HighlightContentStatus[]>();

export const putHighlightArticleAsync = createAsyncAction(
  'PUT_HIGHLIGHT_CONTENT_ARTICLE_REQUEST',
  'PUT_HIGHLIGHT_CONTENT_ARTICLE_SUCCESS',
  'PUT_HIGHLIGHT_CONTENT_ARTICLE_FAILURE',
)<undefined, HighlightContentStatus, HighlightContentStatus[]>();

export const deleteHighlightArticleAsync = createAsyncAction(
  'DELETE_HIGHLIGHT_CONTENT_ARTICLE_REQUEST',
  'DELETE_HIGHLIGHT_CONTENT_ARTICLE_SUCCESS',
  'DELETE_HIGHLIGHT_CONTENT_ARTICLE_FAILURE',
)<undefined, HighlightContentStatus, HighlightContentStatus[]>();

export const putHighlightArticlesAsync = createAsyncAction(
  'PUT_HIGHLIGHT_CONTENT_ARTICLES_REQUEST',
  'PUT_HIGHLIGHT_CONTENT_ARTICLES_SUCCESS',
  'PUT_HIGHLIGHT_CONTENT_ARTICLES_FAILURE',
)<undefined, HighlightContentStatus, HighlightContentStatus[]>();

export const setInitialHighlightContentArticle = createStandardAction(
  'SET_INITIAL_HIGHLIGHT_CONTENT_ARTICLE',
)();

export const fetchHighlightContent = (providerId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchHighlightContentAsync.request());
  try {
    const res = await ApiFetchHighlightContent(apiClient, { type: 'HIGHLIGHT' }, providerId);
    dispatch(fetchHighlightContentAsync.success(res));
  } catch (errors) {
    dispatch(fetchHighlightContentAsync.failure([errors as HighlightContentStatus]));
  }
};

export const postHighlightContentArticle = (
  providerId: string,
  data: HighlightContentArticle,
  baseUrl: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(postHighlightArticleAsync.request());
  try {
    const res = await ApiPostHighlightContent(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(postHighlightArticleAsync.success(res));
    if (res.id) {
      dispatch(pushLocation(`${baseUrl}/highlightContents/${res.id}`));
    }
  } catch (errors) {
    dispatch(postHighlightArticleAsync.failure([errors as HighlightContentStatus]));
  }
};

export const fetchHighlightContentArticle = (
  providerId: string,
  articleId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(fetchHighlightContentArticleAsync.request());
    const res = await ApiFetchHighlightContentArticle(apiClient, providerId, articleId);
    dispatch(fetchHighlightContentArticleAsync.success(res));
  } catch (errors) {
    dispatch(fetchHighlightContentArticleAsync.failure([errors as HighlightContentStatus]));
  }
};

export const putHighlightContentArticle = (
  providerId: string,
  data: HighlightContentArticle,
  pathname: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(putHighlightArticleAsync.request());
    const res = await ApiPutHighlightContent(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putHighlightArticleAsync.success(res));
    dispatch(pushLocation({ pathname }));
  } catch (errors) {
    dispatch(putHighlightArticleAsync.failure([errors as HighlightContentStatus]));
  }
};

export const deleteHighlightContentArticle = (
  providerId: string,
  articleId: string,
  baseUrl: string,
  name: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(deleteHighlightArticleAsync.request());
    const res = await ApiDeleteHighlightContent(apiClient, providerId, articleId);
    dispatch(deleteHighlightArticleAsync.success(res));
    res.message = name;
    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(pushLocation(`${baseUrl}/highlightContents`));
  } catch (errors) {
    dispatch(fetchGlobalErrorDialogAsync.success({ ...errors, isDelete: true }));
    dispatch(deleteHighlightArticleAsync.failure([errors as HighlightContentStatus]));
  }
};

export const putHighlightContentArticles = (
  providerId: string,
  data: HighlightContentArticle[],
  pathname: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(putHighlightArticlesAsync.request());
    const res = await ApiPutHighlightContentArticles(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putHighlightArticlesAsync.success(res));
    dispatch(pushLocation({ pathname }));
  } catch (errors) {
    dispatch(putHighlightArticlesAsync.failure([errors as HighlightContentStatus]));
  }
};
