import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { FolderList } from 'Folder-Types';
import { fetchFolderListAsync, fetchFolderSidebarAsync, setSidebarFolderPosition } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchFolderListAsync.request], (state, action) => true)
  .handleAction(
    [fetchFolderListAsync.success, fetchFolderListAsync.failure],
    (state, action) => false,
  );

export const sidebarFolders = createReducer({} as FolderList).handleAction(
  fetchFolderSidebarAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents?.slice(2), // remove ALL and ARCHIVE since no longer use. TODO: remove from api data
    };
  },
);

export const folderList = createReducer({} as FolderList).handleAction(
  fetchFolderListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents?.slice(2), // remove ALL and ARCHIVE since no longer use. TODO: remove from api data
    };
  },
);

export const sidebarFolderPosition = createReducer(0).handleAction(
  [setSidebarFolderPosition],
  (state, action) => action.payload,
);

const folderReducer = combineReducers({
  isFetching,
  folderList,
  sidebarFolders,
  sidebarFolderPosition,
});

export default folderReducer;
export type FolderState = ReturnType<typeof folderReducer>;
