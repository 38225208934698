import { universalRouterProps } from 'core/universalRouter/types';
import { fetchRatePlanDetail, setInitialPlanDetail } from 'store/ratePlan/actions';
import { fetchInventoryList } from 'store/inventory/actions';
import { fetchProviderInformation } from 'store/providersInformation/actions';
import { fetchMostLaxCancelPolicy } from 'store/cancelPolicy/actions';
import { clearLastUsedValue, fetchLastUsedValue } from 'store/lastUsedValue/actions';
import { fetchAllFeatureGroups } from 'store/featureSelection/actions';
import { PAGE_ID } from 'constants/lastUsedValue';
import { OBJECT_TYPE } from 'constants/feature';
import { MAX_ITEMS } from 'constants/rate-plan';
import { fetchVisualContentGroupList } from 'store/visualContentGroup/actions';
import { getIsCreateAccess } from 'utils/userScopeHelper';
import { getHiddenDesignations } from 'utils/designationHelpers';

type Params = {
  providerId: string;
  ratePlanId: string;
};

export default ({ store }: universalRouterProps, { providerId, ratePlanId }: Params) => {
  const userUuid = store.getState().accessControl.loggedInUser?.userUuid;
  const featureQueryParams = { resourceId: providerId, objectType: OBJECT_TYPE.PLAN };
  const { dispatch } = store;
  const { permissions } = store.getState().accessControl?.loggedInUser || { permissions: [] };
  const hasMediaAccess = getIsCreateAccess(permissions, 'MediaMgmt');

  const providerDesignation =
    store.getState().providersInformation?.providerBasicInfo?.designations || [];
  const serviceTypesMasterData = store.getState().serviceTypes?.serviceTypes || [];
  const designation: string[] = getHiddenDesignations(serviceTypesMasterData, providerDesignation);

  const itemQueryParams = {
    createDateTime: 'desc',
    designation: designation,
  };

  const promises = [
    dispatch(fetchInventoryList(providerId, itemQueryParams, undefined, MAX_ITEMS)),
    dispatch(fetchProviderInformation(providerId)),
    dispatch(fetchAllFeatureGroups(providerId, featureQueryParams)),
    dispatch(fetchMostLaxCancelPolicy(providerId)),
  ];

  if (hasMediaAccess) {
    promises.push(dispatch(fetchVisualContentGroupList(providerId, {})));
  }

  dispatch(setInitialPlanDetail());
  if (ratePlanId) {
    dispatch(clearLastUsedValue());
    promises.push(dispatch(fetchRatePlanDetail(providerId, ratePlanId)));
  } else {
    promises.push(dispatch(fetchLastUsedValue({ type: PAGE_ID.RATE_PLAN, providerId, userUuid })));
  }

  return Promise.all(promises);
};
