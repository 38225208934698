import { universalRouterProps } from 'core/universalRouter/types';
import { fetchFilterGroup, setInitialFilterGroup } from 'store/filterGroup/actions';
import { fetchConsumerFilterFeatures } from 'store/feature/actions';

type Location = {
  params: {
    id?: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  let promises: Array<Promise<void>> = [];
  const { id } = location.params;

  if (id) {
    promises = [dispatch(fetchConsumerFilterFeatures()), dispatch(fetchFilterGroup(id))];
  } else {
    promises = [dispatch(fetchConsumerFilterFeatures())];
    dispatch(setInitialFilterGroup());
  }

  return Promise.all(promises);
};
