import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  AvailabilityControl,
  AvailabilityControlErrors,
  FilterParams,
  BulkSettings,
} from 'AvailabilityControl-Types';
import {
  fetchAvailabilityControlAsync,
  fetchBulkRangeAvailabilityControlAsync,
  setCalendarDataStandard,
  saveAvailabilityControlAsync,
  setFilterParams,
  setBulkSettingCalendarData,
  setBulkSettings,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchAvailabilityControlAsync.request, fetchBulkRangeAvailabilityControlAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchAvailabilityControlAsync.success,
      fetchAvailabilityControlAsync.failure,
      fetchBulkRangeAvailabilityControlAsync.success,
      fetchBulkRangeAvailabilityControlAsync.failure,
    ],
    (state, action) => false,
  );

export const items = createReducer({} as AvailabilityControl).handleAction(
  fetchAvailabilityControlAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as AvailabilityControlErrors[]).handleAction(
  fetchAvailabilityControlAsync.failure,
  (state, action) => action.payload,
);

export const calendarData = createReducer({} as AvailabilityControl).handleAction(
  setCalendarDataStandard,
  (state, action) => action.payload,
);

export const bulkSettingCalendarData = createReducer({} as AvailabilityControl).handleAction(
  setBulkSettingCalendarData,
  (state, action) => action.payload,
);

export const bulkSettings = createReducer({} as BulkSettings).handleAction(
  setBulkSettings,
  (state, action) => action.payload,
);

export const filterParams = createReducer({} as FilterParams).handleAction(
  setFilterParams,
  (state, action) => action.payload,
);

export const isSaving = createReducer(false as boolean)
  .handleAction([saveAvailabilityControlAsync.request], (state, action) => true)
  .handleAction(
    [saveAvailabilityControlAsync.success, saveAvailabilityControlAsync.failure],
    (state, action) => false,
  );

export const saveErrors = createReducer([] as AvailabilityControlErrors[]).handleAction(
  saveAvailabilityControlAsync.failure,
  (state, action) => action.payload,
);

const availabilityControlReducer = combineReducers({
  isFetching,
  items,
  errors,
  calendarData,
  bulkSettingCalendarData,
  bulkSettings,
  filterParams,
  isSaving,
  saveErrors,
});

export default availabilityControlReducer;
export type AvailabilityControlState = ReturnType<typeof availabilityControlReducer>;
