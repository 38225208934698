import { combineReducers } from 'redux';
import utilityReducer from './utility/reducer';
import providerDataTranslationReducer from './providerDataTranslation/reducer';
import objectScoreReducer from './objectScore/reducer';
import folderReducer from './folder/reducer';
import qrcodegeneratorReducer from './qrCodeGenerator/reducer';
import goToPromotionReducer from './goToPromotion/reducer';
import diagnosisReducer from './diagnosis/reducer';
import luxuryInvitationReducer from './luxuryInvitation/reducer';
import menuReducer from './menu/reducer';
import reviewExaminationReducer from './reviewExamination/reducer';
import announcementReducer from './announcement/reducer';
import providerGroupReducer from './providerGroup/reducer';
import faxManagementReducer from './faxManagement/reducer';
import lastUsedValueReducer from './lastUsedValue/reducer';
import manualTranslationReducer from './manualTranslation/reducer';
import certificationReducer from './certification/reducer';
import notificationReducer from './notification/reducer';
import sizeTypesReducer from './sizeTypes/reducer';
import accountingReportReducer from './accountingReport/reducer';
import extraRateReducer from './extraRate/reducer';
import marketsReducer from './markets/reducer';
import currenciesReducer from './currencies/reducer';
import cutOffReducer from './cutOff/reducer';
import applicationTrackerReducer from './applicationTracker/reducer';
import labelManagementReducer from './labelManagement/reducer';
import aliasesReducer from './aliases/reducer';
import ratePlanDistributionReducer from './ratePlanDistribution/reducer';
import providerCampaignReducer from './providerCampaign/reducer';
import campaignReducer from './campaign/reducer';
import accessControlReducer from './accessControl/reducer';
import reviewsReducer from './reviews/reducer';
import channelManagerProviderListReducer from './channelManagerProviderList/reducer';
import channelDistributorReducer from './channelDistributor/reducer';
import channelManagerReducer from './channelManager/reducer';
import loginReducer from './login/reducer';
import placeReducer from './place/reducer';
import availabilityControlReducer from './availabilityControl/reducer';
import bookingReducer from './booking/reducer';
import bookingMessageReducer from './bookingMessage/reducer';
import cancelPolicyReducer from './cancelPolicy/reducer';
// This is for Feature Group endpoint for Internal user
import featureGroupReducer from './featureGroup/reducer';
import featureSelectionReducer from './featureSelection/reducer';
import featureReducer from './feature/reducer';
import globalErrorDialogReducer from './globalErrorDialog/reducer';
import { reducer as httpRequestReducer } from '@travel/http-request';
import { reducer as i18nReducer } from '@travel/i18n';
import inventoryControlReducer from './inventoryControl/reducer';
import inventoryReducer from './inventory/reducer';
import languagesReducer from './languages/reducer';
import mediaReducer from './media/reducer';
import planReducer from './plan/reducer';
import providersInformationReducer from './providersInformation/reducer';
import providersReducer from './providers/reducer';
import providerRestrictionsReducer from './providerRestrictions/reducer';
import rateControlReducer from './rateControl/reducer';
import ratePlanReducer from './ratePlan/reducer';
import { routerReducer } from './__router/reducer';
// This is for Feature Group endpoint for External user
import salesPromotionListReducer from './salesPromotionList/reducer';
import salesPromotionsReducer from './salesPromotion/reducer';
import visualContentGroupReducer from './visualContentGroup/reducer';
import campaignApplicationDetailReducer from './applicationDetail/reducer';
import labelReducer from './labels/reducer';
import ratReducer from './rat/reducer';
import filterGroupReducer from './filterGroup/reducer';
import filterComponentReducer from './filterComponent/reducer';
import taxFeeTypesReducer from './taxFeeTypes/reducer';
import calendarHolidayReducer from './calendarHoliday/reducer';
import segmentsReducer from './segments/reducer';
import userSegmentReducer from './userSegment/reducer';
import introductionContentReducer from './introductionContent/reducer';
import highlightContentReducer from './highlightContent/reducer';
import serviceTypesReducer from './serviceTypes/reducer';

export default combineReducers({
  utility: utilityReducer,
  providerDataTranslation: providerDataTranslationReducer,
  objectScore: objectScoreReducer,
  folder: folderReducer,
  qrcodegenerator: qrcodegeneratorReducer,
  goToPromotion: goToPromotionReducer,
  diagnosis: diagnosisReducer,
  luxuryInvitation: luxuryInvitationReducer,
  menu: menuReducer,
  reviewExamination: reviewExaminationReducer,
  announcement: announcementReducer,
  providerGroup: providerGroupReducer,
  faxManagement: faxManagementReducer,
  lastUsedValue: lastUsedValueReducer,
  manualTranslation: manualTranslationReducer,
  certification: certificationReducer,
  notification: notificationReducer,
  sizeTypes: sizeTypesReducer,
  accountingReport: accountingReportReducer,
  extraRate: extraRateReducer,
  markets: marketsReducer,
  currencies: currenciesReducer,
  cutOff: cutOffReducer,
  applicationTracker: applicationTrackerReducer,
  labelManagement: labelManagementReducer,
  aliases: aliasesReducer,
  ratePlanDistribution: ratePlanDistributionReducer,
  providerCampaign: providerCampaignReducer,
  campaign: campaignReducer,
  accessControl: accessControlReducer,
  reviews: reviewsReducer,
  channelManagerProviderList: channelManagerProviderListReducer,
  channelDistributor: channelDistributorReducer,
  channelManager: channelManagerReducer,
  login: loginReducer,
  place: placeReducer,
  booking: bookingReducer,
  bookingMessage: bookingMessageReducer,
  feature: featureReducer,
  featureGroup: featureGroupReducer,
  salesPromotionList: salesPromotionListReducer,
  featureSelection: featureSelectionReducer,
  globalErrorDialog: globalErrorDialogReducer,
  ratePlan: ratePlanReducer,
  rateControl: rateControlReducer,
  languages: languagesReducer,
  cancelPolicy: cancelPolicyReducer,
  availabilityControl: availabilityControlReducer,
  media: mediaReducer,
  plan: planReducer,
  _i18n: i18nReducer,
  _httpRequest: httpRequestReducer,
  // application specific reducers
  inventoryControl: inventoryControlReducer,
  providers: providersReducer,
  providersInformation: providersInformationReducer,
  providerRestrictions: providerRestrictionsReducer,
  segments: segmentsReducer,
  inventory: inventoryReducer,
  router: routerReducer,
  salesPromotion: salesPromotionsReducer,
  visualContentGroup: visualContentGroupReducer,
  extranetApplication: campaignApplicationDetailReducer,
  labels: labelReducer,
  rat: ratReducer,
  filterGroup: filterGroupReducer,
  filterComponent: filterComponentReducer,
  taxFeeTypes: taxFeeTypesReducer,
  countriesHoliday: calendarHolidayReducer,
  userSegments: userSegmentReducer,
  introductionContent: introductionContentReducer,
  highlightContent: highlightContentReducer,
  serviceTypes: serviceTypesReducer,
});
