import { universalRouterProps } from 'core/universalRouter/types';
import {
  fetchFeatureGroup,
  fetchFeatureGroupObjectTypes,
  setInitialFeatureGroup,
} from 'store/featureGroup/actions';

type Location = {
  params: {
    featureGroupId?: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  let promises: Array<Promise<void>> = [];
  const { featureGroupId } = location.params;

  if (featureGroupId) {
    promises = [
      dispatch(fetchFeatureGroup(featureGroupId)),
      dispatch(fetchFeatureGroupObjectTypes()),
    ];
  } else {
    dispatch(setInitialFeatureGroup());
    promises = [dispatch(fetchFeatureGroupObjectTypes())];
  }

  return Promise.all(promises);
};
