import env from '@travel/env';
import { isNotEmptyArray } from '@travel/utils';

import { Permission } from 'AccessControl-Types';

import PERM from 'constants/permissions';

export const USER_SCOPE = {
  INTERNAL: 'internal',
  PROVIDER: 'provider',
  PROVIDERGROUP: 'providerGroup',
  INTERNAL_PROVIDER: 'internal_provider',
  INTERNAL_PROVIDERGROUP: 'internal_providerGroup',
  INTERNAL_PROVIDERGROUP_PROVIDER: 'internal_providerGroup_provider',
  PROVIDERGROUP_PROVIDER: 'providerGroup_provider',
  JUMPED_USER: 'current_provider',
} as const;

export type UserScopeType = typeof USER_SCOPE[keyof typeof USER_SCOPE];

export const PLATFORM = {
  EXTRANET: 'EXTRANET',
  INTERNAL: 'INTERNAL',
  GROUP_EXTRANET: 'GROUP_EXTRANET',
  OUTSIDE: 'OUTSIDE', // login page etc.
} as const;

export type PlatformType = typeof PLATFORM[keyof typeof PLATFORM];

type Params = {
  providerId?: string;
  providerGroupId?: string;
};

export const getIsInternalUser = (userScope: UserScopeType) => {
  return userScope === USER_SCOPE.INTERNAL;
};

export const getIsSwitchUser = (userScope: UserScopeType) => {
  return (
    userScope === USER_SCOPE.INTERNAL_PROVIDER ||
    userScope === USER_SCOPE.INTERNAL_PROVIDERGROUP ||
    userScope === USER_SCOPE.INTERNAL_PROVIDERGROUP_PROVIDER ||
    userScope === USER_SCOPE.PROVIDERGROUP_PROVIDER
  );
};

export const getIsInternalSwitchUser = (userScope: UserScopeType) => {
  return (
    userScope === USER_SCOPE.INTERNAL_PROVIDER || userScope === USER_SCOPE.INTERNAL_PROVIDERGROUP
  );
};

export const getIsProviderGroupSwitchUser = (userScope: UserScopeType) => {
  return userScope === USER_SCOPE.PROVIDERGROUP_PROVIDER;
};

export const getIsInternalProviderGroupSwitchUser = (userScope: UserScopeType) => {
  return userScope === USER_SCOPE.INTERNAL_PROVIDERGROUP_PROVIDER;
};

export const getIsShowProviderGroupInfo = (userScope: UserScopeType) => {
  return (
    userScope === USER_SCOPE.INTERNAL_PROVIDERGROUP_PROVIDER ||
    userScope === USER_SCOPE.PROVIDERGROUP_PROVIDER ||
    userScope === USER_SCOPE.INTERNAL_PROVIDER
  );
};

export const getIsProviderGroupUser = (userScope: UserScopeType) => {
  return userScope === USER_SCOPE.INTERNAL_PROVIDERGROUP || userScope === USER_SCOPE.PROVIDERGROUP;
};

export const getIsProviderGroupProviderUser = (userScope: UserScopeType) => {
  const targetScopes: Array<UserScopeType> = [
    USER_SCOPE.INTERNAL_PROVIDERGROUP_PROVIDER,
    USER_SCOPE.PROVIDERGROUP_PROVIDER,
  ];
  return targetScopes.includes(userScope);
};

export const getIsJumpedUser = (userScope: UserScopeType) => {
  return userScope === USER_SCOPE.JUMPED_USER;
};

export const getFullPath = (userScope: UserScopeType, id: string, childPath: string = '') => {
  const basename = env('UNIVERSAL_BASE_NAME') ? `/${env('UNIVERSAL_BASE_NAME')}` : '';
  let path = '';

  switch (userScope) {
    case USER_SCOPE.PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDER:
    case USER_SCOPE.PROVIDERGROUP_PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDERGROUP_PROVIDER:
      path = 'provider';
      break;
    case USER_SCOPE.INTERNAL_PROVIDERGROUP:
    case USER_SCOPE.PROVIDERGROUP:
      path = 'providerGroup';
      break;
    case USER_SCOPE.INTERNAL:
      path = 'internal';
      break;
    default:
      return `${window.location.origin}${basename}/logout`;
  }

  return `${window.location.origin}${basename}/${path}/${id}${childPath}`;
};

export const getPlatformFlag = (userScope: UserScopeType) => {
  const platform = getUserPlatform(userScope);
  return {
    isInternal: platform === PLATFORM.INTERNAL,
    isExtranet: platform === PLATFORM.EXTRANET,
    isProviderGroup: platform === PLATFORM.GROUP_EXTRANET,
  };
};

export const getUserPlatform = (userScope: UserScopeType) => {
  switch (userScope) {
    case USER_SCOPE.PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDER:
    case USER_SCOPE.PROVIDERGROUP_PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDERGROUP_PROVIDER:
    case USER_SCOPE.JUMPED_USER:
      return PLATFORM.EXTRANET;
    case USER_SCOPE.INTERNAL_PROVIDERGROUP:
    case USER_SCOPE.PROVIDERGROUP:
      return PLATFORM.GROUP_EXTRANET;
    case USER_SCOPE.INTERNAL:
      return PLATFORM.INTERNAL;
    default:
      return PLATFORM.OUTSIDE;
  }
};

export const getBasePath = (userScope: UserScopeType, id: string) => {
  switch (userScope) {
    case USER_SCOPE.PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDER:
    case USER_SCOPE.PROVIDERGROUP_PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDERGROUP_PROVIDER:
    case USER_SCOPE.JUMPED_USER:
      return `/provider/${id}`;
    case USER_SCOPE.INTERNAL_PROVIDERGROUP:
    case USER_SCOPE.PROVIDERGROUP:
      return `/providerGroup/${id}`;
    case USER_SCOPE.INTERNAL:
      return '/internal';
    default:
      return '';
  }
};

export const getProviderRelatedId = (userScope: UserScopeType, params: Params) => {
  switch (userScope) {
    case USER_SCOPE.PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDER:
    case USER_SCOPE.PROVIDERGROUP_PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDERGROUP_PROVIDER:
    case USER_SCOPE.JUMPED_USER:
      return params.providerId || '';
    case USER_SCOPE.INTERNAL_PROVIDERGROUP:
    case USER_SCOPE.PROVIDERGROUP:
      return params.providerGroupId || '';
    case USER_SCOPE.INTERNAL:
    default:
      return '';
  }
};

export const getIsNotValidProviderIdInUrl = (props: {
  loggedInUser: { scope: UserScopeType; providerUuid?: string; providerGroupId?: string };
  providerRelatedIdInUrl: string;
}): boolean => {
  const { loggedInUser, providerRelatedIdInUrl } = props;
  const { providerUuid, providerGroupId, scope } = loggedInUser;

  if (!providerRelatedIdInUrl) {
    return false;
  }

  switch (scope) {
    case USER_SCOPE.PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDER:
    case USER_SCOPE.PROVIDERGROUP_PROVIDER:
    case USER_SCOPE.INTERNAL_PROVIDERGROUP_PROVIDER:
    case USER_SCOPE.JUMPED_USER:
      return !!providerUuid && providerUuid !== providerRelatedIdInUrl;
    case USER_SCOPE.INTERNAL_PROVIDERGROUP:
    case USER_SCOPE.PROVIDERGROUP:
      return !!providerGroupId && providerGroupId !== providerRelatedIdInUrl;
    case USER_SCOPE.INTERNAL:
    default:
      return false;
  }
};

export const isLoginPage = (path: string) => {
  return path === '/' || path.includes('/login');
};

export const isNotLogin = (path: string) => {
  return (
    path !== '/' &&
    !path.includes('login') &&
    !path.includes('logout') &&
    !path.includes('notFound') &&
    !path.includes('unauthorized') &&
    !path.includes('error.html')
  );
};

export const getIsCreatePermission = (permissionLevel: string): boolean => {
  if (permissionLevel) {
    return permissionLevel === PERM.readEdit || permissionLevel === PERM.readEditDelete;
  }
  return false;
};

export const getIsDeletePermission = (permissionLevel: string): boolean => {
  if (permissionLevel) {
    return permissionLevel === PERM.readEditDelete;
  }
  return false;
};

/** Get whether user has readonly permission or not. */
export const getIsOnlyReadPermission = (permissionLevel: string): boolean => {
  if (permissionLevel) {
    return permissionLevel === PERM.read;
  }
  return false;
};

export const getIsCreateAccess = (permissions: Permission[], authKey: string): boolean => {
  const permissionLevel = permissions.find(permission => permission.objectName === authKey)
    ?.permissionLevel;

  if (permissionLevel) {
    return getIsCreatePermission(permissionLevel);
  }
  return false;
};

/** Get whether user should have access permission, instead of NO_ACCESS */
export const getHasAccess = (permissions: Permission[], authKey: string): boolean => {
  const permissionLevel = permissions.find(permission => permission.objectName === authKey)
    ?.permissionLevel;

  if (permissionLevel) {
    return (
      permissionLevel === PERM.read ||
      permissionLevel === PERM.readEdit ||
      permissionLevel === PERM.readEditDelete
    );
  }
  return false;
};

export const getCurrentLink = (pathname: string, platform: PlatformType) => {
  const linkComponents = pathname.split('/');
  const linkIndex = platform === PLATFORM.INTERNAL ? 2 : 3;
  const currentLink =
    isNotEmptyArray(linkComponents) && linkComponents.length > linkIndex
      ? `/${linkComponents[linkIndex]}`
      : pathname;

  return currentLink;
};
