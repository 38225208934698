import React, { ReactNode } from 'react';

import { Translate } from '@travel/translation';

import { PERIOD_TYPE_CONDITIONS } from 'constants/searchConditions';
import { PlatformType, PLATFORM } from 'utils/userScopeHelper';

import { isValidDate } from 'utils/calendar';

type SearchCondition = {
  tagName: ReactNode;
  tagId: string;
  disabledIn?: PlatformType[];
};

type TagOption = {
  tagId: string;
  tagName: ReactNode;
};

type FormData = {
  [field: string]: any;
};

export const SEARCH_MAXIMUM_DAYS = 31;

// TODO: unify all tag condition to this object.
export const SEARCH_TAGS: Array<SearchCondition & { regexp: RegExp }> = [
  {
    tagName: <Translate id="booking_management_fundamental.list.search.e_mail" />,
    tagId: 'email',
    regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    disabledIn: [PLATFORM.EXTRANET, PLATFORM.GROUP_EXTRANET],
  },
  {
    tagName: (
      <Translate id="booking_management_fundamental.list.search.distributor_booking_number" />
    ),
    tagId: 'distributorReservationId',
    regexp: /.+/, // allow any string
  },
  {
    tagName: (
      <Translate id="booking_management_fundamental.list.search.last_four_digits_and_bin_number" />
    ),
    tagId: 'creditCardNumber',
    regexp: /^\d+-?\d*?-?\d*?-?$/, // follow existing impl. TODO: can be revised.
    disabledIn: [PLATFORM.EXTRANET, PLATFORM.GROUP_EXTRANET],
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.provider_id" />,
    tagId: 'providerId',
    regexp: /^[0-9]{1,14}$/, // any number, max 14 digits
    disabledIn: [PLATFORM.EXTRANET],
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.plan_id" />,
    tagId: 'ratePlanId',
    regexp: /^[0-9]{1,14}$/, // any number, max 14 characters
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.item_id" />,
    tagId: 'itemId',
    regexp: /^[0-9a-zA-Z]{1,14}$/, // any alpha-numeric, max 14 characters
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.promotion_id" />,
    tagId: 'promotionId',
    regexp: /^[0-9a-zA-Z\-]{1,19}$/,
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.fax_code" />,
    tagId: 'faxCode',
    regexp: /^[0-9]*$/,
  },
];

// TODO: unify all tag condition to SEARCH_TAGS.
export const SEARCH_CONDITION_BY_ID: Array<SearchCondition & {
  first2Digits: string[];
  regexp?: RegExp;
}> = [
  {
    tagName: <Translate id="booking_management_fundamental.list.search.booking_number" />,
    tagId: 'bookingId',
    first2Digits: [],
    regexp: /^2$|^(20|21).*$/,
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.dp_booking_id" />,
    tagId: 'dpReservationId',
    first2Digits: [],
    regexp: /^(G|RA|RJ).*$/,
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.reservation_number" />,
    tagId: 'reservationId',
    first2Digits: [],
    regexp: /^2$|^(2[2-9]|WS|DU|RY).*$/,
  },
  {
    tagName: (
      <Translate id="booking_management_fundamental.list.search.distributor_booking_number" />
    ),
    tagId: 'bookingStatus',
    first2Digits: [],
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.transaction_number" />,
    tagId: 'transactionNumber',
    disabledIn: [PLATFORM.EXTRANET, PLATFORM.GROUP_EXTRANET],
    first2Digits: [],
    regexp: /^2$|^(20|21|RA|RJ).*$/,
  },
];

// TODO: unify all tag condition to SEARCH_TAGS.
export const SEARCH_CONDITION_BY_NAME: Array<SearchCondition> = [
  {
    tagName: <Translate id="booking_management_fundamental.list.search.address" />,
    tagId: 'address',
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.contact" />,
    tagId: 'contact',
    disabledIn: [PLATFORM.EXTRANET, PLATFORM.GROUP_EXTRANET],
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.applicant_first_name" />,
    tagId: 'firstName',
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.applicant_last_name" />,
    tagId: 'lastName',
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.guest_first_name" />,
    tagId: 'guestFirstName',
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.guest_last_name" />,
    tagId: 'guestLastName',
  },
];

export const SEARCH_TAGS_CSQMO: Array<SearchCondition & { regexp: RegExp }> = [
  {
    tagName: <Translate id="booking_management_fundamental.list.search.applicant_easy_id" />,
    tagId: 'easyId',
    regexp: /.+/, // allow any string
    disabledIn: [PLATFORM.EXTRANET, PLATFORM.GROUP_EXTRANET],
  },
  {
    tagName: <Translate id="booking_management_fundamental.list.search.member_id" />,
    tagId: 'memberId',
    regexp: /.+/, // allow any string
    disabledIn: [PLATFORM.EXTRANET, PLATFORM.GROUP_EXTRANET],
  },
];

export const getInitialValues = (formData: FormData, tagOptions: TagOption[]) => {
  const tags =
    (formData?.tags &&
      Object.keys(formData.tags).map(key => {
        return {
          searchText: formData.tags[key],
          selectedTag: {
            ...tagOptions.find(option => option.tagId === key),
            value: formData.tags[key],
          },
        };
      })) ||
    [];

  const fromDate =
    'periodFrom' in formData && isValidDate(formData?.periodFrom) ? formData.periodFrom : '';
  const toDate = 'periodTo' in formData && isValidDate(formData?.periodTo) ? formData.periodTo : '';

  return {
    ...formData,
    ...(!formData?.periodType && { periodType: PERIOD_TYPE_CONDITIONS.defaultSelectedValue }),
    periodFrom: fromDate,
    periodTo: toDate,
    // use default data only when periodFrom/periodTo key is not passed
    ...(formData &&
      !('periodFrom' in formData) &&
      !('periodTo' in formData) && {
        periodFrom: '',
        periodTo: '',
      }),
    tags,
  };
};

export default SEARCH_CONDITION_BY_ID;
