import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { LoginState } from './reducer';

export const getLogin = (state: RootState) => state.login;
export const getIsFetching = createSelector(getLogin, (state: LoginState) => state.isFetching);

export const getLoginResponse = createSelector(
  getLogin,
  (state: LoginState) => state.loginResponse,
);
export const getLoginErrors = createSelector(getLogin, (state: LoginState) => state.loginErrors);

export const getForgotPasswordResponse = createSelector(
  getLogin,
  (state: LoginState) => state.forgotPasswordResponse,
);
export const getForgotPasswordError = createSelector(
  getLogin,
  (state: LoginState) => state.forgotPasswordError,
);

export const getOneTimeKeyResponse = createSelector(
  getLogin,
  (state: LoginState) => state.oneTimeKeyResponse,
);
export const getOneTimeKeyError = createSelector(
  getLogin,
  (state: LoginState) => state.oneTimeKeyError,
);

export const getIsTokenAlmostExpire = createSelector(
  getLogin,
  (state: LoginState) => state.isTokenAlmostExpire,
);

export const getIsInitialLoad = createSelector(
  getLogin,
  (state: LoginState) => state.isInitialLoad,
);

export const getIsShowLoginDialog = createSelector(
  getLogin,
  (state: LoginState) => state.isShowLoginDialog,
);

export const getIsShowBrowserPrompt = createSelector(
  getLogin,
  (state: LoginState) => state.isShowBrowserPrompt,
);

export const getCurrentStageProviderDetail = createSelector(
  getLogin,
  (state: LoginState) => state.currentStageProviderDetail,
);

export const getIsJumping = createSelector(getLogin, (state: LoginState) => state.isJumping);

export const getVerifyTokenDetail = createSelector(
  getLogin,
  (state: LoginState) => state.verifyTokenDetail,
);

export const getNormalizedCookies = (state: RootState) => state._httpRequest?.normalizedCookies;
