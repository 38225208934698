import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { PlanList, PlanErrors, FeatureFilterValues } from 'Plan-Types';
import {
  fetchPlanListAsync,
  updatePlanBrowseParameter,
  updateFeatureFilterBrowseParameters,
  clearFeatureFilterBrowseParamaters,
  clearPlanList,
} from './actions';
import { initialBrowseParameter, initialFeatureFilterParamaters } from './initialValues';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchPlanListAsync.request], (state, action) => true)
  .handleAction([fetchPlanListAsync.success, fetchPlanListAsync.failure], (state, action) => false);

export const planList = createReducer({} as PlanList)
  .handleAction(fetchPlanListAsync.success, (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      ratePlans:
        state.ratePlans && shouldConcat
          ? state.ratePlans.concat(action.payload.ratePlans)
          : action.payload.ratePlans,
      items:
        state.items && shouldConcat
          ? state.items.concat(action.payload.items)
          : action.payload.items,
    };
  })
  .handleAction(clearPlanList, () => ({} as PlanList));

export const errors = createReducer([] as PlanErrors[]).handleAction(
  fetchPlanListAsync.failure,
  (state, action) => action.payload,
);

export const planListParameters = createReducer(initialBrowseParameter).handleAction(
  updatePlanBrowseParameter,
  (state, action) => action.payload,
);

export const featureFilterParameters = createReducer(
  initialFeatureFilterParamaters as FeatureFilterValues,
)
  .handleAction(updateFeatureFilterBrowseParameters, (state, action) => action.payload)
  .handleAction(
    clearFeatureFilterBrowseParamaters,
    () => initialFeatureFilterParamaters as FeatureFilterValues,
  );

const planReducer = combineReducers({
  isFetching,
  planList,
  errors,
  planListParameters,
  featureFilterParameters,
});

export default planReducer;
export type PlanState = ReturnType<typeof planReducer>;
