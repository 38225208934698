import React from 'react';

import { Link } from 'core/universalRouter/Link';
import { SubMenu as SubMenuType } from 'constants/menuItems';
import { cx } from '@travel/utils';
import styles from './navigationMenu.module.scss';

type Props = {
  items: SubMenuType[];
  isOpen: boolean;
  selectedItem: number;
  basePath: string;
  isDisabled?: boolean;
};

function SubMenu({ items, isOpen, selectedItem, basePath, isDisabled }: Props) {
  const subMenuClass = !isOpen ? styles.subMenu : styles.openedSubMenu;
  return (
    <ul
      className={cx(subMenuClass, isDisabled ? styles.disabled : '')}
      data-testid="subMenu-ul-wrapper"
    >
      {!isDisabled &&
        items.map((subItem, index) => {
          const selectedClass = index === selectedItem ? styles.linkSelected : '';
          const isSubItemDisabled = subItem.isDisabled;

          return (
            <li
              key={subItem.key}
              className={cx(styles.subMenuItem, isSubItemDisabled && styles.disabled)}
            >
              {isSubItemDisabled ? (
                <div className={cx(styles.subLink)}>
                  <span className={styles.suspendedContent}>{subItem.title}</span>
                </div>
              ) : (
                <Link
                  to={`${basePath}${subItem.link}`}
                  className={cx(selectedClass, styles.subLink)}
                >
                  <span className={styles.suspendedContent} data-testid="menuItems-subMenu-title">
                    {subItem.title}
                  </span>
                </Link>
              )}
            </li>
          );
        })}
    </ul>
  );
}

SubMenu.defaultProps = {
  isDisabled: false,
};

export default SubMenu;
