import React from 'react';

import { Translate } from 'core/translate';
import { ApplicableRatePlanBrowseParameters, FeatureFilterValues } from 'RatePlan-Types';

export const initialValues = {
  id: null,
  code: '',
  names: '',
  description: '',
  revenueType: 'GROSS',
  designation: '',
  bookingAcceptancePeriod: null,
  active: true,
  bookLimit: null,
  ratePlanAvanumberlability: null,
  applicableRooms: [],
  media: [],
  bookingAcceptanceDay: null,
  entryExit: null,
  cancelPolicies: [],
  losConstraints: [],
  paymentMethods: [],
  createDate: '',
  updateDate: '',
  featureGroups: [],
  dailyBookLimit: '',
  bookingNotice: {
    notifications: [],
    questions: [],
  },
  discountApplicability: null,
};

export const applicbleRatePlanBrowseParameter: ApplicableRatePlanBrowseParameters = {
  keyword: '',
  searchCondition: '',
  filter: [
    {
      label: <Translate id="Rate_plan_management.List.filter.status" />,
      id: 'status',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Status.Sales.Active" />,
          value: 'ACTIVE',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Sales.Inactive" />,
          value: 'INACTIVE',
          isCheck: false,
        },
      ],
    },
  ],
  sort: {
    options: [
      {
        text: <Translate id="Supplier_Enumeration.Sort.Creation_Date.New_Old" />,
        value: 'createDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Creation_Date.Old_New" />,
        value: 'createDateTime=asc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.New_Old" />,
        value: 'updateDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.Old_New" />,
        value: 'updateDateTime=asc',
      },
    ],
    selectedValue: 'createDateTime=desc',
  },
  offset: 0,
  limit: 100,
};

export const initialFeatureFilterParamaters: FeatureFilterValues = {
  benefits: [],
  status: [],
  revenueType: [],
  designation: [],
  feature: [],
};
