import { AppThunk, createAsyncAction, createStandardAction } from 'typesafe-actions';

import env from '@travel/env';
import { toDecodedQuery } from '@travel/utils';

import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { COOKIE_NAMES, setCookie } from 'utils/cookieStorage';

import { pushLocation } from 'store/__router/actions';

import { Account, AccountList, AccountQueryParams, ApiStatus, Errors } from 'AccessControl-Types';
import { ProviderGroupAccount } from 'ProviderGroup-Types';
import {
  ApiCreateAccount,
  ApiCreateGroupAccount,
  ApiDeleteAccount,
  ApiDeleteGroupAccount,
  ApiFetchAccount,
  ApiFetchAccounts,
  ApiFetchGroupAccount,
  ApiUpdateAccount,
  ApiUpdateGroupAccount,
} from './apis';

// Moved here to fix a circular import
export const setIsShowBrowserPrompt = createStandardAction('SET_IS_SHOW_BROWSER_PROMPT')<boolean>();

export const fetchAccountsAsync = createAsyncAction(
  'FETCH_ACCOUNTS_REQUEST',
  'FETCH_ACCOUNTS_SUCCESS',
  'FETCH_ACCOUNTS_FAILURE',
)<undefined, AccountList, Errors[]>();

export const fetchAccountAsync = createAsyncAction(
  'FETCH_ACCOUNT_REQUEST',
  'FETCH_ACCOUNT_SUCCESS',
  'FETCH_ACCOUNT_FAILURE',
)<undefined, Account, Errors[]>();

export const createAccountAsync = createAsyncAction(
  'CREATE_ACCOUNT_REQUEST',
  'CREATE_ACCOUNT_SUCCESS',
  'CREATE_ACCOUNT_FAILURE',
)<undefined, ApiStatus, Errors[]>();

export const updateAccountAsync = createAsyncAction(
  'UPDATE_ACCOUNT_REQUEST',
  'UPDATE_ACCOUNT_SUCCESS',
  'UPDATE_ACCOUNT_FAILURE',
)<undefined, Account, Errors[]>();

export const deleteAccountAsync = createAsyncAction(
  'DELETE_ACCOUNT_REQUEST',
  'DELETE_ACCOUNT_SUCCESS',
  'DELETE_ACCOUNT_FAILURE',
)<undefined, ApiStatus, Errors[]>();

export const fetchGroupAccountAsync = createAsyncAction(
  'FETCH_GROUP_ACCOUNT_REQUEST',
  'FETCH_GROUP_ACCOUNT_SUCCESS',
  'FETCH_GROUP_ACCOUNT_FAILURE',
)<undefined, AccountList, Errors[]>();

export const updateGroupAccountAsync = createAsyncAction(
  'UPDATE_GROUP_ACCOUNT_REQUEST',
  'UPDATE_GROUP_ACCOUNT_SUCCESS',
  'UPDATE_GROUP_ACCOUNT_FAILURE',
)<undefined, Account, Errors[]>();

export const createGroupAccountAsync = createAsyncAction(
  'CREATE_GROUP_ACCOUNT_REQUEST',
  'CREATE_GROUP_ACCOUNT_SUCCESS',
  'CREATE_GROUP_ACCOUNT_FAILURE',
)<undefined, ApiStatus, Errors[]>();

export const deleteGroupAccountAsync = createAsyncAction(
  'DELETE_GROUP_ACCOUNT_REQUEST',
  'DELETE_GROUP_ACCOUNT_SUCCESS',
  'DELETE_GROUP_ACCOUNT_FAILURE',
)<undefined, ApiStatus, Errors[]>();

export const setLoggedInUserStandard = createStandardAction('SET_LOGGED_IN_USER')<Account | {}>();
export const clearAccount = createStandardAction('CLEAR_ACCOUNT')();

export const fetchAccounts = (queryParameter: AccountQueryParams): AppThunk => async (
  dispatch,
  getState,
  args,
) => {
  const { apiClient } = args;
  dispatch(fetchAccountsAsync.request());
  const accounts = await ApiFetchAccounts(apiClient, toDecodedQuery(queryParameter));
  dispatch(fetchAccountsAsync.success(accounts));
};

export const fetchAccount = (userUuid: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchAccountAsync.request());
  const accountDetails = await ApiFetchAccount(apiClient, userUuid);
  dispatch(fetchAccountAsync.success(accountDetails));
};

export const createAccount = (data: Account, providerId?: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(createAccountAsync.request());
  try {
    const res = await ApiCreateAccount(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(createAccountAsync.success(res));
    if (providerId && res.userUuid) {
      dispatch(pushLocation(`/provider/${providerId}/access/${res.userUuid}`));
    }
  } catch (err) {
    dispatch(createAccountAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const updateAccount = (
  data: Account,
  userUuid: string,
  isLoggedInUser?: boolean,
  state?: { prevSearch: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateAccountAsync.request());
  try {
    const res = (await ApiUpdateAccount(apiClient, data, userUuid)) as Account;
    dispatch(updateAccountAsync.success(res));

    if (res.userUuid) {
      const url = res.providerUuid
        ? `/provider/${res.providerUuid}/access/${userUuid}`
        : `/internal/access/${userUuid}`;

      if (!res.providerUuid) {
        setCookie(COOKIE_NAMES.INPUT_LANGUAGE, data.language);
      }

      if (isLoggedInUser) {
        const basename = env('UNIVERSAL_BASE_NAME') ? `/${env('UNIVERSAL_BASE_NAME')}` : '';
        dispatch(setIsShowBrowserPrompt(false));
        window.location.href = `${window.location.origin}${basename}${url}`;
      } else {
        dispatch(pushLocation({ pathname: url, state }));
      }
    }
    dispatch(fetchGlobalErrorDialogAsync.success({ status: 200, message: '' }));
  } catch (err) {
    dispatch(updateAccountAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const deleteAccount = (userUuid: string, accountName: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(deleteAccountAsync.request());
  try {
    const res = await ApiDeleteAccount(apiClient, userUuid);
    res.message = accountName;
    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteAccountAsync.success(res));
  } catch (err) {
    dispatch(deleteAccountAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
  }
};

export const setLoggedInUser = (data: Account | {}): AppThunk => async dispatch => {
  dispatch(setLoggedInUserStandard(data));
};

export const fetchGroupAccount = (providerGroupId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchGroupAccountAsync.request());

  try {
    const groupAccount = await ApiFetchGroupAccount(apiClient, providerGroupId);
    dispatch(fetchGroupAccountAsync.success(groupAccount));
  } catch (err) {
    dispatch(fetchGroupAccountAsync.failure(err));
  }
};

export const updateGroupAccount = (
  groupId: string,
  data: Account,
  pathname?: string,
): AppThunk<Promise<ApiStatus | Account | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(updateGroupAccountAsync.request());

  try {
    const groupAccount = await ApiUpdateGroupAccount(apiClient, groupId, data);
    dispatch(updateGroupAccountAsync.success(groupAccount as Account));

    if (pathname) {
      dispatch(fetchGlobalErrorDialogAsync.success(groupAccount as ApiStatus));
      dispatch(pushLocation({ pathname }));
    }

    return groupAccount;
  } catch (err) {
    dispatch(updateGroupAccountAsync.failure(err));
  }
};

export const createGroupAccount = (
  providerGroupId: string,
  data: ProviderGroupAccount,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(createGroupAccountAsync.request());

  try {
    const res = await ApiCreateGroupAccount(apiClient, providerGroupId, data);
    dispatch(createGroupAccountAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: 200, message: '' }));
    dispatch(pushLocation(`/internal/providerGroup/${providerGroupId}`));
  } catch (err) {
    dispatch(createGroupAccountAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const deleteGroupAccount = (
  groupId: string,
): AppThunk<Promise<ApiStatus | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteGroupAccountAsync.request());
  try {
    const res = await ApiDeleteGroupAccount(apiClient, groupId);
    dispatch(deleteGroupAccountAsync.success(res));
    return res;
  } catch (err) {
    dispatch(deleteGroupAccountAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
  }
};
