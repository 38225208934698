import { universalRouterProps } from 'core/universalRouter/types';
import { fetchApplications } from 'store/applicationTracker/actions';
import { queryToObject } from '@travel/utils/src/queryString';
import { fetchCampaign } from 'store/campaign/actions';

type Context = {
  params: { campaignId: string };
  query: { [key: string]: string };
};

export default ({ store: { dispatch } }: universalRouterProps, context: Context) => {
  const { params, query } = context;

  return Promise.all([
    dispatch(fetchApplications(params.campaignId, queryToObject(query))),
    dispatch(fetchCampaign(params.campaignId)),
  ]);
};
