import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';

import { CurrenciesErrors, CurrencyItems } from 'SupplierCurrencies-Types';
import { fetchCurrencyList } from './api';

export const fetchCurrenciesAsync = createAsyncAction(
  'FETCH_CURRENCIES_REQUEST',
  'FETCH_CURRENCIES_SUCCESS',
  'FETCH_CURRENCIES_FAILURE',
)<undefined, CurrencyItems, CurrenciesErrors[]>();

export const setCurrency = createStandardAction('SET_CURRENCIES_LIST')<CurrencyItems>();

export const fetchCurrencies = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchCurrenciesAsync.request());
  try {
    const response = await fetchCurrencyList(apiClient);
    dispatch(fetchCurrenciesAsync.success(response));
  } catch (error) {
    dispatch(fetchCurrenciesAsync.failure([error as CurrenciesErrors]));
  }
};
