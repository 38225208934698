import { Request, Response } from 'express';
import { Store, AppThunkDispatch } from 'typesafe-actions';

export type universalRouterProps = {
  store: Omit<Store, 'dispatch'> & {
    dispatch: AppThunkDispatch;
  };
  req?: Request;
  res?: Response;
};

export class Location {
  readonly pathname: string = '/';
  readonly key?: string = '';
  readonly search?: string = '';
  readonly hash?: string = '';
  readonly pageName?: string = '';
  readonly state?: Object = {};
  constructor(init?: Partial<Location>) {
    Object.assign(this, init);
  }
}
