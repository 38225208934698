import React, { useContext, useEffect, useState, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSupportedLanguages } from '@travel/i18n';
import {
  Logout as IconLogout,
  LogoExtranet,
  LogoInternal,
  LogoProviderGroupExtranet,
  Menu as IconMenu,
} from '@travel/icons/ui';
import { IconTextLink, LanguageDropDown } from '@travel/ui'; //TODO QA#1: Will be changed to SupplierLogo once it is available
import UIComponentContext from '@travel/ui/contexts';
import { capitalize, cx, isNotEmptyArray, sortLanguage } from '@travel/utils';

import { useBasePath } from 'hooks/useBasePath';
import useDeviceType from 'hooks/useDeviceType';
import {
  getLoggedInUser,
  getLoggedInUserName,
  getLoggedInUserScope,
} from 'store/accessControl/selectors';
import { getProviderGroupBaseInfo } from 'store/providerGroup/selectors';
import { getProviderLanguage } from 'store/providersInformation/selectors';
import { getEnvNameIfNotProd } from 'utils/environmentHelpers';
import { getIsJumpedUser } from 'utils/userScopeHelper';
import { getBgColor } from '../getBackgroundColor';
import useRefreshInputLanguage from './useRefreshInputLanguage';

import { pushLocation } from 'store/__router/actions';

import { getUserPlatform, PLATFORM } from 'utils';
import { getIsUserNameClickable } from './helpers';

import { getLocation } from 'store/__router/selectors';
import { getInputLanguage, getIsDataInputLanguageAllow } from 'store/languages/selectors';

import styles from './header.module.scss';

type Props = {
  onCloseMenu: () => void;
  providerId: string;
  onLanguageChange: (newLanguage: string) => void;
};

type SupportedLanguage = {
  text: string;
  value: string;
  enName?: string;
};

function Header(props: Props) {
  const { providerId, onCloseMenu, onLanguageChange } = props;
  const deviceType = useDeviceType();
  const dispatch = useDispatch();
  const { LinkComponent } = useContext(UIComponentContext);
  const userName = useSelector(getLoggedInUserName);
  const userScope = useSelector(getLoggedInUserScope);
  const providerLanguage = useSelector(getProviderLanguage);
  const inputLanguage = useSelector(getInputLanguage);
  const location = useSelector(getLocation);
  const supportedLanguages = useSelector(getSupportedLanguages);
  const [langOptions, setLangOptions] = useState<SupportedLanguage[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const platform = getUserPlatform(userScope);
  const envName = getEnvNameIfNotProd();
  const groupId = useSelector(getProviderGroupBaseInfo).groupId;
  const isDataInputLanguageAllow = useSelector(getIsDataInputLanguageAllow);
  const { userUuid, scope, permissions } = useSelector(getLoggedInUser);
  const basePath = useBasePath();
  const isUserNameClickable = getIsUserNameClickable(scope, permissions);
  const bgColor = getBgColor(platform);
  const selectedLanguage: string = inputLanguage || 'en-US';
  const ICON_SIZE = deviceType === 'sp' ? 24 : 32;
  const isJumpedUser = getIsJumpedUser(scope);

  const isNotLoginPage =
    location.pathname !== '/' &&
    !location.pathname.includes('login') &&
    !location.pathname.includes('logout') &&
    !location.pathname.includes('notFound') &&
    !location.pathname.includes('unauthorized');

  const handleClickLogout = () => {
    dispatch(pushLocation('/logout'));
  };

  const handleClickUsername = () => {
    const path = `${basePath}/access/${userUuid}`;
    dispatch(pushLocation(path));
  };

  // TODO: This is temporary. Will be removed in the future
  const handleLogoClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    let newLocation = '/login';
    if (isNotLoginPage) {
      if (platform === PLATFORM.EXTRANET && providerId) {
        newLocation = `/provider/${providerId}`;
      } else if (platform === PLATFORM.GROUP_EXTRANET) {
        newLocation = `/providerGroup/${groupId}`;
      } else if (platform === PLATFORM.INTERNAL) {
        newLocation = '/internal';
      }
    }
    dispatch(pushLocation(newLocation));
  };

  const handleOnSearchLang = (value: string) => {
    setKeyword(value);
  };

  const handleOnClearLang = () => {
    setKeyword('');
  };

  useEffect(() => {
    const capitalizedKeyword = capitalize(keyword);
    const sortedSupportedLanguages = sortLanguage(
      supportedLanguages
        .sort((a, b) => a?.enName?.localeCompare(b.enName))
        .map(supportedLanguage => ({
          text: supportedLanguage.name,
          value: supportedLanguage.code,
          enName: supportedLanguage.enName,
        })),
      providerLanguage,
    ).filter(option => {
      const { enName, text } = option;
      return enName?.includes(capitalizedKeyword) || text?.includes(capitalizedKeyword);
    });

    setLangOptions(sortedSupportedLanguages);
  }, [keyword, providerLanguage, supportedLanguages]);

  useRefreshInputLanguage({ pathname: location.pathname, platform, selectedLanguage });

  return (
    <header
      className={cx(styles.container, bgColor)}
      data-locator-id="Header-0be6e37a-66b1-42b1-9426-2acaf41f2de0"
    >
      <LinkComponent className={styles.logo} href="/" onClick={handleLogoClick}>
        <>
          {platform === PLATFORM.INTERNAL ? (
            <LogoInternal />
          ) : platform === PLATFORM.GROUP_EXTRANET ? (
            <LogoProviderGroupExtranet />
          ) : (
            <LogoExtranet />
          )}
          {envName && <span className={styles.envName}>{`${envName} Environment`}</span>}
        </>
      </LinkComponent>

      {platform !== PLATFORM.OUTSIDE && isNotEmptyArray(supportedLanguages) && (
        <LanguageDropDown
          languageCode={selectedLanguage}
          options={langOptions}
          supportedLanguages={supportedLanguages}
          keyword={keyword}
          onChange={onLanguageChange}
          onSearch={handleOnSearchLang}
          onClear={handleOnClearLang}
          isDisabled={!isDataInputLanguageAllow}
        />
      )}

      {isNotLoginPage && (
        <>
          <div className={styles.userInfo}>
            {isUserNameClickable ? (
              <IconTextLink
                className={styles.userName}
                onClick={handleClickUsername}
                text={userName}
                hoveredColor="white"
                unhoveredColor="white"
                data-testid="logged-in-user-name"
              />
            ) : (
              <p data-testid="logged-in-user-name">{userName}</p>
            )}
          </div>

          {!isJumpedUser && (
            <IconTextLink
              className={styles.logoutButton}
              onClick={handleClickLogout}
              icon={<IconLogout className={styles.logoutIcon} size={ICON_SIZE} />}
              text=""
              hoveredColor="white"
              unhoveredColor="white"
              data-testid="header-logout-button"
            />
          )}
        </>
      )}
    </header>
  );
}

export default Header;
