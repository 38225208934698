import { universalRouterProps } from 'core/universalRouter/types';
import { fetchFilterComponent } from 'store/filterComponent/actions';
import { fetchConsumerFilterGroups } from 'store/filterGroup/actions';

type Location = {
  params: {
    id: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  const { id } = location.params;
  let promises: Array<Promise<void>> = [
    dispatch(fetchConsumerFilterGroups()),
    dispatch(fetchFilterComponent(id)),
  ];

  return Promise.all(promises);
};
