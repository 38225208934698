import { isNotEmptyArray } from '@travel/utils';
import { ServiceTypes } from 'SupplierService-Types';
import { USER_SCOPE } from 'utils';
import { UserScopeType } from 'utils/userScopeHelper';
import { arrayToQueryObject } from 'utils/searchConditions';

// This function determines whether filters should be available for either RatePlanList Page or PlanList Page
export const isFilteringAvailable = (
  userScope: string,
  providerDesignation: string[],
  serviceTypesMasterData: ServiceTypes,
) => {
  if (USER_SCOPE.PROVIDER === userScope) {
    let filterCount = 0;
    serviceTypesMasterData.forEach(serviceType => {
      serviceType.designations?.forEach(item => {
        if (!item.isHidden && providerDesignation.includes(item.designation)) {
          filterCount += 1;
        }
      });
    });
    return providerDesignation.length >= 2 && filterCount >= 2;
  }

  return providerDesignation.length >= 2;
};

// this type is for functions that determines whether we need a filter for the page
// and since the Inventory Page, and RatePlan List, PlanList page required different conditions for the filter
type isFilteringAvailableFn = (
  userScope: UserScopeType,
  providerDesignation: string[],
  serviceTypesMasterData: ServiceTypes,
) => boolean;

// this function determines the initial filter-options required in the query when the page loads
// this function collects filter options based on user type and designation for the provider
export const getInitialQuery = (
  providerDesignation: string[],
  serviceTypesMasterData: ServiceTypes,
  userScope: UserScopeType,
  isFilteringAvailable: isFilteringAvailableFn,
) => {
  const designation: string[] = [];
  if (
    isNotEmptyArray(serviceTypesMasterData) &&
    isNotEmptyArray(providerDesignation) &&
    isFilteringAvailable(userScope, providerDesignation, serviceTypesMasterData)
  ) {
    serviceTypesMasterData.forEach(serviceType => {
      serviceType.designations?.forEach(item => {
        if (!item.isHidden && providerDesignation.includes(item.designation)) {
          designation.push(item.designation);
        }
      });
    });
  }
  return arrayToQueryObject(designation, 'designation');
};

export function getHiddenDesignations(
  serviceTypesMasterData: ServiceTypes,
  providerDesignation: string[],
) {
  const designations: string[] = [];
  // Check if Provider's designation is not hidden from the master data
  if (isNotEmptyArray(serviceTypesMasterData) && isNotEmptyArray(providerDesignation)) {
    serviceTypesMasterData.forEach(serviceType => {
      serviceType.designations?.forEach(item => {
        if (!item.isHidden && providerDesignation.includes(item.designation)) {
          designations.push(item.designation);
        }
      });
    });
  }
  return designations;
}

export const isDesignationPresent = (checkAgainst: string[], designation: string) => {
  return checkAgainst.includes(designation);
};
