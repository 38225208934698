import React from 'react';
import { Translate } from 'core/translate';

export const defaultChannelManager = {
  id: '',
  name: '',
  apiKey: null,
  apiKeyRegenerate: null,
  ipAddresses: [],
  active: true,
  notificationUrl: '',
  payVaultUrlId: '',
  httpHeaders: [],
  createDateTime: '',
  updateDateTime: '',
};

export const initialSortValue = {
  options: [
    {
      value: 'updateDateTime=desc',
      text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.New_Old" />,
    },
    {
      value: 'updateDateTime=asc',
      text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.Old_New" />,
    },
  ],
  selectedValue: 'updateDateTime=desc',
};

export const initialFilterValue = [
  {
    label: <Translate id="Channel_Manager_Management.List.Filter.Status" />,
    id: 'status',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Status.Activation.Active" />,
        value: 'active',
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Status.Activation.Inactive" />,
        value: 'inactive',
        isCheck: false,
      },
    ],
  },
];
