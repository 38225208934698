import React, { ReactNode } from 'react';
import { cx } from '@travel/utils';
import styles from './balloonSupplier.module.scss';

export type ArrowDirection =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

type Props = {
  className?: string;
  children?: ReactNode;

  /** arrow direction */
  direction?: ArrowDirection;

  /** callback function when balloon is clicked */
  onClick?: () => void;

  /** flag to define content min-height */
  hasContentMinHeight?: boolean;
};

export default function BalloonSupplier(props: Props) {
  const { className, hasContentMinHeight, ...rest } = props;
  const computedClassName = cx(
    className,
    styles.balloon,
    hasContentMinHeight ? styles.ballMinH : '',
  );

  return (
    <div data-testid="balloonSupplier-wrapper" className={computedClassName} {...rest}>
      <div className={cx(styles.text, hasContentMinHeight ? styles.textMinH : '')}>
        {props.children}
      </div>
    </div>
  );
}

BalloonSupplier.defaultProps = {
  direction: '',
  children: '',
  className: '',
  hasContentMinHeight: true,
  onClick: () => {},
};
