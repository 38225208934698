import { isNotEmptyArray } from '@travel/utils';
import { SelectBoxOption as Option } from '@travel/ui';

type API = {
  name: string;
  id: string;
};

export const apiDataToOptions = (data: API[] | undefined): Option[] => {
  const initOption = { text: '-', value: '' };

  if (!isNotEmptyArray(data)) return [initOption];

  const newOptions = data.map(datum => {
    return {
      text: datum.name,
      value: datum.id,
    };
  });

  return [initOption, ...newOptions];
};
