import { ApiClient } from '@travel/api-client';

import {
  ApiStatus,
  Promotions,
  SalesPromotionDetail,
  SalesPromotionDetailAPI,
} from 'SalesPromotion-Types';

const ApiPutPromotionTimeout = 60 * 1000; // 60s

// TODO https://jira.rakuten-it.com/jira/browse/EVEREST-10807
export const ApiFetchPromotions = (http: ApiClient, queries: any): Promise<Promotions> => {
  return http.get('/provider-api/extranet/promotions', { values: queries });
};

export const ApiFetchSinglePromotion = (
  http: ApiClient,
  promotionId: string,
): Promise<SalesPromotionDetailAPI> => http.get(`/provider-api/extranet/promotions/${promotionId}`);

export const ApiFetchDeletablePromotion = (
  http: ApiClient,
  promotionId: string,
): Promise<ApiStatus> =>
  http
    .get(`/provider-api/extranet/promotions/${promotionId}/deletable`, {
      ignoreCommonErrorHandler: true,
    })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiPostPromotion = (http: ApiClient, data: SalesPromotionDetail): Promise<ApiStatus> =>
  http
    .post('/provider-api/extranet/promotions', { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiPutPromotion = (
  http: ApiClient,
  data: SalesPromotionDetail,
  promotionId: string,
): Promise<ApiStatus> =>
  http
    .put(`/provider-api/extranet/promotions/${promotionId}`, {
      values: data,
      timeout: ApiPutPromotionTimeout,
    })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiDeleteSinglePromotion = (
  http: ApiClient,
  promotionId: string,
): Promise<ApiStatus> =>
  http
    .delete(`/provider-api/extranet/promotions/${promotionId}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const fetchCSVData = (http: ApiClient, promotionId: string) =>
  http.get(`/provider-api/extranet/promotions/${promotionId}/downloadInvitations`, {
    ignoreCommonErrorHandler: true,
  });
