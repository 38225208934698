import { ApiClient } from '@travel/api-client';

import {
  ApiStatus,
  PlaceCategoryOption,
  PlaceDetail,
  PlaceListQueryParams,
  PlaceSimple,
  UploadResError,
  UploadResSuccess,
} from 'Place-Types';

export const ApiFetchPlaces = (
  http: ApiClient,
  params: PlaceListQueryParams,
): Promise<PlaceDetail> => http.get('/provider-api/extranet/places', { values: params });

export const ApiFetchPlacesByParentId = (
  http: ApiClient,
  parentId?: string,
): Promise<PlaceSimple[]> =>
  http.get('/provider-api/extranet/places/findByParentId', {
    values: parentId ? { parentId } : {},
  });

export const ApiFetchPlaceDetail = (http: ApiClient, placeId: string): Promise<PlaceDetail> =>
  http.get(`/provider-api/extranet/places/${placeId}`);

export const ApiPostPlace = (http: ApiClient, data: PlaceDetail): Promise<ApiStatus> =>
  http.post('/provider-api/extranet/places', { values: data }).then((data: ApiStatus) => data);

export const ApiPutPlace = (
  http: ApiClient,
  data: PlaceDetail,
  placeId: string,
): Promise<ApiStatus> =>
  http
    .put(`/provider-api/extranet/places/${placeId}`, { values: data })
    .catch((data: ApiStatus) => data);

export const ApiFetchPlaceCategories = (http: ApiClient): Promise<PlaceCategoryOption[]> =>
  http.get('/provider-api/extranet/places/categories');

export const ApiSEOUpload = (http: ApiClient, formData: FormData) =>
  http.post('/provider-api/extranet/places/seoIndex/upload', {
    values: formData,
    ignoreCommonErrorHandler: true,
  });

export const ApiSEODownload = (http: ApiClient) =>
  http.get('/provider-api/extranet/places/seoIndex/download', {}).catch((data: ApiStatus) => data);

export const ApiUploadFile = (http: ApiClient, formData: any) => {
  return http
    .post(`/provider-api/extranet/places/upload`, {
      values: formData,
      ignoreCommonErrorHandler: true,
    })
    .then((data: UploadResSuccess) => ({ ...data, status: 200 }))
    .catch((data: UploadResError) => data);
};
