import { CampaignErrors, CampaignList, CouponCodes, UninvitedProviders } from 'Campaign-Types';
import {
  clearCoupon,
  clearCurrentCampaign,
  createCampaignAsync,
  deleteCampaignAsync,
  fetchCampaignAsync,
  fetchCampaignListAsync,
  fetchDeletableCampaignAsync,
  generateCouponAsync,
  generateCouponsAsync,
  putDeletableDialogAsync,
  submitUninvitedProvidersAsync,
  updateCampaignAsync,
  updateCampaignBrowseParameter,
} from './actions';
import {
  defaultCampaignDeletable,
  defaultCampaignItem,
  defaultDeletableDialog,
} from 'store/campaign/initialValues';

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { initialBrowseParameter } from './initialValues';
import { isNotNullish } from '@travel/utils';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchCampaignListAsync.request], (state, action) => true)
  .handleAction(
    [fetchCampaignListAsync.success, fetchCampaignListAsync.failure],
    (state, action) => false,
  );

export const isCreating = createReducer(false)
  .handleAction([createCampaignAsync.request, updateCampaignAsync.request], () => true)
  .handleAction(
    [
      createCampaignAsync.success,
      createCampaignAsync.failure,
      updateCampaignAsync.success,
      updateCampaignAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction(deleteCampaignAsync.request, () => true)
  .handleAction([deleteCampaignAsync.success, deleteCampaignAsync.failure], () => false);

export const list = createReducer({} as CampaignList).handleAction(
  fetchCampaignListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const item = createReducer(defaultCampaignItem)
  .handleAction(fetchCampaignAsync.success, (state, action) => {
    const { payload } = action;
    const updatedPayload = {
      ...payload,
      incentive: {
        incentiveType: payload.incentiveType,
        discount: payload.discount
          ? payload.discount
          : {
              discountValue: payload?.point?.pointValue && payload.point.pointValue,
              discountValueType: payload?.point?.pointValueType && payload.point.pointValueType,
              discountPeak: payload?.point?.pointPeak && payload.point.pointPeak,
            },
      },
      offer: {
        offerType: payload.offerType,
        couponCodes: payload.couponCodes,
        codeChecked: payload.couponCodes ? payload.couponCodes.length > 0 : false,
      },
      promotionCap: {
        maxUsage: payload.promotionCap.maxUsage,
        maxUsagePerUser: payload.promotionCap.maxUsagePerUser,
        maxUsagePerProvider: payload.promotionCap.maxUsagePerProvider,
      },
      searchCondition: {
        ...payload.searchCondition,
        guestCapacity: payload.searchCondition.guestsNumber,
      },
    };
    return updatedPayload;
  })
  .handleAction(clearCurrentCampaign, (state, action) => defaultCampaignItem);

export const deletable = createReducer(defaultCampaignDeletable).handleAction(
  fetchDeletableCampaignAsync.success,
  (state, action) => {
    const { payload } = action;
    const updatedPayload = {
      ...payload,
      statusCode: payload.status,
    };
    return updatedPayload;
  },
);

export const deletableDialog = createReducer(defaultDeletableDialog).handleAction(
  putDeletableDialogAsync.success,
  (state, action) => action.payload,
);

export const couponCodes = createReducer([] as CouponCodes[])
  .handleAction(generateCouponsAsync.success, (state, action) => {
    const couponCodes = [...action.payload];

    /** Update null values of Active and Note property from Coupons */
    couponCodes.forEach((coupon: CouponCodes) => {
      if (!isNotNullish(coupon.active)) {
        coupon.active = true;
      }
      if (!isNotNullish(coupon.note)) {
        coupon.note = '';
      }
    });

    return couponCodes;
  })
  .handleAction(clearCoupon, () => {
    return [];
  });

export const uninvitedprovidersList = createReducer([] as UninvitedProviders[]).handleAction(
  submitUninvitedProvidersAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as CampaignErrors[]).handleAction(
  fetchCampaignAsync.failure ||
    fetchCampaignListAsync.failure ||
    fetchDeletableCampaignAsync.failure ||
    generateCouponsAsync.failure ||
    generateCouponAsync.failure,
  (state, action) => action.payload,
);

export const browseParameters = createReducer(initialBrowseParameter).handleAction(
  updateCampaignBrowseParameter,
  (state, action) => action.payload,
);

const campaignReducer = combineReducers({
  isFetching,
  list,
  item,
  deletable,
  deletableDialog,
  couponCodes,
  errors,
  browseParameters,
  isCreating,
  isDeleting,
  uninvitedprovidersList,
});

export default campaignReducer;
export type CampaignState = ReturnType<typeof campaignReducer>;
