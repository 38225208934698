import React, { useCallback, useEffect, useRef, useState, ChangeEvent } from 'react';

import { cx } from '@travel/utils';

import RadioButton, { Props as RadioButtonProps } from '../RadioButton';

import styles from './radioButtonGroup.module.scss';

export type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Custom style for Options */
  optionClassName?: string;
  /** Custom style for checked Options */
  isCheckedClassName?: string;
  /** List of options */
  options: Array<RadioButtonProps>;
  /** default selected option */
  defaultSelectedOption?: string;
  /** On select handler */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /** This would be the unique identifier for the RadioButton */
  name: string;
  /** disabled all RadioButton */
  isDisabled?: boolean;
  /** Class name for each radio button checkmark */
  checkmarkClassName?: string;
  /** Class name for each radio button label */
  labelClassName?: string;
};

function RadioButtonGroup(props: Props) {
  const {
    className,
    optionClassName,
    options,
    onChange,
    defaultSelectedOption,
    name,
    isDisabled,
    isCheckedClassName,
    labelClassName,
    checkmarkClassName,
    ...rest
  } = props;
  const [selectedValue, setSelectedValue] = useState(defaultSelectedOption);
  const [tabindex, setTabindex] = useState(0);
  const [focusedValue, setFocusValue] = useState(0);
  useEffect(() => {
    setSelectedValue(props.defaultSelectedOption);
  }, [props.defaultSelectedOption]);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event) return;
    onChange(event);
    setSelectedValue(event.currentTarget.value);
  };

  const onFocus = useCallback(
    (event: React.FocusEvent) => {
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        let index = options.findIndex(option => option.value === selectedValue);
        index = index === -1 ? 0 : index;
        setFocusValue(index);
        setTabindex(-1);
        buttonRefs.current[index]?.focus();
      }
    },
    [options, selectedValue],
  );

  const onBlur = useCallback((event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setTabindex(0);
    }
  }, []);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        const nextButton = focusedValue === options.length - 1 ? 0 : focusedValue + 1;
        setFocusValue(nextButton);
        buttonRefs.current[nextButton]?.focus();
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        const nextButton = focusedValue === 0 ? options.length - 1 : focusedValue - 1;
        setFocusValue(nextButton);
        buttonRefs.current[nextButton]?.focus();
      }
    },
    [focusedValue, options.length],
  );

  const buttonRefs = useRef<(HTMLInputElement | null)[]>([]);

  return (
    <div
      tabIndex={tabindex}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      className={className}
      {...rest}
    >
      {options.map((option, index) => (
        <RadioButton
          {...option}
          isCheckedClassName={isCheckedClassName}
          key={option.value}
          className={cx(optionClassName, styles.option)}
          onChange={onChangeHandler}
          isChecked={selectedValue === option.value}
          isDisabled={isDisabled || option.isDisabled}
          ref={ref => (buttonRefs.current[index] = ref)}
          labelClassName={labelClassName}
          checkmarkClassName={checkmarkClassName}
        />
      ))}
    </div>
  );
}

RadioButtonGroup.defaultProps = {
  className: '',
  optionClassName: '',
  defaultSelectedOption: '',
  name: '',
  isDisabled: false,
};

export default RadioButtonGroup;
