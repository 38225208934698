import { toQuery, toDecodedQuery } from '@travel/utils';

export type Query = {
  keyword?: string;
  sort?: { [key: string]: string };
  filter?: { status: string[] };
  offset?: number;
  limit?: number;
  searchCondition?: string;
};

export const transformToQueryObject = (params: Query, filterIdList: string[]) => {
  if (!params) return toQuery({});
  const paramsUpdate = { ...params };
  delete paramsUpdate['keyword'];
  delete paramsUpdate['searchCondition'];
  const selectedValueKey = Object.keys(paramsUpdate).find(key => {
    const isExistFilter = key.indexOf('[') !== -1;
    const newKey = isExistFilter ? key.slice(0, key.indexOf('[')) : key;
    return !filterIdList.includes(newKey);
  }) as keyof Query;
  delete paramsUpdate[selectedValueKey || ''];
  const builtQuery = {
    filter: paramsUpdate,
    keyword: encodeURIComponent(params.keyword || ''),
    searchCondition: params.searchCondition,
    sort: {
      [selectedValueKey || '']: params[selectedValueKey || ''],
    },
  };
  return toDecodedQuery(builtQuery);
};
