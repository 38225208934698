import loadable from '@loadable/component';
import React from 'react';

import App from 'core/App';
import accessControlResolver from 'pages/AccessControl/resolver';
import accessControlDetailResolver from 'pages/AccessControlCreate/resolver';
import accountingReportResolver from 'pages/AccountingReport/resolver';
import aliasCreateResolver from 'pages/AliasCreate/resolver';
import aliasResolver from 'pages/Aliases/resolver';
import bookingDetailResolver from 'pages/BookingDetail/resolver';
import bookingListResolver from 'pages/BookingList/resolver';
import cancelPolicyResolver from 'pages/CancellationPolicy/components/ManageCancellationPolicy/resolver';
import cancelPolicyListResolver from 'pages/CancellationPolicy/resolver';
import createItemResolver from 'pages/CreateItem/resolver';
import customerReviewDetailResolver from 'pages/CustomerReviewDetail/resolver';
import customerReviewsResolver from 'pages/CustomerReviews/resolver';
import providerInfoResolver from 'pages/EditProvider/resolver';
import extraRateDetailResolver from 'pages/ExtraRateDetail/resolver';
import extraRateDetailViaControlResolver from 'pages/ExtraRateDetailViaControl/resolver';
import extraRateListResolver from 'pages/ExtraRateList/resolver';
import highlightContentsResolver from 'pages/HighlightContents/resolver';
import highlightContentsDetailResolver from 'pages/HighlightContentsDetail/resolver';
import introductionContentsResolver from 'pages/IntroductionContents/resolver';
import inventoryItemResolver from 'pages/Inventory/resolver';
import mediaDetailResolver from 'pages/MediaDetail/resolver';
import planResolver from 'pages/PlanList/resolver';
import providerCampaignDetailResolver from 'pages/ProviderCampaignDetail/resolver';
// import inventoryControlResolver from 'pages/InventoryControl/resolver';
import providerCampaignListResolver from 'pages/ProviderCampaignList/resolver';
import providerMediaListResolver from 'pages/ProviderMediaList/resolver';
import providerSalesPromotionListResolver from 'pages/ProviderSalesPromotion/resolver';
import providerSalesPromotionResolver from 'pages/ProviderSalesPromotionCreate/resolver';
import rateControlResolver from 'pages/RateControl/resolver';
import rateControlExtraRateForAgeResolver from 'pages/RateControlExtraRateForAge/resolver';
import ratePlanResolver from 'pages/RatePlan/resolver';
import ratePlanListResolver from 'pages/RatePlanList/resolver';
import reviewAnalyticsResolver from 'pages/ReviewAnalytics/resolver';
import SkeletonPage from 'pages/SkeletonPage';
import visualContentGroupDetailResolver from 'pages/VisualContentGroupCreate/resolver';
import visualContentGroupListResolver from 'pages/VisualContentGroupList/resolver';

import { SITE_MENU_MAP } from 'constants/site-menu';
import { universalRouterProps } from './types';

export default (props: universalRouterProps) => {
  const { req } = props;
  const isServer = !!req;

  return [
    {
      path: '/provider/:providerId',
      async action(context: any) {
        const children = await context.next();
        return <App {...context}>{children}</App>;
      },
      children: [
        {
          path: '',
          async action(context: any) {
            const Home = loadable(() => import(/* webpackChunkName: "pages-Home" */ 'pages/Home'));
            return <Home {...context} />;
          },
        },
        {
          path: '/items',
          authKey: 'ItemMgmt',
          async action(context: any) {
            const Inventory = loadable(
              () => import(/* webpackChunkName: "pages-Inventory" */ 'pages/Inventory'),
              {
                fallback: <SkeletonPage pageType={'list'} hasMedia={true} />,
              },
            );
            if (isServer) {
              await inventoryItemResolver(props, context);
            } else if (!context.skipResolver) {
              inventoryItemResolver(props, context);
            }
            return <Inventory {...context} />;
          },
        },
        {
          path: '/items/create',
          authKey: 'ItemMgmt',
          isCreate: true,
          async action(context: any) {
            const CreateItem = loadable(
              () => import(/* webpackChunkName: "pages-CreateItem" */ 'pages/CreateItem'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await createItemResolver(props, context);
            } else if (!context.skipResolver) {
              createItemResolver(props, context);
            }

            return <CreateItem {...context} />;
          },
        },
        {
          path: '/items/inventory-control',
          authKey: 'InvtryMgmt',
          async action(context: any) {
            const InventoryControl = loadable(() =>
              import(/* webpackChunkName: "pages-InventoryControl" */ 'pages/InventoryControl'),
            );
            return <InventoryControl {...context} />;
          },
        },
        {
          path: '/plans/inventory-control',
          authKey: 'InvtryMgmt',
          async action(context: any) {
            const InventoryControl = loadable(() =>
              import(/* webpackChunkName: "pages-InventoryControl" */ 'pages/InventoryControl'),
            );
            return <InventoryControl {...context} />;
          },
        },
        {
          path: '/items/:itemId/inventory-control',
          authKey: 'InvtryMgmt',
          async action(context: any) {
            const InventoryControl = loadable(() =>
              import(/* webpackChunkName: "pages-InventoryControl" */ 'pages/InventoryControl'),
            );
            return <InventoryControl {...context} />;
          },
        },
        {
          path: '/items/:itemId',
          authKey: 'ItemMgmt',
          async action(context: any) {
            const CreateItem = loadable(
              () => import(/* webpackChunkName: "pages-CreateItem" */ 'pages/CreateItem'),
              {
                fallback: <SkeletonPage pageType={'detail'} hasMedia={true} />,
              },
            );
            if (isServer) {
              await createItemResolver(props, context);
            } else if (!context.skipResolver) {
              createItemResolver(props, context);
            }

            return <CreateItem {...context} />;
          },
        },
        {
          path: '/ratePlans',
          authKey: 'RatePlanMgmt',
          async action(context: any) {
            const RatePlanList = loadable(
              () => import(/* webpackChunkName: "pages-RatePlanList" */ 'pages/RatePlanList'),
              {
                fallback: <SkeletonPage pageType={'list'} />,
              },
            );

            if (isServer) {
              await ratePlanListResolver(props, context);
            } else if (!context.skipResolver) {
              ratePlanListResolver(props, context);
            }

            return <RatePlanList {...context} />;
          },
        },
        {
          path: '/ratePlans/create',
          authKey: 'RatePlanMgmt',
          isCreate: true,
          async action(context: any) {
            const RatePlan = loadable(
              () => import(/* webpackChunkName: "pages-RatePlan" */ 'pages/RatePlan'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            const urlParams = context.params;

            if (isServer) {
              await ratePlanResolver(props, urlParams);
            } else if (!context.skipResolver) {
              ratePlanResolver(props, urlParams);
            }

            return <RatePlan {...context} />;
          },
        },
        {
          path: '/ratePlans/:ratePlanId',
          authKey: 'RatePlanMgmt',
          async action(context: any) {
            const RatePlan = loadable(
              () => import(/* webpackChunkName: "pages-RatePlan" */ 'pages/RatePlan'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            const urlParams = context.params;

            if (isServer) {
              await ratePlanResolver(props, urlParams);
            } else if (!context.skipResolver) {
              ratePlanResolver(props, urlParams);
            }

            return <RatePlan {...context} />;
          },
        },
        {
          path: '/plans/item-rate-plan-availability-control',
          authKey: 'PlanMgmt',
          async action(context: any) {
            const ItemRatePlanAvailabilityControl = loadable(() =>
              import(
                /* webpackChunkName: "pages-ItemRatePlanAvailabilityControl" */ 'pages/ItemRatePlanAvailabilityControl'
              ),
            );
            return <ItemRatePlanAvailabilityControl {...context} />;
          },
        },
        {
          path: '/plans/rate-plan-availability-control',
          authKey: 'PlanMgmt',
          async action(context: any) {
            const RatePlanAvailabilityControl = loadable(() =>
              import(
                /* webpackChunkName: "pages-RatePlanAvailabilityControl" */ 'pages/RatePlanAvailabilityControl'
              ),
            );
            return <RatePlanAvailabilityControl {...context} />;
          },
        },
        {
          path: '/plans/rate-control',
          authKey: 'PlanMgmt',
          async action(context: any) {
            const RateControl = loadable(() =>
              import(/* webpackChunkName: "pages-RateControl" */ 'pages/RateControl'),
            );
            if (isServer) {
              await rateControlResolver(props, context);
            } else if (!context.skipResolver) {
              await rateControlResolver(props, context);
            }
            return <RateControl {...context} />;
          },
        },
        {
          path: '/plans/rate-control/extra-rate-for-age',
          authKey: 'PlanMgmt',
          async action(context: any) {
            const RateControlExtraRateForAge = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-RateControlExtraRateForAge" */ 'pages/RateControlExtraRateForAge'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );
            if (isServer) {
              await rateControlExtraRateForAgeResolver(props, context);
            } else if (!context.skipResolver) {
              await rateControlExtraRateForAgeResolver(props, context);
            }
            return <RateControlExtraRateForAge {...context} />;
          },
        },
        {
          path: '/plans/rate-control/extra-rate-for-age/create',
          authKey: 'PlanMgmt',
          isCreate: true,
          async action(context: any) {
            const ExtraRateDetailViaControl = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ExtraRateDetailViaControl" */ 'pages/ExtraRateDetailViaControl'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );
            if (isServer) {
              await extraRateDetailViaControlResolver(props, context);
            } else {
              extraRateDetailViaControlResolver(props, context);
            }
            return <ExtraRateDetailViaControl {...context} />;
          },
        },
        {
          path: '/plans/rate-control/extra-rate-for-age/:extraRateId',
          authKey: 'PlanMgmt',
          async action(context: any) {
            const ExtraRateDetailViaControl = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ExtraRateDetailViaControl" */ 'pages/ExtraRateDetailViaControl'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );
            if (isServer) {
              await extraRateDetailViaControlResolver(props, context);
            } else {
              extraRateDetailViaControlResolver(props, context);
            }

            return <ExtraRateDetailViaControl {...context} />;
          },
        },
        {
          path: '/extra-rates',
          authKey: 'XtraRateMgmt',
          async action(context: any) {
            const ExtraRateList = loadable(
              () => import(/* webpackChunkName: "pages-ExtraRateList" */ 'pages/ExtraRateList'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );
            if (isServer) {
              await extraRateListResolver(props, context);
            } else if (!context.skipResolver) {
              extraRateListResolver(props, context);
            }
            return <ExtraRateList {...context} />;
          },
        },

        {
          path: '/extra-rates/create',
          authKey: 'XtraRateMgmt',
          isCreate: true,
          async action(context: any) {
            const ExtraRateDetail = loadable(
              () => import(/* webpackChunkName: "pages-ExtraRateDetail" */ 'pages/ExtraRateDetail'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await extraRateDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await extraRateDetailResolver(props, context);
            }
            return <ExtraRateDetail {...context} />;
          },
        },
        {
          path: '/extra-rates/:extraRateId',
          authKey: 'XtraRateMgmt',
          isCreate: false,
          async action(context: any) {
            const ExtraRateDetail = loadable(
              () => import(/* webpackChunkName: "pages-ExtraRateDetail" */ 'pages/ExtraRateDetail'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await extraRateDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await extraRateDetailResolver(props, context);
            }
            return <ExtraRateDetail {...context} />;
          },
        },
        {
          path: '/cancellationPolicy',
          authKey: SITE_MENU_MAP.CANCELLATION_POLICY.authKey,
          async action(context: any) {
            const CancellationPolicy = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-CancellationPolicy" */ 'pages/CancellationPolicy'
                ),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );
            if (isServer) {
              await cancelPolicyListResolver(props, context);
            } else if (!context.skipResolver) {
              cancelPolicyListResolver(props, context);
            }
            return <CancellationPolicy {...context} />;
          },
        },
        {
          path: '/cancellationPolicy/create',
          authKey: SITE_MENU_MAP.CANCELLATION_POLICY.authKey,
          isCreate: true,
          async action(context: any) {
            const ManageCancellationPolicy = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ManageCancellationPolicy" */ 'pages/CancellationPolicy/components/ManageCancellationPolicy'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );
            if (isServer) {
              await cancelPolicyResolver(props, context);
            } else if (!context.skipResolver) {
              await cancelPolicyResolver(props, context);
            }
            return <ManageCancellationPolicy {...context} />;
          },
        },
        {
          path: '/cancellationPolicy/:cancelPolicyId',
          authKey: SITE_MENU_MAP.CANCELLATION_POLICY.authKey,
          async action(context: any) {
            const ManageCancellationPolicy = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ManageCancellationPolicy" */ 'pages/CancellationPolicy/components/ManageCancellationPolicy'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );
            if (isServer) {
              await cancelPolicyResolver(props, context);
            } else if (!context.skipResolver) {
              await cancelPolicyResolver(props, context);
            }
            return <ManageCancellationPolicy {...context} />;
          },
        },
        {
          path: '/plans',
          authKey: 'PlanMgmt',
          async action(context: any) {
            const PlanList = loadable(
              () => import(/* webpackChunkName: "pages-PlanList" */ 'pages/PlanList'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await planResolver(props, context.params, context.query);
            } else if (!context.skipResolver) {
              await planResolver(props, context.params, context.query);
            }

            return <PlanList {...context} />;
          },
        },
        {
          path: '/provider',
          authKey: 'ProvInfoMgmt',
          async action(context: any) {
            const EditProvider = loadable(
              () => import(/* webpackChunkName: "pages-EditProvider" */ 'pages/EditProvider'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await providerInfoResolver(props, context);
            } else if (!context.skipResolver) {
              providerInfoResolver(props, context);
            }
            return <EditProvider {...context} />;
          },
        },
        {
          path: '/media',
          authKey: SITE_MENU_MAP.MEDIA_MANAGEMENT.authKey,
          async action(context: any) {
            const ProviderMediaList = loadable(
              () =>
                import(/* webpackChunkName: "pages-ProviderMediaList" */ 'pages/ProviderMediaList'),
              {
                fallback: <SkeletonPage pageType={'folder'} hasMedia={true} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await providerMediaListResolver(props, context);
            } else if (!context.skipResolver) {
              providerMediaListResolver(props, context);
            }

            return <ProviderMediaList {...context} />;
          },
        },
        {
          path: '/media/create',
          authKey: SITE_MENU_MAP.MEDIA_MANAGEMENT.authKey,
          isCreate: true,
          async action(context: any) {
            const MediaDetail = loadable(
              () => import(/* webpackChunkName: "pages-MediaDetail" */ 'pages/MediaDetail'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await mediaDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await mediaDetailResolver(props, context);
            }

            return <MediaDetail {...context} />;
          },
        },
        {
          path: '/media/:mediaId',
          authKey: SITE_MENU_MAP.MEDIA_MANAGEMENT.authKey,
          async action(context: any) {
            const MediaDetail = loadable(
              () => import(/* webpackChunkName: "pages-MediaDetail" */ 'pages/MediaDetail'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await mediaDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await mediaDetailResolver(props, context);
            }
            return <MediaDetail {...context} />;
          },
        },
        {
          path: '/sales-promotions',
          authKey: 'ProvCostSlsPromo',
          async action(context: any) {
            const ProviderSalesPromotion = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderSalesPromotion" */ 'pages/ProviderSalesPromotion'
                ),
              {
                fallback: <SkeletonPage pageType={'table'} />,
              },
            );
            if (isServer) {
              await providerSalesPromotionListResolver(props, context.params, context.query);
            } else if (!context.skipResolver) {
              providerSalesPromotionListResolver(props, context.params, context.query);
            }
            return <ProviderSalesPromotion {...context} />;
          },
        },
        {
          path: '/sales-promotions/create',
          authKey: 'ProvCostSlsPromo',
          isCreate: true,
          redirectIfNeeded: ({ providerRelatedIdInUrl }: { providerRelatedIdInUrl: string }) => {
            const isProhibited =
              props.store.getState().providersInformation?.providerBasicInfo
                ?.canCreatePromotions === false;
            return isProhibited ? `provider/${providerRelatedIdInUrl}/sales-promotions` : '';
          },
          async action(context: any) {
            const ProviderSalesPromotionCreate = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderSalesPromotionCreate" */ 'pages/ProviderSalesPromotionCreate'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );
            if (isServer) {
              await providerSalesPromotionResolver(props, context);
            } else if (!context.skipResolver) {
              await providerSalesPromotionResolver(props, context);
            }
            return <ProviderSalesPromotionCreate {...context} />;
          },
        },
        {
          path: '/sales-promotions/:promotionId',
          authKey: 'ProvCostSlsPromo',
          async action(context: any) {
            const ProviderSalesPromotionCreate = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderSalesPromotionCreate" */ 'pages/ProviderSalesPromotionCreate'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );
            if (isServer) {
              await providerSalesPromotionResolver(props, context);
            } else if (!context.skipResolver) {
              await providerSalesPromotionResolver(props, context);
            }
            return <ProviderSalesPromotionCreate {...context} />;
          },
        },
        {
          path: '/access',
          authKey: 'SysAcctAccCtrlMgmt',
          async action(context: any) {
            const AccessControl = loadable(
              () => import(/* webpackChunkName: "pages-AccessControl" */ 'pages/AccessControl'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await accessControlResolver(props, context);
            } else if (!context.skipResolver) {
              accessControlResolver(props, context);
            }

            return <AccessControl {...context} />;
          },
        },
        {
          path: '/access/create',
          authKey: 'SysAcctAccCtrlMgmt',
          isCreate: true,
          async action(context: any) {
            const AccessControlCreate = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-AccessControlCreate" */ 'pages/AccessControlCreate'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await accessControlDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await accessControlDetailResolver(props, context);
            }

            return <AccessControlCreate {...context} />;
          },
        },
        {
          path: '/access/:userUuid',
          authKey: 'SysAcctAccCtrlMgmt',
          async action(context: any) {
            const AccessControlCreate = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-AccessControlCreate" */ 'pages/AccessControlCreate'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await accessControlDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await accessControlDetailResolver(props, context);
            }

            return <AccessControlCreate {...context} />;
          },
        },
        {
          path: '/alias',
          authKey: SITE_MENU_MAP.ALIAS_MANAGEMENT.authKey,
          async action(context: any) {
            const Aliases = loadable(
              () => import(/* webpackChunkName: "pages-Aliases" */ 'pages/Aliases'),
              {
                fallback: <SkeletonPage pageType={'list'} isNarrow={true} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await aliasResolver(props, context);
            } else if (!context.skipResolver) {
              aliasResolver(props, context);
            }

            return <Aliases {...context} />;
          },
        },
        {
          path: '/alias/create',
          authKey: SITE_MENU_MAP.ALIAS_MANAGEMENT.authKey,
          isCreate: true,
          async action(context: any) {
            const Aliases = loadable(
              () => import(/* webpackChunkName: "pages-AliasCreate" */ 'pages/AliasCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await aliasCreateResolver(props, context);
            } else if (!context.skipResolver) {
              await aliasCreateResolver(props, context);
            }

            return <Aliases {...context} />;
          },
        },
        {
          path: '/alias/:aliasId',
          authKey: SITE_MENU_MAP.ALIAS_MANAGEMENT.authKey,
          async action(context: any) {
            const Aliases = loadable(
              () => import(/* webpackChunkName: "pages-AliasCreate" */ 'pages/AliasCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await aliasCreateResolver(props, context);
            } else if (!context.skipResolver) {
              await aliasCreateResolver(props, context);
            }

            return <Aliases {...context} />;
          },
        },
        {
          path: '/booking',
          authKey: 'BookMgmt',
          async action(context: any) {
            const BookingList = loadable(() =>
              import(/* webpackChunkName: "pages-BookingList" */ 'pages/BookingList'),
            );

            if (!isServer) {
              bookingListResolver(props, context);
            }

            return <BookingList {...context} />;
          },
        },
        {
          path: '/booking/:bookingId',
          authKey: 'BookMgmt',
          async action(context: any) {
            const BookingDetail = loadable(() =>
              import(/* webpackChunkName: "pages-BookingDetail" */ 'pages/BookingDetail'),
            );

            if (isServer) {
              await bookingDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await bookingDetailResolver(props, context);
            }

            return <BookingDetail {...context} />;
          },
        },
        {
          path: '/reviews',
          authKey: 'RevMgmt',
          async action(context: any) {
            const CustomerReviews = loadable(() =>
              import(/* webpackChunkName: "pages-CustomerReviews" */ 'pages/CustomerReviews'),
            );

            if (isServer) {
              await customerReviewsResolver(props, context);
            } else if (!context.skipResolver) {
              await customerReviewsResolver(props, context);
            }

            return <CustomerReviews {...context} />;
          },
        },
        {
          path: '/reviews/analytics',
          authKey: 'RevAnalytics',
          async action(context: any) {
            const CustomerReviews = loadable(() =>
              import(/* webpackChunkName: "pages-ReviewAnalytics" */ 'pages/ReviewAnalytics'),
            );

            if (isServer) {
              await reviewAnalyticsResolver(props, context);
            } else if (!context.skipResolver) {
              await reviewAnalyticsResolver(props, context);
            }

            return <CustomerReviews {...context} />;
          },
        },
        {
          path: '/campaigns',
          authKey: 'SpoSlsPromoMgmt',
          async action(context: any) {
            const ProviderCampaignList = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderCampaignList" */ 'pages/ProviderCampaignList'
                ),
              {
                fallback: <SkeletonPage pageType={'table'} />,
              },
            );

            if (isServer) {
              await providerCampaignListResolver(props, context.params, context.query);
            } else if (!context.skipResolver) {
              providerCampaignListResolver(props, context.params, context.query);
            }
            return <ProviderCampaignList {...context} />;
          },
        },
        {
          path: '/campaigns/:campaignId',
          authKey: 'SpoSlsPromoMgmt',
          async action(context: any) {
            const CampaignDetails = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderCampaignDetail" */ 'pages/ProviderCampaignDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await providerCampaignDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await providerCampaignDetailResolver(props, context);
            }

            return <CampaignDetails {...context} />;
          },
        },
        {
          path: '/campaigns/:campaignId/overlay/:ratePlanId',
          authKey: 'SpoSlsPromoMgmt',
          async action(context: any) {
            const SingleOverlaySettings = loadable(() =>
              import(
                /* webpackChunkName: "pages-SingleOverlaySettings" */ 'pages/OverlayConditionsControl/SingleOverlaySettings'
              ),
            );

            if (isServer) {
              await providerCampaignDetailResolver(props, context);
            } else if (!context.skipResolver) {
              providerCampaignDetailResolver(props, context);
            }

            return <SingleOverlaySettings {...context} />;
          },
        },
        {
          path: '/campaigns/:campaignId/bulkOverlay',
          authKey: 'SpoSlsPromoMgmt',
          async action(context: any) {
            const BulkOverlaySettings = loadable(() =>
              import(
                /* webpackChunkName: "pages-BulkOverlaySettings" */ 'pages/OverlayConditionsControl/BulkOverlaySettings'
              ),
            );

            if (isServer) {
              await providerCampaignDetailResolver(props, context);
            } else if (!context.skipResolver) {
              providerCampaignDetailResolver(props, context);
            }

            return <BulkOverlaySettings {...context} />;
          },
        },
        {
          path: '/visualContentGroup',
          authKey: 'VisConGrpMgmt',
          async action(context: any) {
            const VisualContentGroupList = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-VisualContentGroupList" */ 'pages/VisualContentGroupList'
                ),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} isNarrow={true} />,
              },
            );

            if (isServer) {
              await visualContentGroupListResolver(
                props,
                context.query || {},
                context.params || {},
              );
            } else if (!context.skipResolver) {
              visualContentGroupListResolver(props, context.query || {}, context.params || {});
            }

            return <VisualContentGroupList {...context} />;
          },
        },
        {
          path: '/visualContentGroup/create',
          authKey: 'VisConGrpMgmt',
          isCreate: true,
          async action(context: any) {
            const VisualContentGroupDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-VisualContentGroupCreate" */ 'pages/VisualContentGroupCreate'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );

            if (isServer) {
              await visualContentGroupDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await visualContentGroupDetailResolver(props, context);
            }

            return <VisualContentGroupDetail {...context} />;
          },
        },
        {
          path: '/visualContentGroup/:visualContentGroupId',
          authKey: 'VisConGrpMgmt',
          async action(context: any) {
            const VisualContentGroupDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-VisualContentGroupCreate" */ 'pages/VisualContentGroupCreate'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );

            if (isServer) {
              await visualContentGroupDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await visualContentGroupDetailResolver(props, context);
            }

            return <VisualContentGroupDetail {...context} />;
          },
        },
        {
          path: '/reviews/:reviewId',
          authKey: 'RevMgmt',
          async action(context: any) {
            const CustomerReviewDetail = loadable(() =>
              import(
                /* webpackChunkName: "pages-CustomerReviewDetail" */ 'pages/CustomerReviewDetail'
              ),
            );

            if (isServer) {
              await customerReviewDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await customerReviewDetailResolver(props, context);
            }

            return <CustomerReviewDetail {...context} />;
          },
        },
        {
          path: '/accountingReport',
          authKey: 'AccReport',
          async action(context: any) {
            const AccountingReport = loadable(
              () =>
                import(/* webpackChunkName: "pages-AccountingReport" */ 'pages/AccountingReport'),
              {
                fallback: <SkeletonPage pageType={'table'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await accountingReportResolver(props, context);
            } else if (!context.skipResolver) {
              await accountingReportResolver(props, context);
            }

            return <AccountingReport {...context} />;
          },
        },
        /*{
          path: '/notifications',
          authKey: 'Notification',
          async action(context: any) {
            const NotificationInbox = loadable(() => import('pages/NotificationInbox'), {
              fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
            });

            if (isServer) {
              await notificationInboxResolver(props, context);
            } else if (!context.skipResolver) {
              notificationInboxResolver(props, context);
            }

            return <NotificationInbox {...context} />;
          },
        },
        {
          path: '/notifications/:notificationId',
          authKey: 'Notification',
          async action(context: any) {
            const NotificationDetail = loadable(() => import('pages/NotificationDetail'));

            if (isServer) {
              await notificationDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await notificationDetailResolver(props, context);
            }

            return <NotificationDetail {...context} />;
          },
        },*/
        {
          path: '/introductionContents',
          authKey: SITE_MENU_MAP.INTRODUCTION_CONTENT.authKey,
          async action(context: any) {
            const IntroductionContents = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-IntroductionContents" */ 'pages/IntroductionContents'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await introductionContentsResolver(props, context.params);
            } else if (!context.skipResolver) {
              introductionContentsResolver(props, context.params);
            }

            return <IntroductionContents {...context} />;
          },
        },
        {
          path: '/highlightContents',
          authKey: SITE_MENU_MAP.HIGHLIGHT_CONTENT.authKey,
          async action(context: any) {
            const HighlightContents = loadable(
              () =>
                import(/* webpackChunkName: "pages-HighlightContents" */ 'pages/HighlightContents'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await highlightContentsResolver(props, context.params);
            } else if (!context.skipResolver) {
              highlightContentsResolver(props, context.params);
            }

            return <HighlightContents {...context} />;
          },
        },
        {
          path: '/highlightContents/create',
          authKey: SITE_MENU_MAP.HIGHLIGHT_CONTENT.authKey,
          isCreate: true,
          async action(context: any) {
            const HighlightContentDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-HighlightContentsDetail" */ 'pages/HighlightContentsDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await highlightContentsDetailResolver(props, context);
            } else if (!context.skipResolver) {
              highlightContentsDetailResolver(props, context);
            }

            return <HighlightContentDetail {...context} />;
          },
        },
        {
          path: '/highlightContents/:articleId',
          authKey: SITE_MENU_MAP.HIGHLIGHT_CONTENT.authKey,
          async action(context: any) {
            const HighlightContentDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-HighlightContentsDetail" */ 'pages/HighlightContentsDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await highlightContentsDetailResolver(props, context);
            } else if (!context.skipResolver) {
              highlightContentsDetailResolver(props, context);
            }

            return <HighlightContentDetail {...context} />;
          },
        },
      ],
    },
  ];
};
