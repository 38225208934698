import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { defaultBookingDetail, initialBookingModificationDetail } from './initialValues';

import {
  BookingCancellation,
  BookingErrors,
  BookingList,
  CancellationCharges,
  RatePlanList,
  Statistics,
} from 'Booking-Types';
import { TRANSLATE_TYPES } from 'constants/booking';
import {
  confirmBookingPriceChangeAsync,
  deleteQueueAsync,
  fetchBookingCancelStepOneAsync,
  fetchBookingDetailAsync,
  fetchBookingListAsync,
  fetchBookingModificationDetailAsync,
  fetchCancellationChargesAsync,
  fetchRatePlanListAsync,
  fetchStatisticsAsync,
  rConnectConfirmAsync,
  setInitialChangedModificationDetail,
  setInitialModificationDetail,
  setIsStatisticsError,
  updateBookingTranslation,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchBookingListAsync.request, fetchBookingCancelStepOneAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchBookingListAsync.success,
      fetchBookingListAsync.failure,
      fetchBookingCancelStepOneAsync.success,
    ],
    (state, action) => false,
  );

export const isRatePlansFetching = createReducer(false as boolean)
  .handleAction([fetchRatePlanListAsync.request], (state, action) => true)
  .handleAction(
    [fetchRatePlanListAsync.success, fetchRatePlanListAsync.failure],
    (state, action) => false,
  );

export const ratePlanList = createReducer({} as RatePlanList).handleAction(
  fetchRatePlanListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;
    // return the ratePlanLists
    return {
      ...action.payload,
      ratePlans:
        state.ratePlans && shouldConcat
          ? state.ratePlans.concat(action.payload.ratePlans)
          : action.payload.ratePlans,
    };
  },
);

export const isStatisticsFetching = createReducer(false as boolean)
  .handleAction(fetchStatisticsAsync.request, (state, action) => true)
  .handleAction(
    [fetchStatisticsAsync.success, fetchStatisticsAsync.failure],
    (state, action) => false,
  );

export const bookingList = createReducer({} as BookingList).handleAction(
  fetchBookingListAsync.success,
  (state, action) => action.payload,
);

export const isInitialDataLoaded = createReducer(false as boolean).handleAction(
  [fetchBookingListAsync.success],
  (state, action) => true,
);

export const errors = createReducer([] as BookingErrors[]).handleAction(
  fetchBookingListAsync.failure,
  (state, action) => action.payload,
);

export const bookingDetail = createReducer(defaultBookingDetail)
  .handleAction(fetchBookingDetailAsync.success, (state, action) => action.payload)
  .handleAction(updateBookingTranslation, (state, action) => {
    const { id, type, translatedText } = action.payload;
    if (type === TRANSLATE_TYPES.SPECIAL_REQUEST) {
      const bookingDetails = state.bookingDetails;
      return {
        ...state,
        bookingDetails: {
          ...bookingDetails,
          translatedUserSpecialRequest: translatedText,
          showTranslatedUserSpecialRequestBtn: false,
        },
      };
    } else {
      const questions = state.noticesAndQuestions.questions.map(question => {
        if (question.questionId === id) {
          if (type === TRANSLATE_TYPES.ANSWER) {
            question.translatedAnswer = translatedText;
            question.showTranslatedAnswerBtn = false;
          } else {
            question.translatedQuestion = translatedText;
            question.showTranslatedQuestionBtn = false;
          }
        }

        return question;
      });

      return {
        ...state,
        noticesAndQuestions: {
          ...state.noticesAndQuestions,
          questions,
        },
      };
    }
  });

export const bookingStepOne = createReducer({} as BookingCancellation).handleAction(
  fetchBookingCancelStepOneAsync.success,
  (state, action) => action.payload,
);

export const bookingModificationDetail = createReducer(initialBookingModificationDetail)
  .handleAction(fetchBookingModificationDetailAsync.success, (state, action) => action.payload)
  .handleAction(setInitialModificationDetail, () => initialBookingModificationDetail);

export const bookingPriceChange = createReducer(initialBookingModificationDetail)
  .handleAction(confirmBookingPriceChangeAsync.success, (state, action) => action.payload)
  .handleAction(setInitialChangedModificationDetail, () => initialBookingModificationDetail);

export const cancellationChargesDetail = createReducer({} as CancellationCharges).handleAction(
  fetchCancellationChargesAsync.success,
  (state, action) => action.payload,
);

export const statistics = createReducer({} as Statistics).handleAction(
  fetchStatisticsAsync.success,
  (state, action) => action.payload,
);

export const isStatisticsError = createReducer({} as boolean).handleAction(
  setIsStatisticsError,
  (state, action) => action.payload,
);

export const isQueueDeleting = createReducer(false)
  .handleAction(deleteQueueAsync.request, () => true)
  .handleAction([deleteQueueAsync.success, deleteQueueAsync.failure], () => false);

export const isRconnectConfirming = createReducer(false)
  .handleAction(rConnectConfirmAsync.request, () => true)
  .handleAction([rConnectConfirmAsync.success, rConnectConfirmAsync.failure], () => false);

const bookingReducer = combineReducers({
  isFetching,
  isStatisticsFetching,
  bookingList,
  bookingDetail,
  bookingStepOne,
  bookingModificationDetail,
  bookingPriceChange,
  cancellationChargesDetail,
  errors,
  statistics,
  isStatisticsError,
  isRatePlansFetching,
  ratePlanList,
  isQueueDeleting,
  isRconnectConfirming,
  isInitialDataLoaded,
});

export default bookingReducer;
export type BookingState = ReturnType<typeof bookingReducer>;
