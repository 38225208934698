import { Feature, Features, FeatureStatus, IconList, FeatureFilter } from 'Feature-Types';
import { ApiClient } from '@travel/api-client';
import { internalPrefix } from './../../constants/api-prefix';

export const ApiFetchFeatures = (http: ApiClient, params: Object): Promise<Features> =>
  http.get(`${internalPrefix}/features`, { values: params }).then((data: Features) => data);

export const ApiSearchFeatures = (http: ApiClient, params: Object): Promise<Features> =>
  http.get(`${internalPrefix}/features/search`, { values: params }).then((data: Features) => data);

export const ApiAddFeature = (http: ApiClient, feature: Feature): Promise<FeatureStatus> =>
  http.post(`${internalPrefix}/features`, { values: feature }).then((data: FeatureStatus) => data);

export const ApiUpdateFeature = (http: ApiClient, feature: Feature): Promise<FeatureStatus> =>
  http
    .put(`${internalPrefix}/features/${feature.id}`, { values: feature })
    .then((data: FeatureStatus) => data);

export const ApiGetFeature = (http: ApiClient, id: string): Promise<Feature> =>
  http
    .get(`/provider-api/extranet/features/${id}`, { values: { id } })
    .then((data: Feature) => data);

export const ApiFetchIconList = (http: ApiClient, params: Object): Promise<IconList> =>
  http.get(`/provider-api/extranet/icons`, { values: params }).then((data: IconList) => data);

export const ApiFetchFeatureFilters = (
  http: ApiClient,
  providerId: string,
  expectedObjectType: string,
): Promise<FeatureFilter> => {
  const values = providerId ? { expectedObjectType, providerId } : { expectedObjectType };
  return http
    .get(`${internalPrefix}/features/getByExpectedObjectType`, { values })
    .then((data: FeatureFilter) => data);
};

export const ApiFetchConsumerFilterFeatures = (
  http: ApiClient,
  params: Object,
): Promise<Features> =>
  http
    .get(`${internalPrefix}/features/search/filterFeatures`, { values: params })
    .then((data: Features) => data);
