import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  ProviderCampaignErrors,
  ProviderCampaignList,
  ProviderCampaign,
} from 'ProviderCampaign-Types';
import {
  fetchProviderCampaignListAsync,
  fetchProviderCampaignAsync,
  putProviderCampaignSaveAsync,
  putProviderCampaignSubmitAsync,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchProviderCampaignListAsync.request,
      fetchProviderCampaignAsync.request,
      putProviderCampaignSaveAsync.request,
      putProviderCampaignSubmitAsync.request,
    ],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchProviderCampaignListAsync.success,
      fetchProviderCampaignListAsync.failure,
      fetchProviderCampaignAsync.success,
      fetchProviderCampaignAsync.failure,
      putProviderCampaignSaveAsync.success,
      putProviderCampaignSaveAsync.failure,
      putProviderCampaignSubmitAsync.success,
      putProviderCampaignSubmitAsync.failure,
    ],
    (state, action) => false,
  );

export const providerCampaignList = createReducer({} as ProviderCampaignList).handleAction(
  fetchProviderCampaignListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const providerCampaign = createReducer({} as ProviderCampaign).handleAction(
  fetchProviderCampaignAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as ProviderCampaignErrors[]).handleAction(
  fetchProviderCampaignListAsync.failure || fetchProviderCampaignAsync.failure,
  (state, action) => action.payload,
);

const providerCampaignReducer = combineReducers({
  isFetching,
  providerCampaignList,
  providerCampaign,
  errors,
});

export default providerCampaignReducer;
export type ProviderCampaignState = ReturnType<typeof providerCampaignReducer>;
