import {
  ApiStatus,
  Inventory,
  ItemDetail,
  MasterGroupList,
  BulkDetails,
  CreateFeatureGroup,
} from 'Inventory-Types';
import { createReducer } from 'typesafe-actions';
import {
  deleteItemAsync,
  fetchInventoryListAsync,
  fetchItemDetailAsync,
  setInitialItemDetail,
  setProviderID,
  submitNewItemAsync,
  updateItemAsync,
  fetchMasterGroupAsync,
  bulkUpdateFeaturesAsync,
  createFeatureGroupAsync,
} from './actions';

import { as } from 'utils/index';
import { combineReducers } from 'redux';
import { itemInitialState, bulkInitialState, createFeatureGroupInitial } from './initialValues';

export const isCreating = createReducer(false as boolean)
  .handleAction(
    [
      createFeatureGroupAsync.request,
      bulkUpdateFeaturesAsync.request,
      submitNewItemAsync.request,
      updateItemAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      createFeatureGroupAsync.success,
      createFeatureGroupAsync.failure,
      bulkUpdateFeaturesAsync.success,
      bulkUpdateFeaturesAsync.failure,
      submitNewItemAsync.success,
      submitNewItemAsync.failure,
      updateItemAsync.success,
      updateItemAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false as boolean)
  .handleAction(
    deleteItemAsync.request,

    () => true,
  )
  .handleAction([deleteItemAsync.success, deleteItemAsync.failure], () => false);

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchMasterGroupAsync.request,
      bulkUpdateFeaturesAsync.request,
      createFeatureGroupAsync.request,
      fetchInventoryListAsync.request,
      fetchItemDetailAsync.request,
      submitNewItemAsync.request,
      updateItemAsync.request,
      deleteItemAsync.request,
    ],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchMasterGroupAsync.success,
      fetchMasterGroupAsync.failure,
      fetchInventoryListAsync.success,
      fetchInventoryListAsync.failure,
      fetchItemDetailAsync.success,
      fetchItemDetailAsync.failure,
      bulkUpdateFeaturesAsync.success,
      bulkUpdateFeaturesAsync.failure,
      createFeatureGroupAsync.success,
      createFeatureGroupAsync.failure,
      submitNewItemAsync.success,
      submitNewItemAsync.failure,
      updateItemAsync.success,
      updateItemAsync.failure,
      deleteItemAsync.success,
      deleteItemAsync.failure,
    ],
    (state, action) => false,
  );

export const inventoryList = createReducer({} as Inventory).handleAction(
  fetchInventoryListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const inventoryListErrors = createReducer([] as ApiStatus[]).handleAction(
  fetchInventoryListAsync.success,
  (state, action) => action.payload,
);

export const masterGroupList = createReducer([] as MasterGroupList).handleAction(
  fetchMasterGroupAsync.success,
  (state, action) => {
    return action.payload;
  },
);

export const masterGroupErrors = createReducer([] as ApiStatus[]).handleAction(
  fetchMasterGroupAsync.success,
  (state, action) => action.payload,
);

export const itemDetail = createReducer(as<ItemDetail>(itemInitialState))
  .handleAction(fetchItemDetailAsync.success, (_state, action) => action.payload)
  .handleAction(setInitialItemDetail, () => as<ItemDetail>(itemInitialState));

export const itemDetailErrors = createReducer([] as ApiStatus[]).handleAction(
  fetchItemDetailAsync.success,
  (state, action) => action.payload,
);

//Ashish: we might not need this action check and remove
export const submitNewItem = createReducer(as<ItemDetail>(itemInitialState)).handleAction(
  submitNewItemAsync.success,
  (state, action) => action.payload,
);

export const submitNewItemErrors = createReducer([] as ApiStatus[]).handleAction(
  submitNewItemAsync.success,
  (state, action) => action.payload,
);
export const bulkUpdateFeatures = createReducer(as<BulkDetails>(bulkInitialState)).handleAction(
  bulkUpdateFeaturesAsync.success,
  (state, action) => action.payload,
);
export const bulkUpdateFeaturesError = createReducer([] as ApiStatus[]).handleAction(
  bulkUpdateFeaturesAsync.success,
  (state, action) => action.payload,
);

export const createFeatureGroup = createReducer(
  as<CreateFeatureGroup>(createFeatureGroupInitial),
).handleAction(createFeatureGroupAsync.success, (state, action) => action.payload);

export const createFeatureGroupError = createReducer([] as ApiStatus[]).handleAction(
  createFeatureGroupAsync.failure,
  (state, action) => action.payload,
);

export const updateItem = createReducer({} as ApiStatus).handleAction(
  updateItemAsync.success,
  (state, action) => action.payload,
);

export const updateItemErrors = createReducer([] as ApiStatus[]).handleAction(
  updateItemAsync.success,
  (state, action) => action.payload,
);

export const deleteItem = createReducer({} as ApiStatus).handleAction(
  deleteItemAsync.success,
  (state, action) => action.payload,
);

export const deleteItemErrors = createReducer([] as ApiStatus[]).handleAction(
  deleteItemAsync.success,
  (state, action) => action.payload,
);

// TODO: Set provider ID after logged in
export const providerId = createReducer('testing').handleAction(
  setProviderID,
  (state, action) => action.payload,
);

const inventoryReducer = combineReducers({
  isFetching,
  masterGroupList,
  masterGroupErrors,
  bulkUpdateFeatures,
  bulkUpdateFeaturesError,
  createFeatureGroup,
  createFeatureGroupError,
  inventoryList,
  inventoryListErrors,
  itemDetail,
  itemDetailErrors,
  submitNewItem,
  submitNewItemErrors,
  updateItem,
  updateItemErrors,
  deleteItem,
  deleteItemErrors,
  providerId,
  isCreating,
  isDeleting,
});

export default inventoryReducer;
export type InventoryState = ReturnType<typeof inventoryReducer>;
