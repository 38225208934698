import React, { ReactNode } from 'react';

import { Translate } from '@travel/translation';

export const cancelledWithErrorReasonLabelId: { [key: string]: string } = {
  AUTHORIZATION_FAILURE:
    'booking_management_fundamental.popup_cancel_booking_1.form.capture_failed_authorization_message',
  FRAUD_RISK:
    'booking_management_fundamental.popup_cancel_booking_1.form.capture_failed_fraud_message',
  UNKNOWN:
    'booking_management_fundamental.popup_cancel_booking_1.form.capture_failed_unknown_message',
} as const;

export const timeZoneLabel = (timezone: string, className?: string) => (
  <Translate
    id={`Supplier_Enumeration.Supplier_Timezone.${timezone.replace('/', '.')}`}
    className={className}
  />
);

export const mealConditionLabel = {
  breakfastIncluded: (
    <Translate id="booking_management_fundamental.booking_detail.form.breakfast_included" />
  ),
  breakfastNotIncluded: (
    <Translate id="booking_management_fundamental.booking_detail.form.breakfast_not_included" />
  ),
  lunchIncluded: (
    <Translate id="booking_management_fundamental.booking_detail.form.lunch_included" />
  ),
  lunchNotIncluded: (
    <Translate id="booking_management_fundamental.booking_detail.form.lunch_not_included" />
  ),
  dinnerIncluded: (
    <Translate id="booking_management_fundamental.booking_detail.form.dinner_included" />
  ),
  dinnerNotIncluded: (
    <Translate id="booking_management_fundamental.booking_detail.form.dinner_not_included" />
  ),
};

export const DIALOG_TITLE: { [key: string]: ReactNode } = {
  PROVIDER: <Translate id={'booking_management_fundamental.popup_provider_detail.header.title'} />,
  ITEM: <Translate id={'booking_management_fundamental.popup_item_detail.header.title'} />,
  TAX_FEE_EXCLUDED: (
    <Translate
      id={'booking_management_fundamental.popup_excluded_tax_and_fee_detail.header.title'}
    />
  ),
  RATE_PLAN: (
    <Translate id={'booking_management_fundamental.popup_rate_plan_detail.form.rate_plan_detail'} />
  ),
  RAKUTEN_PROMOTION: (
    <Translate id={'booking_management_fundamental.popup_rakuten_promotion_details.header.title'} />
  ),
  PROVIDER_PROMOTION: (
    <Translate
      id={'booking_management_fundamental.popup_provider_promotion_details.header.title'}
    />
  ),
  TAX_FEE_INCLUDED: (
    <Translate
      id={'booking_management_fundamental.popup_included_tax_and_fee_detail.header.title'}
    />
  ),
};

export const NTA_CANCELLATION: { [key: string]: ReactNode } = {
  UNAVOIDABLE_REASON: (
    <Translate id="booking_management_fundamental.booking_detail.form.cancellation_fee_weather" />
  ),
  SYSTEM_BOOKING_FAILED: (
    <Translate id="booking_management_fundamental.booking_detail.form.cancellation_fee_system" />
  ),
};
export const OPERATION_DETAIL_ID: { [key: string]: string } = {
  BOOKING_CONFIRM:
    'booking_management_fundamental.Notification_Management.Operation_History.Booking_Changed',
  CANCEL_CONFIRM:
    'booking_management_fundamental.Notification_Management.Operation_History.Cancel_Changed',
  SHORTEN_CONFIRM:
    'booking_management_fundamental.Notification_Management.Operation_History.Shorten_Changed',
  DELETE_BOOKING_QUEUE:
    'booking_management_fundamental.Notification_Management.Operation_History.Booking_Removed',
  DELETE_CANCEL_QUEUE:
    'booking_management_fundamental.Notification_Management.Operation_History.Cancel_Removed',
  DELETE_SHORTEN_QUEUE:
    'booking_management_fundamental.Notification_Management.Operation_History.Shorten_Removed',
};
