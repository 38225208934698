import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { ProvidersInformationState } from './reducer';

export const getProvidersInformation = (state: RootState) => state.providersInformation;

export const getProviderInformation = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.providerInformation,
);

export const getProviderLang = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.providerInformation.providerLanguage,
);

export const getProviderBasicInfo = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.providerBasicInfo,
);

export const getProviderLanguage = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.providerBasicInfo.languageCode,
);

export const getProviderTimezone = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state?.providerBasicInfo?.timezone,
);

export const getProviderInformationName = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state?.providerBasicInfo?.name,
);

export const getProviderType = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.providerBasicInfo.category,
);

export const getProviderInformationCurrency = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.providerBasicInfo.currency,
);

export const getDesignations = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => {
    return state?.providerBasicInfo?.designations;
  },
);

export const getHasMultiDesignation = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => {
    return (state?.providerBasicInfo?.designations || []).length > 1;
  },
);

export const getProviderInformationRoundPosition = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.providerBasicInfo.roundPositionForPriceCalculation,
);

export const getIsUpdating = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.isUpdating,
);

export const getIsFetching = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.isFetching,
);

export const getIsPostingProviderSearchManually = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.isPostingProviderSearchManually,
);

export const getIsPostingProviderSearchCsv = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.isPostingProviderSearchCsv,
);

export const getIsPostingProviderSearchByConditions = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.isPostingProviderSearchByConditions,
);

export const getMidTaskMediaName = createSelector(
  getProvidersInformation,
  (state: ProvidersInformationState) => state.midTaskMediaName,
);
