import React from 'react';
import { Translate } from '@travel/translation';
import { CancelPolicyContent } from 'CancelPolicy-Types';

export const initialBrowseParameter = {
  searchText: '',
  searchCondition: '',
  filter: [
    {
      label: <Translate id="cancellation_policy.list.filter.status" />,
      id: 'status',
      options: [
        {
          label: <Translate id="Supplier_Enumeration.Status.Activation.Active" />,
          value: 'true',
          isCheck: false,
        },
        {
          label: <Translate id="Supplier_Enumeration.Status.Activation.Inactive" />,
          value: 'false',
          isCheck: false,
        },
      ],
    },
  ],
  sort: {
    options: [
      {
        text: <Translate id="Supplier_Enumeration.Sort.Creation_Date.New_Old" />,
        value: 'createDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Creation_Date.Old_New" />,
        value: 'createDateTime=asc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.New_Old" />,
        value: 'updateDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.Old_New" />,
        value: 'updateDateTime=asc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Strictness_Level.Strict_Gentle" />,
        value: 'strictnessLevel=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Strictness_Level.Gentle_Strict" />,
        value: 'strictnessLevel=asc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Name_Alphabetical.Ascending" />,
        value: 'cancelPolicyName=asc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Name_Alphabetical.Descending" />,
        value: 'cancelPolicyName=desc',
      },
    ],
    selectedValue: 'createDateTime=desc',
  },
};

export const filterIdList = ['status'];

export const defaultCancelPolicy: CancelPolicyContent = {
  name: '',
  status: true,
  statusEditable: true,
  cancellation: [
    {
      penaltyPercentage: '',
      hasPenaltyPerNights: false,
    },
  ],
  noShow: {},
  alwaysApplyPenalty: true,
  id: '',
  strictnessLevel: undefined,
  version: '',
  updatedTime: '',
  createdTime: '',
};

export const defaultPolicyDeletable = {
  message: '',
  statusCode: null,
};

export const defaultDeletableDialog = {
  isShow: false,
};
