import React from 'react';
import { useSelector } from 'react-redux';

import cx from '@travel/ui/utils/classnames';

import { getFooterMenu, LinkItem } from 'constants/menuItems';
import { getDesignations } from 'store/providersInformation/selectors';

import styles from './navigationMenu.module.scss';

type Props = {
  onClickFooterLink: (linkItem: LinkItem) => void;
  isMenuOpen: boolean;
};

function MenuFooter(props: Props) {
  const { onClickFooterLink, isMenuOpen } = props;
  const designations = useSelector(getDesignations) || [];

  const menus = getFooterMenu(designations);

  return (
    <ul
      className={styles.navFooter}
      data-locator-id="MenuFooter-1f592f2b-1dc6-47c5-b7f6-905c5d0e27ee"
    >
      {isMenuOpen && <div className={styles.footerDivider} />}
      {menus.map(item => {
        const MenuIcon = item.icon;
        return (
          <li key={item.key} className={styles.footerItem}>
            <div onClick={() => onClickFooterLink(item)} className={styles.footerLink}>
              <MenuIcon className={cx(styles.icon, item.class && styles[item.class])} />
              <p className={styles.suspendedContent}>{item.title}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default MenuFooter;
