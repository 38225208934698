import { ApiClient } from '@travel/api-client';
import { FetchAllBody, ChannelManager, QueryParameter, ApiStatus } from 'ChannelManager-Types';

export const fetchAll = (http: ApiClient, query: QueryParameter): Promise<FetchAllBody> =>
  http.get('/partner-api/partner/channelManagers', {
    values: query,
  });

export const fetchManager = (http: ApiClient, managerId: string): Promise<ChannelManager> =>
  http.get(`/partner-api/partner/channelManagers/${managerId}`);

export const createManager = (http: ApiClient, data: ChannelManager): Promise<ApiStatus> =>
  http.post('/partner-api/partner/channelManagers', {
    values: data,
  });

export const updateManager = (
  http: ApiClient,
  data: ChannelManager,
  managerId: string,
): Promise<ApiStatus> =>
  http.put(`/partner-api/partner/channelManagers/${managerId}`, { values: data });
