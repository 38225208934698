import { universalRouterProps } from 'core/universalRouter/types';
import { fetchAllLabels, fetchPage, clearLabel } from '../../store/labelManagement/actions';
import { QueryParameter } from 'LabelManagement-Types';

export default (
  { store: { dispatch } }: universalRouterProps,
  pageId: string,
  query: QueryParameter,
) => {
  return Promise.all([
    dispatch(fetchAllLabels(pageId, query)),
    dispatch(fetchPage(pageId)),
    dispatch(clearLabel()),
  ]);
};
