import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { processRefreshToken, setIsInitialLoad } from 'store/login/actions';

import { getIsInitialLoad } from 'store/login/selectors';

const REFRESH_INTERVAL = 55 * 60 * 1000; // 55 mins

const useTokenRefresh = (isNotLoginPage: boolean) => {
  const isInitialLoad = useSelector(getIsInitialLoad);
  const dispatch = useDispatch();

  const refreshTokenHandler = useCallback(async () => {
    const result = await dispatch(processRefreshToken());
    if (result?.token) {
      setTimeout(refreshTokenHandler, REFRESH_INTERVAL);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isNotLoginPage && isInitialLoad) {
      refreshTokenHandler();
      dispatch(setIsInitialLoad(false));
    }
  }, [isNotLoginPage, refreshTokenHandler, isInitialLoad, dispatch]);
};

export default useTokenRefresh;
