import { universalRouterProps } from 'core/universalRouter/types';
import { fetchCampaignList } from 'store/campaign/actions';

export default (
  { store: { dispatch } }: universalRouterProps,
  context: { query: { keyword: string } },
) => {
  const { query } = context;
  return Promise.all([dispatch(fetchCampaignList({ ...query, limit: 30, offset: 0 }))]);
};
