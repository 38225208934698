import { createSelector } from 'reselect';
import { ProviderGroupState } from './reducer';
import { RootState } from 'typesafe-actions';
import { ProviderGroupList } from 'ProviderGroup-Types';

export const getProviderGroupState = (state: RootState) => state.providerGroup;

export const getAllProviderGroups = (state: RootState) => state.providerGroup.allProviderGroups;
export const getProviderGroupList = (state: RootState) => state.providerGroup.providerGroupList;
export const getProviderGroup = (state: RootState) => state.providerGroup.providerGroup;
export const getProviderGroupProviders = (state: RootState) => state.providerGroup.providerList;
export const getProviderGroupBaseInfo = (state: RootState) => state.providerGroup.baseInfo;

export const getErrors = (state: RootState) => state.providerGroup.errors;

export const getIsFetching = createSelector(
  getProviderGroupState,
  (state: ProviderGroupState) => state.isFetching,
);

export const getIsCreating = createSelector(
  getProviderGroupState,
  (state: ProviderGroupState) => state.isCreating,
);

export const getProviderGroupOptions = createSelector(
  getProviderGroupList,
  (state: ProviderGroupList) =>
    state?.contents?.map(g => ({
      label: g?.name || '-',
      name: g?.name || '-',
      value: g?.groupId,
    })),
);
