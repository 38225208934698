import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { IntroductionContent, IntroductionContentStatus } from 'IntroductionContent-Types';

import {
  fetchIntroductionContentAsync,
  postIntroductionContentArticleAsync,
  putIntroductionContentArticleAsync,
  setIntroductionData,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchIntroductionContentAsync.request], (state, action) => true)
  .handleAction(
    [fetchIntroductionContentAsync.success, fetchIntroductionContentAsync.failure],
    (state, action) => false,
  );

export const isSaving = createReducer(false as boolean)
  .handleAction(
    [postIntroductionContentArticleAsync.request, putIntroductionContentArticleAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      postIntroductionContentArticleAsync.success,
      postIntroductionContentArticleAsync.failure,
      putIntroductionContentArticleAsync.success,
      postIntroductionContentArticleAsync.failure,
    ],
    (state, action) => false,
  );

export const introductionContent = createReducer({} as IntroductionContent)
  .handleAction(fetchIntroductionContentAsync.success, (state, action) => action.payload)
  /** To Avoid the issue of displaying old data till we save
   * To Persist The Data While We Request To Save and Update Again After getting from server */
  .handleAction(setIntroductionData, (state, action) => {
    return [action.payload];
  });

export const introductionContentError = createReducer(
  [] as IntroductionContentStatus[],
).handleAction(fetchIntroductionContentAsync.failure, (state, action) => action.payload);

const introductionContentReducer = combineReducers({
  isFetching,
  isSaving,
  introductionContent,
  introductionContentError,
});

export default introductionContentReducer;
export type IntroductionContentState = ReturnType<typeof introductionContentReducer>;
