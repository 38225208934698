import { createAsyncAction, AppThunk, createStandardAction } from 'typesafe-actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { ApiCreateDiagnosisAvailability } from './apis';
import { DiagnosisAvailability, DiagnosisErrors, DiagnosisRequestBody } from 'Diagnosis-Types';

export const createDiagnosisAvailabilityAsync = createAsyncAction(
  'CREATE_DIAGNOSIS_AVAILABILITY_REQUEST',
  'CREATE_DIAGNOSIS_AVAILABILITY_SUCCESS',
  'CREATE_DIAGNOSIS_AVAILABILITY_FAILURE',
)<undefined, DiagnosisAvailability, DiagnosisErrors[]>();

export const clearDiagnosisAvailability = createStandardAction('CLEAR_DIAGNOSIS_AVAILABILITY')();

export const createDiagnosisAvailability = (data: DiagnosisRequestBody): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(createDiagnosisAvailabilityAsync.request());
  try {
    const res = await ApiCreateDiagnosisAvailability(apiClient, data);
    dispatch(createDiagnosisAvailabilityAsync.success(res));
  } catch (err) {
    dispatch(createDiagnosisAvailabilityAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};
