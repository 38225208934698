import { BrowseParameters, FilterGroup, FilterGroupStatus, FilterGroups } from 'FilterGroup-Types';
import {
  deleteFilterGroupAsync,
  fetchConsumerFilterGroupsAsync,
  fetchConsumerFilterGroupsWithOffsetAsync,
  fetchFilterGroupAsync,
  fetchFilterGroupsAsync,
  postFilterGroupAsync,
  putFilterGroupAsync,
  setInitialFilterGroup,
  updateConsumerFilterGroupBrowseParameter,
  updateFilterGroupBrowseParameter,
} from './actions';
import { initialBrowseParameter, initialFilterGroup } from './initialValues';

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

export const isCreating = createReducer(false)
  .handleAction([postFilterGroupAsync.request, putFilterGroupAsync.request], () => true)
  .handleAction(
    [
      postFilterGroupAsync.success,
      postFilterGroupAsync.failure,
      putFilterGroupAsync.success,
      putFilterGroupAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction(deleteFilterGroupAsync.request, () => true)
  .handleAction([deleteFilterGroupAsync.success, deleteFilterGroupAsync.failure], () => false);

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchFilterGroupsAsync.request], (state, action) => true)
  .handleAction(
    [fetchFilterGroupsAsync.success, fetchFilterGroupsAsync.failure],
    (state, action) => false,
  );

export const filterGroups = createReducer({} as FilterGroups).handleAction(
  fetchFilterGroupsAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const filterGroupsError = createReducer([] as FilterGroupStatus[]).handleAction(
  fetchFilterGroupsAsync.failure,
  (state, action) => action.payload,
);

export const filterGroup = createReducer(initialFilterGroup as FilterGroup)
  .handleAction(fetchFilterGroupAsync.success, (state, action) => action.payload)
  .handleAction(setInitialFilterGroup, () => initialFilterGroup);

export const filterGroupError = createReducer([] as FilterGroup[]).handleAction(
  fetchFilterGroupAsync.failure,
  (state, action) => action.payload,
);

export const browseParameters = createReducer(
  initialBrowseParameter as BrowseParameters,
).handleAction(updateFilterGroupBrowseParameter, (_state, action) => action.payload);

export const consumerFilterGroups = createReducer({} as FilterGroups)
  .handleAction(fetchConsumerFilterGroupsAsync.success, (state, action) => action.payload)
  .handleAction(fetchConsumerFilterGroupsWithOffsetAsync.success, (state, action) => {
    return {
      ...action.payload,
      contents: [...state.contents, ...action.payload.contents],
    };
  });

export const consumerFilterGroupBrowseParameters = createReducer(
  initialBrowseParameter as BrowseParameters,
).handleAction(updateConsumerFilterGroupBrowseParameter, (_state, action) => action.payload);

const filterGroupReducer = combineReducers({
  isFetching,
  filterGroups,
  filterGroupsError,
  filterGroup,
  filterGroupError,
  browseParameters,
  consumerFilterGroups,
  consumerFilterGroupBrowseParameters,
  isCreating,
  isDeleting,
});

export default filterGroupReducer;
export type FilterGroupState = ReturnType<typeof filterGroupReducer>;
