import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  ProviderList,
  ProviderGroup,
  ProviderGroupList,
  ProviderGroupErrors,
  SimpleProviderGroup,
} from 'ProviderGroup-Types';
import {
  clearProviderGroup,
  fetchAllProviderGroupsAsync,
  fetchProviderGroupAsync,
  fetchProviderGroupListAsync,
  createProviderGroupAsync,
  updateProviderGroupAsync,
  fetchProviderGroupProvidersAsync,
  fetchProviderGroupBaseInfoAsync,
  setProviderGroupBasicInfoStandard,
} from './actions';
import { initialProviderGroup } from './initialValues';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchProviderGroupListAsync.request,
      fetchProviderGroupAsync.request,
      fetchProviderGroupBaseInfoAsync.request,
      fetchProviderGroupProvidersAsync.request,
    ],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchProviderGroupListAsync.success,
      fetchProviderGroupListAsync.failure,
      fetchProviderGroupAsync.success,
      fetchProviderGroupAsync.failure,
      fetchProviderGroupBaseInfoAsync.success,
      fetchProviderGroupBaseInfoAsync.failure,
      fetchProviderGroupProvidersAsync.success,
      fetchProviderGroupProvidersAsync.failure,
    ],
    (state, action) => false,
  );

export const isCreating = createReducer(false)
  .handleAction([createProviderGroupAsync.request, updateProviderGroupAsync.request], () => true)
  .handleAction(
    [
      createProviderGroupAsync.success,
      createProviderGroupAsync.failure,
      updateProviderGroupAsync.success,
      updateProviderGroupAsync.failure,
    ],
    () => false,
  );

export const allProviderGroups = createReducer([] as SimpleProviderGroup[]).handleAction(
  fetchAllProviderGroupsAsync.success,
  (state, action) => action.payload,
);

export const providerGroupList = createReducer({} as ProviderGroupList).handleAction(
  fetchProviderGroupListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;
    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const providerGroup = createReducer(initialProviderGroup as ProviderGroup)
  .handleAction(fetchProviderGroupAsync.success, (state, action) => action.payload)
  .handleAction(clearProviderGroup, (state, action) => initialProviderGroup as ProviderGroup);

export const baseInfo = createReducer({} as ProviderGroup)
  .handleAction([setProviderGroupBasicInfoStandard], (state, action) => action.payload)
  .handleAction(fetchProviderGroupBaseInfoAsync.success, (state, action) => action.payload);

export const providerList = createReducer({} as ProviderList).handleAction(
  fetchProviderGroupProvidersAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as ProviderGroupErrors[]).handleAction(
  fetchProviderGroupAsync.failure,
  (state, action) => action.payload,
);

const providerGroupReducer = combineReducers({
  isFetching,
  isCreating,
  allProviderGroups,
  providerGroupList,
  providerGroup,
  baseInfo,
  providerList,
  errors,
});

export default providerGroupReducer;
export type ProviderGroupState = ReturnType<typeof providerGroupReducer>;
