import React from 'react';
import { cx } from '@travel/utils';
import styles from './gridCell.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  colSpan?: number;
  rowSpan?: number;
};

function GridCell(props: Props) {
  const { className, children, ...rest } = props;

  return (
    <td
      data-locator-id="GridCell-9ed2d30f-8694-4ff2-b9e5-fcfe18758d0c"
      className={cx(className, styles.gridCell)}
      {...rest}
    >
      {children}
    </td>
  );
}

export default GridCell;
