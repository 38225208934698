import React from 'react';
import { cx } from '@travel/utils';
import styles from './simpleSkeletonItem.module.scss';

type Props = {
  /** wrap style */
  itemClassName?: string;
  /** 1st skeleton. please set if you want */
  titleClassName?: string;
  /** 2nd skeleton. please set if you want */
  contentClassName?: string;
  /** media style if you want to customize */
  mediaClassName?: string;
  /** please set true if you want */
  hasMedia?: boolean;
};

function SimpleSkeletonItem(props: Props) {
  const { itemClassName, mediaClassName, titleClassName, contentClassName, hasMedia } = props;
  return (
    <div className={itemClassName} data-testid="skeleton-item">
      {hasMedia && <div className={cx(mediaClassName, styles.thumbnail, styles.skeleton)} />}
      <div>
        {titleClassName && <div className={cx(titleClassName, styles.skeleton)} />}
        {contentClassName && <div className={cx(contentClassName, styles.skeleton)} />}
      </div>
    </div>
  );
}

export default SimpleSkeletonItem;
