import { universalRouterProps } from 'core/universalRouter/types';
import { fetchHistory } from 'store/providerDataTranslation/actions';
import { getToday, reduceMonths } from 'utils/calendar';

type URLParams = { query: { [field: string]: string } };

export default ({ store: { dispatch, getState } }: universalRouterProps, urlParams: URLParams) => {
  const today = getToday();
  const fromDate = reduceMonths(today, -3);

  const historyParam = { filter: { dateFrom: fromDate, dateTo: today, status: ['IN_PROGRESS'] } };

  return Promise.all([dispatch(fetchHistory(historyParam))]);
};
