import { universalRouterProps } from 'core/universalRouter/types';
import { fetchMediaListWithQuery } from 'store/media/actions';
import { fetchSidebarFolders, fetchFolderList } from 'store/folder/actions';
import { FOLDER_OBJECT_TYPE as OBJECT_TYPE, FOLDER_TYPE } from 'constants/folder';
import { isEmptyObject } from '@travel/utils';

export type Context = {
  params: { providerId: string };
  query: { [key: string]: string };
};

const FILTER_FOLDER_TYPE = 'filter.folderType';
const FILTER_FOLDER_ID = 'filter.folderId';

export default ({ store: { dispatch, getState } }: universalRouterProps, context: Context) => {
  const { params, query } = context;
  const sidebarFolders = getState().folder.sidebarFolders;

  const newQuery = { ...query };
  const isInitialLoad = !query?.[FILTER_FOLDER_TYPE] && !query?.[FILTER_FOLDER_ID];
  if (isInitialLoad) {
    newQuery[FILTER_FOLDER_TYPE] = FOLDER_TYPE.ALL;
  }

  const promises = [
    dispatch(fetchMediaListWithQuery(params.providerId, newQuery)),
    dispatch(fetchFolderList(params.providerId, { objectType: OBJECT_TYPE.VISUAL_CONTENT })),
  ];

  if (isInitialLoad || isEmptyObject(sidebarFolders)) {
    // fetch sidebar folder at first render. don't need to fetch again.
    promises.push(dispatch(fetchSidebarFolders(params.providerId, {})));
  }

  return Promise.all(promises);
};
