import { ApiClient } from '@travel/api-client';
import { toQuery } from '@travel/utils';
import providerPrefix from 'constants/api-prefix';
import {
  RateControlRates,
  FilterType,
  ChildRateBulkUpdateParams,
  ApiStatus,
} from 'RateControl-Types';

export const fetch = (
  http: ApiClient,
  providerID: string,
  ratePlanId: string,
  params: FilterType,
): Promise<RateControlRates> => {
  return http
    .get(`${providerPrefix}/${providerID}/plans/${ratePlanId}/rates`, {
      values: toQuery(params),
    })
    .then((data: RateControlRates) => data);
};

export const saveRateControlCalendar = (
  http: ApiClient,
  providerId: string,
  ratePlanId: string,
  calendarData: RateControlRates,
): Promise<ApiStatus> => {
  return http
    .put(`${providerPrefix}/${providerId}/plans/${ratePlanId}/rates`, {
      values: calendarData,
    })
    .catch((data: ApiStatus) => data);
};

export const ApiUpdateChildRateInBulk = (
  http: ApiClient,
  providerID: string,
  ratePlanId: string,
  params: ChildRateBulkUpdateParams,
): Promise<ApiStatus> => {
  return http.put(`${providerPrefix}/${providerID}/plans/${ratePlanId}/rates/bulkChildRate`, {
    values: params,
  });
};

export const ApiDeleteExtraRate = (
  http: ApiClient,
  providerId: string,
  ratePlanId: string,
  extraRateId: string,
): Promise<ApiStatus> => {
  return http.delete(
    `${providerPrefix}/${providerId}/plans/${ratePlanId}/rates/${extraRateId}`,
    {},
  );
};
