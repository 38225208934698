import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import {
  ApiFetchNotifications,
  ApiFetchNotificationDetail,
  ApiComposeNotification,
  ApiFetchNotificationsDetailList,
} from './apis';
import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  NotificationInboxList,
  NotificationDetail,
  FetchNotificationParams,
  ApiStatus,
  ComposeNotification,
  NotificationDetailList,
} from 'Notification-Types';
import { pushLocation } from 'store/__router/actions';

export const fetchNotificationAsync = createAsyncAction(
  'FETCH_NOTIFICATION_REQUEST',
  'FETCH_NOTIFICATION_SUCCESS',
  'FETCH_NOTIFICATION_FAILURE',
)<undefined, NotificationInboxList, ApiStatus[]>();

export const fetchNotificationDetailAsync = createAsyncAction(
  'FETCH_NOTIFICATION_DETAIL_REQUEST',
  'FETCH_NOTIFICATION_DETAIL_SUCCESS',
  'FETCH_NOTIFICATION_DETAIL_FAILURE',
)<undefined, NotificationDetail, ApiStatus[]>();

export const composeNotificationAsync = createAsyncAction(
  'FETCH_NOTIFICATION_COMPOSE_REQUEST',
  'FETCH_NOTIFICATION_COMPOSE_SUCCESS',
  'FETCH_NOTIFICATION_COMPOSE_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const fetchNotificationDetailListAsync = createAsyncAction(
  'FETCH_NOTIFICATION_DETAIL_LIST_REQUEST',
  'FETCH_NOTIFICATION_DETAIL_LIST_SUCCESS',
  'FETCH_NOTIFICATION_DETAIL_LIST_FAILURE',
)<undefined, NotificationDetailList, ApiStatus[]>();

export const clearNotification = createStandardAction('CLEAR_NOTIFICATION')();

export const fetchNotifications = (params: FetchNotificationParams): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchNotificationAsync.request());
  try {
    const res = await ApiFetchNotifications(apiClient, params);
    dispatch(fetchNotificationAsync.success(res));
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const fetchNotificationDetail = (notificationId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchNotificationDetailAsync.request());
  try {
    const res = await ApiFetchNotificationDetail(apiClient, notificationId);
    dispatch(fetchNotificationDetailAsync.success(res));
  } catch (error) {
    dispatch(fetchNotificationDetailAsync.success(error));
  }
};

export const composeNotification = (
  notification: ComposeNotification,
  state: { prevSearch: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(composeNotificationAsync.request());

  try {
    const res = await ApiComposeNotification(apiClient, notification);
    dispatch(composeNotificationAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    if (res.notificationId)
      dispatch(pushLocation({ pathname: `/internal/notifications/${res.notificationId}`, state }));
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error));
    dispatch(composeNotificationAsync.success(error));
  }
};

export const fetchNotificationDetailList = (notificationIdList: Array<string>): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchNotificationDetailListAsync.request());
    const res = await ApiFetchNotificationsDetailList(apiClient, notificationIdList);
    dispatch(fetchNotificationDetailListAsync.success(res));
  } catch (error) {
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};
