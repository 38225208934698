import { AppThunk, createStandardAction } from 'typesafe-actions';
import { GlobalPopup } from 'Utility-Types';

export const setGlobalPopupContent = createStandardAction('SET_GLOBAL_POPUP_CONTENT')<
  GlobalPopup
>();
export const closeGlobalPopup = createStandardAction('CLOSE_GLOBAL_POPUP')();

export const openGlobalSuccessPopup = (
  titleId: string,
  messageId: string,
): AppThunk => async dispatch => {
  dispatch(
    setGlobalPopupContent({
      isOpen: true,
      customTitleLabelId: titleId,
      messageLabelId: messageId,
      type: 'SUCCESS',
    }),
  );
};
