import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import {
  FetchAllBody,
  ChannelDistributorErrors,
  ChannelDistributor,
} from 'ChannelDistributor-Types';
import { QueryParameter, ApiStatus } from 'ChannelDistributor-Types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { fetchAll, createDistributor, fetchDistributor, updateDistributor } from './api';
import { pushLocation } from 'store/__router/actions';
import { transformToQueryObject } from 'utils/transformToQueryObject';

export const clearChannelDistributor = createStandardAction('CLEAR_CHANNEL_DISTRIBUTOR')();

export const fetchChannelDistributorsAsync = createAsyncAction(
  'FETCH_CHANNEL_DISTRIBUTORS_REQUEST',
  'FETCH_CHANNEL_DISTRIBUTORS_SUCCESS',
  'FETCH_CHANNEL_DISTRIBUTORS_FAILURE',
)<undefined, FetchAllBody, ChannelDistributorErrors[]>();

export const fetchAllChannelDistributorsAsync = createAsyncAction(
  'FETCH_ALL_CHANNEL_DISTRIBUTORS_REQUEST',
  'FETCH_ALL_CHANNEL_DISTRIBUTORS_SUCCESS',
  'FETCH_ALL_CHANNEL_DISTRIBUTORS_FAILURE',
)<undefined, FetchAllBody, ChannelDistributorErrors[]>();

export const createChannelDistributorAsync = createAsyncAction(
  'CREATE_CHANNEL_DISTRIBUTOR_REQUEST',
  'CREATE_CHANNEL_DISTRIBUTOR_SUCCESS',
  'CREATE_CHANNEL_DISTRIBUTOR_FAILURE',
)<undefined, ChannelDistributor, ChannelDistributorErrors[]>();

export const fetchChannelDistributorAsync = createAsyncAction(
  'FETCH_CHANNEL_DISTRIBUTOR_DETAIL_REQUEST',
  'FETCH_CHANNEL_DISTRIBUTOR_DETAIL_SUCCESS',
  'FETCH_CHANNEL_DISTRIBUTOR_DETAIL_FAILURE',
)<undefined, ChannelDistributor, ChannelDistributorErrors[]>();

export const updateChannelDistributorAsync = createAsyncAction(
  'UPDATE_CHANNEL_DISTRIBUTOR_REQUEST',
  'UPDATE_CHANNEL_DISTRIBUTOR_SUCCESS',
  'UPDATE_CHANNEL_DISTRIBUTOR_FAILURE',
)<undefined, ApiStatus, ChannelDistributorErrors[]>();

export const fetchChannelDistributors = (query: QueryParameter = {}): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchChannelDistributorsAsync.request());
  try {
    const newQuery = {
      ...transformToQueryObject(query || {}, ['status']),
      limit: 30,
      offset: query?.offset || 0,
    };
    const response = await fetchAll(apiClient, newQuery);
    dispatch(fetchChannelDistributorsAsync.success(response));
  } catch (errors) {
    dispatch(fetchChannelDistributorsAsync.failure([errors as ChannelDistributorErrors]));
  }
};

export const fetchAllChannelDistributors = (initQuery: QueryParameter = {}): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const LIMIT = 100;
  let query = { ...initQuery, limit: LIMIT };
  dispatch(fetchAllChannelDistributorsAsync.request());

  try {
    const response = await fetchAll(apiClient, query);
    let { total, offset, contents } = response;

    while (total - (offset + LIMIT) > 0) {
      query = { ...query, offset: offset + LIMIT };
      let newResponse = await fetchAll(apiClient, query);
      contents = [...contents, ...newResponse.contents];
      total = newResponse.total;
      offset = newResponse.offset;
    }
    dispatch(fetchAllChannelDistributorsAsync.success({ total, offset, limit: LIMIT, contents }));
  } catch (error) {
    dispatch(fetchAllChannelDistributorsAsync.failure(error));
  }
};

export const fetchChannelDistributor = (distributorId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchChannelDistributorAsync.request());
  const response = await fetchDistributor(apiClient, distributorId);
  dispatch(fetchChannelDistributorAsync.success(response));
};

export const createChannelDistributor = (data: ChannelDistributor): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(createChannelDistributorAsync.request());
  try {
    const response = await createDistributor(apiClient, data);
    dispatch(createChannelDistributorAsync.success(response));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: 200, message: '' }));
    dispatch(pushLocation(`/internal/distributorManagement/edit/${response.id}`));
  } catch (err) {
    dispatch(createChannelDistributorAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
  }
};

export const updateChannelDistributor = (
  data: ChannelDistributor,
  prevSearch?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const state = getState();
  const distributorId = state.channelDistributor.channelDistributor.id;
  dispatch(updateChannelDistributorAsync.request());
  try {
    const res = await updateDistributor(apiClient, data, distributorId);
    dispatch(updateChannelDistributorAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));

    if (res.status === 200) {
      dispatch(
        pushLocation({
          pathname: `/internal/distributorManagement/edit/${data.id}`,
          state: { prevSearch },
        }),
      );
    }
  } catch (err) {
    dispatch(updateChannelDistributorAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
  }
};
