import React, { useState } from 'react';
import { DropdownArrow, Keyboard } from '@travel/icons/ui';
import { get } from '@travel/utils';

import BallonDropdown from '../BallonDropdown';

import styles from './languageDropDown.module.scss';

export type Language = {
  text: string;
  value: string;
};

export type SupportedLanguage = {
  code: string;
  enName: string;
  name: string;
};

type Props = {
  languageCode: string;
  options: Language[];
  supportedLanguages: SupportedLanguage[];
  keyword?: string;
  onChange: (newLanguage: string) => void;
  onSearch?: (keyword: string) => void;
  onClear?: () => void;
  isDisabled?: boolean;
};

function LanguageDropDown(props: Props) {
  const {
    languageCode,
    supportedLanguages,
    options,
    onChange,
    keyword,
    onSearch,
    onClear,
    isDisabled = false,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleLanguageClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOnChange = (newLanguage: string) => {
    onChange(newLanguage);
    setIsOpen(false);
  };

  const language = get(
    supportedLanguages.find(supportedLanguage => supportedLanguage.code === languageCode),
    'name',
    '',
  );

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        className={styles.languageButton}
        onClick={handleLanguageClick}
        data-testid="languageDropdown-menu-button"
        disabled={isDisabled}
      >
        <Keyboard />
        <span>{language}</span>
        {!isDisabled && <DropdownArrow />}
      </button>
      <div>
        {isOpen && (
          <BallonDropdown
            className={styles.balloon}
            options={options}
            defaultValue={languageCode}
            onChange={handleOnChange}
            keyword={keyword}
            onSearch={onSearch}
            onClear={onClear}
            data-testid="languageDropdown-language-dropdown"
          />
        )}
      </div>
    </div>
  );
}

export default LanguageDropDown;
