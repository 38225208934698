import { ComponentProps } from '@travel/ui/components/Drawer/types';
import React, { FunctionComponent, ReactNode } from 'react';

export type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** Id to be passed for onDetail*/
  listItemId: string | number;

  /** Callback to be called after detail button clicked */
  onDetailClick: (id?: string | number) => void;

  /** Pass a container component or html that will act as a wrapper element for the ListItemHeader,
   *  base html element can be passed as string ex: 'p' or 'div' */
  container?: string | FunctionComponent;

  /** Childrens of List Item Header */
  children: ReactNode;
};

function ListItemHeader(props: Props) {
  const { listItemId, onDetailClick, container, children, className, ...rest } = props;

  const handleOnClickDetail = () => {
    onDetailClick(listItemId);
  };

  const ContainerElement = () => {
    const headerContainerProps: ComponentProps = {
      onClick: handleOnClickDetail,
      className,
      ...rest,
    };

    if (container) {
      return React.createElement(container, headerContainerProps, children);
    }
    return <div {...headerContainerProps}>{children}</div>;
  };

  return <ContainerElement />;
}

ListItemHeader.defaultProps = {};

export default ListItemHeader;
