import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { MenuStatus } from 'Menu-Types';
import { setMenuIsOpen } from './actions';
import initialValues from './initialValues';

export const isOpen = createReducer(initialValues as MenuStatus).handleAction(
  setMenuIsOpen,
  (state, action) => action.payload,
);

const menuReducer = combineReducers({
  isOpen,
});

export default menuReducer;
export type MenuState = ReturnType<typeof menuReducer>;
