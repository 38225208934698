import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Certification, CertificationErrors } from 'Certification-Types';
import { fetchCertificationAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchCertificationAsync.request], (state, action) => true)
  .handleAction(
    [fetchCertificationAsync.success, fetchCertificationAsync.failure],
    (state, action) => false,
  );

export const list = createReducer([] as Certification[]).handleAction(
  fetchCertificationAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as CertificationErrors[]).handleAction(
  fetchCertificationAsync.failure,
  (state, action) => action.payload,
);

const certificationReducer = combineReducers({
  isFetching,
  list,
  errors,
});

export default certificationReducer;
export type CertificationState = ReturnType<typeof certificationReducer>;
