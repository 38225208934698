import { universalRouterProps } from 'core/universalRouter/types';
import { fetchPlaceList, fetchPlaceCategories, clearPlaceDetail } from 'store/place/actions';

import { initFilterState } from 'store/place/initialValues';
import { PlaceListQueryParams } from 'Place-Types';

type Location = {
  query: PlaceListQueryParams | null;
};

export default ({ store: { dispatch } }: universalRouterProps, { query }: Location) => {
  const fetchQuery =
    query && Object.keys(query).length
      ? { ...query, keyword: encodeURIComponent(query.keyword || '') }
      : initFilterState;

  return Promise.all([
    dispatch(fetchPlaceList(fetchQuery)),
    dispatch(fetchPlaceCategories()),
    dispatch(clearPlaceDetail()),
  ]);
};
