import { universalRouterProps } from 'core/universalRouter/types';
import { fetchCancelPolicy, clearCancelPolicy } from 'store/cancelPolicy/actions';
import { fetchProviderInformation } from 'store/providersInformation/actions';

export type URLParams = {
  params: { providerId: string; cancelPolicyId: string };
  query: { [key: string]: string };
};

export default (
  { store: { dispatch } }: universalRouterProps,
  { params: { providerId, cancelPolicyId }, query }: URLParams,
) => {
  const promises: Promise<void>[] = [dispatch(fetchProviderInformation(providerId))];
  if (cancelPolicyId) {
    promises.push(dispatch(fetchCancelPolicy(providerId, cancelPolicyId, query)));
  } else {
    dispatch(clearCancelPolicy());
  }
  return Promise.all(promises);
};
