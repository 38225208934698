import { universalRouterProps } from 'core/universalRouter/types';
import { fetchAnnouncementDetail, clearAnnouncement } from 'store/announcement/actions';

export type URLParams = {
  params: { announcementId: string };
};

export default (
  { store: { dispatch } }: universalRouterProps,
  { params: { announcementId } }: URLParams,
) => {
  if (announcementId) {
    return Promise.all([dispatch(fetchAnnouncementDetail(announcementId))]);
  } else {
    dispatch(clearAnnouncement());
  }
};
