import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AccountingReports, YearAndMonths, AccountingReportErrors } from 'AccountingReport-Types';
import { fetchAccountingReportAsync, fetchYearAndMonthsAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchAccountingReportAsync.request], (state, action) => true)
  .handleAction(
    [fetchAccountingReportAsync.success, fetchAccountingReportAsync.failure],
    (state, action) => false,
  );

export const items = createReducer({} as AccountingReports).handleAction(
  fetchAccountingReportAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      records:
        state.records && shouldConcat
          ? state.records.concat(action.payload.records)
          : action.payload.records,
    };
  },
);

export const yearAndMoths = createReducer([] as YearAndMonths).handleAction(
  fetchYearAndMonthsAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as AccountingReportErrors[]).handleAction(
  fetchAccountingReportAsync.failure,
  (state, action) => action.payload,
);

const accountingReportReducer = combineReducers({
  isFetching,
  items,
  yearAndMoths,
  errors,
});

export default accountingReportReducer;
export type AccountingReportState = ReturnType<typeof accountingReportReducer>;
