import React, { ReactNode } from 'react';

import { Dialog } from '@travel/ui';

import styles from './confirmationPopup.module.scss';

type DialogContent = {
  dialogTitle: ReactNode;
  dialogBody: ReactNode;
};

type Props = {
  /** Custom style for wrapper */
  className?: string;
  onConfirm: () => void;
  isOpenConfirmation: boolean;
  onClose: () => void;
  dialogContent: DialogContent;
  primaryLabel?: ReactNode;
  secondaryLabel?: ReactNode;
  buttonPrimaryClassName?: string;
};

function ConfirmationPopup(props: Props) {
  const {
    onConfirm,
    isOpenConfirmation,
    onClose,
    dialogContent = { dialogTitle: '', dialogBody: '' } as DialogContent,
    primaryLabel = 'TLFX: Confirm',
    secondaryLabel = 'TLFX: Cancel',
    buttonPrimaryClassName = '',
  } = props;

  return (
    <>
      {isOpenConfirmation && (
        <Dialog
          className={styles.dialogContainer}
          contentClassName={styles.dialogContent}
          buttonPrimaryClassName={buttonPrimaryClassName}
          isOpen={isOpenConfirmation}
          actionClassName={styles.action}
          onClose={onClose}
          title={dialogContent?.dialogTitle}
          buttonLabelPrimary={primaryLabel}
          buttonLabelSecondary={secondaryLabel}
          onClickPrimary={onConfirm}
          onClickSecondary={onClose}
          isUseContentFlexBox={false}
          data-locator-id="ConfirmationPopup-2f832f6a-6669-4c03-ba8f-fbd46aafb86d"
        >
          {dialogContent?.dialogBody}
        </Dialog>
      )}
    </>
  );
}

export default ConfirmationPopup;
