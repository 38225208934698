import { PlanList } from 'Plan-Types';
import { ApiClient } from '@travel/api-client';
import providerPrefix from './../../constants/api-prefix';

export const ApiFetchPlanList = (
  http: ApiClient,
  providerId: string | null,
  params: Object,
): Promise<PlanList> =>
  http
    .get(`${providerPrefix}/${providerId}/plans`, { values: params })
    .then((data: PlanList) => data);
