import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import { Providers, ProvidersErrors, ProviderDetail, ApiStatus } from 'Providers-Types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { ApiFetchProviders, ApiPostProvider, ApiFetchProvider, ApiPutProvider } from './apis';
import { pushLocation } from 'store/__router/actions';
import { ApiFetchPlaceDetail } from 'store/place/apis';

export const updateSearchText = createStandardAction('UPDATE_SEARCH_TEXT')<string>();
export const clearProvider = createStandardAction('CLEAR_PROVIDER')();
export const updateCurrencyIsChangedFlag = createStandardAction('UPDATE_CURRENCY_CHANGED_FLAG')<
  boolean
>();

export const fetchProvidersAsync = createAsyncAction(
  'FETCH_PROVIDERS_REQUEST',
  'FETCH_PROVIDERS_SUCCESS',
  'FETCH_PROVIDERS_FAILURE',
)<undefined, Providers, ProvidersErrors[]>();

export const fetchProviderAsync = createAsyncAction(
  'FETCH_PROVIDER_REQUEST',
  'FETCH_PROVIDER_SUCCESS',
  'FETCH_PROVIDER_FAILURE',
)<undefined, ProviderDetail, ProvidersErrors[]>();

export const postProviderAsync = createAsyncAction(
  'POST_PROVIDER_REQUEST',
  'POST_PROVIDER_SUCCESS',
  'POST_PROVIDER_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const putProviderAsync = createAsyncAction(
  'PUT_PROVIDER_REQUEST',
  'PUT_PROVIDER_SUCCESS',
  'PUT_PROVIDER_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

//TODO https://jira.rakuten-it.com/jira/browse/EVEREST-10808
export const fetchProviders = (queries: any): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProvidersAsync.request());
  try {
    const res = await ApiFetchProviders(apiClient, queries);
    dispatch(fetchProvidersAsync.success(res));
  } catch (err) {
    dispatch(fetchProvidersAsync.failure(err));
  }
};

export const postProvider = (data: ProviderDetail): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(postProviderAsync.request());
  try {
    const res = await ApiPostProvider(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(postProviderAsync.success(res));
    if (res.id) {
      dispatch(pushLocation(`/internal/provider/${res.id}`));
    }
  } catch (err) {
    dispatch(postProviderAsync.failure(err));
  }
};

export const putProvider = (data: ProviderDetail, providerId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(putProviderAsync.request());
  try {
    const res = await ApiPutProvider(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putProviderAsync.success(res));

    if (res.status === 200) {
      dispatch(pushLocation(`/internal/provider/${data.id}`));
    }
  } catch (err) {
    dispatch(putProviderAsync.failure(err));
  }
};

export const fetchProvider = (providerId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProviderAsync.request());
  try {
    const res = await ApiFetchProvider(apiClient, providerId);

    // TEQB-4160: we need to save result once ApiFetchProvider is done first
    // since provider detail cannot be rendered properly when ApiFetchPlaceDetail is failed
    dispatch(fetchProviderAsync.success(res));
    if (res?.parentPlaceId) {
      const places = await ApiFetchPlaceDetail(apiClient, res?.parentPlaceId);
      dispatch(fetchProviderAsync.success({ ...res, ancestors: places.ancestors }));
    }
  } catch (err) {
    dispatch(fetchProviderAsync.failure(err));
  }
};
