import { isNotEmptyArray } from '@travel/utils';

import { Permission } from 'AccessControl-Types';
import PERM from 'constants/permissions';

export const hasViewPermission = (userPermissions: Permission[], permission: string) => {
  const userPermission =
    (isNotEmptyArray(userPermissions) &&
      userPermissions.find(p => p.objectName === permission)?.permissionLevel) ||
    PERM.readEditDelete;
  return userPermission !== PERM.noAccess;
};

export const hasEditPermission = (userPermissions: Permission[], permission: string) => {
  const userPermission =
    (isNotEmptyArray(userPermissions) &&
      userPermissions.find(p => p.objectName === permission)?.permissionLevel) ||
    PERM.readEditDelete;
  return userPermission === PERM.readEdit || userPermission === PERM.readEditDelete;
};
