import { stringify, toQuery } from '@travel/utils';

export type FilterFormData = {
  [fieldName: string]: {
    [option: string]: boolean;
  };
};

type FilterValues = {
  [field: string]: string;
};

type SearchConditions = {
  [field: string]: string;
};

export const convertFormDataToQuery = (values: FilterFormData, exclude?: Array<string>) => {
  return Object.keys(values).reduce((filter: FilterValues, key: string) => {
    if (exclude?.includes(key) && values[key]) {
      filter[key] = [values[key]].join(','); //lets keep the structure similar
      return filter;
    }
    const propValue = values[key];
    filter[key] = Object.keys(values[key])
      .filter(function(x: string) {
        return propValue[x];
      })
      .join(',');
    return filter;
  }, {});
};

export const transformFilterQueryToFormData = (query: FilterValues, exclude?: Array<string>) => {
  const filterFormData = Object.keys(query || {})
    .map(key => {
      if (exclude?.includes(key)) {
        return { [key]: query[key] };
      }
      return {
        [key]: query[key].split(',').reduce((accumulator, currentValue) => {
          return { ...accumulator, [currentValue]: true };
        }, {}),
      };
    })
    .reduce((accumulator, currentValue) => {
      return { ...accumulator, ...currentValue };
    }, {});
  return filterFormData;
};

export const createNewPath = (
  pathname: string,
  params: {
    filter?: FilterValues;
    search?: {
      tags: { [key: string]: unknown };
      conditions?: SearchConditions;
    };
    searchCondition?: string;
    matchType?: string;
  },
  sort: string,
) => {
  const query = stringify(toQuery(params)).concat(sort ? `&sort.${sort}` : '');

  return {
    pathname,
    search: query,
  };
};

export const createReviewsPath = (
  pathname: string,
  params: {
    filter?: {
      [id: string]: Array<string>;
    };
    search?: {
      tags?: SearchConditions;
      condition: SearchConditions;
    };
  },
  sort: string,
) => {
  const query = stringify(toQuery(params)).concat(sort ? `&sort.${sort}` : '');

  return {
    pathname,
    search: query,
  };
};

export const arrayToQueryObject = (query: string[], key: string) => {
  const queryObject: { [k: string]: string } = {};
  query.forEach((des, index) => {
    queryObject[`${key}[${index}]`] = des;
  });
  return queryObject;
};
