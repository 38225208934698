import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { Certification, CertificationErrors } from 'Certification-Types';
import { ApiFetchCertification } from './apis';

export const fetchCertificationAsync = createAsyncAction(
  'FETCH_CERTIFICATION_REQUEST',
  'FETCH_CERTIFICATION_SUCCESS',
  'FETCH_CERTIFICATION_FAILURE',
)<undefined, Certification[], CertificationErrors[]>();

export const fetchCertification = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchCertificationAsync.request());
  try {
    const res = await ApiFetchCertification(apiClient);
    dispatch(fetchCertificationAsync.success(res));
  } catch (error) {
    dispatch(fetchCertificationAsync.failure([error as CertificationErrors]));
  }
};
