import React, { useState, ChangeEvent, HTMLProps, ReactNode, SyntheticEvent } from 'react';

//components
import { Checkmark } from '@travel/icons/ui';

import cx from '../../utils/classnames';

//styles
import styles from './checkbox.module.scss';

type Props = {
  /** unique name of parameter */
  name: string;

  id?: string;

  /** title of the checkbox being displayed after checkbox*/
  label?: ReactNode;

  /** style name to control appearance of this component from parent component*/
  className?: string;

  /** style name to control appearance of label of this component from parent component*/
  labelClassName?: string;

  /** style name to control appearance of icon of this component from parent component*/
  iconClassName?: string;

  /** style name to control appearance of icon of this component on checked state from parent component*/
  checkedClassName?: string;

  /** style name to control appearance of icon of this component on un-checked state from parent component*/
  uncheckedClassName?: string;

  /** used for uncontrolled checkbox */
  isDefaultChecked?: boolean;

  /** isChecked is used to control check/uncheck status  */
  isChecked?: boolean;

  /** isDisabled to disable a checkbox */
  isDisabled?: boolean;

  /** special props passed to input[checkbox]: can be for a11y or other features*/
  inputProps?: HTMLProps<HTMLInputElement>;

  /** Position of check box beside the label */
  position?: 'left' | 'right';

  /** Node to define the custom checked icon */
  customCheckMarkIcon?: React.ReactNode;

  /** callback func to be called when checkbox is out of focus or can say blurred */
  onBlur?: (events: SyntheticEvent<HTMLInputElement>) => void;

  /** callback func to be called on every change is value i.e. checked/uncheck of checkbox*/
  onChange?: (events: ChangeEvent<HTMLInputElement>) => void;
};

export default function Checkbox(props: Props) {
  //props const
  const {
    name,
    id,
    label,
    className,
    labelClassName,
    iconClassName,
    isDefaultChecked,
    isChecked: isCheckedProp,
    isDisabled,
    inputProps,
    customCheckMarkIcon,
    checkedClassName,
    uncheckedClassName,
    position = 'left',
    onBlur,
    onChange,
    ...rest
  } = props;
  const isControlled = isCheckedProp !== undefined;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!isControlled) {
      setIsCheckedState(event.target.checked);
    }

    onChange && onChange(event);
  }

  const [isCheckedState, setIsCheckedState] = useState(isDefaultChecked);
  const isChecked = isControlled ? isCheckedProp : isCheckedState;

  //styles for checked and disabled props
  const checkedIconClass = isChecked
    ? cx(styles.checkedIcon, checkedClassName)
    : cx(styles.uncheckedIcon, uncheckedClassName);

  const testId = name ? name : typeof label === 'string' ? label : '';

  return (
    <label
      className={cx(styles.container, isDisabled && styles.disabled, className)}
      data-testid="checkbox-input"
      {...rest}
    >
      {label && position === 'right' && (
        <span
          id={id ? `${id}-label` : undefined}
          aria-hidden={true}
          className={cx(styles.checkBoxLabel, labelClassName)}
        >
          {label}
        </span>
      )}
      <input
        {...inputProps}
        type="checkbox"
        name={name}
        id={id}
        disabled={isDisabled}
        checked={isChecked}
        onChange={handleChange}
        onBlur={onBlur}
        data-testid={`${testId}-test`}
        aria-labelledby={id ? `${id}-label` : undefined}
      />
      <div
        className={cx(
          styles.checkBoxIcon,
          checkedIconClass,
          isDisabled && styles.disabledCheckedIcon,
          iconClassName,
        )}
        data-testid="checkbox-icon"
      >
        {customCheckMarkIcon || <Checkmark size={15} />}
      </div>
      {name && (
        <label htmlFor={inputProps?.id} className={styles.hidden}>
          {name}
        </label>
      )}
      {label && position === 'left' && (
        <span
          id={id ? `${id}-label` : undefined}
          aria-hidden={true}
          className={cx(styles.checkBoxLabel, labelClassName)}
        >
          {label}
        </span>
      )}
    </label>
  );
}
