import { universalRouterProps } from 'core/universalRouter/types';
import { fetchProvider, clearProvider } from 'store/providers/actions';
import { fetchChannelManagers } from 'store/channelManager/actions';
import { fetchChannelDistributors } from 'store/channelDistributor/actions';
import { fetchCertification } from 'store/certification/actions';
import { fetchTaxFeeTypes } from 'store/taxFeeTypes/actions';
import { fetchAllFeatureGroups } from 'store/featureSelection/actions';
import { OBJECT_TYPE } from 'constants/feature';
import { fetchPlaceCategories } from 'store/place/actions';
import { fetchVisualContentGroupList } from 'store/visualContentGroup/actions';
import { getIsCreateAccess } from 'utils/userScopeHelper';
import { fetchCurrencies } from 'store/currencies/actions';

type Location = {
  params: {
    providerId: string;
  };
  query: {
    [key: string]: string;
  };
};

export default ({ store }: universalRouterProps, location: Location) => {
  const { providerId } = location.params;
  const { dispatch } = store;
  const { permissions } = store.getState().accessControl?.loggedInUser || { permissions: [] };
  const hasMediaAccess = getIsCreateAccess(permissions, 'MediaMgmt');

  const queryParams = { resourceId: providerId, objectType: OBJECT_TYPE.PROVIDER };

  const promises = [
    dispatch(fetchChannelManagers({})),
    dispatch(fetchChannelDistributors({})),
    dispatch(fetchCertification()),
    dispatch(fetchTaxFeeTypes()),
    dispatch(fetchAllFeatureGroups(providerId, queryParams)),
    dispatch(fetchPlaceCategories()),
    dispatch(fetchCurrencies()),
  ];

  if (hasMediaAccess) {
    promises.push(dispatch(fetchVisualContentGroupList(providerId, {})));
  }

  if (providerId) {
    promises.push(dispatch(fetchProvider(providerId)));
  } else {
    dispatch(clearProvider());
  }

  return Promise.all(promises);
};
