import React from 'react';
import cx from '../../utils/classnames';
import styles from './divider.module.scss';

type Props = {
  /** Custom style from wrapper */
  className?: string;

  /** Style for the divider */
  type: 'solid' | 'dotted' | 'dashed';
};

export default function Divider(props: Props) {
  const { className, type, ...rest } = props;
  return (
    <hr
      className={cx(className, styles.divider, styles[type])}
      data-testid="divider-hr"
      {...rest}
    />
  );
}
