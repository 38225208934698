import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { toDecodedQuery } from '@travel/utils';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { AliasList, Alias, Errors, AliasQueryParams, ApiStatus } from 'Aliases-Types';

import {
  ApiFetchAliases,
  ApiFetchAlias,
  ApiCreateAlias,
  ApiUpdateAlias,
  ApiDeleteAlias,
} from './apis';
import { pushLocation } from 'store/__router/actions';
import { createAccountAsync, updateAccountAsync } from 'store/accessControl/actions';

export const fetchAliasesAsync = createAsyncAction(
  'FETCH_ALIASES_REQUEST',
  'FETCH_ALIASES_SUCCESS',
  'FETCH_ALIASES_FAILURE',
)<undefined, AliasList, Errors>();

export const fetchAliasAsync = createAsyncAction(
  'FETCH_ALIAS_REQUEST',
  'FETCH_ALIAS_SUCCESS',
  'FETCH_ALIAS_FAILURE',
)<undefined, Alias, Errors>();

export const createAliasAsync = createAsyncAction(
  'CREATE_ALIAS_REQUEST',
  'CREATE_ALIAS_SUCCESS',
  'CREATE_ALIAS_FAILURE',
)<undefined, ApiStatus, Errors[]>();

export const updateAliasAsync = createAsyncAction(
  'UPDATE_ALIAS_REQUEST',
  'UPDATE_ALIAS_SUCCESS',
  'UPDATE_ALIAS_FAILURE',
)<undefined, ApiStatus, Errors[]>();

export const deleteAliasAsync = createAsyncAction(
  'DELETE_ALIAS_REQUEST',
  'DELETE_ALIAS_SUCCESS',
  'DELETE_ALIAS_FAILURE',
)<undefined, ApiStatus, Errors[]>();

export const fetchAliases = (queryParameter: AliasQueryParams): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchAliasesAsync.request());
  try {
    const aliases = await ApiFetchAliases(apiClient, toDecodedQuery(queryParameter));
    dispatch(fetchAliasesAsync.success(aliases));
  } catch (error) {
    dispatch(fetchAliasesAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const fetchAlias = (aliasId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchAliasAsync.request());
  try {
    const alias = await ApiFetchAlias(apiClient, aliasId);
    dispatch(fetchAliasAsync.success(alias));
  } catch (error) {
    dispatch(fetchAliasAsync.failure(error));
    dispatch(fetchAliasAsync.success(error));
  }
};

export const createAlias = (
  data: Alias,
  providerId?: string | undefined,
  isMidtask?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(createAliasAsync.request());
  try {
    const res = await ApiCreateAlias(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(createAliasAsync.success(res));

    if (res.id && !isMidtask) {
      const url = providerId
        ? `/provider/${providerId}/alias/${res.id}`
        : `/internal/alias/${res.id}`;
      dispatch(pushLocation(url));
    }
  } catch (err) {
    dispatch(createAccountAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const updateAlias = (
  data: Alias,
  aliasId: string,
  providerId?: string,
  state?: {
    prevSearch: string;
  },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateAliasAsync.request());
  try {
    const res = await ApiUpdateAlias(apiClient, data, aliasId);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(updateAliasAsync.success(res));
    if (res.id) {
      const url = providerId
        ? `/provider/${providerId}/alias/${res.id}`
        : `/internal/alias/${res.id}`;
      dispatch(pushLocation({ pathname: url, state }));
    }
  } catch (err) {
    dispatch(updateAccountAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const deleteAlias = (aliasName: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(deleteAliasAsync.request());
  try {
    const res = await ApiDeleteAlias(apiClient, aliasName);
    if (res) {
      dispatch(
        fetchGlobalErrorDialogAsync.success({ status: 200, message: aliasName, isDelete: true }),
      );
      dispatch(deleteAliasAsync.success(res));
    }
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    dispatch(deleteAliasAsync.failure(err));
  }
};
