import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { toQuery } from '@travel/utils';
import {
  FilterGroups,
  FilterGroup,
  FilterGroupStatus,
  BrowseParameters,
  checkboxOption,
} from 'FilterGroup-Types';
import {
  ApiDeleteFilterGroup,
  ApiFetchFilterGroup,
  ApiFetchFilterGroups,
  ApiPostFilterGroup,
  ApiPutFilterGroup,
} from './apis';

import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';
import { transformToQueryObject as paramsToQueryObject } from 'utils/transformToQueryObject';
import { initialBrowseParameter as init } from 'store/feature/initialValues';

export const updateFilterGroupBrowseParameter = createStandardAction(
  'UPDATE_FILTER_GROUP_BROWSE_PARAMETER',
)<BrowseParameters>();

export const updateConsumerFilterGroupBrowseParameter = createStandardAction(
  'UPDATE_CONSUMER_FILTER_GROUP_BROWSE_PARAMETER',
)<BrowseParameters>();

export const fetchFilterGroupsAsync = createAsyncAction(
  'FETCH_FILTER_GROUPS_REQUEST',
  'FETCH_FILTER_GROUPS_SUCCESS',
  'FETCH_FILTER_GROUPS_FAILURE',
)<undefined, FilterGroups, FilterGroupStatus[]>();

export const fetchConsumerFilterGroupsAsync = createAsyncAction(
  'FETCH_CONSUMER_FILTER_GROUPS_REQUEST',
  'FETCH_CONSUMER_FILTER_GROUPS_SUCCESS',
  'FETCH_CONSUMER_FILTER_GROUPS_FAILURE',
)<undefined, FilterGroups, FilterGroupStatus[]>();

export const fetchConsumerFilterGroupsWithOffsetAsync = createAsyncAction(
  'FETCH_CONSUMER_FILTER_GROUPS_WITH_OFFSET_REQUEST',
  'FETCH_CONSUMER_FILTER_GROUPS_WITH_OFFSET_SUCCESS',
  'FETCH_CONSUMER_FILTER_GROUPS_WITH_OFFSET_FAILURE',
)<undefined, FilterGroups, FilterGroupStatus[]>();

export const fetchFilterGroupAsync = createAsyncAction(
  'FETCH_FILTER_GROUP_REQUEST',
  'FETCH_FILTER_GROUP_SUCCESS',
  'FETCH_FILTER_GROUP_FAILURE',
)<undefined, FilterGroup, FilterGroupStatus[]>();

export const postFilterGroupAsync = createAsyncAction(
  'POST_FILTER_GROUP_REQUEST',
  'POST_FILTER_GROUP_SUCCESS',
  'POST_FILTER_GROUP_FAILURE',
)<undefined, FilterGroupStatus, FilterGroupStatus[]>();

export const putFilterGroupAsync = createAsyncAction(
  'PUT_FILTER_GROUP_REQUEST',
  'PUT_FILTER_GROUP_SUCCESS',
  'PUT_FILTER_GROUP_FAILURE',
)<undefined, FilterGroupStatus, FilterGroupStatus[]>();

export const deleteFilterGroupAsync = createAsyncAction(
  'DELETE_FILTER_GROUP_REQUEST',
  'DELETE_FILTER_GROUP_SUCCESS',
  'DELETE_FILTER_GROUP_FAILURE',
)<undefined, FilterGroupStatus, FilterGroupStatus[]>();

export const setInitialFilterGroup = createStandardAction('SET_INITIAL_FILTER_GROUP')();

export const fetchFilterGroups = (query?: { [key: string]: string | number }): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchFilterGroupsAsync.request());
  try {
    const newQuery = {
      ...paramsToQueryObject(query || {}, ['status', 'logic']),
      limit: 30,
      offset: query?.offset || init.offset,
    };
    const res = await ApiFetchFilterGroups(apiClient, newQuery);
    dispatch(fetchFilterGroupsAsync.success(res));
  } catch (errors) {
    dispatch(fetchFilterGroupsAsync.failure([errors as FilterGroupStatus]));
  }
};

export const fetchConsumerFilterGroups = (): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const { filterGroup } = getState();
  dispatch(fetchConsumerFilterGroupsAsync.request());
  // this is to always add this type of filter type query
  // not putting this into browserParameters since it could be depecrated soon
  const filterTypeQuery = {
    'filter.type[0]': 'PRICE_FILTER',
    'filter.type[1]': 'FEATURE_FILTER',
  };
  try {
    const res = await ApiFetchFilterGroups(
      apiClient,
      transformToQueryObject({
        ...filterGroup.consumerFilterGroupBrowseParameters,
        ...filterTypeQuery,
      }),
    );
    if (filterGroup.consumerFilterGroupBrowseParameters.offset > 0) {
      dispatch(fetchConsumerFilterGroupsWithOffsetAsync.success(res));
    } else {
      dispatch(fetchConsumerFilterGroupsAsync.success(res));
    }
  } catch (errors) {
    dispatch(fetchConsumerFilterGroupsAsync.failure([errors as FilterGroupStatus]));
  }
};

export const fetchFilterGroup = (filterGroupId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchFilterGroupAsync.request());
    const res = await ApiFetchFilterGroup(apiClient, filterGroupId);
    dispatch(fetchFilterGroupAsync.success(res));
  } catch (errors) {
    dispatch(fetchFilterGroupAsync.failure([errors as FilterGroupStatus]));
  }
};

export const postFilterGroup = (data: FilterGroup): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(postFilterGroupAsync.request());
    const res = await ApiPostFilterGroup(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(postFilterGroupAsync.success(res));
    if (res.id) {
      dispatch(pushLocation(`/internal/filterGroup/${res.id}`));
    }
  } catch (errors) {
    dispatch(postFilterGroupAsync.failure([errors as FilterGroupStatus]));
  }
};

export const putFilterGroup = (data: FilterGroup): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(putFilterGroupAsync.request());
    const res = await ApiPutFilterGroup(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putFilterGroupAsync.success(res));
  } catch (errors) {
    dispatch(putFilterGroupAsync.failure([errors as FilterGroupStatus]));
  }
};

export const deleteFilterGroup = (id: string, name: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(deleteFilterGroupAsync.request());
  try {
    const res = await ApiDeleteFilterGroup(apiClient, id);
    res.message = name;
    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteFilterGroupAsync.success(res));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
    dispatch(deleteFilterGroupAsync.failure(err));
  }
};

/** Query constructor for options like: Filter, Sort, and Search */
function transformToQueryObject(params: BrowseParameters, query?: Object) {
  const parseLogicGate = (options: Array<checkboxOption>) => {
    let logicGate = [] as string[];
    options?.forEach(option => {
      if (option.value === 'AND' && option.isCheck) {
        logicGate.push('AND');
      }
      if (option.value === 'OR' && option.isCheck) {
        logicGate.push('OR');
      }
    });
    return logicGate;
  };

  const parseStatus = (options: Array<checkboxOption>) => {
    const status = [] as boolean[];
    options?.forEach(option => {
      if (option.value === 'true' && option.isCheck) {
        status.push(true);
      } else if (option.value === 'false' && option.isCheck) {
        status.push(false);
      }
    });
    return status;
  };

  const getStatus = params.filter.filter(item => item.id.toLowerCase() === 'status');
  const getLogicGate = params.filter.filter(item => item.id.toLowerCase() === 'logic');

  const filter = {
    logic: parseLogicGate(getLogicGate[0]?.options),
    status: parseStatus(getStatus[0]?.options),
  };

  const keyword = params.searchText;

  params.searchText = '';

  const builtQuery = Object.assign({}, params, { filter }, { keyword }, { query });

  return toQuery(builtQuery);
}
