import { universalRouterProps } from 'core/universalRouter/types';
import { fetchCurrentStageProvider } from 'store/login/actions';

type QueryParam = {
  query: {
    currentStageProviderId: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, { query }: QueryParam) => {
  let promise: Array<Promise<void>> = [];
  const providerRelatedId = query?.currentStageProviderId;

  if (providerRelatedId) {
    promise.push(dispatch(fetchCurrentStageProvider(providerRelatedId)));
    return Promise.all(promise);
  }

  return Promise.all(promise);
};
