import { universalRouterProps } from 'core/universalRouter/types';
import { queryToObject } from '@travel/utils/src/queryString';
import { fetchProviderScoreAnalytics } from 'store/reviews/actions';
import { getToday, reduceDays } from 'utils/calendar';
import { MAX_DATE_RANGE_MANAGEMENT } from 'constants/reviews';

type Query = { [key: string]: string };

export type URLParams = { query: Query };

const getQuery = (query: Query = {}, timezone: string) => {
  const today = getToday(timezone);
  const fromDate = reduceDays(today, -MAX_DATE_RANGE_MANAGEMENT + 1);
  const objectQuery = queryToObject(query);
  const conditions = {
    postDateFrom: fromDate,
    postDateTo: today,
    ...objectQuery?.search,
  };

  return {
    ...conditions,
  };
};

export default ({ store }: universalRouterProps, urlParams: URLParams) => {
  const { query } = urlParams;
  const { dispatch } = store;
  const providerTimeZone =
    store.getState()?.providersInformation?.providerBasicInfo?.timezone || '';

  return Promise.all([dispatch(fetchProviderScoreAnalytics(getQuery(query, providerTimeZone)))]);
};
