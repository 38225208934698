import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { ApiStatus, BrowseParameters } from 'LastUsedValue-Types';
import { ApiFetchLastUsedValue } from './apis';

export const clearLastUsedValue = createStandardAction('CLEAR_LAST_USED_VALUE')();
export const fetchLastUsedValueAsync = createAsyncAction(
  'FETCH_LAST_USED_VALUE_REQUEST',
  'FETCH_LAST_USED_VALUE_SUCCESS',
  'FETCH_LAST_USED_VALUE_FAILURE',
)<undefined, unknown, ApiStatus[]>();

export const fetchLastUsedValue = (params: BrowseParameters): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchLastUsedValueAsync.request());
  try {
    const res = await ApiFetchLastUsedValue(apiClient, params);
    dispatch(fetchLastUsedValueAsync.success(res));
  } catch (error) {
    dispatch(fetchLastUsedValueAsync.failure([error as ApiStatus]));
  }
};
