import { getTranslation } from '@travel/translation';
import { Folder, FolderType } from 'Folder-Types';

export const FOLDER_TYPE = {
  ALL: 'ALL',
  ARCHIVE: 'ARCHIVE',
};

export const FOLDER_OBJECT_TYPE = {
  VISUAL_CONTENT: 'VISUAL_CONTENT',
};

// define as function to avoid empty translation. see TEQB-11287
export const getUniqueFolders: () => Folder[] = () => [
  {
    id: null,
    name: getTranslation({ id: 'LocalLevelFolderManagement.Folder_Management.All' }).join(''),
    type: FOLDER_TYPE.ALL as FolderType,
  },
  {
    id: null,
    name: getTranslation({ id: 'LocalLevelFolderManagement.Folder_Management.Trash' }).join(''),
    type: FOLDER_TYPE.ARCHIVE as FolderType,
  },
];
