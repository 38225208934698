import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Feature, FeatureStatus } from 'Feature-Types';
import { FeatureGroupList } from 'FeatureGroup-Types';
import { FeatureDescriptions } from 'FeatureSelection-Types';
import {
  resetFeatureDescriptons,
  fetchProviderFeaturesAsync,
  fetchFeaturesAsync,
  fetchProviderFeatureDetailAsync,
  updateProviderFeatureDetailAsync,
  updateFeatureBrowseParameter,
  fetchFeaturesForSelectionAsync,
  fetchAllFeatureGroupsAsync,
} from './actions';
import { initialBrowseParameter, initialDescriptions } from './initialValues';

export const isUpdating = createReducer(false)
  .handleAction(updateProviderFeatureDetailAsync.request, () => true)
  .handleAction(
    [updateProviderFeatureDetailAsync.failure, updateProviderFeatureDetailAsync.failure],
    () => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchProviderFeaturesAsync.request,
      fetchFeaturesAsync.request,
      fetchProviderFeatureDetailAsync.request,
      fetchFeaturesForSelectionAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchProviderFeaturesAsync.success,
      fetchProviderFeaturesAsync.failure,
      fetchFeaturesAsync.success,
      fetchFeaturesAsync.failure,
      fetchProviderFeatureDetailAsync.success,
      fetchProviderFeatureDetailAsync.success,
      fetchFeaturesForSelectionAsync.success,
      fetchFeaturesForSelectionAsync.failure,
    ],
    () => false,
  );

export const featureSelections = createReducer({} as FeatureGroupList).handleAction(
  fetchFeaturesForSelectionAsync.success,
  (state, action) => action.payload,
);

export const allFeatureGroups = createReducer({} as FeatureGroupList).handleAction(
  fetchAllFeatureGroupsAsync.success,
  (state, action) => action.payload,
);

export const providerFeatures = createReducer({} as FeatureGroupList).handleAction(
  fetchProviderFeaturesAsync.success,
  (state, action) => action.payload,
);

export const providerFeaturesError = createReducer([] as FeatureStatus[]).handleAction(
  fetchProviderFeaturesAsync.failure,
  (state, action) => action.payload,
);

export const features = createReducer({} as FeatureGroupList).handleAction(
  fetchFeaturesAsync.success,
  (state, action) => action.payload,
);

export const featuresError = createReducer([] as FeatureStatus[]).handleAction(
  fetchFeaturesAsync.failure,
  (state, action) => action.payload,
);

export const featureDescriptions = createReducer(initialDescriptions as FeatureDescriptions)
  .handleAction(resetFeatureDescriptons, (state, action) => ({
    providerId: action.payload,
    features: [],
  }))
  .handleAction(fetchProviderFeatureDetailAsync.success, (state, action) => {
    const fetched: Feature = action.payload;
    const { providerId, features } = state;
    const newFeatures = features.filter(f => f.id !== fetched.id);
    newFeatures.push(fetched);
    return { providerId, features: newFeatures };
  });

export const featureDetailError = createReducer([] as FeatureStatus[]).handleAction(
  fetchProviderFeatureDetailAsync.failure,
  (state, action) => action.payload,
);

export const updatedProviderFeature = createReducer({} as Feature).handleAction(
  updateProviderFeatureDetailAsync.success,
  (state, action) => action.payload,
);

export const updatedProviderFeatureError = createReducer([] as FeatureStatus[]).handleAction(
  updateProviderFeatureDetailAsync.failure,
  (state, action) => action.payload,
);

export const browseParameters = createReducer(initialBrowseParameter).handleAction(
  updateFeatureBrowseParameter,
  (state, action) => action.payload,
);

const featureSelectionReducer = combineReducers({
  isFetching,
  providerFeatures,
  providerFeaturesError,
  features,
  featuresError,
  featureDescriptions,
  featureDetailError,
  updatedProviderFeature,
  updatedProviderFeatureError,
  browseParameters,
  isUpdating,
  featureSelections,
  allFeatureGroups,
});

export default featureSelectionReducer;
export type FeatureSelectionState = ReturnType<typeof featureSelectionReducer>;
