import React, { SyntheticEvent, ReactNode } from 'react';
import { cx, isNotEmptyArray } from '@travel/utils';
import styles from './checkboxGroup.module.scss';
import Checkbox from '../Checkbox';
import { Option } from '../FilterBalloon';

type Props = {
  /** Style name to control appearance of this component from parent component*/
  className?: string;
  /** Style for every single checkbox */
  checkboxClassName?: string;
  /** Style every sing checkbox label */
  checkboxLabelClassName?: string;
  /** callback function when checkbox is clicked */
  onSelect?: (options: Option[], groupIndex: number, selectedIndex: number) => void;
  /** Checkbox Group index */
  groupIndex?: number;
  label?: ReactNode;
  options?: Option[];
  /** Position of check box beside the label */
  position?: 'left' | 'right';
  /** Checkbox display on UI */
  isDisplayRow?: boolean;
};
function CheckboxGroup(props: Props) {
  const {
    className,
    checkboxClassName,
    checkboxLabelClassName,
    label,
    options = [],
    onSelect,
    groupIndex = 0,
    position = 'left',
    isDisplayRow,
    ...rest
  } = props;

  const handleSelect = (e: SyntheticEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const selectedIndex = options.findIndex(option => target.name === option.value);

    if (selectedIndex !== -1) {
      onSelect?.(
        options.map((option, i) => {
          if (selectedIndex === i) {
            return {
              ...option,
              isCheck: !option.isCheck,
            };
          }
          return option;
        }),
        groupIndex,
        selectedIndex,
      );
    }
  };
  return (
    <div
      className={cx(className, styles.checkboxContainer)}
      data-testid={'CheckboxGroup-wrapper'}
      {...rest}
    >
      {label && <label className={styles.label}>{label}</label>}

      {isNotEmptyArray(options) && (
        <ul
          className={cx(
            styles.checkboxWrapper,
            isDisplayRow ? styles.displayRow : styles.displayColumn,
          )}
        >
          {options.map((item, idx) => {
            return (
              <li key={`${item.value}-${idx}`} data-testid={`listItem-wrapper-.${item.value}`}>
                <Checkbox
                  className={checkboxClassName}
                  label={item.label}
                  name={item.value}
                  isChecked={item.isCheck}
                  isDisabled={item.isDisabled}
                  onChange={handleSelect}
                  position={position}
                  labelClassName={checkboxLabelClassName}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default CheckboxGroup;
