import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  BrowseParameters,
  FilterComponents,
  FilterComponentStatus,
  FilterComponent,
} from 'FilterComponent-Types';

import {
  fetchFilterComponentAsync,
  fetchFilterComponentsAsync,
  updateFilterComponentBrowseParameter,
  putFilterComponentAsync,
  deleteFilterComponentAsync,
} from './actions';

import { initialFilterComponent, initialBrowseParameter } from './initialValues';

export const isUpdating = createReducer(false)
  .handleAction(putFilterComponentAsync.request, () => true)
  .handleAction([putFilterComponentAsync.success, putFilterComponentAsync.failure], () => false);

export const isDeleting = createReducer(false)
  .handleAction(deleteFilterComponentAsync.request, () => true)
  .handleAction(
    [deleteFilterComponentAsync.success, deleteFilterComponentAsync.failure],
    () => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchFilterComponentsAsync.request], (state, action) => true)
  .handleAction(
    [fetchFilterComponentsAsync.success, fetchFilterComponentsAsync.failure],
    (state, action) => false,
  );

export const filterComponents = createReducer({} as FilterComponents).handleAction(
  fetchFilterComponentsAsync.success,
  (state, action) => {
    const shouldConcat = Number(action.payload.offset) > state.offset;

    return {
      ...action.payload,

      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const filterComponentsError = createReducer([] as FilterComponentStatus[]).handleAction(
  fetchFilterComponentsAsync.failure,
  (state, action) => action.payload,
);

export const filterComponent = createReducer(
  initialFilterComponent as FilterComponent,
).handleAction(fetchFilterComponentAsync.success, (state, action) => action.payload);

export const filterComponentError = createReducer([] as FilterComponent[]).handleAction(
  fetchFilterComponentAsync.failure,
  (state, action) => action.payload,
);

export const browseParameters = createReducer(
  initialBrowseParameter as BrowseParameters,
).handleAction(updateFilterComponentBrowseParameter, (_state, action) => action.payload);

const filterComponentReducer = combineReducers({
  isFetching,
  filterComponents,
  filterComponentsError,
  filterComponent,
  filterComponentError,
  browseParameters,
  isUpdating,
  isDeleting,
});

export default filterComponentReducer;
export type FilterComponentState = ReturnType<typeof filterComponentReducer>;
