import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { CutOffItems, CutOffErrors } from 'CutOff-Types';
import { fetchCutOffAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchCutOffAsync.request], (state, action) => true)
  .handleAction([fetchCutOffAsync.success, fetchCutOffAsync.failure], (state, action) => false);

export const items = createReducer({} as CutOffItems).handleAction(
  fetchCutOffAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as CutOffErrors[]).handleAction(
  fetchCutOffAsync.failure,
  (state, action) => action.payload,
);

const cutOffReducer = combineReducers({
  isFetching,
  items,
  errors,
});

export default cutOffReducer;
export type CutOffState = ReturnType<typeof cutOffReducer>;
