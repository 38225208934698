import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import DEFAULT_ACCOUNT_DETAILS from './initialValues';

import { Account, AccountList, Errors } from 'AccessControl-Types';
import {
  clearAccount,
  createAccountAsync,
  deleteAccountAsync,
  deleteGroupAccountAsync,
  fetchAccountsAsync,
  fetchAccountAsync,
  fetchGroupAccountAsync,
  setLoggedInUserStandard,
  updateAccountAsync,
  updateGroupAccountAsync,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchAccountsAsync.request, fetchAccountAsync.request, fetchGroupAccountAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchAccountsAsync.success,
      fetchAccountsAsync.failure,
      fetchAccountAsync.success,
      fetchAccountAsync.failure,
      fetchGroupAccountAsync.success,
      fetchGroupAccountAsync.failure,
    ],
    (state, action) => false,
  );

export const accountList = createReducer({} as AccountList).handleAction(
  fetchAccountsAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      accounts:
        state.accounts && shouldConcat
          ? state.accounts.concat(action.payload.accounts)
          : action.payload.accounts,
    };
  },
);

export const account = createReducer(DEFAULT_ACCOUNT_DETAILS)
  .handleAction(fetchAccountAsync.success, (state, action) => action.payload)
  .handleAction(clearAccount, () => DEFAULT_ACCOUNT_DETAILS);

export const loggedInUser = createReducer({} as Account).handleAction(
  setLoggedInUserStandard,
  (state, action) => action.payload,
);

export const errors = createReducer([] as Errors[]).handleAction(
  [fetchAccountsAsync.failure],
  (state, action) => action.payload,
);

export const isCreating = createReducer(false)
  .handleAction(
    [createAccountAsync.request, updateAccountAsync.request, updateGroupAccountAsync.request],
    () => true,
  )
  .handleAction(
    [
      createAccountAsync.success,
      createAccountAsync.failure,
      updateAccountAsync.success,
      updateAccountAsync.failure,
      updateGroupAccountAsync.success,
      updateGroupAccountAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction([deleteAccountAsync.request, deleteGroupAccountAsync.request], () => true)
  .handleAction(
    [
      deleteAccountAsync.success,
      deleteAccountAsync.failure,
      deleteGroupAccountAsync.success,
      deleteGroupAccountAsync.failure,
    ],
    () => false,
  );

export const groupAccount = createReducer({} as Account).handleAction(
  fetchGroupAccountAsync.success,
  (state, action) => action.payload?.accounts[0] || {},
);

const accessControlReducer = combineReducers({
  isFetching,
  accountList,
  account,
  loggedInUser,
  errors,
  isCreating,
  isDeleting,
  groupAccount,
});

export default accessControlReducer;
export type AccessControlState = ReturnType<typeof accessControlReducer>;
