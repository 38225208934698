type SupportedLanguage = {
  text: string;
  value: string;
  enName?: string;
};

/**
 * Sorts array of supported languages.
 * If provider language exists, it is removed and moved to first position
 * If not, a placeholder is put at the first position.
 *
 * @param supportedLanguages - Array of supported languages.
 * @param providerLanguage - The language to be moved to the first position. Defaults to 'en-US'.
 * @return Array of sorted languages.
 */
export const sortLanguage = (
  supportedLanguages: Array<SupportedLanguage>,
  providerLanguage: string = 'en-US',
) => {
  const providerLanguageIndex = supportedLanguages.findIndex(
    language => language.value === providerLanguage,
  );

  const isProviderCreation = !providerLanguage || providerLanguageIndex === -1;

  let languageSelected: SupportedLanguage = { text: '-', value: '' };
  const sortedLanguages = [...supportedLanguages];

  if (!isProviderCreation) {
    languageSelected = sortedLanguages[providerLanguageIndex];
    sortedLanguages.splice(providerLanguageIndex, 1);
  }

  sortedLanguages.splice(0, 0, languageSelected);

  return sortedLanguages;
};
