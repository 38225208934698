import { universalRouterProps } from 'core/universalRouter/types';
import { fetchAliases } from 'store/aliases/actions';
import { isEmptyObject } from '@travel/utils';
import { AliasQueryParams } from 'Aliases-Types';

type Location = {
  query: AliasQueryParams;
};

export default ({ store: { dispatch } }: universalRouterProps, { query }: Location) => {
  const defaultQuery = {
    offset: 0,
    limit: 30,
    sort: { editedDateTime: 'desc' },
  };

  return Promise.all([
    dispatch(
      fetchAliases(
        isEmptyObject(query)
          ? defaultQuery
          : { ...query, keyword: encodeURIComponent(query.keyword || '') },
      ),
    ),
  ]);
};
