import { universalRouterProps } from 'core/universalRouter/types';
import { fetchLabelDetail, fetchPage } from '../../store/labelManagement/actions';

type Params = {
  pageId: string;
  labelId: string;
};

export default ({ store: { dispatch } }: universalRouterProps, params: Params) => {
  return Promise.all([
    dispatch(fetchLabelDetail(params.pageId, params.labelId)),
    dispatch(fetchPage(params.pageId)),
  ]);
};
