import { ApiClient } from '@travel/api-client';
import { toQuery } from '@travel/utils';
import {
  InventoryControlItems,
  FilterType,
  SaveInventoryControl,
  ApiStatus,
  InventoryBulkUpdateParams,
} from 'InventoryControl-Types';

export const fetch = (
  http: ApiClient,
  providerID: string,
  params: FilterType,
): Promise<InventoryControlItems[]> => {
  return http
    .get(`/provider-api/extranet/providers/${providerID}/items/inventories`, {
      values: toQuery(params),
    })
    .then((data: InventoryControlItems[]) => data);
};

export const saveInventoryControlCalendar = (
  http: ApiClient,
  providerId: string,
  calendarData: SaveInventoryControl,
): Promise<ApiStatus> => {
  return http
    .put(`/provider-api/extranet/providers/${providerId}/items/inventories`, {
      values: calendarData,
    })
    .catch((data: ApiStatus) => data);
};

export const bulkUpdateInventoryApi = (
  http: ApiClient,
  providerId: string,
  data: InventoryBulkUpdateParams,
): Promise<ApiStatus> => {
  return http
    .put(`/provider-api/extranet/providers/${providerId}/items/inventories/bulk`, {
      values: data,
    })
    .catch((data: ApiStatus) => data);
};
