import { universalRouterProps } from 'core/universalRouter/types';
import { fetchPlanList } from 'store/plan/actions';
import { setBulkSettings, setBulkSettingCalendarData } from 'store/availabilityControl/actions';
import { clearBulkSettingsTarget } from 'store/rateControl/actions';
import { isEmptyObject } from '@travel/utils';
import { getInitialQuery, isFilteringAvailable } from 'utils/designationHelpers';

export default (
  { store: { dispatch, getState } }: universalRouterProps,
  { providerId }: { providerId: string },
  query: { [key: string]: string },
) => {
  const providerDesignation =
    getState().providersInformation?.providerBasicInfo?.designations || [];
  const serviceTypesMasterData = getState().serviceTypes?.serviceTypes || [];
  const userScope = getState().accessControl?.loggedInUser?.scope;
  const designations = getInitialQuery(
    providerDesignation,
    serviceTypesMasterData,
    userScope,
    isFilteringAvailable,
  );
  const defaultQuery = {
    ...(isEmptyObject(query) && designations),
    ...query,
  };

  return Promise.all([
    dispatch(fetchPlanList(providerId, defaultQuery)),
    dispatch(setBulkSettings({})),
    dispatch(setBulkSettingCalendarData({})),
    dispatch(clearBulkSettingsTarget()),
  ]);
};
