import { ApiClient } from '@travel/api-client';
import { ApplicationDetail, RatePlanSuggestion } from 'ApplicationDetail-Types';

export const ApiFetchApplicationDetail = (
  http: ApiClient,
  campaignId: string,
  applicationId: string,
): Promise<ApplicationDetail> => {
  return http
    .get(`/provider-api/extranet/campaigns/${campaignId}/applications/${applicationId}`)
    .then((response: ApplicationDetail) => response);
};

export const ApiFetchRatePlanSuggestion = (
  http: ApiClient,
  campaignId: string,
  applicationId: string,
): Promise<RatePlanSuggestion[]> => {
  return http
    .get(
      `/provider-api/extranet/campaigns/${campaignId}/applications/${applicationId}/ratePlanSuggestion`,
    )
    .then((response: RatePlanSuggestion[]) => response)
    .catch((data: RatePlanSuggestion[]) => {
      //TODO: Error handling
      return data;
    });
};

export const ApiUpdateRatePlanSuggestion = (
  http: ApiClient,
  campaignId: string,
  applicationId: string,
  data: RatePlanSuggestion[],
): Promise<RatePlanSuggestion[]> => {
  return http
    .put(
      `/provider-api/extranet/campaigns/${campaignId}/applications/${applicationId}/ratePlanSuggestion`,
      { values: data },
    )
    .then((response: RatePlanSuggestion[]) => response)
    .catch((data: RatePlanSuggestion[]) => {
      //TODO: Error handling
      return data;
    });
};
