import { Store } from 'typesafe-actions';
import { COOKIE_NAMES, getCookie } from 'utils/cookieStorage';
import { parseInputLanguage } from 'utils/dataInputLang';

const DEVICE_ID = 'trv-device-id';
export const LANGUAGE_CODE = 'trv-language';
export const PROVIDER_LANGUAGE_CODE = 'trv-provider-language';
const MARKET_CODE = 'trv-market';
const CURRENCY_CODE = 'trv-currency';

type RequestInterceptorOptions = { options: any; path: string; url: string; withAuthCode: boolean };

const requestInterceptor = (store: Store, basePath?: string) => ({
  options,
  path,
  url,
  withAuthCode,
}: RequestInterceptorOptions) => {
  const state = store.getState();
  // Add normalized header for every request
  const { normalizedCookies } = state._httpRequest;
  const { language } = state._i18n;
  const { inputLanguage } = state.languages;

  let verbose = false;
  if (normalizedCookies) {
    verbose = 'trv-very-verbose' in normalizedCookies;
  }

  const everestExtranetToken = normalizedCookies && normalizedCookies[COOKIE_NAMES.APP_TOKEN_NAME];
  const token =
    (everestExtranetToken && everestExtranetToken.value) || state.login.loginResponse.token;

  const selectedInputLanguage =
    normalizedCookies &&
    parseInputLanguage(
      normalizedCookies[COOKIE_NAMES.INPUT_LANGUAGE]?.value,
      false,
      basePath || '',
    );

  const result = {
    url: url,
    options: {
      ...options,
      headers: {
        ...options.headers,
        [DEVICE_ID]: normalizedCookies[DEVICE_ID] && normalizedCookies[DEVICE_ID].value,
        // ToDo: en will be updated to en-US in beta
        [LANGUAGE_CODE]:
          options.headers[LANGUAGE_CODE] ||
          parseInputLanguage(getCookie(COOKIE_NAMES.INPUT_LANGUAGE) || '', true) ||
          selectedInputLanguage ||
          inputLanguage ||
          language ||
          'en-US',
        [PROVIDER_LANGUAGE_CODE]:
          state?.providersInformation?.providerBasicInfo?.languageCode || 'en-US',
        [MARKET_CODE]: 'JPN',
        [CURRENCY_CODE]: 'JPY',
      },
    },
    verbose,
  };

  if (token) {
    result.options.headers.Authorization = token;
  }

  return result;
};

export default requestInterceptor;
