import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { LuxuryInvitationErrors, UploadResult } from 'LuxuryInvitation-Types';
import { uploadFileAsync } from './actions';
import { initialUploadResult } from './initialValues';

export const isFetching = createReducer(false as boolean)
  .handleAction([uploadFileAsync.request], (state, action) => true)
  .handleAction([uploadFileAsync.success, uploadFileAsync.failure], (state, action) => false);

export const uploadResult = createReducer(initialUploadResult as UploadResult).handleAction(
  uploadFileAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as LuxuryInvitationErrors[]).handleAction(
  [uploadFileAsync.failure],
  (state, action) => action.payload,
);

const luxuryInvitationReducer = combineReducers({
  isFetching,
  uploadResult,
  errors,
});

export default luxuryInvitationReducer;
export type LuxuryInvitationState = ReturnType<typeof luxuryInvitationReducer>;
