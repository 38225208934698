import { universalRouterProps } from 'core/universalRouter/types';
import { fetchAccount, clearAccount } from 'store/accessControl/actions';

type Location = {
  params: {
    userUuid: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, { params }: Location) => {
  const promises = [];

  if (params.userUuid) {
    promises.push(dispatch(fetchAccount(params.userUuid)));
  } else {
    dispatch(clearAccount());
  }
  return Promise.all(promises);
};
