import React, { ReactNode, useState, useEffect } from 'react';
import { isNotEmptyArray } from '@travel/utils';
import { FlatButton } from '@travel/ui';
import BalloonSupplier, { ArrowDirection } from '../BalloonSupplier';
import { CheckboxGroup } from '@travel/ui';
import { Block } from '@travel/ui';

import styles from './filterBalloon.module.scss';
export type Option = {
  /** title of the checkbox being displayed after checkbox*/
  label: ReactNode;
  /** unique name of parameter */
  value: string;
  /** isChecked is used to control check/uncheck status  */
  isCheck?: boolean;
};

export type GroupList = {
  /** Checkbox group label*/
  label: ReactNode;
  /** Option(s) for the checkbox */
  options: Array<Option>;
  /** Checkbox group id */
  id?: string;
};

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Group of Checkbox(s) */
  filterList: Array<GroupList>;
  /** Any additional  component to render with filterList */
  filterComponent?: ReactNode;
  /** Style the horizontal line , solid or dotted */
  isSolid?: boolean;
  /** arrow direction */
  direction?: ArrowDirection;
  /** Text for clear button */
  clearButtonText?: ReactNode;
  /** Text for apply button */
  applyButtonText?: ReactNode;
  /** callback function when checkbox is clicked */
  onSelect: (options: Array<GroupList>, groupIdx: number) => void;
  /** callback function when clear button is clicked */
  onClear: (filterList: Array<GroupList>) => void;

  onSubmit: () => void;
  hasApplyButton: boolean;
};

function FilterBalloon(props: Props) {
  const {
    className,
    filterList,
    direction,
    isSolid,
    clearButtonText,
    onSelect,
    onClear,
    onSubmit,
    hasApplyButton,
    applyButtonText,
    filterComponent,
    ...rest
  } = props;

  const [filter, setFilter] = useState(filterList);

  const handleOnClear = () => {
    const newFilterList = filterList.map((groupList: GroupList) => {
      const newOptions = groupList.options.map((option: Option) => ({
        ...option,
        isCheck: false,
      }));

      return {
        ...groupList,
        options: newOptions,
      };
    });

    setFilter(newFilterList);
    onClear(newFilterList);
  };

  const onSelectHandler = (updatedOptionGroup: Option[], groupIndex: number) => {
    onSelect(
      [
        ...filterList.slice(0, groupIndex),
        {
          label: filterList[groupIndex].label,
          options: updatedOptionGroup,
          id: filterList[groupIndex].id,
        },
        ...filterList.slice(groupIndex + 1),
      ],
      groupIndex,
    );
  };
  useEffect(() => {
    setFilter(filterList);
  }, [filterList]);

  return (
    <BalloonSupplier
      direction={direction}
      hasContentMinHeight={false}
      className={className}
      data-testid="filterBalloon-wrapper"
      {...rest}
    >
      {isNotEmptyArray(filter) &&
        filter.map((item, idx) => {
          return (
            <div key={`${item.label}-${idx}`}>
              <Block
                isSolid={isSolid}
                isNotLast={!!filterComponent || idx !== filterList.length - 1}
              >
                <CheckboxGroup
                  label={item.label}
                  options={item.options}
                  onSelect={onSelectHandler}
                  groupIndex={idx}
                  checkboxLabelClassName={styles.checkboxLabel}
                />
              </Block>
            </div>
          );
        })}
      {filterComponent}
      <div className={styles.actionsButtonWrapper}>
        {clearButtonText && (
          <FlatButton
            className={styles.actionButton}
            classType="secondary"
            onClick={handleOnClear}
            data-testid="filterBalloon-clear-button"
          >
            {clearButtonText}
          </FlatButton>
        )}
        {hasApplyButton && (
          <FlatButton
            type="submit"
            classType="primary"
            className={styles.actionButton}
            onClick={onSubmit}
            data-testid="filterBalloon-apply-button"
          >
            {applyButtonText}
          </FlatButton>
        )}
      </div>
    </BalloonSupplier>
  );
}

FilterBalloon.defaultProps = {
  className: '',
  isSolid: true,
  direction: 'bottomLeft',
  clearButtonText: '',
  filterList: [],
  filterComponent: null,
  onSelect: () => {},
  onClear: () => {},
  hasApplyButton: true,
  applyButtonText: 'TLFX: Apply',
};

export default FilterBalloon;
export { getSelectedFilterObject } from './helpers';
