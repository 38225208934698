import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { CurrenciesErrors, CurrencyItems } from 'SupplierCurrencies-Types';
import { fetchCurrenciesAsync, setCurrency } from './actions';

export const isFetching = createReducer(false)
  .handleAction(fetchCurrenciesAsync.request, () => true)
  .handleAction([fetchCurrenciesAsync.success, fetchCurrenciesAsync.failure], () => false);

export const items = createReducer([] as CurrencyItems)
  .handleAction(fetchCurrenciesAsync.success, (_state, action) => action.payload)
  .handleAction(setCurrency, (_state, action) => action.payload);

export const errors = createReducer([] as CurrenciesErrors[]).handleAction(
  fetchCurrenciesAsync.failure,
  (_state, action) => action.payload,
);

const currenciesReducer = combineReducers({
  items,
  errors,
  isFetching,
});

export default currenciesReducer;
export type CurrenciesState = ReturnType<typeof currenciesReducer>;
