import { universalRouterProps } from 'core/universalRouter/types';
import { fetchProviderCampaign } from 'store/providerCampaign/actions';
import { clearCurrentRatePlanList } from 'store/ratePlan/actions';

export type URLParams = {
  params: { providerId: string; campaignId: string };
};

export default (
  { store: { dispatch, getState } }: universalRouterProps,
  { params: { providerId, campaignId } }: URLParams,
) => {
  // to delete existing list in store and reset to empty object
  dispatch(clearCurrentRatePlanList());

  return Promise.all([dispatch(fetchProviderCampaign(providerId, campaignId))]);
};
