import { createAsyncAction, createStandardAction, AppThunk, RootState } from 'typesafe-actions';

import env from '@travel/env';
import { setNormalizedCookies } from '@travel/http-request';

import { setIsShowBrowserPrompt, setLoggedInUser } from 'store/accessControl/actions';
import { ApiFetchLoggedInUser } from 'store/accessControl/apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { getErrorPagePath } from 'utils/errorPageHelper';
import { getIsRFBUser } from 'utils/verifyTokenHelper';

import { pushLocation } from 'store/__router/actions';
import { setInputLanguage } from 'store/languages/actions';

import {
  ApiStatus,
  ChangePasswordWithOneTimeKey,
  Credentials,
  CurrentStageProviderDetail,
  ForgotPasswordData,
  JumpToV1DetailParams,
  JumpToV1TopParams,
  LoginStatus,
  ResetPasswordPreData,
  TokenInfo,
} from 'Login-Types';
import { getCookie, getFullPath, redirectToSSO, setCookie, COOKIE_NAMES } from 'utils';
import {
  changeExpiredPassword,
  changePasswordWithOneTimeKey,
  refreshToken,
  sendKeyToEmail,
  switchUserToken,
  validateCredentials,
  validateKey,
  ApiFetchCurrentStageProvider,
  ApiJumpToV1Detail,
  ApiJumpToV1Top,
  ApiJumpToV1TopFromDialog,
  ApiLogout,
  ApiSetV1Token,
  ApiVerifyToken,
} from './apis';

const TOKEN_EXPIRE_TIME: number = 60 * 60 * 1000; // 60 minutes for login flow
const RFB_SESSION_EXPIRE_TIME: number = 24 * 60 * 60 * 1000; // 24 hours for RFB session

let timeOutTokenExpire: number = 0;

export const postLoginAsync = createAsyncAction(
  'POST_LOGIN_REQUEST',
  'POST_LOGIN_SUCCESS',
  'POST_LOGIN_FAILURE',
)<undefined, LoginStatus, LoginStatus[]>();

export const processForgotPasswordAsync = createAsyncAction(
  'PROCESS_FORGOT_PASSWORD_REQUEST',
  'PROCESS_FORGOT_PASSWORD_SUCCESS',
  'PROCESS_FORGOT_PASSWORD_FAILURE',
)<undefined, LoginStatus, LoginStatus[]>();

export const validateOneTimeKeyAsync = createAsyncAction(
  'VALIDATE_ONE_TIME_KEY_REQUEST',
  'VALIDATE_ONE_TIME_KEY_SUCCESS',
  'VALIDATE_ONE_TIME_KEY_FAILURE',
)<undefined, LoginStatus, LoginStatus[]>();

export const processOneTimeKeyAsync = createAsyncAction(
  'PROCESS_ONE_TIME_KEY_REQUEST',
  'PROCESS_ONE_TIME_KEY_SUCCESS',
  'PROCESS_ONE_TIME_KEY_FAILURE',
)<undefined, LoginStatus, LoginStatus[]>();

export const processChangeExpiredPasswordAsync = createAsyncAction(
  'PROCESS_CHANGE_EXPIRED_PASSWORD_REQUEST',
  'PROCESS_CHANGE_EXPIRED_PASSWORD_SUCCESS',
  'PROCESS_CHANGE_EXPIRED_PASSWORD_FAILURE',
)<undefined, LoginStatus, LoginStatus[]>();

export const processRefreshTokenAsync = createAsyncAction(
  'PROCESS_REFRESH_TOKEN_REQUEST',
  'PROCESS_REFRESH_TOKEN_SUCCESS',
  'PROCESS_REFRESH_TOKEN_FAILURE',
)<undefined, LoginStatus, LoginStatus[]>();

export const fetchCurrentStageProviderAsync = createAsyncAction(
  'FETCH_CURRENT_STAGE_PROVIDER_REQUEST',
  'FETCH_CURRENT_STAGE_PROVIDER_SUCCESS',
  'FETCH_CURRENT_STAGE_PROVIDER_FAILURE',
)<undefined, CurrentStageProviderDetail, LoginStatus[]>();

export const switchTokenAsync = createAsyncAction(
  'SWITCH_TOKEN_REQUEST',
  'SWITCH_TOKEN_SUCCESS',
  'SWITCH_TOKEN_FAILURE',
)<undefined, LoginStatus, LoginStatus[]>();

export const jumpingAsync = createAsyncAction(
  'JUMPING_TO_V1_REQUEST',
  'JUMPING_TO_V1_SUCCESS',
  'JUMPING_TO_V1_FAILURE',
)<undefined, {}, ApiStatus[]>();

export const verifyTokenAsync = createAsyncAction(
  'VERIFY_TOKEN_REQUEST',
  'VERIFY_TOKEN_SUCCESS',
  'VERIFY_TOKEN_FAILURE',
)<undefined, TokenInfo, ApiStatus[]>();

export const logoutAsync = createAsyncAction('LOGOUT_REQUEST', 'LOGOUT_SUCCESS', 'LOGOUT_FAILURE')<
  undefined,
  LoginStatus,
  LoginStatus[]
>();

export const resetPasswordPreData = createStandardAction('RESET_PASSWORD_PRE_DATA')<
  ResetPasswordPreData
>();

export const setSsoTokenData = createStandardAction('SET_SSO_USER_TOKEN')<LoginStatus>();

export const setIsTokenAlmostExpire = createStandardAction('SET_TOKEN_IS_ALMOST_EXPIRE')<boolean>();

export const setIsShowLoginDialog = createStandardAction('SET_IS_SHOW_LOGIN_DIALOG')<boolean>();

export const setVerifyTokenStandard = createStandardAction('SET_VERIFY_TOKEN_DETAIL')<TokenInfo>();

export const setIsInitialLoad = createStandardAction('SET_IS_INITIAL_LOAD')<boolean>();

export const setLoginCredential = (response: LoginStatus): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const tokenInfo = getState().login?.verifyTokenDetail;
  const isRFBUser = getIsRFBUser(tokenInfo?.loginScheme);
  const authTime = tokenInfo?.authTime || new Date().toString();

  if (response.token) {
    const expire = new Date();
    expire.setTime(
      isRFBUser
        ? new Date(authTime).getTime() + RFB_SESSION_EXPIRE_TIME
        : new Date().getTime() + TOKEN_EXPIRE_TIME,
    );
    const cookieOptions = {
      path: '/',
      expires: expire,
    };

    setCookie(COOKIE_NAMES.APP_TOKEN_NAME, response.token || '', cookieOptions);
  }
};

export const resetTokenTimeout = (): AppThunk => async (dispatch, getState) => {
  if (typeof window !== 'undefined') {
    const state = getState();
    const normalizedCookies = state._httpRequest.normalizedCookies;
    const uiLanguage = state._i18n.language;
    const tokenInfo = state.login?.verifyTokenDetail;
    const isRFBUser = getIsRFBUser(tokenInfo?.loginScheme);
    const authTime = tokenInfo?.authTime || new Date().toString();

    window.clearTimeout(timeOutTokenExpire);

    if (isRFBUser) {
      const tokenExpiredTime =
        new Date(authTime).getTime() + RFB_SESSION_EXPIRE_TIME - new Date().getTime();

      if (tokenExpiredTime > 0) {
        timeOutTokenExpire = window.setTimeout(() => {
          window.clearTimeout(timeOutTokenExpire);
          dispatch(
            setNormalizedCookies({
              ...normalizedCookies,
              [COOKIE_NAMES.APP_TOKEN_NAME]: {},
            }),
          );
          dispatch(setIsShowBrowserPrompt(false));
          window.location.href = getErrorPagePath('SESSION', uiLanguage);
        }, tokenExpiredTime);
      }
    } else {
      timeOutTokenExpire = window.setTimeout(() => {
        window.clearTimeout(timeOutTokenExpire);
        dispatch(
          setNormalizedCookies({
            ...normalizedCookies,
            [COOKIE_NAMES.APP_TOKEN_NAME]: {},
          }),
        );
      }, TOKEN_EXPIRE_TIME);
    }
  }
};

export const postLogin = (credentials: Credentials): AppThunk<Promise<LoginStatus>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(postLoginAsync.request());
  try {
    const response = await validateCredentials(apiClient, credentials);
    dispatch(setLoginCredential(response));
    dispatch(resetTokenTimeout());
    dispatch(postLoginAsync.success(response));
    return response;
  } catch (error) {
    dispatch(postLoginAsync.failure([error]));
    return error;
  }
};

export const processForgotPassword = (
  data: ForgotPasswordData,
  ignoreCommonErrorHandler: boolean = true,
): AppThunk<Promise<LoginStatus>> => async (dispatch, getState, { apiClient }) => {
  dispatch(processForgotPasswordAsync.request());
  try {
    const response: LoginStatus = await sendKeyToEmail(apiClient, data, ignoreCommonErrorHandler);
    dispatch(processForgotPasswordAsync.success(response));
    dispatch(
      fetchGlobalErrorDialogAsync.success({
        status: 200,
        message: '',
        isResetPassword: true,
      }),
    );
    return response;
  } catch (error) {
    dispatch(processForgotPasswordAsync.failure([error]));
    if (!ignoreCommonErrorHandler) {
      dispatch(fetchGlobalErrorDialogAsync.success(error));
    }
    return error;
  }
};

export const validateOneTimeKey = (key: string): AppThunk<Promise<LoginStatus>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(validateOneTimeKeyAsync.request());
  try {
    const response: LoginStatus = await validateKey(apiClient, key);
    dispatch(validateOneTimeKeyAsync.success(response));
    return response;
  } catch (error) {
    dispatch(validateOneTimeKeyAsync.failure([error]));
    return error;
  }
};

export const processOneTimeKey = (
  data: ChangePasswordWithOneTimeKey,
): AppThunk<Promise<LoginStatus>> => async (dispatch, getState, { apiClient }) => {
  dispatch(processOneTimeKeyAsync.request());
  try {
    const response = await changePasswordWithOneTimeKey(apiClient, data);
    dispatch(processOneTimeKeyAsync.success(response));
    return response;
  } catch (error) {
    dispatch(processOneTimeKeyAsync.failure([error]));
    return error;
  }
};

export const processChangeExpiredPassword = (
  data: ResetPasswordPreData,
): AppThunk<Promise<LoginStatus>> => async (dispatch, getState, { apiClient }) => {
  dispatch(processChangeExpiredPasswordAsync.request());
  try {
    const response: LoginStatus = await changeExpiredPassword(apiClient, data);
    dispatch(processChangeExpiredPasswordAsync.success(response));
    return response;
  } catch (error) {
    dispatch(processChangeExpiredPasswordAsync.failure([error]));
    return error;
  }
};

export const processRefreshToken = (): AppThunk<Promise<LoginStatus | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const state = getState();
  const normalizedCookies = state._httpRequest.normalizedCookies;
  const uiLanguage = state._i18n.language;
  const isRFBUser = getIsRFBUser(state.login?.verifyTokenDetail?.loginScheme);
  const isUserLoggedIn = state.accessControl?.loggedInUser?.scope;

  if (!isUserLoggedIn) {
    return undefined;
  }

  dispatch(processRefreshTokenAsync.request());
  try {
    const response = await refreshToken(apiClient);

    dispatch(processRefreshTokenAsync.success(response));
    if (response) {
      dispatch(setLoginCredential(response));
      dispatch(
        setNormalizedCookies({
          ...normalizedCookies,
          [COOKIE_NAMES.APP_TOKEN_NAME]: { value: response.token },
        }),
      );
      dispatch(resetTokenTimeout());
      return response;
    }
  } catch (error) {
    dispatch(processRefreshTokenAsync.failure([error]));
    if (isRFBUser) {
      dispatch(setIsShowBrowserPrompt(false));
      window.location.href = getErrorPagePath('SESSION', uiLanguage);
    } else {
      dispatch(pushLocation('/logout'));
    }
    return error;
  }
};

export const removeCookie = (): AppThunk => async (
  dispatch,
  getState: () => RootState,
  { apiClient },
) => {
  const cookieOptions = {
    path: '/',
    expires: new Date(),
  };

  setCookie(COOKIE_NAMES.API_TOKEN_NAME, '', { ...cookieOptions, domain: 'travel.rakuten.com' });
  setCookie(COOKIE_NAMES.APP_TOKEN_NAME, '', cookieOptions);
  setCookie(COOKIE_NAMES.INPUT_LANGUAGE, '', cookieOptions);
};

export const logout = (options?: { redirectToSSO: boolean }): AppThunk => async (
  dispatch,
  getState: () => RootState,
  { apiClient },
) => {
  const state = getState();
  const normalizedCookies = state._httpRequest.normalizedCookies;
  let isRFBUser: boolean;

  const appToken =
    getCookie(COOKIE_NAMES.APP_TOKEN_NAME) || normalizedCookies[COOKIE_NAMES.APP_TOKEN_NAME];

  const apiToken =
    getCookie(COOKIE_NAMES.API_TOKEN_NAME) || normalizedCookies[COOKIE_NAMES.API_TOKEN_NAME];

  if (appToken || apiToken) {
    const tokenDetail = await ApiVerifyToken(apiClient, appToken || apiToken);
    dispatch(setVerifyToken(tokenDetail));
    isRFBUser = getIsRFBUser(tokenDetail.loginScheme);
  } else {
    isRFBUser = false;
  }

  try {
    if (appToken) {
      dispatch(logoutAsync.request());
      const response = await ApiLogout(apiClient);
      dispatch(logoutAsync.success(response));
    }
  } catch (error) {
    dispatch(logoutAsync.failure(error));
  }

  dispatch(removeCookie());

  dispatch(setIsTokenAlmostExpire(false));
  dispatch(setLoggedInUser({}));

  dispatch(
    setNormalizedCookies({
      ...normalizedCookies,
      [COOKIE_NAMES.APP_TOKEN_NAME]: {},
      [COOKIE_NAMES.API_TOKEN_NAME]: {},
    }),
  );

  dispatch(setIsShowBrowserPrompt(false));
  if (typeof window !== 'undefined') {
    window.clearTimeout(timeOutTokenExpire);
  }

  if (options?.redirectToSSO) {
    redirectToSSO(isRFBUser);
  } else {
    try {
      if (isRFBUser) {
        const res = await ApiSetV1Token(apiClient, {
          token: '',
        });
        if (res.status === 200) {
          window.location.href = env('UNIVERSAL_RFB_LOGOUT_URL') || '';
        }
      }
    } catch (error) {}
  }
};

export const fetchCurrentStageProvider = (currentStageProviderId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchCurrentStageProviderAsync.request());
  try {
    const res = await ApiFetchCurrentStageProvider(apiClient, currentStageProviderId);
    dispatch(fetchCurrentStageProviderAsync.success(res));
  } catch (error) {
    dispatch(fetchCurrentStageProviderAsync.failure([error]));
  }
};

export const returnToInternal = (): AppThunk => async (
  dispatch,
  getState: () => RootState,
  { apiClient },
) => {
  const state = getState();
  const normalizedCookies = state._httpRequest.normalizedCookies;
  const uiLanguage = state._i18n.language;
  const isRFBUser = getIsRFBUser(state.login?.verifyTokenDetail?.loginScheme);

  dispatch(setInputLanguage(uiLanguage));
  setCookie(COOKIE_NAMES.INPUT_LANGUAGE, uiLanguage);

  dispatch(switchTokenAsync.request);
  try {
    const response = await switchUserToken(apiClient, {});
    if (response) {
      dispatch(setIsShowBrowserPrompt(false));
      dispatch(setLoginCredential(response));
      dispatch(
        setNormalizedCookies({
          ...normalizedCookies,
          [COOKIE_NAMES.APP_TOKEN_NAME]: { value: response.token },
        }),
      );
      dispatch(switchTokenAsync.success(response));
      const { scope } = await ApiFetchLoggedInUser(apiClient);

      window.location.href = getFullPath(scope, '');
    }
  } catch (err) {
    dispatch(switchTokenAsync.failure([err]));
    if (isRFBUser) {
      dispatch(setIsShowBrowserPrompt(false));
      window.location.href = getErrorPagePath('LOGIN', uiLanguage);
    } else {
      dispatch(pushLocation('/logout'));
    }
  }
};

export const switchUser = (
  providerUuid?: string,
  providerGroup?: string,
  childPath?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const state = getState();
  const normalizedCookies = state._httpRequest.normalizedCookies;
  const uiLanguage = state._i18n.language;
  const isRFBUser = getIsRFBUser(state.login?.verifyTokenDetail?.loginScheme);

  dispatch(setInputLanguage(uiLanguage));
  setCookie(COOKIE_NAMES.INPUT_LANGUAGE, uiLanguage);

  dispatch(switchTokenAsync.request);
  try {
    const response = await switchUserToken(apiClient, {
      providerUuid: providerUuid,
      providerGroupId: providerGroup,
    });
    if (response) {
      dispatch(setIsShowBrowserPrompt(false));
      dispatch(setLoginCredential(response));
      dispatch(
        setNormalizedCookies({
          ...normalizedCookies,
          [COOKIE_NAMES.APP_TOKEN_NAME]: { value: response.token },
        }),
      );
      dispatch(switchTokenAsync.success(response));
      const { scope, providerGroupId, providerUuid } = await ApiFetchLoggedInUser(apiClient);

      window.location.href = getFullPath(scope, providerGroupId || providerUuid, childPath);
    }
  } catch (err) {
    dispatch(switchTokenAsync.failure([err]));
    if (isRFBUser) {
      dispatch(setIsShowBrowserPrompt(false));
      window.location.href = getErrorPagePath('LOGIN', uiLanguage);
    } else {
      dispatch(pushLocation('/logout'));
    }
  }
};

export const jumpToV1Detail = (params: JumpToV1DetailParams): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const isJumping = getState().login.isJumping;
  if (isJumping) return;

  dispatch(jumpingAsync.request());
  try {
    const res = await ApiJumpToV1Detail(apiClient, params);
    if (res.redirectUrl) {
      window.open(res.redirectUrl, '_blank');
    }
    dispatch(jumpingAsync.success(res));
  } catch (error) {
    dispatch(jumpingAsync.failure([error]));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const jumpToV1Top = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isJumping = getState().login.isJumping;
  if (isJumping) return;

  dispatch(jumpingAsync.request());
  try {
    const res = await ApiJumpToV1Top(apiClient);
    if (res.redirectUrl) {
      window.location.href = res.redirectUrl;
    }
    dispatch(jumpingAsync.success(res));
  } catch (error) {
    dispatch(jumpingAsync.failure([error]));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const jumpToV1TopFromDialog = (params: JumpToV1TopParams): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const isJumping = getState().login.isJumping;
  if (isJumping) return;

  dispatch(jumpingAsync.request());
  try {
    const res = await ApiJumpToV1TopFromDialog(apiClient, params);
    if (res.redirectUrl) {
      window.location.href = res.redirectUrl;
    }
    dispatch(jumpingAsync.success(res));
  } catch (error) {
    dispatch(jumpingAsync.failure([error]));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const verifyToken = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(verifyTokenAsync.request());
  const tokenDetails = await ApiVerifyToken(apiClient);
  dispatch(verifyTokenAsync.success(tokenDetails));
};

export const setVerifyToken = (data: TokenInfo | {}): AppThunk => async dispatch => {
  dispatch(setVerifyTokenStandard(data));
};
