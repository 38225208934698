import { ApiClient } from '@travel/api-client';

import { ProviderRestriction } from 'ProviderRestrictions-Types';

export const ApiFetchProviderRestrictions = (http: ApiClient): Promise<ProviderRestriction[]> =>
  http.get('/provider-api/extranet/providers/restrictions');

export const ApiFetchProviderRestrictionCsv = (http: ApiClient, id: string) =>
  http.get(`/provider-api/extranet/providers/restrictions/export`, {
    values: {
      restrictionType: id,
    },
    ignoreCommonErrorHandler: true,
    timeout: 30000,
  });

export const ApiUploadProviderRestrictionCsv = (http: ApiClient, id: string, formData: FormData) =>
  http.post(`/provider-api/extranet/providers/restrictions/import?restrictionType=${id}`, {
    values: formData,
    ignoreCommonErrorHandler: true,
    timeout: 60000,
  });
