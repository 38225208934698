import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  CancelPolicy,
  CancelPolicyErrors,
  BrowseParameters,
  CancelPolicyContent,
} from 'CancelPolicy-Types';
import {
  fetchCancelPolicyListAsync,
  fetchCancelPolicyAsync,
  fetchMostLaxCancelPolicyAsync,
  fetchDeletableCancelPolicyAsync,
  updateCancelPolicyBrowseParameter,
  putDeletableDialogAsync,
  createCancelPolicyAsync,
  updateCancelPolicyAsync,
  deleteCancelPolicyAsync,
  clearCancelPolicy,
} from './actions';
import { defaultCancelPolicy, initialBrowseParameter } from './initialValues';
import { defaultPolicyDeletable, defaultDeletableDialog } from './initialValues';

export const isCreating = createReducer(false)
  .handleAction([createCancelPolicyAsync.request, updateCancelPolicyAsync.request], () => true)
  .handleAction(
    [
      createCancelPolicyAsync.success,
      createCancelPolicyAsync.failure,
      updateCancelPolicyAsync.success,
      updateCancelPolicyAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction(deleteCancelPolicyAsync.request, () => true)
  .handleAction([deleteCancelPolicyAsync.success, deleteCancelPolicyAsync.failure], () => false);

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchCancelPolicyAsync.request, fetchCancelPolicyListAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchCancelPolicyAsync.success,
      fetchCancelPolicyAsync.failure,
      fetchCancelPolicyListAsync.success,
      fetchCancelPolicyListAsync.failure,
    ],
    (state, action) => false,
  );

export const policyList = createReducer({} as CancelPolicy).handleAction(
  fetchCancelPolicyListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const mostLaxPolicy = createReducer({} as CancelPolicyContent).handleAction(
  fetchMostLaxCancelPolicyAsync.success,
  (state, action) => action.payload,
);

export const policy = createReducer(defaultCancelPolicy as CancelPolicyContent)
  .handleAction(fetchCancelPolicyAsync.success, (state, action) => action.payload)
  .handleAction(clearCancelPolicy, () => defaultCancelPolicy as CancelPolicyContent);

export const deletablePolicyList = createReducer(defaultPolicyDeletable).handleAction(
  fetchDeletableCancelPolicyAsync.success,
  (state, action) => {
    const { payload } = action;
    const updatedPayload = {
      ...payload,
      statusCode: payload.status,
    };
    return updatedPayload;
  },
);

export const deletableDialog = createReducer(defaultDeletableDialog).handleAction(
  putDeletableDialogAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as CancelPolicyErrors[]).handleAction(
  fetchCancelPolicyAsync.failure,
  (state, action) => action.payload,
);

export const browseParameters = createReducer(
  initialBrowseParameter as BrowseParameters,
).handleAction(updateCancelPolicyBrowseParameter, (_state, action) => action.payload);

const cancelPolicyReducer = combineReducers({
  isFetching,
  policyList,
  mostLaxPolicy,
  policy,
  deletablePolicyList,
  deletableDialog,
  errors,
  browseParameters,
  isCreating,
  isDeleting,
});

export default cancelPolicyReducer;
export type CancelPolicyState = ReturnType<typeof cancelPolicyReducer>;
