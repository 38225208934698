import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';

import { toDecodedQuery } from '@travel/utils';

import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { pushLocation } from 'store/__router/actions';

import {
  ApiStatus,
  ProviderGroup,
  ProviderGroupErrors,
  ProviderGroupList,
  ProviderGroupRequest,
  ProviderList,
  QueryParameter,
  SimpleProviderGroup,
} from 'ProviderGroup-Types';
import {
  ApiCreateProviderGroup,
  ApiDeleteProviderGroup,
  ApiFetchProviderGroup,
  ApiFetchProviderGroupBasicInfo,
  ApiFetchProviderGroupList,
  ApiFetchProviderGroupProviders,
  ApiUpdateProviderGroup,
} from './apis';

export const setProviderGroupBasicInfoStandard = createStandardAction(
  'SET_PROVIDER_GROUP_BASIC_INFO',
)<ProviderGroup>();
export const clearProviderGroup = createStandardAction('CLEAR_PROVIDER_GROUP')();
export const fetchAllProviderGroupsAsync = createAsyncAction(
  'FETCH_ALL_PROVIDER_GROUPS_REQUEST',
  'FETCH_ALL_PROVIDER_GROUPS_SUCCESS',
  'FETCH_ALL_PROVIDER_GROUPS_FAILURE',
)<undefined, SimpleProviderGroup[], ProviderGroupErrors[]>();

export const fetchProviderGroupListAsync = createAsyncAction(
  'FETCH_PROVIDER_GROUP_LIST_REQUEST',
  'FETCH_PROVIDER_GROUP_LIST_SUCCESS',
  'FETCH_PROVIDER_GROUP_LIST_FAILURE',
)<undefined, ProviderGroupList, ProviderGroupErrors[]>();

export const fetchProviderGroupAsync = createAsyncAction(
  'FETCH_PROVIDER_GROUP_REQUEST',
  'FETCH_PROVIDER_GROUP_SUCCESS',
  'FETCH_PROVIDER_GROUP_FAILURE',
)<undefined, ProviderGroup, ProviderGroupErrors[]>();

export const createProviderGroupAsync = createAsyncAction(
  'CREATE_PROVIDER_GROUP_REQUEST',
  'CREATE_PROVIDER_GROUP_SUCCESS',
  'CREATE_PROVIDER_GROUP_FAILURE',
)<undefined, ProviderGroup, ProviderGroupErrors[]>();

export const updateProviderGroupAsync = createAsyncAction(
  'UPDATE_PROVIDER_GROUP_REQUEST',
  'UPDATE_PROVIDER_GROUP_SUCCESS',
  'UPDATE_PROVIDER_GROUP_FAILURE',
)<undefined, ApiStatus, ProviderGroupErrors[]>();

export const deleteProviderGroupAsync = createAsyncAction(
  'DELETE_PROVIDER_GROUP_REQUEST',
  'DELETE_PROVIDER_GROUP_SUCCESS',
  'DELETE_PROVIDER_GROUP_FAILURE',
)<undefined, ApiStatus, ProviderGroupErrors[]>();

export const fetchProviderGroupBaseInfoAsync = createAsyncAction(
  'FETCH_PROVIDER_GROUP_BASE_INFO_REQUEST',
  'FETCH_PROVIDER_GROUP_BASE_INFO_SUCCESS',
  'FETCH_PROVIDER_GROUP_BASE_INFO_FAILURE',
)<undefined, ProviderGroup, ProviderGroupErrors[]>();

export const fetchProviderGroupProvidersAsync = createAsyncAction(
  'FETCH_PROVIDER_GROUP_PROVIDERS_REQUEST',
  'FETCH_PROVIDER_GROUP_PROVIDERS_SUCCESS',
  'FETCH_PROVIDER_GROUP_PROVIDERS_FAILURE',
)<undefined, ProviderList, ProviderGroupErrors[]>();

export const fetchAllProviderGroups = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchAllProviderGroupsAsync.request());
  try {
    const LIMIT = 100;
    let query = { limit: LIMIT, offset: 0 };
    const response = await ApiFetchProviderGroupList(apiClient, query);
    let { total, offset, contents } = response;

    while (total - (offset + LIMIT) > 0) {
      query = { ...query, offset: offset + LIMIT };
      let newResponse = await ApiFetchProviderGroupList(apiClient, query);
      contents = [...contents, ...newResponse.contents];
      total = newResponse.total;
      offset = newResponse.offset;
    }
    dispatch(fetchAllProviderGroupsAsync.success(contents));
  } catch (err) {
    dispatch(fetchAllProviderGroupsAsync.failure(err));
  }
};

export const fetchProviderGroupList = (query: QueryParameter): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProviderGroupListAsync.request());
  try {
    const res = await ApiFetchProviderGroupList(apiClient, query);
    dispatch(fetchProviderGroupListAsync.success(res));
  } catch (err) {
    dispatch(fetchProviderGroupListAsync.failure(err));
  }
};

export const fetchProviderGroup = (query: QueryParameter, groupId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProviderGroupAsync.request());
  try {
    const res = await ApiFetchProviderGroup(apiClient, query, groupId);
    dispatch(fetchProviderGroupAsync.success(res));
  } catch (err) {
    dispatch(fetchProviderGroupAsync.failure(err));
  }
};

export const createProviderGroup = (
  data: ProviderGroupRequest,
): AppThunk<Promise<string | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(createProviderGroupAsync.request());
  try {
    const res = await ApiCreateProviderGroup(apiClient, data);
    dispatch(createProviderGroupAsync.success(res));
    return res.groupId;
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    dispatch(createProviderGroupAsync.failure(err));
  }
};

export const updateProviderGroup = (
  data: ProviderGroupRequest,
  prevSearch?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateProviderGroupAsync.request());
  try {
    const res = await ApiUpdateProviderGroup(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(updateProviderGroupAsync.success(res));
    dispatch(
      pushLocation({ pathname: `/internal/providerGroup/${data.groupId}`, state: { prevSearch } }),
    );
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    dispatch(updateProviderGroupAsync.failure(err));
  }
};

export const deleteProviderGroup = (
  groupId: string,
  name: string,
  accountId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteProviderGroupAsync.request());
  try {
    const res = await ApiDeleteProviderGroup(apiClient, groupId, accountId);
    res.message = name;
    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteProviderGroupAsync.success(res));
    dispatch(pushLocation(`/internal/providerGroup`));
  } catch (err) {
    dispatch(deleteProviderGroupAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const fetchProviderGroupBaseInfo = (groupId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchProviderGroupBaseInfoAsync.request());
  try {
    const res = await ApiFetchProviderGroupBasicInfo(apiClient, groupId);
    dispatch(fetchProviderGroupBaseInfoAsync.success(res));
  } catch (err) {
    dispatch(fetchProviderGroupBaseInfoAsync.failure(err));
  }
};

export const fetchProviderGroupProviderList = (
  query: QueryParameter,
  groupId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchProviderGroupProvidersAsync.request());
  try {
    const res = await ApiFetchProviderGroupProviders(apiClient, toDecodedQuery(query), groupId);
    dispatch(fetchProviderGroupProvidersAsync.success(res));
  } catch (err) {
    dispatch(fetchProviderGroupProvidersAsync.failure(err));
  }
};

export const setProviderGroupBasicInfo = (data: ProviderGroup): AppThunk => async dispatch => {
  dispatch(setProviderGroupBasicInfoStandard(data));
};
