import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  FilterComponents,
  FilterComponent,
  FilterComponentStatus,
  BrowseParameters,
} from 'FilterComponent-Types';
import {
  ApiDeleteFilterComponent,
  ApiFetchFilterComponent,
  ApiFetchFilterComponents,
  ApiPutFilterComponent,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';

export const updateFilterComponentBrowseParameter = createStandardAction(
  'UPDATE_FILTER_COMPONENT_BROWSE_PARAMETER',
)<BrowseParameters>();

export const fetchFilterComponentsAsync = createAsyncAction(
  'FETCH_FILTER_COMPONENTS_REQUEST',
  'FETCH_FILTER_COMPONENTS_SUCCESS',
  'FETCH_FILTER_COMPONENTS_FAILURE',
)<undefined, FilterComponents, FilterComponentStatus[]>();

export const fetchFilterComponentAsync = createAsyncAction(
  'FETCH_FILTER_COMPONENT_REQUEST',
  'FETCH_FILTER_COMPONENT_SUCCESS',
  'FETCH_FILTER_COMPONENT_FAILURE',
)<undefined, FilterComponent, FilterComponentStatus[]>();

export const putFilterComponentAsync = createAsyncAction(
  'PUT_FILTER_COMPONENT_REQUEST',
  'PUT_FILTER_COMPONENT_SUCCESS',
  'PUT_FILTER_COMPONENT_FAILURE',
)<undefined, FilterComponentStatus, FilterComponentStatus[]>();

export const deleteFilterComponentAsync = createAsyncAction(
  'DELETE_FILTER_COMPONENT_REQUEST',
  'DELETE_FILTER_COMPONENT_SUCCESS',
  'DELETE_FILTER_COMPONENT_FAILURE',
)<undefined, FilterComponentStatus, FilterComponentStatus[]>();

export const fetchFilterComponents = (query: {
  [key: string]: string | number;
}): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchFilterComponentsAsync.request());
  try {
    const res = await ApiFetchFilterComponents(apiClient, query);
    dispatch(fetchFilterComponentsAsync.success(res));
  } catch (errors) {
    dispatch(fetchFilterComponentsAsync.failure([errors as FilterComponentStatus]));
  }
};

export const fetchFilterComponent = (id: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchFilterComponentAsync.request());
    const res = await ApiFetchFilterComponent(apiClient, id);
    dispatch(fetchFilterComponentAsync.success(res));
  } catch (errors) {
    dispatch(fetchFilterComponentAsync.failure([errors as FilterComponentStatus]));
  }
};

export const putFilterComponent = (data: FilterComponent): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(putFilterComponentAsync.request());
    const res = await ApiPutFilterComponent(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putFilterComponentAsync.success(res));
    dispatch(pushLocation(`/internal/filterComponent/${data.id}`));
  } catch (errors) {
    dispatch(putFilterComponentAsync.failure([errors as FilterComponentStatus]));
  }
};

export const deleteFilterComponent = (id: string, name: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(deleteFilterComponentAsync.request());
  try {
    const res = await ApiDeleteFilterComponent(apiClient, id);
    res.message = name;
    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteFilterComponentAsync.success(res));
  } catch (error) {
    dispatch(deleteFilterComponentAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success({ ...error, isDelete: true }));
  }
};
