import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { TaxFeeTypes, TaxFeeTypesErrors } from 'TaxFeeTypes-Types';
import { fetchTaxFeeTypesAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchTaxFeeTypesAsync.request], (state, action) => true)
  .handleAction(
    [fetchTaxFeeTypesAsync.success, fetchTaxFeeTypesAsync.failure],
    (state, action) => false,
  );

export const taxFeeTypes = createReducer([] as TaxFeeTypes[]).handleAction(
  fetchTaxFeeTypesAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as TaxFeeTypesErrors[]).handleAction(
  [fetchTaxFeeTypesAsync.failure],
  (state, action) => action.payload,
);

const taxFeeTypesReducer = combineReducers({
  isFetching,
  taxFeeTypes,
  errors,
});

export default taxFeeTypesReducer;
export type TaxFeeTypesState = ReturnType<typeof taxFeeTypesReducer>;
