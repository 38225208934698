import env from '@travel/env';

const ERROR_PAGE_BASE_URL = 'error.html?errorCode=';

const ERROR_URLS: {
  [key: string]: string;
} = {
  COMMON: `${ERROR_PAGE_BASE_URL}EVEREST_ERROR`, // Common error
  WRONG_ID: `${ERROR_PAGE_BASE_URL}WRONG_PROVIDER_ID`, // mismatch provider id between url and login account data
  LOGIN: `${ERROR_PAGE_BASE_URL}LOGIN_ERROR`, // error on login process
  TOKEN: `${ERROR_PAGE_BASE_URL}NO_LOGIN`, // no token
  SESSION: `${ERROR_PAGE_BASE_URL}SESSION_EXPIRED`, // session is expired by time
} as const;

export type ErrorPageCodeType = typeof ERROR_URLS[keyof typeof ERROR_URLS];

const supportedErrorPageLanguages: string[] = ['en-US', 'ja-JP'];

export const getErrorPagePath = (errorCode: ErrorPageCodeType, langCode?: string) => {
  const basename = env('UNIVERSAL_BASE_NAME') ? `/${env('UNIVERSAL_BASE_NAME')}` : '';
  return `${window.location.origin}${basename}/${ERROR_URLS[errorCode]}${getErrorPageLangQuery(
    langCode,
  )}`;
};

export const getErrorPageLangQuery = (langCode?: string) => {
  return langCode && supportedErrorPageLanguages.includes(langCode) ? `&lang=${langCode}` : '';
};

export default ERROR_URLS;
