import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';

import { getTranslation } from '@travel/translation';
import { toDecodedQuery, toQuery } from '@travel/utils';

import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { pushLocation } from 'store/__router/actions';
import { clearMediaOnLocal } from 'store/media/actions';

import {
  ApiStatus,
  PlaceCategoryOption,
  PlaceDetail,
  PlaceErrors,
  PlaceListQueryParams,
  PlaceSimple,
  SEOError,
  UploadResError,
  UploadResSuccess,
} from 'Place-Types';
import {
  ApiFetchPlaces,
  ApiFetchPlacesByParentId,
  ApiFetchPlaceCategories,
  ApiFetchPlaceDetail,
  ApiPostPlace,
  ApiPutPlace,
  ApiSEODownload,
  ApiSEOUpload,
  ApiUploadFile,
} from './apis';

export const clearPlaceList = createStandardAction('CLEAR_PLACE_LIST')();
export const clearPlaceDetail = createStandardAction('CLEAR_PLACE_DETAIL')();
export const updatePlaceLayers = createStandardAction('UPDATE_PLACE_LAYERS')<PlaceSimple[]>();

/**Fetch Options for selctbox
 *   update selectbox directily from response */
export const fetchPlacesByParentIdAsync = createAsyncAction(
  'FETCH_PLACES_BYPID_REQUEST',
  'FETCH_PLACES_BYPID_SUCCESS',
  'FETCH_PLACES_BYPID_FAILURE',
)<undefined, PlaceSimple[], PlaceErrors[]>();

export const fetchPlacesByParentId = (
  parentId?: string,
): AppThunk<Promise<PlaceSimple[] | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchPlacesByParentIdAsync.request());
  try {
    const res = await ApiFetchPlacesByParentId(apiClient, parentId);
    dispatch(fetchPlacesByParentIdAsync.success(res));
    return res;
  } catch (err) {
    dispatch(fetchPlacesByParentIdAsync.failure(err));
    return [];
  }
};

export const fetchPlaceLayerOptionsAsync = createAsyncAction(
  'FETCH_PLACE_LAYERS_REQUEST',
  'FETCH_PLACE_LAYERS_SUCCESS',
  'FETCH_PLACE_LAYERS_FAILURE',
)<undefined, Array<PlaceSimple[] | undefined>, PlaceErrors[]>();

/** Fetch place options.
 * If there is same option comparing ancestors, this returns previous one instead of calling api. */
export const fetchPlaceLayerOptions = (ancestors: PlaceSimple[]): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchPlaceLayerOptionsAsync.request());
  const prevAncestors = getState().place.placeLayers;
  const prevOptions = getState().place.placeLayerOptions;

  try {
    const promises: Promise<PlaceSimple[] | undefined>[] = [];
    ancestors.forEach((anc, index) => {
      if (anc.id === prevAncestors?.[index]?.id) {
        promises.push((async () => prevOptions[index])());
      } else {
        promises.push(dispatch(fetchPlacesByParentId(anc.id)));
      }
    });
    const res = await Promise.all(promises);
    dispatch(fetchPlaceLayerOptionsAsync.success(res));
    dispatch(updatePlaceLayers(ancestors));
  } catch (err) {
    dispatch(fetchPlaceLayerOptionsAsync.failure(err));
  }

  return;
};

/**Fetch Place List */
export const fetchPlacesAsync = createAsyncAction(
  'FETCH_PLACES_REQUEST',
  'FETCH_PLACES_SUCCESS',
  'FETCH_PLACES_FAILURE',
)<undefined, PlaceDetail, PlaceErrors[]>();

export const fetchAreaPlacesAsync = createAsyncAction(
  'FETCH_AREA_PLACES_REQUEST',
  'FETCH_AREA_PLACES_SUCCESS',
  'FETCH_AREA_PLACES_FAILURE',
)<undefined, PlaceDetail, PlaceErrors[]>();

export const fetchCountriesAsync = createAsyncAction(
  'FETCH_COUNTRIES_REQUEST',
  'FETCH_COUNTRIES_SUCCESS',
  'FETCH_COUNTRIES_FAILURE',
)<undefined, PlaceDetail, PlaceErrors[]>();

export const fetchPlaceList = (
  queryParameter: PlaceListQueryParams,
  rejectIfFetching?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isFetching = getState().place.isFetching;
  if (isFetching && rejectIfFetching) return;

  dispatch(fetchPlacesAsync.request());
  const res = await ApiFetchPlaces(apiClient, toDecodedQuery(queryParameter));
  dispatch(fetchPlacesAsync.success(res));
};

export const fetchCountries = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  const queryParameter = {
    filter: { category: ['COUNTRY'], children: 'ALL', type: ['AREA'] },
    limit: 200,
    offset: 0,
  };
  dispatch(fetchCountriesAsync.request());
  const res = await ApiFetchPlaces(apiClient, toQuery(queryParameter));
  dispatch(fetchCountriesAsync.success(res));
};

/**Fetch Place Detail*/
export const fetchPlaceDetailAsync = createAsyncAction(
  'FETCH_PLACE_DETAIL_REQUEST',
  'FETCH_PLACE_DETAIL_SUCCESS',
  'FETCH_PLACE_DETAIL_FAILURE',
)<undefined, PlaceDetail, PlaceErrors[]>();

export const fetchPlaceDetail = (placeId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchPlaceDetailAsync.request());
  const res = await ApiFetchPlaceDetail(apiClient, placeId);
  dispatch(fetchPlaceDetailAsync.success(res));
};

export const postPlaceAsync = createAsyncAction(
  'POST_PLACE_REQUEST',
  'POST_PLACE_SUCCESS',
  'POST_PLACE_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const postPlace = (data: PlaceDetail): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(postPlaceAsync.request());
  try {
    const res = await ApiPostPlace(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(postPlaceAsync.success(res));
    dispatch(pushLocation(`/internal/places/${res.id}`));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    dispatch(postPlaceAsync.failure(err));
  }
};

export const putPlaceAsync = createAsyncAction(
  'PUT_PLACE_REQUEST',
  'PUT_PLACE_SUCCESS',
  'PUT_PLACE_FAILURE',
)<undefined, PlaceDetail, ApiStatus[]>();

export const putPlace = (data: PlaceDetail, placeId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(postPlaceAsync.request());
  try {
    const res = await ApiPutPlace(apiClient, data, placeId);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putPlaceAsync.success(data));
    dispatch(pushLocation(`/internal/places/${placeId}`));
    dispatch(clearMediaOnLocal());
  } catch (err) {
    dispatch(putPlaceAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

/**Fetch Place List */
export const fetchPlaceCategoriesAsync = createAsyncAction(
  'FETCH_PLACE_CATEGORIES_REQUEST',
  'FETCH_PLACE_CATEGORIES_SUCCESS',
  'FETCH_PLACE_CATEGORIES_FAILURE',
)<undefined, PlaceCategoryOption[], PlaceErrors[]>();

/** fetch Place Categories */
export const fetchPlaceCategories = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  // const searchText = getState().place.searchText;
  dispatch(fetchPlaceCategoriesAsync.request());
  const res = await ApiFetchPlaceCategories(apiClient);
  dispatch(fetchPlaceCategoriesAsync.success(res));
};

/** SEO Management */
export const uploadSEOMgmtCSVFileAsync = createAsyncAction(
  'UPLOAD_SEO_REQUEST',
  'UPLOAD_SEO_SUCCESS',
  'UPLOAD_SEO_FAILURE',
)<undefined, undefined, SEOError>();

export const downloadSEOMgmtCSVFileAsync = createAsyncAction(
  'DOWNLOAD_SEO_REQUEST',
  'DOWNLOAD_SEO_SUCCESS',
  'DOWNLOAD_SEO_FAILURE',
)<undefined, undefined, SEOError>();

export const clearSEOError = createStandardAction('CLEAR_SEO_ERROR')();

export const uploadSEOMgmtCSVFile = (csvFile: File): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(uploadSEOMgmtCSVFileAsync.request());
  dispatch(clearSEOError());
  try {
    const formData = new FormData();
    formData.append('file', csvFile, csvFile.name);
    const response = await ApiSEOUpload(apiClient, formData);

    dispatch(fetchGlobalErrorDialogAsync.success(response));
    dispatch(uploadSEOMgmtCSVFileAsync.success());
  } catch (error) {
    dispatch(uploadSEOMgmtCSVFileAsync.failure(error));
    dispatch(
      fetchGlobalErrorDialogAsync.success({
        ...(error as ApiStatus),
        errorTitle: getTranslation({ id: 'Extranet_Error.System_Logic.Upload_Failed' }).join(''),
      }),
    );
  }
};

export const downloadSEOMgmtCSVFile = (): AppThunk<Promise<
  | {
      response: Response;
      blob: Blob;
    }
  | any
>> => async (dispatch, getState, { apiClient }) => {
  dispatch(downloadSEOMgmtCSVFileAsync.request());
  try {
    const response = await ApiSEODownload(apiClient);
    const blob = await response.blob();
    dispatch(downloadSEOMgmtCSVFileAsync.success());
    return { response, blob };
  } catch (error) {
    dispatch(downloadSEOMgmtCSVFileAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const uploadFile = (
  file: File,
): AppThunk<Promise<UploadResSuccess | UploadResError>> => async (
  _dispatch,
  _getState,
  { apiClient },
) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  try {
    const res = await ApiUploadFile(apiClient, formData);
    return res;
  } catch (error) {
    return error;
  }
};
