import { FilterGroups, FilterGroup, FilterGroupStatus } from 'FilterGroup-Types';
import { ApiClient } from '@travel/api-client';
import { internalPrefix } from 'constants/api-prefix';

export const ApiFetchFilterGroups = (http: ApiClient, params: Object): Promise<FilterGroups> => {
  return http
    .get(`${internalPrefix}/filterGroups/search`, { values: params })
    .then((data: FilterGroups) => data);
};

export const ApiFetchFilterGroup = (http: ApiClient, filterGroupId: string): Promise<FilterGroup> =>
  http.get(`${internalPrefix}/filterGroups/${filterGroupId}`).then((data: FilterGroup) => data);

export const ApiPostFilterGroup = (
  http: ApiClient,
  data: FilterGroup,
): Promise<FilterGroupStatus> =>
  http
    .post(`${internalPrefix}/filterGroups`, { values: data })
    .then((data: FilterGroupStatus) => data)
    .catch((data: FilterGroupStatus) => data);

export const ApiPutFilterGroup = (http: ApiClient, data: FilterGroup): Promise<FilterGroupStatus> =>
  http
    .put(`${internalPrefix}/filterGroups/${data.id}`, { values: data })
    .then((data: FilterGroupStatus) => data)
    .catch((data: FilterGroupStatus) => data);

export const ApiDeleteFilterGroup = (http: ApiClient, id: string): Promise<FilterGroupStatus> =>
  http
    .delete(`${internalPrefix}/filterGroups/${id}`, {})
    .then((data: FilterGroupStatus) => data)
    .catch((data: FilterGroupStatus) => data);
