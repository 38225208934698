import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { ApplicableSizeTypes, SizeTypesStatus } from 'SizeTypes-Types';
import { ApiFetchSizeTypes } from './apis';

export const fetchFeatureSizeTypesAsync = createAsyncAction(
  'FETCH_SIZE_TYPES_REQUEST',
  'FETCH_SIZE_TYPES_SUCCESS',
  'FETCH_SIZE_TYPES_FAILURE',
)<undefined, ApplicableSizeTypes, SizeTypesStatus[]>();

export const fetchResouceSizeTypesAsync = createAsyncAction(
  'FETCH_ITEM_SIZE_TYPES_REQUEST',
  'FETCH_ITEM_SIZE_TYPES_SUCCESS',
  'FETCH_ITEM_SIZE_TYPES_FAILURE',
)<undefined, ApplicableSizeTypes, SizeTypesStatus[]>();

export const fetchFeatureSizeTypes = (
  featureId: string,
  featureGroupId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchFeatureSizeTypesAsync.request());
  try {
    const state = getState();
    const res = await ApiFetchSizeTypes(
      apiClient,
      { category: 'FEATURE', featureId, featureGroupId },
      state._i18n.language,
    );
    dispatch(fetchFeatureSizeTypesAsync.success(res));
  } catch (error) {
    dispatch(fetchFeatureSizeTypesAsync.failure([error as SizeTypesStatus]));
  }
};

export const fetchItemSizeTypes = (category: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchResouceSizeTypesAsync.request());
  try {
    const state = getState();
    const res = await ApiFetchSizeTypes(apiClient, { category }, state._i18n.language);
    dispatch(fetchResouceSizeTypesAsync.success(res));
  } catch (error) {
    dispatch(fetchResouceSizeTypesAsync.failure([error as SizeTypesStatus]));
  }
};
