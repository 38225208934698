const DEFAULT_ACCOUNT_DETAILS = {
  userUuid: '',
  providerUuid: '',
  userId: '',
  name: '',
  email: '',
  permissions: '',
  insertDateTime: '',
  updateDateTime: '',
  remark: '',
  language: '',
  active: false,
  firstAccount: false,
  passwordExpiredDateTime: '',
};

export default DEFAULT_ACCOUNT_DETAILS;
