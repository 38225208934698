import {
  ApiFetchPromotions,
  ApiFetchGoToPromotion,
  ApiDeleteSinglePromotion,
  ApiFetchDeletablePromotion,
  ApiPostPromotion,
  ApiPutPromotion,
} from './apis';
import {
  ApiStatus,
  Deletable,
  DeletableDialog,
  XtrPagedItemsOfXtrGotoPromotion,
  XtrGotoPromotion,
  SalsPromotionsErrors,
} from 'GoToPromotion-Types';
import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';

export const clearCurrentGoToPromotion = createStandardAction('CLEAR_GOTO_PROMOTION')();
export const clearCurrentGoToDeletable = createStandardAction('CLEAR_DELETABLE_GOTO_PROMOTION')();

export const fetchGoToPromotionsAsync = createAsyncAction(
  'FETCH_GOTO_PROMOTIONS_REQUEST',
  'FETCH_GOTO_PROMOTIONS_SUCCESS',
  'FETCH_GOTO_PROMOTIONS_FAILURE',
)<undefined, XtrPagedItemsOfXtrGotoPromotion, SalsPromotionsErrors[]>();

export const fetchGoToPromotionAsync = createAsyncAction(
  'FETCH_GOTO_PROMOTION_REQUEST',
  'FETCH_GOTO_PROMOTION_SUCCESS',
  'FETCH_GOTO_PROMOTION_FAILURE',
)<undefined, XtrGotoPromotion, SalsPromotionsErrors[]>();

export const fetchDeletablePromotionAsync = createAsyncAction(
  'FETCH_DELETABLE_GOTO_PROMOTIONS_REQUEST',
  'FETCH_DELETABLE_GOTO_PROMOTIONS_SUCCESS',
  'FETCH_DELETABLE_GOTO_PROMOTIONS_FAILURE',
)<undefined, Deletable, SalsPromotionsErrors[]>();

export const putDeletableDialogAsync = createAsyncAction(
  'DELETABLE_DIALOG_PROMOTION_REQUEST',
  'DELETABLE_DIALOG_PROMOTION_SUCCESS',
  'DELETABLE_DIALOG_PROMOTION_FAILURE',
)<undefined, DeletableDialog, SalsPromotionsErrors[]>();

export const postGoToPromotionAsync = createAsyncAction(
  'POST_GOTO_PROMOTION_REQUEST',
  'POST_GOTO_PROMOTION_SUCCESS',
  'POST_GOTO_PROMOTION_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const putGoToPromotionAsync = createAsyncAction(
  'PUT_GOTO_PROMOTION_REQUEST',
  'PUT_GOTO_PROMOTION_SUCCESS',
  'PUT_GOTO_PROMOTION_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const deleteGoToPromotionAsync = createAsyncAction(
  'DELETE_GOTO_PROMOTION_REQUEST',
  'DELETE_GOTO_PROMOTION_SUCCESS',
  'DELETE_GOTO_PROMOTION_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const fetchGoToPromotions = (queries: any): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchGoToPromotionsAsync.request());
  const res = await ApiFetchPromotions(apiClient, queries);
  dispatch(fetchGoToPromotionsAsync.success(res));
};

export const fetchGoToPromotion = (promotionId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchGoToPromotionAsync.request());
    const promotion = await ApiFetchGoToPromotion(apiClient, promotionId);
    dispatch(fetchGoToPromotionAsync.success(promotion));
  } catch (error) {
    dispatch(fetchGoToPromotionAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const fetchDeletable = (promotionId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchDeletablePromotionAsync.request());
    const res = await ApiFetchDeletablePromotion(apiClient, promotionId);
    dispatch(putDeletableDialogAsync.success({ isShow: true }));
    dispatch(fetchDeletablePromotionAsync.success(res));
  } catch (error) {
    error.status === 403
      ? dispatch(fetchDeletablePromotionAsync.success(error as ApiStatus)) &&
        dispatch(putDeletableDialogAsync.success({ isShow: true }))
      : dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const postGoToPromotion = (data: any): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(postGoToPromotionAsync.request());
  try {
    const res = await ApiPostPromotion(apiClient, data);
    dispatch(postGoToPromotionAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(pushLocation(`/internal/gotoCoupons/${res.id}`));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    dispatch(postGoToPromotionAsync.failure(err));
  }
};

export const putGoToPromotion = (
  data: any,
  promotionId: string,
  state?: { prevSearch: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(putGoToPromotionAsync.request());
  try {
    const res = await ApiPutPromotion(apiClient, data, promotionId);
    dispatch(putGoToPromotionAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(pushLocation({ pathname: `/internal/gotoCoupons/${promotionId}`, state }));
  } catch (err) {
    dispatch(putGoToPromotionAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const deleteGoToPromotion = (promotionId: string, promotionName: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(deleteGoToPromotionAsync.request());
  try {
    const res = await ApiDeleteSinglePromotion(apiClient, promotionId);
    res.message = promotionName; // Pass the deleted object name
    dispatch(deleteGoToPromotionAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
  } catch (err) {
    dispatch(deleteGoToPromotionAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
  }
};
