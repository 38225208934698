import loadable from '@loadable/component';
import React from 'react';
import UniversalRouter from 'universal-router';

import { noticeError } from '@travel/tracker';
import { parseQueryString } from '@travel/utils/src/queryString';

import App from 'core/App';
import loginResolver from 'pages/Login/loginResolver';
import logoutResolver from 'pages/Login/logoutResolver';
import removeCookieResolver from 'pages/Login/removeCookieResolver';
import ERROR_URLS, { getErrorPageLangQuery } from 'utils/errorPageHelper';
import { getProviderRelatedIdInUrl } from 'utils/urlHelper';
import { getIsNotValidProviderIdInUrl } from 'utils/userScopeHelper';
import { getIsRFBUser } from 'utils/verifyTokenHelper';
import getExtranetRoutes from './extranetRoutes';
import getInternalRoutes from './internalRoutes';
import getProviderGroupsRoutes from './providerGroupsRoutes';

import { pushLocation } from 'store/__router/actions';
import { setIsDataInputLanguageAllow } from 'store/languages/actions';

import { Permission } from 'Aliases-Types';
import PERM from 'constants/permissions';
import { Translate } from 'core/translate';
import { universalRouterProps } from './types';

function NoPermission() {
  return (
    <div data-testid="NoPermission-wrapper">
      <Translate id="Extranet_Error.Permission.No_Permission" />
    </div>
  );
}

export default (props: universalRouterProps) => {
  const { req, store } = props;
  const isServer = !!req;

  const extranetRoutes = getExtranetRoutes(props);
  const internalRoutes = getInternalRoutes(props);
  const providerGroupsRoutes = getProviderGroupsRoutes(props);

  const routes = [
    {
      path: '/',
      async action(context: any) {
        //NOTE: tmp fix to allow deployment on one cloud until we fix it definitely or we adopt balloon way
        // if (props.req?.headers['health-checkup'] === HEALTH_CHECK_UUID) {
        //   const HealthCheck = loadable(() => import('@travel/ui/components/HealthCheck'));
        //   return <HealthCheck />;
        // }

        const Login = loadable(() => import(/* webpackChunkName: "pages-Login" */ 'pages/Login'));

        return (
          <App {...context}>
            <Login {...context}></Login>
          </App>
        );
      },
    },
    {
      path: '/login',
      async action(context: any) {
        const Login = loadable(() => import(/* webpackChunkName: "pages-Login" */ 'pages/Login'));
        if (isServer) {
          await loginResolver(props, context);
        } else if (!context.skipResolver) {
          await loginResolver(props, context);
        }

        return (
          <App {...context}>
            <Login {...context}></Login>
          </App>
        );
      },
    },
    {
      path: '/logout',
      async action(context: any) {
        const Login = loadable(() => import(/* webpackChunkName: "pages-Login" */ 'pages/Login'));
        if (!isServer) {
          await logoutResolver(props, context);
        } else {
          await removeCookieResolver(props, context);
        }

        return (
          <App {...context}>
            <Login {...context}></Login>
          </App>
        );
      },
    },
    {
      path: '/notFound',
      async action(context: any) {
        const NotFound = loadable(() =>
          import(/* webpackChunkName: "pages-NotFound" */ 'pages/NotFound'),
        );
        return (
          <App {...context}>
            <NotFound {...context}></NotFound>
          </App>
        );
      },
    },
    {
      path: '/unauthorized',
      async action(context: any) {
        const Unauthorized = loadable(() =>
          import(/* webpackChunkName: "pages-Unauthorized" */ 'pages/Unauthorized'),
        );
        return (
          <App {...context}>
            <Unauthorized {...context}></Unauthorized>
          </App>
        );
      },
    },
    {
      path: '/login/:process?',
      async action(context: any) {
        const Login = loadable(() => import(/* webpackChunkName: "pages-Login" */ 'pages/Login'));
        return (
          <App {...context}>
            <Login {...context}></Login>
          </App>
        );
      },
    },
    ...extranetRoutes,
    ...internalRoutes,
    ...providerGroupsRoutes,
  ];

  return new UniversalRouter(routes, {
    resolveRoute(context, params) {
      const baseName =
        typeof process !== 'undefined' && process.env.UNIVERSAL_BASE_NAME
          ? `/${process.env.UNIVERSAL_BASE_NAME}`
          : '';
      const currentRoute = context.route;
      const { accessControl, login, _i18n } = store.getState();
      const uiLanguage = _i18n?.language;
      const langQuery = getErrorPageLangQuery(uiLanguage);
      const providerRelatedIdInUrl = getProviderRelatedIdInUrl(context.baseUrl);

      const isNotValidURL = getIsNotValidProviderIdInUrl({
        loggedInUser: accessControl.loggedInUser,
        providerRelatedIdInUrl,
      });
      const isRFBUser = getIsRFBUser(login.verifyTokenDetail.loginScheme);

      if (isNotValidURL) {
        console.log('Router Error:', 'providerRelated ID in Token and in URL are different.');
        noticeError('Router Error:', 'providerRelated ID in Token and in URL are different.');

        store.dispatch(
          pushLocation(
            `${baseName}/${isRFBUser ? `${ERROR_URLS.WRONG_ID}${langQuery}` : 'logout'}`,
          ),
        );
        return undefined;
      }

      if (currentRoute.isDataInputLanguageAllowed) {
        store.dispatch(setIsDataInputLanguageAllow(true));
      } else {
        store.dispatch(setIsDataInputLanguageAllow(false));
      }

      if (typeof currentRoute.redirectIfNeeded === 'function') {
        const redirectPath = currentRoute.redirectIfNeeded({ providerRelatedIdInUrl });
        if (redirectPath) {
          store.dispatch(pushLocation(`${baseName}/${redirectPath}`));
          return <></>;
        }
      }

      if (typeof currentRoute.action === 'function') {
        const authKey = currentRoute.authKey;
        const permissions = accessControl.loggedInUser?.permissions;

        context.permissionLevel = PERM.readEditDelete;
        context.isCreate = currentRoute.isCreate;

        if (authKey && permissions && permissions.length) {
          const permissionLevel = permissions.find(
            (permission: Permission) => permission.objectName === authKey,
          )?.permissionLevel;

          if (permissionLevel) {
            context.permissionLevel = permissionLevel;
            /**
             * All pages -> NO_ACCESS -> No Permission to access
             * Create Page -> (READ) -> No permission to view create page
             */
            switch (permissionLevel) {
              case PERM.noAccess:
                return <NoPermission />;
              case PERM.read:
                if (currentRoute.isCreate) {
                  return <NoPermission />;
                }
                break;
              default:
                context.permissionLevel = permissionLevel;
            }
          } else return <NoPermission />;
        }
        context.query = context.search ? parseQueryString(context.search) : {};

        return context.route.action(context, params);
      }

      return undefined;
    },
  });
};
