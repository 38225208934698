import React, { AnchorHTMLAttributes } from 'react';
import styles from './gridRow.module.scss';
import { cx } from '@travel/utils';

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
} & AnchorHTMLAttributes<HTMLElement>;

function GridRow(props: Props) {
  const { className, ...rest } = props;

  return (
    <tr
      data-locator-id="GridRow-96eb291b-620a-4de9-8a88-5754c4d841d3"
      className={cx(className, styles.gridRow)}
      onClick={props.onClick}
      {...rest}
    >
      {props.children}
    </tr>
  );
}

export default GridRow;
