// This file is generated automatically by scripts/generate-icon.js. Don't modify it.

import NotFoundFolder from '../_raw/ui/icon_not_found_folder.svg';
import withIconProps from '../withIconProps';

export default withIconProps(NotFoundFolder, {
  shouldSetDefaultSize: true,
  defaultWidth: 375,
  defaultHeight: 215,
});
