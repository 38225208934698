import React from 'react';
import { SkeletonListItems, FlatButton, FixedButton } from '@travel/ui';
import SimpleSkeletonItem from '@travel/ui/components/SkeletonListItems/components/SimpleSkeletonItem';
import styles from './skeletonDrawer.module.scss';

type Props = {
  /** Flag to add media skeleton to item in list pageType skeleton. [default false] */
  hasMedia: boolean;
};

function SkeletonDrawer(props: Props) {
  const { hasMedia } = props;

  return (
    <div className={styles.drawer} data-testid="skeleton-drawer">
      <div className={styles.drawerContent}>
        <div className={styles.detailTypeHeader}>
          <div>
            <SimpleSkeletonItem
              hasMedia={hasMedia}
              itemClassName={styles.detailTypeHeaderContent}
              titleClassName={styles.title}
            />
          </div>
          <FlatButton className={styles.headerButton} isSkeleton>
            {'...'}
          </FlatButton>
        </div>
        <SkeletonListItems
          listClassName={styles.skeletonList}
          itemClassName={styles.skeletonItem}
          numberOfItem={10}
          skeletonType={'detailPage'}
          hasMedia
        />
      </div>
      <FixedButton
        primaryLabel={'...'}
        secondaryLabel={'...'}
        primaryProps={{ isSkeleton: true }}
        secondaryProps={{ isSkeleton: true, className: styles.longButton }}
        data-testid="skeleton-fixedButton-wrapper"
      >
        <FlatButton isSkeleton>{'...'}</FlatButton>
      </FixedButton>
    </div>
  );
}

export default SkeletonDrawer;
