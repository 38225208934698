import { createSelector } from 'reselect';
import { UtilityState } from './reducer';
import { RootState } from 'typesafe-actions';

export const getUtilityStore = (state: RootState) => state.utility;

export const getGlobalPopup = createSelector(
  getUtilityStore,
  (state: UtilityState) => state.globalPopup,
);
