import { ApiStatus, XtrGotoPromotion, XtrPagedItemsOfXtrGotoPromotion } from 'GoToPromotion-Types';
import { ApiClient } from '@travel/api-client';

export const ApiFetchPromotions = (
  http: ApiClient,
  queries: any,
): Promise<XtrPagedItemsOfXtrGotoPromotion> => {
  return http.get('/provider-api/extranet/gotoPromotions', { values: queries });
};

export const ApiFetchGoToPromotion = (
  http: ApiClient,
  promotionId: string,
): Promise<XtrGotoPromotion> => http.get(`/provider-api/extranet/gotoPromotions/${promotionId}`);

export const ApiFetchDeletablePromotion = (
  http: ApiClient,
  promotionId: string,
): Promise<ApiStatus> =>
  http
    .get(`/provider-api/extranet/gotoPromotions/${promotionId}/deletable`, {
      ignoreCommonErrorHandler: true,
    })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiPostPromotion = (http: ApiClient, data: XtrGotoPromotion): Promise<ApiStatus> =>
  http
    .post('/provider-api/extranet/gotoPromotions', { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiPutPromotion = (
  http: ApiClient,
  data: XtrGotoPromotion,
  promotionId: string,
): Promise<ApiStatus> =>
  http
    .put(`/provider-api/extranet/gotoPromotions/${promotionId}`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiDeleteSinglePromotion = (
  http: ApiClient,
  promotionId: string,
): Promise<ApiStatus> =>
  http
    .delete(`/provider-api/extranet/gotoPromotions/${promotionId}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
