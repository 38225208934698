import { universalRouterProps } from 'core/universalRouter/types';
import { logout, fetchCurrentStageProvider } from 'store/login/actions';

type QueryParam = {
  query: {
    currentStageProviderId: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, { query }: QueryParam) => {
  const promises: Array<Promise<void>> = [dispatch(logout())];
  const providerRelatedId = query?.currentStageProviderId;

  if (providerRelatedId) {
    promises.push(dispatch(fetchCurrentStageProvider(providerRelatedId)));
  }

  return Promise.all(promises);
};
