import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ProvidersErrors } from 'Providers-Types';
import { ApiStatus, Provider as ProviderType, ProviderBasicInfo } from 'ProvidersInformation-Types';

import {
  fetchProviderInformationAsync,
  setMidTaskMediaName,
  setProviderBasicInfoStandard,
  updateProviderInformationAsync,
  PostSelectionProviderSearchConditionAsync,
  PostSelectionProviderSearchCsvAsync,
  PostSelectionProviderSearchManuallyAsync,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchProviderInformationAsync.request], (state, action) => true)
  .handleAction(
    [fetchProviderInformationAsync.success, fetchProviderInformationAsync.failure],
    () => false,
  );

export const isUpdating = createReducer(false as boolean)
  .handleAction([updateProviderInformationAsync.request], (_state, _action) => true)
  .handleAction(
    [updateProviderInformationAsync.success, updateProviderInformationAsync.failure],
    (_state, _action) => false,
  );

export const providerInformation = createReducer({} as ProviderType).handleAction(
  [fetchProviderInformationAsync.success],
  (_state, action) => action.payload,
);

export const providerBasicInfo = createReducer({} as ProviderBasicInfo).handleAction(
  [setProviderBasicInfoStandard],
  (_state, action) => action.payload,
);

export const errors = createReducer([] as ProvidersErrors[])
  .handleAction(
    [fetchProviderInformationAsync.failure, updateProviderInformationAsync.failure],
    (state, action) => action.payload,
  )
  .handleAction(
    [fetchProviderInformationAsync.success, updateProviderInformationAsync.success],
    (state, action) => [],
  );

export const updateProviderInfo = createReducer({} as ApiStatus).handleAction(
  updateProviderInformationAsync.success,
  (_state, action) => action.payload,
);

export const isPostingProviderSearchManually = createReducer(false as boolean)
  .handleAction([PostSelectionProviderSearchManuallyAsync.request], (_state, _action) => true)
  .handleAction(
    [
      PostSelectionProviderSearchManuallyAsync.success,
      PostSelectionProviderSearchManuallyAsync.failure,
    ],
    (_state, _action) => false,
  );

export const isPostingProviderSearchCsv = createReducer(false as boolean)
  .handleAction([PostSelectionProviderSearchCsvAsync.request], (_state, _action) => true)
  .handleAction(
    [PostSelectionProviderSearchCsvAsync.success, PostSelectionProviderSearchCsvAsync.failure],
    (_state, _action) => false,
  );

export const isPostingProviderSearchByConditions = createReducer(false as boolean)
  .handleAction([PostSelectionProviderSearchConditionAsync.request], (_state, _action) => true)
  .handleAction(
    [
      PostSelectionProviderSearchConditionAsync.success,
      PostSelectionProviderSearchConditionAsync.failure,
    ],
    (_state, _action) => false,
  );

export const midTaskMediaName = createReducer('' as string).handleAction(
  setMidTaskMediaName,
  (state, action) => action.payload,
);

const providersInformationReducer = combineReducers({
  isFetching,
  isUpdating,
  errors,
  providerInformation,
  providerBasicInfo,
  isPostingProviderSearchManually,
  isPostingProviderSearchCsv,
  isPostingProviderSearchByConditions,
  midTaskMediaName,
});

export default providersInformationReducer;
export type ProvidersInformationState = ReturnType<typeof providersInformationReducer>;
