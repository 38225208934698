import React from 'react';

import { Skeleton, SkeletonListItems, ToolBar } from '@travel/ui';

import CreatePageHeader from 'components/CreatePageHeader';
import SkeletonTable from 'components/SkeletonTable';
import FilterPageSkeleton from './components/FilterPageSkeleton/index';
import FolderPageSkeleton from './components/FolderPageSkeleton';
import SkeletonDrawer from './components/SkeletonDrawer';

import styles from './skeletonPage.module.scss';
import { EMPTY_FUNCTION } from '@travel/traveler-core/constants';

const PAGE_TYPE = {
  LIST: 'list',
  DETAIL: 'detail',
  TABLE: 'table',
  FOLDER: 'folder',
  FILTER: 'filter',
  BOOKINGTABLE: 'booking',
} as const;
type SkeletonPageType = typeof PAGE_TYPE[keyof typeof PAGE_TYPE];

type Props = {
  /** Enum to specify the page type. */
  pageType: SkeletonPageType;
  /** Flag to add media skeleton to item in list pageType skeleton. [default false] */
  hasMedia: boolean;
  /** Flag to add margin-left to item in list pageType skeleton. [default true] */
  hasCheckbox: boolean;
  /** Flag to make item narrow in list pageType skeleton. [default false] */
  isNarrow: boolean;
};

function SkeletonPage(props: Props) {
  const { pageType, hasMedia, hasCheckbox, isNarrow } = props;

  return (
    <div className={styles[pageType]} data-testid="skeletonPage-wrapper">
      {pageType === PAGE_TYPE.DETAIL ? (
        <SkeletonDrawer hasMedia={hasMedia} />
      ) : pageType === PAGE_TYPE.FILTER ? (
        <FilterPageSkeleton />
      ) : (
        <>
          {pageType === PAGE_TYPE.BOOKINGTABLE ? (
            <>
              <Skeleton type="image" height="100px" />
              <div className={styles.grid}>
                <SkeletonTable />
              </div>
            </>
          ) : (
            <>
              <CreatePageHeader
                className={styles.pageHeader}
                onCreate={EMPTY_FUNCTION}
                onSearch={EMPTY_FUNCTION}
                hasCreateBtn={false}
                isAutoFocusDisabled={true}
              />
              {pageType === PAGE_TYPE.LIST ? (
                <>
                  <ToolBar className={styles.toolBar} children={<></>} />
                  <section className={styles.searchResult}></section>
                  <SkeletonListItems
                    hasMedia={hasMedia}
                    hasCheckbox={hasCheckbox}
                    itemClassName={isNarrow ? styles.skeletonItem : ''}
                  />
                </>
              ) : pageType === PAGE_TYPE.TABLE ? (
                <>
                  <ToolBar className={styles.toolBar} children={<></>} />
                  <div className={styles.grid}>
                    <SkeletonTable />
                  </div>
                </>
              ) : (
                pageType === PAGE_TYPE.FOLDER && <FolderPageSkeleton />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

SkeletonPage.defaultProps = {
  hasMedia: false,
  hasCheckbox: true,
  isNarrow: false,
};

export default SkeletonPage;
