import { FetchNotificationParams } from 'Notification-Types';

import { universalRouterProps } from 'core/universalRouter/types';
import { fetchNotifications } from 'store/notification/actions';

export type URLParams = { params: { providerId: string }; query: FetchNotificationParams };

export default (
  { store: { dispatch } }: universalRouterProps,
  { params: { providerId }, query }: URLParams,
) => {
  const queryParams = {
    ...query,
    offset: 0,
    limit: 30,
  };

  return Promise.all([dispatch(fetchNotifications(queryParams))]);
};
