import loadable from '@loadable/component';
import React from 'react';

import App from 'core/App';
import bookingDetailResolver from 'pages/BookingDetail/resolver';
import bookingListResolver from 'pages/BookingList/resolver';
import pgBasicInformationResolver from 'pages/PgBasicInformation/resolver';
import pgProviderListResolver from 'pages/PgProviderList/resolver';
import SkeletonPage from 'pages/SkeletonPage';

import { universalRouterProps } from './types';

export default (props: universalRouterProps) => {
  const { req } = props;
  const isServer = !!req;

  return [
    {
      path: '/providerGroup/:providerGroupId',
      async action(context: any) {
        const children = await context.next();
        return <App {...context}>{children}</App>;
      },
      children: [
        {
          path: '',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const Home = loadable(() => import(/* webpackChunkName: "pages-Home" */ 'pages/Home'));
            return <Home {...context} />;
          },
        },
        {
          path: '/provider',
          authKey: 'ProvMgmt',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const PgProviderList = loadable(
              () => import(/* webpackChunkName: "pages-PgProviderList" */ 'pages/PgProviderList'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await pgProviderListResolver(props, context || {});
            } else if (!context.skipResolver) {
              pgProviderListResolver(props, context || {});
            }

            return <PgProviderList {...context} />;
          },
        },
        {
          path: '/booking',
          authKey: 'BookMgmt',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const BookingList = loadable(() =>
              import(/* webpackChunkName: "pages-BookingList" */ 'pages/BookingList'),
            );

            if (!isServer) {
              bookingListResolver(props, context);
            }

            return <BookingList {...context} />;
          },
        },
        {
          path: '/booking/:bookingId',
          authKey: 'BookMgmt',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const BookingDetail = loadable(() =>
              import(/* webpackChunkName: "pages-BookingDetail" */ 'pages/BookingDetail'),
            );

            if (isServer) {
              await bookingDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await bookingDetailResolver(props, context);
            }

            return <BookingDetail {...context} />;
          },
        },
        {
          path: '/basicInfo',
          authKey: 'BasicInfo',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const PgProviderList = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-PgBasicInformation" */ 'pages/PgBasicInformation'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await pgBasicInformationResolver(props, context || {});
            } else if (!context.skipResolver) {
              pgBasicInformationResolver(props, context || {});
            }

            return <PgProviderList {...context} />;
          },
        },
      ],
    },
  ];
};
