import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { GlobalErrorDialogResponse } from 'GlobalErrorDialog-Types';
import { fetchGlobalErrorDialogAsync } from './actions';

export const response = createReducer({ status: 0 } as GlobalErrorDialogResponse).handleAction(
  fetchGlobalErrorDialogAsync.success,
  (_state, action) => action.payload,
);

const globalErrorDialogReducer = combineReducers({
  response,
});

export default globalErrorDialogReducer;
export type GlobalErrorDialogState = ReturnType<typeof globalErrorDialogReducer>;
