/** This is defined in (V1.4) PRD_InternalTools004_User behavior tracking
 * CAUTION: Don't change the code */
export const dispatchRatEvent = () => {
  if (typeof window !== 'undefined') {
    let event;
    if (typeof window.Event === 'function') {
      event = new Event('SINGLE_PAGE_APPLICATION_LOAD');
      document.dispatchEvent(event);
    } else {
      try {
        event = document.createEvent('Event');
        event.initEvent('SINGLE_PAGE_APPLICATION_LOAD', true, true);
        document.dispatchEvent(event);
      } catch (e) {
        console.error(e);
      }
    }
  }
};

export default dispatchRatEvent;
