import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import {
  Inventory,
  ItemDetail,
  ApiStatus,
  MasterGroupList,
  BulkDetails,
  CreateFeatureGroup,
} from 'Inventory-Types';
import { pushLocation } from 'store/__router/actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
// use this for normalize params
import { isEmptyObject, toDecodedQuery, toQuery } from '@travel/utils';

import {
  ApiFetchInventoryList,
  ApiFetchItemDetail,
  ApiSubmitNewItem,
  ApiUpdateItem,
  ApiDeleteItem,
  ApiFetchMasterGroup,
  ApiBulkUpdateFeatures,
  ApiCreateFeatureGroup,
} from './apis';

export const fetchMasterGroupAsync = createAsyncAction(
  'FETCH_MASTER_GROUP_REQUEST',
  'FETCH_MASTER_GROUP_SUCCESS',
  'FETCH_MASTER_GROUP_FAILURE',
)<undefined, MasterGroupList, ApiStatus[]>();

export const bulkUpdateFeaturesAsync = createAsyncAction(
  'BULK_UPDATE_FEATURES_REQUEST',
  'BULK_UPDATE_FEATURES_SUCCESS',
  'BULK_UPDATE_FEATURES_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const createFeatureGroupAsync = createAsyncAction(
  'CREATE_FEATURE_GROUP_REQUEST',
  'CREATE_FEATURE_GROUP_SUCCESS',
  'CREATE_FEATURE_GROUP_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const fetchInventoryListAsync = createAsyncAction(
  'LOAD_INVENTORY_LIST_REQUEST',
  'LOAD_INVENTORY_LIST_SUCCESS',
  'LOAD_INVENTORY_LIST_FAILURE',
)<undefined, Inventory, ApiStatus[]>();

export const fetchItemDetailAsync = createAsyncAction(
  'LOAD_ITEM_DETAIL_REQUEST',
  'LOAD_ITEM_DETAIL_SUCCESS',
  'LOAD_ITEM_DETAIL_FAILURE',
)<undefined, ItemDetail, ApiStatus[]>();

export const submitNewItemAsync = createAsyncAction(
  'SUBMIT_NEW_ITEM_REQUEST',
  'SUBMIT_NEW_ITEM_SUCCESS',
  'SUBMIT_NEW_ITEM_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const updateItemAsync = createAsyncAction(
  'UPDATE_ITEM_REQUEST',
  'UPDATE_ITEM_SUCCESS',
  'UPDATE_ITEM_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const deleteItemAsync = createAsyncAction(
  'DELETE_ITEM_REQUEST',
  'DELETE_ITEM_SUCCESS',
  'DELETE_ITEM_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const setProviderID = createStandardAction('SET_PROVIDER_ID')<string>();
export const setInitialItemDetail = createStandardAction('SET_INITIAL_ITEM_DETAIL')();

export const fetchMasterGroup = (providerId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMasterGroupAsync.request());

  try {
    const res = await ApiFetchMasterGroup(apiClient, providerId);
    dispatch(fetchMasterGroupAsync.success(res));
  } catch (err) {
    dispatch(fetchMasterGroupAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
  }
};

export const bulkUpdateFeatures = (providerId: string, data: BulkDetails): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(bulkUpdateFeaturesAsync.request());
  try {
    const res = await ApiBulkUpdateFeatures(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(bulkUpdateFeaturesAsync.success(res));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
    dispatch(bulkUpdateFeaturesAsync.failure(err));
    throw err;
  }
};

export const createFeatureGroup = (
  providerId: string,
  data: CreateFeatureGroup,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(createFeatureGroupAsync.request());
  try {
    // create an api
    const res = await ApiCreateFeatureGroup(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(createFeatureGroupAsync.success(res));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
    dispatch(createFeatureGroupAsync.failure(err));
    throw err;
  }
};

export const fetchInventoryList = (
  providerId: string,
  queryParameter: { [key: string]: string | string[] },
  offset?: number,
  limit?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchInventoryListAsync.request());
  const fetchQuery = {
    ...transformToQueryObject(queryParameter),
    offset: offset || 0,
    limit: limit || 30,
  };
  try {
    const res = await ApiFetchInventoryList(apiClient, providerId, fetchQuery);
    dispatch(fetchInventoryListAsync.success(res));
  } catch (err) {
    dispatch(fetchInventoryListAsync.failure(err));
  }
};

export const fetchItemDetail = (providerId: string, itemId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchItemDetailAsync.request());
  const res = await ApiFetchItemDetail(apiClient, providerId, itemId);
  dispatch(fetchItemDetailAsync.success(res));
};

export const submitNewItem = (providerId: string, data: ItemDetail): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(submitNewItemAsync.request());
  try {
    const res = await ApiSubmitNewItem(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(submitNewItemAsync.success(res));

    if (res.id) {
      dispatch(pushLocation(`/provider/${providerId}/items/${res.id}`));
    }
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
    dispatch(submitNewItemAsync.success(err));
  }
};

export const updateItem = (
  providerId: string,
  data: ItemDetail,
  search?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateItemAsync.request());
  try {
    const res = await ApiUpdateItem(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(updateItemAsync.success(res));

    if (res.status === 200) {
      dispatch(
        pushLocation({
          pathname: `/provider/${providerId}/items/${data.id}`,
          state: {
            prevSearch: search,
          },
        }),
      );
    }
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.failure(err));
    dispatch(updateItemAsync.success(err));
  }
};

export const deleteItem = (
  providerId: string,
  itemId: string,
  itemName: string,
): AppThunk<Promise<ApiStatus | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteItemAsync.request());
  try {
    const res = await ApiDeleteItem(apiClient, providerId, itemId);

    // Pass the deleted object name as message
    res.message = itemName;

    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteItemAsync.success(res));
    return res;
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
    dispatch(deleteItemAsync.failure(err));
  }
};

function transformToQueryObject(params: { [key: string]: string | string[] }) {
  if (!params || isEmptyObject(params)) return toQuery({});
  const { searchText, keyword, updateDateTime, createDateTime, searchCondition, ...rest } = params;

  const builtQuery = {
    filter: {
      key: searchText || '',
      ...rest,
    },
    sort: {
      updateDateTime,
      createDateTime,
    },
    keyword: encodeURIComponent(keyword as string),
    searchCondition,
  };

  return toDecodedQuery(builtQuery);
}
