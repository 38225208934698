import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import {
  UserSegmentQueryParams,
  ApiStatus,
  UserSegment,
  UploadResult,
  UserSegmentErrors,
  SegmentIds,
  SelectedUserSegment,
} from 'UserSegment-Types';
import {
  ApiFetchUserSegments,
  ApiFetchSelectedUserSegments,
  ApiCreateUserSegment,
  ApiUpdateUserSegment,
  ApiDownloadUserSegment,
  // ApiDeleteUserSegment,
} from './apis';
import { toDecodedQuery, isNotEmptyArray } from '@travel/utils';
import { getTranslation } from '@travel/translation';

export const fetchUserSegmentAsync = createAsyncAction(
  'FETCH_USER_SEGMENT_REQUEST',
  'FETCH_USER_SEGMENT_SUCCESS',
  'FETCH_USER_SEGMENT_FAILURE',
)<undefined, UserSegment, UserSegmentErrors[]>();

export const fetchSelectedUserSegmentAsync = createAsyncAction(
  'FETCH_SELECTED_USER_SEGMENT_REQUEST',
  'FETCH_SELECTED_USER_SEGMENT_SUCCESS',
  'FETCH_SELECTED_USER_SEGMENT_FAILURE',
)<undefined, SelectedUserSegment[], UserSegmentErrors[]>();

export const createUserSegmentAsync = createAsyncAction(
  'CREATE_USER_SEGMENT_REQUEST',
  'CREATE_USER_SEGMENT_SUCCESS',
  'CREATE_USER_SEGMENT_FAILURE',
)<undefined, UploadResult, ApiStatus[]>();

export const updateUserSegmentAsync = createAsyncAction(
  'UPDATE_USER_SEGMENT_REQUEST',
  'UPDATE_USER_SEGMENT_SUCCESS',
  'UPDATE_USER_SEGMENT_FAILURE',
)<undefined, UploadResult, ApiStatus[]>();

export const downloadUserSegmentCsvAsync = createAsyncAction(
  'DOWNLOAD_USER_SEGMENT_REQUEST',
  'DOWNLOAD_USER_SEGMENT_SUCCESS',
  'DOWNLOAD_USER_SEGMENT_FAILURE',
)<undefined, Blob, UserSegmentErrors[]>();

export const deleteUserSegmentAsync = createAsyncAction(
  'DELETE_USER_SEGMENT_REQUEST',
  'DELETE_USER_SEGMENT_SUCCESS',
  'DELETE_USER_SEGMENT_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

// -- User Segment list
export const fetchUserSegments = (queryParameter: UserSegmentQueryParams): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchUserSegmentAsync.request());
  try {
    const res = await ApiFetchUserSegments(apiClient, toDecodedQuery(queryParameter));

    dispatch(fetchUserSegmentAsync.success(res));
  } catch (error) {
    dispatch(fetchUserSegmentAsync.failure(error));
  }
};

export const fetchSelectedUserSegment = (userSegment: SegmentIds): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  if (isNotEmptyArray(userSegment.segmentIds)) {
    dispatch(fetchSelectedUserSegmentAsync.request());
    try {
      const res = await ApiFetchSelectedUserSegments(apiClient, userSegment);

      dispatch(fetchSelectedUserSegmentAsync.success(res));
    } catch (error) {
      dispatch(fetchSelectedUserSegmentAsync.failure(error as UserSegmentErrors[]));
    }
  }
};

//-- Create
export const createUserSegment = (
  file: File,
  name: string,
): AppThunk<Promise<UploadResult | undefined>> => async (dispatch, getState, { apiClient }) => {
  const formData = new FormData();
  formData.append('csvFile', file, file.name);
  formData.append('userSegmentName', name);

  dispatch(createUserSegmentAsync.request());
  try {
    const res = await ApiCreateUserSegment(apiClient, formData, name);

    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(createUserSegmentAsync.success(res));
    return res;
  } catch (error) {
    dispatch(createUserSegmentAsync.failure(error));
    dispatch(
      fetchGlobalErrorDialogAsync.success({
        ...(error as ApiStatus),
        errorDialogTitle: getTranslation({ id: 'User_Segment_Management.Upload.Heading' }).join(''),
        errorTitle: getTranslation({ id: 'Extranet_Error.System_Logic.Upload_Failed' }).join(''),
      }),
    );
  }
};

// -- Update
export const updateUserSegment = (
  file: File,
  id: string,
): AppThunk<Promise<UploadResult | undefined>> => async (dispatch, getState, { apiClient }) => {
  dispatch(updateUserSegmentAsync.request());

  const formData = new FormData();
  formData.append('csvFile', file, file.name);
  formData.append('userSegmentId', id);

  try {
    const res = await ApiUpdateUserSegment(apiClient, formData, id);

    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(updateUserSegmentAsync.success(res));
    return res;
  } catch (error) {
    dispatch(updateUserSegmentAsync.failure(error));
    dispatch(
      fetchGlobalErrorDialogAsync.success({
        ...(error as ApiStatus),
        errorDialogTitle: getTranslation({ id: 'User_Segment_Management.Upload.Heading' }).join(''),
        errorTitle: getTranslation({ id: 'Extranet_Error.System_Logic.Upload_Failed' }).join(''),
      }),
    );
  }
};

// -- Download/Export
export const downloadUserSegmentCsv = (id: string): AppThunk<Promise<Blob | any>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(downloadUserSegmentCsvAsync.request());
  try {
    const res = await ApiDownloadUserSegment(apiClient, id);
    dispatch(downloadUserSegmentCsvAsync.success(res));
    return res;
  } catch (error) {
    dispatch(downloadUserSegmentCsvAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

// -- Delete
// export const deleteUserSegment = (id: string): AppThunk => async (
//   dispatch,
//   getState,
//   { apiClient },
// ) => {
//   dispatch(deleteUserSegmentAsync.request());
//   try {
//     const res = await ApiDeleteUserSegment(apiClient, id);
//     dispatch(deleteUserSegmentAsync.success(res));
//   } catch (error) {
//     dispatch(fetchGlobalErrorDialogAsync.success({ ...error, isDelete: true }));
//     dispatch(deleteUserSegmentAsync.failure(error));
//   }
// };
