import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  RateControlRates,
  RateControlErrors,
  FilterType,
  ChildRateBulkUpdateParams,
  ApiStatus,
  AllRate,
} from 'RateControl-Types';
import {
  fetch,
  saveRateControlCalendar,
  ApiUpdateChildRateInBulk,
  ApiDeleteExtraRate,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';

export const setItem = createStandardAction('SET_ITEM')<RateControlRates>();

export const fetchRateControlAsync = createAsyncAction(
  'FETCH_RATE_CONTROL_REQUEST',
  'FETCH_RATE_CONTROL_SUCCESS',
  'FETCH_RATE_CONTROL_FAILURE',
)<undefined, RateControlRates, RateControlErrors[]>();

export const fetchBulkSettingsTargetAsync = createAsyncAction(
  'FETCH_BULK_SETTINGS_TARGET_REQUEST',
  'FETCH_BULK_SETTINGS_TARGET_SUCCESS',
  'FETCH_BULK_SETTINGS_TARGET_FAILURE',
)<undefined, AllRate[], RateControlErrors[]>();

export const saveRateControlAsync = createAsyncAction(
  'SAVE_INVENTORY_CONTROL_REQUEST',
  'SAVE_INVENTORY_CONTROL_SUCCESS',
  'SAVE_INVENTORY_CONTROL_FAILURE',
)<undefined, ApiStatus, RateControlErrors[]>();

const updateChildRateInBulkAsync = createAsyncAction(
  'UPDATE_CHILD_RATE_IN_BULK_REQUEST',
  'UPDATE_CHILD_RATE_IN_BULK_SUCCESS',
  'UPDATE_CHILD_RATE_IN_BULK_FAILURE',
)<undefined, ApiStatus, RateControlErrors[]>();

const deleteExtraRateAsync = createAsyncAction(
  'DELETE_EXTRA_RATE_REQUEST',
  'DELETE_EXTRA_RATE_SUCCESS',
  'DELETE_EXTRA_RATE_FAILURE',
)<undefined, ApiStatus, RateControlErrors[]>();

export const setFilterParams = createStandardAction('SET_FILTER_PARAMS')<FilterType>();

export const setBulkSettingsTargetStandard = createStandardAction('SET_BULK_SETTINGS_TARGET')<
  AllRate[]
>();

export const clearBulkSettingsTarget = createStandardAction('CLEAR_BULK_SETTINGS_TARGET')();

export const fetchBulkSettingsTarget = (
  providerId: string,
  ratePlanId: string,
  params: FilterType,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchBulkSettingsTargetAsync.request());
  const rates = await fetch(apiClient, providerId, ratePlanId, params);
  dispatch(fetchBulkSettingsTargetAsync.success(rates.allRates));
};

export const setBulkSettingsTarget = (selectedRates: AllRate[]): AppThunk<void> => dispatch => {
  dispatch(setBulkSettingsTargetStandard(selectedRates));
};

export const fetchRateControl = (
  providerId: string,
  ratePlanId: string,
  params: FilterType,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchRateControlAsync.request());
  const rates = await fetch(apiClient, providerId, ratePlanId, params);
  dispatch(fetchRateControlAsync.success(rates));
  dispatch(setFilterParams(params));
};

export const setCalendarDataStandard = createStandardAction('SET_SELECTED_RATES')<
  RateControlRates
>();

export const setCalendarData = (selectedRates: RateControlRates): AppThunk<void> => dispatch => {
  dispatch(setCalendarDataStandard(selectedRates));
};

export const saveRateControl = (
  providerId: string,
  ratePlanId: string,
  calendarData: RateControlRates,
  redirectTo?: { pathname: string; search: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { filterParams } = getState().rateControl;

  dispatch(saveRateControlAsync.request());
  try {
    const response = await saveRateControlCalendar(apiClient, providerId, ratePlanId, calendarData);

    dispatch(fetchRateControl(providerId, ratePlanId, filterParams));
    dispatch(saveRateControlAsync.success(response));
    dispatch(fetchGlobalErrorDialogAsync.success(response));

    if (redirectTo) {
      dispatch(pushLocation(redirectTo));
    }
  } catch (err) {
    dispatch(saveRateControlAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const updateChildRateInBulk = (
  providerId: string,
  ratePlanId: string,
  bulkParams: ChildRateBulkUpdateParams,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateChildRateInBulkAsync.request());
  try {
    const res = await ApiUpdateChildRateInBulk(apiClient, providerId, ratePlanId, bulkParams);
    dispatch(updateChildRateInBulkAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
  } catch (err) {
    dispatch(updateChildRateInBulkAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const deleteExtraRate = (
  providerId: string,
  ratePlanId: string,
  rateId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteExtraRateAsync.request());
  try {
    const res = await ApiDeleteExtraRate(apiClient, providerId, ratePlanId, rateId);
    dispatch(deleteExtraRateAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
  } catch (err) {
    dispatch(deleteExtraRateAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};
