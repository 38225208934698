import React from 'react';

import { useTranslate, Translate } from '@travel/translation';
import { isNotEmptyArray } from '@travel/utils';

import { CancelPolicyContent } from 'CancelPolicy-Types';
import { CancelPolicy, FilterGroup } from 'RatePlan-Types';

import { getToday } from 'utils/calendar';

export const initialFilterValue = [
  {
    label: <Translate id="Rate_plan_management.List.filter.status" />,
    id: 'status',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Status.Sales.Active" />,
        value: 'true',
        isCheck: true,
      },
      {
        label: <Translate id="Supplier_Enumeration.Status.Sales.Inactive" />,
        value: 'false',
        isCheck: true,
      },
    ],
  },
] as Array<FilterGroup>;

export const initialSortValue = {
  options: [
    {
      text: useTranslate({ id: 'Supplier_Enumeration.Sort.Creation_Date.New_Old' }),
      value: 'createDateTime=desc',
    },
    {
      text: useTranslate({ id: 'Supplier_Enumeration.Sort.Creation_Date.Old_New' }),
      value: 'createDateTime=asc',
    },
    {
      text: useTranslate({ id: 'Supplier_Enumeration.Sort.Updated_Date.New_Old' }),
      value: 'updateDateTime=desc',
    },
    {
      text: useTranslate({ id: 'Supplier_Enumeration.Sort.Updated_Date.Old_New' }),
      value: 'updateDateTime=asc',
    },
  ],
  selectedValue: 'createDateTime=desc',
};

export const ToolBarOptions = {
  searchText: '',
  filter: [
    {
      label: <Translate id="Rate_plan_management.List.filter.status" />,
      id: 'status',
      options: [
        {
          label: 'Supplier_Enumeration.Status.Sales.Active',
          value: 'true',
          isCheck: true,
        },
        {
          label: 'Supplier_Enumeration.Status.Sales.Inactive',
          value: 'false',
          isCheck: true,
        },
      ],
    },
    /** Disabled in TEQB-4855
    {
      label: 'Revenue Type',
      id: 'revenueType',
      options: [
        {
          label: 'Supplier_Enumeration.Revenue_Type.Gross',
          value: 'GROSS',
          isCheck: true,
        },
        {
          label: 'Supplier_Enumeration.Revenue_Type.Net',
          value: 'NET',
          isCheck: true,
        },
      ],
    },
    */
    /** Option not used
    {
      label: 'Rate Plan Benefit',
      id: 'benefits',
      options: [
        {
          label: 'Breakfast',
          value: 'BREAKFAST',
          isCheck: true,
        },
        {
          label: 'Dinner',
          value: 'DINNER',
          isCheck: true,
        },
        {
          label: 'Early Check-In',
          value: 'EARLY_CHECK_IN',
          isCheck: true,
        },
        {
          label: 'Late Check-Out',
          value: 'LATE_CHECK_OUT',
          isCheck: true,
        },
      ],
    },
    */
    {
      label: <Translate id="Rate_plan_management.List.filter.publish_designation" />,
      id: 'designation',
      options: [
        {
          label: 'Supplier_Enumeration.Designation.Standard',
          value: 'STANDARD',
          isCheck: true,
        },
        {
          label: 'Supplier_Enumeration.Designation.Luxury',
          value: 'LUXURY',
          isCheck: true,
        },
      ],
    },
    {
      label: <Translate id="Rate_plan_management.List.Filter.Booking_Acceptance_Status" />,
      id: 'bookingAcceptanceStatus',
      options: [
        {
          label: 'Supplier_Enumeration.Status.Booking_Acceptance.Before',
          value: 'BEFORE_PUBLICATION',
          isCheck: true,
        },
        {
          label: 'Supplier_Enumeration.Status.Booking_Acceptance.Within',
          value: 'PUBLISHING',
          isCheck: true,
        },
        {
          label: 'Supplier_Enumeration.Status.Booking_Acceptance.After',
          value: 'FINISHED_PUBLICATION',
          isCheck: true,
        },
      ],
    },
  ] as Array<FilterGroup>,
  sort: {
    options: [
      {
        text: <Translate id="Supplier_Enumeration.Sort.Creation_Date.New_Old" />,
        value: 'createDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Creation_Date.Old_New" />,
        value: 'createDateTime=asc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.New_Old" />,
        value: 'updateDateTime=desc',
      },
      {
        text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.Old_New" />,
        value: 'updateDateTime=asc',
      },
    ],
    selectedValue: 'createDateTime=desc',
  },
};

export const filterIdList = [
  'status',
  'revenueType',
  'benefits',
  'designation',
  'bookingAcceptanceStatus',
];

export type ENUM = { [fieldID: string]: string };

/** Used in BenefitInformationSection but component is not used */
export const BENEFITS: ENUM = {
  BREAKFAST: 'TLFX: Breakfast',
  DINNER: 'TLFX: Dinner',
  EARLY_CHECK_IN: 'TLFX: Early Check In',
  LATE_CHECK_OUT: 'TLFX: Late Check Out',
};

/** Not used
export const PAYMENT_METHODS = {
  ON_SITE: 'On Site',
  COUPON: 'Coupun',
  CARD: 'Card',
  POINTS: 'Points',
  CVS: 'CVS',
};

export const PROVIDER_TYPES: ENUM = {
  HOTEL: 'Hotel',
  PENSION: 'Pension',
  HOSTEL: 'Hostel',
  APARTMENT: 'Apartment',
};
*/

export const PAYMENT_TYPES: ENUM = {
  ON_SITE_ECO: 'Supplier_Enumeration.Rate_Plan.Payment_Method.Pay_On_Site.Type.Combination',
  ON_SITE_ONLY: 'Supplier_Enumeration.Rate_Plan.Payment_Method.Pay_On_Site.Type.Pay_On_Site_Only',
  AGENT_ECO: 'Supplier_Enumeration.Rate_Plan.Payment_Method.Agent_Payment.Type.Combination',
  AGENT_ONLY: 'Supplier_Enumeration.Rate_Plan.Payment_Method.Agent_Payment.Type.Agent_Payment_Only',
  PREPAID: 'Supplier_Enumeration.Rate_Plan.Payment_Method.Type.Prepaid',
};

export const RATE_UNIT = {
  PER_ITEM: 'PER_ITEM',
  PER_PERSON: 'PER_PERSON',
} as const;

export const UNLIMITED = -9999;

export const DEFAULT_EFFECTIVE_PERIODS = [
  {
    fromDate: getToday(),
    toDate: '2099-12-31',
  },
];

export const FALLBACK_CP_TO_DATE = '9999-12-31';
export const FALLBACK_CP_EFFECTIVE_PERIODS = [
  {
    fromDate: getToday(),
    toDate: FALLBACK_CP_TO_DATE,
  },
];

const createFallbackCp = ({ id, name }: CancelPolicyContent) => {
  return { id, name, effectivePeriods: FALLBACK_CP_EFFECTIVE_PERIODS };
};

export const arrangeFallbackCpToTop = (
  cancelPolicies: CancelPolicy[],
  mostLaxPolicy: CancelPolicyContent,
): CancelPolicy[] => {
  /** In case of rate plan creation */
  if (!isNotEmptyArray(cancelPolicies)) {
    return [createFallbackCp(mostLaxPolicy)];
  }

  /** In case of rate plan modification */
  const fallbackCp = cancelPolicies.find(cp =>
    cp.effectivePeriods?.find(period => period.toDate === FALLBACK_CP_TO_DATE),
  );
  if (fallbackCp) {
    return [fallbackCp, ...cancelPolicies.filter(cp => cp.id !== fallbackCp.id)];
  } else {
    return [
      createFallbackCp(mostLaxPolicy),
      ...cancelPolicies.filter(cp => cp.id !== mostLaxPolicy.id),
    ];
  }
};

export const MAX_ITEMS = 800;
