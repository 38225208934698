import { get, isEmptyObject } from '@travel/utils';

import { SelectBoxOption as Option } from '@travel/ui';

export type Sort = {
  options: Array<Option>;
  selectedValue: string | number;
};

function setSort(query: { [key: string]: string | number } | undefined, sortData: Sort) {
  if (!query || isEmptyObject(query)) return sortData;

  const sortValue = sortData.options.find(option => {
    const optionData = option.value.toString().split('=');
    const sortValue = get(query, optionData[0], null);
    return sortValue === optionData[1];
  });
  const selectedValue = sortValue ? sortValue.value : '';

  return {
    options: sortData.options,
    selectedValue,
  };
}

export default setSort;
