import { universalRouterProps } from 'core/universalRouter/types';
import { fetchPlaceCategories } from 'store/place/actions';
import { fetchObjectScoreList, clearObjectScoreList } from 'store/objectScore/actions';
import { isEmptyObject } from '@travel/utils';

export type Context = {
  params: { providerId: string };
  query: { [key: string]: string };
};

export default ({ store: { dispatch } }: universalRouterProps, context: Context) => {
  const { query } = context;
  if (isEmptyObject(query)) {
    dispatch(clearObjectScoreList());
    return Promise.all([dispatch(fetchPlaceCategories())]);
  } else {
    const { objectName, ...restQuery } = query;
    return Promise.all([
      dispatch(fetchObjectScoreList(restQuery)),
      dispatch(fetchPlaceCategories()),
    ]);
  }
};
