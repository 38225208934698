import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

function GridBody(props: Props) {
  return (
    <tbody
      data-locator-id="GridBody-c19bc10d-1d91-49fb-8434-a2f7bbceb95d"
      className={props.className}
    >
      {props.children}
    </tbody>
  );
}

export default GridBody;
