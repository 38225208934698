import { ApiClient } from '@travel/api-client';

import { Account, AccountList, AccountQueryParams, ApiStatus } from 'AccessControl-Types';
import { ProviderGroupAccount } from 'ProviderGroup-Types';

export const ApiFetchAccounts = (
  http: ApiClient,
  params: AccountQueryParams,
): Promise<AccountList> =>
  http
    .get('/extranet-account/member-token/accounts', { values: params })
    .catch((data: ApiStatus) => data);

export const ApiFetchAccount = async (http: ApiClient, userUuid: string): Promise<Account> => {
  return http
    .get(`/extranet-account/member-token/account/${userUuid}`)
    .then((data: Account) => data);
};

export const ApiFetchLoggedInUser = async (http: ApiClient): Promise<Account> => {
  return http.get(`/extranet-account/member-token/account`).then((data: Account) => data);
};

export const ApiCreateAccount = (http: ApiClient, data: Account): Promise<ApiStatus> =>
  http
    .post('/extranet-account/member-token/createAccount', { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiUpdateAccount = (
  http: ApiClient,
  data: Account,
  userUuid: string,
): Promise<Account | ApiStatus> =>
  http
    .patch(`/extranet-account/member-token/account/${userUuid}`, { values: data })
    .then((data: Account) => data)
    .catch((data: ApiStatus) => data);

export const ApiDeleteAccount = (http: ApiClient, userUuid: string): Promise<ApiStatus> =>
  http
    .delete(`/extranet-account/member-token/account/${userUuid}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiFetchGroupAccount = (
  http: ApiClient,
  providerGroupId: string,
): Promise<AccountList> =>
  http
    .get(`/extranet-account/member-token/group/${providerGroupId}/accounts`)
    .then((data: AccountList) => data);

export const ApiUpdateGroupAccount = (
  http: ApiClient,
  groupId: string,
  data: Account,
): Promise<Account | ApiStatus> =>
  http
    .patch(`/extranet-account/member-token/group/${groupId}/account`, { values: data })
    .then((data: Account) => data)
    .catch((data: ApiStatus) => data);

export const ApiCreateGroupAccount = (
  http: ApiClient,
  providerGroupId: string,
  data: ProviderGroupAccount,
): Promise<ApiStatus> =>
  http
    .post(`/extranet-account/member-token/group/${providerGroupId}/createAccount`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiDeleteGroupAccount = (http: ApiClient, groupId: string): Promise<ApiStatus> =>
  http
    .delete(`/extranet-account/member-token/group/${groupId}/account`, {})
    .then((data: ApiStatus) => data);
