import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { setDictionary, setMultiLangDictionary } from './actions';
import { Dictionary, MultiLangDictionary } from '@travel/translation';

export const dictionary = createReducer({} as Dictionary).handleAction(
  setDictionary,
  (state, action) => action.payload,
);

export const multiLangDictionary = createReducer({} as MultiLangDictionary).handleAction(
  setMultiLangDictionary,
  (state, action) => action.payload,
);

const labelReducer = combineReducers({
  dictionary,
  multiLangDictionary,
});

export default labelReducer;
export type LabelState = ReturnType<typeof labelReducer>;
