import { ApiClient } from '@travel/api-client';

import { providerGroupPrefix } from 'constants/api-prefix';
import {
  ApiStatus,
  ProviderGroup,
  ProviderGroupList,
  ProviderGroupRequest,
  ProviderList,
  QueryParameter,
} from 'ProviderGroup-Types';

/** From Internal */
export const ApiFetchProviderGroupList = (
  http: ApiClient,
  params: QueryParameter,
): Promise<ProviderGroupList> => http.get(`${providerGroupPrefix}/search`, { values: params });

export const ApiFetchProviderGroup = (
  http: ApiClient,
  params: QueryParameter,
  providerGroupId: string,
): Promise<ProviderGroup> =>
  http.get(`${providerGroupPrefix}/${providerGroupId}`, { values: params });

export const ApiUpdateProviderGroup = (
  http: ApiClient,
  data: ProviderGroupRequest,
): Promise<ApiStatus> => http.put(`${providerGroupPrefix}/${data.groupId}`, { values: data });

export const ApiCreateProviderGroup = (
  http: ApiClient,
  data: ProviderGroupRequest,
): Promise<ProviderGroup> => http.post(`${providerGroupPrefix}`, { values: data });

export const ApiDeleteProviderGroup = (
  http: ApiClient,
  providerGroupId: string,
  accountId: string,
): Promise<ApiStatus> =>
  http
    .delete(`${providerGroupPrefix}/${providerGroupId}?accountId=${accountId}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

/** From Provider Group Extranet */
export const ApiFetchProviderGroupBasicInfo = (
  http: ApiClient,
  providerGroupId: string,
): Promise<ProviderGroup> => http.get(`${providerGroupPrefix}/${providerGroupId}/baseInfo`);

export const ApiFetchProviderGroupProviders = (
  http: ApiClient,
  params: QueryParameter,
  providerGroupId: string,
): Promise<ProviderList> =>
  http.get(`${providerGroupPrefix}/${providerGroupId}/searchProviders`, { values: params });
