import { universalRouterProps } from 'core/universalRouter/types';
import { fetchRatePlanList } from 'store/ratePlan/actions';
import { isEmptyObject } from '@travel/utils';
import { getInitialQuery, isFilteringAvailable } from 'utils/designationHelpers';

export type URLParams = { params: { providerId: string }; query: { [key: string]: string } };

export default (
  { store: { dispatch, getState } }: universalRouterProps,
  { params: { providerId }, query }: URLParams,
) => {
  const providerDesignation =
    getState().providersInformation?.providerBasicInfo?.designations || [];
  const serviceTypesMasterData = getState().serviceTypes?.serviceTypes || [];
  const userScope = getState().accessControl?.loggedInUser?.scope;
  const designations = getInitialQuery(
    providerDesignation,
    serviceTypesMasterData,
    userScope,
    isFilteringAvailable,
  );
  const defaultQuery = {
    ...(isEmptyObject(query) && designations),
    ...query,
  };
  return Promise.all([dispatch(fetchRatePlanList(providerId, defaultQuery))]);
};
