import { ApiStatus, Folder, FolderList, Query } from 'Folder-Types';
import { providerPrefix } from 'constants/api-prefix';
import { ApiClient } from '@travel/api-client';

export const ApiFetchFolderList = (
  http: ApiClient,
  providerId: string,
  params?: Query,
): Promise<FolderList> => {
  const url = `${providerPrefix}/${providerId}/folders`;
  return http.get(url, { values: params }).then((data: FolderList) => data);
};

export const ApiPostFolder = (
  http: ApiClient,
  providerId: string,
  name: string,
): Promise<Folder> => {
  const url = `${providerPrefix}/${providerId}/folders`;
  return http.post(url, { values: { name } }).then((data: Folder) => data);
};

export const ApiPutFolder = (
  http: ApiClient,
  providerId: string,
  folderId: string,
  name: string,
): Promise<Folder> => {
  const url = `${providerPrefix}/${providerId}/folders/${folderId}`;
  return http.put(url, { values: { name } }).then((data: Folder) => data);
};

export const ApiDeleteFolder = (
  http: ApiClient,
  providerId: string,
  folderId: string,
): Promise<ApiStatus> => {
  const url = `${providerPrefix}/${providerId}/folders/${folderId}`;
  return http.delete(url, {}).then((data: ApiStatus) => data);
};
