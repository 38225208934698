import React, { useState, ReactNode, useRef, useEffect } from 'react';
import { isNotEmptyArray, cx } from '@travel/utils';
import { Sort, DropdownArrow } from '@travel/icons/ui';
import { BallonDropdown } from '@travel/ui';

import styles from './sortDropDown.module.scss';

export type Option = {
  text: ReactNode;
  value: string;
};

type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** Set description per props */
  title?: ReactNode;

  /** Selected Sort Option index */
  selectedSortOptionIndex: number;

  /** Sort options */
  options?: Array<Option>;

  /** callback to be called after sort option selected */
  onSort: (index: number) => void;
};

function SortDropDown(props: Props) {
  const { className, title, selectedSortOptionIndex, options, onSort } = props;
  const [isOpen, setIsOpen] = useState(false);

  const node: any = useRef();

  useEffect(() => {
    document.addEventListener('click', handleFocusOut);
    return () => {
      document.removeEventListener('click', handleFocusOut);
    };
  }, [selectedSortOptionIndex]);

  const handleFocusOut = (event: MouseEvent) => {
    if (node.current && !node.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const selectedOption = (isNotEmptyArray(options) && options[selectedSortOptionIndex]) || null;

  const handleSortBalloon = () => setIsOpen(!isOpen);

  const handleOnSort = (value: string) => {
    if (isNotEmptyArray(options)) {
      const index = options.findIndex(option => option.value === value);
      onSort(index);
      setIsOpen(false);
    }
  };

  return (
    <div
      data-locator-id="SortDropDown-58eb6247-7ae5-42d6-9a6b-6e75587c2dd8"
      className={cx(className, styles.sortDropDown)}
      ref={node}
    >
      <div
        className={isOpen ? styles.filterBarDimmed : styles.filterBar}
        onClick={handleSortBalloon}
        data-testid="sortDropDown-wrapper"
      >
        <Sort size={24} />
        <span className={styles.title}>{title}</span>
        <span className={styles.selected}>{!!selectedOption ? selectedOption.text : ''}</span>
        <DropdownArrow size={24} />
      </div>
      <div className={styles.balloonWrapper}>
        {isOpen && (
          <BallonDropdown
            options={options}
            onChange={handleOnSort}
            className={styles.sortBalloon}
            defaultValue={!!selectedOption ? selectedOption.value : ''}
          />
        )}
      </div>
    </div>
  );
}

SortDropDown.defaultProps = {
  className: '',
  title: '',
  selectedSortOptionIndex: 0,
  options: [],
  onSort: () => {},
};

export default SortDropDown;
