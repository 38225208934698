import { ChannelDistributor, FetchAllBody, ApiStatus } from 'ChannelDistributor-Types';
import { ApiClient } from '@travel/api-client';

import { QueryParameter } from 'ChannelManager-Types';

export const fetchAll = (http: ApiClient, query: QueryParameter): Promise<FetchAllBody> =>
  http.get('/partner-api/partner/distributors', {
    values: query,
  });

export const createDistributor = (
  http: ApiClient,
  data: ChannelDistributor,
): Promise<ChannelDistributor> =>
  http.post('/partner-api/partner/distributors', {
    values: data,
  });

export const fetchDistributor = (
  http: ApiClient,
  distributorId: string,
): Promise<ChannelDistributor> => http.get(`/partner-api/partner/distributors/${distributorId}`);

export const updateDistributor = (
  http: ApiClient,
  data: ChannelDistributor,
  distributorId: string,
): Promise<ApiStatus> =>
  http.put(`/partner-api/partner/distributors/${distributorId}`, { values: data });
