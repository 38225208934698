import { fetchProviderInformation } from 'store/providersInformation/actions';
import { universalRouterProps } from 'core/universalRouter/types';
import { fetchCertification } from 'store/certification/actions';
import { fetchVisualContentGroupList } from 'store/visualContentGroup/actions';
import { fetchAllFeatureGroups } from 'store/featureSelection/actions';
import { OBJECT_TYPE } from 'constants/feature';
import { getIsCreateAccess } from 'utils/userScopeHelper';
import { fetchCurrencies } from 'store/currencies/actions';
import { fetchPlaceCategories } from 'store/place/actions';

type Location = {
  params: {
    providerId: string;
    type: string;
  };
};

export default ({ store }: universalRouterProps, { params: { providerId } }: Location) => {
  const featureQueryParams = { resourceId: providerId, objectType: OBJECT_TYPE.PROVIDER };
  const { dispatch } = store;
  const { permissions } = store.getState().accessControl?.loggedInUser || { permissions: [] };
  const hasMediaAccess = getIsCreateAccess(permissions, 'MediaMgmt');

  let promises: Array<Promise<void>> = [
    dispatch(fetchProviderInformation(providerId)),
    dispatch(fetchCertification()),
    dispatch(fetchCurrencies()),
    dispatch(fetchAllFeatureGroups(providerId, featureQueryParams)),
    dispatch(fetchPlaceCategories()),
  ];

  // we no longer need since we have introduction and highlight TODO: remove EVEREST-15973
  if (hasMediaAccess) {
    promises.push(dispatch(fetchVisualContentGroupList(providerId, {})));
  }

  return Promise.all(promises);
};
