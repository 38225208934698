import { universalRouterProps } from 'core/universalRouter/types';
import { fetchChannelManager } from '../../store/channelManager/actions';
type Location = {
  params: {
    channelManagementId: string;
    type: string;
  };
};

export default (
  { store: { dispatch } }: universalRouterProps,
  { params: { channelManagementId } }: Location,
) => {
  return Promise.all([dispatch(fetchChannelManager(channelManagementId))]);
};
