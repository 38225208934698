import { fetchProviderRestrictionDetail } from 'store/providerRestrictions/actions';

import { universalRouterProps } from 'core/universalRouter/types';

export default (
  { store: { dispatch } }: universalRouterProps,
  { params }: { params: { providerRestrictionId: string } },
) => {
  const { providerRestrictionId } = params;
  return Promise.all([dispatch(fetchProviderRestrictionDetail(providerRestrictionId))]);
};
