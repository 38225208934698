import React, { useCallback, useEffect, useRef, ReactNode } from 'react';

import { Translate, TranslatePropsForPlainText } from '@travel/translation';
import { SearchBox } from '@travel/ui';
import { Props as SearchCondition } from '@travel/ui/components/RadioButton';
import { cx, isNotEmptyArray } from '@travel/utils';

import HelmetWrapper from 'components/HelmetWrapper';
import CreateButton from '../CreateButton';

import styles from './createPageHeader.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** Page Title */
  title?: string | TranslatePropsForPlainText; // should be plain for Helmet

  /** search text */
  searchText?: string;

  /** Page Title */
  placeHolder?: string;

  /** Flag whether the page should show Create button */
  hasCreateBtn?: boolean;

  createBtnLabel?: ReactNode;
  createBtnClassName?: string;

  /** SearchInput className if needed */
  searchInputClassName?: string;

  /** popupClassName className if needed */
  popupClassName?: string;

  /** Breadcrumbs if needed */
  breadcrumbs?: ReactNode;

  /** Component in header if you need */
  children?: React.ReactNode;
  /** className for the children wrapper */
  childrenClassName?: string;

  isSearchDisplay: boolean;

  /**
   * Allow to search as user type with some debounce
   */
  isAutoSearch?: boolean;
  isAutoFocusDisabled?: boolean;

  isCreateBtnDisabled?: boolean;

  /** Custom label for search condition title */
  searchConditionTitle?: React.ReactNode;

  /** Search conditions of Search box */
  searchConditions?: Array<SearchCondition>;

  /** Default search condtion selected */
  defaultCondition: string;

  /** callback to be called after create button clicked */
  onCreate?: () => void;

  /** Flag to define whether the returned keyword will be encoded or not */
  isEncodeSearchKeyword: boolean;

  /** callback to be called after search input changed.
   *  WARNING: should be wrapperd by useCallback to avoid infiite loop by <SearchBox>	*/
  onSearch?: (keyword: string, condition: string) => void;

  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;

  onClear?: () => void;
  /** Node of additional custom button */
  customButton?: ReactNode;
};

function CreatePageHeader(props: Props) {
  const {
    className,
    title = '',
    searchText = '',
    placeHolder,
    hasCreateBtn,
    createBtnLabel,
    createBtnClassName,
    searchInputClassName = '',
    popupClassName = '',
    breadcrumbs,
    children,
    isSearchDisplay,
    searchConditionTitle,
    searchConditions,
    isAutoSearch,
    isAutoFocusDisabled,
    isEncodeSearchKeyword,
    isCreateBtnDisabled = false,
    defaultCondition,
    customButton,
    onCreate,
    onSearch,
    onSubmit,
    onClear,
    childrenClassName,
  } = props;
  const titleComponent = typeof title === 'string' ? title : <Translate {...title} />;

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit?.(event);
  };

  const headerRef = useRef<HTMLDivElement>(null); // to focus search field everytime.
  const SEARCH_FIELD_ID = 'create-page-header-search-field';

  /** focus search field */
  useEffect(() => {
    const ref = headerRef?.current;
    if (ref && !isAutoFocusDisabled) {
      const searchField: HTMLInputElement | null = ref.querySelector('#' + SEARCH_FIELD_ID);
      searchField && searchField.focus();
    }
  }, [isAutoFocusDisabled]);

  const customOnSearch = useCallback(
    (keyword: string, condition: string) => {
      if (isEncodeSearchKeyword) {
        onSearch?.(encodeURIComponent(keyword), condition);
      } else {
        onSearch?.(keyword, condition);
      }
    },
    [onSearch, isEncodeSearchKeyword],
  );

  return (
    <div className={cx(className, styles.createPageHeader)} ref={headerRef}>
      <HelmetWrapper title={title} />
      <div className={styles.main}>
        <section className={styles.searchSection}>
          {breadcrumbs && <div className={styles.breadcrumbs}>{breadcrumbs}</div>}
          <div className={styles.pageTitle}>{titleComponent}</div>
          {isSearchDisplay && (
            <form onSubmit={onSubmitHandler}>
              <SearchBox
                popupClassName={popupClassName}
                onClear={onClear}
                onChange={customOnSearch}
                value={searchText}
                className={searchInputClassName ? searchInputClassName : styles.searchInput}
                textBoxPlaceHolder={placeHolder}
                searchConditionTitle={searchConditionTitle}
                searchConditions={searchConditions}
                defaultCondition={
                  defaultCondition ||
                  (isNotEmptyArray(searchConditions) && searchConditions[0].value) ||
                  ''
                }
                isAutoSearch={isAutoSearch}
                id={SEARCH_FIELD_ID}
              />
            </form>
          )}
        </section>
        {customButton}
        {hasCreateBtn && (
          <CreateButton
            className={createBtnClassName}
            onCreate={onCreate}
            isDisabled={isCreateBtnDisabled}
            label={createBtnLabel}
          />
        )}
      </div>
      {children && <div className={cx(styles.children, childrenClassName)}>{children}</div>}
    </div>
  );
}

CreatePageHeader.defaultProps = {
  searchText: '',
  placeHolder: '',
  hasCreateBtn: true,
  children: null,
  isSearchDisplay: true,
  isAutoSearch: true,
  isEncodeSearchKeyword: true,
  searchConditions: [
    {
      label: 'TLFX: Broad Match',
      name: 'broad',
      value: 'BROAD',
    },
    {
      label: 'TLFX: Phrase Match',
      name: 'phrase',
      value: 'PHRASE',
    },
    {
      label: 'TLFX: Exact Match',
      name: 'exact',
      value: 'EXACT',
    },
  ],
  defaultCondition: '',
};

export default CreatePageHeader;
