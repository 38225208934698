import { universalRouterProps } from 'core/universalRouter/types';
import { fetchProviders } from 'store/providers/actions';
import { fetchCertification } from 'store/certification/actions';
import { fetchChannelManagers } from 'store/channelManager/actions';
import { fetchAllChannelDistributors } from 'store/channelDistributor/actions';
import { fetchAllProviderGroups } from 'store/providerGroup/actions';

export default ({ store: { dispatch } }: universalRouterProps, query: { keyword: string }) => {
  return Promise.all([
    dispatch(fetchProviders(query)),
    dispatch(fetchChannelManagers({})),
    dispatch(fetchAllChannelDistributors()),
    dispatch(fetchCertification()),
    dispatch(fetchAllProviderGroups()),
  ]);
};
