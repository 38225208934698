import React, { ChangeEvent, useCallback, useState, ReactNode } from 'react';
import { Block, FlatButton, ListWrapper, ListItem, RadioButtonGroup } from '@travel/ui';
import CreatePageHeader from 'components/CreatePageHeader';
import ToolBar from 'components/ToolBar';
import BalloonSupplier from 'components/BalloonSupplier';
import FilterDropDown from 'components/FilterDropDown';
import SortDropDown from 'components/SortDropDown';
import { L10nDate, SHORT_DATE_SHORT_TIME, getLanguage } from '@travel/i18n';

import setSort from 'hooks/setSort';
import { queryToObject } from '@travel/utils';
import { Translate } from 'core/translate';
import { initialSortValue, STATUS_FILTER_OPTIONS } from 'store/labelManagement/initialValues';
import { useDispatch, useSelector } from 'react-redux';
import { pushLocation } from 'store/__router/actions';
import {
  getLabelManagement,
  getPages,
  getPagesCount,
  getPagesOffset,
} from 'store/labelManagement/selectors';
import { getSortQuery, hasFilteringQuery } from 'utils';
import PERM from 'constants/permissions';
import { fetchPages } from 'store/labelManagement/actions';

import styles from './labelManagementPageList.module.scss';
import ListItemHeader from 'components/ListItemHeader';
import { getTranslation } from '@travel/translation';

export enum Products {
  EXTRNT = 'Extranet',
  TRVLR = 'Traveler',
  SEO = 'SEO',
  TVLRCOM = 'Traveler_Common',
  DP = 'DP',
  MYPAGE = 'MyPage',
  RENTALCAR = 'RentalCar',
  TRAVELAPP = 'TravelApp',
}
export type Props = {
  query: {
    [field: string]: string;
  };
  pathname: string;
  permissionLevel: string;
  product: Products;
  search: string;
};

const PRODUCT_NAME: {
  [key: string]: ReactNode;
} = {
  Traveler: <Translate id="Supplier_Enumeration.Label.Page_Product.Traveler" />,
  Extranet: <Translate id="Supplier_Enumeration.Label.Page_Product.Extranet" />,
  SEO: <Translate id="Supplier_Enumeration.Label.Page_Product.SEO" />,
  Traveler_Common: (
    <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.Traveler_Common_Label_Management" />
  ),
  DP: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.JRDP_Label_Management" />,
  MyPage: <Translate id="Supplier_Menu_Sitemap.Internal_Tool.Tools.MyPage_Label_Management" />,
  RentalCar: <Translate id="Supplier_Enumeration.Label.Page_Product.RentalCar" />,
  TravelApp: <Translate id="Supplier_Enumeration.Label.Page_Product.TravelApp" />,
} as const;

function LabelManagementPageList(props: Props) {
  const { pathname, query = {}, permissionLevel, product, search } = props;
  const dispatch = useDispatch();
  const queryObject = queryToObject(query);

  const language = useSelector(getLanguage);

  const sort = setSort(queryObject, initialSortValue);
  const selectedSortOptionIndex = sort.options.findIndex(
    (option: { value: string | number }) => option.value === sort.selectedValue,
  );
  const [activeStatus, setActiveStatus] = useState<string>(
    queryObject.filter && queryObject.filter.pageStatus ? queryObject.filter.pageStatus : 'active',
  );

  const handleOnCreate = useCallback(() => {
    dispatch(
      pushLocation({
        pathname: `${pathname}/createPage`,
        state: {
          prevSearch: search,
        },
      }),
    );
  }, [dispatch, pathname, search]);

  const { isFetching } = useSelector(getLabelManagement);
  const pages = useSelector(getPages);
  const total = useSelector(getPagesCount);
  const offset = useSelector(getPagesOffset);
  const limit = 30;

  const hasFilter = hasFilteringQuery(query, ['filter.pageStatus']);
  const hasMoreData = total - (offset + limit) > 0;

  const fetchMore = useCallback(() => {
    const newOffset = offset + limit;
    if (hasMoreData && !isFetching) {
      const newQuery = {
        ...query,
        'filter.product': product,
        offset: newOffset,
      };
      dispatch(fetchPages(newQuery));
    }
  }, [dispatch, hasMoreData, isFetching, offset, product, query]);

  const handleOnDetail = useCallback(
    (pageId?: string | number) => {
      dispatch(
        pushLocation({
          pathname: `${pathname}/${pageId}`,
          state: {
            prevSearch: search,
          },
        }),
      );
    },
    [dispatch, pathname, search],
  );

  const handleOnSort = (index: number) => {
    const activeFilters = [
      query.keyword ? `keyword=${query.keyword}` : '',
      initialSortValue.options[index].value,
    ];
    const searchQueryParams = getSortQuery(activeFilters, query, [
      'filter.pageStatus',
      'filter.product',
    ]);
    dispatch(pushLocation(`${pathname}?${searchQueryParams}`));
  };

  const handleOnChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setActiveStatus(event.currentTarget.value);
  };

  const handleOnFilterClear = useCallback(() => {
    setActiveStatus('active');
  }, []);

  const handleOnFilter = useCallback(() => {
    const activeFilters = [sort.selectedValue, `filter.pageStatus=${activeStatus}`];
    const searchQueryParams = getSortQuery(activeFilters, {}, ['filter.pageStatus']);

    dispatch(pushLocation(`${pathname}?${searchQueryParams}`));
  }, [activeStatus, dispatch, pathname, sort.selectedValue]);

  return (
    <div data-testid="LabelManagementPageList-wrapper" className={styles.listRoot}>
      <div className={styles.header}>
        <CreatePageHeader
          data-testid="labelManagementPageList-createPageHeader"
          onCreate={handleOnCreate}
          title={{
            id: 'Page_Label_Management.List.Heading',
            data: {
              product_name: getTranslation({
                id: `Supplier_Menu_Sitemap.Internal_Tool.Tools.${
                  product === 'DP' ? 'JRDP' : product
                }_Label_Management`,
              }).join(''),
            },
          }}
          isSearchDisplay={false}
          hasCreateBtn={
            permissionLevel === PERM.readEditDelete || permissionLevel === PERM.readEdit
          }
        />
        <ToolBar>
          <FilterDropDown
            data-testid="labelManagementPageList-filterDropDown"
            title={<Translate id="Page_Label_Management.List.Filter.Title" />}
          >
            <BalloonSupplier
              className={styles.filterBalloon}
              direction={'topLeft'}
              hasContentMinHeight={false}
            >
              <Block isSolid isNotLast={false} className={styles.block}>
                <h4 className={styles.filterTitle}>
                  <Translate id="Page_Label_Management.List.Filter.Page_Status" />
                </h4>
                <RadioButtonGroup
                  className={styles.radioGroup}
                  options={STATUS_FILTER_OPTIONS}
                  onChange={handleOnChangeFilter}
                  defaultSelectedOption={activeStatus}
                />
              </Block>
              <Block isNotLast={false} isSolid className={styles.buttonsWrapper}>
                <FlatButton
                  className={styles.button}
                  type="reset"
                  onClick={handleOnFilterClear}
                  classType="secondary"
                >
                  <Translate id="Common.List.Filter.Cleat_Filters" />
                </FlatButton>
                <FlatButton
                  className={styles.button}
                  type="submit"
                  onClick={handleOnFilter}
                  classType="primary"
                >
                  <Translate id="Common.List.Filter.Apply" />
                </FlatButton>
              </Block>
            </BalloonSupplier>
          </FilterDropDown>
          <SortDropDown
            data-testid="labelManagementPageList-sortDropDown"
            title={<Translate id="Page_Label_Management.List.Sort" />}
            onSort={handleOnSort}
            options={initialSortValue.options}
            selectedSortOptionIndex={selectedSortOptionIndex}
          />
        </ToolBar>
      </div>
      <ListWrapper
        next={fetchMore}
        hasMore={hasMoreData}
        isFetching={isFetching}
        hasFilter={hasFilter}
        listData={pages}
        hasCheckbox={false}
        total={total}
        resultLabel={
          <Translate
            id="Page_Label_Management.List.List_Count"
            data={{ number_of_label_pages: total }}
          />
        }
      >
        {pages?.map(({ product, pageName, pageId, updateZonedDateTime, status }) => {
          return (
            <ListItem
              data-testid="labelManagementPageList-listItem"
              onDetailClick={handleOnDetail}
              status={
                status ? (
                  <Translate id="Supplier_Enumeration.Status.Activation.Active" />
                ) : (
                  <Translate id="Supplier_Enumeration.Status.Activation.Inactive" />
                )
              }
              listItemId={pageId || ''}
              key={pageId}
              time={
                <L10nDate
                  locale={language}
                  value={updateZonedDateTime || ''}
                  format={SHORT_DATE_SHORT_TIME}
                />
              }
              isUpdatedTime
              detailLabel={<Translate id="Common.List.Detail" />}
              statusColor={status ? 'yellow' : 'gray'}
            >
              <p>
                <ListItemHeader
                  data-testid="labelManagementPageList-listItemHeader"
                  container={'span'}
                  listItemId={pageId as string}
                  onDetailClick={handleOnDetail}
                  className={styles.itemHeader}
                >
                  {pageName}
                </ListItemHeader>{' '}
              </p>
              {product ? PRODUCT_NAME[product] : <p>'-'</p>}
            </ListItem>
          );
        })}
      </ListWrapper>
    </div>
  );
}

LabelManagementPageList.defaultProps = {
  product: Products.EXTRNT,
};

export default LabelManagementPageList;
