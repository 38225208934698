import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';

import { useTranslate } from '@travel/translation';

import { periodTypeToQuery } from 'constants/searchConditions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { fetchProviderInformation, setProviderBasicInfo } from 'store/providersInformation/actions';
import { ApiFetchProviderBasicInfo } from 'store/providersInformation/apis';
import { getUserPlatform, PLATFORM } from 'utils/userScopeHelper';
import { cancelledWithErrorReasonLabelId } from '../../pages/BookingDetail/labels';

import { pushLocation } from 'store/__router/actions';

import {
  APIResponse,
  BookingCancellation,
  BookingDetail,
  BookingErrors,
  BookingList,
  BookingModificationDetail,
  BookingTranslation,
  CancellationCharge,
  CancellationCharges,
  CancelStepTwoData,
  FormData,
  RatePlanError,
  RatePlanList,
  Statistics,
} from 'Booking-Types';
import { BOOKING_LIST_LIMIT, DESIGNATIONS_ENUM } from 'constants/booking';
import {
  ApiBookingCancelStepOne,
  ApiBookingCancelStepTwo,
  ApiBookingDetail,
  ApiConfirmPriceChange,
  ApiConfirmStayDateChange,
  ApiDeleteQueue,
  ApiFetchBookingList,
  ApiFetchBookingModificationDetails,
  ApiFetchRatePlans,
  ApiFetchStatistics,
  ApiRConnectConfirm,
  ApiSaveNote,
  ApiTranslateText,
  ApiUpdateCancellationCharge,
  ApiUpdatePriceChange,
  ApiUpdateStayDateChange,
} from './apis';

export const setInitialBookingList = createStandardAction('SET_INITIAL_BOOKING_LIST')();
export const setInitialModificationDetail = createStandardAction(
  'SET_INITIAL_MODIFICATION_DETAILS',
)();
export const setInitialChangedModificationDetail = createStandardAction(
  'SET_INITIAL_CHANGED_MODIFICATION_DETAILS',
)();

export const updateBookingTranslation = createStandardAction('UPDATE_BOOKING_TRANSLATION')<
  BookingTranslation
>();

export const setIsStatisticsError = createStandardAction('SET_IS_STATISTICS_ERROR')<boolean>();

export const fetchBookingListAsync = createAsyncAction(
  'FETCH_BOOKING_LIST_REQUEST',
  'FETCH_BOOKING_LIST_SUCCESS',
  'FETCH_BOOKING_LIST_FAILURE',
)<undefined, BookingList, BookingErrors[]>();

export const fetchRatePlanListAsync = createAsyncAction(
  'FETCH_RATE_PLAN_LIST_REQUEST',
  'FETCH_RATE_PLAN_LIST_SUCCESS',
  'FETCH_RATE_PLAN_LIST_ERROR',
)<undefined, RatePlanList, RatePlanError[]>();

export const fetchBookingDetailAsync = createAsyncAction(
  'FETCH_BOOKING_DETAIL_REQUEST',
  'FETCH_BOOKING_DETAIL_SUCCESS',
  'FETCH_BOOKING_DETAIL_FAILURE',
)<undefined, BookingDetail, BookingErrors[]>();

export const fetchBookingCancelStepOneAsync = createAsyncAction(
  'FETCH_BOOKING_CANCEL_STEP_ONE_REQUEST',
  'FETCH_BOOKING_CANCEL_STEP_ONE_SUCCESS',
  'FETCH_BOOKING_CANCEL_STEP_ONE_FAILURE',
)<undefined, BookingCancellation, BookingErrors[]>();

export const confirmBookingCancelAsync = createAsyncAction(
  'CONFIRM_BOOKING_CANCEL_REQUEST',
  'CONFIRM_BOOKING_CANCEL_SUCCESS',
  'CONFIRM_BOOKING_CANCEL_FAILURE',
)<undefined, {}, BookingErrors[]>();

export const saveNoteAsync = createAsyncAction(
  'SAVE_NOTE_REQUEST',
  'SAVE_NOTE_SUCCESS',
  'SAVE_NOTE_FAILURE',
)<undefined, {}, BookingErrors[]>();

export const fetchBookingModificationDetailAsync = createAsyncAction(
  'FETCH_BOOKING_MODIFICATION_DETAIL_REQUEST',
  'FETCH_BOOKING_MODIFICATION_DETAIL_SUCCESS',
  'FETCH_BOOKING_MODIFICATION_DETAIL_FAILURE',
)<undefined, BookingModificationDetail, BookingErrors[]>();

export const updateBookingPriceChangeAsync = createAsyncAction(
  'UPDATE_BOOKING_PRICE_CHANGE_REQUEST',
  'UPDATE_BOOKING_PRICE_CHANGE_SUCCESS',
  'UPDATE_BOOKING_PRICE_CHANGE_FAILURE',
)<undefined, APIResponse, BookingErrors[]>();

export const confirmBookingPriceChangeAsync = createAsyncAction(
  'CONFIRM_BOOKING_PRICE_CHANGE_REQUEST',
  'CONFIRM_BOOKING_PRICE_CHANGE_SUCCESS',
  'CONFIRM_BOOKING_PRICE_CHANGE_FAILURE',
)<undefined, BookingModificationDetail, BookingErrors[]>();

export const fetchCancellationChargesAsync = createAsyncAction(
  'FETCH_CANCELLATION_CHARGES_REQUEST',
  'FETCH_CANCELLATION_CHARGES_SUCCESS',
  'FETCH_CANCELLATION_CHARGES_FAILURE',
)<undefined, CancellationCharges, BookingErrors[]>();

export const updateCancellationChargesAsync = createAsyncAction(
  'UPDATE_CANCELLATION_CHARGES_REQUEST',
  'UPDATE_CANCELLATION_CHARGES_SUCCESS',
  'UPDATE_CANCELLATION_CHARGES_FAILURE',
)<undefined, APIResponse, BookingErrors[]>();

export const translateTextAsync = createAsyncAction(
  'TRANSLATE_TEXT_REQUEST',
  'TRANSLATE_TEXT_SUCCESS',
  'TRANSLATE_TEXT_FAILURE',
)<undefined, {}, BookingErrors[]>();

export const fetchStatisticsAsync = createAsyncAction(
  'FETCH_STATISTICS_REQUEST',
  'FETCH_STATISTICS_SUCCESS',
  'FETCH_STATISTICS_FAILURE',
)<undefined, Statistics, BookingErrors[]>();

export const deleteQueueAsync = createAsyncAction(
  'DELETE_QUEUE_REQUEST',
  'DELETE_QUEUE_SUCCESS',
  'DELETE_QUEUE_FAILURE',
)<undefined, {}, BookingErrors[]>();

export const rConnectConfirmAsync = createAsyncAction(
  'RCONNECT_CONFIRM_REQUEST',
  'RCONNECT_CONFIRM_SUCCESS',
  'RCONNECT_CONFIRM_FAILURE',
)<undefined, {}, BookingErrors[]>();

export const fetchBookingList = (
  values: { [key: string]: string },
  providerId?: string,
  offset?: number,
  rejectIfFetching?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isFetching = getState().booking.isFetching;

  if (isFetching && rejectIfFetching) return;

  const formValues = {
    ...values,
    [periodTypeToQuery[values.periodType].fromField]: values.periodFrom,
    [periodTypeToQuery[values.periodType].toField]: values.periodTo,
  };

  delete formValues.periodFrom;
  delete formValues.periodTo;
  delete formValues.periodType;

  const fetchQuery = {
    ...formValues,
    offset: offset || 0,
    limit: BOOKING_LIST_LIMIT,
  };

  dispatch(fetchBookingListAsync.request());

  try {
    const res = await ApiFetchBookingList(apiClient, fetchQuery, providerId);
    dispatch(fetchBookingListAsync.success(res));
  } catch (error) {
    dispatch(fetchBookingListAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

type RatePlanListQuery = {
  filters: string[];
  active?: boolean;
  limit: number;
};

export const fetchRatePlanList = (
  values: RatePlanListQuery,
  offset?: number,
  rejectIfFetching?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isFetching = getState().booking.isRatePlansFetching;

  if (isFetching && rejectIfFetching) return;

  // generate the query
  const fetchQuery = {
    ...values,
    offset: offset || 0,
    limit: 30,
  };

  dispatch(fetchRatePlanListAsync.request());

  try {
    const res = await ApiFetchRatePlans(apiClient, fetchQuery);
    dispatch(fetchRatePlanListAsync.success(res));
  } catch (error) {
    dispatch(fetchRatePlanListAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const fetchBookingDetail = (bookingId: string, providerId?: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchBookingDetailAsync.request());
  const userScope = getState().accessControl.loggedInUser?.scope;
  const platform = getUserPlatform(userScope);
  try {
    const res = await ApiBookingDetail(apiClient, bookingId);

    if (platform === PLATFORM.EXTRANET) {
      const providerBasicInfo = await ApiFetchProviderBasicInfo(apiClient, res.provider.id);
      dispatch(setProviderBasicInfo(providerBasicInfo));
    } else if (res.bookingDetails.designation !== DESIGNATIONS_ENUM.JRDP) {
      dispatch(fetchProviderInformation(res.provider.id));
    }

    dispatch(fetchBookingDetailAsync.success(res));
  } catch (error) {
    dispatch(fetchBookingDetailAsync.failure([error]));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: error.status, message: error.message }));
  }
};

export const fetchBookingCancelStepOne = (
  bookingId: string,
  isNoShow: boolean = false,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchBookingCancelStepOneAsync.request());
  const designation = getState().booking.bookingDetail.bookingDetails.designation;
  const res = await ApiBookingCancelStepOne(apiClient, bookingId, isNoShow, designation);
  dispatch(fetchBookingCancelStepOneAsync.success(res));
};

export const confirmBookingCancel = (
  reservationId: string,
  values: CancelStepTwoData,
  providerId: string,
  newLocation?: { pathname: string; search: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(confirmBookingCancelAsync.request());
  const designation = getState().booking.bookingDetail.bookingDetails.designation;

  try {
    const res = await ApiBookingCancelStepTwo(apiClient, reservationId, values, designation);
    if (res.cancelledWithErrorReason) {
      dispatch(
        fetchGlobalErrorDialogAsync.success({
          status: 502,
          message: useTranslate({
            id: cancelledWithErrorReasonLabelId[res.cancelledWithErrorReason],
          }),
        }),
      );
    } else if (res.success) {
      dispatch(fetchGlobalErrorDialogAsync.success({ status: 200, message: '' }));
    }

    if (newLocation) {
      dispatch(pushLocation(newLocation));
    } else {
      dispatch(fetchBookingDetail(reservationId, providerId));
    }

    dispatch(confirmBookingCancelAsync.success(res));
  } catch (error) {
    dispatch(confirmBookingCancelAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const saveNote = (
  reservationId: string,
  note: string,
  providerId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(saveNoteAsync.request());

  try {
    const res = await ApiSaveNote(apiClient, reservationId, { note });
    dispatch(fetchGlobalErrorDialogAsync.success({ status: res.statusCode, message: res.message }));

    dispatch(fetchBookingDetail(reservationId, providerId));

    dispatch(saveNoteAsync.success(res));
  } catch (error) {
    dispatch(saveNoteAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const fetchBookingModificationDetails = (reservationId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchBookingModificationDetailAsync.request());
  const res = await ApiFetchBookingModificationDetails(apiClient, reservationId);
  dispatch(fetchBookingModificationDetailAsync.success(res));
};

export const updateBookingPriceChange = (
  reservationId: string,
  values: FormData,
  providerId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateBookingPriceChangeAsync.request());
  const designation = getState().booking.bookingDetail.bookingDetails.designation;
  try {
    const res = await ApiUpdatePriceChange(apiClient, reservationId, values, designation);
    dispatch(setInitialModificationDetail());
    dispatch(setInitialChangedModificationDetail());
    dispatch(fetchBookingDetail(reservationId, providerId));
    dispatch(fetchBookingModificationDetails(reservationId));

    dispatch(updateBookingPriceChangeAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: res.statusCode, message: res.message }));
  } catch (error) {
    dispatch(updateBookingPriceChangeAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const confirmBookingPriceChange = (
  reservationId: string,
  values: FormData,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(confirmBookingPriceChangeAsync.request());
  const designation = getState().booking.bookingDetail.bookingDetails.designation;
  try {
    const res = await ApiConfirmPriceChange(apiClient, reservationId, values, designation);
    dispatch(confirmBookingPriceChangeAsync.success(res));
  } catch (error) {
    dispatch(confirmBookingPriceChangeAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const updateBookingStayDateChange = (
  reservationId: string,
  values: FormData,
  providerId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateBookingPriceChangeAsync.request());
  const designation = getState().booking.bookingDetail.bookingDetails.designation;
  try {
    const res = await ApiUpdateStayDateChange(apiClient, reservationId, values, designation);

    dispatch(setInitialModificationDetail());
    dispatch(setInitialChangedModificationDetail());
    dispatch(fetchBookingDetail(reservationId, providerId));
    dispatch(fetchBookingModificationDetails(reservationId));

    dispatch(updateBookingPriceChangeAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: res.statusCode, message: res.message }));
  } catch (error) {
    dispatch(updateBookingPriceChangeAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const confirmBookingStayDateChange = (
  reservationId: string,
  values: FormData,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(confirmBookingPriceChangeAsync.request());
  const designation = getState().booking.bookingDetail.bookingDetails.designation;
  try {
    const res = await ApiConfirmStayDateChange(apiClient, reservationId, values, designation);
    dispatch(confirmBookingPriceChangeAsync.success(res));
  } catch (error) {
    dispatch(confirmBookingPriceChangeAsync.failure(error));
  }
};

function reconcileCancellationCharges(
  initialValues: CancellationCharge[],
  modifiedValues: FormData,
) {
  // Cloning initialValues to avoid modify the original values, and allowing us to color red correctly on backspace.
  const values = initialValues.map(({ date, cancellationCharge }: CancellationCharge) => ({
    date,
    cancellationCharge,
  }));

  let changes: CancellationCharge[] = [];
  Object.keys(modifiedValues)
    .filter(x => x.startsWith('cancellationCharge#'))
    .forEach(key => {
      const index = Number(key.split('#')[1]);
      const modifiedValue = Number(modifiedValues[key]);
      if (values[index].cancellationCharge !== modifiedValue)
        changes.push({
          date: values[index].date,
          cancellationCharge: modifiedValue,
        });
    });
  return changes;
}

export const updateCancellationChargeDetails = (
  reservationModificationDetail: BookingModificationDetail,
  reservationId: string,
  values: FormData,
  providerId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateCancellationChargesAsync.request());
  try {
    const cancellationCharges = reconcileCancellationCharges(
      reservationModificationDetail.cancellationDetails.cancellationCharges,
      values,
    );
    const payload = {
      cancellationCharges,
      note: values.note || reservationModificationDetail.note,
    };
    const res = await ApiUpdateCancellationCharge(apiClient, reservationId, payload);

    dispatch(setInitialModificationDetail());
    dispatch(setInitialChangedModificationDetail());
    dispatch(fetchBookingDetail(reservationId, providerId));
    dispatch(fetchBookingModificationDetails(reservationId));

    dispatch(updateCancellationChargesAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: res.statusCode, message: res.message }));
  } catch (error) {
    dispatch(updateCancellationChargesAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const translateText = (
  reservationId: string,
  id: string | null,
  type: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(translateTextAsync.request());

  try {
    const res = await ApiTranslateText(apiClient, reservationId, { id, type });

    dispatch(translateTextAsync.success(res));
    dispatch(updateBookingTranslation({ id, type, translatedText: res.translatedText }));
  } catch (error) {
    dispatch(translateTextAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const fetchStatistics = (
  values: { [key: string]: string },
  providerId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const formValues = {
    ...values,
    [periodTypeToQuery[values.periodType].fromField]: values.periodFrom,
    [periodTypeToQuery[values.periodType].toField]: values.periodTo,
  };
  delete formValues.periodFrom;
  delete formValues.periodTo;
  delete formValues.periodType;

  try {
    dispatch(fetchStatisticsAsync.request());
    const res = await ApiFetchStatistics(apiClient, formValues, providerId);
    dispatch(fetchStatisticsAsync.success(res));
    dispatch(setIsStatisticsError(false));
  } catch (error) {
    dispatch(setIsStatisticsError(true));
    dispatch(fetchStatisticsAsync.failure(error));
  }
};

export const deleteQueue = (
  reservationId: string,
  noticeQueueId: string,
  type: string,
  providerId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteQueueAsync.request());

  try {
    const res = await ApiDeleteQueue(apiClient, reservationId, noticeQueueId);

    dispatch(fetchBookingDetail(reservationId, providerId));

    dispatch(deleteQueueAsync.success(res));
    dispatch(
      fetchGlobalErrorDialogAsync.success({
        status: res.statusCode,
        message: type,
        isDelete: true,
      }),
    );
  } catch (error) {
    dispatch(deleteQueueAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const rConnectConfirm = (reservationId: string, providerId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(rConnectConfirmAsync.request());

  try {
    const res = await ApiRConnectConfirm(apiClient, reservationId);

    dispatch(fetchBookingDetail(reservationId, providerId));

    dispatch(rConnectConfirmAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: res.statusCode, message: res.message }));
  } catch (error) {
    dispatch(rConnectConfirmAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};
