import React, { ReactNode } from 'react';
import styles from './navigationMenu.module.scss';

type Props = {
  children: ReactNode;
  isExpand?: boolean;
};

function NavigationMenu(props: Props) {
  const { isExpand = true } = props;
  return (
    <aside
      className={isExpand ? styles.expandMenu : styles.collapseMenu}
      data-testid="navigationMenu-wrapper"
    >
      {props.children}
    </aside>
  );
}

export default NavigationMenu;
