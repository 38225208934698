export const DESIGNATION = {
  STANDARD: 'STANDARD',
  LUXURY: 'LUXURY',
  JRDP: 'JRDP',
} as const;
export type DesignationType = typeof DESIGNATION[keyof typeof DESIGNATION];

export const SERVICE_TYPE = {
  HOTEL: 'HOTEL',
  DP: 'DP',
} as const;
