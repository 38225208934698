import { RatePlan, RatePlanDetail, ApiStatus, BulkStatusUpdate } from 'RatePlan-Types';
import { ApiClient } from '@travel/api-client';
import providerPrefix from 'constants/api-prefix';

export const ApiFetchRatePlanList = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<RatePlan> =>
  http
    .get(`${providerPrefix}/${providerId}/ratePlans`, { values: params })
    .then((data: RatePlan) => data);

export const ApiFetchMasterRatePlanList = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<RatePlan> =>
  http
    .get(`${providerPrefix}/${providerId}/ratePlans`, { values: params })
    .then((data: RatePlan) => data);

export const ApiFetchRatePlanDetail = (
  http: ApiClient,
  providerId: string,
  ratePlanId: string,
): Promise<RatePlanDetail> =>
  http
    .get(`${providerPrefix}/${providerId}/ratePlans/${ratePlanId}`)
    .then((data: RatePlanDetail) => data);

export const ApiSubmitNewRatePlan = (
  http: ApiClient,
  providerId: string,
  data: RatePlanDetail,
): Promise<ApiStatus> => {
  return http
    .post(`${providerPrefix}/${providerId}/ratePlans`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiUpdateRatePlan = (
  http: ApiClient,
  providerId: string,
  data: RatePlanDetail,
): Promise<ApiStatus> => {
  return http
    .put(`${providerPrefix}/${providerId}/ratePlans/${data.id}`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiDeleteRatePlan = (
  http: ApiClient,
  providerId: string,
  ratePlanId: string,
): Promise<ApiStatus> => {
  return http
    .delete(`${providerPrefix}/${providerId}/ratePlans/${ratePlanId}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiDeletableRatePlan = (
  http: ApiClient,
  providerId: string,
  ratePlanId: string,
): Promise<ApiStatus> => {
  return http
    .get(`${providerPrefix}/${providerId}/ratePlans/${ratePlanId}/deletable`, {
      ignoreCommonErrorHandler: true,
    })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApibulkStatusUpdate = (
  http: ApiClient,
  providerId: string,
  data: BulkStatusUpdate,
): Promise<ApiStatus> => {
  return http
    .post(`${providerPrefix}/${providerId}/ratePlans/bulk`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};
