import React from 'react';
import { Translate } from 'core/translate';

export const defaultChannelDistributor = {
  active: true,
  apiKey: '',
  apiKeyRegenerate: true,
  createDateTime: '',
  httpHeaders: [],
  id: '',
  ipAddresses: [],
  market: '',
  name: '',
  notificationUrl: '',
  paymentMethods: [],
  providerMarkets: [''],
  providerTypes: [''],
  updateDateTime: '',
};

export const initialSortValue = {
  options: [
    {
      value: 'updateDateTime=desc',
      text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.New_Old" />,
    },
    {
      value: 'updateDateTime=asc',
      text: <Translate id="Supplier_Enumeration.Sort.Updated_Date.Old_New" />,
    },
  ],
  selectedValue: 'updateDateTime=desc',
};

export const initialFilterValue = [
  {
    label: <Translate id="Distributor_Management.List.Filter.Status" />,
    id: 'status',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Status.Activation.Active" />,
        value: 'active',
        isCheck: false,
      },
      {
        label: <Translate id="Supplier_Enumeration.Status.Activation.Inactive" />,
        value: 'inactive',
        isCheck: false,
      },
    ],
  },
];
