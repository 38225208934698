import React, { useEffect, useRef, useState, ReactNode } from 'react';

import ListLoader from '@travel/ui/components/List/ListLoader';
import cx from '@travel/ui/utils/classnames';

import styles from './grid.module.scss';

type Props = {
  className?: string;
  wrapperClassName?: string;
  children: ReactNode;
  hasInfiniteScroll?: boolean;
  next?: () => void;
  hasMore?: boolean;
  loader?: ReactNode;
  isFetching?: boolean;
};

const IS_BOTTOM_THRESHOLD = 2;

function Grid(props: Props) {
  const {
    className,
    wrapperClassName,
    hasInfiniteScroll,
    next,
    hasMore,
    loader,
    isFetching = false,
  } = props;

  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const scrollElem = scrollRef.current?.parentElement;
    if (!hasInfiniteScroll || !scrollElem) return;
    if (scrollElem) {
      const handleScroll = () => {
        // return if isFetching is true, to prevent multiple trigger
        if (isFetching) return;

        const isBottom =
          scrollElem.scrollHeight - scrollElem.scrollTop - scrollElem.clientHeight <
          IS_BOTTOM_THRESHOLD;

        if (isBottom && hasMore) {
          setShowLoader(true);
          next && next();
        } else {
          return;
        }
      };

      scrollElem.addEventListener('scroll', handleScroll, false);
      return () => scrollElem.removeEventListener('scroll', handleScroll, false);
    } else {
      return;
    }
  }, [next, scrollRef, hasMore, hasInfiniteScroll, isFetching]);

  return (
    <>
      {hasInfiniteScroll ? (
        <div
          data-testid="Grid-wrapper"
          className={cx(wrapperClassName, styles.gridContainer)}
          ref={scrollRef}
        >
          <table className={cx(className, styles.grid)}>{props.children}</table>
          {showLoader && hasMore && (loader ? loader : <ListLoader type={'pulse'} />)}
          {hasMore}
        </div>
      ) : (
        <table data-testid="grid-table-wrapper" className={cx(className, styles.grid)}>
          {props.children}
        </table>
      )}
    </>
  );
}

export default Grid;

/** Export All Grid Composition Components */
export { default as GridBody } from './GridBody';
export { default as GridCell } from './GridCell';
export { default as GridHead } from './GridHead';
export { default as GridRow } from './GridRow';
