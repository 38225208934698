import { universalRouterProps } from 'core/universalRouter/types';
import { fetchProviderGroupList } from 'store/providerGroup/actions';
import { QueryParameter } from 'ProviderGroup-Types';

type Location = {
  query: QueryParameter;
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  const { query } = location;
  const promises = [dispatch(fetchProviderGroupList(query))];

  return Promise.all(promises);
};
