import { Feature, FeatureStatus } from 'Feature-Types';
import { FeatureGroupList } from 'FeatureGroup-Types';
import { providerPrefix, internalPrefix } from 'constants/api-prefix';
import { ApiClient } from '@travel/api-client';

export const ApiFetchProviderFeatures = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<FeatureGroupList> => {
  return http
    .get(`${providerPrefix}/${providerId}/featureSelection`, { values: params })
    .then((data: FeatureGroupList) => data);
};
export const ApiFetchFeatures = (http: ApiClient, params: Object): Promise<FeatureGroupList> =>
  http.get(`${internalPrefix}/featureSelection`, { values: params });

export const ApiFetchProviderFeatureDetail = (
  http: ApiClient,
  providerId: string,
  featureId: string,
): Promise<Feature> =>
  http
    .get(`${providerPrefix}/${providerId}/features/${featureId}`, {})
    .then((data: Feature) => data);

export const ApiUpdateProviderFeatureDetail = (
  http: ApiClient,
  providerId: string,
  data: Feature,
): Promise<FeatureStatus> =>
  http
    .put(`${providerPrefix}/${providerId}/features/${data.id}`, { values: data })
    .then((data: FeatureStatus) => data);
