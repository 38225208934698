import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { TaxFeeTypes, TaxFeeTypesErrors } from 'TaxFeeTypes-Types';
import { ApiFetchTaxFeeTypes } from './apis';

export const fetchTaxFeeTypesAsync = createAsyncAction(
  'FETCH_TAX_FEE_TYPES_REQUEST',
  'FETCH_TAX_FEE_TYPES_SUCCESS',
  'FETCH_TAX_FEE_TYPES_FAILURE',
)<undefined, TaxFeeTypes[], TaxFeeTypesErrors[]>();

export const fetchTaxFeeTypes = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchTaxFeeTypesAsync.request());
  try {
    const res = await ApiFetchTaxFeeTypes(apiClient);
    dispatch(fetchTaxFeeTypesAsync.success(res));
  } catch (error) {
    dispatch(fetchTaxFeeTypesAsync.failure([error as TaxFeeTypesErrors]));
  }
};
