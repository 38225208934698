import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  ReviewList,
  Errors,
  ReviewDetail,
  ReviewHistory,
  ReplyHistory,
} from 'ReviewExamination-Types';
import {
  fetchReviewListAsync,
  fetchReviewAsync,
  updateReviewStatusAsync,
  fetchReviewHistoryAsync,
  fetchReplyHistoryAsync,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchReviewListAsync.request], () => true)
  .handleAction([fetchReviewListAsync.success, fetchReviewListAsync.failure], () => false);

export const isUpdateing = createReducer(false)
  .handleAction(updateReviewStatusAsync.request, () => true)
  .handleAction([updateReviewStatusAsync.success, updateReviewStatusAsync.failure], () => false);

export const reviewList = createReducer({} as ReviewList).handleAction(
  fetchReviewListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      reviews:
        state.reviews && shouldConcat
          ? state.reviews.concat(action.payload.reviews)
          : action.payload.reviews,
    };
  },
);

export const reviewDetail = createReducer({} as ReviewDetail).handleAction(
  fetchReviewAsync.success,
  (_state, action) => action.payload,
);

export const reviewHistory = createReducer({} as ReviewHistory).handleAction(
  fetchReviewHistoryAsync.success,
  (_state, action) => action.payload,
);

export const replyHistory = createReducer({} as ReplyHistory).handleAction(
  fetchReplyHistoryAsync.success,
  (_state, action) => action.payload,
);

export const errors = createReducer([] as Errors[]).handleAction(
  fetchReviewListAsync.failure,
  (state, action) => action.payload,
);

const reviewExaminationReducer = combineReducers({
  isFetching,
  isUpdateing,
  reviewList,
  reviewDetail,
  reviewHistory,
  replyHistory,
  errors,
});

export default reviewExaminationReducer;
export type ReviewExaminationState = ReturnType<typeof reviewExaminationReducer>;
