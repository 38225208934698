import './public-path';

import React from 'react';
import { hydrate } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { loadableReady } from '@loadable/component';
import { createBrowserHistory } from 'history';
import configureStore from 'store';

import { ApiClientProvider } from '@travel/api-client';
import env from '@travel/env';

import { createApiClient } from 'core/httpClient';
import commonErrorHandler from 'core/httpClient/commonErrorHandler';
import requestInterceptor from 'core/httpClient/requestInterceptor';
import initTranslation from 'core/translate/init';
import universalRouter from 'core/universalRouter';
import ClientRouter from 'core/universalRouter/client/Router';

const apiClient = createApiClient();

const history = createBrowserHistory({
  basename: env('UNIVERSAL_BASE_NAME'),
});
const store = configureStore(window.PRELOADED_STATE, history, undefined, apiClient);

// @ts-ignore
if (window.Cypress) {
  //  @ts-ignore
  window.store = store;
}

apiClient.setRequestInterceptor(requestInterceptor(store));
apiClient.setCommonErrorHandler(commonErrorHandler(store));

// initialize labels
initTranslation(store.getState().labels.dictionary || {});

loadableReady(async () => {
  // For matching html from SSR
  const router = universalRouter({ store });
  const children = await router.resolve({
    ...history.location,
    skipResolver: true,
  });
  let App = () => <>{children}</>;

  window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
    const isShowBrowserPrompt = store.getState().login.isShowBrowserPrompt;
    if (isShowBrowserPrompt) {
      event.preventDefault();
      event.returnValue = true;
    }
  });

  hydrate(
    <ApiClientProvider apiClient={apiClient}>
      <ReduxProvider store={store}>
        <ClientRouter history={history} router={router}>
          <App />
        </ClientRouter>
      </ReduxProvider>
    </ApiClientProvider>,
    document.getElementById('root'),
  );
});
