import React from 'react';
import cx from '../../utils/classnames';
import SimpleSkeletonItem from './components/SimpleSkeletonItem';
import styles from './skeletonListItems.module.scss';

const LIST_TYPE = {
  LIST_PAGE: 'listPage',
  DETAIL_PAGE: 'detailPage',
  DIALOG: 'dialog',
} as const;
type SkeletonListType = typeof LIST_TYPE[keyof typeof LIST_TYPE];

type Props = {
  listClassName?: string;
  itemClassName?: string;
  skeletonType?: SkeletonListType;
  hasMedia?: boolean;
  hasCheckbox?: boolean;
  numberOfItem?: number;
};

function SkeletonListItems(props: Props) {
  const {
    listClassName,
    itemClassName,
    skeletonType = LIST_TYPE.LIST_PAGE,
    hasMedia,
    hasCheckbox = true,
    numberOfItem = 3,
    ...rest
  } = props;

  const switchChildProps = () => {
    switch (skeletonType) {
      case LIST_TYPE.LIST_PAGE:
        return {
          itemClassName: cx(itemClassName, hasCheckbox && styles.paddingLeft, styles.listType),
          hasMedia,
          titleClassName: styles.title,
          contentClassName: styles.content,
        };
      case LIST_TYPE.DETAIL_PAGE:
        return {
          itemClassName: cx(itemClassName, styles.detailType),
          titleClassName: styles.title,
          contentClassName: styles.content,
        };
      case LIST_TYPE.DIALOG:
        return {
          itemClassName: cx(itemClassName, styles.dialogType),
          contentClassName: styles.content,
        };
      default:
        return {};
    }
  };

  return (
    <div className={cx(listClassName)} data-testid="skeletonListItems-wrapper" {...rest}>
      {[...Array(numberOfItem)].map((_, i) => (
        <SimpleSkeletonItem {...switchChildProps()} key={i} />
      ))}
    </div>
  );
}

export default SkeletonListItems;
export { default as SimpleSkeletonItem } from './components/SimpleSkeletonItem';
