import { Store } from 'typesafe-actions';

import env from '@travel/env';
import { noticeError } from '@travel/tracker';

import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { pushLocation } from 'store/__router/actions';

import { setIsShowBrowserPrompt } from 'store/accessControl/actions';
import ERROR_URLS, { getErrorPagePath } from 'utils/errorPageHelper';
import { getIsRFBUser } from 'utils/verifyTokenHelper';

const setCommonErrorHandler = (store: Store, isSSR?: boolean) => ({
  status,
  request,
  response,
}: {
  status: number;
  request?: Request;
  response?: Response;
}) => {
  const { dispatch } = store;
  const state = store.getState();
  const basename = env('UNIVERSAL_BASE_NAME') ? `/${env('UNIVERSAL_BASE_NAME')}` : '';
  const isRFBUser = getIsRFBUser(state.login?.verifyTokenDetail?.loginScheme);
  const uiLanguage = state._i18n?.language;
  const redirectPage = isRFBUser
    ? `${ERROR_URLS.COMMON}${uiLanguage ? `&lang=${uiLanguage}` : ''}`
    : 'logout';
  if (status === 401) {
    if (isSSR) {
      dispatch(pushLocation(`${basename}/${redirectPage}`));
    } else {
      if (isRFBUser) {
        dispatch(setIsShowBrowserPrompt(false));
        window.location.href = getErrorPagePath('COMMON', uiLanguage);
      } else {
        dispatch(pushLocation(`/${redirectPage}`));
      }
    }
  } else {
    response?.json().then(json => {
      const errorJson = {
        url: response.url,
        req: request,
        res: response,
        json,
      };
      dispatch(fetchGlobalErrorDialogAsync.success({ ...json, status }));
      if (/^50/.test(String(response.status))) {
        noticeError('API 50x Error', errorJson);
      } else if (/^40/.test(String(response.status))) {
        noticeError('API 40x Error', errorJson);
      }
    });
  }
};

export default setCommonErrorHandler;
