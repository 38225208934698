import React from 'react';
import { Helmet } from 'react-helmet';
import { getTranslation, TranslatePropsForPlainText } from '@travel/translation';

type Props = {
  title: string | TranslatePropsForPlainText;
};
function HelmetWrapper(props: Props) {
  const { title } = props;
  const plainTitle = typeof title === 'string' ? title : getTranslation({ ...title }).join('');

  return (
    <Helmet>
      <title>{plainTitle}</title>
    </Helmet>
  );
}
export default HelmetWrapper;
