import {
  IntroductionContent,
  IntroductionContentArticle,
  IntroductionContentStatus,
} from 'IntroductionContent-Types';
import { ApiClient } from '@travel/api-client';
import { providerPrefix } from 'constants/api-prefix';

export const ApiFetchIntroductionContent = (
  http: ApiClient,
  params: Object,
  providerId: string,
): Promise<IntroductionContent> => {
  const url = `${providerPrefix}/${providerId}/media/articles`;
  return http.get(url, { values: params }).then((data: IntroductionContent) => data);
};

export const ApiPostIntroductionContentArticle = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<IntroductionContentStatus> => {
  const url = `${providerPrefix}/${providerId}/media/articles`;
  return http
    .post(url, {
      values: {
        ...params,
        type: 'INTRODUCTION',
      },
    })
    .then((data: IntroductionContentStatus) => data)
    .catch((data: IntroductionContentStatus) => data);
};

export const ApiPutIntroductionContentArticle = (
  http: ApiClient,
  providerId: string,
  params: IntroductionContentArticle,
): Promise<IntroductionContentStatus> => {
  const url = `${providerPrefix}/${providerId}/media/articles/${params.id}`;
  return http
    .put(url, {
      values: {
        ...params,
        type: 'INTRODUCTION',
      },
    })
    .then((data: IntroductionContentStatus) => data)
    .catch((data: IntroductionContentStatus) => data);
};
