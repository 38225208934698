import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import {
  SalesPromotion,
  SalesPromotionList,
  ApiStatus,
  Deletable,
  DeletableDialog,
} from 'SalesPromotionList-Types';
import {
  ApiFetchSalesPromotions,
  ApiFetchSalesPromotionDetail,
  ApiFetchDeletablePromotion,
  ApiAddSalesPromotion,
  ApiUpdateSalesPromotion,
  ApiDeleteSalesPromotion,
} from './api';
import { pushLocation } from 'store/__router/actions';

export const fetchSalesPromotionsAsync = createAsyncAction(
  'FETCH_PROVIDER_SALES_PROMOTION_REQUEST',
  'FETCH_PROVIDER_SALES_PROMOTION_SUCCESS',
  'FETCH_PROVIDER_SALES_PROMOTION_FAILURE',
)<undefined, SalesPromotionList, ApiStatus[]>();

export const fetchSalesPromotionDetailAsync = createAsyncAction(
  'FETCH_PROVIDER_SALES_PROMOTION_DETAIL_REQUEST',
  'FETCH_PROVIDER_SALES_PROMOTION_DETAIL_SUCCESS',
  'FETCH_PROVIDER_SALES_PROMOTION_DETAIL_FAILURE',
)<undefined, SalesPromotion, ApiStatus[]>();

export const fetchDeletablePromotionAsync = createAsyncAction(
  'FETCH_DELETABLE_PROVIDER_SALES_PROMOTION_REQUEST',
  'FETCH_DELETABLE_PROVIDER_SALES_PROMOTION_SUCCESS',
  'FETCH_DELETABLE_PROVIDER_SALES_PROMOTION_FAILURE',
)<undefined, Deletable, ApiStatus[]>();

export const putDeletableDialogAsync = createAsyncAction(
  'DELETABLE_DIALOG_PROMOTION_REQUEST',
  'DELETABLE_DIALOG_PROMOTION_SUCCESS',
  'DELETABLE_DIALOG_PROMOTION_FAILURE',
)<undefined, DeletableDialog, ApiStatus[]>();

export const addSalesPromotionAsync = createAsyncAction(
  'ADD_PROVIDER_SALES_PROMOTION_REQUEST',
  'ADD_PROVIDER_SALES_PROMOTION_SUCCESS',
  'ADD_PROVIDER_SALES_PROMOTION_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const updateSalesPromotionAsync = createAsyncAction(
  'UPDATE_PROVIDER_SALES_PROMOTION_REQUEST',
  'UPDATE_PROVIDER_SALES_PROMOTION_SUCCESS',
  'UPDATE_PROVIDER_SALES_PROMOTION_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const deleteSalesPromotionAsync = createAsyncAction(
  'DELETE_PROVIDER_SALES_PROMOTION_REQUEST',
  'DELETE_PROVIDER_SALES_PROMOTION_SUCCESS',
  'DELETE_PROVIDER_SALES_PROMOTION_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const setInitialPromotionDetail = createStandardAction('SET_INITIAL_PROMOTION_DETAIL')();

export const fetchSalesPromotions = (
  providerId: string,
  query?: { [key: string]: string },
  offset?: number,
  rejectIfFetching?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isFetching = getState().salesPromotionList.isFetching;
  if (isFetching && rejectIfFetching) return;

  dispatch(fetchSalesPromotionsAsync.request());
  const fetchQuery = {
    ...query,
    offset: offset || 0,
    limit: 30,
  };

  try {
    const res = await ApiFetchSalesPromotions(apiClient, providerId, fetchQuery);

    dispatch(fetchSalesPromotionsAsync.success(res));
  } catch (error) {
    error.status !== 403 && dispatch(fetchSalesPromotionsAsync.failure([error as ApiStatus]));
  }
};

export const fetchSalesPromotionDetail = (
  providerId: string,
  promotionId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchSalesPromotionDetailAsync.request());

  try {
    const res = await ApiFetchSalesPromotionDetail(apiClient, providerId, promotionId);
    dispatch(fetchSalesPromotionDetailAsync.success(res));
  } catch (error) {
    dispatch(fetchSalesPromotionDetailAsync.failure([error as ApiStatus]));
  }
};

export const fetchDeletableSalesPromotion = (
  providerId: string,
  promotionId: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(fetchDeletablePromotionAsync.request());
    const res = await ApiFetchDeletablePromotion(apiClient, providerId, promotionId);
    dispatch(fetchDeletablePromotionAsync.success(res));
    dispatch(putDeletableDialogAsync.success({ isShow: true }));
  } catch (error) {
    error.status === 403
      ? dispatch(fetchDeletablePromotionAsync.success(error as ApiStatus)) &&
        dispatch(putDeletableDialogAsync.success({ isShow: true }))
      : dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

export const addSalesPromotion = (
  providerId: string,
  newSalesPromotion: SalesPromotion,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(addSalesPromotionAsync.request());

  try {
    const res = await ApiAddSalesPromotion(apiClient, providerId, newSalesPromotion);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(addSalesPromotionAsync.success(res));
    if (res.id) {
      dispatch(pushLocation(`/provider/${providerId}/sales-promotions/${res.id}`));
    }
  } catch (error) {
    dispatch(addSalesPromotionAsync.failure([error as ApiStatus]));
  }
};

export const updateSalesPromotion = (
  providerId: string,
  newSalesPromotion: SalesPromotion,
  search?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateSalesPromotionAsync.request());

  try {
    const res = await ApiUpdateSalesPromotion(apiClient, providerId, newSalesPromotion);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(updateSalesPromotionAsync.success(res));
    dispatch(
      pushLocation({
        pathname: `/provider/${providerId}/sales-promotions/${newSalesPromotion.id}`,
        state: {
          prevSearch: search,
        },
      }),
    );
  } catch (error) {
    dispatch(updateSalesPromotionAsync.failure([error as ApiStatus]));
  }
};

export const deleteSalesPromotion = (
  providerId: string,
  promotionId: string,
  promotionName: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteSalesPromotionAsync.request());

  try {
    const res = await ApiDeleteSalesPromotion(apiClient, providerId, promotionId);
    // Pass the deleted object name as message
    res.message = promotionName;

    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteSalesPromotionAsync.success(res));
  } catch (error) {
    dispatch(deleteSalesPromotionAsync.failure([error as ApiStatus]));
  }
};
