import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { AccountingReports, YearAndMonths, AccountingReportErrors } from 'AccountingReport-Types';
import { ApiFetchYearAndMonths, ApiFetchAccountingReports, ApiDownloadReport } from './apis';
import { downloadFileFromBlob } from '@travel/utils';

export const fetchAccountingReportAsync = createAsyncAction(
  'FETCH_ACCOUNTING_REPORT_REQUEST',
  'FETCH_ACCOUNTING_REPORT_SUCCESS',
  'FETCH_ACCOUNTING_REPORT_FAILURE',
)<undefined, AccountingReports, AccountingReportErrors[]>();

export const fetchYearAndMonthsAsync = createAsyncAction(
  'FETCH_YEAR_MONTHS_REQUEST',
  'FETCH_YEAR_MONTHS_SUCCESS',
  'FETCH_YEAR_MONTHSFAILURE',
)<undefined, YearAndMonths, AccountingReportErrors[]>();

export const downloadFileAsync = createAsyncAction(
  'DOWNLOAL_FILE_REQUEST',
  'DOWNLOAL_FILE_SUCCESS',
  'DOWNLOAL_FILE_FAILURE',
)<undefined, Object, AccountingReportErrors[]>();

export const fetchAccountingReports = (params: Object, offset?: number): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchAccountingReportAsync.request());
  const fetchQuery = {
    ...params,
    offset: offset || 0,
    limit: 30,
  };
  const res = await ApiFetchAccountingReports(apiClient, fetchQuery);
  dispatch(fetchAccountingReportAsync.success(res));
};

export const fetchYearAndMonths = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchYearAndMonthsAsync.request());
  const res = await ApiFetchYearAndMonths(apiClient);
  dispatch(fetchYearAndMonthsAsync.success(res));
};

export const downloadFile = (recodId: string, file: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(downloadFileAsync.request());
  const res = await ApiDownloadReport(apiClient, recodId, file);
  try {
    const blob = await res.blob();
    downloadFileFromBlob(blob, `accounting_report_${recodId}.${file}`);
  } catch (error) {
    console.error(error);
  }
  dispatch(downloadFileAsync.success(res));
};
