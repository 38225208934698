import { PAGE_ID } from 'constants/lastUsedValue';
import { fetchAllChannelDistributors } from 'store/channelDistributor/actions';
import { fetchAllFeatureGroups } from 'store/featureSelection/actions';
import { clearLastUsedValue, fetchLastUsedValue } from 'store/lastUsedValue/actions';
import { clearCurrentPromotion, fetchSingleSalesPromotion } from 'store/salesPromotion/actions';
import { fetchSelectedUserSegment, fetchUserSegments } from 'store/userSegment/actions';
import { initFilterState } from 'store/userSegment/initialValues';

import { fetchCurrencies } from 'store/currencies/actions';

import { MANAGEMENT, OBJECT_TYPE } from 'constants/feature';
import { universalRouterProps } from 'core/universalRouter/types';

type Location = {
  params: {
    promotionId: string;
  };
};

export default ({ store: { dispatch, getState } }: universalRouterProps, location: Location) => {
  const { promotionId } = location.params;
  const userUuid = getState().accessControl.loggedInUser?.userUuid;
  const featureQueryParams = {
    resourceId: promotionId,
    objectType: OBJECT_TYPE.SALES_PROMOTION,
    management: [MANAGEMENT.SALES_PROMOTION.CAMPAIGN.name],
  };

  const promises = [
    dispatch(fetchAllChannelDistributors()),
    dispatch(fetchCurrencies()),
    dispatch(fetchUserSegments(initFilterState)),
    dispatch(fetchAllFeatureGroups('', featureQueryParams)),
  ];

  dispatch(clearCurrentPromotion());

  if (promotionId) {
    promises.push(
      dispatch(fetchSingleSalesPromotion(promotionId)).then(data => {
        dispatch(fetchSelectedUserSegment({ segmentIds: data?.userCondition?.userSegments || [] }));
      }),
    );
    dispatch(clearLastUsedValue());
  } else {
    promises.push(dispatch(fetchLastUsedValue({ type: PAGE_ID.RAKUTEN_SP, userUuid })));
    dispatch(clearCurrentPromotion());
  }

  return Promise.all(promises);
};
