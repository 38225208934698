import {
  NotificationInboxList,
  FetchNotificationParams,
  ComposeNotification,
  NotificationDetail,
  ApiStatus,
  NotificationDetailList,
} from 'Notification-Types';
import { ApiClient } from '@travel/api-client';

export const ApiFetchNotifications = (http: ApiClient, params: FetchNotificationParams) => {
  return http
    .get(`/pushNotifications/list`, { values: params })
    .then((data: NotificationInboxList) => data);
};

export const ApiFetchNotificationDetail = (http: ApiClient, notificationId: string) => {
  return http
    .get(`/pushNotifications/detail/${notificationId}`)
    .then((data: NotificationDetail) => data);
};

export const ApiComposeNotification = (http: ApiClient, notification: ComposeNotification) => {
  return http
    .post(`/pushNotifications/compose`, { values: notification })
    .then((data: ApiStatus) => data);
};

export const ApiFetchNotificationsDetailList = (
  http: ApiClient,
  notificationIdList: Array<string>,
) => {
  return http
    .post(`/pushNotifications/notifications`, {
      values: { notificationIds: notificationIdList },
    })
    .then((data: NotificationDetailList) => data);
};
