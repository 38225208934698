import { ApiClient } from '@travel/api-client';

import { ApiStatus, CampaignItems, CampaignList, CouponCodes } from 'Campaign-Types';

import { internalPrefix } from 'constants/api-prefix';

export const ApiFetchCampaignList = (http: ApiClient, params: Object): Promise<CampaignList> => {
  return http.get(`${internalPrefix}/campaigns`, { values: params });
};

export const ApiCreateCampaign = (http: ApiClient, data: CampaignItems): Promise<ApiStatus> => {
  return http
    .post(`${internalPrefix}/campaigns`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiFetchCampaign = (http: ApiClient, sponsoredId: string): Promise<CampaignItems> => {
  return http.get(`${internalPrefix}/campaigns/${sponsoredId}`);
};

export const ApiUpdateCampaign = (
  http: ApiClient,
  sponsoredId: string,
  data: CampaignItems,
): Promise<ApiStatus> => {
  return http
    .put(`${internalPrefix}/campaigns/${sponsoredId}`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiDeleteCampaign = (http: ApiClient, sponsoredId: string): Promise<ApiStatus> => {
  return http
    .delete(`${internalPrefix}/campaigns/${sponsoredId}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiFetchCampaignDeletable = (
  http: ApiClient,
  sponsoredId: string,
): Promise<ApiStatus> =>
  http
    .get(`${internalPrefix}/campaigns/${sponsoredId}/deletable`, { ignoreCommonErrorHandler: true })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiResubmitCampaign = (
  http: ApiClient,
  sponsoredId: string,
  params: Object,
): Promise<ApiStatus> => {
  return http
    .put(`${internalPrefix}/campaigns/${sponsoredId}/revoke`, { values: params })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const ApiGenerateCoupons = (http: ApiClient, number: number): Promise<CouponCodes[]> => {
  return http
    .post(`${internalPrefix}/campaigns/coupons?number=${number}`, {})
    .then((data: CouponCodes[]) => data);
};

export const fetchCSVData = (http: ApiClient, id: string) =>
  http.get(`${internalPrefix}/campaigns/${id}/downloadInvitations`, {
    ignoreCommonErrorHandler: true,
  });
