import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ApplicationDetail, Error, RatePlanSuggestion } from 'ApplicationDetail-Types';
import {
  fetchApplicationDetailAsync,
  fetchRatePlanSuggestionAsync,
  updateRatePlanSuggestionAsync,
} from './actions';
import applicationRatePlanDetail from './ratePlanReducers';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchApplicationDetailAsync.request], (_state, _action) => true)
  .handleAction(
    [fetchApplicationDetailAsync.success, fetchApplicationDetailAsync.failure],
    (_state, _action) => false,
  );

export const errors = createReducer([] as Error[]).handleAction(
  fetchApplicationDetailAsync.failure ||
    fetchRatePlanSuggestionAsync.failure ||
    updateRatePlanSuggestionAsync.failure,
  (state, action) => action.payload,
);

export const applicationDetail = createReducer({} as ApplicationDetail).handleAction(
  fetchApplicationDetailAsync.success,
  (state, action) => action.payload,
);

export const ratePlanSuggestion = createReducer({} as RatePlanSuggestion[]).handleAction(
  fetchRatePlanSuggestionAsync.success,
  (state, action) => action.payload,
);

const applicationDetailReducer = combineReducers({
  isFetching,
  applicationDetail,
  applicationRatePlanDetail,
  errors,
  ratePlanSuggestion,
});

export default applicationDetailReducer;
export type ApplicationDetailState = ReturnType<typeof applicationDetailReducer>;
