import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import env from '@travel/env';
import { MyPageOutlined, RoundtripArrows, Survey } from '@travel/icons/ui';
import { FlatButton } from '@travel/ui';

import { setIsShowBrowserPrompt } from 'store/accessControl/actions';
import {
  getLoggedInUserPermissions,
  getLoggedInUserScope,
  getPlatformFlag,
} from 'store/accessControl/selectors';
import { hasViewPermission } from 'utils/userPermissionsHelper';
import { getIsProviderGroupProviderUser } from 'utils/userScopeHelper';
import { ACCOUNT_TYPE, RFB_USER_ROLE } from 'utils/verifyTokenHelper';

import { labels } from './labels';

import { getVerifyTokenDetail } from 'store/login/selectors';

import styles from './rfbMenu.module.scss';

type Props = { isRFBUser: boolean; isMenuOpen: boolean };

export const V1_NAVIGATION_URL: { [key: string]: string } = {
  CUSTOM: 'https://manage.travel.rakuten.co.jp/portal/inn/mp_custom_kanri.main',
  COMMON: 'https://manage.travel.rakuten.co.jp/portal/inn/mp_kanri.main',
  GROUP: 'https://manage.travel.rakuten.co.jp/portal/inn/mp_group.main',
  GROUP_PROVIDER: 'https://manage.travel.rakuten.co.jp/portal/inn/mp_kanri_stock.main',
};

function RFBMenu(props: Props) {
  const { isRFBUser, isMenuOpen } = props;
  const dispatch = useDispatch();
  const { contractCount, accountType, v1ProviderId, v1GroupCode, rfbRole } = useSelector(
    getVerifyTokenDetail,
  );
  const { isExtranet, isProviderGroup } = useSelector(getPlatformFlag);
  const loggedInUserPermissions = useSelector(getLoggedInUserPermissions);
  const loggedInUserScope = useSelector(getLoggedInUserScope);

  const hasV1FullPermission = hasViewPermission(loggedInUserPermissions, 'v1ExtrntFull');
  const hasV1CstmPermission = hasViewPermission(loggedInUserPermissions, 'v1ExtrntCstmPage');

  const shouldShowHotelListButton =
    contractCount &&
    Number(contractCount) > 1 &&
    isExtranet &&
    accountType !== ACCOUNT_TYPE.INTERNAL;

  const shouldShowPortalButton =
    accountType === ACCOUNT_TYPE.INTERNAL || rfbRole === RFB_USER_ROLE.ADMIN;

  const handleNavigateToV1Provider = () => {
    const isWAccountUser = hasV1CstmPermission && !hasV1FullPermission;
    const isGroupUser = getIsProviderGroupProviderUser(loggedInUserScope);
    dispatch(setIsShowBrowserPrompt(false));
    if (isGroupUser) {
      window.location.href = `${V1_NAVIGATION_URL.GROUP_PROVIDER}?f_lang=J&f_no=${v1ProviderId}&f_code=${v1GroupCode}&f_stock_flg=room_gh`;
    } else if (isWAccountUser) {
      window.location.href = `${V1_NAVIGATION_URL.CUSTOM}?f_lang=J&f_no=${v1ProviderId}&f_t_flg=heya&f_flg=RTN`;
    } else {
      window.location.href = `${V1_NAVIGATION_URL.COMMON}?f_lang=J&f_no=${v1ProviderId}&f_t_flg=heya&f_flg=RTN`;
    }
  };

  const handleNavigateToV1Group = () => {
    dispatch(setIsShowBrowserPrompt(false));
    window.location.href = `${V1_NAVIGATION_URL.GROUP}?f_lang=J&f_code=${v1GroupCode}&f_flg=RTN`;
  };

  const handleNavigateToHotelList = () => {
    dispatch(setIsShowBrowserPrompt(false));
    window.location.href = env('UNIVERSAL_RFB_LIST_URL') || '';
  };

  const handleNavigateToPortal = () => {
    dispatch(setIsShowBrowserPrompt(false));
    window.location.href = env('UNIVERSAL_RFB_PORTAL_URL') || '';
  };

  return isRFBUser && isMenuOpen ? (
    <div className={styles.buttonSection}>
      {isExtranet && (
        <FlatButton
          classType={'secondary'}
          icon={<RoundtripArrows color="cilantro" size={20} />}
          onClick={handleNavigateToV1Provider}
          className={styles.rfbButton}
          labelClassName={styles.rfbButtonLabel}
          data-testid="rfbMenu-cruiseProviderButton"
        >
          {labels.providerTop}
        </FlatButton>
      )}
      {isProviderGroup && (
        <FlatButton
          classType={'secondary'}
          icon={<RoundtripArrows color="cilantro" size={20} />}
          onClick={handleNavigateToV1Group}
          className={styles.rfbButton}
          labelClassName={styles.rfbButtonLabel}
          data-testid="rfbMenu-cruiseGroupButton"
        >
          {labels.groupTop}
        </FlatButton>
      )}
      {/** displayed on Extranet for non-internal user who own multi hotel */}
      {shouldShowHotelListButton && (
        <FlatButton
          classType={'secondary'}
          icon={<Survey color="cilantro" size={20} />}
          onClick={handleNavigateToHotelList}
          className={styles.rfbButton}
          labelClassName={styles.rfbButtonLabel}
          data-testid="rfbMenu-hotelListButton"
        >
          {labels.hotelList}
        </FlatButton>
      )}
      {shouldShowPortalButton && (
        <FlatButton
          classType={'secondary'}
          icon={<MyPageOutlined color="cilantro" size={20} />}
          onClick={handleNavigateToPortal}
          className={styles.rfbButton}
          labelClassName={styles.rfbButtonLabel}
          data-testid="rfbMenu-portalButton"
        >
          {labels.portal}
        </FlatButton>
      )}
      <div className={styles.divider} />
    </div>
  ) : (
    <></>
  );
}

export default RFBMenu;
