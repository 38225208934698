import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { SalesPromotion, SalesPromotionList, ApiStatus } from 'SalesPromotionList-Types';
import {
  fetchSalesPromotionsAsync,
  fetchSalesPromotionDetailAsync,
  fetchDeletablePromotionAsync,
  addSalesPromotionAsync,
  updateSalesPromotionAsync,
  deleteSalesPromotionAsync,
  setInitialPromotionDetail,
  putDeletableDialogAsync,
} from './actions';
import {
  initialPromotion,
  initialPromotionDeletable,
  initialDeletableDialog,
} from './initialValues';

export const isCreating = createReducer(false)
  .handleAction([addSalesPromotionAsync.request, updateSalesPromotionAsync.request], () => true)
  .handleAction(
    [
      addSalesPromotionAsync.success,
      addSalesPromotionAsync.failure,
      updateSalesPromotionAsync.success,
      updateSalesPromotionAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction(deleteSalesPromotionAsync.request, () => true)
  .handleAction(
    [deleteSalesPromotionAsync.success, deleteSalesPromotionAsync.failure],
    () => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [fetchSalesPromotionsAsync.request, fetchSalesPromotionDetailAsync.request],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchSalesPromotionsAsync.success,
      fetchSalesPromotionsAsync.failure,
      fetchSalesPromotionDetailAsync.success,
      fetchSalesPromotionDetailAsync.failure,
    ],
    (state, action) => false,
  );

export const promotions = createReducer({} as SalesPromotionList).handleAction(
  fetchSalesPromotionsAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const promotionsError = createReducer([] as ApiStatus[]).handleAction(
  fetchSalesPromotionsAsync.failure,
  (state, action) => action.payload,
);

export const promotionDetail = createReducer(initialPromotion)
  .handleAction(fetchSalesPromotionDetailAsync.success, (state, action) => {
    const { payload } = action;
    const updatedPayload = {
      ...payload,
    };
    return updatedPayload;
  })
  .handleAction(setInitialPromotionDetail, () => initialPromotion);

export const deletablePromotion = createReducer(initialPromotionDeletable).handleAction(
  fetchDeletablePromotionAsync.success,
  (state, action) => {
    const { payload } = action;
    const updatedPayload = {
      ...payload,
      statusCode: payload.status,
    };
    return updatedPayload;
  },
);

export const deletableDialog = createReducer(initialDeletableDialog).handleAction(
  putDeletableDialogAsync.success,
  (state, action) => action.payload,
);

export const promotionDetailError = createReducer([] as ApiStatus[]).handleAction(
  fetchSalesPromotionDetailAsync.failure,
  (state, action) => action.payload,
);

export const addPromotion = createReducer({} as SalesPromotion).handleAction(
  addSalesPromotionAsync.success,
  (state, action) => action.payload,
);

export const addPromotionError = createReducer([] as ApiStatus[]).handleAction(
  addSalesPromotionAsync.failure,
  (state, action) => action.payload,
);

export const updatePromotion = createReducer({} as SalesPromotion).handleAction(
  updateSalesPromotionAsync.success,
  (state, action) => action.payload,
);

export const updatePromotionError = createReducer([] as ApiStatus[]).handleAction(
  updateSalesPromotionAsync.failure,
  (state, action) => action.payload,
);

export const deletePromotion = createReducer({} as ApiStatus).handleAction(
  deleteSalesPromotionAsync.success,
  (state, action) => action.payload,
);

export const deletePromotionError = createReducer([] as ApiStatus[]).handleAction(
  deleteSalesPromotionAsync.failure,
  (state, action) => action.payload,
);

const salesPromotionListReducer = combineReducers({
  isFetching,
  promotions,
  promotionsError,
  promotionDetail,
  deletablePromotion,
  deletableDialog,
  promotionDetailError,
  addPromotion,
  addPromotionError,
  updatePromotion,
  updatePromotionError,
  deletePromotion,
  deletePromotionError,
  isCreating,
  isDeleting,
});

export default salesPromotionListReducer;
export type SalesPromotionListState = ReturnType<typeof salesPromotionListReducer>;
