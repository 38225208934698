import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  FeatureGroupList,
  FeatureGroupSearch,
  FeatureGroupStatus,
  FeatureGroupObjectTypes,
} from 'FeatureGroup-Types';
import { FeatureGroup } from 'Feature-Types';
import {
  ApiFetchFeatureGroupList,
  ApiFetchFeatureGroup,
  ApiFetchFeatureGroupSearch,
  ApiPostFeatureGroup,
  ApiPutFeatureGroup,
  ApiFetchFeatureGroupObjectTypes,
  ApiFetchFindByObjectTypeAndManagement,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { pushLocation } from 'store/__router/actions';
import { filterIdList } from 'constants/feature';
import { Query, transformToQueryObject as paramsToQueryObject } from 'utils/transformToQueryObject';
import { initialBrowseParameter as init } from 'store/feature/initialValues';

export const fetchFeatureGroupListAsync = createAsyncAction(
  'FETCH_FEATURE_GROUP_LIST_REQUEST',
  'FETCH_FEATURE_GROUP_LIST_SUCCESS',
  'FETCH_FEATURE_GROUP_LIST_FAILURE',
)<undefined, FeatureGroupList, FeatureGroupStatus[]>();

export const fetchFeatureGroupAsync = createAsyncAction(
  'FETCH_FEATURE_GROUP_REQUEST',
  'FETCH_FEATURE_GROUP_SUCCESS',
  'FETCH_FEATURE_GROUP_FAILURE',
)<undefined, FeatureGroup, FeatureGroupStatus[]>();

export const fetchFeatureGroupSearchAsync = createAsyncAction(
  'FETCH_FEATURE_GROUP_SEARCH_REQUEST',
  'FETCH_FEATURE_GROUP_SEARCH_SUCCESS',
  'FETCH_FEATURE_GROUP_SEARCH_FAILURE',
)<undefined, FeatureGroupSearch, FeatureGroupStatus[]>();

export const fetchFeatureGroupObjectTypesAsync = createAsyncAction(
  'FETCH_FEATURE_GROUP_OBJECT_TYPES_REQUEST',
  'FETCH_FEATURE_GROUP_OBJECT_TYPES_SUCCESS',
  'FETCH_FEATURE_GROUP_OBJECT_TYPES_FAILURE',
)<undefined, FeatureGroupObjectTypes, FeatureGroupStatus[]>();

export const postFeatureGroupAsync = createAsyncAction(
  'POST_FEATURE_GROUP_REQUEST',
  'POST_FEATURE_GROUP_SUCCESS',
  'POST_FEATURE_GROUP_FAILURE',
)<undefined, FeatureGroupStatus, FeatureGroupStatus[]>();

export const putFeatureGroupAsync = createAsyncAction(
  'PUT_FEATURE_GROUP_REQUEST',
  'PUT_FEATURE_GROUP_SUCCESS',
  'PUT_FEATURE_GROUP_FAILURE',
)<undefined, FeatureGroupStatus, FeatureGroupStatus[]>();

export const fetchRepFeatureGroupAsync = createAsyncAction(
  'FETCH_REP_FEATURE_GROUP_REQUEST',
  'FETCH_REP_FEATURE_GROUP_SUCCESS',
  'FETCH_REP_FEATURE_GROUP_FAILURE',
)<undefined, FeatureGroup, FeatureGroupStatus[]>();

export const setInitialFeatureGroup = createStandardAction('SET_INITIAL_FEATURE_GROUP')();

export const fetchFeatureGroupList = (query?: Query, offset?: number): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const fetchQuery = {
    ...paramsToQueryObject({ ...query } || {}, filterIdList),
    offset: offset || init.offset,
    limit: 30,
  };

  dispatch(fetchFeatureGroupListAsync.request());
  try {
    const res = await ApiFetchFeatureGroupList(apiClient, fetchQuery);
    dispatch(fetchFeatureGroupListAsync.success(res));
  } catch (errors) {
    dispatch(fetchFeatureGroupListAsync.failure([errors as FeatureGroupStatus]));
  }
};

export const fetchFeatureGroup = (featureGroupsId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchFeatureGroupAsync.request());
    const res = await ApiFetchFeatureGroup(apiClient, featureGroupsId);
    dispatch(fetchFeatureGroupAsync.success(res));
  } catch (errors) {
    dispatch(fetchFeatureGroupAsync.failure([errors as FeatureGroupStatus]));
  }
};

export const fetchFeatureGroupSearch = (type: string, id?: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchFeatureGroupSearchAsync.request());
    const res = await ApiFetchFeatureGroupSearch(apiClient, type, id);
    dispatch(fetchFeatureGroupSearchAsync.success(res));
  } catch (errors) {
    dispatch(fetchFeatureGroupSearchAsync.failure([errors as FeatureGroupStatus]));
  }
};

export const postFeatureGroup = (data: FeatureGroup): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(postFeatureGroupAsync.request());
    const res = await ApiPostFeatureGroup(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(postFeatureGroupAsync.success(res));
    if (res.id) {
      dispatch(pushLocation(`/internal/featureGroup/${res.id}`));
    }
  } catch (errors) {
    dispatch(postFeatureGroupAsync.failure([errors as FeatureGroupStatus]));
  }
};

export const putFeatureGroup = (data: FeatureGroup): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(putFeatureGroupAsync.request());
    const res = await ApiPutFeatureGroup(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putFeatureGroupAsync.success(res));
  } catch (errors) {
    dispatch(putFeatureGroupAsync.failure([errors as FeatureGroupStatus]));
  }
};

export const fetchFeatureGroupObjectTypes = (): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchFeatureGroupObjectTypesAsync.request());
    const res = await ApiFetchFeatureGroupObjectTypes(apiClient);
    dispatch(fetchFeatureGroupObjectTypesAsync.success(res));
  } catch (errors) {
    dispatch(fetchFeatureGroupObjectTypesAsync.failure([errors as FeatureGroupStatus]));
  }
};

/** Get Feature Group data for Representative name */
export const fetchRepFeatureGroup = (management: string, type: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchRepFeatureGroupAsync.request());
    const param = { management, type };
    const res = await ApiFetchFindByObjectTypeAndManagement(apiClient, param);
    dispatch(fetchRepFeatureGroupAsync.success(res));
  } catch (errors) {
    dispatch(fetchRepFeatureGroupAsync.failure([errors as FeatureGroupStatus]));
  }
};
