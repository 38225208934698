import React, { ReactChild, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { getCurrency, getIsGlobalCurrency } from '../selectors';
import { getCurrencySign, getCurrencyPatternWithFlag } from '../utils/l10n';
export type Props = {
  currency?: string;
  children: ReactChild;
  isGlobalCurrency?: boolean;
  isPositive?: boolean;
  currencyClassName?: string;
  isJPYAlternative?: boolean;
};
export const DEFAULT_CURRENCY = 'JPY';

/** This replaceJSX function ensures to help the getL10nMoneyField function children prop
 * to return as React.ReactNode and not return to null or undefined
 */
const replaceWithJSX = (format: string, amount: ReactNode, sign: string): ReactNode[] => {
  return format.split(/({amount}|{sign})/).reduce((result, item) => {
    if (item === '{amount}') {
      result.push(amount);
    } else if (item === '{sign}') {
      result.push(sign);
    } else if (item) {
      result.push(item);
    }
    return result;
  }, [] as ReactNode[]);
};
export function getL10nMoneyField(
  currencyClassName: string = '',
  children: ReactChild,
  currency = DEFAULT_CURRENCY,
  isGlobalCurrency: boolean = true,
  isPositive: boolean = true,
  isJPYAlternative: boolean = true,
) {
  const pattern = getCurrencyPatternWithFlag(currency, isJPYAlternative, isGlobalCurrency);
  const sign = getCurrencySign(currency, isGlobalCurrency);
  if (isPositive) {
    return replaceWithJSX(pattern.positive, children, sign);
  }

  return replaceWithJSX(pattern.negative, children, sign);
}
function L10nMoneyField({
  children,
  currencyClassName,
  currency,
  isGlobalCurrency,
  isPositive,
  isJPYAlternative = true,
}: Props) {
  const currencyFromStore = useSelector(getCurrency) || '';
  const shouldUseGlobalCurrency = useSelector(getIsGlobalCurrency) || isGlobalCurrency;
  return (
    <>
      {getL10nMoneyField(
        currencyClassName,
        children,
        currency || currencyFromStore,
        shouldUseGlobalCurrency,
        isPositive,
        isJPYAlternative,
      )}
    </>
  );
}
export default L10nMoneyField;
