import { SalesPromotionList, SalesPromotion, ApiStatus } from 'SalesPromotionList-Types';
import { ApiClient } from '@travel/api-client';
import providerPrefix from './../../constants/api-prefix';

export const ApiFetchSalesPromotions = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<SalesPromotionList> =>
  http
    .get(`${providerPrefix}/${providerId}/promotions`, { values: params })
    .then((data: SalesPromotionList) => data);

export const ApiFetchSalesPromotionDetail = (
  http: ApiClient,
  providerId: string,
  promotionId: string,
): Promise<SalesPromotion> =>
  http
    .get(`${providerPrefix}/${providerId}/promotions/${promotionId}`)
    .then((data: SalesPromotion) => data);

export const ApiFetchDeletablePromotion = (
  http: ApiClient,
  providerId: string,
  promotionId: string,
): Promise<ApiStatus> =>
  http
    .get(`${providerPrefix}/${providerId}/promotions/${promotionId}/deletable`, {
      ignoreCommonErrorHandler: true,
    })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiAddSalesPromotion = (
  http: ApiClient,
  providerId: string,
  data: SalesPromotion,
): Promise<ApiStatus> =>
  http
    .post(`${providerPrefix}/${providerId}/promotions`, {
      values: { providerId: providerId, ...data },
    })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiUpdateSalesPromotion = (
  http: ApiClient,
  providerId: string,
  data: SalesPromotion,
): Promise<ApiStatus> =>
  http
    .put(`${providerPrefix}/${providerId}/promotions/${data.id}`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const ApiDeleteSalesPromotion = (
  http: ApiClient,
  providerId: string,
  promotionId: string,
): Promise<ApiStatus> =>
  http
    .delete(`${providerPrefix}/${providerId}/promotions/${promotionId}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
