import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { RatePlanDistributionDetail_UI } from 'RatePlanDistribution-Types';
import { setSucceededRatePlanDistribution, createRatePlanDistributionAsync } from './actions';

export const isCreating = createReducer(false)
  .handleAction(createRatePlanDistributionAsync.success, () => true)
  .handleAction(
    [createRatePlanDistributionAsync.success, createRatePlanDistributionAsync.failure],
    () => false,
  );
export const succeeded = createReducer({} as RatePlanDistributionDetail_UI).handleAction(
  setSucceededRatePlanDistribution,
  (state, action) => action.payload,
);

const ratePlanDistributionReducer = combineReducers({
  succeeded,
  isCreating,
});

export default ratePlanDistributionReducer;
