import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Providers as ProvidersType, ProvidersErrors, ProviderDetail } from 'Providers-Types';
import {
  fetchProvidersAsync,
  updateSearchText,
  fetchProviderAsync,
  clearProvider,
  postProviderAsync,
  putProviderAsync,
  updateCurrencyIsChangedFlag,
} from './actions';
import { defaultProviderDetail } from './initialValues';

export const isCreating = createReducer(false)
  .handleAction([postProviderAsync.request, putProviderAsync.request], () => true)
  .handleAction(
    [
      postProviderAsync.success,
      postProviderAsync.failure,
      putProviderAsync.success,
      putProviderAsync.failure,
    ],
    () => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchProvidersAsync.request, fetchProviderAsync.request], (state, action) => true)
  .handleAction(
    [
      fetchProvidersAsync.success,
      fetchProvidersAsync.failure,
      fetchProviderAsync.success,
      fetchProviderAsync.failure,
    ],
    (state, action) => false,
  );

export const providers = createReducer({} as ProvidersType).handleAction(
  fetchProvidersAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;
    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const provider = createReducer(defaultProviderDetail as ProviderDetail)
  .handleAction(fetchProviderAsync.success, (state, action) => action.payload)
  .handleAction(clearProvider, (state, action) => defaultProviderDetail);

export const errors = createReducer([] as ProvidersErrors[]).handleAction(
  fetchProvidersAsync.failure,
  (state, action) => action.payload,
);

export const searchText = createReducer('').handleAction(
  updateSearchText,
  (state, action) => action.payload,
);

export const isCurrencyChanged = createReducer(false).handleAction(
  updateCurrencyIsChangedFlag,
  (state, action) => action.payload,
);

const providersReducer = combineReducers({
  isFetching,
  providers,
  provider,
  errors,
  searchText,
  isCreating,
  isCurrencyChanged,
});

export default providersReducer;
export type ProvidersState = ReturnType<typeof providersReducer>;
