import { APPLICABLE_SETTINGS_OPTIONS } from 'constants/promotion';

export const defaultPromotionDetail = {
  id: '',
  active: false,
  analytics: null,
  name: '',
  representativeFeature: null,
  privateTitle: null,
  code: '',
  status: '',
  currency: '',
  timeZone: '',
  description: '',
  designations: [],
  effectivePeriod: null,
  type: '',
  incentive: null,
  offer: {},
  searchCondition: {},
  paymentMethods: null, // Needs to be null to allow for form to be dirty on mount
  enableBinInput: null,
  binNumbers: null,
  promotionCap: {},
  promotionBudget: {},
  applicablePlans: {},
  applyToAllProviders: true,
  applicableSettings: APPLICABLE_SETTINGS_OPTIONS.NONE,
  isDeleteAllProviderOrRatePlans: false,
  updateDateTime: '',
  createDateTime: '',
  userCondition: {},
  platformCondition: {},
  ancestors: [],
  participationExclusive: false,
};

export const defaultPromotionDeletable = {
  message: '',
  statusCode: null,
};

export const defaultDeletableDialog = {
  isShow: false,
};
