import { ApiClient } from '@travel/api-client';
import { internalPrefix } from 'constants/api-prefix';
import { DiagnosisAvailability, DiagnosisRequestBody } from 'Diagnosis-Types';

export const ApiCreateDiagnosisAvailability = (
  http: ApiClient,
  data: DiagnosisRequestBody,
): Promise<DiagnosisAvailability> => {
  return http.post(`${internalPrefix}/diagnosis/availability`, { values: data });
};
