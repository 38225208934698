import { Page, QueryParameter, LabelBody, DownLoadParameter } from 'LabelManagement-Types';
import { ApiClient } from '@travel/api-client';

export const postPage = (http: ApiClient, data: Page) =>
  http.post('/world-api/pages', { values: data });

export const getPage = (http: ApiClient, pageId: string) => http.get(`/world-api/pages/${pageId}`);

export const putPage = (http: ApiClient, data: Page, pageId: string) =>
  http.put(`/world-api/pages/${pageId}`, {
    values: data,
  });

export const getPages = (http: ApiClient, query: QueryParameter) =>
  http.get('/world-api/pages', { values: { ...query, limit: 30 } });

export const getAllPages = (http: ApiClient) =>
  http.get('/world-api/pages', { values: { limit: 1000 } });

export const getLabels = (http: ApiClient, pageId: string, query: QueryParameter) =>
  http.get(`/world-api/pages/${pageId}/labels`, {
    values: { ...query, limit: 30 },
  });

export const getLabel = (http: ApiClient, pageId: string, labelId: string) =>
  http.get(`/world-api/pages/${pageId}/labels/${labelId}`);

export const postLabel = (http: ApiClient, data: LabelBody, pageId: string) =>
  http.post(`/world-api/pages/${pageId}/labels`, {
    values: data,
  });

export const putLabel = (
  http: ApiClient,
  data: LabelBody,
  pageId: string,
  labelId: string | null,
) => {
  return http.put(`/world-api/pages/${pageId}/labels/${labelId}`, {
    values: data,
  });
};

export const ApiDownloadLabel = (http: ApiClient, data: DownLoadParameter) =>
  http.post('/world-api/translations/download', {
    values: data,
  });

export const ApiUploadLabel = (http: ApiClient, translation: FormData) =>
  http.post('/world-api/translations/upload', {
    values: translation,
    ignoreCommonErrorHandler: true,
  });
