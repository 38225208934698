import {
  VisualContentGroupList,
  VisualContentGroup,
  VisualContentGroupStatus,
} from 'VisualContentGroup-Types';
import { ApiClient } from '@travel/api-client';
import { internalPrefix, providerPrefix } from 'constants/api-prefix';

export const ApiFetchVisualContentGroupList = (
  http: ApiClient,
  params: Object,
  providerId: string,
): Promise<VisualContentGroupList> => {
  const url = providerId
    ? `${providerPrefix}/${providerId}/mediumGroups`
    : `${internalPrefix}/mediumGroups`;
  return http.get(url, { values: params }).then((data: VisualContentGroupList) => data);
};

export const ApiFetchVisualContentGroup = (
  http: ApiClient,
  visualContentGroupId: string,
  providerId: string,
): Promise<VisualContentGroup> => {
  const url = providerId
    ? `${providerPrefix}/${providerId}/mediumGroups/${visualContentGroupId}`
    : `${internalPrefix}/mediumGroups/${visualContentGroupId}`;
  return http.get(url).then((data: VisualContentGroup) => data);
};

export const ApiPostVisualContentGroup = (
  http: ApiClient,
  providerId: string,
  data: VisualContentGroup,
): Promise<VisualContentGroupStatus> => {
  const url = providerId
    ? `${providerPrefix}/${providerId}/mediumGroups`
    : `${internalPrefix}/mediumGroups`;
  return http
    .post(url, { values: data })
    .then((data: VisualContentGroupStatus) => data)
    .catch((data: VisualContentGroupStatus) => data);
};

export const ApiPutVisualContentGroup = (
  http: ApiClient,
  providerId: string,
  data: VisualContentGroup,
): Promise<VisualContentGroupStatus> => {
  const url = providerId
    ? `${providerPrefix}/${providerId}/mediumGroups/${data.id}`
    : `${internalPrefix}/mediumGroups/${data.id}`;
  return http
    .put(url, { values: data })
    .then((data: VisualContentGroupStatus) => data)
    .catch((data: VisualContentGroupStatus) => data);
};

export const ApiDeleteVisualContentGroup = (
  http: ApiClient,
  providerId: string,
  id?: string,
): Promise<VisualContentGroupStatus> => {
  const url = providerId
    ? `${providerPrefix}/${providerId}/mediumGroups/${id}`
    : `${internalPrefix}/mediumGroups/${id}`;
  return http
    .delete(url, {})
    .then((data: VisualContentGroupStatus) => data)
    .catch((data: VisualContentGroupStatus) => data);
};
