import { ApiClient } from '@travel/api-client';
import { DownLoadParameter } from 'ManualTranslation-Types';

export const getDataTypeApi = (http: ApiClient) => http.get('/world-api/supplier/dataTypes');

export const downloadThermsApi = (http: ApiClient, filter: DownLoadParameter) =>
  http.post('/world-api/supplier/translations/download', { values: filter });

export const uploadThermsApi = (http: ApiClient, translation: FormData) =>
  http.post('/world-api/supplier/translations/upload', {
    values: translation,
    ignoreCommonErrorHandler: true,
  });
