import React, { ReactNode } from 'react';
import { checkboxOptionGroup } from 'Layout-Types';
import { Translate } from '@travel/translation';
import {
  BusinessNew,
  FamilyNew,
  FriendsNew,
  CoupleNew,
  SoloNew,
  OthersNew,
} from '@travel/icons/service';

export const initialSortValue = {
  options: [
    {
      text: <Translate id="Supplier_Enumeration.Sort.Published_Date.New_Old" />,
      value: 'MOST_RECENT',
    },
    {
      text: <Translate id="Supplier_Enumeration.Sort.Published_Date.Old_New" />,
      value: 'OLDEST',
    },
  ],
  selectedValue: 'MOST_RECENT',
};

export const initialFilterValue = [
  {
    label: <Translate id="review_management.customer_review_list.filter.status" />,
    id: 'replyStatus',
    options: [
      {
        label: <Translate id="Supplier_Enumeration.Review.Management_Status.Replied" />,
        value: 'REPLIED',
        isCheck: true,
      },
      {
        label: <Translate id="Supplier_Enumeration.Review.Management_Status.Unreplied" />,
        value: 'UNREPLIED',
        isCheck: true,
      },
    ],
  },
] as Array<checkboxOptionGroup>;

export const REVIEW_STATUS_COLOR: {
  [key: string]: 'purple' | 'red' | 'yellow' | 'green' | 'gray';
} = {
  REPLIED: 'green',
  UNREPLIED: 'red',
};

export const RATING_TYPE: { [key: string]: ReactNode } = {
  EXCELLENT: (
    <>
      5 <Translate id="review_management.customer_review_list.composition.point" count={5} />
    </>
  ),
  GOOD: (
    <>
      4 <Translate id="review_management.customer_review_list.composition.point" count={4} />
    </>
  ),
  AVERAGE: (
    <>
      3 <Translate id="review_management.customer_review_list.composition.point" count={3} />
    </>
  ),
  POOR: (
    <>
      2 <Translate id="review_management.customer_review_list.composition.point" count={2} />
    </>
  ),
  TERRIBLE: (
    <>
      1 <Translate id="review_management.customer_review_list.composition.point" count={1} />
    </>
  ),
};

export const VISIBLE_STATUS = {
  DISPLAYED: 'DISPLAYED',
  HIDDEN: 'HIDDEN',
};

export const REVIEW_VISIBLE_STATUS_COLOR = {
  [VISIBLE_STATUS.DISPLAYED]: 'green',
  [VISIBLE_STATUS.HIDDEN]: 'red',
} as const;

export const REVIEW_STATUS = [
  {
    label: <Translate id="Supplier_Enumeration.Status.Display.Displayed" />,
    value: VISIBLE_STATUS.DISPLAYED,
    name: `status`,
  },
  {
    label: <Translate id="Supplier_Enumeration.Status.Display.Hidden" />,
    value: VISIBLE_STATUS.HIDDEN,
    name: `status`,
  },
];

export const TRAVEL_TYPE_ICONS: {
  [key: string]: ReactNode;
} = {
  BUSINESS: <BusinessNew />,
  FAMILY: <FamilyNew />,
  FRIENDS: <FriendsNew />,
  COUPLE: <CoupleNew />,
  SOLO: <SoloNew />,
  OTHERS: <OthersNew />,
};

export const TRAVEL_TYPES: {
  [key: string]: ReactNode;
} = {
  BUSINESS: <Translate id="Supplier_Enumeration.Review.Type_Of_Travel.Business" />,
  FAMILY: <Translate id="Supplier_Enumeration.Review.Type_Of_Travel.Family" />,
  FRIENDS: <Translate id="Supplier_Enumeration.Review.Type_Of_Travel.Friends" />,
  COUPLE: <Translate id="Supplier_Enumeration.Review.Type_Of_Travel.Couple" />,
  SOLO: <Translate id="Supplier_Enumeration.Review.Type_Of_Travel.Solo" />,
  OTHERS: <Translate id="Supplier_Enumeration.Review.Type_Of_Travel.Others" />,
};

export const AGE_RANGE: {
  [key: string]: ReactNode;
} = {
  10: <Translate id="Supplier_Enumeration.Review.Age Range.10" />,
  20: <Translate id="Supplier_Enumeration.Review.Age Range.20" />,
  30: <Translate id="Supplier_Enumeration.Review.Age Range.30" />,
  40: <Translate id="Supplier_Enumeration.Review.Age Range.40" />,
  50: <Translate id="Supplier_Enumeration.Review.Age Range.50" />,
  60: <Translate id="Supplier_Enumeration.Review.Age Range.60" />,
  70: <Translate id="Supplier_Enumeration.Review.Age Range.70" />,
  80: <Translate id="Supplier_Enumeration.Review.Age Range.80" />,
};

export const MAX_DATE_RANGE_MANAGEMENT = 730;
export const MAX_DATE_RANGE_EXAMINATION = 30;

export const REVIEW_EVALUATIONS = {
  LOCATION: 'LOCATION',
  FACILITY: 'FACILITY',
  SERVICE_STAFF: 'SERVICE_STAFF',
  COMFORT_CLEANLINESS: 'COMFORT_CLEANLINESS',
  VALUE_FOR_MONEY: 'VALUE_FOR_MONEY',
  BATH: 'BATH',
  MEAL: 'MEAL',
};

export const REVIEW_SOURCE: { [key: string]: ReactNode } = {
  PREMIUM: <Translate id="Supplier_Enumeration.Review.Source.Premium_Club" />,
  INBOUND: <Translate id="Supplier_Enumeration.Review.Source.Inbound_Site" />,
  DOMESTIC: <Translate id="Supplier_Enumeration.Review.Source.Domestic_Site" />,
};

export const REVIEW_SOURCE_VALUES = {
  DOMESTIC: 'DOMESTIC',
  INBOUND: 'INBOUND',
  PREMIUM: 'PREMIUM',
};
