import { ApiClient } from '@travel/api-client';

import { MediaItem, MediaList, MediaStatus, MoveRequest, NewMedia, RequestForm } from 'Media-Types';

import { internalPrefix, providerPrefix } from 'constants/api-prefix';

export const ApiFetchMediaList = (
  http: ApiClient,
  providerId: string,
  params: Object,
  isInternal: boolean = false,
): Promise<MediaList> => {
  const url =
    !isInternal && providerId ? `${providerPrefix}/${providerId}/media` : `${internalPrefix}/media`;
  return http.get(url, { values: params }).then((data: MediaList) => data);
};

export const ApiFetchMediaDetail = (
  http: ApiClient,
  providerId: string,
  mediaId: string,
): Promise<MediaItem> => {
  const url = providerId
    ? `${providerPrefix}/${providerId}/media/${mediaId}`
    : `${internalPrefix}/media/${mediaId}`;
  return http.get(url).then((data: MediaItem) => data);
};

export const ApiSubmitNewMedia = (
  http: ApiClient,
  providerId: string,
  data: RequestForm,
): Promise<MediaStatus> => {
  const url = providerId ? `${providerPrefix}/${providerId}/media` : `${internalPrefix}/media`;
  return http
    .post(url, { values: data })
    .then((ret: MediaStatus) => ret)
    .catch((data: MediaStatus) => data);
};

export const ApiUploadNewMedia = (http: ApiClient, formData: FormData): Promise<NewMedia> => {
  return http
    .post(`/media-api/media`, { values: formData, timeout: 120000 })
    .then((data: NewMedia) => data);
};

export const ApiUpdateMedia = (
  http: ApiClient,
  providerId: string,
  data: RequestForm,
  mediaId: string,
): Promise<MediaStatus> => {
  const url = providerId
    ? `${providerPrefix}/${providerId}/media/${mediaId}`
    : `${internalPrefix}/media/${mediaId}`;
  return http
    .put(url, { values: data })
    .then((data: MediaStatus) => data)
    .catch((data: MediaStatus) => data);
};

export const FetchDeletableMedia = (
  http: ApiClient,
  providerId: string,
  mediaId: string,
): Promise<MediaStatus> => {
  const url = providerId
    ? `${providerPrefix}/${providerId}/media/${mediaId}/deletable`
    : `${internalPrefix}/media/${mediaId}/deletable`;
  return http
    .get(url, { ignoreCommonErrorHandler: true })
    .then((data: MediaStatus) => data)
    .catch((data: MediaStatus) => data);
};

export const ApiDeleteMedia = (
  http: ApiClient,
  providerId: string,
  mediaId: string,
): Promise<MediaStatus> => {
  const url = providerId
    ? `${providerPrefix}/${providerId}/media/${mediaId}`
    : `${internalPrefix}/media/${mediaId}`;
  return http
    .delete(url, {})
    .then((data: MediaStatus) => data)
    .catch((data: MediaStatus) => data);
};

export const ApiMoveMedia = (
  http: ApiClient,
  providerId: string,
  data: MoveRequest,
): Promise<MediaStatus> => {
  const url = `${providerPrefix}/${providerId}/media/move`;
  return http
    .post(url, { values: data })
    .then((data: MediaStatus) => data)
    .catch((data: MediaStatus) => data);
};
