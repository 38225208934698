import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ProviderRestriction, ProviderRestrictionsError } from 'ProviderRestrictions-Types';
import {
  fetchProviderRestrictionsAsync,
  fetchProviderRestrictionCsvDownloadAsync,
  fetchProviderRestrictionDetailAsync,
  uploadProviderRestrictionCsvAsync,
} from './actions';

const providerRestrictions = createReducer([] as ProviderRestriction[]).handleAction(
  fetchProviderRestrictionsAsync.success,
  (state, action) => action.payload,
);

const providerRestrictionDetail = createReducer({} as ProviderRestriction).handleAction(
  fetchProviderRestrictionDetailAsync.success,
  (state, action) => action.payload,
);

const providerRestrictionCsvDownload = createReducer({} as Blob).handleAction(
  fetchProviderRestrictionCsvDownloadAsync.success,
  (state, action) => action.payload,
);

const isProviderRestrictionCsvDownloadFetching = createReducer(false)
  .handleAction(fetchProviderRestrictionCsvDownloadAsync.request, () => true)
  .handleAction(
    [
      fetchProviderRestrictionCsvDownloadAsync.success,
      fetchProviderRestrictionCsvDownloadAsync.failure,
    ],
    () => false,
  );

const isProviderRestrictionCsvUploadSending = createReducer(false)
  .handleAction(uploadProviderRestrictionCsvAsync.request, () => true)
  .handleAction(
    [uploadProviderRestrictionCsvAsync.success, uploadProviderRestrictionCsvAsync.failure],
    () => false,
  );

const providerRestrictionError = createReducer({} as ProviderRestrictionsError).handleAction(
  [
    fetchProviderRestrictionsAsync.failure,
    fetchProviderRestrictionDetailAsync.failure,
    fetchProviderRestrictionCsvDownloadAsync.failure,
  ],
  (state, action) => action.payload,
);

const providerRestrictionsReducer = combineReducers({
  providerRestrictions,
  providerRestrictionDetail,
  providerRestrictionError,
  providerRestrictionCsvDownload,
  isProviderRestrictionCsvDownloadFetching,
  isProviderRestrictionCsvUploadSending,
});

export default providerRestrictionsReducer;
export type ProviderRestrictionsState = ReturnType<typeof providerRestrictionsReducer>;
