import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { QrCodeGeneratorErrors } from 'QrCodeGenerator-Types';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { ApiFetchQrCode } from './apis';

export const fetchQrCodeGeneratorAsync = createAsyncAction(
  'FETCH_QRCODEGENERATOR_REQUEST',
  'FETCH_QRCODEGENERATOR_SUCCESS',
  'FETCH_QRCODEGENERATOR_FAILURE',
)<undefined, undefined, QrCodeGeneratorErrors[]>();

export const fetchQrCodeGenerator = (
  bookingId: string,
  goToLocalCouponToken: string,
): AppThunk<Promise<string | undefined>> => async (dispatch, getState, { apiClient }) => {
  const TRAVEL_AGENT_ID = 3;
  const data = `${TRAVEL_AGENT_ID},${bookingId},${goToLocalCouponToken}`;
  const query = {
    data,
    size: 2,
    version: 8,
    errorCorrectLevel: 'M',
    qrcodeEncodeMode: 'B',
  };

  dispatch(fetchQrCodeGeneratorAsync.request());
  try {
    const res = await ApiFetchQrCode(apiClient, query);
    const blob = await res?.blob?.();
    const url = window.URL.createObjectURL(new Blob([blob], { type: 'image/png' }));
    dispatch(fetchQrCodeGeneratorAsync.success());
    return url;
  } catch (error) {
    dispatch(fetchQrCodeGeneratorAsync.failure(error));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};
