import { universalRouterProps } from 'core/universalRouter/types';
import { fetchProviderCampaignList } from 'store/providerCampaign/actions';

export default (
  { store: { dispatch } }: universalRouterProps,
  { providerId }: { providerId: string },
  query: { [key: string]: string },
) => {
  return Promise.all([dispatch(fetchProviderCampaignList(providerId, query))]);
};
