import { ApiClient } from '@travel/api-client';
import { stringify } from '@travel/utils';

import { providerPrefix } from 'constants/api-prefix';
import {
  ApiStatus,
  Provider,
  ProviderBasicInfo,
  SelectProvider,
  SelectProvider2,
} from 'ProvidersInformation-Types';
import {
  ProviderCsv2SearchFilters,
  ProviderCsvRspSearchFilters,
} from '../../components/SelectProviderOption/types';

export const ApiFetchProviderBasicInfo = (
  http: ApiClient,
  providerId: string,
): Promise<ProviderBasicInfo> =>
  http.get(`/provider-api/extranet/external/providers/${providerId}/basicInfo`);

export const fetchProvider = (http: ApiClient, id: string): Promise<Provider> =>
  http.get(`/provider-api/extranet/external/providers/${id}`);

export const updateProvider = (http: ApiClient, id: string, data: Provider): Promise<ApiStatus> =>
  http
    .put(`/provider-api/extranet/external/providers/${id}`, { values: data })
    .catch((data: SelectProvider) => data);

export const ApiPostSelectionProvider = (
  http: ApiClient,
  providerId: Array<string>,
  queryParameter: Object,
): Promise<SelectProvider> => {
  const url = `${providerPrefix}/selection/searchByProviderIds?${stringify(queryParameter)}`;

  return http
    .post(url, { values: providerId })
    .then((data: SelectProvider) => data)
    .catch((data: SelectProvider) => data);
};

export const ApiPostSelectionProviderSearchCsv = (
  http: ApiClient,
  formData: FormData,
  queryParameter: Object,
): Promise<SelectProvider> => {
  const url = `${providerPrefix}/selection/searchByCsv?${stringify(queryParameter)}`;

  return http
    .post(url, { values: formData })
    .then((data: SelectProvider) => data)
    .catch((data: SelectProvider) => data);
};

export const ApiPostSelectionProviderSearchCsv2 = (
  http: ApiClient,
  formData: FormData,
  queryParameter?: ProviderCsv2SearchFilters,
): Promise<SelectProvider2> => {
  const url = `${providerPrefix}/selection/searchByCsv2${
    queryParameter ? `?${stringify(queryParameter)}` : ''
  }`;

  return http
    .post(url, { values: formData, timeout: 60 * 1000 })
    .then((data: SelectProvider2) => data)
    .catch((data: SelectProvider2) => data);
};

export const ApiPostSelectionProviderSearchCsvRsp = (
  http: ApiClient,
  formData: FormData,
  queryParameter: ProviderCsvRspSearchFilters,
): Promise<SelectProvider2> => {
  const url = `${providerPrefix}/selection/searchByCsvRsp${
    queryParameter ? `?${stringify(queryParameter)}` : ''
  }`;

  return http
    .post(url, { values: formData, timeout: 60 * 1000 })
    .then((data: SelectProvider2) => data)
    .catch((data: SelectProvider2) => data);
};

export const ApiFetchSelectionProviderSearchByConditions = (
  http: ApiClient,
  queryParameter: Object,
): Promise<SelectProvider> => {
  return http
    .get(`${providerPrefix}/selection/searchByConditions?`, { values: queryParameter })
    .then((data: SelectProvider) => data)
    .catch((data: SelectProvider) => data);
};
