import React, { useState, useEffect, ReactNode } from 'react';
import { useLocation } from './useLocation';
import { History, Location } from 'history';

type Props = {
  router: { resolve: (location: Location) => Promise<ReactNode> };
  history: History;
  children: ReactNode;
};

function Router(props: Props) {
  const { router, history, children } = props;
  const location = useLocation(history);
  const [Component, setComponent] = useState(children);
  const [isFirstRendering, setIsFirstRendering] = useState(true);
  useEffect(() => {
    if (isFirstRendering) {
      setIsFirstRendering(false);
      return;
    }
    let cancel = false;
    let unlisten = history.listen(() => {
      cancel = true;
    });
    router.resolve(location).then((NextComponent: ReactNode) => {
      if (!cancel) setComponent(NextComponent);
      unlisten();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return <>{Component}</>;
}

export default Router;
