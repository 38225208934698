import { SEARCH_CONDITIONS } from 'constants/searchConditions';
import { PlaceCategory, PlaceListQueryParams, PlaceSimple, PlaceType } from 'Place-Types';

export const defaultPlaceDetail = {
  active: true,
  ancestors: [] as Array<PlaceSimple>,
  category: '' as PlaceCategory,
  country: '',
  description: '',
  id: '',
  latitude: 0,
  longitude: 0,
  media: [],
  name: '',
  parentId: '',
  subPlaces: {
    contents: [],
    limit: 0,
    offset: 0,
    total: 0,
  },
  type: 'AREA' as PlaceType,
  url: '',
  zoomLevel: 0,
  providers: null,
  seoIndex: false,
};

export const defaultCategory = [
  {
    name: 'World_Region',
    value: 'WORLD_REGION',
    type: 'AREA',
  },
  {
    name: 'Country',
    value: 'COUNTRY',
    type: 'AREA',
  },
  {
    name: 'Subdivision',
    value: 'SUBDIVISION',
    type: 'AREA',
  },
  {
    name: 'City',
    value: 'CITY',
    type: 'AREA',
  },
  {
    name: 'District',
    value: 'DISTRICT',
    type: 'AREA',
  },
  // {
  //   name: 'None',
  //   value: 'NONE',
  //   type: 'AREA',
  // },

  {
    name: 'Provider',
    value: 'PROVIDER',
    type: 'SPOT',
  },
  {
    name: 'Train_Station',
    value: 'TRAIN_STATION',
    type: 'SPOT',
  },
  {
    name: 'Airport',
    value: 'AIRPORT',
    type: 'SPOT',
  },
  {
    name: 'Point_Of_Interest',
    value: 'POINT_OF_INTEREST',
    type: 'SPOT',
  },
  {
    name: 'Bus_Stop',
    value: 'BUS_STOP',
    type: 'SPOT',
  },
  {
    name: 'Seaport',
    value: 'SEAPORT',
    type: 'SPOT',
  },
  {
    name: 'Island',
    value: 'ISLAND',
    type: 'SPOT',
  },
  {
    name: 'Hot_Spring',
    value: 'HOT_SPRING',
    type: 'SPOT',
  },
  {
    name: 'None',
    value: 'NONE',
    type: 'SPOT',
  },
  {
    name: 'Enclosed_Spot',
    value: 'Enclosed_Spot',
    type: 'ENCLOSED_SPOT',
  },
];

export const initFilterState: PlaceListQueryParams = {
  limit: 30,
  offset: 0,
  parentPlaceId: '',
  keyword: '',
  searchCondition: SEARCH_CONDITIONS[0].value,
  filter: {
    category: [],
    children: 'ALL',
    type: [],
  },
  sort: {
    updateDate: 'desc',
  },
};
