import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { PlaceCategoryOption, PlaceDetail, PlaceErrors, PlaceSimple, SEOError } from 'Place-Types';
import { defaultPlaceDetail } from './initialValues';

import {
  clearPlaceDetail,
  clearPlaceList,
  clearSEOError,
  downloadSEOMgmtCSVFileAsync,
  fetchAreaPlacesAsync,
  fetchCountriesAsync,
  fetchPlacesAsync,
  fetchPlaceCategoriesAsync,
  fetchPlaceDetailAsync,
  fetchPlaceLayerOptionsAsync,
  postPlaceAsync,
  putPlaceAsync,
  updatePlaceLayers,
  uploadSEOMgmtCSVFileAsync,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchPlacesAsync.request], (state, action) => true)
  .handleAction([fetchPlacesAsync.success, fetchPlacesAsync.failure], (state, action) => false);

export const isCreating = createReducer(false)
  .handleAction([postPlaceAsync.request, putPlaceAsync.request], () => true)
  .handleAction(
    [postPlaceAsync.success, postPlaceAsync.failure, putPlaceAsync.success, putPlaceAsync.failure],
    () => false,
  );

export const isUploadingSEOMgmtFile = createReducer(false as boolean)
  .handleAction([uploadSEOMgmtCSVFileAsync.request], () => true)
  .handleAction(
    [uploadSEOMgmtCSVFileAsync.success, uploadSEOMgmtCSVFileAsync.failure],
    () => false,
  );

export const isDownloadingSEOMgmtFile = createReducer(false as boolean)
  .handleAction([downloadSEOMgmtCSVFileAsync.request], () => true)
  .handleAction(
    [downloadSEOMgmtCSVFileAsync.success, downloadSEOMgmtCSVFileAsync.failure],
    () => false,
  );

export const placeLayers = createReducer([] as PlaceSimple[]).handleAction(
  updatePlaceLayers,
  (state, action) => action.payload,
);
export const placeLayerOptions = createReducer([] as PlaceSimple[][]).handleAction(
  fetchPlaceLayerOptionsAsync.success,
  (state, action) => action.payload,
);

export const placeList = createReducer({} as PlaceDetail)
  .handleAction(clearPlaceList, () => ({} as PlaceDetail))
  .handleAction(fetchPlacesAsync.success, (state, action) => {
    const shouldConcat =
      action.payload.parentId === state.parentId &&
      action.payload.subPlaces?.offset > state.subPlaces.offset;

    return {
      ...action.payload,
      subPlaces: {
        ...action.payload.subPlaces,
        contents:
          state.subPlaces?.contents && shouldConcat
            ? state.subPlaces.contents.concat(action.payload.subPlaces.contents)
            : action.payload.subPlaces.contents,
      },
    };
  });

export const countryList = createReducer({} as PlaceDetail).handleAction(
  fetchCountriesAsync.success,
  (state, action) => action.payload,
);

export const areaPlaceList = createReducer({} as PlaceDetail).handleAction(
  fetchAreaPlacesAsync.success,
  (state, action) => action.payload,
);

export const placeCategories = createReducer([] as PlaceCategoryOption[]).handleAction(
  fetchPlaceCategoriesAsync.success,
  (state, action) => action.payload,
);

export const placeDetail = createReducer(defaultPlaceDetail as PlaceDetail)
  .handleAction(clearPlaceDetail, (state, action) => defaultPlaceDetail)
  .handleAction(fetchPlaceDetailAsync.success, (state, action) => transformToUI(action.payload))
  .handleAction(putPlaceAsync.success, (state, action) => transformToUI(action.payload));

export const placeDetailAncestors = createReducer([] as PlaceSimple[]).handleAction(
  fetchPlaceDetailAsync.success,
  (state, action) => action.payload.ancestors,
);

export const errors = createReducer([] as PlaceErrors[]).handleAction(
  fetchPlacesAsync.failure,
  (state, action) => action.payload,
);

export const seoErrors = createReducer({} as SEOError)
  .handleAction(
    [uploadSEOMgmtCSVFileAsync.failure, uploadSEOMgmtCSVFileAsync.success],
    (state, action) => action.payload,
  )
  .handleAction(clearSEOError, () => ({} as SEOError));

const placesReducer = combineReducers({
  isFetching,
  isCreating,
  isUploadingSEOMgmtFile,
  isDownloadingSEOMgmtFile,
  placeLayers,
  placeLayerOptions,
  placeList,
  areaPlaceList,
  placeDetail,
  placeDetailAncestors,
  placeCategories,
  countryList,
  errors,
  seoErrors,
});

export default placesReducer;
export type PlacesState = ReturnType<typeof placesReducer>;

function transformToUI(data: PlaceDetail) {
  /** remove unuse ancestor representing itself */
  const placeId = data.id;
  const ancestors = data.ancestors;
  const newAncestors = ancestors.filter(anc => anc.id !== placeId);

  const rtnData = { ...data, ancestors: newAncestors };
  return rtnData;
}
