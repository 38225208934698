import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ApplicationRatePlanDetail, Error } from 'ApplicationDetail-Types';
import {
  fetchApplicationRatePlanDetailAsync,
  acceptApplicationRatePlanDetailAsync,
  suggestApplicationRatePlanDetailAsync,
  rejectApplicationRatePlanDetailAsync,
} from './ratePlanActions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchApplicationRatePlanDetailAsync.request], (_state, _action) => true)
  .handleAction(
    [fetchApplicationRatePlanDetailAsync.success, fetchApplicationRatePlanDetailAsync.failure],
    (_state, _action) => false,
  );

export const isLoading = createReducer(false as boolean)
  .handleAction(
    [
      acceptApplicationRatePlanDetailAsync.request,
      suggestApplicationRatePlanDetailAsync.request,
      rejectApplicationRatePlanDetailAsync.request,
    ],
    (_state, _action) => true,
  )
  .handleAction(
    [
      acceptApplicationRatePlanDetailAsync.success,
      acceptApplicationRatePlanDetailAsync.failure,
      suggestApplicationRatePlanDetailAsync.success,
      suggestApplicationRatePlanDetailAsync.failure,
      rejectApplicationRatePlanDetailAsync.success,
      rejectApplicationRatePlanDetailAsync.failure,
    ],
    (_state, _action) => false,
  );

export const errors = createReducer([] as Error[]).handleAction(
  fetchApplicationRatePlanDetailAsync.failure,
  (state, action) => action.payload,
);

export const applicationRatePlanDetail = createReducer(
  {} as ApplicationRatePlanDetail,
).handleAction(fetchApplicationRatePlanDetailAsync.success, (state, action) => action.payload);

const applicationRatePlanDetailReducer = combineReducers({
  isFetching,
  isLoading,
  applicationRatePlanDetail,
  errors,
});

export default applicationRatePlanDetailReducer;
export type ApplicationRatePlanDetailState = ReturnType<typeof applicationRatePlanDetailReducer>;
