import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AliasList, Errors, Alias } from 'Aliases-Types';
import { fetchAliasesAsync, fetchAliasAsync, createAliasAsync, updateAliasAsync } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchAliasesAsync.request, fetchAliasAsync.request], (state, action) => true)
  .handleAction(
    [
      fetchAliasesAsync.success,
      fetchAliasesAsync.failure,
      fetchAliasAsync.success,
      fetchAliasAsync.failure,
    ],
    (state, action) => false,
  );

export const isCreating = createReducer(false)
  .handleAction([createAliasAsync.request, updateAliasAsync.request], () => true)
  .handleAction(
    [
      createAliasAsync.success,
      createAliasAsync.failure,
      updateAliasAsync.success,
      updateAliasAsync.failure,
    ],
    () => false,
  );

export const aliasList = createReducer({} as AliasList).handleAction(
  fetchAliasesAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      aliases:
        state.aliases && shouldConcat
          ? state.aliases.concat(action.payload.aliases)
          : action.payload.aliases,
    };
  },
);

export const alias = createReducer({} as Alias).handleAction(
  fetchAliasAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as Errors[]).handleAction(
  [
    fetchAliasesAsync.failure,
    fetchAliasAsync.failure,
    createAliasAsync.failure,
    updateAliasAsync.failure,
  ],
  (state, action) => action.payload,
);

const aliasesReducer = combineReducers({
  isFetching,
  aliasList,
  alias,
  errors,
  isCreating,
});

export default aliasesReducer;
export type AliasesState = ReturnType<typeof aliasesReducer>;
