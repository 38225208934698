import { fetchGroupAccount } from 'store/accessControl/actions';
import { fetchProviderGroupBaseInfo } from 'store/providerGroup/actions';

import { universalRouterProps } from 'core/universalRouter/types';

type Location = {
  params: {
    providerGroupId: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, { params }: Location) => {
  const { providerGroupId } = params;
  const promises = [
    dispatch(fetchProviderGroupBaseInfo(providerGroupId)),
    dispatch(fetchGroupAccount(providerGroupId)),
  ];

  return Promise.all(promises);
};
