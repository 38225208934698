import { createStandardAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import { toDecodedQuery, toQuery } from '@travel/utils';
import { ExtraRateDetail, ExtraRateList, ExtraRateStatus, QueryParameter } from 'ExtraRate-Types';
import { RATE_UNIT } from 'constants/rate-plan';

import {
  ApiFetchExtraRateList,
  ApiFetchExtraRateDetail,
  ApiCreateExtraRateDetail,
  ApiUpdateExtraRateDetail,
  ApiDeleteExtraRateDetail,
} from './apis';
import { pushLocation } from 'store/__router/actions';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

export const clearExtraRateDetail = createStandardAction('CLEAR_EXTRA_RATE_DETAIL')();

export const fetchExtraRateListPerItemAsync = createAsyncAction(
  'FETCH_EXTRA_RATE_LIST_PER_ITEM_REQUEST',
  'FETCH_EXTRA_RATE_LIST_PER_ITEM_SUCCESS',
  'FETCH_EXTRA_RATE_LIST_PER_ITEM_FAULURE',
)<undefined, ExtraRateList, ExtraRateStatus[]>();

export const fetchExtraRateListPerPersonAsync = createAsyncAction(
  'FETCH_EXTRA_RATE_LIST_PER_PERSON_REQUEST',
  'FETCH_EXTRA_RATE_LIST_PER_PERSON_SUCCESS',
  'FETCH_EXTRA_RATE_LIST_PER_PERSON_FAULURE',
)<undefined, ExtraRateList, ExtraRateStatus[]>();

export const fetchExtraRateListAsync = createAsyncAction(
  'FETCH_EXTRA_RATE_LIST_REQUEST',
  'FETCH_EXTRA_RATE_LIST_SUCCESS',
  'FETCH_EXTRA_RATE_LIST_FAILURE',
)<undefined, ExtraRateList, ExtraRateStatus[]>();

export const fetchExtraRateDetailAsync = createAsyncAction(
  'FETCH_EXTRA_RATE_DETAIL_REQUEST',
  'FETCH_EXTRA_RATE_DETAIL_SUCCESS',
  'FETCH_EXTRA_RATE_DETAIL_FAILURE',
)<undefined, ExtraRateDetail, ExtraRateStatus[]>();

export const createExtraRateDetailAsync = createAsyncAction(
  'CREATE_EXTRA_RATE_DETAIL_REQUEST',
  'CREATE_EXTRA_RATE_DETAIL_SUCCESS',
  'CREATE_EXTRA_RATE_DETAIL_FAILURE',
)<undefined, ExtraRateStatus, ExtraRateStatus[]>();

export const updateExtraRateDetailAsync = createAsyncAction(
  'UPDATE_EXTRA_RATE_DETAIL_REQUEST',
  'UPDATE_EXTRA_RATE_DETAIL_SUCCESS',
  'UPDATE_EXTRA_RATE_DETAIL_FAILURE',
)<undefined, ExtraRateStatus, ExtraRateStatus[]>();

export const deleteExtraRateAsync = createAsyncAction(
  'DELETE_EXTRA_RATE_REQUEST',
  'DELETE_EXTRA_RATE_SUCCESS',
  'DELETE_EXTRA_RATE_FAILURE',
)<undefined, ExtraRateStatus, ExtraRateStatus[]>();

export const fetchExtraRateListPerItem = (providerId: string, offset?: number): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchExtraRateListPerItemAsync.request());
  try {
    const fetchQuery = {
      filter: { rateUnitType: RATE_UNIT.PER_ITEM },
      offset: offset || 0,
      limit: 30,
    };
    const res = await ApiFetchExtraRateList(apiClient, providerId, toQuery(fetchQuery));
    dispatch(fetchExtraRateListPerItemAsync.success(res));
  } catch (error) {
    dispatch(fetchExtraRateListPerItemAsync.failure([error as ExtraRateStatus]));
  }
};

export const fetchExtraRateListPerPerson = (
  providerId: string,
  offset?: number,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchExtraRateListPerPersonAsync.request());
  try {
    const fetchQuery = {
      filter: { rateUnitType: RATE_UNIT.PER_PERSON },
      offset: offset || 0,
      limit: 30,
    };
    const res = await ApiFetchExtraRateList(apiClient, providerId, toQuery(fetchQuery));
    dispatch(fetchExtraRateListPerPersonAsync.success(res));
  } catch (error) {
    dispatch(fetchExtraRateListPerPersonAsync.failure([error as ExtraRateStatus]));
  }
};

export const fetchExtraRateList = (providerId: string, query: QueryParameter): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchExtraRateListAsync.request());
  const { tabFilter, filter, keyword, ...rest } = query;
  try {
    const fetchQuery: QueryParameter = {
      offset: 0,
      limit: 30,
      keyword: encodeURIComponent(keyword || ''),
      filter: {
        ...filter,
        ...(tabFilter && { rateUnitType: tabFilter }),
      },
      ...rest,
    };

    const res = await ApiFetchExtraRateList(apiClient, providerId, toDecodedQuery(fetchQuery));
    dispatch(fetchExtraRateListAsync.success(res));
  } catch (error) {
    dispatch(fetchExtraRateListAsync.failure([error as ExtraRateStatus]));
  }
};

export const fetchExtraRateDetail = (providerId: string, extraRateId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchExtraRateDetailAsync.request());
  try {
    const res = await ApiFetchExtraRateDetail(apiClient, providerId, extraRateId);
    dispatch(fetchExtraRateDetailAsync.success(res));
  } catch (error) {
    dispatch(fetchExtraRateDetailAsync.failure([error as ExtraRateStatus]));
  }
};

export const createExtraRateDetail = (
  providerId: string,
  data: ExtraRateDetail,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(createExtraRateDetailAsync.request());
  try {
    const res = await ApiCreateExtraRateDetail(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(createExtraRateDetailAsync.success(res));
    if (res.id) dispatch(pushLocation(`/provider/${providerId}/extra-rates/${res.id}`));
  } catch (error) {
    dispatch(createExtraRateDetailAsync.failure([error as ExtraRateStatus]));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const updateExtraRateDetail = (
  providerId: string,
  data: ExtraRateDetail,
  search?: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(updateExtraRateDetailAsync.request());
  try {
    const res = await ApiUpdateExtraRateDetail(apiClient, providerId, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(updateExtraRateDetailAsync.success(res));
    if (res.status === 200) {
      dispatch(fetchExtraRateDetail(providerId, data.id));
      dispatch(
        pushLocation({
          pathname: `/provider/${providerId}/extra-rates/${data.id}`,
          state: {
            prevSearch: search,
          },
        }),
      );
    }
  } catch (error) {
    dispatch(updateExtraRateDetailAsync.failure([error as ExtraRateStatus]));
    dispatch(fetchGlobalErrorDialogAsync.success(error));
  }
};

export const deleteExtraRate = (
  providerId: string,
  extraRateId: string,
  managementName: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteExtraRateAsync.request());
  const res = await ApiDeleteExtraRateDetail(apiClient, providerId, extraRateId);

  // Pass the deleted id as message
  res.message = managementName;
  dispatch(deleteExtraRateAsync.success(res));
  dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
};
