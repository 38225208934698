import { createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  QueryParams,
  ReviewList,
  Errors,
  ReviewDetail,
  ReviewStatus,
  ApiStatus,
  ReplyStatus,
  ReviewHistory,
  ReplyHistory,
} from 'ReviewExamination-Types';
import {
  ApiFetchReviewList,
  ApiFetchReview,
  ApiUpdateReviewStatus,
  ApiUpdateReplyStatus,
  ApiFetchReviewHistory,
  ApiFetchReplyHistory,
} from './apis';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

// XXX: Kindly update payloads to your own async implementation
export const fetchReviewListAsync = createAsyncAction(
  'FETCH_REVIEW_LIST_REQUEST',
  'FETCH_REVIEW_LIST_SUCCESS',
  'FETCH_REVIEW_LIST_FAILURE',
)<undefined, ReviewList, Errors[]>();

export const fetchReviewHistoryAsync = createAsyncAction(
  'FETCH_REVIEW_HISTORY_REQUEST',
  'FETCH_REVIEW_HISTORY_SUCCESS',
  'FETCH_REVIEW_HISTORY_FAILURE',
)<undefined, ReviewHistory, Errors[]>();

export const fetchReplyHistoryAsync = createAsyncAction(
  'FETCH_REPLY_HISTORY_REQUEST',
  'FETCH_REPLY_HISTORY_SUCCESS',
  'FETCH_REPLY_HISTORY_FAILURE',
)<undefined, ReplyHistory, Errors[]>();

export const fetchReviewAsync = createAsyncAction(
  'FETCH_REVIEW_REQUEST',
  'FETCH_REVIEW_SUCCESS',
  'FETCH_REVIEW_FAILURE',
)<undefined, ReviewDetail, Errors[]>();

export const updateReviewStatusAsync = createAsyncAction(
  'UPDATE_REVIEW_STATUS_REQUEST',
  'UPDATE_REVIEW_STATUS_SUCCESS',
  'UPDATE_REVIEW_STATUS_FAILURE',
)<undefined, ApiStatus, Errors[]>();

export const updateReplyStatusAsync = createAsyncAction(
  'UPDATE_REPLY_STATUS_REQUEST',
  'UPDATE_REPLY_STATUS_SUCCESS',
  'UPDATE_REPLY_STATUS_FAILURE',
)<undefined, ApiStatus, Errors[]>();

export const fetchReviewList = (params: QueryParams, offset?: number): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const fetchQuery = {
    ...params,
    offset: offset || 0,
  };

  dispatch(fetchReviewListAsync.request());
  const res = await ApiFetchReviewList(apiClient, fetchQuery);
  dispatch(fetchReviewListAsync.success(res));
};

export const fetchReview = (reviewId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReviewAsync.request());
  const res = await ApiFetchReview(apiClient, reviewId);
  dispatch(fetchReviewAsync.success(res));
};

export const fetchReviewHistory = (reviewId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReviewHistoryAsync.request());
  const res = await ApiFetchReviewHistory(apiClient, reviewId);
  dispatch(fetchReviewHistoryAsync.success(res));
};

export const fetchReplyHistory = (reviewId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReplyHistoryAsync.request());
  const res = await ApiFetchReplyHistory(apiClient, reviewId);
  dispatch(fetchReplyHistoryAsync.success(res));
};

export const updateReviewStatus = (reviewId: string, values: ReviewStatus): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(updateReviewStatusAsync.request());
  try {
    const res = await ApiUpdateReviewStatus(apiClient, reviewId, values);
    dispatch(updateReviewStatusAsync.success(res));

    dispatch(fetchReview(reviewId));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
  } catch (err) {
    dispatch(updateReviewStatusAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};

export const updateReplyStatus = (reviewId: string, values: ReplyStatus): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(updateReplyStatusAsync.request());
  try {
    const res = await ApiUpdateReplyStatus(apiClient, reviewId, values);
    dispatch(updateReplyStatusAsync.success(res));

    dispatch(fetchReview(reviewId));
    dispatch(fetchGlobalErrorDialogAsync.success(res));
  } catch (err) {
    dispatch(updateReplyStatusAsync.failure(err));
    dispatch(fetchGlobalErrorDialogAsync.success(err));
  }
};
