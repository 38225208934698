import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowLeft, ArrowRight } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { cx, isNotEmptyArray } from '@travel/utils';

import {
  internalMenus,
  providerGroupMenus,
  providerMenus,
  Menu,
  SubMenu as SubMenuType,
} from 'constants/menuItems';
import { Link } from 'core/universalRouter/Link';
import cloneDeep from 'lodash/cloneDeep';
import { getLoggedInUserPermissions } from 'store/accessControl/selectors';
import { getDesignations } from 'store/providersInformation/selectors';
import { PlatformType } from 'utils/userScopeHelper';
import SubMenu from './SubMenu';

import { setMenuIsOpen } from 'store/menu/actions';

import PERM from 'constants/permissions';
import { PLATFORM } from 'utils';

import styles from './navigationMenu.module.scss';

type Props = {
  accessControl?: object;
  basePath: string;
  platform: PlatformType;
  pathname: string;
  isMenuOpen: boolean;
  onCloseMenu: () => void;
};

const getSelectedSubmenu = (subMenu: SubMenuType[], currentLink: string) => {
  return subMenu.findIndex(menu => {
    const linkParts = menu.link.split('?');
    const link = isNotEmptyArray(linkParts) ? linkParts[0] : '';

    return link === currentLink;
  });
};

function MenuContent(props: Pick<Menu, 'icon' | 'title' | 'class'>) {
  const MenuIcon = props.icon;
  return (
    <>
      <MenuIcon className={cx(styles.icon, props.class && styles[props.class])} />
      <span className={styles.suspendedContent} data-testid="menuItems-menu-title">
        {props.title}
      </span>
    </>
  );
}

export const getProviderMenus = (platform: string, designations: string[]) => {
  const _providerMenus = cloneDeep(providerMenus);

  if (platform === PLATFORM.EXTRANET && designations.length) {
    const photoVideoSecIndex = _providerMenus.findIndex(
      menu => menu.key === 'Photo/VideoManagement',
    );
    const photoVideoSecSubMenu = _providerMenus[photoVideoSecIndex].subMenu || [];

    _providerMenus[photoVideoSecIndex].subMenu = photoVideoSecSubMenu.filter(
      subMenu =>
        !subMenu.displayOnDesignation || designations.includes(subMenu.displayOnDesignation),
    );
  }
  return _providerMenus;
};

function MenuItems(props: Props) {
  const { basePath, platform, pathname, isMenuOpen, onCloseMenu } = props;
  const [openItems, setOpenItems] = useState<number[]>([]);
  const urlParts = pathname.split(basePath);
  const initPermission = useSelector(getLoggedInUserPermissions);
  const designations = useSelector(getDesignations);
  const currentPathname = isNotEmptyArray(urlParts) && urlParts.length > 1 ? urlParts[1] : '/';
  const linkComponents = currentPathname.split('/');
  const itemRefs = useRef<(HTMLElement | null)[]>([]);
  const [scrollItem, setScrollItem] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();

  const currentLink =
    isNotEmptyArray(linkComponents) && linkComponents.length > 2
      ? `/${linkComponents[1]}`
      : currentPathname;

  const menu: Menu[] = {
    [PLATFORM.INTERNAL]: internalMenus,
    [PLATFORM.EXTRANET]: getProviderMenus(platform, designations),
    [PLATFORM.GROUP_EXTRANET]: providerGroupMenus,
    [PLATFORM.OUTSIDE]: [],
  }[platform];

  useEffect(() => {
    if (currentLink === `/booking`) {
      if (isMenuOpen) dispatch(setMenuIsOpen(false));
    } else {
      if (!isMenuOpen) {
        dispatch(setMenuIsOpen(true));
      }
    }
  }, []);
  function onMenuItemClick(event: React.MouseEvent, index: number) {
    event.preventDefault();
    const newOpentems: number[] = [...openItems];
    if (index && openItems.indexOf(index) === -1) {
      newOpentems.push(index);
    } else {
      newOpentems.splice(openItems.indexOf(index), 1);
    }
    setOpenItems(newOpentems);
    const item = itemRefs.current[index];
    if (!isMenuOpen) {
      onCloseMenu();
      // if menu is closed, scroll to the menuItem when clicked on icon
      setScrollItem(item);
    }
  }

  useEffect(() => {
    if (scrollItem) {
      scrollItem?.scrollIntoView();
      setScrollItem(null);
    }
  }, [scrollItem]);

  const checkDisabled = (key: string, isSubMenu?: boolean): boolean => {
    if (isNotEmptyArray(initPermission)) {
      const permission = initPermission.find(permission => permission.objectName === key);
      return (isSubMenu && !permission) || permission?.permissionLevel === PERM.noAccess;
    }
    return true;
  };
  const openMenu = () => {
    if (!isMenuOpen) onCloseMenu();
  };
  const menuWithDisabledProps = menu.map(menu => {
    menu.isDisabled = checkDisabled(menu.key);
    return menu;
  });

  return (
    <ul className={styles.nav} data-testid="menuItems-ul-wrapper">
      {menuWithDisabledProps.map((item, index) => {
        const isOpened = openItems.indexOf(index) > -1;
        const selectedSubmenu = item.subMenu ? getSelectedSubmenu(item.subMenu, currentLink) : -1;
        const isSelected = item.link ? item.link === currentLink : selectedSubmenu !== -1;
        const isDisabled = checkDisabled(item.key);
        const withDisabledPropsSubMenu: SubMenuType[] | undefined = item.subMenu?.map(subMenu => {
          subMenu.isDisabled = checkDisabled(subMenu.key, true);
          return subMenu;
        });
        const isNotLastElement = index !== menuWithDisabledProps.length - 1;
        return (
          <li
            key={`${item.key}-${index}`}
            className={cx(
              styles.parentNav,
              isSelected ? styles.selected : '',
              isDisabled ? styles.disabled : '',
            )}
            ref={el => (itemRefs.current[index] = el)}
            onClick={() => openMenu()}
          >
            {!item.subMenu && item.link ? (
              <div className={cx(isSelected ? styles.selectedMenu : '')} onClick={openMenu}>
                <Link to={`${basePath}${item.link}`} className={styles.menuItem}>
                  <MenuContent icon={item.icon} title={item.title} class={item.class} />
                </Link>
              </div>
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                className={cx(
                  styles.menuItemParent,
                  isSelected || isOpened ? styles.selectedMenu : '',
                )}
                onClick={event => onMenuItemClick(event, index)}
                ref={el => (itemRefs.current[index] = el)}
              >
                <MenuContent icon={item.icon} title={item.title} class={item.class} />
              </a>
            )}
            {withDisabledPropsSubMenu ? (
              <SubMenu
                basePath={basePath}
                items={withDisabledPropsSubMenu}
                isOpen={isOpened || isSelected}
                selectedItem={selectedSubmenu}
                isDisabled={isDisabled}
              />
            ) : null}
            {isNotLastElement && isMenuOpen && <div className={styles.navMenuDivider} />}
          </li>
        );
      })}
      <button
        onClick={onCloseMenu}
        className={styles.expandMenuButton}
        data-testid="menuItems-expand-menu"
      >
        <span className={styles.tooltipText}>
          {isMenuOpen ? (
            <Translate id="Supplier_Menu_Sitemap.Common.Button.collapse_menu" />
          ) : (
            <Translate id="Supplier_Menu_Sitemap.Common.Button.expand_menu" />
          )}
        </span>

        {isMenuOpen ? (
          <ArrowLeft data-testid="menuItems-chevron-left" size={20} />
        ) : (
          <ArrowRight data-testid="menuItems-chevron-right" size={20} />
        )}
      </button>
    </ul>
  );
}

export default MenuItems;
