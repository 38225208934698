import { universalRouterProps } from 'core/universalRouter/types';
import { fetchFeature, fetchIconList, setInitialFeature } from 'store/feature/actions';

type Location = {
  params: {
    featureId?: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  let promises: Array<Promise<void>> = [];
  const { featureId } = location.params;

  if (featureId) {
    promises = [dispatch(fetchFeature(featureId)), dispatch(fetchIconList())];
  } else {
    dispatch(setInitialFeature());
    promises = [dispatch(fetchIconList())];
  }

  return Promise.all(promises);
};
