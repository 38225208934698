import React, { ReactNode, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { pushLocation } from 'store/__router/actions';
import { Location } from './types';

type Props = {
  to: Location | string;
  children: ReactNode;
  className?: string;
  shouldOpenNewTab: boolean;
};

export function Link(props: Props) {
  const { shouldOpenNewTab, to, className, ...rest } = props;
  const dispatch = useDispatch();
  const handleClick = (ent: MouseEvent) => {
    ent.preventDefault();
    dispatch(pushLocation(to));
  };

  return (
    <div>
      {shouldOpenNewTab ? (
        <a className={className} href={`${to}`} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      ) : (
        <a onClick={handleClick} data-testid={'link'} className={className} {...rest}>
          {props.children}
        </a>
      )}
    </div>
  );
}

Link.defaultProps = {
  shouldOpenNewTab: false,
};
