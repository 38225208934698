import { universalRouterProps } from 'core/universalRouter/types';
import { fetchMediaDetail, setInitialMedia } from 'store/media/actions';
import { fetchAllFeatureGroups } from 'store/featureSelection/actions';
import { OBJECT_TYPE } from 'constants/feature';

type Location = {
  params: {
    providerId: string;
    mediaId: string;
  };
};

export default ({ store: { dispatch } }: universalRouterProps, location: Location) => {
  const { providerId, mediaId = '' } = location.params;

  const featureQueryParams = { resourceId: providerId, objectType: OBJECT_TYPE.MEDIA };
  const promises = [dispatch(fetchAllFeatureGroups(providerId, featureQueryParams))];

  if (mediaId) {
    promises.push(dispatch(fetchMediaDetail(providerId, mediaId)));
  } else {
    dispatch(setInitialMedia());
  }

  return Promise.all(promises);
};
