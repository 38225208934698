import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ServiceTypes, ServiceTypesErrors } from 'SupplierService-Types';
import { fetchServiceTypesAsync, setServiceTypesStandard } from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchServiceTypesAsync.request], (state, action) => true)
  .handleAction(
    [fetchServiceTypesAsync.success, fetchServiceTypesAsync.failure],
    (state, action) => false,
  );

export const serviceTypes = createReducer([] as ServiceTypes)
  .handleAction(fetchServiceTypesAsync.success, (state, action) => action.payload)
  .handleAction(setServiceTypesStandard, (state, action) => action.payload);

export const errors = createReducer([] as ServiceTypesErrors[]).handleAction(
  fetchServiceTypesAsync.failure,
  (state, action) => action.payload,
);

const serviceTypesReducer = combineReducers({
  isFetching,
  serviceTypes,
  errors,
});

export default serviceTypesReducer;
export type ServiceTypesState = ReturnType<typeof serviceTypesReducer>;
