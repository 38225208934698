import cookie, { CookieSerializeOptions } from 'cookie';

export const COOKIE_NAMES = {
  APP_TOKEN_NAME: 'everest-extranet-token',
  API_TOKEN_NAME: 'trv-supplier-token',
  INPUT_LANGUAGE: 'inputLanguage',
} as const;

export function setCookie(key: string, value: string, options?: CookieSerializeOptions) {
  if (typeof document === 'undefined' || !key) {
    return;
  }
  document.cookie = cookie.serialize(key, value, {
    path: '/',
    secure: true,
    ...options,
  });
}

export function getCookie(key: string) {
  if (typeof document === 'undefined' || !key) {
    return;
  }

  const parsedCookie = cookie.parse(document.cookie);
  return parsedCookie && (parsedCookie[key] || '');
}
