import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';

import { getTranslation } from '@travel/translation';

import {
  BrowseParameters,
  DeletableDialog,
  MediaItem,
  MediaList,
  MediaStatus,
  MoveRequest,
  NewMedia,
} from 'Media-Types';
import { initialBrowseParameter as init } from 'store/feature/initialValues';
import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';
import { transformToQueryObject as paramsToQueryObject } from 'utils/transformToQueryObject';

import {
  ApiDeleteMedia,
  ApiFetchMediaDetail,
  ApiFetchMediaList,
  ApiMoveMedia,
  ApiSubmitNewMedia,
  ApiUpdateMedia,
  ApiUploadNewMedia,
  FetchDeletableMedia,
} from './apis';

export const fetchMediaListAsync = createAsyncAction(
  'LOAD_MEDIA_LIST_REQUEST',
  'LOAD_MEDIA_LIST_SUCCESS',
  'LOAD_MEDIA_LIST_FAILURE',
)<undefined, MediaList, MediaStatus[]>();

export const fetchMediaDetailAsync = createAsyncAction(
  'LOAD_MEDIA_DETAIL_REQUEST',
  'LOAD_MEDIA_DETAIL_SUCCESS',
  'LOAD_MEDIA_DETAIL_FAILURE',
)<undefined, MediaItem, MediaStatus[]>();

export const submitNewMediaAsync = createAsyncAction(
  'SUBMIT_NEW_MEDIA_REQUEST',
  'SUBMIT_NEW_MEDIA_SUCCESS',
  'SUBMIT_NEW_MEDIA_FAILURE',
)<undefined, MediaItem, MediaStatus[]>();

export const uploadNewMediaAsync = createAsyncAction(
  'UPLOAD_NEW_MEDIA_REQUEST',
  'UPLOAD_NEW_MEDIA_SUCCESS',
  'UPLOAD_NEW_MEDIA_FAILURE',
)<undefined, NewMedia, MediaStatus[]>();

export const updateMediaAsync = createAsyncAction(
  'UPDATE_MEDIA_REQUEST',
  'UPDATE_MEDIA_SUCCESS',
  'UPDATE_MEDIA_FAILURE',
)<undefined, MediaStatus, MediaStatus[]>();

export const putDeletableDialogAsync = createAsyncAction(
  'DELETABLE_DIALOG_MEDIA_REQUEST',
  'DELETABLE_DIALOG_MEDIA_SUCCESS',
  'DELETABLE_DIALOG_MEDIA_FAILURE',
)<undefined, DeletableDialog, MediaStatus[]>();

export const fetchDeletableMediaAsync = createAsyncAction(
  'LOAD_DELETABLE_MEDIA_REQUEST',
  'LOAD_DELETABLE_MEDIA_SUCCESS',
  'LOAD_DELETABLE_MEDIA_FAILURE',
)<undefined, MediaStatus, MediaStatus[]>();

export const deleteMediaAsync = createAsyncAction(
  'DELETE_MEDIA_REQUEST',
  'DELETE_MEDIA_SUCCESS',
  'DELETE_MEDIA_FAILURE',
)<undefined, MediaStatus, MediaStatus[]>();

export const moveMediaAsync = createAsyncAction(
  'MOVE_MEDIA_REQUEST',
  'MOVE_MEDIA_SUCCESS',
  'MOVE_MEDIA_FAILURE',
)<undefined, MediaStatus, MediaStatus[]>();

export const updateMediaBrowseParameter = createStandardAction('UPDATE_MEDIA_BROWSE_PARAMETER')<
  BrowseParameters
>();

export const initializeUploadedMedia = createStandardAction('INITIALIZE_UPLOADED_MEDIA')();
export const clearSubmittedMedia = createStandardAction('CLEAR_SUBMITTED_MEDIA')();

export const setInitialMedia = createStandardAction('SET_INITIAL_MEDIA')();

export const storeMediaOnLocal = createStandardAction('STORE_MEDIA_ON_LOCAL')<MediaItem>();
export const clearMediaOnLocal = createStandardAction('CLEAR_STORE_MEDIA_ON_LOCAL')();

export const setProviderID = createStandardAction('SET_PROVIDER_ID')<string>();
export const fetchMediaList = (
  providerId: string,
  query?: { [key: string]: string },
  offset?: number,
  isInternal: boolean = false,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchMediaListAsync.request());

  try {
    const fetchMediaQuery = {
      ...paramsToQueryObject(query || {}, ['type', 'feature', 'owner', 'id', 'objectType']),
      limit: 30,
      offset: offset || init.offset,
    };

    const res = await ApiFetchMediaList(apiClient, providerId, fetchMediaQuery, isInternal);
    dispatch(fetchMediaListAsync.success(res));
  } catch (error) {
    dispatch(fetchMediaListAsync.failure([error as MediaStatus]));
  }
};

export const fetchMediaListWithQuery = (
  providerId: string,
  query?: { [key: string]: string },
  offset?: number,
  isInternal: boolean = false,
  rejectIfFetching?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isFetching = getState().media.isFetching;
  if (isFetching && rejectIfFetching) return;

  dispatch(fetchMediaListAsync.request());
  const fetchMediaQuery = {
    ...query,
    limit: 30,
    offset: offset || 0,
  };

  try {
    const res = await ApiFetchMediaList(apiClient, providerId, fetchMediaQuery, isInternal);
    dispatch(fetchMediaListAsync.success(res));
  } catch (error) {
    dispatch(fetchMediaListAsync.failure([error as MediaStatus]));
  }
};

export const fetchMediaDetail = (providerId: string, mediaId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(initializeUploadedMedia());
  dispatch(fetchMediaDetailAsync.request());

  try {
    const res = await ApiFetchMediaDetail(apiClient, providerId, mediaId);
    dispatch(fetchMediaDetailAsync.success(res));
  } catch (error) {
    dispatch(fetchMediaDetailAsync.failure([error as MediaStatus]));
  }
};

export const submitNewMedia = (
  providerId: string,
  data: MediaItem,
  file: File,
  allowLocalMediaStore?: boolean,
): AppThunk<Promise<string | undefined>> => async (dispatch, getState, { apiClient }) => {
  try {
    dispatch(uploadNewMediaAsync.request());

    const formData = new FormData();
    formData.append('media', file, file.name);

    const uploadedMedia = await ApiUploadNewMedia(apiClient, formData);
    dispatch(uploadNewMediaAsync.success(uploadedMedia as NewMedia));

    if (uploadedMedia?.id) {
      dispatch(submitNewMediaAsync.request());
      const newData = { ...data, id: uploadedMedia.id, type: uploadedMedia.type };
      const res = await ApiSubmitNewMedia(apiClient, providerId, createRequestBody(newData));

      if (res) {
        const newMedia = await ApiFetchMediaDetail(apiClient, providerId, uploadedMedia?.id);
        if (allowLocalMediaStore) {
          /*keep media in local store for place media mid task
           as in place we will not be able to get the media unless its linked
           and there is no proper way to link media for place other than PUT */
          dispatch(storeMediaOnLocal(newMedia));
        }

        dispatch(submitNewMediaAsync.success(newMedia));
        dispatch(fetchGlobalErrorDialogAsync.success(res));
      }

      return uploadedMedia.id;
    }
  } catch (error) {
    // Dispatch error message for Media invalid size
    if ((error as MediaStatus).status === 413) {
      const { type, size } = file;
      const message = getTranslation({
        id: 'Extranet_Error.Media.Invaild_Size',
        data: { type, size },
      }).join('');
      dispatch(uploadNewMediaAsync.failure([error as MediaStatus]));

      // Need to add status code explicitly to avoid vanish
      dispatch(fetchGlobalErrorDialogAsync.success({ status: 413, message }));
    } else {
      dispatch(fetchGlobalErrorDialogAsync.success(error as MediaStatus));
    }

    dispatch(submitNewMediaAsync.failure([error as MediaStatus]));
  }
};

export const uploadNewMedia = (file: File): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(uploadNewMediaAsync.request());

  const formData = new FormData();
  formData.append('media', file, file.name);

  try {
    const res = await ApiUploadNewMedia(apiClient, formData);
    dispatch(uploadNewMediaAsync.success(res as NewMedia));
  } catch (error) {
    dispatch(uploadNewMediaAsync.failure([error as MediaStatus]));
  }
};

export const uploadNewIcon = (file: File, name: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(uploadNewMediaAsync.request());

  const formData = new FormData();
  formData.append('media', file, name);

  try {
    const res = await ApiUploadNewMedia(apiClient, formData);
    const requestBody = {
      id: res.id,
      active: true,
      name: name,
      description: '',
      featureGroups: [],
    };
    await ApiUpdateMedia(apiClient, '', requestBody, res.id);

    dispatch(uploadNewMediaAsync.success(res as NewMedia));
  } catch (error) {
    dispatch(uploadNewMediaAsync.failure([error as MediaStatus]));
  }
};

export const updateMedia = (
  providerId: string,
  data: MediaItem,
  mediaId: string,
  file: File | null,
  disableSuccessMessage: boolean = false,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  if (!mediaId) {
    return;
  }

  dispatch(updateMediaAsync.request());

  try {
    if (file) {
      const formData = new FormData();
      formData.append('media', file, file.name);
      const uploadedMedia = await ApiUploadNewMedia(apiClient, formData);
      const newData = uploadedMedia
        ? { ...data, id: uploadedMedia.id, type: uploadedMedia.type }
        : data;
      const res = await ApiUpdateMedia(apiClient, providerId, createRequestBody(newData), mediaId);

      dispatch(uploadNewMediaAsync.success(uploadedMedia as NewMedia));
      dispatch(updateMediaAsync.success(res));
      if (!disableSuccessMessage) {
        dispatch(fetchGlobalErrorDialogAsync.success(res));
      }
    } else {
      const res = await ApiUpdateMedia(apiClient, providerId, createRequestBody(data), mediaId);
      dispatch(updateMediaAsync.success(res));
      if (!disableSuccessMessage) {
        dispatch(fetchGlobalErrorDialogAsync.success(res));
      }
    }
  } catch (error) {
    // Dispatch error message for Media invalid size
    if ((error as MediaStatus).status === 413) {
      const message = getTranslation({
        id: 'Extranet_Error.Media.Invaild_Size',
        data: { type: file?.type, size: file?.size },
      }).join('');
      dispatch(uploadNewMediaAsync.failure([error as MediaStatus]));

      // Need to add status code explicitly to avoid vanish
      dispatch(fetchGlobalErrorDialogAsync.success({ status: 413, message }));
    } else {
      dispatch(fetchGlobalErrorDialogAsync.success(error as MediaStatus));
    }

    dispatch(updateMediaAsync.failure([error as MediaStatus]));
  }
};

export const fetchDelatableMedia = (providerId: string, mediaId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchDeletableMediaAsync.request());
    const res = await FetchDeletableMedia(apiClient, providerId, mediaId);
    dispatch(fetchDeletableMediaAsync.success(res));
    dispatch(putDeletableDialogAsync.success({ isShow: true }));
  } catch (error) {
    error.status === 403
      ? dispatch(fetchDeletableMediaAsync.success(error as MediaStatus)) &&
        dispatch(putDeletableDialogAsync.success({ isShow: true }))
      : dispatch(fetchGlobalErrorDialogAsync.success(error as MediaStatus));
  }
};

export const deleteMedia = (
  providerId: string,
  mediaId: string,
  mediaName: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteMediaAsync.request());
  try {
    const res = await ApiDeleteMedia(apiClient, providerId, mediaId);

    // Pass the deleted object name as message
    res.message = mediaName;
    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteMediaAsync.success(res));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
    dispatch(deleteMediaAsync.failure(err));
  }
};

const createRequestBody = (data: MediaItem) => {
  const requestBody = {
    id: data.id || '',
    active: data.active || true,
    name: data.name || '',
    description: data.description || '',
    featureGroups: data.featureGroups,
    mediumGroups: data.mediumGroups,
    type: data.type,
    owner: data.owner,
    designations: data.designations,
  };
  return requestBody;
};

export const moveMedia = (providerId: string, requestBody: MoveRequest): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(moveMediaAsync.request());

  try {
    const res = await ApiMoveMedia(apiClient, providerId, requestBody);
    dispatch(moveMediaAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: 200, message: '' }));
  } catch (error) {
    dispatch(moveMediaAsync.failure([error as MediaStatus]));
    dispatch(fetchGlobalErrorDialogAsync.success(error as MediaStatus));
  }
};
