import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { FetchAllBody, ChannelDistributorErrors } from 'ChannelDistributor-Types';
import {
  fetchChannelDistributorsAsync,
  createChannelDistributorAsync,
  fetchChannelDistributorAsync,
  fetchAllChannelDistributorsAsync,
  updateChannelDistributorAsync,
  clearChannelDistributor,
} from './actions';
import { defaultChannelDistributor } from './initialValues';

export const isCreating = createReducer(false as boolean)
  .handleAction(
    [createChannelDistributorAsync.request, updateChannelDistributorAsync.request],
    () => true,
  )
  .handleAction(
    [
      createChannelDistributorAsync.success,
      createChannelDistributorAsync.failure,
      updateChannelDistributorAsync.success,
      updateChannelDistributorAsync.failure,
    ],
    () => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchChannelDistributorsAsync.request,
      createChannelDistributorAsync.request,
      fetchChannelDistributorAsync.request,
      updateChannelDistributorAsync.request,
      fetchAllChannelDistributorsAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchChannelDistributorsAsync.success,
      fetchChannelDistributorsAsync.failure,
      createChannelDistributorAsync.success,
      createChannelDistributorAsync.failure,
      updateChannelDistributorAsync.success,
      updateChannelDistributorAsync.failure,
      fetchAllChannelDistributorsAsync.success,
      fetchAllChannelDistributorsAsync.failure,
    ],
    () => false,
  );

export const channelDistributors = createReducer({} as FetchAllBody)
  .handleAction(fetchAllChannelDistributorsAsync.success, (state, action) => action.payload)
  .handleAction(fetchChannelDistributorsAsync.success, (_state, action) => {
    const shouldConcat = action.payload.offset > _state.offset;

    return {
      ...action.payload,
      contents:
        _state.contents && shouldConcat
          ? _state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  });

export const channelDistributor = createReducer(defaultChannelDistributor)
  .handleAction(fetchChannelDistributorAsync.success, (_state, action) => action.payload)
  .handleAction(
    [updateChannelDistributorAsync.success, clearChannelDistributor],
    () => defaultChannelDistributor,
  );

export const errors = createReducer([] as ChannelDistributorErrors[]).handleAction(
  fetchChannelDistributorsAsync.failure,
  (_state, action) => action.payload,
);

const channelDistributorReducer = combineReducers({
  isFetching,
  channelDistributors,
  channelDistributor,
  errors,
  isCreating,
});

export default channelDistributorReducer;
export type ChannelDistributorState = ReturnType<typeof channelDistributorReducer>;
