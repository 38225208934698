import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  VisualContentGroupList,
  VisualContentGroupStatus,
  VisualContentGroup,
} from 'VisualContentGroup-Types';

import {
  fetchVisualContentGroupListAsync,
  fetchVisualContentGroupAsync,
  setInitialVisualContentGroup,
  postVisualContentGroupAsync,
  putVisualContentGroupAsync,
} from './actions';

import { initialVisualContentGroup } from './initialValues';
import { deleteCampaignAsync } from 'store/campaign/actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchVisualContentGroupListAsync.request], (state, action) => true)
  .handleAction(
    [fetchVisualContentGroupListAsync.success, fetchVisualContentGroupListAsync.failure],
    (state, action) => false,
  );

export const isCreating = createReducer(false)
  .handleAction(
    [postVisualContentGroupAsync.request, putVisualContentGroupAsync.request],
    () => true,
  )
  .handleAction(
    [
      postVisualContentGroupAsync.success,
      postVisualContentGroupAsync.failure,
      putVisualContentGroupAsync.success,
      putVisualContentGroupAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction(deleteCampaignAsync.request, () => true)
  .handleAction([deleteCampaignAsync.failure, deleteCampaignAsync.success], () => false);

export const visualContentGroupList = createReducer({} as VisualContentGroupList).handleAction(
  fetchVisualContentGroupListAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const visualContentGroupListError = createReducer(
  [] as VisualContentGroupStatus[],
).handleAction(fetchVisualContentGroupListAsync.failure, (state, action) => action.payload);

export const visualContentGroup = createReducer(initialVisualContentGroup as VisualContentGroup)
  .handleAction(fetchVisualContentGroupAsync.success, (state, action) => action.payload)
  .handleAction(setInitialVisualContentGroup, () => initialVisualContentGroup);

export const visualContentGroupError = createReducer([] as VisualContentGroupStatus[]).handleAction(
  fetchVisualContentGroupAsync.failure,
  (state, action) => action.payload,
);

const visualContentGroupReducer = combineReducers({
  isFetching,
  visualContentGroupList,
  visualContentGroupListError,
  visualContentGroup,
  visualContentGroupError,
  isCreating,
  isDeleting,
});

export default visualContentGroupReducer;
export type VisualContentGroupState = ReturnType<typeof visualContentGroupReducer>;
