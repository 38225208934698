import { ApiClient } from '@travel/api-client';
import { toQuery } from '@travel/utils';
import { AvailabilityControl, FilterParams, ApiStatus } from 'AvailabilityControl-Types';

//** Item Rate Plan Availability Control APIs */==============================================
export const fetchItemRatePlansAvailabilityControl = async (
  http: ApiClient,
  providerId: string,
  planId: string,
  params: FilterParams,
): Promise<AvailabilityControl> => {
  return http
    .get(`/provider-api/extranet/v2/providers/${providerId}/plans/${planId}/availabilities`, {
      values: toQuery(params),
    })
    .then((data: AvailabilityControl) => data);
};

export const saveItemRatePlanAvailabilityControlCalendar = (
  http: ApiClient,
  providerId: string,
  planId: string,
  calendarData: AvailabilityControl,
): Promise<ApiStatus> => {
  return http
    .put(`/provider-api/extranet/v2/providers/${providerId}/plans/${planId}/availabilities`, {
      values: calendarData,
    })
    .catch((data: ApiStatus) => data);
};

//** Rate Plan Availability Control APIs */===================================================
export const fetchRatePlansAvailabilityControl = async (
  http: ApiClient,
  providerId: string,
  ratePlanId: string,
  params: FilterParams,
): Promise<AvailabilityControl> => {
  return http
    .get(`/provider-api/extranet/providers/${providerId}/ratePlans/${ratePlanId}/availabilities`, {
      values: toQuery(params),
    })
    .then((data: AvailabilityControl) => data);
};

export const saveRatePlansAvailabilityControlCalendar = (
  http: ApiClient,
  providerId: string,
  ratePlanId: string,
  calendarData: AvailabilityControl,
): Promise<ApiStatus> => {
  return http
    .put(`/provider-api/extranet/providers/${providerId}/ratePlans/${ratePlanId}/availabilities`, {
      values: calendarData,
    })
    .catch((data: ApiStatus) => data);
};
