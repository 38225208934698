import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { UserSegment, UploadResult, SelectedUserSegment } from 'UserSegment-Types';
import {
  fetchUserSegmentAsync,
  fetchSelectedUserSegmentAsync,
  deleteUserSegmentAsync,
  createUserSegmentAsync,
  updateUserSegmentAsync,
  downloadUserSegmentCsvAsync,
} from './actions';

const initialImport = {
  status: 200,
  field: '',
  message: '',
  traceId: '',
  labelId: '',
  userSegmentId: '',
  errorDetails: [],
};

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchUserSegmentAsync.request,
      fetchSelectedUserSegmentAsync.request,
      createUserSegmentAsync.request,
      downloadUserSegmentCsvAsync.request,
      updateUserSegmentAsync.request,
    ],
    (state, action) => true,
  )
  .handleAction(
    [
      fetchUserSegmentAsync.success,
      fetchUserSegmentAsync.failure,
      fetchSelectedUserSegmentAsync.success,
      fetchSelectedUserSegmentAsync.failure,
      createUserSegmentAsync.success,
      createUserSegmentAsync.failure,
      updateUserSegmentAsync.success,
      updateUserSegmentAsync.failure,
      downloadUserSegmentCsvAsync.success,
      downloadUserSegmentCsvAsync.failure,
    ],
    (state, action) => false,
  );

export const isDeleting = createReducer(false as boolean)
  .handleAction(
    deleteUserSegmentAsync.request,

    () => true,
  )
  .handleAction([deleteUserSegmentAsync.success, deleteUserSegmentAsync.failure], () => false);

export const isCreating = createReducer(false as boolean)
  .handleAction([createUserSegmentAsync.request, updateUserSegmentAsync.request], () => true)
  .handleAction(
    [
      createUserSegmentAsync.success,
      createUserSegmentAsync.failure,
      updateUserSegmentAsync.success,
      updateUserSegmentAsync.failure,
    ],
    () => false,
  );

export const uploadResult = createReducer(initialImport as UploadResult).handleAction(
  [
    updateUserSegmentAsync.success,
    updateUserSegmentAsync.failure,
    createUserSegmentAsync.success,
    createUserSegmentAsync.failure,
  ],
  (state, action) => {
    return action.payload;
  },
);

export const userSegments = createReducer({} as UserSegment).handleAction(
  fetchUserSegmentAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      segments:
        state.segments && shouldConcat
          ? state.segments.concat(action.payload.segments)
          : action.payload.segments,
    };
  },
);

export const selectedUserSegment = createReducer([] as SelectedUserSegment[]).handleAction(
  fetchSelectedUserSegmentAsync.success,
  (state, action) => action.payload,
);

const userSegmentReducer = combineReducers({
  isFetching,
  userSegments,
  uploadResult,
  selectedUserSegment,
});

export default userSegmentReducer;
export type UserSegmentState = ReturnType<typeof userSegmentReducer>;
