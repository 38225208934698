export const initialValues = {
  announcementId: '',
  title: '',
  category: 'General',
  contentsMethod: 'External Content',
  content: {},
  announcementTarget: 'Public',
  designations: ['STANDARD'],
  markets: [],
  userList: [],
  targetUserIdType: 'Easy ID',
  postImmediately: false,
  titleLang: 'en-US',
  contents: [],
};

export const initialListQuery = {
  offset: 0,
  limit: 30,
  filter: {
    status: ['AWAITING', 'RUNNING'],
  },
  sort: 'NEWEST_TO_OLDEST',
};

export default initialValues;
