import { Dispatch } from 'redux';
import {
  AppThunk,
  createStandardAction,
  RootAction,
  RootState,
  ThunkDependencies,
} from 'typesafe-actions';
import { Location } from 'core/universalRouter/types';

export const push = createStandardAction('[Router] PUSH')<string>();
export const replace = createStandardAction('[Router] REPLACE')<string>();
export const go = createStandardAction('[Router] GO')<number>();
export const goBack = createStandardAction('[Router] GO_BACK')();
export const goForward = createStandardAction('[Router] GO_FORWARD')();
export const locationChange = createStandardAction('[Router] LOCATION_CHANGE')<Location>();

export const pushLocation = (
  to: Location | string,
  option?: { reload?: boolean },
): AppThunk<void> => (
  dispatch: Dispatch<RootAction>,
  getState: () => RootState,
  { history, res }: ThunkDependencies,
) => {
  if (typeof to === 'string') {
    const [pathname, search] = to.split('?');
    to = new Location({ pathname, search });
  }
  if (history) {
    history.push(to);
    dispatch(locationChange(to));
    if (option?.reload) {
      setTimeout(() => {
        history.go(0);
      }, 1000);
    }
  } else if (res) {
    let path = to.pathname || '/';
    if (to.search) path = `${path}?${to.search}`;
    res.redirect(path);
  }
};

// TODO (@kim): create replace location action lol.
