import { universalRouterProps } from 'core/universalRouter/types';
import { queryToObject } from '@travel/utils/src/queryString';
import { fetchReviewList } from 'store/reviewExamination/actions';
import { getDeviceTZ, getToday, reduceDays } from 'utils/calendar';
import { MAX_DATE_RANGE_EXAMINATION } from 'constants/reviews';

type Query = { [key: string]: string };

export type URLParams = { query: Query };

export const getQuery = (query: Query = {}) => {
  const timezone = getDeviceTZ();
  const today = getToday(timezone);
  const fromDate = reduceDays(today, -MAX_DATE_RANGE_EXAMINATION + 1);

  const objectQuery = queryToObject(query);
  const conditions = {
    postDateFrom: fromDate,
    postDateTo: today,
    ...objectQuery?.search,
  };
  const filter = objectQuery?.filter;
  const sortValue = objectQuery.sort && Object.keys(objectQuery.sort)[0];

  return {
    ...filter,
    ...conditions,
    sort: sortValue,
    limit: 30,
  };
};

export default ({ store: { dispatch } }: universalRouterProps, urlParams: URLParams) => {
  const { query } = urlParams;

  return Promise.all([dispatch(fetchReviewList(getQuery(query)))]);
};
