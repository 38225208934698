import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { FetchAllBody, ChannelManagerErrors } from 'ChannelManager-Types';
import { defaultChannelManager } from './initialValues';
import {
  fetchChannelManagersAsync,
  fetchChannelManagerAsync,
  createChannelManagerAsync,
  updateChannelManagerAsync,
  clearChannelManager,
} from './actions';

export const isCreating = createReducer(false as boolean)
  .handleAction([createChannelManagerAsync.request, updateChannelManagerAsync.request], () => true)
  .handleAction(
    [
      createChannelManagerAsync.success,
      createChannelManagerAsync.failure,
      updateChannelManagerAsync.success,
      updateChannelManagerAsync.failure,
    ],
    () => false,
  );

export const isFetching = createReducer(false as boolean)
  .handleAction(
    [
      fetchChannelManagersAsync.request,
      fetchChannelManagerAsync.request,
      createChannelManagerAsync.request,
      updateChannelManagerAsync.request,
    ],
    () => true,
  )
  .handleAction(
    [
      fetchChannelManagersAsync.success,
      fetchChannelManagersAsync.failure,
      fetchChannelManagerAsync.success,
      fetchChannelManagerAsync.failure,
      createChannelManagerAsync.success,
      createChannelManagerAsync.failure,
      updateChannelManagerAsync.success,
      updateChannelManagerAsync.failure,
    ],
    () => false,
  );

export const channelManagers = createReducer({} as FetchAllBody).handleAction(
  fetchChannelManagersAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const managerDetail = createReducer(defaultChannelManager)
  .handleAction(fetchChannelManagerAsync.success, (_state, action) => action.payload)
  .handleAction(clearChannelManager, (_state, _action) => defaultChannelManager);

export const errors = createReducer([] as ChannelManagerErrors[]).handleAction(
  fetchChannelManagersAsync.failure,
  (_state, action) => action.payload,
);

const channelManagerReducer = combineReducers({
  isFetching,
  channelManagers,
  managerDetail,
  errors,
  isCreating,
});

export default channelManagerReducer;
export type ChannelManagerState = ReturnType<typeof channelManagerReducer>;
