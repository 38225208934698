import { universalRouterProps } from 'core/universalRouter/types';
import { fetchPages, clearPage } from '../../store/labelManagement/actions';
import { QueryParameter } from 'LabelManagement-Types';
import { Products } from '.';

export default (
  { store: { dispatch } }: universalRouterProps,
  query: QueryParameter,
  product = Products.EXTRNT,
) => {
  const updatedQuery = {
    ...query,
    'filter.product': product,
  };

  return Promise.all([dispatch(fetchPages(updatedQuery)), dispatch(clearPage())]);
};
