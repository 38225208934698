import { ApiClient } from '@travel/api-client';
import { FaxManagementItems, FaxQueryParams } from 'FaxManagement-Types';

export const fetch = (http: ApiClient, params: FaxQueryParams): Promise<FaxManagementItems[]> => {
  const url = `/notification-gateway-api/extranet/notifications/fax`;
  return http
    .get(url, {
      values: params,
    })
    .then((data: FaxManagementItems) => data)
    .catch(data => {
      //TODO: Error handling
      console.error(data);
      return data;
    });
};

export const ApiDownloadFax = (http: ApiClient, faxId: string) =>
  http.get(`/notification-gateway-api/extranet/notifications/fax/${faxId}`).then(data => data);

const resendParams = {
  recipient: 'SUPPLIER',
  notificationType: 'FAX',
};

export const ResendFax = (http: ApiClient, notifiactionId: string) =>
  http
    .post(`/notification-gateway-api/extranet/notifications/fax/${notifiactionId}`, {
      values: resendParams,
    })
    .then(data => data);
